import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import axios from "axios";

import {
  AddNewItem,
  UpdateItem,
  getAllItems,
} from "../../Services/item-services";
import "../../Assests/Css/welder.css";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumericWithDot,
  isAlphanumericWithSpecialChars,
  isAlphanumericWithSpace,
  twodecimal,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../utility/FormControl";
import {
  getAllProject,
  getAllLNProjects,
  getLNProjectdata,
} from "../../Services/project-services";
import { getItemsByProject } from "../../Services/item-services";

import { Autocomplete, Box, TextField } from "@mui/material";
import { SearchSelect } from "../utility/search-select";

import ButtonModal from "../Modals/buttonModal";

const AddEditItemMaster = (props) => {
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "Item";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [ProjectDetails, setProjectDetails] = useState([]);

  const [Projectdata, setProjectdata] = useState([]);

  const [Itemdata, setItemdata] = useState([]);

  const INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "CRData/";

  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  console.log("username is -- ", username);

  const [ItemObj, setItemObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          wld_status: true,
          wld_code: "",
          wld_itemcode: "",
          wld_description: "",
          wld_itemgroup: "",
          wld_itemtype: "",
          wld_weight: "",
          SK: "",
          PK: "Item",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
          wld_ProjectNumber: "",
        }
  );

  console.log("HIIIIIIIII", ItemObj);
  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_itemcode: {},
    wld_description: {},
    wld_itemgroup: {},
    wld_itemtype: {},
    wld_weight: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
    wld_ProjectNumber: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setItem",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      // wld_code: {},
    }));
  };

  const fetchprojectnumber = async (projectNo) => {
    
    const result = await getItemsByProject(projectNo);
    console.log("result1111", result);

    setProjectdata(result);

    setItemObj((prev) => ({
      ...prev,
      wld_code: projectNo,
    }));

    let filterprojectno = result?.map((a) => {
      return { item: a.item };
    });

    setItemdata(filterprojectno);
    console.log("aaaaaa", filterprojectno);
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setItemObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  const resetForm = () => {
    if (action === "Add") {
      setItemObj((prevState) => ({
        ...prevState,
        wld_status: true,
        wld_code: "",
        wld_itemcode: "",
        wld_description: "",
        wld_itemgroup: "",
        wld_itemtype: "",
        wld_weight: "",
        SK: "",
        wld_createdby: "",
        wld_createdon: "",
        wld_ProjectNumber: "",
        item: "",
        itemDesc: "",
        itemGroup: "",
        itemType: "",
      }));
    } else if (action === "Edit") {
      setItemObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_code: props.rowdata.wld_code,
        wld_itemcode: props.rowdata.wld_itemcode,
        wld_description: props.rowdata.wld_description,
        wld_itemgroup: props.rowdata.wld_itemgroup,
        wld_itemtype: props.rowdata.wld_itemtype,
        wld_weight: props.rowdata.wld_weight,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
        wld_ProjectNumber: props.rowdata.wld_ProjectNumber,
        item: props.rowdata.item,
        itemDesc: props.rowdata.itemDesc,
        itemGroup: props.rowdata.itemGroup,
        itemType: props.rowdata.itemType,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_code: {},
      wld_itemcode: {},
      SK: {},
    }));
  };

  const fetchProject = async () => {
    const result = await getLNProjectdata();
    console.log("result", result);
    setProjectDetails(result);
  };
  useEffect(() => {
    fetchProject();
  }, []);
  console.log("ProjectDetails", ProjectDetails);

  const validateItem = (action) => {
    let result = false;
    let isError = false;

    if (!ItemObj.wld_weight.match(/^[0-9]*(\.[0-9]{0,2})*$/)) {
      ItemObj.wld_weight = "";
      isError = true;
      error.wld_weight = {
        status: true,
        message: "Only two digits allowed after the decimal point ",
      };
    } else if (ItemObj.wld_weight === "") {
      ItemObj.wld_weight = "";
      isError = true;
      error.wld_weight = {
        status: true,
        message: "Weight is required.",
      };
    }

    if (ItemObj.wld_status === "") {
      isError = true;
      error.wld_status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (action === "Add") {
      
      console.log("ItemObj -- ", ItemObj);
      result = props.allRecs.find(
        (item) =>
          item.PK.toLowerCase() === ItemObj.PK.toLowerCase() &&
          item.SK.toLowerCase() === ItemObj.wld_ProjectNumber.toLowerCase()
      );
      console.log("adddd", result);
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (error.SK.status || error.wld_code.status || error.wld_itemcode.status) {
      isError = true;
    }
    return isError;
  };

  const handleAdd = (e) => {
    let result;
    if (ItemObj.wld_status === true) {
      ItemObj.wld_status = "Active";
    } else {
      ItemObj.wld_status = "Inactive";
    }
    if (action === "Add") {
      ItemObj.SK = ItemObj.wld_code;
      ItemObj.wld_createdby = username;
      ItemObj.wld_createdon = ItemObj.wld_createdon;
      result = AddNewItem(ItemObj);
    } else {
      ItemObj.wld_updatedby = ItemObj.wld_createdby;
      ItemObj.wld_updatedon = ItemObj.wld_updatedon;
      result = UpdateItem(ItemObj);
    }
    result
      .then((resp) => {
        if (action === "Edit") {
          toast.success(`Selected Item  has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success(`Selected Item has been Added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateItem(action)) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Item successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Item Code </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              {/* <Row className="gx-3" style={{ marginBottom: "2rem" }}> */}
              <Row>
               
                <Col md={4}>
                  <Form.Group className="proj-input-select">
                    <div className="search-select">
                      <SearchSelect
                        required
                        data={ProjectDetails}
                        value={ItemObj.wld_code}
                        disabled={action === "Edit" ? true : false}
                        labelField={"wld_ProjectNumber"}
                        valueField={"wld_ProjectNumber"}
                        placeholder="Project Number"
                        onChange={(ProjectList) => {
                          
                          console.log("ddd",ProjectList,ProjectList.value);
                          fetchprojectnumber(ProjectList.value);
                          setItemObj((prevState) => ({
                            ...prevState,
                            wld_status: true,
                            wld_itemcode: "",
                            wld_description: "",
                            wld_itemgroup: "",
                            wld_itemtype: "",
                            wld_weight: "",
                            SK: "",
                            wld_createdby: "",
                            wld_createdon: "",
                            item: "",
                            itemDesc: "",
                            itemGroup: "",
                            itemType: "",
                          }));
                        }}
                      ></SearchSelect>
                    </div>
                    <label>Project Number</label>
                    <div className="required-text-select">
                      {error.wld_ProjectNumber.status && (
                        <Form.Text className="text-danger">
                          {error.wld_ProjectNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

            

                <Col md={4}>
                  <Form.Group className="proj-input-select">
                  <div className="search-select">
                    <SearchSelect
                      className="inspdrop"
                      data={Itemdata}
                      valueField={"item"}
                      disabled={action === "Edit" ? true : false}
                      value={ItemObj.wld_itemcode}
                      labelField={"item"}
                      //placeholder="Item Number"
                      onChange={(ItemList) => {
                        console.log("ItemList on", ItemList, Projectdata);
                        let a = Projectdata.filter((b) => {
                          return b.item === ItemList.value;
                        });
                        console.log("a", a);
                        setItemObj((prev) => ({
                          ...prev,
                          wld_itemcode: ItemList.value,
                          wld_description: a[0].itemDesc,
                          wld_itemgroup: a[0].itemGroup,
                          wld_itemtype: a[0].itemType,
                        }));
                      }}
                    ></SearchSelect>
                    </div>
                    <label>Item Number</label>
                    <div className="required-text-select">
                      {error.wld_itemcode.status && (
                        <Form.Text className="text-danger">
                          {error.wld_itemcode.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_description"
                        name="wld_description"
                        type="text"
                        maxLength="50"
                        disabled="true"
                        class="asterisk"
                        className={
                          error.wld_description.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ItemObj.wld_description}
                        onChange={(event, ItemObj) => {
                          setItemObj((prev) => ({
                            ...prev,
                            wld_description: ItemObj.itemDesc,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_description">
                        Description:
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_description.status && (
                      <Form.Text className="text-danger">
                        {error.wld_description.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row
                className="gx-3"
                style={{
                  // marginBottom: "1rem",
                  position: "relative",
                  bottom: "13px",
                }}
              >
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_itemgroup"
                        name="wld_itemgroup"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        disabled="true"
                        className={
                          error.wld_itemgroup.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ItemObj.wld_itemgroup}
                        onChange={(event, ItemObj) => {
                          setItemObj((prev) => ({
                            ...prev,
                            wld_itemgroup: ItemObj.itemGroup,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_itemgroup">
                        Item Group:
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_itemgroup.status && (
                      <Form.Text className="text-danger">
                        {error.wld_itemgroup.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_itemtype"
                        name="wld_itemtype"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        disabled
                        className={
                          error.wld_itemtype.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ItemObj.wld_itemtype}
                        onChange={(event, ItemObj) => {
                          setItemObj((prev) => ({
                            ...prev,
                            wld_itemtype: ItemObj.itemType,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_itemtype">
                        Item Type:
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_itemtype.status && (
                      <Form.Text className="text-danger">
                        {error.wld_itemtype.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_weight"
                        name="wld_weight"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_weight.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={ItemObj.wld_weight}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = twodecimal(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_weight: error,
                          }));
                        }}
                      />
                      <label for="wld_weight">
                        Weight of consumable (kg/Electrode):
                      </label>
                    </div>

                    {error.wld_weight.status && (
                      <div className="text-danger">
                        {error.wld_weight.message}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{position:"relative", bottom:"30px"}}>
                <Col md={4}>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        {" "}
                        <label
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            color: "var(--col-707070)",
                            fontWeight: "bold",
                          }}
                          class="required-field"
                        >
                          Status{" "}
                        </label>
                      </Col>
                      <Col>
                        <Form.Switch
                          label={ItemObj.wld_status ? "Active" : "Inactive"}
                          className={ItemObj.wld_status ? "green" : "gray"}
                          name="wld_status"
                          style={{ padding: "10px" }}
                          checked={ItemObj.wld_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

          {/* <Row>
            <div>
              <div
                class={
                  action === "Edit"
                    ? " float-start m-1 invisible"
                    : "float-start m-1 visible"
                }
              >
                &nbsp;&nbsp;&nbsp;
                <Button variant="outline-primary" onClick={resetForm}>
                  Reset
                </Button>
              </div>
              <div class="float-end m-1">
                <Button type="submit" className="btn-add-custom">
                  {action === "Edit" ? "Update" : "Add"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </Row> */}
          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditItemMaster;
