import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {
  isAlphanumericWithSpaceDotDash,
   isAlphanumericAllSpecialCharacters,
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { SearchSelect } from "../../utility/search-select";

export const WPS_Filler_metals = ({
  detailObj,
  setdetailObj,
  disabled,
  error,
  readOnly,
  setError,
  handleChangedetailsarray,
  SfaActive,
  setSfaActive,
  classError,
  setClassError,
  FnoActive,
  AnoActive,
  chemicalAnaError,
  setChemicalAnaError,
}) => {
  return (
    <Table className="text-center">
      <thead>
        <tr className="border thead-color ">
          <th className="thickness_title"></th>
          <th className="thickness_title" >Process</th>
          <th className="thickness_title"  style={{textAlign:"left"}}>SFA</th>
          <th className="thickness_title"  style={{textAlign:"left"}}>Classification</th>
          <th className="thickness_title" style={{textAlign:"left"}}>F. No.</th>
          <th className="thickness_title" style={{textAlign:"left"}}>A. No.</th>
          <th className="thickness_title" style={{textAlign:"left"}}>Chemical Analysis or TradeName</th>
        </tr>
      </thead>
      <tbody>
        {detailObj &&
          detailObj.map((item, i) => {
            return (
              <tr
                className={
                  detailObj[i]?.error
                    ? "border WPSSelect error"
                    : "border WPSSelect"
                }
              >
                <td>
                  <Tooltip content={detailObj[i]?.errormessage}>
                    <img
                      src={Error}
                      alt="Error"
                      style={{
                        display: detailObj[i]?.error ? "block" : "none",
                      }}
                    />
                  </Tooltip>
                </td>
                <td style={{ width: "100px" }}>
                <label style={{position:"relative",top:"8px"}}>GTAW</label>
                </td>
                <td>
                  <SearchSelect
                    className="inspdrop"
                    data={SfaActive}
                    readOnly={readOnly}
                    disabled={disabled}
                    valueField={"wld_code"}
                    value={item.WPSD_FM_SFA}
                    labelField={"wld_code"}
                    placeholder=""
                    onChange={(e) => {
                      item.WPSD_FM_SFA = e.value;
                      setdetailObj([...detailObj]);
                      item.WPSD_Process !== ""
                        ? item.WPSD_FM_SFA !== ""
                          ? (detailObj[i].error = false)
                          : (detailObj[i].error = true)
                        : (detailObj[i].error = false);
                    }}
                  ></SearchSelect>
                </td>
                <td className="WpsInput">
                  <input
                    type="text"
                    className={classError[i]?.status ? "error-input" : ""}
                    maxLength={12}
                    readOnly={readOnly}
                    id="WPSD_FM_Classification"
                    name="WPSD_FM_Classification"
                    placeholder=" "
                    value={item.WPSD_FM_Classification}
                    onChange={(e) => handleChangedetailsarray(e, i)}
                    onInput={(e) => {
                      let tempError = [...classError];
                      let error =  isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      tempError[i] = error;
                      setClassError([...tempError]);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_FM_Classification: classError,
                      }));
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      top: "6px",
                      right: "25px",
                    }}
                    className={classError[i]?.status ? "d-block" : "d-none"}
                  >
                    {error.WPSD_FM_Classification.status || (
                      <Tooltip
                        content={
                          error.WPSD_FM_Classification.message ||
                          classError[i]?.message
                        }
                      >
                        <img
                          src={Error}
                          alt="Error"
                          className="ms-1 imgplace"
                        />
                      </Tooltip>
                    )}
                  </div>
                </td>
                <td>
                  <SearchSelect
                    className="inspdrop"
                    data={FnoActive}
                    valueField={"wld_code"}
                    disabled={disabled}
                    readOnly={readOnly}
                    value={item.WPSD_FM_FNo}
                    labelField={"wld_code"}
                    placeholder=""
                    onChange={(e) => {
                      item.WPSD_FM_FNo = e.value;
                      setdetailObj([...detailObj]);
                      item.WPSD_Process !== ""
                        ? item.WPSD_FM_FNo !== ""
                          ? (detailObj[i].error = false)
                          : (detailObj[i].error = true)
                        : (detailObj[i].error = false);
                    }}
                  ></SearchSelect>
                </td>
                <td>
                  <SearchSelect
                    className="inspdrop"
                    data={AnoActive}
                    valueField={"wld_code"}
                    disabled={disabled}
                    readOnly={readOnly}
                    value={item.WPSD_FM_Ano}
                    labelField={"wld_code"}
                    placeholder=""
                    onChange={(e) => {
                      item.WPSD_FM_Ano = e.value;
                      setdetailObj([...detailObj]);
                    }}
                  ></SearchSelect>
                </td>
                <td className="WpsInput">
                  <input
                    type="text"
                    className={chemicalAnaError[i]?.status ? "error-input" : ""}
                    maxLength={25}
                    readOnly={readOnly}
                    id="WPSD_FM_ChemicalAnalysis_TradeName"
                    name="WPSD_FM_ChemicalAnalysis_TradeName"
                    placeholder=" "
                    value={item.WPSD_FM_ChemicalAnalysis_TradeName}
                    onChange={(e) => handleChangedetailsarray(e, i)}
                    onInput={(e) => {
                      let tempError = [...chemicalAnaError];
                      let error =  isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      tempError[i] = error;
                      setChemicalAnaError([...tempError]);
                      setError((prevState) => ({
                        ...prevState,
                        WPSD_FM_ChemicalAnalysis_TradeName: chemicalAnaError,
                      }));
                    }}
                  />
                    <div
                    style={{
                      position: "relative",
                      top: "6px",
                      right: "25px",
                    }}
                    className={chemicalAnaError[i]?.status ? "d-block" : "d-none"}
                  >
                    {error.WPSD_FM_ChemicalAnalysis_TradeName.status || (
                      <Tooltip
                        content={
                          error.WPSD_FM_ChemicalAnalysis_TradeName.message ||
                          chemicalAnaError[i]?.message
                        }
                      >
                        <img
                          src={Error}
                          alt="Error"
                          className="ms-1 imgplace"
                        />
                      </Tooltip>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
