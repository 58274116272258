import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { handleExport1 } from "../../utility/GlobalExport";
import { Button, Row, Col, Container, Form, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  getTrainingRecordbyID,
  updateTraining,
} from "../../../Services/trainingRequisition-service";

import BarLoader from "react-spinners/BarLoader";

const TrainingDetailExcelRecords = forwardRef((props, ref) => {
  console.log("TrainingDetails_new", props.rowdata);

  const [filteredResult, setFilteredResult] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [excelData1, setExcelData] = useState([]);
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();


  const [train, setTrain] = useState({
    wld_ScoreUpdate:
      props.wld_ScoreUpdate === undefined ? props.wld_ScoreUpdate : true,
  });

  const navigate = useNavigate();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate("/weldingmaster/TrainingRecord");
  };

  const tmpcolumnDefs = [
    {
      headerName: "Trainee Name",
      field: "wld_TrainingReqEmployeeName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Salcode",
      field: "wld_TrainingReqSalaryEmployeeCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Requester Department",
      field: "wld_TrainingReqRequesterDepartment",
      type: "text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Attendance",
      field: "wld_attendance",
      type: "Attendance",
      isCheckbox: false,
      editable: true,
    },
    {
      headerName: "Score",
      field: "wld_score",
      type: "Text",
      isCheckbox: false,
      editable: true,
    },
    {
      headerName: "Total Marks",
      field: "wld_marks",
      type: "Text",
      isCheckbox: false,
      editable: true,
    },
    {
      headerName: "Percentage",
      field: "wld_percentage",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];

  const fetchTraining = async () => {
    
    const result = await getTrainingRecordbyID(props?.TrainingSubject);
    setFilteredResult(result);
    setLoading(false);
  };


  if (loading === false) {
    props.onHide();
    props.setReload(!props.reload);
  }

  useEffect(() => {
    fetchTraining();
  }, []);

  const handleGlobalExport = () => {
    
    let filename = "TrainingMaster";
    if(filteredResult.length > 0){
    setExcelData(filteredResult);
    let result = handleExport1(filteredResult, filename, tmpcolumnDefs);
    }
    else{
      alert("Cannot Export PDF, No Record Found")
    }
  };

  useEffect(() => {
    handleGlobalExport();
  }, [filteredResult]);

  return (
    <>
      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                height={8}
                width={196}
                className="shift"
              />
            )}

          </p>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default TrainingDetailExcelRecords;
