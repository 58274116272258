/* Workflow is: "Initiator" > "Approver 1: Trainee L+1" > "Approver 2: Trainee Dept. Head" > 
                 "Final Approver: Training Dept. Head".
      
Important status

1. In progress
2. Approved
3. Deleted - Only initiator can Delete Inprogress Requistion
4. Rejected - only initiator can edit, after Rejection once updated by initiator it goes In Progress
                 
   When workflow is initiated, training request status will be "In-progress". 
   The initiator will not get the option to "Approve" or "Reject". 
   The "Approve" and "Reject" option will be provided to "Approver 1", "Approver 2" and "Final Approver".

   We have 2 status wld_status and wld_pendingStatus 
   1. Requisition Added - wld_status- Initiated , wld_pendingStatus -Approver1
   2. Requisition Reject by Approver 1 - wld_status- Rejected , wld_pendingStatus -Rejected
   3. Requisition Approved by Approver 1 - wld_status- In progress , wld_pendingStatus -Approver2
   4. Requisition Approved by Approver 2 - wld_status-  In progress , wld_pendingStatus -Approver Final
   4. Requisition Deleted by initiator - wld_status-  Deleted , wld_pendingStatus -Deleted

   Approver 1 - is L+1
Appover 2 - WE role, selected by L1

Approval Final - From DB TrainingRequisitionFinalApprover (PK), he is head of Training

*/

import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import SuccessAlertModal from "../../Modals/successAlertModal";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import {
  isAlphanumericWithSpecialChars,
  isAlphanumericWithSpace,
  isAlphanumericampspacedotdashbrackets,
  isAlphanumericWithSpaceDotAmpsDashBrackets,
} from "../../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AddTrainingRequest,
  getReqByType,
} from "../../../Services/trainingRequisition-service";
import { getAllUsersByRole } from "../../../Services/user-service";
import {
  RenderIf,
  formatDateDDMMYYYY,
  getDefaultDate,
} from "../../../functions/welding-functions";
import MultiValuePopUp from "../../utility/MultiValuePopUp";
import { BiSelectMultiple } from "react-icons/bi";
import ClosureRemarks from "../../utility/CommonComponents/ClosureRemarks";
import SingleValuePopUp from "../../utility/SingleValuePopUp";
import { SearchSelect } from "../../utility/search-select";
const AddEditTrainingRequisition = (props) => {
  const todayDate = getDefaultDate();

  let action = props.action;
  let formName = "TrainingRequisition";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errorstyle, seterrorstyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-red-alt)",
    }),
  });
  const TrainingReqEmployeeType = {
    Contractor: "Contract Supervisor",
    Employee: "Company Employee",
  };
  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
    }),
  });
  const [subjectArr, setSubjectArr] = useState([]);
  const [contractorSupervisorL1, setcontractorSupervisor] = useState([
    { um_email: "NA", um_name: TrainingReqEmployeeType.Contractor },
  ]);
  const [Flg, setFlg] = useState(false);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });

  let userId = sessionStorage.getItem("User");
  let username = sessionStorage.getItem("UserName");
  let department = "PED";

  const getTraningReq = async (SK) => {
    let tmpObj = { fieldValue: SK, flag: "trainreq" };
    const Records = await getReqByType(tmpObj);
    if (Records != undefined) {
      setTrainReqObj(Records[0]);
      return Records[0];
    }

  };

  const [trainReqObj, setTrainReqObj] = useState([]);
// ===============================Edit==============================
  useEffect(() => {
    if (action == "Edit") {
      Promise.resolve(getTraningReq(props.rowData.SK));
    } else {
      setTrainReqObj({
        wld_TrainingReqEmployeeType: "Company Employee",
        wld_TrainingReqSalaryEmployeeCode: "",
        wld_TrainingReqEmployeeName: "",
        wld_TrainingReqCompanyName: "Godrej & Boyce Mfg. Ltd.",
        wld_TrainingReqExperience: "",
        wld_TrainingReqSubject: "",
        wld_TrainingReqSubjectCode: "",
        wld_TrainingReqDate: formatDateDDMMYYYY(todayDate),
        wld_TrainingReqRequesterName: username,
        wld_TrainingReqRequesterEmailId: userId,
        wld_TrainingReqRequesterDepartment: department,
        wld_TrainingReqNumber: "",
        wld_createdon: "",
        wld_createdby: username,
        wld_TrainingReqApprover1: "",
        wld_TrainingReqApprover1EmailId: "",
        wld_TrainingReqApprover2: "",
        wld_TrainingReqApprover2EmailId: "",
        wld_TrainingReqApproverFinal: "",
        wld_TrainingReqApproverFinalEmailId: "",
        wld_TrainingReqPendingWith: "",
        wld_TrainingReqApprover1Comment: "",
        wld_TrainingReqApprover1Status: "",
        wld_TrainingReqApprover2Comment: "",
        wld_TrainingReqApprover2Status: "",
        wld_TrainingReqApproverFinalComment: "",
        wld_TrainingReqApproverFinalStatus: "",
      });
    }
    console.log("Trainingdata", trainReqObj);
  }, []);

  useEffect(() => {
    console.log("TrainingOb", trainReqObj);
    if (action == "Edit" && trainReqObj.length != 0) {
      setFlg(true);
    }
  }, [trainReqObj]);
  console.log("username is -- ", username, trainReqObj, props.action);
  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_TrainingReqEmployeeType: {},
    wld_TrainingReqSalaryEmployeeCode: {},
    wld_TrainingReqEmployeeName: {},
    wld_TrainingReqRequesterDepartment: {},
    wld_TrainingReqSubject: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_TrainingReqCompanyName: {},
    wld_TrainingReqExperience: {},
    wld_TrainingReqDate: {},
    wld_TrainingReqRequesterName: {},
  });
  const [contracterror, setcontracterror] = useState({
    ContractSupervisor: {},
  });

  const callReload = () => {
    props.onHide();
    props.setMainReload(true);
  };
  const contractsupervisor = (e) => {
    setTrainReqObj((prevState) => ({
      ...prevState,

      ["wld_TrainingReqApprover1"]: e.label,
      ["wld_TrainingReqApprover1EmailId"]: e.value,
      ["wld_TrainingReqPendingWith"]: e.value,
    }));

    setcontracterror((prevState) => ({
      ...prevState,
      ContractSupervisor: {
        status: false, // Set to false when there is no error
        message: "", // Clear the error message
      },
    }));
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainReqObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "wld_TrainingReqEmployeeType") {
      let compName = "";
      if (value === TrainingReqEmployeeType.Employee) {
        compName = "Godrej & Boyce Mfg. Ltd.";
        setTrainReqObj((prevState) => ({
          ...prevState,
          ["wld_TrainingReqSalaryEmployeeCode"]: "",
          ["wld_TrainingReqEmployeeName"]: "",
          ["wld_TrainingReqApprover1"]: "",
          ["wld_TrainingReqApprover1EmailId"]: "",
          ["wld_TrainingReqPendingWith"]: "",
        }));
      } else {
        setTrainReqObj((prevState) => ({
          ...prevState,
          ["wld_TrainingReqSalaryEmployeeCode"]: "NA",
          ["wld_TrainingReqEmployeeName"]: "",
          ["wld_TrainingReqApprover1"]: "",
          ["wld_TrainingReqApprover1EmailId"]: "",
          ["wld_TrainingReqPendingWith"]: "",
        }));
        getContractSupervisor();
      }
      setTrainReqObj((prevState) => ({
        ...prevState,
        wld_TrainingReqCompanyName: compName,
      }));
    }
  };


  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = (data) => {
    if (data.name === "Training Subject and Date") {
      setSubjectArr(data.result);

      var vs1 = Array.prototype.map
        .call(data.result, function (item) {
          return item.wld_trainingSub;
        })
        .join(",");

      let SubjectPKArr = Array.prototype.map.call(data.result, function (item) {
        return item.SK;
      });
      setTrainReqObj((prevState) => ({
        ...prevState,
        ["wld_TrainingReqSubject"]: vs1,
        ["wld_TrainingReqSubjectCode"]: SubjectPKArr,
      }));
    }

    if (data.name === "PED User") {
      setTrainReqObj((prevState) => ({
        ...prevState,
        ["wld_TrainingReqSalaryEmployeeCode"]: data.result.empSalCode,
        ["wld_TrainingReqEmployeeName"]: data.result.empshortName,
        ["wld_TrainingReqEmployeeEmailID"]:
          data.result.empEmailId != undefined
            ? data.result.empEmailId.split("@")[0]
            : "",
        ["wld_TrainingReqApprover1"]: data.L1?.empL1Name,
        ["wld_TrainingReqApprover1EmailId"]:
          data.L1?.empL1Email != undefined
            ? data.L1?.empL1Email.split("@")[0]
            : "",
        ["wld_TrainingReqPendingWith"]:
          data.L1?.empL1Email != undefined
            ? data.L1?.empL1Email.split("@")[0]
            : "",
      }));
    }
  };
// =====================Reset form=====================================================
  const resetForm = () => {
    if (action === "Add") {
      setTrainReqObj((prevState) => ({
        ...prevState,
        wld_TrainingReqEmployeeType: "Company Employee",
        wld_TrainingReqSalaryEmployeeCode: "",
        wld_TrainingReqEmployeeName: "",
        SK: "",
        PK: "",
        wld_TrainingReqCompanyName: "Godrej & Boyce Mfg. Ltd.",
        wld_TrainingReqExperience: "",
        wld_TrainingReqSubject: "",
        wld_TrainingReqSubjectCode: "",
        wld_TrainingReqDate: formatDateDDMMYYYY(todayDate),
        wld_TrainingReqRequesterName: username,
        wld_TrainingReqRequesterEmailId: userId,
        wld_TrainingReqRequesterDepartment: department,
        wld_TrainingReqNumber: "",
        wld_TrainingReqApprover1: "",
        wld_TrainingReqApprover1Status: "",
        wld_createdon: "",
        wld_createdby: username,
      }));
      setSubjectArr([]);
    }

    setError((prevState) => ({
      ...prevState,
      SK: {},
      PK: {},
      wld_TrainingReqEmployeeType: {},
      wld_TrainingReqSalaryEmployeeCode: {},
      wld_TrainingReqEmployeeName: {},
      wld_TrainingReqRequesterDepartment: {},
      wld_TrainingReqSubject: {},
      wld_createdby: {},
      wld_createdon: {},
      wld_TrainingReqCompanyName: {},
      wld_TrainingReqExperience: {},
      wld_TrainingReqDate: {},
      wld_TrainingReqRequesterName: {},
    }));
  };

  const validateTrainingRequest = (action) => {
    let isError = false;

    if (trainReqObj.wld_TrainingReqEmployeeType === "") {
      isError = true;
      error.wld_TrainingReqEmployeeType = {
        status: true,
        message: "Employee Type is required.",
      };
    }
    if (
      trainReqObj.wld_TrainingReqSalaryEmployeeCode.trim() === "" &&
      trainReqObj.wld_TrainingReqEmployeeType == "Company Employee"
    ) {
      isError = true;
      error.wld_TrainingReqSalaryEmployeeCode = {
        status: true,
        message: "Employee Code is required.",
      };
    }
    if (trainReqObj.wld_TrainingReqEmployeeName === "") {
      isError = true;
      error.wld_TrainingReqEmployeeName = {
        status: true,
        message: "Employee Name is required.",
      };
    }
    if (trainReqObj.wld_TrainingReqCompanyName.trim() === "") {
      isError = true;
      error.wld_TrainingReqCompanyName = {
        status: true,
        message: "Company Name is required.",
      };
    }
    if (trainReqObj.wld_TrainingReqExperience === "") {
      isError = true;
      error.wld_TrainingReqExperience = {
        status: true,
        message: "Experience is required.",
      };
    }
    if (subjectArr.length === 0) {
      isError = true;
      error.wld_TrainingReqSubject = {
        status: true,
        message: "Atleast one Subject is required.",
      };
    }
    if (trainReqObj.wld_TrainingReqRequesterDepartment.trim() === "") {
      isError = true;
      error.wld_TrainingReqRequesterDepartment = {
        status: true,
        message: "Requester Department is required.",
      };
    }
    if (
      trainReqObj.wld_TrainingReqApprover1EmailId === undefined ||
      trainReqObj.wld_TrainingReqApprover1EmailId === ""
    ) {
      isError = true;
      setAlertMessage("L1 Email id not found")
    }
    else{

      isError = false;
      
    }
    
    

    if (
      trainReqObj.wld_TrainingReqEmployeeType ===
        TrainingReqEmployeeType.Contractor &&
      trainReqObj.wld_TrainingReqApprover1.trim() === ""
    ) {
      isError = true;
      contracterror.ContractSupervisor = {
        status: true,
        message: "Contractor Supervisor is required.",
      };
    }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.wld_TrainingReqEmployeeType.status ||
      error.wld_TrainingReqSalaryEmployeeCode.status ||
      error.wld_TrainingReqEmployeeName.status ||
      error.wld_TrainingReqRequesterDepartment.status ||
      error.wld_TrainingReqSubject.status ||
      error.wld_TrainingReqEmployeeName.status ||
      error.wld_TrainingReqCompanyName.status ||
      error.wld_TrainingReqExperience.status ||
      error.wld_TrainingReqDate.status ||
      error.wld_TrainingReqRequesterName.status
    ) {
      isError = true;
    }

    return isError;
  };
// ============================ ADD =====================================
  const handleAdd = (e) => {
    let result;
    if (action === "Add") {
      trainReqObj.wld_status = "Initiated";
      trainReqObj.wld_pendingStatus = "Approver1";
      trainReqObj.wld_TrainingReqNumber =
        trainReqObj.wld_TrainingReqEmployeeType;
      trainReqObj.PK = "TrainingRequisition";
      trainReqObj.SK = userId + "#" + Math.floor(Date.now() / 1000);
      trainReqObj.wld_createdby = username;
      trainReqObj.wld_createdon = new Date().toLocaleDateString("en-GB");

      result = AddTrainingRequest(trainReqObj);
    }
    result
      .then((resp) => {
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (resp.message === "Success") {
          toast.success(`Training Request has been Initiated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        props.setMainReload(!props.mainReload);

        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed",error);
      });
  };

  function funcclose(closebool, param2) {
    if (closebool == true && param2 === "Success") {
      setShowSuccessModel(true);
      props.close(true);
    }

    if (closebool == true && param2 === "Cancel") {
      props.onHide();
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var val = await validateTrainingRequest();
    if (!val) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  const getContractSupervisor = async () => {
    var roles = [
      { role: process.env.REACT_APP_ROLE_Manufacturingsupervisor },
      { role: process.env.REACT_APP_ROLE_WEDLINGENGINEER },
    ];
    let result = await getAllUsersByRole(roles);
    let initialvalues = [
      { um_email: "NA", um_name: TrainingReqEmployeeType.Contractor },
    ];

    if (result != undefined) {
      initialvalues = [...initialvalues, ...result];
    }
    setcontractorSupervisor(initialvalues);
  };

  console.log("Training req", error);

  useEffect(() => {
    if (trainReqObj.wld_TrainingReqSubject !== "") {
      setError((prevError) => ({
        ...prevError,
        wld_TrainingReqSubject: {
          status: false,
          message: "",
        },
      }));
    }
  }, [trainReqObj.wld_TrainingReqSubject]);

  useEffect(() => {
    if (trainReqObj.wld_TrainingReqSalaryEmployeeCode !== "") {
      setError((prevError) => ({
        ...prevError,
        wld_TrainingReqSalaryEmployeeCode: {
          status: false,
          message: "",
        },
        wld_TrainingReqEmployeeName: {
          status: false,
          message: "",
        },
      }));
    }
  }, [trainReqObj.wld_TrainingReqSalaryEmployeeCode]);

  const Mandtoryheading = () =>{
    return(
      <>
      <span style={{color:"red",fontSize:"12px"}}>(All input fields are mandatory)</span>
      </>
    )
  }

  return (
    <>
      <SuccessAlertModal
        msg={`Closure Remarks Updated successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />
      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {action === "Add"
              ? (<>Add Training Request <Mandtoryheading/> </> )
              : "Training Request Number"}{" "}
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row>
                <Col md={4}>
                  <div className="m-input" style={{ width: 300 }}>
                    <span>Employee Type</span>
                    <div className="d-flex mb-2">
                      <div>
                        <input
                          type="radio"
                          name="wld_TrainingReqEmployeeType"
                          value="Company Employee"
                          disabled={action !== "Add"}
                          checked={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.Employee
                          }
                          onChange={(e) => handleChange(e)}
                          style={{
                            fontSize: 10,
                            border: 20,
                            height: 10,
                          }}
                        />
                        <span
                          className={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.Employee
                              ? "fontWeight "
                              : ""
                          }
                          style={{ fontSize: 12 }}
                        >
                          Company Employee
                        </span>
                      </div>
                      <div className="ms-3">
                        <input
                          type="radio"
                          name="wld_TrainingReqEmployeeType"
                          value="Contract Supervisor"
                          disabled={action !== "Add"}
                          checked={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.Contractor
                          }
                          onChange={(e) => handleChange(e)}
                          style={{
                            fontSize: 10,
                            border: 20,
                            height: 10,
                          }}
                        />
                        <span
                          className={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.Contractor
                              ? "fontWeight"
                              : ""
                          }
                          style={{ fontSize: 12 }}
                        >
                          Contract Supervisor
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                {action === "Add" &&
                  trainReqObj.wld_TrainingReqEmployeeType ===
                    TrainingReqEmployeeType.Employee && (
                    <Col md={4} style={{height:"10px"}}>
                      <Form.Group>
                        <div className="g-input" style={{ width: "100%" }}>
                          <>
                            <input
                            required
                            style={{height:"45px", width:"100%"}}
                              id="wld_TrainingReqSalaryEmployeeCode"
                              name="wld_TrainingReqSalaryEmployeeCode"
                              type="text"
                              maxLength="100"
                              readOnly
                              className={
                                error.wld_TrainingReqSalaryEmployeeCode.status
                                  ? "placeHolderInput inputText text-field-with-error"
                                  : "placeHolderInput inputText"
                              }
                              placeholder=" "
                              color="var(--col-707070)"
                              value={
                                trainReqObj.wld_TrainingReqSalaryEmployeeCode
                              }
                              onChange={handleChange}
                            />
                            <label
                              for="wld_TrainingReqSalaryEmployeeCode"
                            >
                              Salary / Employee Code
                            </label>

                            <BiSelectMultiple
                              size={20}
                              onClick={() => {
                                showPopUp(
                                  "PED User",
                                  "Single",
                                  "emailid",
                                  trainReqObj.wld_TrainingReqSalaryEmployeeCode
                                );
                              }}
                              color={"gray"}
                              style={{
                                marginLeft: "15.5rem",
                                marginTop: "-4.2rem",
                              }}
                            />
                          </>
                        </div>
                        
                      </Form.Group>
                    </Col>
                  )}
                
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        type="text"
                        required
                        id="wld_TrainingReqEmployeeName"
                        name="wld_TrainingReqEmployeeName"
                        placeholder=" "
                        readOnly={
                          action === "Edit" ||
                          trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.Employee
                        }
                        maxLength="50"
                        className={
                          error.wld_TrainingReqEmployeeName.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        value={trainReqObj.wld_TrainingReqEmployeeName}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpace(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqEmployeeName: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_TrainingReqEmployeeName"
                      >
                        Employee Name
                      </label>
                    </div>
                     {error.wld_TrainingReqEmployeeName.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "20px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_TrainingReqEmployeeName.message}
                        </span>
                      </Form.Text>
                    )} 
                  </Form.Group>
                </Col>
                {action === "Add" &&
                  trainReqObj.wld_TrainingReqEmployeeType ===
                    TrainingReqEmployeeType.Contractor && (
                    <Col md={4}>
                      <>
                        <SearchSelect
                          id=""
                          name="ContractSupervisor"
                          className={
                            contracterror.ContractSupervisor.status
                              ? "err-input"
                              : ""
                          }
                          stylegreen={
                            contracterror.ContractSupervisor.status
                              ? errorstyle
                              : normalstyle
                          }
                          data={contractorSupervisorL1}
                          stylenormal={normalstyle}
                          valueField={"SK"}
                          labelField={"um_name"}
                          disabled={action === "Edit" ? true : false}
                          value={
                            trainReqObj.wld_TrainingReqApprover1 ||
                            TrainingReqEmployeeType.Contractor
                          }
                          onChange={contractsupervisor}
                        ></SearchSelect>
                        {contracterror.ContractSupervisor.status && (
                          <Form.Text className="text-danger">
                            <span style={{ fontSize: "12px" }}>
                              {contracterror.ContractSupervisor.message}
                            </span>
                          </Form.Text>
                        )}
                      </>
                    </Col>
                  )}
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        style={{height:"45px", width:"100%"}}

                        id="wld_TrainingReqCompanyName"
                        required
                        name="wld_TrainingReqCompanyName"
                        type="text"
                        maxLength="100"
                        className={
                          error.wld_TrainingReqCompanyName.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={trainReqObj.wld_TrainingReqCompanyName}
                        readOnly={
                          action !== "Add" ||
                          trainReqObj.wld_TrainingReqEmployeeType ===
                            "Company Employee"
                        }
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpaceDotAmpsDashBrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqCompanyName: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_TrainingReqCompanyName"
                      >
                        Company Name
                      </label>
                    </div>
                    {error.wld_TrainingReqCompanyName.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_TrainingReqCompanyName.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                                                  style={{width:"100%"}}

                        id="wld_TrainingReqExperience"
                        name="wld_TrainingReqExperience"
                        type="text"
                        maxLength="50"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Approved")
                        }
                        className={
                          error.wld_TrainingReqExperience.status
                            ? "placeHolderInput inputText text-field-with-error "
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={trainReqObj.wld_TrainingReqExperience}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpaceDotAmpsDashBrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqExperience: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_TrainingReqExperience"
                      >
                        Experience
                      </label>
                    </div>
                    {error.wld_TrainingReqExperience.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_TrainingReqExperience.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                {action === "Edit" ?   <Col md={4} style={{height:"60px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        readOnly
                        id="wld_TrainingReqSubject"
                        name="wld_TrainingReqSubject"
                        type="text"
                        placeholder=" "
                        color="var(--col-707070)"
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_TrainingReqSubject}
                      />

                      <label
                        for="wld_TrainingReqSubject"
                      >
                        Training Subject & Date
                      </label>
                      {action === "Add" && (
                        <BiSelectMultiple
                          size={20}
                          onClick={() => {
                            showPopUp(
                              "Training Subject and Date",
                              "Multiple",
                              "Training Subject",
                              popUp.value
                            );
                          }}
                          color={"gray"}
                          style={{
                            marginLeft: "14.5rem",
                            marginTop: "-4.3rem",
                          }}
                        />
                      )}
                    </div>
                     {error.wld_TrainingReqSubject.status && (
                      <Form.Text
                        className="text-danger"
                        style={{ marginLeft: "-17rem", top: "40rem" }}
                      >
                        <span style={{ fontSize: "12px" }}>
                          {error.wld_TrainingReqSubject.message}
                        </span>
                      </Form.Text>
                    )} 
                  </Form.Group>
                </Col> :
                <>
                  <Col md={4} style={{height:"60px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        readOnly
                        id="wld_TrainingReqSubject"
                        name="wld_TrainingReqSubject"
                        type="text"
                        placeholder=" "
                        color="var(--col-707070)"
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_TrainingReqSubject}
                      />

                      <label
                        for="wld_TrainingReqSubject"
                      >
                        Training Subject & Date
                      </label>
                      {action === "Add" && (
                        <BiSelectMultiple
                          size={20}
                          onClick={() => {
                            showPopUp(
                              "Training Subject and Date",
                              "Multiple",
                              "Training Subject",
                              popUp.value
                            );
                          }}
                          color={"gray"}
                          style={{
                            marginLeft: "14.5rem",
                            marginTop: "-4.3rem",
                          }}
                        />
                      )}
                    </div>
                     {error.wld_TrainingReqSubject.status && (
                      <Form.Text
                        className="text-danger"
                        style={{ marginLeft: "-17rem", top: "40rem" }}
                      >
                        <span style={{ fontSize: "12px" }}>
                          {error.wld_TrainingReqSubject.message}
                        </span>
                      </Form.Text>
                    )} 
                  </Form.Group>
                </Col>
                </> }

              
                <Col md={4} >
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                         style={{width:"100%"}}
                        id="wld_TrainingReqDate"
                        readOnly
                        name="wld_TrainingReqDate"
                        type="text"
                        maxLength="20"
                        className={
                          error.wld_TrainingReqDate.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={trainReqObj.wld_TrainingReqDate}
                      />
                      <label
                        for="wld_TrainingReqDate"
                      >
                        Training Requisition Date
                      </label>
                    </div>
                    {error.wld_TrainingReqDate.status && (
                      <Form.Text className="text-danger">
                        {error.wld_TrainingReqDate.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} >
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%", position:"relative"}}>
                      <input
                                                  style={{height:"45px", width:"100%"}}

                        readOnly
                        id="wld_TrainingReqRequesterName"
                        name="wld_TrainingReqRequesterName"
                        type="text"
                        maxLength="20"
                        className={
                          error.wld_TrainingReqRequesterName.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={trainReqObj.wld_TrainingReqRequesterName}
                      />
                      <label
                        for="wld_TrainingReqRequesterName"
                      >
                        Requester Name
                      </label>
                    </div>
                    {error.wld_TrainingReqRequesterName.status && (
                      <Form.Text className="text-danger">
                        {error.wld_TrainingReqRequesterName.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                                                  style={{height:"45px", width:"100%"}}

                        id="wld_TrainingReqRequesterDepartment"
                        name="wld_TrainingReqRequesterDepartment"
                        type="text"
                        maxLength="20"
                        className={
                          error.wld_TrainingReqRequesterDepartment.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={trainReqObj.wld_TrainingReqRequesterDepartment}
                        readOnly={action !== "Add"}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpecialChars(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqRequesterDepartment: error,
                          }));
                        }}
                      />
                      <label
                        // className="required-field"
                        for="wld_TrainingReqRequesterDepartment"
                      >
                        Requester Department
                      </label>
                    </div>
                    {error.wld_TrainingReqRequesterDepartment.status && (
                      <Form.Text className="text-danger">
                        {error.wld_TrainingReqRequesterDepartment.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
            {action === "Add" && (
              <Row>
                <div>
                  <div
                    className={
                      action === "Edit"
                        ? "float-start m-1 invisible"
                        : "float-start m-1 visible"
                    }
                  >
                    <Button variant="outline-primary" onClick={resetForm}  style={{width:"100px",height:"38px", position:"relative",top:"7px"}}>
                      Reset
                    </Button>
                  </div>
                  <div className="float-end m-1">
                    <Button type="submit" className="btn-add-custom" style={{width:"100px",height:"38px"}}>
                      {action === "Edit" ? "Update" : "Add"}
                    </Button>
                  </div>
                </div>
              </Row>
            )}
            {action === "Edit" && Flg && (
              <>
             <Row>
              <br />
             </Row>
                <Row>
                  <u style={{position:"relative", left:"9px"}}>Closure Remarks </u>
                  <ClosureRemarks
                    close={funcclose}
                    rowData={trainReqObj}
                    ExperienceNewRev={trainReqObj.wld_TrainingReqExperience}
                    callReload={callReload}
                    calledFrom={props.calledFrom}
                    setMainReload={props.setMainReload}
                  />
                </Row>
              </>
            )}
          </Modal.Body>
        </Form>
      </Modal>

      <RenderIf
        isTrue={popUp.type === "Multiple" && showForm && action === "Add"}
      >
        <MultiValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>

      <RenderIf isTrue={popUp.type === "Single" && showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditTrainingRequisition;
