const TechniqueTable_Groove = ({pdf}) =>{
  
  var cols = 3,    
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class;
  let WPSProcessDataWidth;
  
  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };
  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";
  //alert(colwidth)
  console.log("PDFFDF", pdf[2]);
  col1Class = "WPSleft7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "WPSleft7-td"
        : "WPSleft7-td-noright"
      : "WPStdhidden";
  col3Class = pdf[3] !== undefined ? "WPSleft7-td-noright1" : "WPStdhidden";

  //   WPSProcessDataWidth = pdf.length < 2 ? pdf.length < 3 ? pdf.length === 3 ? "process3" : "" : "process2": "prcess1";
  WPSProcessDataWidth =
    pdf.length < 3 ? "process1" : pdf.length < 4 ? "process2" : "process3";

    

return(<>
 <table id="table" className="WPSTable7">
              <tr className="WPStrhide" style={{ borderTop: ".1px solid black"  }}>
          <th
            className="WPSthhide"
            style={{ borderTop: ".1px solid black", width: "27%" }}
          >
            Process-Type
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.WPSD_Process} -{pdf[1]?.WPSD_Type}
          </td>

          <td className={col2Class} style={{ width: { colwidth  } }}>
            {pdf[2] && pdf[2]?.WPSD_Process} {pdf[2] && "-"}{" "}
            {pdf[2] && pdf[2]?.WPSD_Type}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.WPSD_Process} {pdf[3] && "-"}{" "}
            {pdf[3] && pdf[3]?.WPSD_Type}
          </td>
        </tr>
              <tr className="WPStrhide">
                  <th className="WPSthhide">Multiple / Single Pass Per Side</th>
                  <td className={col1Class}>
                    {pdf[1]?.WPSD_WP_Multiple_SinglePassPerSide}
                  </td>
                  <td className={col2Class}>
                    {pdf[2] && pdf[2]?.WPSD_WP_Multiple_SinglePassPerSide}
                  </td>
                  <td className={col3Class}>
                    {pdf[3] && pdf[3]?.WPSD_WP_Multiple_SinglePassPerSide}
                  </td>
                </tr>{" "}
                <tr className="WPStrhide">
                  <th className="WPSthhide">Multiple Or Single Layer</th>

                  <td className={col1Class}>
                    {pdf[1]?.WPSD_WP_Multiple_SingleLayer}
                  </td>
                  <td className={col2Class}>
                    {pdf[2] && pdf[2]?.WPSD_WP_Multiple_SingleLayer}
                  </td>
                  <td className={col3Class}>
                    {pdf[3] && pdf[3]?.WPSD_WP_Multiple_SingleLayer}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Multi / Single Electrode</th>

                  <td className={col1Class}>
                    {pdf[1]?.WPSD_WP_Multi_SingleElectrode}
                  </td>
                  <td className={col2Class}>
                    {pdf[2] && pdf[2]?.WPSD_WP_Multi_SingleElectrode}
                  </td>
                  <td className={col3Class}>
                    {pdf[2] && pdf[3]?.WPSD_WP_Multi_SingleElectrode}
                  </td>
                </tr>{" "}
                <tr className="WPStrhide">
                  <th className="WPSthhide">
                    Electrode Spacing Lateral
                    <div className="WPSrcorner">mm</div>
                  </th>
                  <td className={col1Class}>
                    {pdf[1]?.WPSD_WP_ElectrodeSpacing_Late}
                  </td>
                  <td className={col2Class}>
                    {pdf[2] && pdf[2]?.WPSD_WP_ElectrodeSpacing_Late}
                  </td>
                  <td className={col3Class}>
                    {pdf[3] && pdf[3]?.WPSD_WP_ElectrodeSpacing_Late}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">
                    Electrode Spacing Longitudinal
                    <div className="WPSrcorner">mm</div>
                  </th>

                  <td className={col1Class}>
                    {pdf[1]?.WPSD_WP_ElectrodeSpacing_Logit}
                  </td>
                  <td className={col2Class}>
                    {pdf[2] && pdf[2]?.WPSD_WP_ElectrodeSpacing_Logit}
                  </td>
                  <td className={col3Class}>
                    {pdf[3] && pdf[2] && pdf[3]?.WPSD_WP_ElectrodeSpacing_Logit}
                  </td>
                </tr>{" "}

                <tr className="WPStrhide">
                  <th className="WPSthhide">
                  Electrode Angle
                  <div className="WPSrcorner">deg.</div>
                  </th>

                  <td className={col1Class}>
                    {pdf[1]?.WPSD_WP_ElectrodeAngleDegree}
                  </td>
                  <td className={col2Class}>
                    {pdf[2] && pdf[2]?.WPSD_WP_ElectrodeAngleDegree}
                  </td>
                  <td className={col3Class}>
                    {pdf[3] && pdf[2] && pdf[3]?.WPSD_WP_ElectrodeAngleDegree}
                  </td>
                </tr>{" "}
             
                <tr
                  className="WPStrhide"
                >
                  <th
                    className="WPSthhide"
                  >
                    Supplementary MF Control
                  </th>
                  <td style={{ borderLeft: "none" }}>
                    {pdf[0]?.WPSH_Tech_SupplementaryMFControl}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Peening</th>
                  <td style={{ borderLeft: "none" }}>
                    {pdf[0]?.WPSH_Tech_Peening}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Surface Preparation</th>
                  <td style={{ borderLeft: "none" }}>
                    {pdf[0]?.WPSH_Tech_SurfacePreparation}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Oscillation</th>
                  <td style={{ borderLeft: "none" }}>
                    {pdf[0]?.WPSH_Tech_Oscillation}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Initial Interpass Cleaning</th>
                  <td style={{ borderLeft: "none" }}>
                    {pdf[0]?.WPSH_Tech_Inital_InterpassCleaning}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Back Gouging Method</th>
                  <td style={{ borderLeft: "none" }}>
                    {pdf[0]?.WPSH_Tech_BackGougingMethod}
                  </td>
                </tr>
              
                <tr
                  className="WPStrhide"
                >
                  <th
                    className="WPSthhide"
                  >
                    Groove Preparation
                  </th>
                  <td style={{ borderLeft: "none", }}>
                    {pdf[0]?.WPSH_Tech_GroovePreparation}
                  </td>
                </tr>{" "}
                <tr
                  className="WPStrhide"
                >
                  <th
                    className="WPSthhide"
                  >
                    Use of Thermal Process
                  </th>
                  <td style={{ borderLeft: "none", }}>
                    {pdf[0]?.WPSH_Tech_UseOfThermalProcess}
                  </td>
                </tr>{" "}
                <tr
                  className="WPStrhide"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <th
                    className="WPSthhide"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    Closed to out Chamber
                  </th>
                  <td style={{ borderLeft: "none", }}>
                    {pdf[0]?.WPSH_Tech_ClosedtooutChamber}
                  </td>
                </tr>{" "}
              </table></>

)

}
export default TechniqueTable_Groove;