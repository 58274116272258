import React from "react";
import { NavLink } from "react-router-dom";
import "../../Assests/Css/sidebar.css";


const NavImage = ({ item, icon, isSelected, setIsOpen, counter, onHide }) => {
  var count = counter;

  if (item.menu_children === undefined) count = 0;
  console.log("getmenudata Navimage",isSelected)
  return (
    <span className={isSelected ? "divImg" : ""}>
       <NavLink
        to={item.menu_path}
        onClick={() => {
          if (item.menu_path === "#") {
            setIsOpen(true);
          }
          onHide(count);
        }}
        key={"A" + item.menu_id}
      >
        <img className="menu-icons" src={icon} alt=""></img>
      </NavLink>
    </span>
  );
};

export default NavImage;
