import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { defaultColParas } from "./gbcs-functions";
import {
  resetFilter,
  resetAllFilter,
  FilterChange,
  getData,
} from "./gbcs-functions";
import { BsX } from "react-icons/bs";
import { Container, Button } from "react-bootstrap";

const GridMaster = forwardRef((props, ref) => {
  console.log("mygridData", props.rowDetails);
  const [gridApi, setGridApi] = useState(null);
  const [filteredCol, setFilteredCol] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState(props.rowDetails);
  const [searchReText, setSearchReText] = useState("");
  const gridRef = useRef();
  const fullrow = props.fullrow !== undefined ? props.fullrow : "";
  console.log("props.suppressClickEdit", fullrow, props.onRowValueChanged);
  useEffect(() => {
    // console.log("props.rowDetails",props.rowDetails)
    setData(props.rowDetails);
  }, [props.rowDetails]);

  var height =
    props.height === "" || props.height === undefined
      ? window.innerHeight - 130
      : props.height;

  var paginationPageSize =
    props.paginationPageSize === "" || props.paginationPageSize === undefined
      ? 6
      : props.paginationPageSize;

  console.log("sagar_paginationPageSize_777", paginationPageSize);
  console.log("sagar_height_777", height);

  if (filteredCol.length > 0) height = window.innerHeight - 200;

  const gridStyle = useMemo(() => ({ height: height, width: "100%" }), []);
  const gridStylewithFilter = useMemo(
    () => ({ height: window.innerHeight - 230, width: "100%" }),
    []
  );
  const [pageSize] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  const defaultColDef = useMemo(() => {
    return defaultColParas();
  }, []);

  function onGridReady(params) {
    
    let temp = [];
    params.api.forEachNodeAfterFilter((node) => {
      
      temp.push(node.data);
    });
    setRowData(temp);
    if (props.setPropsgridAPI !== undefined) props.setPropsgridAPI(params.api);
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    props.setExcelData(temp);
  }
  console.log("props.rowClassRules", props.rowClassRules);
  const filteredResult = useMemo(() => {
    let firstPageIndex = 0;
    if (currentPage > 1) {
      firstPageIndex = (currentPage - 1) * pageSize;
    }
    if (firstPageIndex >= data?.length) {
      firstPageIndex = 0;
      const totalPageCount = Math.ceil(data.length / pageSize);
      if (totalPageCount > 0) {
        setCurrentPage(totalPageCount);
      } else {
        setCurrentPage(1);
      }
    }
    let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
    return data ? data.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, data, pageSize]);

  useEffect(() => {
    props.setExcelData(props.rowDetails);
  }, [props.rowDetails]);

  useEffect(() => {
    let temp = [];
    setData(props.rowDetails);
    props.setExcelData(props.rowDetails);
    //props.setExcelData(props.rowDetails);
    // console.log("filetered in grid1", props.rowDetails)
    if (gridRef.current.api) {
      // console.log("filetered search text",searchReText)
      // console.log("filetered Grid", gridRef.current.api)

      gridRef.current.api.setQuickFilter(searchReText);
      gridRef.current.api.forEachNodeAfterFilter((node) => {
        temp.push(node.data);
      });

      // console.log("filetered new row search", temp);
    }
    //let temp = getData(gridRef.current.api);
    props.setExcelData(temp);
    setData(temp);
  }, [gridRef.current, searchReText]);
  useImperativeHandle(ref, () => ({
    setSearch: async (text) => {
      setSearchReText(text);
    },
  }));

  return (
    <Container fluid className="grid-comp-container">
      <div className="d-none">
        {props.getFilterColLength(filteredCol.length)}
      </div>
      {filteredCol.length > 0 && (
        <div className="d-flex" style={{ paddingBottom: "0.5%" }}>
          {filteredCol.map((item, index) => {
            
            props.getDataEvent(rowData);
            return (
              <div key={`FilterCol${index}`} className="filter-col pr-0 me-2">
                <span className="ps-2 reset-filter-text">
                  {item}{" "}
                  <BsX
                    size={18}
                    onClick={() => resetFilter(gridRef, item, props.fieldNames)}
                  />
                </span>
              </div>
            );
          })}
          <div>
            <Button
              // variant="outline-primary"
              // className="ms-2 lh-1 clear-all-btn"
              className="clrbtn"
              // style={{ marginTop: "2%" }}
              onClick={() => {
                resetAllFilter(gridRef, props);
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
      )}

      <div
        className="ag-theme-material d-none d-md-block d-lg-block"
        style={filteredCol.length <= 0 ? gridStyle : gridStylewithFilter}
      >

        <AgGridReact
          ref={gridRef}
          rowData={props.rowDetails}
          columnDefs={props.colDetails}
          defaultColDef={defaultColDef}
          defaultWelDef={props.defaultWolDef}
          weld={props.weldcol}
          onGridReady={onGridReady}
          pagination={props.pagination === false ? false : true}
          paginationPageSize={paginationPageSize}
          rowSelection={"multiple"}
          searchReText={props.searchReText}
          enableBrowserTooltips={true}
          onFilterChanged={(params) => {
            FilterChange(
              params,
              setFilteredCol,
              props.headerNames,
              setRowData,
              props.setExcelData
            );
            //getData(params);

            //onGridReady(params)
          }}
          height={height}
          editType={fullrow}
          rowHeight={props.rowHeight}
          headerHeight={props.headerHeight}
          onCellValueChanged={props.onCellValueChanged}
        />
      </div>
    </Container>
  );
});
export default GridMaster;
