export const Tubesheet_Pdf_Fields = [
    {ColumnDesc:"Welding Process", FieldName:"WPSD_WP_WeldingProcess",Process:"WPSD_Type", Unit:"",Colums:""},
    {ColumnDesc:"Type", FieldName:"WPSD_WP_Type", Unit:"",Colums:""},
    {ColumnDesc:"Min. Preheat Temperature", FieldName:"WPSD_WP_MinimumPreheatTemp", Unit:"°C",Colums:""},
    {ColumnDesc:"Max. Interpass Temperature", FieldName:"WPSD_WP_MaximumInterpassTemp", Unit:"°C",Colums:""},
    {ColumnDesc:"Post Heating / DHT", FieldName:"WPSD_WP_PostHeating", Unit:"",Colums:""},
    {ColumnDesc:"Tungsten Size & Type", FieldName:"WPSD_WP_TungstenType", Unit:"mm",Colums:""},
    {ColumnDesc:"Welding Position", FieldName:"WPSD_WP_WeldPosition", Unit:"",Colums:""},
    {ColumnDesc:"Progression", FieldName:"WPSD_WP_WeldProgression", Unit:"",Colums:""},
    {ColumnDesc:"No of Passes", FieldName:"WPSD_WP_NoofPasses", Unit:"",Colums:""},
    {ColumnDesc:"Layer Number", FieldName:"WPSD_WP_LayerNumber", Unit:"",Colums:"2"},
    {ColumnDesc:"Filler Metal Size Ø", FieldName:"WPSD_WP_FillerMetalSIze", Unit:"mm",Colums:"2"},
    {ColumnDesc:"Amperes", FieldName:"WPSD_WP_Amperes", Unit:"Amps",Colums:"2"},
    {ColumnDesc:"DC pulsing Current", FieldName:"WPSD_WP_Pulsing_current", Unit:"Amps",Colums:"2"},
    {ColumnDesc:"DC pulsing Current - Others", FieldName:"WPSD_WP_Pulsing_current_others", Unit:"",Colums:"2"},
    {ColumnDesc:"Voltage", FieldName:"WPSD_WP_Volts", Unit:"Volts",Colums:"2"},
    {ColumnDesc:"Travel Speed", FieldName:"WPSD_WP_TravelSpeed", Unit:"mm/min",Colums:"2"},
    {ColumnDesc:"Maximum Heat Input", FieldName:"WPSD_WP_HeatInput", Unit:"KJ/mm",Colums:"2"},
    {ColumnDesc:"Polarity or current type", FieldName:"WPSD_WP_CurrentPolarity", Unit:"",Colums:"2"},
    {ColumnDesc:"Automatic Arc Voltage control", FieldName:"WPSD_WP_ArcVoltageControl", Unit:"",Colums:""},
    {ColumnDesc:"Shielding:",Suffix:"Shielding",MiddleDesc:"Gas Type", FieldName:"WPSD_WP_ShieldingGasType", Unit:"%Comp"},
    {ColumnDesc:"",MiddleDesc:"Flow Rate", FieldName:"WPSD_WP_ShieldingFlowRate", Unit:"I/min"},
    {ColumnDesc:"Backing/Trailing:", Suffix:"Backing", MiddleDesc:"Gas Type", FieldName:"WPSD_WP_BackingGasType", Unit:"%Comp"},
    {ColumnDesc:"",MiddleDesc:"Flow Rate", FieldName:"WPSD_WP_BackingFlowRate", Unit:"I/min"},
    {ColumnDesc:"Auxiliary gas shield system", FieldName:"WPSD_WP_AuxiGasShieldSystem", Unit:""},
    
    ]
    export default Tubesheet_Pdf_Fields;