import { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumericWithDotSpaceDashCommaBracketsSlash,
  isNumberRange,
  isAlphanumericWithSpaceDotDash,
  checkNull,
} from "../../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../../utility/FormControl";
import SingleValuePopUp from "../../utility/SingleValuePopUp";
import {
  formatDateDD_MM_YYYY,
  dateCompare,
  RenderIf,
} from "../../../functions/welding-functions";
import { BiSelectMultiple } from "react-icons/bi";
import {
  AddNewTraining,
  UpdateTraining,
} from "../../../Services/training-service";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import ButtonModal from "../../Modals/buttonModal";
import { trainingDate } from "./Training_entity";

const AddEditTrainingMaster = (props) => {
  let action = props.action;
  let formName = "Training";

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const todayDate = yyyy + "-" + mm + "-" + dd;
  const maxDate = yyyy + "-" + `${Number(mm) + +6}` + "-" + dd;
  const minDate = yyyy + "-" + mm + "-" + dd;


  const sixMonthsFromNow = new Date(today);
  sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);

  const lastDayOfSixthMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 6 + 1,
    0
  );
  const sixmonth =
    sixMonthsFromNow > lastDayOfSixthMonth
      ? lastDayOfSixthMonth.toISOString().split("T")[0]
      : sixMonthsFromNow.toISOString().split("T")[0];
  const currDate = todayDate;

  const refFromDate = useRef();
  let username = sessionStorage.getItem("UserName");

  const [trainingObj, setTrainingObj] = useState(
    action === "Edit" || action === "Copy"
      ? props.rowdata
      : {
          SK: "",
          PK: "Training",
          wld_trainingSub: "",
          wld_fromDate: todayDate,
          wld_toDate: todayDate,
          wld_startTime: "",
          wld_duration: "",
          wld_endTime: "",
          wld_trainingMonth: "",
          wld_facultyType: "internal",
          wld_faculty: "",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
          wld_facultyEmail: "",
          wld_facultyEmpCode: "",
        }
  );

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [facultyType, setFacultyType] = useState(
    trainingObj.wld_facultyType ? trainingObj.wld_facultyType : "internal"
  );
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });


  const [error, setError] = useState({
    wld_trainingSub: {},
    wld_fromDate: {},
    wld_toDate: {},
    wld_startTime: {},
    wld_duration: {},
    wld_faculty: {},
  });
  useEffect(() => {
    setTrainingObj((prevState) => ({
      ...prevState,
      ["wld_trainingMonth"]: calculateMonth(),
    }));
  }, [trainingObj.wld_fromDate]);

  useEffect(() => {
    setTrainingObj((prevState) => ({
      ...prevState,
      ["wld_endTime"]: calculateEndTime(),
    }));
  }, [trainingObj.wld_startTime, trainingObj.wld_duration]);

  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainingObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTimeChange = (value, name) => {
    setTrainingObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (value, name) => {
    let result = false;
    if (name === "wld_toDate" && trainingObj.wld_fromDate != "") {
      result = dateCompare(value, trainingObj.wld_fromDate);
    } else if (name === "wld_fromDate" && trainingObj.wld_toDate != "") {
      result = dateCompare(trainingObj.wld_toDate, value);
    }
    if (result) {
      setError((prev) => ({
        ...prev,
        wld_toDate: {
          status: true,
          message: "To date should be greater than or equal to From date",
        },
      }));
    } else if (trainingObj.wld_toDate != "") {
      setError((prev) => ({
        ...prev,
        wld_toDate: {
          status: false,
          message: "",
        },
      }));
    }

    if (trainingObj.wld_fromDate != "") {
      setError((prev) => ({
        ...prev,
        wld_fromDate: {
          status: false,
          message: "",
        },
      }));
    }

    setTrainingObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setTrainingObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFacultyType(value);
    setTrainingObj((prevState) => ({
      ...prevState,
      ["wld_faculty"]: "",
      ["wld_facultyEmail"]: "",
      ["wld_facultyEmpCode"]: "",
    }));
  };

  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = (data) => {

    if (data.name === "PED User") {
      setTrainingObj((prevState) => ({
        ...prevState,
        ["wld_faculty"]: data.result.empshortName,
        ["wld_facultyEmail"]: data.result.empEmailId,

        ["wld_facultyEmpCode"]: data.result.empSalCode.toString(),
      }));
    }
  };

  const calculateEndTime = () => {
    if (trainingObj.wld_startTime && trainingObj.wld_duration) {
      let a = trainingObj.wld_startTime.split(":");
      let ans = Number(a[0]) + Number(trainingObj.wld_duration);
      let ansStr = ans.toString(10);
      if (ansStr.length === 1) {
        ans = "0" + ans;
      }
      if (ans > 23) {
        ans = 23;
        a[1] = 59;
      }
      return ans + ":" + a[1];
    } else return "";
  };

  const calculateMonth = () => {
    if (trainingObj.wld_fromDate) {
      let today = new Date(trainingObj.wld_fromDate); // yyyy-mm-dd
      let month = today.toLocaleString("default", { month: "short" });
      let year = today.toLocaleString("default", { year: "numeric" });
      return month + " " + year;
    }
  };


  const resetForm = () => {
    if (action === "Add") {
      setTrainingObj((prevState) => ({
        ...prevState,
        SK: "",
        wld_trainingSub: "",
        wld_fromDate: todayDate,
        wld_toDate: todayDate,
        wld_startTime: "",
        wld_duration: "",
        wld_endTime: "",
        wld_trainingMonth: calculateMonth(),
        wld_facultyType: "internal",
        wld_faculty: "",
        wld_createdby: "",
        wld_createdon: "",
        wld_updatedby: "",
        wld_updatedon: "",
      }));
      setFacultyType("internal");
    } else if (action === "Edit") {
      setTrainingObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_trainingSub: props.rowdata.wld_trainingSub,
        wld_fromDate: props.rowdata.wld_fromDate,
        wld_toDate: props.rowdata.wld_toDate,
        wld_startTime: props.rowdata.wld_startTime,
        wld_duration: props.rowdata.wld_duration,
        wld_endTime: props.rowdata.wld_endTime,
        wld_trainingMonth: props.rowdata.wld_trainingMonth,
        wld_facultyType: props.rowdata.wld_facultyType,
        wld_faculty: props.rowdata.wld_faculty,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
        wld_facultyEmail: props.rowdata.wld_facultyEmail,
      }));
    } else if (action === "Copy") {
      setTrainingObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_trainingSub: props.rowdata.wld_trainingSub,
        wld_fromDate: props.rowdata.wld_fromDate,
        wld_toDate: props.rowdata.wld_toDate,
        wld_startTime: props.rowdata.wld_startTime,
        wld_duration: props.rowdata.wld_duration,
        wld_endTime: props.rowdata.wld_endTime,
        wld_trainingMonth: props.rowdata.wld_trainingMonth,
        wld_facultyType: props.rowdata.wld_facultyType,
        wld_faculty: props.rowdata.wld_faculty,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
      }));
      setFacultyType(props.rowdata.wld_facultyType);
    }
    setError((prevState) => ({
      ...prevState,
      wld_trainingSub: {},
      wld_fromDate: {},
      wld_toDate: {},
      wld_startTime: {},
      wld_duration: {},
      wld_endTime: {},
      wld_trainingMonth: {},
      wld_faculty: {},
    }));
  };

  const handleAdd = (e) => {
    
    let result = "";

    if (action === "Add" || action === "Copy") {
      trainingObj.wld_createdby = username;
      result = AddNewTraining(trainingObj);
    } else {
      trainingObj.wld_updatedby = username;
      result = UpdateTraining(trainingObj);
    }
    result
      .then((resp) => {
        if (action === "Edit") {
          toast.success(`Selected Training has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add" || action === "Copy") {
          toast.success(`Selected Training has been Added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
      });
  };

  const validateTraining = (restObj) => {
    let entries = Object.entries(restObj);
    let entriesdate = Object.entries(trainingDate);
    let isError = false;
    entries.map(([key, val]) => {
      if (trainingObj[key] === "") {
        isError = true;
        error[key] = {
          status: true,
          message: `This field is required`,
        };
      }
    });

    entriesdate.map(([key, val]) => {
      if (trainingObj[key] > sixmonth) {
        isError = true;
        error[key] = {
          status: true,
          message: `Date should be less than 6 months from today`,
        };
      }
    });
   
    
    if (facultyType === "external") {
      trainingObj.wld_facultyEmail = "0";
      trainingObj.wld_facultyEmpCode = "0";
    }
    
    if(trainingObj.wld_facultyEmail === ""){
      error["wld_faculty"] = {
        status: true,
        message: "Faculty Email is Required",
      };
    }
    if (
      !isError &&
      action === "Copy" &&
      (trainingObj.wld_fromDate != "" || trainingObj.wld_toDate != "")
    ) {
      if (dateCompare(trainingObj.wld_fromDate, todayDate)) {
        isError = true;
        error["wld_fromDate"] = {
          status: true,
          message: "From date should be greater than or equal to today date",
        };
      }

      if (dateCompare(trainingObj.wld_toDate, todayDate)) {
        isError = true;
        error["wld_toDate"] = {
          status: true,
          message: "To date should be greater than or equal to today date",
        };
      }
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    console.log("Error Training",error,isError)
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let result = props.allRecs.filter((e) => {
      return (
        ((trainingObj.wld_fromDate >= e.wld_fromDate &&
          trainingObj.wld_fromDate <= e.wld_toDate) ||
          (trainingObj.wld_toDate >= e.wld_fromDate &&
            trainingObj.wld_toDate <= e.wld_toDate)) &&
        e.wld_faculty === trainingObj.wld_faculty &&
        ((trainingObj.wld_startTime >= e.wld_startTime &&
          trainingObj.wld_startTime <= e.wld_endTime) ||
          (trainingObj.wld_endTime >= e.wld_startTime &&
            trainingObj.wld_endTime <= e.wld_endTime))
      );
    });

    if (result.length) {
      setAlertMessage(
        "Other training already scheduled for selected faculty for selected slot"
      );
    } else {
      const {
        SK,
        wld_createdby,
        wld_createdon,
        wld_updatedby,
        wld_updatedon,
        ...restObj
      } = trainingObj;
      if (!validateTraining(restObj)) {
        setshowConfirmModel(true);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} New Training </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row style={{ marginBottom: ".5rem" }}>
                <Col md={8} style={{position:"relative", top:"8px"}}>
                  <div className="g-input w-200">
                    <input
                      type="text"
                      id="wld_trainingSub"
                      name="wld_trainingSub"
                      placeholder=" "
                      style={{ width: "573px"}}
                      maxLength="50"
                      className={
                        error.wld_trainingSub.status ? "err-input" : ""
                      }
                      value={trainingObj.wld_trainingSub}
                      onChange={handleChange}
                      onInput={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsSlash(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          wld_trainingSub: error,
                        }));
                      }}
                    />
                    <label for="wld_trainingSub">Training Subject</label>
                  </div>
                  {error.wld_trainingSub.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "8px" }}>
                        {error.wld_trainingSub.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>
                <Col md={4}>
                  <div className="g-input w-100">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="From Date"
                          value={
                            trainingObj.wld_fromDate
                              ? dayjs(trainingObj.wld_fromDate)
                              : dayjs(todayDate)
                          }
                          minDate={dayjs(minDate)}
                          maxDate={dayjs(maxDate)}
                          onChange={(newValue) => {
                            let date = dayjs(newValue).format("YYYY-MM-DD");

                            handleDateChange(date, "wld_fromDate");
                          }}
                          format="DD-MM-YYYY"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  {error.wld_fromDate.status && (
                    <Form.Text className="text-danger">
                      {error.wld_fromDate.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: ".5rem", position:"relative", top:"5px"}}>
                <Col md={4}>
                  <div className="g-input w-100">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="To Date"
                          value={
                            trainingObj.wld_toDate
                              ? dayjs(trainingObj.wld_toDate)
                              : dayjs(todayDate)
                          }
                          minDate={dayjs(minDate)}
                          maxDate={dayjs(maxDate)}
                          onChange={(newValue) => {
                            let date = dayjs(newValue).format("YYYY-MM-DD");
                            handleDateChange(date, "wld_toDate");
                          }}
                          format="DD-MM-YYYY"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  {error.wld_toDate.status && (
                    <Form.Text className="text-danger">
                      {error.wld_toDate.message}
                    </Form.Text>
                  )}
                </Col>
                <Col md={4}>
                  <div className="start-input w-100">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimeField"]}>
                        <TimeField
                          label="Start Time"
                          value={
                            trainingObj.wld_startTime
                              ? dayjs(
                                  `${todayDate}${trainingObj.wld_startTime}`
                                )
                              : dayjs("2022-04-17T00:00")
                          }
                          onChange={(newValue) => {
                            let time = dayjs(newValue).format("HH:mm");
                            handleTimeChange(time, "wld_startTime");
                          }}
                          onInput={(e) => {
                            let error = checkNull(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              wld_startTime: error,
                            }));
                          }}
                          format="HH:mm"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  {error.wld_startTime.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "8px" }}>
                        {error.wld_startTime.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>
                <Col md={4} style={{position:"relative", top:"8px"}}>
                  <div className="g-input w-100">
                    <input
                      type="text"
                      id="wld_duration"
                      name="wld_duration"
                      placeholder=" "
                      style={{ width: "268px"}}
                      maxLength ="1"
                      className={error.wld_duration.status ? "err-input" : ""}
                      value={trainingObj.wld_duration}
                      onChange={handleChange}
                      onInput={(e) => {
                        let error = isNumberRange(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          wld_duration: error,
                        }));
                      }}
                    />
                    <label for="wld_duration">Duration(hrs)</label>
                  </div>
                  {error.wld_duration.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "8px" }}>
                        {error.wld_duration.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "2rem",  position:"relative", top:"15px" }}>
                <Col md={4}>
                  <div className="g-input w-100">
                    <input
                      style={{ width: "268px"}}
                      readOnly
                      type="text"
                      id="wld_endTime"
                      name="wld_endTime"
                      placeholder=" "
                      value={
                        action === "Edit"
                          ? trainingObj.wld_endTime
                          : calculateEndTime()
                      }
                    />
                    <label for="wld_endTime">End Time</label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="g-input w-100">
                    <input
                      style={{ width: "274px" }}
                      readOnly
                      type="text"
                      id="wld_trainingMonth"
                      name="wld_trainingMonth"
                      placeholder=" "
                      value={
                        action === "Edit"
                          ? trainingObj.wld_trainingMonth
                          : calculateMonth()
                      }
                    />
                    <label for="wld_trainingMonth">Month of Training</label>
                  </div>
                </Col>
              </Row>
              <Row style={{height:"55px"}}>
                <Col md={4}>
                  <div className="m-input">
                    <span>Faculty Type</span>
                    <div className="d-flex mb-2">
                      <div>
                        <input
                          type="radio"
                          name="wld_facultyType"
                          value="internal"
                          checked={facultyType === "internal"}
                          onChange={(e) => handleRadioChange(e)}
                        />
                        <span
                          className={
                            facultyType === "internal"
                              ? "fontWeight ms-2"
                              : "ms-2"
                          }
                        >
                          Internal
                        </span>
                      </div>
                      <div className="ms-5">
                        <input
                          type="radio"
                          name="wld_facultyType"
                          value="external"
                          checked={facultyType === "external"}
                          onChange={(e) => handleRadioChange(e)}
                        />
                        <span
                          className={
                            facultyType === "external"
                              ? "fontWeight ms-2"
                              : "ms-2"
                          }
                        >
                          External
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  {facultyType === "internal" ? (
                    <Form.Group>
                      <div className="d-flex align-items-center">
                        <div className="g-input w-100">
                          <input
                           style={{ width: "274px" }}

                            readOnly
                            id="wld_faculty"
                            name="wld_faculty"
                            type="text"
                            className={
                              error.wld_faculty.status ? "err-input" : ""
                            }
                            placeholder=" "
                            color="var(--col-707070)"
                            value={trainingObj.wld_faculty}
                          />
                          <label for="wld_faculty" style={{position:"absolute"}}>Faculty Name</label>
                          <BiSelectMultiple
                            size={20}
                            onClick={() => {
                              showPopUp(
                                "PED User",
                                "Single",
                                "emailid",
                                trainingObj.SK
                              );
                            }}
                            color={"gray"}
                            style={{
                              marginLeft: "11.5rem",
                              marginTop: "-4.2rem",
                              position:"relative",
                              left:"45px"
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  ) : (
                    <div className="g-input w-100">
                      <input
                        type="text"
                        id="wld_faculty"
                        name="wld_faculty"
                        style={{ width: "274px" }}
                        placeholder=" "
                        maxLength="50"
                        className={error.wld_faculty.status ? "err-input" : ""}
                        value={trainingObj.wld_faculty}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpaceDotDash(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_faculty: error,
                          }));
                        }}
                      />
                      <label for="wld_faculty" style={{position:"absolute"}}>Faculty Name</label>
                    </div>
                  )}
                  {error.wld_faculty.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "30px" }}>
                        {error.wld_faculty.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>

      <RenderIf isTrue={popUp.type === "Single" && showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>

      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditTrainingMaster;
