import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Temp_Save from "../../icons/Temp-Save.svg";
import copy from "../../icons/copy.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";
import Export from "../../icons/Export.svg";
import Close from "../../icons/Close.svg";


export default (props) => {
    console.log("ppppppppp",props.selectedData.data.WI_Slip_Status)
  return (
    <div className="cell-action">
        {props.selectedData.data.WI_Slip_Status === "Cancelled" ? "" : 
        <>
      <img
        onMouseOver={(e) => (e.currentTarget.src = Close)}
        onMouseOut={(e) => (e.currentTarget.src = Close)}
        src={Close}
        alt="cancel"
        style={{position:"relative",bottom:"8px"}}
        onClick={() => props.handleCancelEvent(props.selectedData)}
      />
  
        <img
        src={Delete_gray}
        onMouseOver={(e) => (e.currentTarget.src = Delete)}
        onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
        alt="delete"
        className="p-1"
        style={{position:"relative",bottom:"8px"}}
        onClick={() => props.handleDeleteEvent(props.selectedData)}
      />
      
      <img
        onMouseOver={(e) => (e.currentTarget.src = Export)}
        onMouseOut={(e) => (e.currentTarget.src = Export)}
        src={Export}
        style={{position:"relative",bottom:"8px"}}
        alt="Export"
        onClick={() => props.handlePdfEvent(props.selectedData)}
      />
      </>
}
    </div>
  );
};
  