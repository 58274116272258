import axios from "axios";



if (process.env.REACT_APP_NODE_ENV === "local") {
  var MASTER_API_URL = process.env.REACT_APP_TRAINING_API_URL;
}
else{
  var MASTER_API_URL = process.env.REACT_APP_API_URL + "training";
}

export async function getAllTraining(type) {
  console.log("ttyyeee",type)
    const response = await axios
      .get(MASTER_API_URL + `/get/${type}`)
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response.data;
  }

  export async function getCompletedTraining() {
    const response = await axios
      .get(MASTER_API_URL + "/get/Completed")
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response.data;
  }
export const AddNewTraining = async (training) => {
    let url = `${MASTER_API_URL}/post`;
    const response = await axios
      .post(url, training)
      .then(function (resp) {
        return resp;
      })
      .catch(function (error) {
        return { message: "Unable to find request", status: false };
      });
    return response.data;
  };

export const UpdateTraining = async (training) => {
  
    let url = `${MASTER_API_URL}/update/${training.SK}`;
    console.log("Yog",training);
    const response = await axios
        .post(url, training)
        .then(function (resp) {
        return resp;
        })
        .catch(function (error) {
        return { message: "Unable to find request", status: false };
        });
    return response.data;
};

export const updateTrainingScore = async (training) => {
  let url = `${MASTER_API_URL}/updatescore`;
  console.log("ritu",training);
  const response = await axios
      .post(url, training)
      .then(function (resp) {
      return resp;
      })
      .catch(function (error) {
      return { message: "Unable to find request", status: false };
      });
  return response.data;
}; 

export const DeleteTraining = async (training) => {
  let url = `${MASTER_API_URL}/delete`;
  const resp = await axios
    .post(url,training)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}