import { Button, Modal, Row, Col, Container } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import Edit from "../../icons/EditIcon.svg";
import Delete from "../../icons/DeleteIcon.svg";
import Scrollbar from "smooth-scrollbar";
import { AgGridReact } from "ag-grid-react";
import {
  getAllRoles,
  UpdateRole
} from "../../Services/role-service";
import { handleGlobalSearch1 } from "../utility/GolbalSearch";
import {
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from "react";
import AddEditRoleMaster from "./AddEditRoleMaster";
import GolbalSearch from "../utility/GolbalSearch";
import {
  FilterChange,
  resetAllFilter,
  resetFilter,
} from "../../functions/welding-functions";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import { useNavigate, withRouter } from "react-router-dom";
import { handleExport1 } from "../utility/GlobalExport";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import correctsign from "../../icons/correctsign.svg";
import wrongsign from "../../icons/wrongsign.svg";

import GridMaster from "../utility/GridMaster";
import ActionRenderer from "../utility/ActionRenderer";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import Export from "../../icons/Export.svg";

const RoleMaster = forwardRef((props, ref) => {
  const gridRef = useRef();
  const [roles, setRoles] = useState(null);
  const [filteredResult, setFilteredResult] = useState([]);
  const [globalText, setGlobalText] = useState("");
  const [locObj, setLocObj] = useState({
    rm_status: "",
    SK: "",
    rm_rolename: "",
    rm_menuaccess: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [rowData, setRowData] = useState({});
  const [UserRoleCode, setUserRoleCode] = useState(false);
  const [reload, setReload] = useState(false);
  const [filteredCol, setFilteredCol] = useState([]);
  const [data, setdata] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [show1, setShow1] = useState(false);
  const [msg, setMsg] = useState("");
  const [columnDefs, setcolumnDefs] = useState([]);

  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);
  const childRef = useRef();

  let formName = "Role And Authorization";
  console.log("test");
  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "rm_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "statusRenderer",
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: "Role Code",
      field: "SK",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Role Description",
      field: "rm_name",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "List of session",
      field: "rm_role_session",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];
  console.log("test1");
  const fetchRoleMaster = async () => {
    console.log("test3");
    const result1 = await getAllRoles();
    console.log(result1);
    setRoles(result1);
    setFilteredResult(result1);
  };

  useEffect(() => {
    
    console.log("useeffc");
    fetchRoleMaster();
    fetchColumnDefns();
  }, [reload]);

  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };
  const fetchColumnDefns = async () => {
    console.log("column def");
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ field: "", cellRenderer: actionRenderer, width: 250 ,  suppressMenu: true,
    sortable:false,});
    console.log(result);
    setcolumnDefs(result);
  };

  const handleDelete = async (row) => {
    let result;
    let indication;
    data.rm_status = "Inactive";
    UpdateRole(data)
      .then((resp) => {
        if (action === "Delete") {
          setShowForm(false);
          toast.success(`Selected Role has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        } else {
          toast.warning("Error!!!, Not able to deactivate Role.", {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        console.log("Unable to process request");
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  const resetForm = () => {
    document.filterForm.reset();
    setLocObj({
      rm_status: "",
      SK: "",
      rm_name: "",
    });
    setFilteredResult(roles);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  const contains = (target, lookingFor) => {
    return target && target.indexOf(lookingFor) >= 0;
  };

  const handleGlobalSearch = (text) => {
    
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);

    // if (searchText) {
    //   let result = handleGlobalSearch1(
    //     text,
    //     filteredResult,
    //     "SK",
    //     "rm_name",
    //     "sessions"
    //   );
    //   setFilteredResult(result);
    // } else {
    //   setFilteredResult(roles);
    // }
  };

  const handleGlobalExport = () => {
    let filename = "RoleMaster";
    setExcelData(filteredResult);
    let result = handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const defaultColDef = useMemo(() => {
    console.log("called times10...");
    return {
      flex: 1,
      suppressMovable: true,
      //minWidth: 170,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  const history = useNavigate();
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setUser",
    });
  };
  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      handleAdd: async () => {

        setAction("Add");
        setShowForm(true);
        //setReload(true);
      },
    }),
    []
  );

  return (
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        //message={`Great! You’ve Deleted Role and Authorization successfully.`}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Roles list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for Role Code, Role Description,List of Sessions"
              }
              filterFunc={handleGlobalSearch}
            />
            {/* <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
        paginationPageSize={7}
        pagination={true}
        height={500}
      />

      {showForm && (
        <AddEditRoleMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          roles={roles}
          setReload={setReload}
          reload={reload}
          resetForm={resetForm}
          onHide={() => setShowForm(false)}
          formName={formName}
        />
      )}

      <Toaster />
    </Container>
  );
});

export default RoleMaster;
