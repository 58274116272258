import { useState, useEffect, useRef } from "react";
import { Button, Form, Modal, Row, Col, Alert } from "react-bootstrap";
import { BiSelectMultiple } from "react-icons/bi";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import SingleValuePopUp from "../utility/SingleValuePopUp";
import { AddNewUser, UpdateUser } from "../../Services/user-service";
import { RenderIf } from "../../functions/welding-functions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  isAlphanumericWithSpace,
  isAlphanumericWithDot,
  isNumber,
  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import "../../Assests/Css/user.css";
import { statusChange } from "../utility/FormControl";
import { getPresignedURL } from "../../Services/Welder-services";
import axios from "axios";
import defaultimg from "../../icons/user.png";
import ButtonModal from "../Modals/buttonModal";
import Delete from "../../icons/DeleteIcon.svg";
import { getRoleDescByRoleId } from "../../Services/role-service";

const AddEditUserMaster = (props) => {
  if (props.rowdata.um_status === "Active") {
    props.rowdata.um_status = true;
  } else if (props.rowdata.um_status === "Inactive") {
    props.rowdata.um_status = false;
  }
  let action = props.action;
  let formName = "User";
  let folderName = "wld_signatures";

  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [profile, setProfile] = useState("");
  const [showForm, setShowForm] = useState(false);
  const avatar = { defaultimg };
  const [previewUrl, setPreviewUrl] = useState("");
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });

  const [userObj, setuserObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          um_status: true,
          um_name: "",
          um_role: "",
          um_email: "",
          um_contactNumber: "",
          um_location: "",
          createdby: "",
          PK: "",
          SK: "",
          wld_profile: "",
          um_empSalCode: "",
        }
  );

  console.log("userObj_65", userObj);

  const handleCloseConf = () => {
    setShow1(false);
    // props.setReload(!props.reload);
  };

  const [error, setError] = useState({
    um_status: {},
    SK: {},
    um_name: {},
    um_email: {},
    um_contactNumber: {},
    um_role: {},
    um_location: {},
  });

  const validateInput = () => {
    let result = false;
    if (action === "Add") {
      result = props.allRecs.find(
        (item) =>
          item.um_email.toLowerCase() === userObj.um_email.toLowerCase() ||
          item.PK.toLowerCase() === userObj.PK.toLowerCase()
      );
      if (result) {
        setAlertMessage("User with same User Id");
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const validate = async () => {
    let isError = false;
    let role;
    // var regExp = /^[A-Za-z0-9\s,:;.@#$&()*<>%/_]+$/;

    role = await getRoleDescByRoleId(userObj.um_role)
      .then(function (role) {
        console.log("L1", role);

        return role;
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("rrrr", role);
    // role = Promise.all(result)
    //console.log("rrrrr",role)
    if (
      !userObj.um_contactNumber.match("[0-9]{10}") &
      !userObj.um_contactNumber.match("[0-9]{10}")
    ) {
      isError = true;
      error.um_contactNumber = {
        status: true,
        message: "Add valid contact number (eg: 8888888888/ 10 digits allowed)",
      };
    }
    if (userObj.SK === "") {
      isError = true;
      error.SK = {
        status: true,
        message: "User ID is required",
      };
    }
    if (userObj.um_name === "") {
      isError = true;
      error.um_name = {
        status: true,
        message: "User name is required",
      };
    }
    if (userObj.um_location === "") {
      isError = true;
      error.um_location = {
        status: true,
        message: "User Location is required ",
      };
    }
    if (userObj.um_role === "") {
      isError = true;
      error.um_role = {
        status: true,
        message: "User role required",
      };
    }
    if (
      role != undefined &&
      role[0] != undefined &&
      role[0].rm_status == "Inactive"
    ) {
      isError = true;
      error.um_role = {
        status: true,
        message: "User role Inactive",
      };
    }
    if (userObj.um_email === "") {
      isError = true;
      error.um_email = {
        status: true,
        message: "Email Address is required",
      };
    }
    if (userObj.um_contactNumber === "") {
      isError = true;
      error.um_contactNumber = {
        status: true,
        message: "Official mobile no. is required",
      };
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    if (
      error.um_email.status ||
      error.um_contactNumber.status ||
      error.um_role.status ||
      error.um_name.status ||
      error.SK.status
    )
      isError = true;

    return isError;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setuserObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setError((prevState) => ({
      ...prevState,
      um_role: {},
      SK: {},
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setuserObj((prevState) => ({
      ...prevState,
      um_status: e.target.checked,
    }));
  };

  const resetForm = (e) => {
    if (action === "Add") {
      setuserObj((prevState) => ({
        ...prevState,
        SK: "",
        um_status: true,
        um_name: "",
        um_role: "",
        um_email: "",
        um_contactNumber: "",
        um_location: "",
        um_band: "",
        um_empSalCode: "",
      }));
      setError((prevState) => ({
        ...prevState,
        SK: {},
        um_name: {},
        um_email: {},
        um_contactNumber: {},
        um_role: {},
        um_location: {},
      }));
    } else if (action === "Edit") {
      setuserObj((prevState) => ({
        ...prevState,
        SK: props.rowdata.SK,
        um_status: props.rowdata.um_status,
        um_name: props.rowdata.um_name,
        um_role: props.rowdata.um_role,
        um_email: props.rowdata.um_email,
        um_contactNumber: props.rowdata.um_contactNumber,
        um_location: props.rowdata.um_location,
        um_empSalCode: props.rowdata.um_empSalCode,
      }));
    }
  };

  const handleAdd = async (e) => {
    let result;
    if (userObj.um_status === true) {
      userObj.um_status = "Active";
    } else {
      userObj.um_status = "Inactive";
    }

    if (action === "Add") {
      
      userObj.createdby = sessionStorage.getItem("UserId");
      userObj.PK = "UserMaster";
      //  userObj.SK = userObj.um_email;
      console.log("userObj_bforadd", userObj);
      result = AddNewUser(userObj);
    } else {
      result = UpdateUser(userObj);
    }
    result
      .then(async (resp) => {
        if (userObj.wld_profile !== "") {
          console.log("insideUrl", userObj.wld_profile);
          const result = await getPresignedURL(folderName, userObj.wld_profile);
          console.log("dddssss",result);
          const posturl = result.url;
          setPreviewUrl(posturl);
          console.log("img", posturl);
          await axios.put(posturl, profile);
          // console.log("putt",posturl,fileInput.files[0])
          console.log("puttprofile", profile);
        }
        if (resp.message) {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Unable to process request");
        toast.warning(`User Add/Update Failed:`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      });
    // .finally(() => {
    //   props.setReload(!props.reload);
    //   setShowForm(false);
    //   props.onHide();
    // });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(await validate())) {
      if (validateInput()) {
        if (action === "Add") {
          setShow1(true);
        } else {
          setShow1(true);
        }
      }
    }
  };

  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = (data) => {
    console.log("Popup", data);
    if (data.name === "Location") {
      setuserObj((prevState) => ({
        ...prevState,
        um_location: data.result.SK,
      }));
    }
    if (data.name === "User Role") {
      console.log("User_Role_Popup", data);
      setuserObj((prevState) => ({
        ...prevState,
        um_role: data.result.SK,
      }));
    }
    if (data.name === "PED User") {
      console.log("PEDdata", data);
      setuserObj((prevState) => ({
        ...prevState,
        SK:
          data.result.empEmailId === undefined
            ? ""
            : data.result.empEmailId.split("@")[0],
        um_name: data.result.empshortName,
        um_email: data.result.empEmailId,
        um_band: data.result.empBand,
        um_empSalCode: data.result.empSalCode,
      }));
      console.log("userObj_PED", userObj);
    }

    if (action === "Edit") {
      if (data.name === "User Role") {
        setuserObj((prevState) => ({
          ...prevState,
          um_role: data.result.SK,
        }));
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const fileInputRef = useRef(null);

  const handleFile = (file) => {
    console.log("FILEEe", file);

    const maxSize = 1 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
      file = null;
      return;
    }

    let uploadextension = file.name.split(".");

    if (
      uploadextension[1] !== "jpeg" &&
      uploadextension[1] !== "png" &&
      uploadextension[1] !== "jpg"
    ) {
      alert("Please Upload Jpeg,png or jpg images");
      file = null;
      return;
    }

    setProfile(file);
    setPreviewUrl(URL.createObjectURL(file));
    userObj.wld_profile = file.name;
  };

  const fetchUserProfiles = async () => {
    try {
      if (userObj.wld_profile !== "") {
        console.log("getimage", userObj.wld_profile);
        const result = await getPresignedURL(folderName, userObj.wld_profile);
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl(geturl);
        console.log("img", geturl);
        //await axios.get(geturl)
      }
    } catch (error) {
      if (error.request) {
        console.log("hii", error.request);
      } else {
        console.log("hiiiii", `Error: ${error.message}`);
      }
    }
  };
  useEffect(() => {
    fetchUserProfiles();
  }, []);

  const handleOndrop = (event) => {
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    console.log("drop", imageFile);
  };

  return (
    <>
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={show1}
        handleClose={handleCloseConf}
      />
      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || show || show1
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col>
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <div class="g-input">
                        <input
                        style={{width:"278px", height:"40px"}}
                          readOnly={true}
                          id="SK"
                          name="SK"
                          type="text"
                          maxLength="100"
                          disabled={action === "Edit" ? true : false}
                          className={
                            error.SK.status
                              ? "placeHolderInput inputText text-field-with-error"
                              : "placeHolderInput inputText"
                          }
                          placeholder=" "
                          color="var(--col-707070)"
                          value={userObj.SK}
                          onChange={handleChange}
                          onInput={(e) => {
                            let error = isAlphanumericWithDot(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              SK: error,
                            }));
                          }}
                        />
                        <label class="required-field" for="SK">
                          User ID(EMail ID)
                        </label>
                        <BiSelectMultiple
                          className={action === "Edit" ? "d-none" : ""}
                          size={20}
                          onClick={() => {
                            showPopUp(
                              "PED User",
                              "Single",
                              "emailid",
                              userObj.SK
                            );
                            setError((prevState) => ({
                              ...prevState,
                              SK: {},
                              um_name: {},
                              um_email: {},
                              um_contactNumber: {},
                            }));
                          }}
                          color={"gray"}
                          style={{
                            marginLeft: "15.5rem",
                            marginTop: "-4.2rem",
                          }}
                        />
                      </div>
                    </div>
                    {error.SK.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "30px" }}>
                          {" "}
                          {error.SK.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <div class="g-input">
                        <input
                                                style={{width:"278px", height:"40px"}}

                          readOnly={true}
                          name="um_name"
                          id="um_name"
                          type="text"
                          maxLength="100"
                          disabled={action === "Edit" ? true : false}
                          color="var(--col-707070)"
                          className={
                            error.um_name.status
                              ? "placeHolderInput inputText text-field-with-error"
                              : "placeHolderInput inputText"
                          }
                          placeholder=" "
                          value={userObj.um_name}
                          onChange={handleChange}
                          onInput={(e) => {
                            let error = isAlphanumericWithSpace(e.target.value);
                            setError((prevState) => ({
                              ...prevState,
                              um_name: error,
                            }));
                          }}
                        />
                        <label class="required-field" for="um_name">
                          Name of the person
                        </label>
                      </div>
                    </div>
                    {error.um_name.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "8px" }}>
                          {error.um_name.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <div className="d-flex align-items-center">
                      <div class="g-input">
                        <input
                                                style={{width:"278px", height:"40px"}}

                          readOnly
                          name="um_role"
                          id="um_role"
                          type="text"
                          className={
                            error.um_role.status
                              ? "placeHolderInput inputText text-field-with-error"
                              : "placeHolderInput inputText"
                          }
                          placeholder=" "
                          value={userObj.um_role}
                          onChange={handleChange}
                        />
                        <label class="required-field" for="um_role">
                          Role
                        </label>
                        <BiSelectMultiple
                          size={20}
                          onClick={() => {
                            showPopUp(
                              "User Role",
                              "Single",
                              "SK",
                              userObj.um_role
                            );
                            setError((prevState) => ({
                              ...prevState,
                              um_role: {},
                            }));
                          }}
                          color={"gray"}
                          style={{
                            marginLeft: "15.5rem",
                            marginTop: "-4.2rem",
                          }}
                        />
                      </div>
                    </div>
                    {error.um_role.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "30px" }}>
                          {error.um_role.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="gx-3" style={{position:"relative", bottom:"40px"}}> 
                <Col>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        className={
                          error.um_location.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        style={{width:"278px", height:"40px"}}

                        name="um_location"
                        id="um_location"
                        type="text"
                        maxLength="10"
                        placeholder=" "
                        value={userObj.um_location}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericAllSpecialCharacters(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            um_location: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="um_location">
                        Location
                      </label>
                    </div>
                    {error.um_location.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "8px" }}>
                          {error.um_location.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <div class="g-input">
                      <input
                                              style={{width:"278px", height:"40px"}}

                        className={
                          error.um_contactNumber.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        name="um_contactNumber"
                        id="um_contactNumber"
                        type="tel"
                        maxLength="10"
                        placeholder=" "
                        value={userObj.um_contactNumber}
                        onChange={handleChange}
                        // onInput={(e) => {
                        //   let error = isNumber(e.target.value);
                        //   setError((prevState) => ({
                        //     ...prevState,
                        //     um_contactNumber: error,
                        //   }));
                        // }}
                      />
                      <label class="required-field" for="um_contactNumber">
                        Contact Number
                      </label>
                    </div>
                    {error.um_contactNumber.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "8px" }}>
                          {error.um_contactNumber.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        <Form.Label>Status</Form.Label>{" "}
                      </Col>
                      <Col style={{ paddingTop: "0.5rem", position:"relative", right:"80px" }}>
                        <Form.Switch
                          label={userObj.um_status ? "Active" : "Inactive"}
                          className={userObj.um_status ? "green" : "gray"}
                          name="um_status"
                          checked={userObj.um_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <br></br>
              <Row style={{position:"relative", bottom:"40px"}}>
                <Col md={4} sm={2}>
                  <Form.Group>
                    <h6>Signature Image</h6>
                    <form encType="multipart/form-data">
                      {userObj.wld_profile !== "" || previewUrl !== "" ? (
                        <>
                          <div className="userpic_upload">
                            <img
                              src={previewUrl}
                              style={{
                                position: "relative",
                                left: "0px",
                                width: "100%",
                              }}
                              className="WPS-circler"
                            />
                            <img
                              src={Delete}
                              style={{
                                position: "relative",
                                top: "15px",
                                height: "30px",
                              }}
                              className="WPS-circlee"
                              onClick={() => {
                                setuserObj((prevState) => {
                                  return { ...prevState, wld_profile: "" };
                                });
                                setPreviewUrl("");
                                // window.location.reload()
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="userpic_upload">
                          <div
                            className="drop_zone"
                            onDragOver={handleOndragOver}
                            onDrop={handleOndrop}
                            // onClick={() => fileInputimg.current.click()}
                          >
                            <label
                              className="userpic_text_black"
                              for="fileInput"
                            >
                              Upload image here or
                            </label>{" "}
                            <label
                              className="userpic_text_blue"
                              for="fileInput"
                            >
                              Select a file
                            </label>
                            <br></br>
                            <label className="pic_text1" >
                              Supports: Jpeg, PNG and JPG, Size:Max 1MB
                            </label>
                            <br></br>
                            <label className="imgtext">
                              {userObj.wld_profile}
                            </label>
                            <input
                              id="fileInput"
                              name="fileInput"
                              type="file"
                              accept="image/*"
                              hidden
                              ref={fileInputRef}
                              onChange={(e) => handleFile(e.target.files[0])}
                            />
                          </div>
                        </div>
                      )}
                    </form>
                    &nbsp;&nbsp;
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>
          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>

      <RenderIf isTrue={popUp.type === "Single" && showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>

      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditUserMaster;
