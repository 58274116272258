import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "users";



export async function getAllMenu() {
  let url = `${API_URL}/menu`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getUserMenu(role,device) {
  console.log("getuserrle", sessionStorage.getItem("token"));
  
  let url = `${API_URL}/menu/getmenu`;
  const response = await axios
    .post(url,{Role:role,Device:device})
    .then(function (resp) {
      
      console.log(resp);
      return resp;
    })
    .catch(function (error) {
      
      console.log(error);
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}



export function getMenuDetails(getmenudata, pathname) {
  //console.log("getmenudata1",pathname)
  if (pathname == "/")
    pathname = "/dashboard"
  if (getmenudata) {
   // console.log("getmenudata1",JSON.parse(getmenudata))
    var menudata = JSON.parse(getmenudata)
   // console.log("getmenudata2",menudata,getmenudata)
    let screen = menudata.filter((i) => i.menu_path === pathname);
    if (screen[0]) {
    //  console.log("getmenudata1",screen[0])
      return {
        title: screen[0].menu_name,
        button: screen[0].menu_button,
        buttontext: screen[0].menu_button_name,
        currentMenu: screen[0].menu_group,
        path: pathname,
       // component: screen[0].menu_component,
        heading: screen[0].menu_heading && screen[0].menu_name,
      };
    } 
      

    
    }
  }

