import {
  getbyprojectno,
  getbyprojectnoWPS,
  getbywpsno,
  checkValidWPS,
  getWPSbyJoint,
} from "../../../Services/wps_groove-services";

// import { getbyprojectno,getbyprojectnoWPS,getbywpsno ,checkValidWPS} from "../../../Services/wps_groove-services";
export const GrooveType = ["Groove", "Tubesheet", "Overlay"];

export const checkProcessAdded = (detailObj) => {
  
  var norows = true;

  for (var i = 0; i < 3; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;

    if (
      detailObj[i].WPSD_Process !== "" &&
      detailObj[i].WPSD_Process !== "NA"
    ) {
      norows = false;

      if (detailObj[i].WPSD_FM_FNo === "") {
        detailObj[i].errormessage = "FNo is required";
        detailObj[i].error = true;
        // norows = true;
      }

      if (detailObj[i].WPSD_Type === "") {
        detailObj[i].errormessage = "Type is required";
        detailObj[i].error = true;
        norows = true;
      }

      if (
        detailObj[i].wld_WPSType === "Overlay" &&
        detailObj[i].WPSH_WeldLayer === ""
      ) {
        detailObj[i].errormessage = "Weld Layer is required";
        detailObj[i].error = true;
        // norows = true;
      }
    }

    // norows = false;
  }
  return norows;
};

export const checkduplicateProcess = (detailObj) => {
  var isError = false;
  const unique = detailObj.filter(
    (obj, index) =>
      detailObj.findIndex(
        (item) =>
          item.WPSD_Process + "#" + item.WPSD_Type ===
          obj.WPSD_Process + "#" + obj.WPSD_Type
      ) !== index
  );
  console.log("unique", unique);
  if (
    unique.length >= 1 &&
    unique[0].WPSD_Process != "" &&
    unique[0].WPSD_Process != "NA"
  ) {
    detailObj[0].errormessage =
      "Process '" +
      unique[0].WPSD_Process +
      "' and Type '" +
      unique[0].WPSD_Type +
      "' is  Duplicate";
    isError = true;
    detailObj[0].error = true;
  } else {
    if (!detailObj[0].error) {
      detailObj[0].errormessage = "";
      // isError = false;
      detailObj[0].error = false;
    }
  }
  return isError;
};

export const checkFirstPageValidation = (
  radio,
  ANo,
  ChemicalAnalysis_TradeName,
  Classification,
  FNo,
  SFA,
  errObj,
  seterrobj
) => {
  let result = true
  if (
    radio == "Required" &&
    (ANo === "" ||
      ChemicalAnalysis_TradeName === "" ||
      Classification === "" ||
      FNo === "" ||
      SFA === "" ||
      ANo === undefined ||
      ChemicalAnalysis_TradeName === undefined ||
      Classification === undefined ||
      FNo === undefined ||
      SFA === undefined)
  ) {
    console.log("hello33", errObj, "all");
    seterrobj((result) => [
      ...result,
      {
        id: "all",
        msg: "All fields are Mandatory",
        className: "border radioInput error",
      },
    ]);
  } else {
   
    seterrobj((result) => result.filter((i) => i.id !== "all"));
    
    result= false
    console.log("hello34", result, "all");
  }
  return result
};

// export const validate1stpage = (entries,headerObj,key,isError,error,message,tru)=>{

//     if (headerObj[key] === "") {
//       isError = {isError}
//       error[key] = {
//         status: true,
//         message: `This field is required`,
//       };
//     }

// }

/* export const validateGroove = async (
  entries1,
  entries2,
  entries3,
  error,
  setError,
  headerObj,
  detailObj,
  detaildata,
  entries4
) => {
  // let entries = Object.entries(restObj,detaildata);
  

  entries1 = Object.entries(WPSPage1 || {});
  entries2 = Object.entries(detaildata || {});
  entries3 = Object.entries(WPSPage3_Overlay || {});
  entries4 = Object.entries(Tubesheet_WPSPage1 || {});

  //  else if(WPStype === "Overlay"){
  //    entries1 =Object.entries(WPSPage1_Overlay);
  //    entries2 = Object.entries(detaildata);
  //   entries3 = Object.entries(WPSPage3_Overlay);
  //  }
  
  let isError = false;

  entries1.map(([key, val]) => {
    if (headerObj[key] === "") {
      isError = true;
      error[key] = {
        status: true,
        message: `This field is required`,
      };
    }
  });

  entries3.map(([key, val]) => {
    if (headerObj[key] === "") {
      isError = true;
      error[key] = {
        status: true,
        message: `This field is required`,
      };
    }
  });

  entries4.map(([key, val]) => {
    if (headerObj[key] === "") {
      isError = true;
      error[key] = {
        status: true,
        message: `This field is required`,
      };
    }
  });

  WPS_Groove_Detail.map((cols) => {
    console.log("entry", cols.Column, cols.Type);

    isError = false;
    entries2.map(([key, val]) => {
      var value = "";
      var msg = "";

      if (cols.Type === "S") {
        value = detailObj[key][cols.Column];
        msg = `This field is required`;
        //  isError = true
        // isError =false
      } else if (cols.Type === "A") {
        value = detailObj[key][cols.Column][0];
        msg = `At least one field is required`;
      }

      if (value === undefined || value === "" || value === null) {
        isError = true;

        setError((prevState) => ({
          ...prevState,
          [cols.Column]: {
            status: true,
            message: msg,
          },
        }));
      }
    });

    if (!isError) {
      setError((prevState) => ({
        ...prevState,
        ...error,
        [cols.Column]: {
          status: false,
          message: "",
        },
      }));
    }
  });

  setError((prevState) => ({
    ...prevState,
    ...error,
  }));

  console.log("error", isError);

  return isError;
}; */

//Tubesheet validation Started

// export const checkTubeProcessAdded = (detailObj) => {

//   console.log("checkTubeProcessAdded", detailObj);
//   var norows = true;

//   detailObj.errormessage = "";
//   detailObj.error = false;

//   if (detailObj.WPSD_Process !== "" && detailObj.WPSD_Process !== "NA") {
//     norows = false;

//     if (detailObj.WPSD_FM_FNo === "") {
//       detailObj.errormessage = "FNo is required";
//       detailObj.error = true;
//       // norows = true;
//     }

//     if (detailObj.WPSD_FM_SFA === "") {
//       detailObj.errormessage = "WPSD_FM_SFA is required";
//       detailObj.error = true;
//       norows = true;
//     }
//   }

//   // norows = false;
// };

export const checkTubeProcessAdded = (detailObj) => {
  // console.log("checkTubeProcessAdded", detailObj);
  // console.log("checkTubeProcessAdded[0]", detailObj[0]);

  
  var norows = true;

  for (var i = 0; i < 1; i++) {
    detailObj[i].errormessage = "";
    detailObj[i].error = false;
    

    if (
      detailObj[i].WPSD_Process !== "" &&
      detailObj[i].WPSD_Process !== "NA"
    ) {
      norows = false;

      if (detailObj[i].WPSD_FM_FNo === "") {
        detailObj[i].errormessage = "FNo is required";
        detailObj[i].error = true;
        // norows = true;
      }

      if (detailObj[i].WPSD_FM_SFA === "") {
        

        detailObj[i].errormessage = "SFA is required";
        detailObj[i].error = true;
        norows = true;
      }
    }
  }
  return norows;
};
export const isDuplicate = async (proj, wpsno) => {
  let results = [],
    checkarray = [];
  for (var groove of GrooveType) {
    let result = getbyprojectno(groove, proj, wpsno).catch((error) => {
      console.log("Error isDuplicate", error);
    });

    console.log("isDuplicate", result);
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  console.log("result_checkDuplicate", checkarray, checkarray.length);
  if (checkarray.length == 0) {
    console.log("result_checkDuplicate", false);
    return false;
  } else {
    console.log("result_checkDuplicate", true);
    return true;
  }
};

export const getWPS = async (proj) => {
  let results = [],
    checkarray = [];
  for (var groove of GrooveType) {
    console.log("export_const_getWPS", groove);
    let result = getbyprojectnoWPS(groove, proj).catch((error) => {
      console.log("Error isDuplicate", error);
    });

    console.log("isDuplicate", result);
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  console.log("result_checkDuplicate", checkarray, checkarray.length);
  return checkarray;
};

export const getWPSbyWPSNo = async (groove, wpsno) => {
  let results = [],
    checkarray = [];
  for (var groove of GrooveType) {
    let result = getbywpsno(groove, wpsno).catch((error) => {
      console.log("Error getWPSbyWPSNo", error);
    });

    console.log("getWPSbyWPSNo", result);
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  console.log("getWPSbyWPSNo", checkarray, checkarray.length);
  return checkarray;
};

export const checkWPSinArray = async (wpsno) => {
  
  let results = [],
    checkarray = [];

  for (var groove of GrooveType) {
    let groovetype = { GrooveType: groove };
    let request = { wpsno, groovetype };
    console.log("checkWPSinArray", request);
    let result = checkValidWPS(request).catch((error) => {
      console.log("Error getWPSbyWPSNo", error);
    });

    console.log("getWPSbyWPSNo", result);
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    if (check != undefined) checkarray = [...checkarray, ...check];
  }
  console.log("getWPSbyWPSNo", checkarray, checkarray.length);
  return Promise.resolve(checkarray);
};
//   console.log("getWPSbyWPSNo", checkarray, checkarray.length);
//   return checkarray;
// };

export const checkWPS = async (proj) => {
  console.log("proj_checkWPS", proj);
  console.log("proj_checkWPS_1", proj.WPSNo_Array);

  console.log("proj_checkWPS_2", proj.project);

  let results = [],
    checkarray = [];
  let reqData = [];
  for (var groove of GrooveType) {
    console.log("nowwww", groove);
    reqData.push({
      grooveType: groove,
      WPSNo_Array: proj.WPSNo_Array,
      project: proj.project,
    });
    console.log("reqData--", reqData);
    //let reqData = { groove, proj };
    let result = checkValidWPS(reqData).catch((error) => {
      console.log("Error isDuplicate", error);
    });

    console.log("isDuplicate", result);
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  console.log("result_checkDuplicate", checkarray, checkarray.length);
  return checkarray;
};

export const getWPSonType = async () => {
  let results = [],
    checkarray = [];
  for (var jointType of GrooveType) {
    console.log("export_const_getWPSonType", jointType);
    let result = getWPSbyJoint(jointType).catch((error) => {
      console.log("Error isDuplicate", error);
    });

    console.log("isDuplicate", result);
    if (result != undefined) results.push(result);
  }
  for (let result of results) {
    let check = await Promise.resolve(result);
    checkarray = [...checkarray, ...check];
  }
  console.log("result_checkDuplicate", checkarray, checkarray.length);
  return checkarray;
};
