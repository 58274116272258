import React, { useEffect, useState } from "react";
import "./Css/groovePQR_pdf.css";
import { Modal } from "react-bootstrap";
import { jsPDF } from "jspdf";
import { getPresignedURL } from "../../../Services/Welder-services";
import BarLoader from "react-spinners/BarLoader";

import Groove_pdf_page1PQR from "./Pages/Groove_pdf_page1PQR";
import Groove_pdf_page2PQR from "./Pages/Groove_pdf_page2PQR";
import Groove_pdf_page2 from "./Pages/Groove_pdf_page2";
import { getdetailsById } from "../../../Services/pqr-details-service";
import { getbyprojectno } from "../../../Services/pqr-header-service";
import PQRTubeToTubesheet_pdf_page1 from "./Pages/PQRTubeToTubesheet_pdf_page1";
import PQRTubeToTubesheet_pdf_page2 from "./Pages/PQRTubeToTubesheet_pdf_page2";
import PQRTubeToTubesheet_pdf_page3 from "./Pages/PQRTubeToTubesheet_pdf_page3";
import { useLocation, useNavigate } from "react-router-dom";
import Jspdf_font from "../../../Assests/font/jsPdf_font";

const Pdf_DownloadPQR = (props, wld) => {
  console.log("InPropsRow", props.rowdata);
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);

  const [PreviewUrl, setPreviewUrl] = useState([]);
  const [previewUrl1, setPreviewUrl1] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  const [previewUrl3, setPreviewUrl3] = useState();



  const [previewUrlgroove1, setpreviewUrlgroove1] = useState();
  const [previewUrlgroove2, setpreviewUrlgroove2] = useState();
  const [pdf, setpdf] = useState([]);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");
  let userprofile = sessionStorage.getItem("UserProfile");

  let folderwld = "wld_signatures";
  let folderwps = "WPS_Groove";
  let folderpqr = "PQR_GrooveOverlay";
  let folderpqrtube = "PQR_Tubesheet";

  const location = useLocation();
  const [WPStype, setWPStype] = useState(location.state?.WPStype);

  console.log("userprofile", userprofile);

  console.log("pdpdpdpd", pdf);
  console.log("headerData", pdf[0]);
  console.log("PK-SKdata", pdf[0]?.PK, pdf[0]?.SK); // header data
  console.log("PK-SK-1", pdf[1]?.PK, pdf[1]?.SK); //detail data
  console.log("signature previewapproved",previewapproved);
  console.log("signature previewpreviewuserapproved",previewuser);

  const fetchbyid = async (IddetailPK, IddetailSK) => {
    
    console.log("dddddddddddd_fetchbyid", IddetailPK, IddetailSK);
    let reqDetailParam = {
      id1: props.rowdata.wld_WPSType,
      id2: IddetailSK,
    };
    const result = await getdetailsById(reqDetailParam);
    console.log("dddddddddddd_detail", IddetailPK, IddetailSK);
    let reqParam = {
      groovetype: props.rowdata.wld_WPSType,
      pkid: IddetailPK,
      skid: IddetailSK,
    };
    const pqrhead = await getbyprojectno(reqParam);
    // const pqrhead = await getbyprojectno(props.PQRtype, IddetailPK, IddetailSK);
    console.log("dddddddddddd2", result, pqrhead);

    setpdf([...pqrhead, ...result]); //... means clone/duplicate
  };

  const createPDF = () => {
    fetchbyid(props?.rowdata?.PK, props?.rowdata?.PQR_RecordNo);
  };

  useEffect(() => {
    createPDF();
  }, []);

  const fetchimages = async (item) => {
    console.log("Imagessssss", item);
    if (item.value !== "" && item.value !== undefined) {
      getPresignedURL(folderwps, item.value)
        .then(async (result) => {
          const geturl = result.url;
          console.log("geturl", geturl);
          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
        })
        .catch((e) => console.log("error", e));
    }
  };

  const fetchimagespdf = async () => {
    try {
      console.log("Imagessssss", props?.rowdata?.Pdf_images);
      if (props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0] !== "") {
        console.log(
          "getimage",
          props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0]
        );
        const result = await getPresignedURL(
          folderpqrtube,
          props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0]
        );
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl1(geturl);
        console.log("img", geturl);
      }

      if (props?.rowdata?.Pdf_images[1].tube_Hole[0] !== "") {
        console.log("getimage", props?.rowdata?.Pdf_images[1].tube_Hole[0]);
        const result = await getPresignedURL(
          folderpqrtube,
          props?.rowdata?.Pdf_images[1].tube_Hole[0]
        );
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl2(geturl);
        console.log("img", geturl);
      }

      if (props?.rowdata?.Pdf_images[2].tube_Weld_Joint[0] !== "") {
        console.log("getimage", props?.rowdata?.Pdf_images[2].tube_Weld_Joint[0]);
        const result = await getPresignedURL(
          folderpqrtube,
          props?.rowdata?.Pdf_images[2].tube_Weld_Joint[0]
        );
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl3(geturl);
        console.log("img", geturl);
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchimagespdfgroove = async () => {
    try {
      console.log("Imagessssss", props?.rowdata?.Pdf_images);
      if (props?.rowdata?.Pdf_images[0].pqr_image1[0] !== "") {
        console.log(
          "getimage",
          props?.rowdata?.Pdf_images[0].pqr_image1[0]
        );
        const result = await getPresignedURL(
          folderpqr,
          props?.rowdata?.Pdf_images[0].pqr_image1[0]
        );
        console.log(result.url);
        const geturl = result.url;
        setpreviewUrlgroove1(geturl);
        console.log("img", geturl);
      }

      if (props?.rowdata?.Pdf_images[1].pqr_image2[0] !== "") {
        console.log("getimage", props?.rowdata?.Pdf_images[1].pqr_image2[0]);
        const result = await getPresignedURL(
          folderpqr,
          props?.rowdata?.Pdf_images[1].pqr_image2[0]
        );
        console.log(result.url);
        const geturl = result.url;
        setpreviewUrlgroove2(geturl);
        console.log("img", geturl);
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchapproveUserProfileswld = async () => {
    try {
      console.log("sign", props.rowdata);

      if (
        props?.rowdata?.PQR_SignaturePrepared !== "" ||
        props?.rowdata?.PQR_SignaturePrepared !== undefined
      ) {
        console.log("getimage", props?.rowdata?.PQR_SignaturePrepared);
        const result = await getPresignedURL(
          folderwld,
          props?.rowdata?.PQR_SignaturePrepared
        );
        console.log("ress", result.url, props.rowdata);
        const geturl = result.url;
        setPreviewuser(geturl);
        console.log("img user", previewuser);
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchapproveUserProfileswldapproved = async () => {
    try {
      console.log("sign", props.rowdata);

      if (
        props?.rowdata?.PQR_SignatureApproved !== "" ||
        props?.rowdata?.PQR_SignatureApproved !== undefined
      ) {
        console.log("getimagess", props?.rowdata?.PQR_SignatureApproved);

        const result = await getPresignedURL(
          folderwld,
          props?.rowdata?.PQR_SignatureApproved
        );
        console.log("ress", result.url, props?.rowdata?.PQR_SignatureApproved);
        const geturl = result.url;
        setPreviewapproved(geturl);
        console.log("img app", geturl);
        // await axios.get(geturl);
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  }; 
  const downloadPdf = () => {
    setTimeout(() => {
      let doc = new jsPDF("p", "pt", [595.28, 1020]);
      doc = Jspdf_font(doc)
      let pdfjs = document.querySelector("#MainDiv");
      doc.html(pdfjs, {
        callback: function (doc) {
          console.log("font", doc.getFontList());
          var Filename = `PQR Report ${pdf[0].wld_WPSType}`;
          doc.save(Filename);
          props.onHide();
          props.setReload(!props.reload);
          setLoading(false);

          doc.setFontSize(10);
        },
        margin: [-25, 10, 10, 10],
      });
      console.log("width", window.innerWidth);
    }, 9000);
  };

  useEffect(() => {
    fetchimagespdf();
    fetchimagespdfgroove()

    if (props?.rowdata?.wld_pdf_image) {
      for (let item of props?.rowdata?.wld_pdf_image) {
        fetchimages(item);
      }
    }
    fetchapproveUserProfileswld();
    fetchapproveUserProfileswldapproved();
    if (pdf.length > 0) {
      downloadPdf();
    }
  }, [pdf]);

  return (
    <>
      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}
          </p>
        </Modal.Body>
      </Modal>

      {pdf.length > 0 && (
        <div id="MainDiv">
          <br></br>
          {pdf[0]?.wld_WPSType === "Tubesheet" ? (
            <>
              <PQRTubeToTubesheet_pdf_page1
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                previewUrl1={previewUrl1}
                previewUrl2={previewUrl2}
                previewUrl3= {previewUrl3}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
              <PQRTubeToTubesheet_pdf_page2
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
               <div style={{backgroundColor:"none", height:"410px", position: "relative" ,top:"70px"}}></div>
             
              <PQRTubeToTubesheet_pdf_page3
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
            </>
          ) : (
            <>
              <Groove_pdf_page1PQR
                pdf={pdf}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
                previewUrl1={previewUrlgroove1}
                previewUrl2={previewUrlgroove2}
              />
                {pdf[0]?.wld_WPSType === "Overlay" ? (
 <div style={{backgroundColor:"none",height:"110px", position: "relative",top:"50px"}}></div>):(
 <div style={{backgroundColor:"none",height:"30px", position: "relative",top:"10px"}}></div>)}
               {pdf[4] && <Groove_pdf_page2
                pdf={pdf}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />}
            {pdf[4] && <> {pdf[0]?.wld_WPSType === "Overlay" ? (
 <div style={{height:"115px", position: "relative",top:"115px"}}></div>):<div style={{height:"54px", position: "relative",top:"54px"}}></div>}</>}
              <Groove_pdf_page2PQR
                pdf={pdf}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Pdf_DownloadPQR;
