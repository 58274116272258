import axios from "axios";
let REPAIR_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  REPAIR_API_URL = process.env.REACT_APP_WELD_REPAIR_API_URL;
} else {
  REPAIR_API_URL = process.env.REACT_APP_API_URL + "weldRepair/weldRepairAnalysis";
}

export async function getApprovedPendingRepairWorkflow(userObj) {
  let url = `${REPAIR_API_URL}/getApprovedPendingRepairWorkflow`;
  const response = await axios
    .post(url, userObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllRepairWorkflowForApproval(userrole) {
  let url = `${REPAIR_API_URL}/getAllRepairWorkflowForApproval/${encodeURIComponent(userrole)}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getRepairWorkflowProjectWise(projNumber) {
  let url = `${REPAIR_API_URL}/getRepairWorkflowProjectWise/${encodeURIComponent(projNumber)}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const addWeldingRepairAnalysis = async (weldingRepairObj) => {
  let url = `${REPAIR_API_URL}/addWeldingRepairAnalysis`;
  const response = await axios
    .post(url, weldingRepairObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export function sortByPropertyInDescending(property) {
  return function (a, b) {
    if (a[property] < b[property]) return 1;
    else if (a[property] > b[property]) return -1;
    return 0;
  };
}

export async function getClosureRepairWorkflow(tmpObj) {
  let url = `${REPAIR_API_URL}/getClosureRepairWorkflow`;
  const response = await axios
    .post(url, tmpObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function updateClosureRepairWorkflow(data) {
  console.log("updateNewTrainingrequest -- ", data);
  let url = `${REPAIR_API_URL}/updateClosureRepairWorkflow`;
  const response = await axios
    .post(url, data)

    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("updateTrainingRequest_response", response.data);
  return response.data;
}

export async function getProjectReqCount() {
  
  let url = `${REPAIR_API_URL}/projectreqcount`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getallApprovedCount(Year) {
  let keyObj = {
    year: Year,
  }
  let url = `${REPAIR_API_URL}/totalApprovedCount`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getProjectReqCountProjectWise(projObj) {
  let url = `${REPAIR_API_URL}/getprojectreqcountprojectwise`;
  const response = await axios
    .post(url, projObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

