import axios from "axios";
let MASTER_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getAllRecordsSfa() {
  const response = await axios
    .get(MASTER_API_URL + "/Sfa/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getActiveSfa() {
  const response = await axios
    .get(MASTER_API_URL + "/Sfa/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export const AddNewSfa = async (Sfa) => {
  let url = `${MASTER_API_URL}/Sfa`;
  const response = await axios
    .post(url, Sfa)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateSfa = async (Sfa) => {
  let url = `${MASTER_API_URL}/Sfa/update`;
  const response = await axios
    .post(url, Sfa)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteSfa(skid) {
  let url = `${MASTER_API_URL}/Sfa/delete`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
