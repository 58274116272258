//TABLE 3 WITH 3 ROWS AND 6 COLUMNS (BASE METAL THICKNESS)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import { isAlphanumeric } from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
export const Wpq_JointDetail_table1A = ({
  headerObj,
  error,
  isReadonly,
  handleChange,
  setError,
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
  label7,
  label8, //   wpq_process,
  readOnly,
  name,
  name1,
  name2,
  name3,
  maxLength,

  status,
  status1,
  status2,
  status3,
  status4,
  status5,
  status6,
  status7,

  handleActualValueChange,
  handleRangeQualifiedChange,
  message,
  message1,
  message2,
  message3,
  message4,
  message5,
  message6,
  message7,

  ColumnHeader,
  ColumnHeader1,
  ColumnHeader2,
  ColumnHeader3,

  onInput,
  onInput1,
  onInput2,
  onInput3,

}) => {
  return (
    <Table className="text-center ">
      <thead>
        <tr className="border thead-color ">
          <th className=" border thickness_title" style={{ width: "31%" }}></th>
          <th className="thickness_title" >Actual Value</th>
          <th className="thickness_title">Range Qualified</th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            status || status1
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status || status1
                ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader}
              {/* <span>mm</span> */}
              {(status || status1) && (
                <Tooltip content={message || message1}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name] &&
                headerObj[name].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                         // console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                position:"relative",
                                width:"142px",
                                left:"93px"
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              // maxLength={maxLength}
                              readOnly
                              // readOnly={readOnly}
                              value="Tube to Tubesheet"
                              // onChange={(e) =>
                              //   handleActualValueChange(
                              //     name,
                              //     id,
                              //     valueIndex,
                              //     e.target.value
                              //   )
                              // }
                              // onInput={onInput}
                             
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            {/* <div>
              {headerObj[name] &&
                headerObj[name].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                              }}
                              key={valueIndex}
                              name={name}
                              value="Tube to Tubesheet"
                              // readOnly={readOnly}
                              // maxLength={maxLength}
                              type="text"
                              // value={value}
                              // onChange={(e) =>
                              //   handleRangeQualifiedChange(
                              //     name,
                              //     id,
                              //     valueIndex,
                              //     e.target.value
                              //   )
                              // }
                              // onInput={onInput}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div> */}

            {/* </div>
)})} */}
          </td>
        </tr>

        <tr
          className={
            status2 || status3
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status2 || status3
                ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader1}
              {/* <span>mm</span> */}
              {(status2 || status3) && (
                <Tooltip content={message2 || message3}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name1] &&
                headerObj[name1].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          //console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"
                              }}
                              key={valueIndex}
                              type="text"
                              name={name1}
                              maxLength={maxLength}
                              readOnly={readOnly}
                              value={value}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name1,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput1}
                              // onInput={(e) => {
                              //   let error = isAlphanumericWithDashDot(e.target.value);

                              //   setError((prevState) => ({
                              //     ...prevState,
                              //     WPQ_FM_Classification: [
                              //       {
                              //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
                              //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
                              //       },
                              //       {
                              //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
                              //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
                              //       },
                              //     ],
                              //   }));
                              // }
                              // }
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name1] &&
                headerObj[name1].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              name={name1}
                              readOnly={readOnly}
                              maxLength={maxLength}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name1,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput1}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        <tr
          className={
            status4 || status5
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status4 || status5
              ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader2}
              {/* <span>mm</span> */}
              {(status4 || status5) && (
                <Tooltip content={message4 || message5}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name2] &&
                headerObj[name2].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
//console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          //console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              type="text"
                              name={name2}
                              maxLength={maxLength}
                              readOnly={readOnly}
                              value={value}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name2,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput2}
                              // onInput={(e) => {
                              //   let error = isAlphanumericWithDashDot(e.target.value);

                              //   setError((prevState) => ({
                              //     ...prevState,
                              //     WPQ_FM_Classification: [
                              //       {
                              //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
                              //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
                              //       },
                              //       {
                              //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
                              //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
                              //       },
                              //     ],
                              //   }));
                              // }
                              // }
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name2] &&
                headerObj[name2].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              name={name2}
                              readOnly={readOnly}
                              maxLength={maxLength}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name2,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput2}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        <tr
          className={
            status6 || status7
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status6 || status7
                ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader3}
              {/* <span>mm</span> */}
              {(status6 || status7) && (
                <Tooltip content={message6 || message7}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name3] &&
                headerObj[name3].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
//console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          //console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              type="text"
                              name={name3}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name3,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput3}
                              // onInput={(e) => {
                              //   let error = isAlphanumericWithDashDot(e.target.value);

                              //   setError((prevState) => ({
                              //     ...prevState,
                              //     WPQ_FM_Classification: [
                              //       {
                              //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
                              //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
                              //       },
                              //       {
                              //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
                              //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
                              //       },
                              //     ],
                              //   }));
                              // }
                              // }
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name3] &&
                headerObj[name3].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              maxLength={maxLength}
                              name={name3}
                              readOnly={readOnly}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name3,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput3}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        
        {/* <tr
    className={
      error.WPQ_BMT_BaseM_ActualV_Groove &&( error.WPQ_BMT_BaseM_ActualV_Groove.status ||
      error.WPQ_BMT_BaseM_ActualV_Fillet.status)
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={
        error.WPQ_BMT_BaseM_ActualV_Groove &&( error.WPQ_BMT_BaseM_ActualV_Groove.status ||
        error.WPQ_BMT_BaseM_ActualV_Fillet.status)
          ? "text-start error-head"
          : "text-start border thead-color"
      }
    >
      <div>
        <label>
          {label1}
        </label>
        {error.WPQ_BMT_BaseM_ActualV_Groove && (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
          error.WPQ_BMT_BaseM_ActualV_Fillet.status) && (
          <Tooltip
            content={
              error.WPQ_BMT_BaseM_ActualV_Groove.message ||
              error.WPQ_BMT_BaseM_ActualV_Fillet.message
            }
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td className="Thickness_data">
      <input
        type="text"
        className={
          error.WPQ_BMT_BaseM_ActualV_Groove &&( error.WPQ_BMT_BaseM_ActualV_Groove.status)
            ? "error-input"
            : ""
        }
        name={"WPQ_Joint_Type"}
        maxLength={8}
        value={headerObj.WPQ_Joint_Type.Actual_Value}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumeric(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPQ_BMT_BaseM_ActualV_Groove: error,
          }));
        }}
      />
    </td>
    <td>
      <input
        type="text"
        className={
          error.WPQ_BMT_BaseM_ActualV_Fillet && error.WPQ_BMT_BaseM_ActualV_Fillet.status
            ? "error-input"
            : ""
        }
        name={"WPQ_Hole_Id"}
        maxLength={8}
        value={headerObj.WPQ_BMT_BaseM_ActualV_Fillet}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        onInput={(e) => {
          let error = isAlphanumeric(e.target.value);
          setError((prevState) => ({
            ...prevState,
            WPQ_BMT_BaseM_ActualV_Fillet: error,
          }));
        }}
      />
    </td>

  </tr> */}

        {/* <tr
          className={
            error.WPQ_BMT_BaseM_ActualV_Groove &&
            (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
              error.WPQ_BMT_BaseM_ActualV_Fillet.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPQ_BMT_BaseM_ActualV_Groove &&
              (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
                error.WPQ_BMT_BaseM_ActualV_Fillet.status)
                ? "text-start error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>{label2}</label>
              {error.WPQ_BMT_BaseM_ActualV_Groove &&
                (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
                  error.WPQ_BMT_BaseM_ActualV_Fillet.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BMT_BaseM_ActualV_Groove.message ||
                      error.WPQ_BMT_BaseM_ActualV_Fillet.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="Thickness_data">
            <input
              type="text"
              className={
                error.WPQ_BMT_BaseM_ActualV_Groove &&
                error.WPQ_BMT_BaseM_ActualV_Groove.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_Tube_Pitch"}
              maxLength={8}
              value={headerObj.WPQ_BMT_BaseM_ActualV_Groove}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumeric(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_BaseM_ActualV_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPQ_BMT_BaseM_ActualV_Fillet &&
                error.WPQ_BMT_BaseM_ActualV_Fillet.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_Groove_Depth"}
              maxLength={8}
              value={headerObj.WPQ_BMT_BaseM_ActualV_Fillet}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumeric(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_BaseM_ActualV_Fillet: error,
                }));
              }}
            />
          </td>
        </tr>

        <tr
          className={
            error.WPQ_BMT_BaseM_ActualV_Groove &&
            (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
              error.WPQ_BMT_BaseM_ActualV_Fillet.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPQ_BMT_BaseM_ActualV_Groove &&
              (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
                error.WPQ_BMT_BaseM_ActualV_Fillet.status)
                ? "text-start error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>{label3}</label>
              {error.WPQ_BMT_BaseM_ActualV_Groove &&
                (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
                  error.WPQ_BMT_BaseM_ActualV_Fillet.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BMT_BaseM_ActualV_Groove.message ||
                      error.WPQ_BMT_BaseM_ActualV_Fillet.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="Thickness_data">
            <input
              type="text"
              className={
                error.WPQ_BMT_BaseM_ActualV_Groove &&
                error.WPQ_BMT_BaseM_ActualV_Groove.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_Tube_Projection"}
              maxLength={8}
              value={headerObj.WPQ_BMT_BaseM_ActualV_Groove}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumeric(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_BaseM_ActualV_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPQ_BMT_BaseM_ActualV_Fillet &&
                error.WPQ_BMT_BaseM_ActualV_Fillet.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_Width_Ligament"}
              maxLength={8}
              value={headerObj.WPQ_Width_Ligament}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumeric(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_BaseM_ActualV_Fillet: error,
                }));
              }}
            />
          </td>
        </tr>

        <tr
          className={
            error.WPQ_BMT_BaseM_ActualV_Groove &&
            (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
              error.WPQ_BMT_BaseM_ActualV_Fillet.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPQ_BMT_BaseM_ActualV_Groove &&
              (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
                error.WPQ_BMT_BaseM_ActualV_Fillet.status)
                ? "text-start error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>{label4}</label>
              {error.WPQ_BMT_BaseM_ActualV_Groove &&
                (error.WPQ_BMT_BaseM_ActualV_Groove.status ||
                  error.WPQ_BMT_BaseM_ActualV_Fillet.status) && (
                  <Tooltip
                    content={
                      error.WPQ_BMT_BaseM_ActualV_Groove.message ||
                      error.WPQ_BMT_BaseM_ActualV_Fillet.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="Thickness_data">
            <input
              type="text"
              className={
                error.WPQ_BMT_BaseM_ActualV_Groove &&
                error.WPQ_BMT_BaseM_ActualV_Groove.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_Pattern"}
              maxLength={8}
              value={headerObj.WPQ_Pattern}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumeric(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_BaseM_ActualV_Groove: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPQ_BMT_BaseM_ActualV_Fillet &&
                error.WPQ_BMT_BaseM_ActualV_Fillet.status
                  ? "error-input"
                  : ""
              }
              name={"WPQ_Groove_Angle"}
              maxLength={8}
              value={headerObj.WPQ_Groove_Angle}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumeric(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPQ_BMT_BaseM_ActualV_Fillet: error,
                }));
              }}
            />
          </td>
        </tr> */}
      </tbody>
    </Table>
  );
};

// export const Wpq_JointDetail_table1A = ({
//   wpq_process,
//   headerObj,
//   readOnly,
//   status,
//   status1,
//   handleActualValueChange,
//   name,
//   handleRangeQualifiedChange,
//   message,
//   message1,
//   ColumnHeader,
//   onInput,
//   setError,
//   error,
// }) => {

//   return (
//     <tr
//       className={
//         status || status1 ? "border WpsInput-md error" : "border WpsInput-md"
//       }
//     >
//       <th
//         className={
//           status || status1
//             ? "text-start thead-color"
//             : "text-start thead-color"
//         }
//       >
//         <div>
//           {ColumnHeader}
//           {/* <span>mm</span> */}
//           {(status || status1) && (
//             <Tooltip content={message || message1}>
//               <img src={Error} alt="Error" className="ms-1" />
//             </Tooltip>
//           )}
//         </div>
//       </th>
//       {/* Below code is working */}
//       <td>
//               <div>
//                 {headerObj[name] &&
//                   headerObj[name].map((element, id) => {
//                     //console.log("xxyyzz", element);
//                     return (
//                       <div key={id}>
//                         {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{
// console.log("abccc",a)
// return (
// <div key={i}> */}

//                         {element.Actual_Value &&
//                           element.Actual_Value.map((value, valueIndex) => {
//                             console.log("abcccc", element.Actual_Value);

//                             return (
//                               <input
//                                 style={{
//                                   marginRight: "10px",
//                                   marginLeft: "10px",
//                                 }}
//                                 key={valueIndex}
//                                 type="text"
//                                 name={name}
//                                 readOnly={readOnly}
//                                 value={value}
//                                 onChange={(e) =>
//                                   handleActualValueChange(
//                                     name,
//                                     id,
//                                     valueIndex,
//                                     e.target.value
//                                   )
//                                 }
//                                 onInput={onInput}
//                                 // onInput={(e) => {
//                                 //   let error = isAlphanumericWithDashDot(e.target.value);

//                                 //   setError((prevState) => ({
//                                 //     ...prevState,
//                                 //     WPQ_FM_Classification: [
//                                 //       {
//                                 //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
//                                 //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
//                                 //       },
//                                 //       {
//                                 //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
//                                 //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
//                                 //       },
//                                 //     ],
//                                 //   }));
//                                 // }
//                                 // }
//                               />
//                             );
//                           })}
//                       </div>
//                     );
//                   })}
//               </div>

//         {/* </div>
// )})} */}
//       </td>

// <td>
//               <div>
//                 {headerObj[name] &&
//                   headerObj[name].map((element, id) => {
//                     console.log("abcc", element);
//                     return (
//                       <div key={id}>
//                         {element.Range_Qualified &&
//                           element.Range_Qualified.map((value, valueIndex) => {
//                             console.log("abcccc", element.Range_Qualified);

//                             return (
//                               <input
//                                 style={{
//                                   marginRight: "10px",
//                                   marginLeft: "10px",
//                                 }}
//                                 key={valueIndex}
//                                 name={name}
//                                 readOnly={readOnly}
//                                 type="text"
//                                 value={value}
//                                 onChange={(e) =>
//                                   handleRangeQualifiedChange(
//                                     name,
//                                     id,
//                                     valueIndex,
//                                     e.target.value
//                                   )
//                                 }
//                                 onInput={onInput}
//                               />
//                             );
//                           })}
//                       </div>
//                     );
//                   })}
//               </div>

//         {/* </div>
// )})} */}
//       </td>
//     </tr>

//   );
// };


export const Wpq_JointDetail_table1B = ({
  headerObj,
  error,
  isReadonly,
  handleChange,
  setError,
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
  label7,
  label8, //   wpq_process,
  readOnly,
  name,
  name1,
  name2,
  name3,
  maxLength,

  status,
  status1,
  status2,
  status3,
  status4,
  status5,
  status6,
  status7,

  handleActualValueChange,
  handleRangeQualifiedChange,
  message,
  message1,
  message2,
  message3,
  message4,
  message5,
  message6,
  message7,

  ColumnHeader,
  ColumnHeader1,
  ColumnHeader2,
  ColumnHeader3,

  onInput,
  onInput1,
  onInput2,
  onInput3,

}) => {
  return (
    <Table className="text-center ">
      <thead>
        <tr className="border thead-color ">
          <th className=" border thickness_title" style={{ width: "31%" }}></th>
          <th className="thickness_title">Actual Value</th>
          <th className="thickness_title">Range Qualified</th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            status || status1
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status || status1
                ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader}
              {/* <span>mm</span> */}
              {(status || status1) && (
                <Tooltip content={message || message1}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name] &&
                headerObj[name].map((element, id) => {
                  console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              maxLength={maxLength}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput}
                            
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name] &&
                headerObj[name].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              name={name}
                              readOnly={readOnly}
                              maxLength={maxLength}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        <tr
          className={
            status2 || status3
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status2 || status3
                ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader1}
              {/* <span>mm</span> */}
              {(status2 || status3) && (
                <Tooltip content={message2 || message3}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name1] &&
                headerObj[name1].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
//console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          //console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              type="text"
                              name={name1}
                              readOnly={readOnly}
                              maxLength={maxLength}
                              value={value}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name1,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput1}
                             
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name1] &&
                headerObj[name1].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              name={name1}
                              maxLength={maxLength}
                              readOnly={readOnly}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name1,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput1}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        <tr
          className={
            status4 || status5
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status4 || status5
              ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader2}
              {/* <span>mm</span> */}
              {(status4 || status5) && (
                <Tooltip content={message4 || message5}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name2] &&
                headerObj[name2].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          //console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              maxLength={maxLength}
                              type="text"
                              name={name2}
                              readOnly={readOnly}
                              value={value}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name2,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput2}
                              
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name2] &&
                headerObj[name2].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              name={name2}
                              readOnly={readOnly}
                              maxLength={maxLength}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name2,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput2}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        <tr
          className={
            status6 || status7
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              status6 || status7
                ? "text-start thead-color"
                : "text-start thead-color"
            }
          >
            <div>
              {ColumnHeader3}
              {/* <span>mm</span> */}
              {(status6 || status7) && (
                <Tooltip content={message6 || message7}>
                  <img src={Error} alt="Error" className="ms-1" />
                </Tooltip>
              )}
            </div>
          </th>
          {/* Below code is working */}
          <td>
            <div>
              {headerObj[name3] &&
                headerObj[name3].map((element, id) => {
                  //console.log("xxyyzz", element);
                  return (
                    <div key={id}>
                      {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
//console.log("abccc",a)
return (
<div key={i}> */}

                      {element.Actual_Value &&
                        element.Actual_Value.map((value, valueIndex) => {
                          //console.log("abcccc", element.Actual_Value);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              type="text"
                              name={name3}
                              readOnly={readOnly}
                              maxLength={maxLength}
                              value={value}
                              onChange={(e) =>
                                handleActualValueChange(
                                  name3,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput3}
                              
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>

          <td>
            <div>
              {headerObj[name3] &&
                headerObj[name3].map((element, id) => {
                  //console.log("abcc", element);
                  return (
                    <div key={id}>
                      {element.Range_Qualified &&
                        element.Range_Qualified.map((value, valueIndex) => {
                          //console.log("abcccc", element.Range_Qualified);

                          return (
                            <input
                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"110px"

                              }}
                              key={valueIndex}
                              maxLength={maxLength}
                              name={name3}
                              readOnly={readOnly}
                              type="text"
                              value={value}
                              onChange={(e) =>
                                handleRangeQualifiedChange(
                                  name3,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput3}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

            {/* </div>
)})} */}
          </td>
        </tr>

        
  
      </tbody>
    </Table>
  );
};