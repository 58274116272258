import { NavLink } from "react-router-dom";
import downArrow from "../../icons/DownArrow.svg"
import uprrow from "../../icons/UpArrow.svg"
const NavItem = ({ item,children, counter, IsChildopen, onHide, toggle, currentMenu }) => {
  var count = counter;
  if (item.menu_children === undefined) count = 0;
 // console.log("children",children,item)
  return (

    <div name="menuItem" className="menu-group"  key={"MM" + item.menu_id}>
      <div className="menu-side-image">
      <img
        className="menu-icons"
        src={IsChildopen ? "/sidebar/" + item.menu_name + "_Active.svg" : "/sidebar/" + item.menu_name + "_Default.svg"}
        key={"IMG" + item.menu_id}  
        alt=""
      ></img>
      </div>
      <div className="menu-side-name">
      <div className="menu-items" id={"M" + item.menu_id}>
       
        <NavLink
          to={item.menu_path}
          className={IsChildopen ? "menu-activeitems" : "menu-items"}
          onClick={() => onHide(count, item.menu_path,children)}
        >
          {item.menu_name}
        </NavLink>
      </div>
       
      <div
        className="nav-children"
        style={{ display: IsChildopen ? "block" : "none" }}
        key={"Child" + item.menu_id + "_" + count}
      >
        {children !== undefined &&
          children.map((c) => (
            
            <div className="mb-2">
              <NavLink
                to={c.menu_path}
                className="link"
                onClick={() => toggle()}
              >
                {c.menu_name}
              </NavLink>
              <br />
            </div>
          ))}
      </div>
      </div>
      <div className="menu-toggle-open">
      {children.length > 0 ? <img src={IsChildopen ? uprrow :downArrow} className="menu-toggleicon" onClick={() => onHide(count, item.menu_path)}></img> :""}
      </div>
    </div>
   
  );
};

export default NavItem;
