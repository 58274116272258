import axios from "axios";



if (process.env.REACT_APP_NODE_ENV === "local") {
  var INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "PEDUsers/";
  var MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  var INTERFACE_URL = process.env.REACT_APP_API_URL + "interface/PEDUsers/";
  var MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getAllRecordsWeldingProcess() {
  const response = await axios
    .get(MASTER_API_URL + "/process/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getActiveProcess() {
  console.log("inside_getActiveProcess",MASTER_API_URL + "/process/active")
  const response = await axios
    .get(MASTER_API_URL + "/process/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export const AddNewWeldingProcess = async (WeldingProcess) => {
  let url = `${MASTER_API_URL}/process`;
  const response = await axios
    .post(url, WeldingProcess)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateWeldingProcess = async (WeldingProcess) => {
 
  let url = `${MASTER_API_URL}/process/update`;
  const response = await axios
    .post(url, WeldingProcess)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteWeldingProcess(skid) {
  let url = `${MASTER_API_URL}/process/delete`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
