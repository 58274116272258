import React, { useEffect, useState } from "react";
import "../Css/WPQgroove_pdf.css"

import Groove_pdf_headerWPQ from "./Groove_pdf_headerWPQ";

import PDFBaseMetalTable from "./PDFBaseMetalTable";


// import image from "../../../../icons/images.png";
// import PageFooter from "../../../WPSTransaction/PDF/Pages/PageFooter";
import PageFooter from "../../WPSObjects/PageFooter";


const Groove_pdf_page1WPQ = ({ pdf, setpdf, previewuser, previewapproved }, props, wld) => {

    
    const tablewidth = "100%"
    let action = props.action;

    var width = pdf[2] !== undefined ? (pdf[3] !== undefined ? "8.5%" : "13%"): "26%"
    function bindewldprocess(single,type,key) {
        
    
      //  let attributetoFetch = type;

       // console.log("InBind", attributetoFetch, pdf[1]?.attributetoFetch)
        let ArryMap = [];
        const rows = [];
        
       for(var index=1;index<=3;index++){
       console.log("Array1",pdf[index],index)
       console.log("NotDP",key,key !== "WPQ_FM_Weld_Deposit_Thickness")
        if(pdf[index] != undefined){
           var element = pdf[index]
         
          if(single)
           {
               // console.log("Actual Ran",element[key],key)
                if(type === "ACTUAL"){  
                  
                    element[key] && ArryMap.push(element[key][0]?.Actual_Value[0])
                    
                }
                else if(type === "ELEMENT")
                    ArryMap.push(element[key])
                else if (type === "POSITION")
                {
                    
                    const itemArray = []; 
                    for( let item of element[key][1]?.Range_Qualified){
                        
                        console.log("itemmm",item)
                        itemArray.push(item[0])
                   

                    }

                    console.log("itemarraayyy",itemArray)
                    const filteritemarray = itemArray.filter(value => value.trim() !== "")
                    // const result = ArryMap.join(",")
                    ArryMap.push(filteritemarray)
                    // element[key] && ArryMap.push(element[key][1]?.Range_Qualified[0])

                }
                else
                    element[key] && ArryMap.push(element[key][1]?.Range_Qualified[0])
                
           }
         else{ 
           
                try{
                    if (element[key]) {
                        
                        
                   
                        
                        let qualifiedValues
                        
                        if(key !== "WPQ_FM_Weld_Deposit_Thickness" ){  
                        element[key][1]?.Range_Qualified.map((value, key1) => {
                            
                            
                           console.log("RangeValue",value[0],key)
                            qualifiedValues = value[0];
                            
                        })
                        }
                        else{
                            
                            console.log("Deposit Thickness",key,element?.WPQ_FM_Weld_Deposit_Thickness[1]?.Range_Qualified[2].Range_Qualified2[0][0])
                            if(element?.WPQ_FM_Weld_Deposit_Thickness != undefined){
                            if(  element?.WPQ_FM_Weld_Deposit_Thickness[1]?.Range_Qualified[2].Range_Qualified2[0][2] === "Unchecked"){
                                ArryMap.push(element?.WPQ_FM_Weld_Deposit_Thickness[1]?.Range_Qualified[0].Range_Qualified1[0])
                            }
                            else{
                                ArryMap.push("No Limit")
                                // ArryMap.push(element?.WPQ_FM_Weld_Deposit_Thickness[1]?.Range_Qualified[2].Range_Qualified2[0][0])
                            }
                             }
                        }
                            // Filter out null values before joining
                            console.log("qualifiedValues",qualifiedValues)
                            if (qualifiedValues)
                                qualifiedValues = qualifiedValues?.filter(value => value !== "");
                            console.log("qualifiedValues1",qualifiedValues)
                            if (qualifiedValues && qualifiedValues.length > 0) {
                                console.log("qualifiedValues2",qualifiedValues)
                                let commaSeparatedValues = qualifiedValues.join(',');
                                ArryMap.push(commaSeparatedValues);
                                console.log("qualifiedValues3",qualifiedValues)
                            }
                       
                    }
                }
                catch(ex){
                    console.log("Error in mul",key)
                }

              
            }
        }
       
        }
        //console.log("ArrayTomap11",ArryMap, ArryMap[0], ArryMap[1], ArryMap[2])

       console.log("ErrorWhere",key,ArryMap,ArryMap.length)    
        try{  

        var count = ArryMap.length
   
        var divStyle= count == 3 ? "div3class" : count == 2 ? "div2class" : "div1class"
        
        var cssClass = count > 2 ? "divRightleftBorder "+ divStyle : "divLeftBorder "+divStyle
        
        console.log("ErrorWhere",key,ArryMap.length,count,cssClass)    
        // rows.push(
        //     <div className="WPQgroupcols" style={{width:"100%"}}>
           
        //         <div className={divStyle}>{ArryMap[0]}</div>
        //         {(count >= 2 )&&
        //         <div className={cssClass} >{ArryMap[1]}</div>
        //         }
        //        { (count == 3 )&&
        //         <div className={divStyle}>{ArryMap[2]}</div>
        //        }
               
        //         </div>
        // );
        rows.push(
            <div className="WPQgroupcols" style={{ width: "100%" }}>
                <div className={divStyle}>{String(ArryMap[0])}</div>
                {(count >= 2) &&
                    <div className={cssClass}>{String(ArryMap[1])}</div>
                }
                {(count === 3) &&
                    <div className={divStyle}>{String(ArryMap[2])}</div>
                }
            </div>
        );
        }
        catch(ex){
            console.log("ErrorInRow",key,ArryMap)
        }
      
        console.log("Rowwwww", rows)
        console.log("Arryryryryryry", ArryMap[0])
        return rows;
    }

    function Tests_Processes() {
        const testarray = pdf[0]?.testarray || [];
        const rows = [];

        for (let i = 0; i <= testarray.length-1; i++) {
            const item = testarray[i];
            if(item.WPQ_WP_TypeOfTest != undefined && item.WPQ_WP_TypeOfTest != ""){
            rows.push(
               
                <tr key={i}>
                    <td className="PdfNoTopBorder pdfLeftBorder ">{item.WPQ_WP_TypeOfTest}</td>
                    <td className="PdfNoTopBorder pdfLeftBorder ">{item.WPQ_WP_Acceptance_Criteria}</td>
                    <td className="PdfNoTopBorder pdfLeftBorder ">{item.WPQ_WP_Result}</td>
                    <td className="PdfNoTopBorder pdfLeftBorder ">{item.WPQ_WP_Comments}</td>
                    <td className="PdfNoTopBorder pdfLeftBorder pdfRightBorder" >{item.WPQ_WP_Report_Nummber}</td>
                </tr>
                
            );}
        }
console.log("testArray",testarray.length)
        return rows;
    }


  
    const styles = {
        container: {
            display: "flex",
        },
        group: {
            display: "flex",
        },
        title3: {
            width: "50%",
        }
    };




    return (
        <div className="WPQMainPDF">
         <div id="container" className="WPQflexb">
            <div className="WPQheaderRow">
            <Groove_pdf_headerWPQ pdf={pdf} from="WPQ" Page={1}></Groove_pdf_headerWPQ> 
            </div>
            <div className="WPQContent" >
            <PDFBaseMetalTable pdf={pdf} from="WPQ"></PDFBaseMetalTable>
            <table className="WPQTableHeading" style={{ width: "72%",paddingTop:"10px",position:"relative"}}>
                <tbody>
                    <tr style={{height:"15px"}}>
                        <td className="WPQbasemetal  alignleft" style={{ width:"21.5%"}}>
                            VARIABLES
                        </td>
                        <td className="pdfwpqBorderLeftRighTopRemoved WPQbasemetal" style={{width:"6px"}} >
                            Actual Values
                        </td>
                        <td className="WPQbasemetal aligncenter" style={{width:"6px"}}> 
                            Range Qualified
                        </td>
                    </tr>
                    </tbody></table>
                    <table className="pdfwpqTable" style={{ width: "72%",paddingTop:"5px",position:"relative"}}>
                    <tbody>
                    <tr  >
                        <th className="pdfLeftBorder pdfTopBorder"  style={{ width:"21.5%"}}>
                            Type of Weld Joint
                        </th>
                        <td className="pdfLeftRightTopBorder aligncenter"   style={{width:"6px"}}>
                            {pdf[0]?.WPQ_VAR_TypeofJoint_actualValue}
                        </td>
                        <td className="pdfRightBorder aligncenter pdfTopBorder" style={{width:"6px"}} >
                            {pdf[0]?.WPQ_VAR_TypeofJoint_RangeQualified}
                        </td>
                    </tr>
                    <tr >
                        <th className="pdfLeftBorder pdfBorderBottom">
                            Base Metal
                        </th>
                        <td className="pdfLeftBorder pdfRightBorder pdfBorderBottom aligncenter">
                            {pdf[0]?.WPQ_VAR_BaseM_actualValue}
                        </td>
                        <td className="pdfRightBorder pdfBorderBottom  aligncenter">
                            {pdf[0]?.WPQ_VAR_BaseM_RangeQualified}
                        </td>
                    </tr>
                </tbody>
            </table>
           
            <table  className="WPQbasemetal" style={{ width: "72%" }}>
                <tbody>
                    <tr  style={{height:"15px"}} >
                        <td className="WPQbasemetal pdfBorderBottom" style={{width:"16.5%"}}>
                            BASE METAL THICKNESS
                        </td>
                        <td  className="pdfBorderBottom" style={{ width: '30%' }}>
                            <td style={{ width: '6%' }} className="WPQbasemetal aligncenter" >Groove</td>
                            <td style={{ width: '6%' }} className="WPQbasemetal aligncenter" >Fillet</td>
                            <td style={{ width: '6%' }} className="WPQbasemetal aligncenter" >Overlay</td>
                        </td>
                        <td  className="pdfBorderBottom" style={{ width: '30%' }}>
                            <td style={{ width: '6%' }} className="WPQbasemetal aligncenter " >Groove</td>
                            <td style={{ width: '6%' }} className="WPQbasemetal aligncenter " >Fillet</td>
                            <td style={{ width: '6%' }} className="WPQbasemetal aligncenter " >Overlay</td>
                        </td>
                    </tr>
                    </tbody></table>
                <table id="table" className="pdfwpqTable" style={{ width: "72%" }}>
                <tbody>
                    <tr  >
                        <th style={{width:"16.5%"}} className="pdfLeftBorder pdfRightBorder" >
                            Plate Thickness {"       "} <div style={{float:"right"}}>mm</div>
                        </th>
                        <td id="table" className="pdfRightBorder" style={{ width: '30%' ,padding:"0"}}>
                            <td  style={{ width: '100px', wordBreak:"break-all" }} className="aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Plate_thickness_ActualV_Groove}</td>
                            <td style={{ width: '100px' , wordBreak:"break-all"}}  className="aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Plate_thickness_ActualV_Fillet}</td>
                            <td style={{ width: '100px' , wordBreak:"break-all"}}  className="aligncenter" >{pdf[0].WPQ_BMT_Plate_thickness_ActualV_Overlay}</td>
                        </td>
                        <td id="table" className="pdfRightBorder" style={{ width: '30%',padding:"0" }}>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}} className="aligncenter pdfwpqBorderRightRemoved" >{pdf[0].WPQ_BMT_Plate_thickness_RangeQ_Groove}</td>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}}  className="aligncenter pdfwpqBorderRightRemoved" >{pdf[0].WPQ_BMT_Plate_thickness_RangeQ_Fillet}</td>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}} className="aligncenter" >
                            {pdf[0]?.wld_WPSType === "Overlay" ? `${pdf[0].WPQ_BMT_Plate_thickness_RangeQ_Overlay  + "min"}`: pdf[0].WPQ_BMT_Plate_thickness_RangeQ_Overlay }</td>
                        </td>
                    </tr>
                    <tr  >
                        <th  className="pdfLeftBorder pdfRightBorder">
                            Pipe / Tube Thickness {"       "} <div style={{float:"right"}}>mm</div>
                        </th>
                        <td id="table" className="pdfRightBorder" style={{ width: '30%',padding:"0" }}>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}} className="aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Pipe_thickness_ActualV_Groove}</td>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}}className="aligncenter pdfwpqBorderRightRemoved"  >{pdf[0].WPQ_BMT_Pipe_thickness_ActualV_Fillet}</td>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}}  className="aligncenter" >{pdf[0].WPQ_BMT_Pipe_thickness_ActualV_Overlay}</td>
                        </td>
                        <td id="table" className="pdfRightBorder" style={{ width: '30%',padding:"0" }}>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}} className="aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Pipe_thickness_RangeQ_Groove}</td>
                            <td  style={{ width: '100px' , wordBreak:"break-all"}}className="aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Pipe_thickness_RangeQ_Fillet}</td>
                            <td style={{ width: '100px' , wordBreak:"break-all"}}  className="aligncenter" >
                            {pdf[0]?.wld_WPSType === "Overlay" ? `${pdf[0].WPQ_BMT_Pipe_thickness_RangeQ_Overlay  + "min"}`: pdf[0].WPQ_BMT_Pipe_thickness_RangeQ_Overlay }</td>
                        </td>
                    </tr>
                    <tr  >
                        <th style={{ width: "30%" }} className="pdfLeftBorder pdfRightBorder pdfBorderBottom">
                            Pipe Diameter {"       "} <div style={{float:"right"}}>mm</div>
                        </th>
                        <td  id="table" className="pdfRightBorder pdfBorderBottom"  style={{ width: '30%' ,padding:"0"}}>

                            <td style={{ width: '100px' }} className=" aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Pipe_Diameter_ActualV_Groove}</td>
                            <td style={{ width: '100px' }} className=" aligncenter pdfwpqBorderRightRemoved">{pdf[0].WPQ_BMT_Pipe_Diameter_ActualV_Fillet}</td>
                            <td style={{ width: '100px' }}  className="  aligncenter" >{pdf[0].WPQ_BMT_Pipe_Diameter_ActualV_Overlay}</td>
                        </td>
                        <td id="table" className="pdfRightBorder pdfBorderBottom" style={{ width: '30%' ,padding:"0"}} >

                            <td  style={{ width: '100px' }} className=" aligncenter pdfwpqBorderRightRemoved" >
                            {pdf[0]?.wld_WPSType === "Groove" ? `${pdf[0].WPQ_BMT_Pipe_Diameter_RangeQ_Groove  + " min"}`: pdf[0].WPQ_BMT_Pipe_Diameter_RangeQ_Groove }</td>
                            <td  style={{ width: '100px' }} className=" aligncenter pdfwpqBorderRightRemoved" >{pdf[0].WPQ_BMT_Pipe_Diameter_RangeQ_Fillet}</td>
                            <td  style={{ width: '100px' }} className=" aligncenter"  >
                            {pdf[0]?.wld_WPSType === "Overlay" ? `${pdf[0].WPQ_BMT_Pipe_Diameter_RangeQ_Overlay  + "min"}`: pdf[0].WPQ_BMT_Pipe_Diameter_RangeQ_Overlay }</td>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
            
            </table>
            <table className="WPQTableHeading" style={{ width: "72%",paddingTop:"10px",position:"relative"}}>
                <tbody>
                    <tr style={{height:"15px"}}>
                        <td className="WPQbasemetal  alignleft" style={{ width:"21.5%"}}>
                            VARIABLES
                        </td>
                        <td className="pdfwpqBorderLeftRighTopRemoved WPQbasemetal" style={{width:"20%"}} >
                            Actual Values
                        </td>
                        <td className="WPQbasemetal aligncenter" style={{width:"20%"}}> 
                            Range Qualified
                        </td>
                    </tr>
                    </tbody></table>
            <table className="WPQTable7" style={{ width: "72%" }}>
                <tbody>
                    <tr>
                        <th className="pdfwpqNoBorderBottom pdfTop">
                            Welding Process
                        </th>
                        <td className="pdfTop"> {bindewldprocess(true,"ELEMENT","WPQ_Process_Category")}</td>
                        <td className="pdfLeftBorder pdfRightBorder pdfTop" style={{width:"0.5%"}}></td>
                        <td className="pdfTop">{bindewldprocess(true,"ELEMENT","WPQ_Process_Category")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved" >
                        <th className="pdfwpqNoBorderBottom">
                            Type
                        </th>
                        <td> {bindewldprocess(true,"ELEMENT","WPQ_Type")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"ELEMENT","WPQ_Type")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved">
                        <th className="pdfwpqNoBorderBottom">
                            Backing
                        </th>
                        
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_Detail_Backing")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_Detail_Backing")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved">
                        <th className="pdfwpqNoBorderBottom" >
                            Filler Metal Specification
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Specification")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td> {bindewldprocess(true,"RANGE","WPQ_FM_Specification")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved">
                        <th className="processleft pdfwpqNoBorderBottom" >
                            Filler Metal Classification
                        </th>
                        <td>{bindewldprocess(true,"ACTUAL","WPQ_FM_Classification")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td> {bindewldprocess(true,"RANGE","WPQ_FM_Classification")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved">
                        <th className="processleft pdfwpqNoBorderBottom" >
                        Filler Wire With/Without
                        </th>
                        <td>{bindewldprocess(true,"ACTUAL","WPQ_FM_filler_Wire")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td> {bindewldprocess(true,"RANGE","WPQ_FM_filler_Wire")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved">
                        <th className="pdfwpqNoBorderBottom">
                            Filler Metal F-Number
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_F_No")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td> {bindewldprocess(true,"RANGE","WPQ_FM_F_No")}</td>
                    </tr>
                    <tr className="pdfwpqBorderRightBottomRemoved">
                        <th className="pdfwpqNoBorderBottom">
                        Arc Transfer Mode (FCAW/GMAW)
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Arc_transfermode")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Arc_transfermode")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            Consumable Insert
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Consumable_Insert")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Consumable_Insert")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            Filler Metal Product From (GTAW)
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Product_Form")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Product_Form")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            No Of Layer Deposited
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_No_of_LayerDeposit")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_No_of_LayerDeposit")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            Weld Deposit Thickness  <div className="WPQrcorner"> mm</div>
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Weld_Deposit_Thickness")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(false,"RANGE","WPQ_FM_Weld_Deposit_Thickness")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                        Thickness Limit {"("}S.Cir.Arc{")"}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Thickness_Limit")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Thickness_Limit")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            Welding Position {"("}Actual Position Tested{")"}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Welding_Position")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Welding_Position")}</td>
                    </tr>
                    
                    {pdf[0]?.wld_WPSType === "Groove" ?
                        <>
                            <tr className="pdfwpqNoBorderBottom" >
                                <th className="pdfwpqNoBorderBottom" style={{textAlign:"right"}}>
                                Groove - Plate & Pipe {'>'}610mm
                                    {/* <div style={{float:"right"}}>Groove - Plate & Pipe {'>'}610mm</div> */}
                                </th>
                                <td> {bindewldprocess(true,"ACTUAL","WPQ_Groove_610")}</td>
                                <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                                <td>{bindewldprocess(true,"POSITION","WPQ_Groove_610")}</td>
                            </tr>
                            <tr className="pdfwpqNoBorderBottom" >
                                <th className="pdfwpqNoBorderBottom" style={{textAlign:"right"}} >
                                Groove - Pipe 73mm to 610mm
                                {/* <div style={{float:"right"}}> {'Groove - Pipe 73mm to 610mm'}</div> */}
                                </th>
                                <td> {bindewldprocess(true,"ACTUAL","WPQ_Groove_73to610")}</td>
                                <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                                <td>{bindewldprocess(true,"POSITION","WPQ_Groove_73to610")}</td>
                            </tr>
                            <tr className="pdfwpqNoBorderBottom" >
                                <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}} >
                                {'Groove - Pipe < 73mm'} 
                                {/* <div style={{float:"right"}}>  {'Groove - Pipe < 73mm'} </div> */}
                                </th>
                                <td> {bindewldprocess(true,"ACTUAL","WPQ_Groove_73")}</td>
                                <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                                <td>{bindewldprocess(true,"POSITION","WPQ_Groove_73")}</td>
                            </tr>
                        </>
                        :
                        <>
                            <tr className="pdfwpqNoBorderBottom" >
                                <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}} >
                                {'Overlay - Plate & Pipe > 610mm'}
                                {/* <div style={{float:"right"}}>  {'Overlay - Plate & Pipe > 610mm'} </div> */}
                                </th>
                                <td> {bindewldprocess(true,"ACTUAL","WPQ_Overlay_610")}</td>
                                <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                                <td>{bindewldprocess(true,"POSITION","WPQ_Overlay_610")}</td>
                            </tr>
                            <tr className="pdfwpqNoBorderBottom" >
                                <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}}>
                                {'Overlay - Pipe 73mm to 610mm'}
                                {/* <div style={{float:"right"}}> {'Overlay - Pipe 73mm to 610mm'} </div> */}
                                </th>
                                <td> {bindewldprocess(true,"ACTUAL","WPQ_Overlay_73to610")}</td>
                                <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                                <td>{bindewldprocess(true,"POSITION","WPQ_Overlay_73to610")}</td>
                            </tr>
                            <tr className="pdfwpqNoBorderBottom" >
                                <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}}>
                                {'Overlay - Pipe < 73mm'} 
                                {/* <div style={{float:"right"}}>  {'Overlay - Pipe < 73mm'} </div> */}
                                </th>
                                <td> {bindewldprocess(true,"ACTUAL","WPQ_Overlay_73")}</td>
                                <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                                <td>{bindewldprocess(true,"POSITION","WPQ_Overlay_73")}</td>
                            </tr>
                        </>
                    }
                    {pdf[0]?.wld_WPSType === "Groove" &&
                    <>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}}>
                        {'Fillet - Plate & Pipe > 610mm'}
                        {/* <div style={{float:"right"}}>  {'Fillet - Plate & Pipe > 610mm'} </div> */}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_Fillet_610")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"POSITION","WPQ_Fillet_610")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}}>
                        {'Fillet - Pipe 73mm to 610mm'}
                        {/* <div style={{float:"right"}}>{'Fillet - Pipe 73mm to 610mm'}</div>     */}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_Fillet_73to610")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"POSITION","WPQ_Fillet_73to610")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom"  style={{textAlign:"right"}}>
                        {'Fillet - Pipe < 73mm'}
                        {/* <div style={{float:"right"}}>  {'Fillet - Pipe < 73mm'} </div> */}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_Fillet_73")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"POSITION","WPQ_Fillet_73")}</td>
                    </tr>
                   </> }
                    
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            {'Progression'}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Progression")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Progression")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            {'Backing Gas'}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Backing_Gas")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Backing_Gas")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            {'Welding current/polarity'}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Current_Polarity")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Current_Polarity")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            {'Direct/remote visual control (Machine)'}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Direct_VisualControl")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"ACTUAL","WPQ_FM_Direct_VisualControl")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            {'Automatic Joint Tracking (Machine)'}
                        </th>
                        <td> {bindewldprocess(true,"ACTUAL","WPQ_FM_Automatic_jointTracking")}</td>
                        <td className="pdfLeftBorder pdfRightBorder" style={{width:"0.5%"}}></td>
                        <td>{bindewldprocess(true,"RANGE","WPQ_FM_Automatic_jointTracking")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom" >
                            {'Single Or Multiple Pass Per Side'}
                        </th>
                        <td  style={{fontSize:"6"}} > {bindewldprocess(true,"ACTUAL","WPQ_FM_Single_Pass_PerSide")}</td>
                        <td  style={{fontSize:"6",width:"0.5%"}} className="pdfLeftBorder pdfRightBorder "></td>
                        <td  style={{fontSize:"6"}} >{bindewldprocess(true,"RANGE","WPQ_FM_Single_Pass_PerSide")}</td>
                    </tr>
                    <tr className="pdfwpqNoBorderBottom" >
                        <th className="pdfwpqNoBorderBottom pdfBorderBottom" >
                            {'Automatic AVC System (GTAW)'}
                        </th>
                        <td className="pdfBorderBottom"> {bindewldprocess(true,"ACTUAL","WPQ_FM_Automatic_AVC_System")}</td>
                        <td className="pdfLeftBorder pdfRightBorder pdfBorderBottom" style={{width:"0.5%"}} ></td>
                        <td className="pdfBorderBottom">{bindewldprocess(true,"RANGE","WPQ_FM_Automatic_AVC_System")}</td>
                    </tr>
                </tbody>
            </table>
            {/* ---------------------------TESTS------------------------------------------- */}
            <div className="WPQgroup" style={{width: "50%" }}>
                <div className="WPQbasemetal" >
                    TESTS
                </div>
            </div>
            <div>
                <div style={{ paddingTop: "1px" }}></div>
                <table id="table" className="WPQTable1" style={{ width: "72%" }}>
                    <tbody>
                        <tr>
                            <th  style={{ width: "25%", borderRight:"none"}}>
                                Type Of Tests
                            </th>
                            <th   style={{ width: "20%" , borderRight:"none"}}>
                                Acceptance Criteria
                            </th>
                            <th  style={{ width: "12%" , borderRight:"none"}}>
                                Result
                            </th>
                            <th   style={{ width: "30%",borderRight:"none" }}>
                                Comment
                            </th >
                            <th   style={{ width: "20%" }}>
                                Report Number
                            </th >
                        </tr>
                    
                        {Tests_Processes()}
                    </tbody>
                </table>
               
                <table id="table" className="pdfwpqTable" style={{ width: "72%" }}>
                    <tr ><th style={{  padding: "1px 4px", width: "4%" }} className="pdfLeftBorder pdfTopBottom pdfRightBorder">
                        Notes
                    </th>
                        <td  style={{  padding: "1px 4px", width: "50%" }} className="pdfTopBottom pdfRightBorder">
                            {pdf[0]?.WPQ_Notes}
                        </td>
                    </tr>
                </table>
            </div>
            {/* ---------------------------CERTIFICATION-------------------------------- */}
            <div className="WPQgroup">
                <div className="WPQbasemetal" style={{ width: "90%" }}>
                    CERTIFICATION
                </div>
            </div>
            <table id="table" className="WPQTable2" style={{ width: "72%" }}>
                <tr >
                    <th style={{ width: "13%"}} >
                        Test Conducted By
                    </th>
                    <td style={{ width: "24%" }} className="pdfTopBottom">
                        {pdf[0]?.WPQ_Certi_test_conductedby}
                    </td>
                    <th style={{ width: "12%"}} >
                        Mechnical Test By
                    </th>
                    <td style={{ width: "24%" }} className="pdfTopBottomRight">
                        {pdf[0]?.WPQ_Certi_Mechanical_Tesstby}
                    </td>
                </tr>
                <tr>
                    <th style={{ width: "13%",borderTop:"none" }} className="pdfBottomBorder">
                        RT Film Evaluated By
                    </th>
                    <td colspan="3" style={{ width: "15%" }} className="pdfBottomBorder pdfRightBorder ">
                        {pdf[0]?.WPQ_Certi_RT_Film_Rvaluatedby}
                    </td>
                    {/* <th style={{ width: "25%"}}>
                        --
                    </th>
                    <td style={{ width: "15%"}} className="pdfBottomRight">
                        --
                    </td> */}
                </tr>
            </table>
            
            <p className="WPQcertifont" >We certify that the statement in this record are correct and that the test welds were prepared, welded and tested in the accordance with the requirements of Section IX of the ASME code </p>
            <br></br><br></br>
            </div>
              
         </div>
 
         <div style={{position:"relative",top:"50px"}}>
      <PageFooter pdf={pdf} previewuser={previewuser} previewapproved={previewapproved}></PageFooter>
      </div>
      <br></br>      <br></br>

        <div className="WPSfooterRow"  style={{position:"relative",top:"60px"}}>
          
          <table className="WPSfooter" >
          <tr>
            <td>Page 1 of 1</td>
          </tr>
        </table>
      </div>

      
        </div>
        
    )
}
export default Groove_pdf_page1WPQ