import { borderBottom, padding } from "@mui/system";
import React, { useEffect, useState } from "react";
import "../Css/groovePQR_pdf.css";
import PageFooter from "../../../WPSTransaction/PDF/Pages/PageFooter";
import PQR_Footer from "./PQR_Footer";
import TTS_pdf_headerPQRpage2 from "./TTS_pdf_headerPQRpage2";

const PQRTubeToTubesheet_pdf_page2 = (
  { pdf, setpdf, PreviewUrl, previewuser, previewapproved },
  props,
  wld
) => {
  let action = props.action;

  const width = "72.6%";
 

  var cols = 3,
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class;

  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };


  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";
 
  col1Class = "left7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "left7-td"
        : "left7-td-noright"
      : "tdhidden";
  col3Class = pdf[3] !== undefined ? "left7-td-noright1" : "tdhidden";

  function Max_ProcessesTB1() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const macroExamination = pdf[i]?.Macro_Examination || [];
      const table1Data = macroExamination[0]?.Table6 || [];

      for (let j = 0; j < table1Data.length; j++) {
        const item = table1Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MEX_Tube_No !== "" ? item.PQR_MEX_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocA !== "" ? item.PQR_MEX_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocB !== "" ? item.PQR_MEX_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocC !== "" ? item.PQR_MEX_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocD !== "" ? item.PQR_MEX_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Max_ProcessesTB2() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const macroExamination = pdf[i]?.Macro_Examination || [];
      const table2Data = macroExamination[0]?.Table7 || [];

      for (let j = 0; j < table2Data.length; j++) {
        const item = table2Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MEX_Tube_No !== "" ? item.PQR_MEX_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocA !== "" ? item.PQR_MEX_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocB !== "" ? item.PQR_MEX_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocC !== "" ? item.PQR_MEX_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocD !== "" ? item.PQR_MEX_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Max_ProcessesTB3() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const macroExamination = pdf[i]?.Macro_Examination || [];
      const table3Data = macroExamination[0]?.Table8 || [];

      for (let j = 0; j < table3Data.length; j++) {
        const item = table3Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MEX_Tube_No !== "" ? item.PQR_MEX_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocA !== "" ? item.PQR_MEX_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocB !== "" ? item.PQR_MEX_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocC !== "" ? item.PQR_MEX_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocD !== "" ? item.PQR_MEX_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Max_ProcessesTB4() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const macroExamination = pdf[i]?.Macro_Examination || [];
      const table4Data = macroExamination[0]?.Table9 || [];

      for (let j = 0; j < table4Data.length; j++) {
        const item = table4Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MEX_Tube_No !== "" ? item.PQR_MEX_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocA !== "" ? item.PQR_MEX_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocB !== "" ? item.PQR_MEX_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocC !== "" ? item.PQR_MEX_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_LocD !== "" ? item.PQR_MEX_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Max_ProcessesTB5() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const macroExamination = pdf[i]?.Macro_Examination || [];
      const table5Data = macroExamination[0]?.Table10 || [];

      for (let j = 0; j < table5Data.length; j++) {
        const item = table5Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MEX_Magnification !== ""
                ? item.PQR_MEX_Magnification
                : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_Acceptance_Criteria !== ""
                ? item.PQR_MEX_Acceptance_Criteria
                : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MEX_Observation !== ""
                ? item.PQR_MEX_Observation
                : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  // ------------------------------------------------------------------------------------//

  function Min_ProcessesTB1() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const minExamination = pdf[i]?.Minimum_Leak_Path || [];
      const table1Data = minExamination[0]?.Table1 || [];

      for (let j = 0; j < table1Data.length; j++) {
        const item = table1Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MLP_Tube_No !== "" ? item.PQR_MLP_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocA !== "" ? item.PQR_MLP_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocB !== "" ? item.PQR_MLP_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocC !== "" ? item.PQR_MLP_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocD !== "" ? item.PQR_MLP_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Min_ProcessesTB2() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const minExamination = pdf[i]?.Minimum_Leak_Path || [];
      const table2Data = minExamination[0]?.Table2 || [];

      for (let j = 0; j < table2Data.length; j++) {
        const item = table2Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MLP_Tube_No !== "" ? item.PQR_MLP_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocA !== "" ? item.PQR_MLP_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocB !== "" ? item.PQR_MLP_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocC !== "" ? item.PQR_MLP_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocD !== "" ? item.PQR_MLP_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Min_ProcessesTB3() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const minExamination = pdf[i]?.Minimum_Leak_Path || [];
      const table3Data = minExamination[0]?.Table3 || [];

      for (let j = 0; j < table3Data.length; j++) {
        const item = table3Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop" style={{background:"none"}}>
              {item.PQR_MLP_Tube_No !== "" ? item.PQR_MLP_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocA !== "" ? item.PQR_MLP_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocB !== "" ? item.PQR_MLP_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocC !== "" ? item.PQR_MLP_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocD !== "" ? item.PQR_MLP_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Min_ProcessesTB4() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const minExamination = pdf[i]?.Minimum_Leak_Path || [];
      const table4Data = minExamination[0]?.Table4 || [];

      for (let j = 0; j < table4Data.length; j++) {
        const item = table4Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MLP_Tube_No !== "" ? item.PQR_MLP_Tube_No : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocA !== "" ? item.PQR_MLP_LocA : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocB !== "" ? item.PQR_MLP_LocB : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocC !== "" ? item.PQR_MLP_LocC : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_LocD !== "" ? item.PQR_MLP_LocD : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function Min_ProcessesTB5() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const minExamination = pdf[i]?.Minimum_Leak_Path || [];
      const table5Data = minExamination[0]?.Table5 || [];

      for (let j = 0; j < table5Data.length; j++) {
        const item = table5Data[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_noTop">
              {item.PQR_MLP_Magnification !== ""
                ? item.PQR_MLP_Magnification
                : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_Acceptance_Criteria !== ""
                ? item.PQR_MLP_Acceptance_Criteria
                : "--"}
            </td>
            <td className="PQR_Botmrt">
              {item.PQR_MLP_Observation !== ""
                ? item.PQR_MLP_Observation
                : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }

  function  VISUAL_EXAMINATION() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.Visual_Examination || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft">
                      {item.PQR_VEX_WeldPass !== "" ? item.PQR_VEX_WeldPass : '--'}</td>
                    <td className="PQR_right">
                      {item.PQR_VEX_Acceptance_Criteria !== "" ? item.PQR_VEX_Acceptance_Criteria : '--'}</td>
                    <td className="PQR_right">
                      {item.PQR_VEX_Observation !== "" ? item.PQR_VEX_Observation : '--'}</td>
                    <td className="PQR_right">
                      {item.PQR_VEX_Results !== "" ? item.PQR_VEX_Results : '--'}</td>

                </tr>
            );
        }
         }
    return rows;
        }
  function  LIQUID_PENETRANT_EXAMINATION() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.Liquid_Penetrant_Examination || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft">
                      {item.PQR_LPEX_WeldPass !== "" ? item.PQR_LPEX_WeldPass : '--'}</td>
                    <td className="PQR_right">
                      {item.PQR_LPEX_Acceptance_Criteria !== "" ? item.PQR_LPEX_Acceptance_Criteria : '--'}</td>
                    <td className="PQR_right">
                      {item.PQR_LPEX_Observation !== "" ? item.PQR_LPEX_Observation : '--'}</td>
                    <td className="PQR_right">
                      {item.PQR_LPEX_Results !== "" ? item.PQR_LPEX_Results : '--'}</td>

                </tr>
            );
        }
         }
    return rows;
        }



  return (
    // ------------------------------------------------------------------------------------//

    <div className="PQRMainPDF">
     
      <br></br> <br></br> <br></br> <br></br>
      <br></br> <br></br> <br></br> <br></br> <br></br>
      <br></br> <br></br> <br></br> <br></br> <br></br>
      <br></br> <br></br> <br></br> <br></br>
      <TTS_pdf_headerPQRpage2 pdf={pdf}></TTS_pdf_headerPQRpage2>
     
      {/* ------------------------------Visual examination---------------------- */}

      <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            VISUAL EXAMINATION
            </div>
          </div>
           <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "25%"}}>Weld Pass</th>
              <th className="PQR_noleft" style={{ width: "25%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "25%"}}>Observation  </th>
              <th className="PQR_noleft" style={{ width: "25%" }}>Results</th>
              
            </tr> 

        { VISUAL_EXAMINATION()}
        </table>
        <table className="PQRTablePg2" style={{ width: { width } }}>
           
            <tr>
              <th className="PQR_border" style={{width:"35px"}}>Notes</th>
              <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_VEX_Notes}</td>
            </tr>
          </table>

     {/* -----------------------------LIQUID PENETRANT EXAMINATION----------------------------------- */}

      <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            LIQUID PENETRANT EXAMINATION
            </div>
          </div>
           <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "25%"}}>Weld Pass</th>
              <th className="PQR_noleft" style={{ width: "25%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "25%"}}>Observation  </th>
              <th className="PQR_noleft" style={{ width: "25%" }}>Results</th>
              
            </tr> 
        { LIQUID_PENETRANT_EXAMINATION()}
        </table>
        <table className="PQRTablePg2" style={{ width: { width } }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_LPEX_Notes}</td>
           </tr>
         </table>
  {/* -------------------------MACRO EXAMINATION---------------------------------  */}
      <div
        className="group"
        // style={{  textAlign: "right", width: "50%" }}
      >
        <div className="PQRbasemetal" style={{ width: { width }}}>MACRO EXAMINATION</div>
      </div>
      <div style={styles.container}>
        <div style={{ paddingTop: "20px" }}></div>
        <table
          id="table"
          className="PQRTable6"
          style={{ width: "45%"}}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Max_ProcessesTB1()}
          </tbody>
        </table>

        <table
          id="table"
          className="PQRTable6"
          style={{
            width: "45%",
            position: "relative",
            right: "1.4cm",
          }}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Max_ProcessesTB2()}
          </tbody>
        </table>
      </div>
     
        <br></br>
      <div style={styles.container}>
        <div style={{ paddingTop: ".1vw" }}></div>
        <table
          id="table"
          className="PQRTable6"
          style={{ width: "45%"}}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Max_ProcessesTB3()}
          </tbody>
        </table>

        <table
          id="table"
          className="PQRTable6"
          style={{
            width: "45%",
            position: "relative",
            right: "1.4cm",
          }}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Max_ProcessesTB4()}
          </tbody>
        </table>
      </div>
      <br></br>
      <div className="group">
           <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "25%"}}>Magnification</th>
              <th className="PQR_noleft" style={{ width: "25%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "35%"}}>Observation  </th>
              
            </tr> 

        { Max_ProcessesTB5()}
            <tr>
              <th className="PQR_noTop">Result</th>
              <td colSpan="7" className="PQR_rt_botm" style={{textAlign:"left"}}>{pdf[0]?.PQR_MEX_Result}</td>
            </tr>
          </table> </div>
        


          {/* -------------------------MINIMUM LEAK PATH---------------------------------*/}
          <div
        className="group"
        // style={{textAlign: "right", width: "50%" }}
      >
        <div className="PQRbasemetal"  style={{ width: { width } }}>MINIMUM LEAK PATH</div>
      </div>
      <div style={styles.container}>
        <div style={{ paddingTop: "20px" }}></div>
        <table
          id="table"
          className="PQRTable6"
          style={{ width: "45%"}}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Min_ProcessesTB1()}
          </tbody>
        </table>

        <table
          id="table"
          className="PQRTable6"
          style={{
            width: "45%",
            position: "relative",
            right: "1.4cm",
          }}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Min_ProcessesTB2()}
          </tbody>
        </table>
      </div>
     
        <br></br>
      <div style={styles.container}>
        <div style={{ paddingTop: ".1vw" }}></div>
        <table
          id="table"
          className="PQRTable6"
          style={{ width: "45%"}}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2">
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Min_ProcessesTB3()}
          </tbody>
        </table>

        <table
          id="table"
          className="PQRTable6"
          style={{
            width: "45%",
            position: "relative",
            right: "1.4cm",
          }}
        >
          <tbody>
            <tr>
              <th className="left-th PQR_border" rowSpan="2" style={{ width: "15%" }}>
                Tube No
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc A
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc B
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc C
              </th>
              <th className="PQRleft-th-noleft" rowSpan="2" >
                Loc D
              </th>
            </tr>
            <br></br>
            {Min_ProcessesTB4()}
          </tbody>
        </table>
      </div>
       <br></br>
      <div className="group">
            <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "25%"}}>Magnification</th>
              <th className="PQR_noleft" style={{ width: "25%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "35%"}}>Observation  </th>
              
            </tr> 

        { Min_ProcessesTB5()}
            <tr>
              <th className="PQR_noTop">Result</th>
              <td colSpan="7" className="PQR_rt_botm" style={{textAlign:"left"}}>{pdf[0]?.PQR_MLP_Result}</td>
            </tr>
          </table>  </div>
     {/* ----------------------------footer---------------------------------------------------- */}

     {/* <div id="footer" style={{position:"fixed",top:"1950px"}} >
      
     <PQR_Footer pdf={pdf} previewuser={previewuser} previewapproved={previewapproved}></PQR_Footer>
<br></br>
</div> */}

<div id="footer" style={{position:"fixed",top:"2040px"}} >
        <table className="WPSfooter">
          <tr>
            <td>Page 2 of 3</td>
          </tr>
        </table>
      </div>

    </div>
  );
};

export default PQRTubeToTubesheet_pdf_page2;
