import axios from "axios";


if (process.env.REACT_APP_NODE_ENV === "local") {
  var MASTER_API_URL = process.env.REACT_APP_TRAINING_API_URL;
} else {
  var MASTER_API_URL = process.env.REACT_APP_API_URL + "training";
}

export async function getReqByType(tmpObj) {
  const response = await axios
    .post(MASTER_API_URL + "/trainingrequisition/getTrainingReqData", tmpObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  console.log("response.data", response);
  return response.data;
}

export async function getTrainingRecordbyID(trainingSub) {
  console.log("TrainingSub", trainingSub);
  let userdata = {
    trainingSub : trainingSub,

  }
  const response = await axios
    .post(MASTER_API_URL + "/trainingrequisition/getApprovedtraining",userdata)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddTrainingRequest = async (Request) => {
  
  console.log("AddTrainingRequest -- ", Request);
  console.log(JSON.stringify(Request));
  let url = `${MASTER_API_URL}/trainingrequisition/`;
  const response = await axios
    .post(url, Request)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const updateTraining = async (item) => {
  console.log("Yogii__", item);
  console.log("Yogii_0_sk", item[0].SK);
  // console.log("Yogii_1_sk", item[1].SK)

  console.log("item[0].wld_marks", item[0].wld_marks);

  // for (let i = 0; i < item.length; i++) {
  //   const element = item[i];
  let url = `${MASTER_API_URL}/trainingrequisition/updater`;
  const response = await axios
    .post(url, item)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//}

export async function updateTrainingRequest(data) {
  console.log("updateNewTrainingrequest -- ", data);
  let url = `${MASTER_API_URL}/trainingrequisition/workflow`;
  const response = await axios
    .post(url, data)

    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("updateTrainingRequest_response", response.data);
  return response.data;
}

//////Started Qualification Requisition
export const AddQualificationRequest = async (Request) => {
  
  console.log("AddQualificationRequest_ ", Request);
  console.log(JSON.stringify(Request));
  console.log(
    "AddQualificationRequest_1",
    JSON.stringify(Request.wld_TrainingReqNumber)
  );
  let url = `${MASTER_API_URL}/qualificationrequisition/`;
  console.log("AddQualificationRequest_url", url);
  const response = await axios
    .post(url, Request)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddFinalApprover = async (Request) => {
  
  console.log("getFinalApprover ", Request);

  let url = `${MASTER_API_URL}/qualificationrequisition/FinalApprover`;
  console.log("AddQualificationRequest_url", url);
  const response = await axios
    .post(url, Request)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getQualReqByType(tmpObj) {
  console.log("getQualReqByType_ ", tmpObj);
  const response = await axios
    .post(
      MASTER_API_URL + "/qualificationrequisition/getTrainingReqData",
      tmpObj
    )
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  console.log("response.data", response);
  return response.data;
}

export async function getFinalApprover(tmpObj) {
  console.log("getFinalApprover ", tmpObj);
  const response = await axios
    .post(
      MASTER_API_URL + "/qualificationrequisition/getFinalApprover",
      tmpObj
    )
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  console.log("response.data", response);
  return response.data;
}


export async function getAllDataQCEExport() {
  const response = await axios
    .get(MASTER_API_URL + "/qualificationrequisition/qcegetalldataexport")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function updateQualificationRequest(data) {
  console.log("updateQualificationReques_service", data);
  let url = `${MASTER_API_URL}/qualificationrequisition/workflow`;
  const response = await axios
    .post(url, data)

    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("updateQualificationReques_response", response.data);
  return response.data;
}
