const Tubesheet_Other_variable = ({pdf}) =>{
    return(
        <table 
        id="table"
        className="WPSTable9"
        style={{position:"relative", top:"0px" }}
      >
        <tbody>
          <tr style={{ borderTop: ".1px solid black" }}>
            <th scope="row" style={{ borderTop: ".1px solid black" , width:"17%"}}>
              Expansion
            </th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_Expansion}</td>
          </tr>

          <tr>
            <th scope="row">Method of cleaning</th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_MethodofCleaning}</td>
          </tr>

          <tr>
            <th scope="row">Single /Multi Electrode</th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_SingleMultiElectrode}</td>
          </tr>

          <tr>
            <th scope="row">Peening</th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_Peening}</td>
          </tr>

          <tr>
            <th scope="row">Use of Thermal Process</th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_UseOfThermalProcess}</td>
          </tr>

          <tr>
            <th scope="row">String & Weave </th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_StringWave}</td>
          </tr>

          <tr>
            <th scope="row">Orifce Cup size
            <div className="WPSrcorner"> mm </div>
            </th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_OrifceCupSize}</td>
          </tr>

          <tr>
            <th scope="row">Oscillation</th>
            <td className="WPSNoLeft" style={{ borderLeft: "none" }}>{pdf[0]?.WPSH_Tech_Oscillation}</td>
          </tr>

          <tr style={{}}>
            <th scope="row" style={{borderBottom:"1px solid black"}}>Closed to out chamber</th>
            <td className="WPSNoLeft" style={{ borderLeft: "none",borderBottom:"1px solid black" }}>{pdf[0]?.WPSH_Tech_ClosedtooutChamber}</td>
          </tr>
          {/* <tr style={{ borderBottom: "0.5 px solid black" }}>
            <th scope="row" style={{ borderBottom: "0.5 px solid black" }}>
              Others
            </th>
            <td style={{ borderLeft:"none",borderBottom: "0.5 px solid black" }}>
              {pdf[0]?.WPSH_Tech_Others}
            </td>
          </tr> */}
        </tbody>
      </table>
    )
}
export default Tubesheet_Other_variable;