// Console Log Common Function---------------------------------------------------------------//

const Wld_Consolelog = (msg, data) => {
  if (
    process.env.REACT_APP_NODE_ENV === "local" ||
    process.env.REACT_APP_NODE_ENV === "dev"
  ) {
    console.log(msg, data);
  }
};


export default Wld_Consolelog;
