import { Col, Form, Row } from "react-bootstrap";
import Delete from "../../../icons/DeleteIcon.svg";

export const ImageArray2 = ({
  name1,
  name2,
  type,
  accept,
  fileInputRef,
  handleOndragOver,
  handleOndrop,
  headerObj,
  setheaderObj,
  previewUrl1,
  previewUrl2,
  handleFile,
}) => {
  const images = ["fileInput1", "fileInput2"];
  var count = 0;
  return (
    <>
        <Row>
        <Col md={4}>
          <Form.Group>
            <form encType="multipart/form-data">

{headerObj?.Pdf_images[0]?.pqr_image1[0] !== "" ? (
  <>
   <div className="WPS-uploadwhite">
                        <img
                          src={previewUrl1}
                          style={{
                            position: "relative",
                            left: "0px",
                            width: "100%",
                          }}
                          className="WPS-circler"
                        />
                        <img
                          src={Delete}
                          style={{
                            position: "relative",
                            top: "15px",
                            height: "30px",
                          }}
                          className="WPS-circlee"
                          onClick={() => {
                            
                            // {handleDeletePopup}
                            const updatedHeaderObj = {...headerObj }; 
                            updatedHeaderObj.Pdf_images[0].pqr_image1[0] = ""; 
                            setheaderObj(updatedHeaderObj); 
                        }}
                        />
                      </div>
                        
                </>
                ) : (<>
              <div className="WPS-upload">
              <div className="d-flex flex-column align-items-center ms-5"></div>

                <div
                  className="drop_zone"
                  onDragOver={handleOndragOver}
                  onDrop={handleOndrop}
                  // onClick={() => fileInputimg.current.click()}
                >
                <br></br>
                          <label for="fileInput1" >
                            <strong>Upload Image here or</strong>
                            <span className="WPS-blue-text">
                              {" "}
                              Select a file
                            </span>
                          </label>
                          <br></br>
                          <span style={{position:"relative", left:"0px", fontSize:"12px"}}>Supports: Jpeg, PNG and JPG, Size:Max 1MB</span>


                  <div className="ImagenameBox">
                    <label className="imgtext1">
                      {headerObj?.Pdf_images[0]?.pqr_image1[0]}
                    </label>
                  </div>

                  <input
                    id={name1}
                    name={name1}
                    type={type}
                    accept={accept}
                    hidden
                    ref={fileInputRef}
                    onChange={(e) => handleFile(e.target.files[0],0)}
                  />
                </div>
                </div>


                </> )}

            </form>
            &nbsp;&nbsp;
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group>
            <form encType="multipart/form-data">
            
{headerObj?.Pdf_images[1]?.pqr_image2[0] !== "" ? (
  <>
   <div className="WPS-uploadwhite">
                        <img
                          src={previewUrl2}
                          style={{
                            position: "relative",
                            left: "0px",
                            width: "100%",
                          }}
                          className="WPS-circler"
                        />
                        <img
                          src={Delete}
                          style={{
                            position: "relative",
                            top: "15px",
                            height: "30px",
                          }}
                          className="WPS-circlee"
                          onClick={() => {
                            
                            // {handleDeletePopup}
                            const updatedHeaderObj = {...headerObj }; 
                            updatedHeaderObj.Pdf_images[1].pqr_image2[0] = ""; 
                            setheaderObj(updatedHeaderObj); 
                        }}
                        />
                      </div>
                        
                </>
                ) : (<>
              <div className="WPS-upload">
              <div className="d-flex flex-column align-items-center ms-5"></div>

                <div
                  className="drop_zone"
                  onDragOver={handleOndragOver}
                  onDrop={handleOndrop}
                  // onClick={() => fileInputimg.current.click()}
                >
                <br></br>
                          <label for="fileInput2" >
                            <strong>Upload Image here or</strong>
                            <span className="WPS-blue-text">
                              {" "}
                              Select a file
                            </span>
                          </label>
                          <br></br>
                          <span style={{position:"relative",left:"0px", fontSize:"12px"}}>Supports: Jpeg, PNG and JPG, Size:Max 1MB</span>


                  <div className="ImagenameBox">
                    <label className="imgtext1">
                      {headerObj?.Pdf_images[1]?.pqr_image2[0]}
                    </label>
                  </div>

                  <input
                    id={name2}
                    name={name2}
                    type={type}
                    accept={accept}
                    hidden
                    ref={fileInputRef}
                    onChange={(e) => handleFile(e.target.files[0],1)}
                  />
                </div>
                </div>


                </> )}
            </form>
            &nbsp;&nbsp;
          </Form.Group>
        </Col>

      </Row>
    </>
  );
};

export const ImageArray3 = ({
  name,
  type,
  accept,
  fileInputRef,
  handleFile,
  imgObj,
  getImgURL,
  handleOndragOver,
  handleOndrop,
  readOnly,
  disabled,
  headerObj,
  setheaderObj,

  setimgObj,
}) => {
  const images = ["fileInput1", "fileInput2", "fileInput3"];
  var count = 0;
  return (
    <>
      <Row>
        {images.map((img) => {
          count++;
          console.log("inloop_imgObj", imgObj);
          console.log("inloop_images_3", img);
          console.log("inloop_wld_pdf_image", headerObj?.wld_pdf_image);

          return (
            <Col md={4}>
              <Form.Group>
                <form encType="multipart/form-data">
                  {imgObj.some((item) => item.name === img) ||
                  headerObj?.wld_pdf_image.some((item) => item.name === img) ? (
                    <>
                      <div className="WPS-uploadwhite">
                        <img
                          src={getImgURL(img)}
                          style={{
                            position: "relative",
                            left: "0px",
                            width: "100%",
                          }}
                          className="WPS-circler"
                        />
                        <img
                          src={Delete}
                          style={{
                            position: "relative",
                            top: "15px",
                            height: "30px",
                          }}
                          className="WPS-circlee"
                          onClick={() => {
                            setheaderObj((prevState) => {
                              const updatedImages =
                                prevState.wld_pdf_image.filter(
                                  (item) => item.name !== img
                                );
                              return {
                                ...prevState,
                                wld_pdf_image: updatedImages,
                              };
                            });

                            const updatedImgObj = imgObj.map((item) => {
                              if (item.name === img) {
                                return { ...item, name: "" };
                              }
                              return item;
                            });

                            setimgObj(updatedImgObj);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="WPS-upload">
                      <div className="d-flex flex-column align-items-center ms-5">
                        <div
                          className="drop_zone"
                          onDragOver={handleOndragOver}
                          onDrop={handleOndrop}
                        >
                          <br></br>
                          <label for={img}>
                            <strong>Upload Image here or</strong>
                            <span className="WPS-blue-text">
                              {" "}
                              Select a file
                            </span>
                          </label>
                          <br></br>
                          <span style={{position:"relative",left:"0px", fontSize:"12px"}}>Supports: Jpeg, PNG and JPG, Size:Max 1MB</span>

                          <input
                            id={name + count}
                            name={name + count}
                            type={type}
                            accept={accept}
                            hidden
                            ref={fileInputRef}
                            onChange={(e) => handleFile(e)}
                            disabled={disabled}
                          />
                        </div>
                        <label>{imgObj.wld_pdf_image}</label>
                      </div>
                    </div>
                  )}
                </form>
                &nbsp;&nbsp;
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
