import { React } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfirmAlertModal = (props) => {
  console.log("Props_ConfirmAlertModal", props);
  let formName = props.formName;
  const closeTab = () => {
    window.close();
  };
  // let MSG = `Are you sure do you want to {props.action} New ${formName}{" "} {props.module} ?`;
  //let MSG = `Are you sure do you want to ${props.action} New ${formName} ?`;
  let actionDependent;
  if (props.action === "Add") {
    actionDependent = ` ${props.action} New ${formName}`;
  } else if (props.action === "Logout") {
    actionDependent = `${props.action}`;
  } else {
    actionDependent = ` ${props.action}  ${formName}`;
  }

  // if (formName === "Deviation") {
  //   MSG = `Are you sure do you want to Raise New ${formName} ?`;
  // }
  // if (formName === "WCSExcelUpload") {
  //   MSG = `Are you sure do you want to upload excel data ?`;
  // }

  return (
    <>
      <Modal
        dialogClassName="my-modal"
        className="confirmAlertModal"
        show={props.show}
        onHide={props.handleClose}
        animation={false}
        backdrop="static"
      >
        <br></br>
        <div
          style={{ color: "var(--txt-color)", fontWeight: "bold", marginLeft: "1rem" }}
        >
          {props.action} {formName}
        </div>
        <Modal.Body className="confirmcss">
          {formName === "Deviation"
            ? "Are you sure do you want to Raise New " + formName + "?"
            : "Are you sure do you want to " + actionDependent + "?"}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="nobtn" onClick={() => props.handleClose()}>
            No
          </Button>
          <Button className="deletebtn" onClick={() => props.handleYes()}>
            {props.action ? props.action : "Yes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmAlertModal;
