import React from "react";

function DeviationStatusRenderer(props) {
    const devNum = props.data;
    
      let status_color = "";
      let bk_color = "";
      let font__Size = "";
      let Margin_Size = "";
      let Rstatus = devNum;
      console.log("RStatus", Rstatus);
  
      switch (Rstatus) {
        case "Pending for CAPA analysis":
          status_color = "var(--godrej-orange)";
          bk_color = "var(--godrej-orange-bg)";
          font__Size = "12px";
          Margin_Size="160px";  
          break;
        case "Pending for Closure":
          status_color = "var(--godrej-blue)";
          bk_color = "var(--godrej-blue-bg)";
          font__Size = "12px";
          Margin_Size = "125px"
          break;
        case "Closed":
          status_color = "var(--txt-color)";
          bk_color = "var(--bg-alt-2)";
          font__Size = "12px";
          Margin_Size = "55px"
          break;
          case "Rejected":
            status_color = "var(--godrej-red)";
            bk_color = "var(--godrej-red-bg)";
          font__Size = "12px";
          Margin_Size = "55px"
          break;
      }
  
      return (
        <>
          <div
            style={{
              backgroundColor: bk_color,
              borderRadius: "4px",
              width: Margin_Size,
              height: "20px"
            }}
            className="d-flex align-items-center"
          >
            <span style={{ color: status_color, borderRadius: "14px", fontSize: font__Size }}
            >
              &nbsp;
              {Rstatus}
              &nbsp;
            </span>
          </div>
        </>
      );
  }

export { DeviationStatusRenderer };