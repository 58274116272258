import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import {
  AddNewWeldingProcess,
  UpdateWeldingProcess,
} from "../../Services/weldingprocess-services";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumeric,
  isAlphanumericWithDash,
  isAlphanumericWithDotSpaceDashCommaBrackets,
  isAlphanumericWithSpaceDotDash,
  isAlphanumericWithSpecialChars,
  isNumber,
  twodecimal,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../utility/FormControl";
import ButtonModal from "../Modals/buttonModal";
import { SearchSelectProject } from "../utility/search-select";
import { CommonTable2 } from "../utility/CommonComponents/Table2";
import { WITable1 } from "../utility/CommonComponents/WITable1";
import { getWCSDataHeader } from "../../Services/wcs-services";
import {
  getbyprojectnoWI,
  getbywpsnowi,
} from "../../Services/wps_groove-services";
import {
  getdetailsById,
  getdetailsByIdWI,
} from "../../Services/wps_details-service";
import {
  getactiveprocess,
  getbyprojectnoWPQ_WI,
  getselectedwelder,
} from "../../Services/wpq_groove-services";
import {
  addwelder,
  getCount,
  linkbyproject,
  linkbyprojecttype,
  linkbyprojecttypetube,
} from "../../Services/WI-services";
import { getAllOnlyProject } from "../../Services/project-services";
import {
  getdetailsInWI,
  updatedatewpq,
} from "../../Services/wpq_details-service";
import { DiameterCheck, isWPQProcessActive } from "./WI_Functions";
import { Plant_Location_GO, Plant_Location_WI } from "../WPQTransaction/WPSObjects/WPQ_entity";
import { General_Single_Input_select } from "../WPSTransaction/WPSObjects/WPS_Process_Grid";

const AddEditWIPage = (props) => {
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "WI Slip";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [ProjectDetails, setProjectDetails] = useState([]);
  const [Widata, setWidata] = useState([]);
  const [getwelder, setgetwelder] = useState([]);
  const [slipno, getslipno] = useState([]);

  const [getWpsData, setgetWpsData] = useState([]);
  const [UpdatedWps, setgetUpdatedWps] = useState([]);
  const [getheaderData, setgetheaderData] = useState([]);

  const [getwpqheaderData, setgetwpqheaderData] = useState([]);

  const [wcsprojectdata, setwcsprojectdata] = useState([]);

  const [linkwpq, setLinkWpq] = useState([]);

  const [plant, setplant] = useState([]);

  const [updatedate, setUpdateDate] = useState([]);

  const [wpstypecheck, setwpstypecheck] = useState([]);

  var Welding_Position = [
    { name: "F" },
    { name: "H" },
    { name: "V" },
    { name: "O" },
    { name: "SP" },
    { name: "6G" },
    { name: "5G" },
  ];

  const GrooveType = ["Groove", "Tubesheet", "Overlay"];

  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");
  let emailid = sessionStorage.getItem("UserId");

  console.log("username is -- ", username);

  console.log("getheaderDataaa", getheaderData);

  console.log("linkwpq", linkwpq);

  const [WiObj, setWiObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          Processes: [
            {
              SK: "",
              WPSD_WP_FillerMetalProductForm: "",
              WPSH_consumRadio: "",
              WPSD_WP_ArcTransferMode: "",
              WPSD_WP_CurrentPolarity: "",
              WPSD_WP_Multiple_SinglePassPerSide: "",
              ActiveProcess: "",
              WPQ_WelderId: "",
              WPQ_RecordNo: "",
              WPQ_Link: [],
            },
          ],
          PK: "",
          SK: "",
          WI_Slip: "",
          WI_Project_No: "",
          WI_Joint_No: "",
          WI_WPS_No: "",
          WI_Process: "",
          WI_Type: "",
          WI_Overlay_Joint_BMT: "",
          WI_PipePlate: "",
          WI_Pipe_Diameter: "",
          WI_Plate_No: "",
          WI_Welding_Position: "",
          WI_Welder_Id: "",
          WI_Welder_Name: "",
          wld_WPSType: "",
          WPSH_TRQ_BaseMetalCRO_Max: "",
          WPSH_BM_WeldingTo_PNo: "",
          WI_24hrs: "",
          WI_Slip_Status: "",
          WI_print: "",
          WI_reprintDate: "",
          WCS_Project_No: "",
          WI_plant: "",
          WI_FillerMetalSize: "",
          WI_WeldConsumable: "",
          WI_WP_BatchNumber: "",
          WI_CreatedBy: "",
          WI_CreatedBy_Name: "",
          WI_WPSHeaderSK: "",
          WI_WPSDetailSK: "",
          WI_WPQ_PrcessStatus: "",
          WPS_Rev_no: "",
          WI_Flux: "",
          WI_Flux_Brand: "",
          WI_Flux_ItemCode:"",
          WI_Flux_Batch:"",
          WI_FM_Brand: "",
          WI_FM_AWS: "",
          WI_ItemDesc: "",
          WI_WCinKgElectrode: "",
          WI_ProcessCategory: "",
          WI_JointDesign: "",
          WI_Wrap_Insulation:"",
          WI_Weld_Max:"",
          WI_WeldDepThick:"",
          WI_NOTES:"",
        }
  );

  console.log("WIObj", WiObj);

  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_p_category: {},
    wld_description: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
    WI_Overlay_Joint_BMT: {},
    WI_plant:{},
  });

  // const [error, setError] = useState({
  //   PK:{},
  //   SK:{},
  //   WI_Slip:{},
  //   WI_Project_No:{},
  //   WI_Joint_No:{},
  //   WI_WPS_No:{},
  //   WI_Process:{},
  //   WI_Type:{},
  //   WI_Overlay_Joint_BMT:{},
  //   WI_PipePlate:{},
  //   WI_Pipe_Diameter:{},
  //   WI_Plate_No:{},
  //   WI_Welding_Position:{},
  //   WI_Welder_Id:{},
  //   WI_Welder_Name:{},
  // });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setWiObj",
    });
  };

  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
    }),
  });

  const [errorstyle, seterrorstyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-red-alt)",
    }),
  });
  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setWiObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setWiObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  // const fetchWelder = async () => {

  //   var result = await getselectedwelder(WiObj);
  //   setgetwelder(result);
  //   console.log("resultwelder", result);

  // }

  // const fetchWelder = async () => {
  //   var result = await getselectedwelder(WiObj);
  //   if (Array.isArray(result) && result.length >= 2) {
  //     setgetwelder([result[1]]); // Wrap result[1] in an array
  //     console.log("resultwelder", [result[1]]);
  //   }
  //   // } else {
  //   //   setgetwelder();
  //   //   console.log("Result is null");
  //   // }
  // };

  // const fetchWelder = async () => {

  //   var result = await getselectedwelder(WiObj);

  //   const filteredData = result.filter(item => item.PK  === "WPQ_HEADER");

  //     setgetwelder(filteredData)
  //     console.log("resultwelder", filteredData);
  //   }

  console.log("typeeeecheck", wpstypecheck);

  const fetchWelder = async () => {
    
    const linkedproject = await linkbyprojecttype(
      WiObj.WI_Project_No,
      getWpsData[0]?.wld_WPSType
    );
    const uniqueData = [];

    if (getWpsData[0]?.wld_WPSType === "Tubesheet") {
      let tuberesult = linkedproject[0]?.WI_WPQ_Data;
      //  const uniqueValues = new Set();
      tuberesult?.forEach((item) => {
        if (
          uniqueData.filter((val) => val.WPQ_WelderId == item.WPQ_WelderId)
            .length === 0
        ) {
          uniqueData.push({
            WPQ_WelderId: item.WPQ_WelderId,
            WPQ_WelderName: item.WPQ_WelderName,
            // remainingday: item.remainingday,
          });
        }
      });

      setgetwelder(uniqueData);
    } else {
      let selectedWelder;
      selectedWelder = await getselectedwelder(WiObj);
      let isLinked =
        linkedproject &&
        linkedproject.length > 0 &&
        linkedproject[0] &&
        linkedproject[0].WI_WPQ_Data &&
        linkedproject[0].WI_WPQ_Data.length > 0;
      let data = linkedproject[0];

      console.log("selectedWelderrrrr", selectedWelder);

      let ActiveID;
      const ActiveWelder = [];

      selectedWelder?.forEach((item) => {
        const wldCreatedOnString = item?.wld_createdon;

        ActiveID = isWPQProcessActive(wldCreatedOnString);

        if (ActiveID === "InActive") {
          return false;
        }

        let element = item;
        let WPQLinked = false;
        if (!isLinked) {
          WPQLinked = true;
        }
        if (isLinked) {
          let linked_data;

          let splitpk = element?.PK;
          let splitrecord = splitpk?.split("#");
          let RecordNo = splitrecord[1];

          if (data?.WI_WPQ_Data?.length > 0) {
            for (let i in data?.WI_WPQ_Data) {
              linked_data = data?.WI_WPQ_Data[i];
              if (
                element.WPQ_WelderId === linked_data.WPQ_WelderId &&
                RecordNo === linked_data.WPQ_RecordNo
              ) {
                WPQLinked = true;
              }
            }
          }
        }

        if (WPQLinked) {
          const isDiameterValid = DiameterCheck(element, item, WiObj);

          if (isDiameterValid) {
            uniqueData.push({
              WPQ_WelderId: item.WPQ_WelderId,
              WPQ_WelderName: item.WPQ_WelderName,
              // remainingday: item.remainingday,
            });
          }
        }
      });
      setUpdateDate(selectedWelder);
      setgetwelder(uniqueData);
    }
  };

  console.log("getwelderrrrrrrr", getwelder);

  const fetchSlipno = async () => {
    if (action === "Add") {
      var result = await getCount();
      getslipno(result);
    }
  };

  const fetchProject = async () => {
    var result = await getAllOnlyProject();

    setWidata(result);
    setProjectDetails(result);
  };

  console.log("ProjectDetails", ProjectDetails);

  console.log("Widataeeee", Widata);

  console.log("wcsprojectdata111", wcsprojectdata);

  const getJointNosForSelectedProject = () => {
    if (WiObj.WI_Project_No && wcsprojectdata && wcsprojectdata.length > 0) {
      let joint = wcsprojectdata[1];

      const selectedProjects = joint?.filter(
        (item) => item.WCS_Project_No === WiObj.WI_Project_No
      );
      const selectedJointNos = selectedProjects
        .map((project) => project.WCS_Joint_No)
        .filter((jointNo) => jointNo);

      if (selectedJointNos.length > 0) {
        return selectedJointNos.map((jointNo) => ({
          label: jointNo,
          value: jointNo,
        }));
      }
    }
    return [];
  };

  const Approved = (ProjectList) => {
    if (
      wcsprojectdata &&
      Array.isArray(wcsprojectdata) &&
      wcsprojectdata.length > 1
    ) {
      let joint = wcsprojectdata[1];
      console.log("wcsprojectdatajj", wcsprojectdata, joint);

      const selectedProject = joint.find(
        (project) => project.WCS_Project_No === ProjectList.value
      );
      if (!selectedProject) {
        alert(`Project No: ${ProjectList.value} is not approved!`);
        setWiObj((prev) => ({
          ...prev,
          WI_Project_No: "",
          WI_Joint_No: "",
          WI_WPS_No: "",
          WI_Process: "",
          WI_Type: "",
        }));
      }
    } else {
      if (wcsprojectdata.length === 0) {
        alert("Please Check");
      }
      console.log("Invalid wcsprojectdata or length is insufficient");
      // Handle any other logic or state updates if needed
    }
  };

  const getWPsnoForSelectedProject = () => {
    console.log("wcsprojectdata_WI", wcsprojectdata);
    let joint = wcsprojectdata[1];

    const selectedJoint = joint?.find(
      (item) => item.WCS_Joint_No === WiObj.WI_Joint_No
    );

    if (selectedJoint) {
      const wcsWPSNos = [];
      for (let i = 1; i <= 4; i++) {
        const wcsWPSNoKey = `WCS_WPS_No${i}`;
        const wpsNo = selectedJoint[wcsWPSNoKey];
        if (wpsNo) {
          wcsWPSNos.push({
            label: wpsNo,
            value: wpsNo,
          });
        }
      }
      return wcsWPSNos;
    }

    return [];
  };

  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    const result = await getdetailsByIdWI(IdprojectPK, IdprojectSK);
    console.log("header333", result);
    if (result != undefined) {
      setgetWpsData(result);
    }
  };

  const updatewi = async (updatedProcesses) => {
    console.log("fgggggggggg", updatedProcesses);

    if(updatedProcesses[0].type === "Tubesheet"){
      if (getWpsData.length > 0) {
      
        console.log("asdsasada")
        const getwpsupdated = getWpsData.filter(
          (item) => item.SK === updatedProcesses[0].SK
          // (item) => item.WPSD_ProcessCategory === updatedProcesses[0].SK
  
        );
        setgetUpdatedWps(getwpsupdated);
        console.log("ffddssaagg", UpdatedWps);
      } else {
        console.log("getWpsData is not available or empty");
      }
    }
    else{

    if (getWpsData.length > 0) {
      
      console.log("asdsasada")
      const getwpsupdated = getWpsData.filter(
        // (item) => item.SK === updatedProcesses[0].SK
        (item) => item.WPSD_ProcessCategory === updatedProcesses[0].SK

      );
      setgetUpdatedWps(getwpsupdated);
      console.log("ffddssaagg", UpdatedWps);
    } else {
      console.log("getWpsData is not available or empty");
    }
  }
  };

  console.log("ffddssaa", UpdatedWps);

  const fetchwpsheaderbyid = async (Type, IdprojectPK, IdprojectSK) => {
    for (let Type of GrooveType) {
      let result = await getbyprojectnoWI(Type, IdprojectPK, IdprojectSK);
      console.log("header33344fff", result);
      if (result.length > 0) {
        if (result != undefined) setgetheaderData(result);
      }
    }
  };

  console.log("getheaderDataaaa:", getheaderData);

  const addwpsdata = () => {
    debugger
    console.log("enteradddd")

    console.log("UpdatedWps:", UpdatedWps);
    console.log("SK:", UpdatedWps[0]?.SK);

    // let element;
    // let wpq_Link;
    // let wpq_data;

    // for(var i in WiObj.Processes){
    //   element = WiObj.Processes[i];
    //   for(var e in element.WPQ_Link){
    //     wpq_Link = WiObj.Processes[e];
    //     for(var data in wpq_Link.WI_WPQ_Data){
    //       wpq_data = WiObj.Processes[data];

    // }

    const updatedProcesses = [...WiObj.Processes];

    updatedProcesses[0] = {
      ...updatedProcesses[0],
      // SK: UpdatedWps[0]?.SK || "",
      SK: UpdatedWps[0]?.WPSD_ProcessCategory || "",
      WPSD_WP_FillerMetalProductForm:
        UpdatedWps[0]?.WPSD_WP_FillerMetalProductForm || "",
      WPSH_consumRadio: getheaderData[0]?.WPSH_consumRadio,

      WPSD_WP_ArcTransferMode: UpdatedWps[0]?.WPSD_WP_ArcTransferMode || "",
      WPSD_WP_CurrentPolarity:
        Array.isArray(UpdatedWps[0]?.WPSD_WP_CurrentPolarity) &&
        UpdatedWps[0]?.WPSD_WP_CurrentPolarity.length > 0
          ? UpdatedWps[0].WPSD_WP_CurrentPolarity[0]
          : UpdatedWps[0]?.WPSD_WP_CurrentPolarity === undefined
          ? "N/A"
          : UpdatedWps[0]?.WPSD_WP_CurrentPolarity || "N/A",
      WPSD_WP_Multiple_SinglePassPerSide:
        UpdatedWps[0]?.WPSD_WP_Multiple_SinglePassPerSide || "",
 

        

        // WPQ_Link: linkwpq,
      // WPQ_RecordNo: linkwpq,
    };
    if (getWpsData[0]?.wld_WPSType === "Tubesheet") {

    setWiObj((prev) => ({
      ...prev,
      Processes: updatedProcesses,
      wld_WPSType: getheaderData[0]?.wld_WPSType,
      WPSH_TRQ_BaseMetalCRO_Max: getheaderData[0]?.WPSH_TRQ_BaseMetalCRO_Max,
      WPSH_BM_WeldingTo_PNo: getheaderData[0]?.WPSH_BM_WeldingTo_PNo,
      WPSH_consumRadio: getheaderData[0]?.WPSH_consumRadio,
      WI_Welder_Id: WiObj[0]?.WI_Welder_Id,
      WI_Welder_Name: WiObj[0]?.WI_Welder_Name,
      WI_Flux: "-",
      WI_Flux_Brand: "-",
      WI_Flux_ItemCode:"-",
      WI_Flux_Batch:"-",
      WI_NOTES:"-"
      
    }));
  }
  else{
    setWiObj((prev) => ({
      ...prev,
      Processes: updatedProcesses,
      wld_WPSType: getheaderData[0]?.wld_WPSType,
      WPSH_TRQ_BaseMetalCRO_Max: getheaderData[0]?.WPSH_TRQ_BaseMetalCRO_Max,
      WPSH_BM_WeldingTo_PNo: getheaderData[0]?.WPSH_BM_WeldingTo_PNo,
      WPSH_consumRadio: getheaderData[0]?.WPSH_consumRadio,
      WI_Welder_Id: getheaderData[0]?.WPQ_WelderId,
      WI_Welder_Name: getheaderData[0]?.WPQ_WelderName,
      WI_Flux: getheaderData[0]?.WPSH_Flux_Classification,
      WI_Flux_Brand: getheaderData[0]?.WPSH_Flux_ChemicalAnalysis_TradeName,
      WI_Flux_ItemCode: getheaderData[0]?.WPSH_FM_FluxItemCode,
      WI_Flux_Batch: getheaderData[0]?.WPSH_FM_FluxItemBatch,
      WI_NOTES: getheaderData[0]?.WPSH_Notes,
      WI_Wrap_Insulation: getheaderData[0]?.WPSH_PWHeatTre_Wrap_insulation_material
    }));
  }



  };

  console.log("getwpsdata", getWpsData);

  console.log("getheaderdata", getheaderData);

  useEffect(() => {
    if (WiObj.WI_Project_No !== "" && WiObj.WI_WPS_No !== "") {
      let IdprojectPK = WiObj.WI_Project_No;
      let IdprojectSK = WiObj.WI_WPS_No;
      let Type = "";
      fetchprojectbyid(IdprojectPK, IdprojectSK);
      fetchwpsheaderbyid(Type, IdprojectPK, IdprojectSK);

      // fetchwpsheaderbyid("Overlay",IdprojectPK, IdprojectSK);
    }
  }, [WiObj.WI_Project_No, WiObj.WI_WPS_No]);

  useEffect(() => {
    fetchProject();
    fetchSlipno();
  }, []);

  useEffect(() => {
    if (WiObj.WI_Welding_Position !== "") {
      fetchWelder();
    }
  }, [WiObj.WI_Welding_Position]);

  useEffect(() => {
    if (WiObj.WI_Type !== "") {
      addwpsdata();
    }
  }, [WiObj.WI_Type]);

  useEffect(() => {
    debugger
    if (getWpsData[0]?.wld_WPSType === "Tubesheet") {
      console.log("enter")
      addwpsdata();
    }
  }, [WiObj.WI_Process]);

  const resetForm = () => {
    if (action === "Add") {
      setWiObj((prevState) => ({
        ...prevState,
        WI_Project_No:"",
        WCS_Project_No: "",
        WI_Joint_No: "",
        WI_WPS_No: "",
        WI_Process: "",
        WI_Type: "",
        WI_Overlay_Joint_BMT:"",
        WI_PipePlate:"",
        WI_Pipe_Diameter:"",
        WI_Welding_Position:"",
        WI_Welder_Id:"",
        WI_Welder_Name:"",
        WI_plant:"",
      }));
    } else if (action === "Edit") {
      setWiObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_code: props.rowdata.wld_code,
        wld_p_category: props.rowdata.wld_p_category,
        wld_description: props.rowdata.wld_description,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_code: {},
      wld_p_category: {},
      wld_description: {},
      SK: {},
    }));
  };

  const validateWeldingProcess = (action) => {
    let result = false;
    let isError = false;
    if (WiObj.wld_code.trim() === "") {
      WiObj.wld_code = "";
      isError = true;
      error.wld_code = {
        status: true,
        message: "WI Slip Number is required",
      };
    }
    if (WiObj.wld_p_category.trim() === "") {
      WiObj.wld_p_category = "";
      isError = true;
      error.wld_p_category = {
        status: true,
        message: "Process Category is required.",
      };
    }
    if (WiObj.wld_description.trim() === "") {
      WiObj.wld_description = "";
      isError = true;
      error.wld_description = {
        status: true,
        message: "Process Description is required.",
      };
    }
    if (WiObj.wld_status === "") {
      isError = true;
      error.wld_status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (action === "Add") {
      console.log("WiObj -- ", WiObj);
      result =
        props.allRecs &&
        props.allRecs.find(
          (item) =>
            item.PK.toLowerCase() === WiObj.PK.toLowerCase() &&
            item.SK.toLowerCase() === WiObj.wld_code.toLowerCase()
        );
      console.log(result);
    }

    if (result) {
      setAlertMessage("Process Code Combination Already Exists!");
      isError = true;
    }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.SK.status ||
      error.wld_code.status ||
      error.wld_p_category.status ||
      error.wld_description.status
    ) {
      isError = true;
    }
    return isError;
  };
  const handleAdd = (e) => {
    let result;
    let result1;
    let Filterwelder;

    if (action === "Add") {
      console.log("updatedateffds", updatedate);

      let Result1 = updatedate;

      console.log("updatedateffdsw", Result1);

      Filterwelder = Result1.filter(
        (item) => item.WPQ_WelderId === WiObj?.WI_Welder_Id
      );

      console.log("Filterwelder", Filterwelder);

      console.log("result1", Result1);

      const DatePK = [];
      const DateSK = [];

      const resultpk = Filterwelder[0]?.PK;

      const resultsk = WiObj?.WI_Process + "#" + WiObj?.WI_Type;

      DatePK.push({ resultpk });

      DateSK.push({ resultsk });

      console.log("DatePK", DatePK);

      console.log("DateSK", DateSK);

      // WiObj.SK = WiObj.wld_code;
      // WiObj.wld_createdby = username;
      // WiObj.wld_createdon = WiObj.wld_createdon;
      WiObj.WI_FillerMetalSize = UpdatedWps[0]?.WPSD_WP_FillerMetalSIze;
      // WiObj.WI_WeldConsumable = UpdatedWps[0]?.WPSD_WP_WeldConsumable;
      WiObj.WI_WP_BatchNumber = UpdatedWps[0]?.WPSD_WP_BatchNumber;
      WiObj.WI_ItemDesc = UpdatedWps[0]?.welddesc;
      WiObj.WI_WCinKgElectrode = UpdatedWps[0]?.weldweight;
      WiObj.WI_FM_Brand = UpdatedWps[0]?.WPSD_FM_ChemicalAnalysis_TradeName;
      WiObj.WI_FM_AWS = UpdatedWps[0]?.WPSD_FM_Classification;
      WiObj.WI_ProcessCategory = UpdatedWps[0]?.WPSD_ProcessCategory;



      if (getheaderData[0]?.wld_WPSType === "Tubesheet") {
        WiObj.WI_WeldConsumable = UpdatedWps[0]?.WPSD_WP_ItemCode;
        WiObj.WI_ItemDesc = UpdatedWps[0]?.welddesc;
        WiObj.WI_WCinKgElectrode = UpdatedWps[0]?.weldweight;
      }
      else{
        WiObj.WI_WeldConsumable = UpdatedWps[0]?.WPSD_WP_WeldConsumable;
        WiObj.WI_Weld_Max = UpdatedWps[0]?.WPSD_FM_TRQ_Max;


      }



      

      // WiObj.WI_ItemDesc = UpdatedWps[0].
      WiObj.WPS_Rev_no = UpdatedWps[0]?.WPSH_WPS_Revi_No;
      WiObj.WI_CreatedBy = emailid;
      WiObj.WI_CreatedBy_Name = username;
      WiObj.WI_WPSHeaderSK = getheaderData[0]?.SK;
      WiObj.WI_WPSDetailSK = UpdatedWps[0]?.SK;
      WiObj.WI_JointDesign = getheaderData[0]?.wld_WPSType;


      // WPSH_PWHeatTre_Wrap_insulation_material

      // WiObj.WI_plant = getwpqheaderData[0]?.WPQ_PlantLocation;
      // WiObj.WPQ_Process_Category = getwpqheaderData[0]?.WPQ_Process_Category;

      // WiObj.WI_WPQ_PrcessStatus = getwpqheaderData[0]?.remainingday

      console.log("activeee");

      if (getheaderData[0]?.wld_WPSType === "Tubesheet") {
        // alert("Tubesheet")

        result = addwelder(WiObj);
        console.log("resultttrttrtrtrtrtrt", result);
      } else {
        let wpqheaderpk = DatePK[0]?.resultpk;
        let wpqparts = wpqheaderpk.split("#");
        let wpqfirstPart = wpqparts[0];
        let wpqsecondPart = wpqparts[1];

        console.log("wpqheaderpkkk", wpqheaderpk);

        result = getbyprojectnoWPQ_WI(
          Filterwelder[0]?.wld_WPSType,
          wpqfirstPart,
          wpqsecondPart
        )
          .then((result) => {
            console.log("result1122222211", result);
            // WiObj.WI_plant = result[0]?.WPQ_PlantLocation;
            WiObj.WI_WeldDepThick = result[0]?.WQC_DepThick;

            

            return addwelder(WiObj);
          })
          .catch((error) => {
            console.error("Error:", error);
          });

        // result = addwelder(WiObj);
        console.log("resaacs", result);

        if (DatePK[0]?.resultpk !== "" && DateSK[0]?.resultsk !== "") {
          if (
            DatePK[0]?.resultpk !== undefined &&
            DateSK[0]?.resultsk !== undefined
          ) {
            let inputString = DatePK[0]?.resultpk;
            let parts = inputString.split("#");
            let firstPart = parts[0];
            let secondPart = parts[1];

            let inputString2 = DateSK[0]?.resultsk;
            let parts1 = inputString2.split("#");
            let firstPart1 = parts1[0];
            let secondPart1 = parts1[1];

            //check
            result1 = getdetailsInWI(
              firstPart,
              secondPart,
              firstPart1,
              secondPart1
            );

            console.log("ffsad", result1);

            result = updatedatewpq(
              firstPart,
              secondPart,
              firstPart1,
              secondPart1
            );
          }
        }
      }
      result
        .then((resp) => {
          setshowConfirmModel(false);
          setShowSuccessModel(false);
          console.log("reesssssssaaa", resp);
          if (action === "Add") {
            toast.success(`Selected WI Slip has been Added successfully`, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
          }
          if (resp.message === "Duplicate") {
            toast.warning(resp.message, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
          }
          props.setReload(!props.reload);
          setShowForm(false);
          props.onHide();
        })
        .catch((error) => {
          console.log("Unable to process request");
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (error.WI_Overlay_Joint_BMT.status !== true) {
      setshowConfirmModel(true);
    }

    // if (!validateWeldingProcess(action)) {
    // }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed WI Slip successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} New </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div
              className="px-2 pb-2 font"
              style={{ position: "relative", top: "12px" }}
            >
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col>
                  <Form.Group>
                    <div class="T-input w-100">
                      <input
                        type="text"
                        id="WI_Slip"
                        name="WI_Slip"
                        placeholder=" "
                        readOnly
                        // disabled={Readonly}
                        maxLength={10}
                        className={error.wld_code.status ? "err-input" : ""}
                        // disabled={action === Action.ActionEdit ? true : false}
                        value={action === "Add" ? slipno : WiObj.WI_Slip}
                      />
                      <label for="wld_code">Slip Number</label>
                    </div>
                    <div className="required-text">
                      {error.wld_code.status && (
                        <Form.Text className="text-danger">
                          {error.wld_code.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="promd4-input-select">
                    <div className="search-select">
                      <SearchSelectProject
                        // className="inspdrop"
                        id="WI_Project_No"
                        name="WI_Project_No"
                        // className={error.WPSH_Project_No.status ? "err-input" : ""}
                        stylegreen={
                          error.wld_code.status ? errorstyle : normalstyle
                        }
                        data={ProjectDetails}
                        stylenormal={normalstyle}
                        valueField={"wld_ProjectNumber"}
                        // disabled={action === Action.ActionEdit ? true : false}
                        value={WiObj.WI_Project_No}
                        labelField={"wld_ProjectNumber"}
                        placeholder=""
                        onChange={async (ProjectList) => {
                          console.log("wcsprojectdata:", wcsprojectdata);
                          setWiObj((prev) => ({
                            ...prev,
                            WI_Project_No: ProjectList.value,
                            WCS_Project_No: ProjectList.value,
                            WI_Joint_No: "",
                            WI_WPS_No: "",
                            WI_Process: "",
                            WI_Type: "",
                          }));

                          try {
                            const result = await getWCSDataHeader({
                              WCS_Project_No: ProjectList.value,
                            });

                            // const linkproject = await linkbyproject({
                            //   WI_Project_No: ProjectList.value,
                            // });

                            // setLinkWpq(linkproject);
                            // console.log("wcsresult", result);

                            // console.log("pprroojlink", linkproject);
                            setwcsprojectdata(result);

                            if (!result || result.length === 0) {
                              alert(
                                `Project No: ${ProjectList.value} is not approved!`
                              );
                              setWiObj((prev) => ({
                                ...prev,
                                WI_Project_No: "",
                                WCS_Project_No: "",
                                WI_Joint_No: "",
                                WI_WPS_No: "",
                                WI_Process: "",
                                WI_Type: "",
                              }));
                            }
                          } catch (error) {
                            console.error("Error fetching data:", error);
                          }
                        }}
                      ></SearchSelectProject>
                    </div>
                    <label>Project Number</label>
                    {/* <div className="required-text-select">
                  {error.WPSH_Project_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_Project_No.message}
                    </Form.Text>
                  )}
                </div> */}
                  </Form.Group>
                </Col>

                <Col md={4}>
              <Form.Group>
                <General_Single_Input_select
                  name="WI_plant"
                  type="text"
                  maxLength={20}
                  // readOnly={isReadonly}
                  // disabled={isDisabled}
                  status={error.WI_plant.status}
                  value={WiObj?.WI_plant}
                  handleSelectChange={handleChange}
                  label="Plant"
                  // message={error.WPQ_PlantLocation.message}
                  array={Plant_Location_WI}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>

                <Col md={4}>
                  <Form.Group className="promd4-input-select">
                    <div className="search-select">
                      <SearchSelectProject
                        // className="inspdrop"
                        id="WI_Joint_No"
                        name="WI_Joint_No"
                        // className={error.WPSH_Project_No.status ? "err-input" : ""}
                        stylegreen={
                          error.wld_code.status ? errorstyle : normalstyle
                        }
                        data={getJointNosForSelectedProject()}
                        // data={wcsprojectdata}
                        stylenormal={normalstyle}
                        valueField={"value"}
                        // disabled={action === Action.ActionEdit ? true : false}
                        value={WiObj.WI_Joint_No}
                        labelField={"label"}
                        placeholder=""
                        onChange={(ProjectList) => {
                          setWiObj((prev) => ({
                            ...prev,
                            WI_Joint_No: ProjectList.value,
                            WI_WPS_No: "",
                            WI_Process: "",
                            WI_Type: "",
                          }));
                        }}
                      ></SearchSelectProject>
                    </div>
                    <label>Joint Number</label>
                  </Form.Group>
                </Col>

                <Col md={4} >
                  <Form.Group className="promd4-input-select">
                    <div className="search-select">
                      <SearchSelectProject
                        // className="inspdrop"
                        id="WI_WPS_No"
                        name="WI_WPS_No"
                        // className={error.WPSH_Project_No.status ? "err-input" : ""}
                        stylegreen={
                          error.wld_code.status ? errorstyle : normalstyle
                        }
                        data={getWPsnoForSelectedProject()}
                        stylenormal={normalstyle}
                        valueField={"value"}
                        // disabled={action === Action.ActionEdit ? true : false}
                        value={WiObj.WI_WPS_No}
                        labelField={"label"}
                        placeholder=""
                        onChange={async (ProjectList) => {
                          setWiObj((prev) => ({
                            ...prev,
                            WI_WPS_No: ProjectList.value,
                          }));

                          /*   let result = await getbywpsnowi(ProjectList.value);
                          setwpstypecheck(result);
                          console.log("reeeeessddfs", result); */
                        }}
                      ></SearchSelectProject>
                    </div>
                    <label>WPS Number</label>
                  </Form.Group>
                </Col>
                <Col md={4}>
</Col>
<Col md={4}>
  <Form.Group className="promd4-input-select">
    <div className="search-select">
      <SearchSelectProject
        id="WI_Process"
        name="WI_Process"
        stylegreen={error.wld_code.status ? errorstyle : normalstyle}
        data={getWpsData}
        stylenormal={normalstyle}
        valueField={"WPSD_Process"}
        value={WiObj.WI_Process}
        labelField={"WPSD_Process"}
        placeholder=""
        onChange={(ProjectList) => {
          let pcat = getWpsData.filter((b) => {
            console.log("gggg", getWpsData);
            return b.WPSD_Process === ProjectList.value;
          });

          console.log("fpfpf",pcat)

          // let processcount = a[0].SK.split("#")
          // let Count = processcount[2]
          // console.log("lflfk",processcount,Count)
          setWiObj((prev) => {
            if(pcat[0].wld_WPSType === "Tubesheet"){

              console.log("ttt");

            const updatedProcesses = [
              {
                ...prev.Processes[0],
                SK: pcat[0].SK,
                type: pcat[0].wld_WPSType
              },
              ...prev.Processes.slice(1),
            ];

            updatewi(updatedProcesses);

            return {
              ...prev,
              WI_Process: pcat[0].WPSD_Process,
              WI_Type: pcat[0].WPSD_WP_Type,
              Processes: updatedProcesses,
            };

          }
          else{
            console.log("gooo");
            debugger
            if (pcat.length === 1) {
              const updatedProcesses = [
                {
                  ...prev.Processes[0],
                  SK: pcat[0].WPSD_ProcessCategory
                },
                ...prev.Processes.slice(1),
              ];

              updatewi(updatedProcesses);

              return {
                ...prev,
                WI_Process: ProjectList.value,
                WI_Type: pcat[0].WPSD_Type,
                Processes: updatedProcesses,
              };
            }
            else if (pcat.length > 1) {

            const updatedProcesses = [
              {
                ...prev.Processes[0],
                // SK: a[0].WPSD_ProcessCategory + "#" + a[0].WPSD_Process +"#" + a[0].WPSD_Type 
                SK: "",
              },
              ...prev.Processes.slice(1),
            ];

            updatewi(updatedProcesses);


            return {
              ...prev,
              WI_Process: ProjectList.value,
              WI_Type: "",
              Processes: updatedProcesses,
            };
          }
          }

          
          });
        }}
      />
    </div>
    <label>Process</label>
  </Form.Group>
</Col>

<Col md={4}>
  <Form.Group>
    <div className="T-input w-100">
      {getWpsData.filter((item) => item.WPSD_Process === WiObj.WI_Process)
        .length > 1 ? (
        // Dropdown for multiple types
        <select
          id="WI_Type"
          name="WI_Type"
          value={WiObj.WI_Type}
          onChange={(e) => {

            debugger
            let pcat = getWpsData.filter((b) => {
              console.log("gggdddg", getWpsData);
              return b.WPSD_Type === e.target.value;
            });
  
            console.log("fpfpfewwww",pcat)

              setWiObj((prev) => {             
            const updatedProcesses = [
              {
                ...prev.Processes[0],
                SK: pcat[0].WPSD_ProcessCategory,
              },
              ...prev.Processes.slice(1),
            ];
            updatewi(updatedProcesses);

            return {
              ...prev,
              WI_Process: WiObj.WI_Process,
              WI_Type: e.target.value,
              Processes: updatedProcesses,
            };
          })

          }}
        >
          <option value="">Select Type</option>
          {getWpsData
            .filter((item) => item.WPSD_Process === WiObj.WI_Process)
            .map((typeItem, index) => (
              <option key={index} value={typeItem.WPSD_Type}>
                {typeItem.WPSD_Type}
              </option>
            ))}
        </select>
      ) : (
        // Auto-fill type input if only one type
        <input
          type="text"
          id="WI_Type"
          name="WI_Type"
          placeholder=" "
          readOnly
          maxLength={10}
          className={error.wld_code.status ? "err-input" : ""}
          value={
            getWpsData.filter(
              (item) => item.WPSD_Process === WiObj.WI_Process
            )[0]?.WPSD_Type || WiObj.WI_Type
          }
        />
      )}
      <label htmlFor="WI_Type">Type</label>
    </div>
    <div className="required-text">
      {error.wld_code.status && (
        <Form.Text className="text-danger">
          {error.wld_code.message}
        </Form.Text>
      )}
    </div>
  </Form.Group>
</Col>
                <Col md={4} >
                  </Col>


                <Col md={4}>
                  <Form.Group>
                    <div class="T-input w-100">
                      <input
                        type="text"
                        id="WI_Overlay_Joint_BMT"
                        name="WI_Overlay_Joint_BMT"
                        placeholder=" "
                        // disabled={Readonly}
                        maxLength={10}
                        className={error.wld_code.status ? "err-input" : ""}
                        value={WiObj.WI_Overlay_Joint_BMT}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onInput={(e) => {
                          let error = twodecimal(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            WI_Overlay_Joint_BMT: error,
                          }));
                        }}
                      />
                      <label for="WI_Overlay_Joint_BMT">
                        Overlay Joint Base Metal Thickness
                      </label>
                    </div>
                    <div className="required-text">
                      {error.WI_Overlay_Joint_BMT.status && (
                        <Form.Text className="text-danger">
                          {error.WI_Overlay_Joint_BMT.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row
                className="gx-3"
                style={{
                  marginBottom: "2rem",
                  position: "relative",
                  bottom: "18px",
                }}
              >
                <Col md={2}>
                  <Form.Group>
                    <div>
                      <input
                        type="radio"
                        name="WI_PipePlate"
                        value="Pipe"
                        checked={WiObj.WI_PipePlate === "Pipe"}
                        onChange={(e) => {
                          handleChange(e);

                          setWiObj((prev) => ({
                            ...prev,
                            WI_Pipe_Diameter: "",
                            WI_Plate_No: "",
                            WI_Welding_Position: "",
                            WI_Welder_Id: "",
                            WI_Welder_Name: "",
                          }));
                        }}
                      />
                      <span className="ms-2">Pipe</span>
                    </div>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <div>
                      <input
                        type="radio"
                        name="WI_PipePlate"
                        value="Plate"
                        checked={WiObj.WI_PipePlate === "Plate"}
                        onChange={(e) => {
                          handleChange(e);
                          setWiObj((prev) => ({
                            ...prev,
                            WI_Plate_No: "73",
                            WI_Pipe_Diameter: "",
                            WI_Welding_Position: "",
                            WI_Welder_Id: "",
                            WI_Welder_Name: "",
                          }));
                        }}
                      />
                      <span className="ms-2">Plate</span>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row
                className="gx-3"
                style={{ position: "relative", bottom: "25px" }}
              >
                <Col md={4}>
                  <Form.Group>
                    <div class="T-input w-100">
                      <input
                        type="text"
                        id="WI_Pipe_Diameter"
                        name="WI_Pipe_Diameter"
                        placeholder=" "
                        disabled={WiObj.WI_PipePlate === "Plate"}
                        maxLength={10}
                        className={error.wld_code.status ? "err-input" : ""}
                        value={WiObj.WI_Pipe_Diameter}
                        onChange={(e) => {
                          handleChange(e);

                          setWiObj((prev) => ({
                            ...prev,
                            WI_Pipe_Diameter: e.target.value,
                            WI_Welding_Position: "",
                            WI_Welder_Id: "",
                            WI_Welder_Name: "",
                          }));
                        }}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpaceDotDash(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_code: error,
                          }));
                        }}
                      />
                      <label for="wld_code">Pipe Diameter</label>
                    </div>
                    <div className="required-text">
                      {error.wld_code.status && (
                        <Form.Text className="text-danger">
                          {error.wld_code.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="promd4-input-select">
                    <div className="search-select">
                      <SearchSelectProject
                        // className="inspdrop"
                        id="WI_Welding_Position"
                        name="WI_Welding_Position"
                        // className={error.WPSH_Project_No.status ? "err-input" : ""}
                        stylegreen={
                          error.wld_code.status ? errorstyle : normalstyle
                        }
                        data={Welding_Position}
                        stylenormal={normalstyle}
                        valueField={"name"}
                        // disabled={action === Action.ActionEdit ? true : false}
                        value={WiObj.WI_Welding_Position}
                        labelField={"name"}
                        placeholder=""
                        onChange={(ProjectList) => {
                          if (
                            WiObj.WI_Project_No !== "" &&
                            WiObj.WI_WPS_No !== ""
                          ) {
                            setWiObj((prev) => ({
                              ...prev,
                              WI_Welding_Position: ProjectList.value,
                              WI_Welder_Id: "",
                              WI_Welder_Name: "",
                            }));
                            if (wpstypecheck.length <= 0) {
                              console.log("enter");
                              // getselectedwelder(WiObj);
                            }
                          } else {
                            setWiObj((prev) => ({
                              ...prev,
                              WI_Welding_Position: "",
                              WI_Welder_Id: "",
                              WI_Welder_Name: "",
                            }));
                          }

                          // setError((prevState) => ({
                          //   ...prevState,
                          //   WPSH_Project_No: {},
                          // }));
                          // for (var i in detailObj) {
                          //   detailObj[i].WPSH_Project_No = ProjectList.value;
                          // }
                        }}
                        // onInput={(e) => {
                        //   let error = isAlphanumericWithDash(e.target.value);
                        //   setError((prevState) => ({
                        //     ...prevState,
                        //     WPSH_Project_No: error,
                        //   }));
                        // }}
                      ></SearchSelectProject>
                    </div>
                    <label>Welding Position</label>
                    {/* <div className="required-text-select">
                  {error.WPSH_Project_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_Project_No.message}
                    </Form.Text>
                  )}
                </div> */}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="promd4-input-select">
                    <div className="search-select">
                      <SearchSelectProject
                        // className="inspdrop"
                        id="WI_Welder_Id"
                        name="WI_Welder_Id"
                        // className={error.WPSH_Project_No.status ? "err-input" : ""}
                        stylegreen={
                          error.wld_code.status ? errorstyle : normalstyle
                        }
                        data={getwelder}
                        stylenormal={normalstyle}
                        valueField={"WPQ_WelderId"}
                        // disabled={action === Action.ActionEdit ? true : false}
                        value={WiObj.WI_Welder_Id}
                        labelField={"WPQ_WelderId"}
                        placeholder=""
                        onChange={(ProjectList) => {
                          let a = getwelder.filter((b) => {
                            console.log("gggg", getWpsData);
                            return b.WPQ_WelderId === ProjectList.value;
                          });
                          setWiObj((prev) => ({
                            ...prev,
                            WI_Welder_Id: ProjectList.value,
                            WI_Welder_Id: a[0]?.WPQ_WelderId,
                            WI_Welder_Name: a[0]?.WPQ_WelderName,
                          }));
                          // setError((prevState) => ({
                          //   ...prevState,
                          //   WPSH_Project_No: {},
                          // }));
                          // for (var i in detailObj) {
                          //   detailObj[i].WPSH_Project_No = ProjectList.value;
                          // }
                        }}
                        // onInput={(e) => {
                        //   let error = isAlphanumericWithDash(e.target.value);
                        //   setError((prevState) => ({
                        //     ...prevState,
                        //     WPSH_Project_No: error,
                        //   }));
                        // }}
                      ></SearchSelectProject>
                    </div>
                    <label>Welder Code</label>
                    {/* <div className="required-text-select">
                  {error.WPSH_Project_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_Project_No.message}
                    </Form.Text>
                  )}
                </div> */}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div class="T-input w-100">
                      <input
                        type="text"
                        id="WI_Welder_Name"
                        name="WI_Welder_Name"
                        placeholder=" "
                        readOnly
                        // disabled={Readonly}
                        maxLength={10}
                        className={error.wld_code.status ? "err-input" : ""}
                        value={WiObj.WI_Welder_Name}
                        // onChange={(e) => {
                        //   handleChange(e);
                        // }}
                        // onInput={(e) => {
                        //   let error = isAlphanumericWithSpaceDotDash(
                        //     e.target.value
                        //   );
                        //   setError((prevState) => ({
                        //     ...prevState,
                        //     wld_code: error,
                        //   }));
                        // }}
                      />
                      <label for="wld_code">Welder Name</label>
                    </div>
                    <div className="required-text">
                      {error.wld_code.status && (
                        <Form.Text className="text-danger">
                          {error.wld_code.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

          {/* <Row>
            <div>
              <div
                class={
                  action === "Edit"
                    ? " float-start m-1 invisible"
                    : "float-start m-1 visible"
                }
              >
                &nbsp;&nbsp;&nbsp;
                <Button variant="outline-primary" onClick={resetForm}>
                  Reset
                </Button>
              </div>
              <div class="float-end m-1">
                <Button type="submit" className="btn-add-custom">
                  {action === "Edit" ? "Update" : "Add"}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </Row> */}

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditWIPage;
