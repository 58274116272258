import React from "react";


const UnAuthorized = () => {
  // if (window.performance) {
  //   if (performance.navigation.type == 1) {
  //     alert("This page is reloaded");
  //   } else {
  //     alert("This page is not reloaded");
  //   }
  // }
  return (
    <>
      <main className="box">
        <div className="app-content">You don't have access to the page</div>
      </main>
    </>
  );
};

export default UnAuthorized;
