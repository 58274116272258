//TABLE WITH 3 ROWS AND 6 COLUMNS (WPQ- BASE METAL TABLE)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {
  isAlphanumericAllSpecialCharacters,
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { SearchSelect } from "../search-select";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const BaseMetalTable_PQR = ({
  headerObj,
  error,
  isReadonly,
  isDisabled,
  handleChange,
  setError,
  PNoActive,
  setheaderObj,
  errorstyle,
  blyestyle,
  id,
}) => {
  console.log("BaseMetalTable_PQR_headerObj", headerObj);
  const [IsEdited, setIsEdited] = useState(false);
  const location = useLocation();

  const [action, setaction] = useState(location.state?.action);

  const handleEditClick = (id) => {
    console.log("idd", id);
    setIsEdited(true);
  };

  const handleEditClick1 = () => {
    setIsEdited(true);
  };

  const handleEditCancel = () => {
    setIsEdited((prevState) => ({
      ...prevState,
      PQR_BM_BM_ProductForm: false,
      PQR_BM_BM_Spec: false,
    }));
  };
  return (
    <Table className="text-center tablewidth ">
      <thead>
        <tr className="border thead-color ">
          <th className="thickness_title" style={{ width: "21%" }}></th>
          <th className="thickness_title tablethwidth ">
            Product Form
          </th>
          <th className="thickness_title tablethwidth">
            Spec (type of Grade),UNS# 
          </th>
          <th
            className="thickness_title tablethwidth"
            style={{ width: "105px" }}
          >
            P No.
          </th>
          <th className="thickness_title tablethwidth ">G No.</th>
          <th className="thickness_title tablethwidth ">Size </th>
          <th className="thickness_title tablethwidth">Sch </th>
          <th className="thickness_title tablethwidth">Thk mm</th>
          <th className="thickness_title tablethwidth">Dia mm </th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            error.PQR_BM_BM_ProductForm &&
            (error.PQR_BM_BM_ProductForm.status ||
              error.PQR_BM_BM_Spec.status ||
              error.PQR_BM_BM_Gno.status ||
              error.PQR_BM_BM_Size.status ||
              error.PQR_BM_BM_Sch.status ||
              error.PQR_BM_BM_Thk.status ||
              error.PQR_BM_BM_Dia.status ||
              error.PQR_BM_BM_Pno.status)
              ? "border error"
              : "border"
          }
        >
          <th
            className={
              error.PQR_BM_BM_ProductForm &&
              (error.PQR_BM_BM_ProductForm.status ||
                error.PQR_BM_BM_Spec.status ||
                error.PQR_BM_BM_Gno.status ||
                error.PQR_BM_BM_Size.status ||
                error.PQR_BM_BM_Sch.status ||
                error.PQR_BM_BM_Thk.status ||
                error.PQR_BM_BM_Dia.status ||
                error.PQR_BM_BM_Pno.status)
                ? "text-start border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>Base Metal 1</label>
              {error.PQR_BM_BM_ProductForm &&
                (error.PQR_BM_BM_ProductForm.status ||
                  error.PQR_BM_BM_Spec.status ||
                  error.PQR_BM_BM_Gno.status ||
                  error.PQR_BM_BM_Size.status ||
                  error.PQR_BM_BM_Sch.status ||
                  error.PQR_BM_BM_Thk.status ||
                  error.PQR_BM_BM_Dia.status ||
                  error.PQR_BM_BM_Pno.status) && (
                  <Tooltip
                    content={
                      error.PQR_BM_BM_ProductForm.message ||
                      error.PQR_BM_BM_Spec.message ||
                      error.PQR_BM_BM_Gno.message ||
                      error.PQR_BM_BM_Size.message ||
                      error.PQR_BM_BM_Sch.message ||
                      error.PQR_BM_BM_Thk.message ||
                      error.PQR_BM_BM_Dia.message ||
                      error.PQR_BM_BM_Pno.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>

          <td className=" WpsInput-md">
            <input
              id="PQR_BM_BM_ProductForm"
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_BM_ProductForm &&
                error.PQR_BM_BM_ProductForm.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_ProductForm"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 15 : 15}
              value={headerObj?.PQR_BM_BM_ProductForm}
              readOnly={isReadonly}
              disabled={isDisabled}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_ProductForm: error,
                }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_ProductForm: error,
                  }));
                }
              }}
            />
          </td>

          <td className="WpsInput-md">
            <input
              id="PQR_BM_BM_Spec"
              type="text"
              style={{ height: "38px" }}
              className={
                error.PQR_BM_BM_Spec && error.PQR_BM_BM_Spec.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Spec"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 25 : 25}
              value={headerObj?.PQR_BM_BM_Spec}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Spec: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Spec: error,
                  }));
                }
              }}
            />
          </td>
          <td className=" WPSSelectsm">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.PQR_BM_BM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj?.PQR_BM_BM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={error.PQR_BM_BM_Pno.status ? "error-input " : ""}
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  PQR_BM_BM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md ">
            <input
              type="text"
              style={{ height: "38px" }}
              className={
                error.PQR_BM_BM_Gno && error.PQR_BM_BM_Gno.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Gno"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 5 : 5}
              value={headerObj?.PQR_BM_BM_Gno}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Gno: error,
                }));
              } else {
                let error = isAlphanumericAllSpecialCharacters(
                  e.target.value
                );
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Gno: error,
                }));}
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_BM_Size && error.PQR_BM_BM_Size.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Size"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj?.PQR_BM_BM_Size}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Size: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Size: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_BM_Sch && error.PQR_BM_BM_Sch.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Sch"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj?.PQR_BM_BM_Sch}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Sch: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Sch: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_BM_Thk && error.PQR_BM_BM_Thk.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Thk"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 10}
              value={headerObj?.PQR_BM_BM_Thk}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Thk: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Thk: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_BM_Dia && error.PQR_BM_BM_Dia.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Dia"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj?.PQR_BM_BM_Dia}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Dia: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_BM_Dia: error,
                  }));
                }
              }}
            />
          </td>
        </tr>
        <tr
          className={
            error.PQR_BM_WTBM_ProductForm &&
            (error.PQR_BM_WTBM_ProductForm.status ||
              error.PQR_BM_WTBM_Spec.status ||
              error.PQR_BM_WTBM_Pno.status ||
              error.PQR_BM_WTBM_Gno.status ||
              error.PQR_BM_WTBM_Size.status ||
              error.PQR_BM_WTBM_Sch.status ||
              error.PQR_BM_WTBM_Thk.status ||
              error.PQR_BM_WTBM_Dia.status)
              ? "border  error"
              : "border "
          }
        >
          <th
            className={
              error.PQR_BM_WTBM_ProductForm &&
              (error.PQR_BM_WTBM_ProductForm.status ||
                error.PQR_BM_WTBM_Spec.status ||
                error.PQR_BM_WTBM_Pno.status ||
                error.PQR_BM_WTBM_Gno.status ||
                error.PQR_BM_WTBM_Size.status ||
                error.PQR_BM_WTBM_Sch.status ||
                error.PQR_BM_WTBM_Thk.status ||
                error.PQR_BM_WTBM_Dia.status)
                ? "text-start  border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              Welded To - Base Metal 2
              {error.PQR_BM_WTBM_ProductForm &&
                (error.PQR_BM_WTBM_ProductForm.status ||
                  error.PQR_BM_WTBM_Spec.status ||
                  error.PQR_BM_WTBM_Pno.status ||
                  error.PQR_BM_WTBM_Gno.status ||
                  error.PQR_BM_WTBM_Size.status ||
                  error.PQR_BM_WTBM_Sch.status ||
                  error.PQR_BM_WTBM_Thk.status ||
                  error.PQR_BM_WTBM_Dia.status) && (
                  <Tooltip
                    content={
                      error.PQR_BM_WTBM_ProductForm.message ||
                      error.PQR_BM_WTBM_Spec.message ||
                      error.PQR_BM_WTBM_Pno.message ||
                      error.PQR_BM_WTBM_Gno.message ||
                      error.PQR_BM_WTBM_Size.message ||
                      error.PQR_BM_WTBM_Sch.message ||
                      error.PQR_BM_WTBM_Thk.message ||
                      error.PQR_BM_WTBM_Dia.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_ProductForm &&
                error.PQR_BM_WTBM_ProductForm.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_ProductForm"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 10 : 15}
              value={headerObj?.PQR_BM_WTBM_ProductForm}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_ProductForm: error,
                }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_ProductForm: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Spec && error.PQR_BM_WTBM_Spec.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Spec"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 25 : 25}
              value={headerObj?.PQR_BM_WTBM_Spec}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Spec: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Spec: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WPSSelect">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.PQR_BM_WTBM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj?.PQR_BM_WTBM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={
                error.PQR_BM_WTBM_Pno.status
                  ? "err-input WPSSelect"
                  : "WPSSelect"
              }
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  PQR_BM_WTBM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Gno && error.PQR_BM_WTBM_Gno.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Gno"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 5 : 5}
              value={headerObj?.PQR_BM_WTBM_Gno}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Gno: error,
                }));
              } else {
                let error = isAlphanumericAllSpecialCharacters(
                  e.target.value
                );
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Gno: error,
                }));
              }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Size && error.PQR_BM_WTBM_Size.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Size"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj?.PQR_BM_WTBM_Size}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Size: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Size: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Sch && error.PQR_BM_WTBM_Sch.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Sch"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj?.PQR_BM_WTBM_Sch}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Sch: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Sch: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Thk && error.PQR_BM_WTBM_Thk.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Thk"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 10}
              value={headerObj?.PQR_BM_WTBM_Thk}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Thk: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Thk: error,
                  }));
                }
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Dia && error.PQR_BM_WTBM_Dia.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Dia"}
              maxLength={headerObj?.wld_WPSType === "Tubesheet" ? 6 : 6}
              value={headerObj?.PQR_BM_WTBM_Dia}
              onChange={handleChange}
              onInput={(e) => {
                if (headerObj?.wld_WPSType === "Tubesheet") {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Dia: error,
                  }));
                } else {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    PQR_BM_WTBM_Dia: error,
                  }));
                }
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export const BaseMetalTable_PQRTube = ({
  headerObj,
  error,
  isReadonly,
  isDisabled,
  handleChange,
  setError,
  PNoActive,
  setheaderObj,
  errorstyle,
  blyestyle,
  id,
}) => {
  console.log("BaseMetalTable_PQR_headerObj", headerObj);
  const [IsEdited, setIsEdited] = useState(false);
  const location = useLocation();

  const [action, setaction] = useState(location.state?.action);

  const handleEditClick = (id) => {
    console.log("idd", id);
    setIsEdited(true);
  };

  const handleEditClick1 = () => {
    setIsEdited(true);
  };

  const handleEditCancel = () => {
    setIsEdited((prevState) => ({
      ...prevState,
      PQR_BM_BM_ProductForm: false,
      PQR_BM_BM_Spec: false,
    }));
  };
  return (
    <Table className="text-center tablewidth ">
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
            <th  className="thickness_title" key={head}>{head}</th>
          ))} */}
          <th className="thickness_title" style={{ width: "21%" }}></th>
          <th className="thickness_title tablethwidth ">
            Product Form{" "}
            <label style={{ fontStyle: "italic", color: "var(--col-707070)", fontWeight:"100"}}>
              (Optional)
            </label>
          </th>

          <th className="thickness_title tablethwidth">
            Spec (type of Grade),UNS{" "}
            <label style={{ fontStyle: "italic", color: "var(--col-707070)", fontWeight:"100" }}>
              (Optional)
            </label>
          </th>

          <th
            className="thickness_title tablethwidth"
            style={{ width: "105px" }}
          >
            P No. /A No.{" "}
            <label style={{ fontStyle: "italic", color: "var(--col-707070)", fontWeight:"100"}}>
              (Optional)
            </label>
          </th>
          <th className="thickness_title tablethwidth ">
            G No.{" "}
            <label style={{ fontStyle: "italic", color: "var(--col-707070)" , fontWeight:"100"}}>
              (Optional)
            </label>
          </th>
          <th className="thickness_title tablethwidth">
            Thk.mm{" "}
            <label style={{ fontStyle: "italic", color: "var(--col-707070)",fontWeight:"100" }}>
              (Optional)
            </label>
          </th>
          <th className="thickness_title tablethwidth">
            Dia.mm{" "}
            <label style={{ fontStyle: "italic", color: "var(--col-707070)", fontWeight:"100" }}>
              (Optional)
            </label>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            error.PQR_BM_BM_ProductForm &&
            (error.PQR_BM_BM_ProductForm.status ||
              error.PQR_BM_BM_Spec.status ||
              error.PQR_BM_BM_Gno.status ||
              error.PQR_BM_BM_Thk.status ||
              error.PQR_BM_BM_Dia.status ||
              error.PQR_BM_BM_Pno.status)
              ? "border error"
              : "border"
          }
        >
          <th
            className={
              error.PQR_BM_BM_ProductForm &&
              (error.PQR_BM_BM_ProductForm.status ||
                error.PQR_BM_BM_Spec.status ||
                error.PQR_BM_BM_Gno.status ||
                error.PQR_BM_BM_Thk.status ||
                error.PQR_BM_BM_Dia.status ||
                error.PQR_BM_BM_Pno.status)
                ? "text-start border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              <label>Base Metal 1</label>
              {error.PQR_BM_BM_ProductForm &&
                (error.PQR_BM_BM_ProductForm.status ||
                  error.PQR_BM_BM_Spec.status ||
                  error.PQR_BM_BM_Gno.status ||
                  error.PQR_BM_BM_Thk.status ||
                  error.PQR_BM_BM_Dia.status ||
                  error.PQR_BM_BM_Pno.status) && (
                  <Tooltip
                    content={
                      error.PQR_BM_BM_ProductForm.message ||
                      error.PQR_BM_BM_Spec.message ||
                      error.PQR_BM_BM_Gno.message ||
                      error.PQR_BM_BM_Thk.message ||
                      error.PQR_BM_BM_Dia.message ||
                      error.PQR_BM_BM_Pno.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>

          <td className=" WpsInput-md">
            <input
              id="PQR_BM_BM_ProductForm"
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_BM_ProductForm &&
                error.PQR_BM_BM_ProductForm.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_ProductForm"}
              maxLength={15}
              value={headerObj?.PQR_BM_BM_ProductForm}
              readOnly={isReadonly}
              disabled={isDisabled}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_ProductForm: error,
                }));
              }}
            />
          </td>

          <td className="WpsInput-md">
            <input
              id="PQR_BM_BM_Spec"
              type="text"
              style={{ height: "38px",width:"135px" }}
              className={
                error.PQR_BM_BM_Spec && error.PQR_BM_BM_Spec.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Spec"}
              maxLength={25}
              value={headerObj?.PQR_BM_BM_Spec}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Spec: error,
                }));
              }}
            />
          </td>
          <td className=" WPSSelectsm">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.PQR_BM_BM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj?.PQR_BM_BM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={error.PQR_BM_BM_Pno.status ? "error-input " : ""}
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  PQR_BM_BM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md ">
            <input
              type="text"
              style={{ height: "38px",width:"135px" }}
              className={
                error.PQR_BM_BM_Gno && error.PQR_BM_BM_Gno.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Gno"}
              maxLength={5}
              value={headerObj?.PQR_BM_BM_Gno}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Gno: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_BM_Thk && error.PQR_BM_BM_Thk.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Thk"}
              maxLength={10}
              value={headerObj?.PQR_BM_BM_Thk}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Thk: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_BM_Dia && error.PQR_BM_BM_Dia.status
                  ? "error-input"
                  : ""
              }
              name={"PQR_BM_BM_Dia"}
              maxLength={6}
              value={headerObj?.PQR_BM_BM_Dia}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_BM_Dia: error,
                }));
              }}
            />
          </td>
        </tr>
        <tr
          className={
            error.PQR_BM_WTBM_ProductForm &&
            (error.PQR_BM_WTBM_ProductForm.status ||
              error.PQR_BM_WTBM_Spec.status ||
              error.PQR_BM_WTBM_Pno.status ||
              error.PQR_BM_WTBM_Gno.status ||
              error.PQR_BM_WTBM_Thk.status ||
              error.PQR_BM_WTBM_Dia.status)
              ? "border  error"
              : "border "
          }
        >
          <th
            className={
              error.PQR_BM_WTBM_ProductForm &&
              (error.PQR_BM_WTBM_ProductForm.status ||
                error.PQR_BM_WTBM_Spec.status ||
                error.PQR_BM_WTBM_Pno.status ||
                error.PQR_BM_WTBM_Gno.status ||
                error.PQR_BM_WTBM_Thk.status ||
                error.PQR_BM_WTBM_Dia.status)
                ? "text-start  border thead-color error-head"
                : "text-start border thead-color"
            }
          >
            <div>
              Welded To - Base Metal 2
              {error.PQR_BM_WTBM_ProductForm &&
                (error.PQR_BM_WTBM_ProductForm.status ||
                  error.PQR_BM_WTBM_Spec.status ||
                  error.PQR_BM_WTBM_Pno.status ||
                  error.PQR_BM_WTBM_Gno.status ||
                  error.PQR_BM_WTBM_Thk.status ||
                  error.PQR_BM_WTBM_Dia.status) && (
                  <Tooltip
                    content={
                      error.PQR_BM_WTBM_ProductForm.message ||
                      error.PQR_BM_WTBM_Spec.message ||
                      error.PQR_BM_WTBM_Pno.message ||
                      error.PQR_BM_WTBM_Gno.message ||
                      error.PQR_BM_WTBM_Thk.message ||
                      error.PQR_BM_WTBM_Dia.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_ProductForm &&
                error.PQR_BM_WTBM_ProductForm.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_ProductForm"}
              maxLength={15}
              value={headerObj?.PQR_BM_WTBM_ProductForm}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_ProductForm: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Spec && error.PQR_BM_WTBM_Spec.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Spec"}
              maxLength={25}
              value={headerObj?.PQR_BM_WTBM_Spec}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Spec: error,
                }));
              }}
            />
          </td>
          <td className="WPSSelect">
            <SearchSelect
              required
              data={PNoActive}
              stylegreen={error.PQR_BM_WTBM_Pno.status ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_code"}
              value={headerObj?.PQR_BM_WTBM_Pno}
              labelField={"wld_code"}
              placeholder=""
              className={
                error.PQR_BM_WTBM_Pno.status
                  ? "err-input WPSSelect"
                  : "WPSSelect"
              }
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  PQR_BM_WTBM_Pno: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Pno: {},
                }));
              }}
            ></SearchSelect>
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Gno && error.PQR_BM_WTBM_Gno.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Gno"}
              maxLength={5}
              value={headerObj?.PQR_BM_WTBM_Gno}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Gno: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Thk && error.PQR_BM_WTBM_Thk.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Thk"}
              maxLength={10}
              value={headerObj?.PQR_BM_WTBM_Thk}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Thk: error,
                }));
              }}
            />
          </td>
          <td className="WpsInput-md">
            <input
              style={{ height: "38px",width:"135px" }}
              type="text"
              className={
                error.PQR_BM_WTBM_Dia && error.PQR_BM_WTBM_Dia.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"PQR_BM_WTBM_Dia"}
              maxLength={6}
              value={headerObj?.PQR_BM_WTBM_Dia}
              onChange={handleChange}
              onInput={(e) => {
                let error = isAlphanumericAllSpecialCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  PQR_BM_WTBM_Dia: error,
                }));
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
