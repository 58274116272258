import { Button, Modal, Alert } from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState, useEffect } from "react";
import { getAllRoles } from "../../Services/role-service";

import GolbalSearch from "./GolbalSearch";
import CustomPopUpTable from "./../layout/CustomPopUpTable";
import { PropTypes } from "react";
import { getAllTraining } from "../../Services/training-service";
import { formatDateDD_MM_YYYY } from "../../functions/welding-functions";
import { getGridHeaderData } from "../../Services/wpq_groove-services";
import { getWiWeldingNumbersProjJointProcessWise } from "../../Services/WI-services";

const MultiValuePopUp = (props) => {
  let count = 1;
  console.log(props);
  let arr = props.masterName.split(":");
  let masterName = arr[0];
  const gridRef = useRef();
  const [dataMethod, setDataMethod] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [parameters, setParams] = useState(null);
  const [ready, setReady] = useState(false);
  const [loc_comp, setLoc_comp] = useState([]);
  const [filteredCol, setFilteredCol] = useState([]);
  const [globalText, setGlobalText] = useState("");
  const [filteredResult, setFilteredResult] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [gridApi, setGridApi] = useState(null);

  //const fetchData = async (param) => {
  // const result = dataMethod && (await dataMethod(param));
  //console.log(result);
  //  if( dataMethod?.name === "getAllTraining") {
  //       let now = Date.now();
  //   const futureDates = result.filter(date => {
  //     // Filter out dates in the past or falsey values
  //     return date && (new Date(date.wld_fromDate)).getTime() > now;
  //   });
  //   console.log(futureDates);
  //   setRowData(futureDates);
  //   setFilteredResult(futureDates);
  // }else{
  //    setRowData(result);
  //  setFilteredResult(result);
  // }
  const year = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(year)
  console.log("selected", new Date().getFullYear(), selectedYear)




  //};
  const fetchTraining = async () => {
    let result = []
    result = await getAllTraining("Upcoming");
    // let training = Promise.resolve(result)
    // console.log("trainingres",training,training.result,result)
    setRowData(result);
    setFilteredResult(result);
    return result
  }
  console.log("wiobjdata", props.wiobj)
  console.log("ediititit", props?.action)


  let joint = props?.action ? props?.wiobj?.WI_Joint_Type : props?.JointType

  let welderid = props?.action ? props?.wiobj?.WIWelderid : props?.WIWelderid

  const fetchwpq = async () => {
    console.log("wwwwwe", welderid)
    let result = []
    result = await getGridHeaderData(
      joint,
      sessionStorage.getItem("Band"),
      sessionStorage.getItem("User"),
      selectedYear
    );
    console.log("inSearchhhh", result)
    const approved = result.filter((item) => item.WPSH_Status === "Approved")
    console.log("aaaapp", approved)
    setRowData(approved);
    // setFilteredResult(result);
    setFilteredResult(
      approved
    )
  }

  const fetchWIgrid = async () => {
    console.log("wwwwwe", welderid)
    let result = []
    result = await getGridHeaderData(
      joint,
      sessionStorage.getItem("Band"),
      sessionStorage.getItem("User"),
      selectedYear
    );
    console.log("inSearchhhh", result)
    const approved = result.filter((item) => item.WPSH_Status === "Approved")
    console.log("aaaapp", approved)

    // Get arrays of welderIds and recordNos from your array
    const welderIds = welderid.map(item => item.WPQ_WelderId);
    const recordNos = welderid.map(item => item.WPQ_RecordNo);

    // Filter the approved array based on the welderId and recordNo
    const filteredByWelderIdAndRecordNo = approved.filter((item) =>
      welderIds.includes(item.WPQ_WelderId.toString()) && recordNos.includes(item.WPQ_RecordNo)
    );
    console.log("filteredByWelderIdAndRecordNo", filteredByWelderIdAndRecordNo)

    setRowData(filteredByWelderIdAndRecordNo);
    setFilteredResult(filteredByWelderIdAndRecordNo)
  }
  // const fetchWIProjectJointProcessWise = async () => {
    
  //   const result = await getWiWeldingNumbersProjJointProcessWise(props.paramvalue.projectNumber, props.paramvalue.jointNumber, props.paramvalue.weldingProcess);
  //   let temp = [];
  //   for (let i of result) {
  //     let chk = temp.filter(x => x.WI_Welder_Id === i.WI_Welder_Id && x.WI_Welder_Name === i.WI_Welder_Name);
  //     if (chk?.length === 0) {
  //       temp.push(i);
  //     }
  //   }
  //   setRowData(temp);
  //   setFilteredResult(temp);
  //   setRowData(result);
  //   setFilteredResult(result);
  //   return result
  // }

  const fetchWIProjectJointProcessWise = async () => {
    const result = await getWiWeldingNumbersProjJointProcessWise(props.paramvalue.projectNumber, props.paramvalue.jointNumber, props.paramvalue.weldingProcess);
    
    // Filter out duplicate entries based on WI_Welder_Id and WI_Welder_Name
    const uniqueData = [];
    for (let i of result) {
      let isDuplicate = uniqueData.some(x => x.WI_Project_No === i.WI_Project_No && x.WI_Joint_No === i.WI_Joint_No && x.WI_Process === i.WI_Process && x.WI_Welder_Id === i.WI_Welder_Id);
      if (!isDuplicate) {
        uniqueData.push(i);
      }
    }
    
    // Set the filtered data
    setRowData(uniqueData);
    setFilteredResult(uniqueData);
    
    return uniqueData;
  };

  useEffect(() => {
    let result = []
    switch (masterName) {

      case "Training Subject and Date":
        {
          result = fetchTraining()

          setColumnDefs([
            {
              width: 60,
              checkboxSelection: true,
              scrollX: true
            },
            // {
            //   field: "SK",
            //   headerName: "Training Subject - Fasulty",
            //   tooltipField: "SK",
            // },
            {
              field: "wld_trainingSub",
              headerName: "Training Subject",
              tooltipField: "wld_trainingSub",
            },
            // {
            //   field: "wld_trainingMonth",
            //   headerName: "Month of Training",
            //   tooltipField: "wld_trainingMonth",
            // },
            {
              field: "wld_fromDate",
              headerName: "From",
              tooltipField: "wld_fromDate",
              valueFormatter: (params) => { return (formatDateDD_MM_YYYY(params.data.wld_fromDate)) }
            },
            {
              field: "wld_toDate",
              headerName: "To",
              tooltipField: "wld_toDate",
              valueFormatter: (params) => { return (formatDateDD_MM_YYYY(params.data.wld_toDate)) }
            },
            {
              field: "wld_faculty",
              headerName: "Faculty",
              tooltipField: "wld_faculty",
            },

          ]);
        }
        break;

      case "WPQ":
        {
          result = fetchwpq()
          setColumnDefs([
            {
              width: 60,
              checkboxSelection: true,
              headerCheckboxSelection: true,

              scrollX: true,

            },
            {
              width: 150,
              field: "WPQ_WelderId",
              headerName: "Welder ID",
              tooltipField: "WPQ_WelderId",

            },
            {
              width: 150,
              field: "WPQ_WelderName",
              headerName: "Welder Name",
              tooltipField: "WPQ_WelderName",
            },
            {
              width: 150,
              field: "WPQ_RecordNo",
              headerName: "WPQ No",
              tooltipField: "WPQ_RecordNo",
            },
            {
              field: "wld_WPSType",
              headerName: "Joint Type",
              tooltipField: "wld_WPSType",
            },
            {
              width: 150,
              field: "WPQ_Inspection",
              headerName: "Inspection agency",
              tooltipField: "WPQ_Inspection",
            },
            {
              width: 150,
              field: "WPQ_Qualificationcode",
              headerName: "Qualification Code",
              tooltipField: "WPQ_Qualificationcode",
            },
            {
              width: 150,
              field: "WPQ_PlantLocation",
              headerName: " Plant Location",
              tooltipField: "WPQ_PlantLocation",
            },
            {
              width: 150,

              field: "WPQ_specialNote",
              headerName: " Special Notes",
              tooltipField: "WPQ_specialNote",
            },

          ]);
        }
        break;

      case "WPQLinkGrid":
        {
          result = fetchWIgrid()
          setColumnDefs([
            // {
            //   width: 40,
            //   checkboxSelection: true,
            //   headerCheckboxSelection: true,

            //   scrollX: true,

            // },
            {
              width: 150,
              field: "WPQ_WelderId",
              headerName: "Welder ID",
              tooltipField: "WPQ_WelderId",

            },
            {
              width: 150,
              field: "WPQ_WelderName",
              headerName: "Welder Name",
              tooltipField: "WPQ_WelderName",
            },
            {
              width: 150,
              field: "WPQ_RecordNo",
              headerName: "WPQ No",
              tooltipField: "WPQ_RecordNo",
            },
            {
              field: "wld_WPSType",
              headerName: "Joint Type",
              tooltipField: "wld_WPSType",
            },
            {
              width: 150,
              field: "WPQ_Inspection",
              headerName: "Inspection agency",
              tooltipField: "WPQ_Inspection",
            },
            {
              width: 150,
              field: "WPQ_Qualificationcode",
              headerName: "Qualification Code",
              tooltipField: "WPQ_Qualificationcode",
            },
            {
              width: 150,
              field: "WPQ_PlantLocation",
              headerName: " Plant Location",
              tooltipField: "WPQ_PlantLocation",
            },
            {
              width: 150,

              field: "WPQ_specialNote",
              headerName: " Special Notes",
              tooltipField: "WPQ_specialNote",
            },

          ]);
        }
        break;


      case "Welding Repair- WI Welding Numbers":
        {
          result = fetchWIProjectJointProcessWise()

          setColumnDefs([
            {
              width: 60,
              checkboxSelection: true,
              scrollX: true
            },
            {
              field: "WI_Project_No",
              headerName: "Project Number",
              tooltipField: "WI_Project_No"
            },
            {
              field: "WI_Joint_No",
              headerName: "Joint Number",
              tooltipField: "WI_Joint_No"
            },
            {
              field: "WI_Process",
              headerName: "Process Number",
              tooltipField: "WI_Process"
            },
            {
              field: "WI_Welder_Id",
              headerName: "Welder Id",
              tooltipField: "WI_Welder_Id"
            },
            {
              field: "WI_Welder_Name",
              headerName: "Welder Name",
              tooltipField: "WI_Welder_Name"
            }
          ]);
        }
        break;
    }
    //  console.log("Training",result )




  }, []);

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
  };



  const assignData = () => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    console.log("Selected Rows", selectedRows);
    let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    console.log("Selected Rows Count: ", selectedRowsCount);
    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows });
      props.onHide();
    } else
      setAlertMessage("Please select record before clicking on Assign button.");
  };

  const assignDataWPQ = () => {

    const selectedRows = gridRef.current.api.getSelectedNodes().map(node => node.data);
    console.log("Selected Rows", selectedRows);
    let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    console.log("Selected Rows Count: ", selectedRowsCount);

    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows });
      props.onHide();
    } else {
      setAlertMessage("Please select record before clicking on Assign button.");
    }
  };

  const assignDataCustom = () => {
    let selectedRows = selectedCard;
    let selectedRowsCount = selectedCard.length;
    if (selectedRowsCount > 0) {
      props.setSelected({ name: props.masterName, result: selectedRows });
      props.onHide();
    } else
      setAlertMessage("Please select record before clicking on Assign button.");
  };


  const onGridReady = (params) => {

    setReady(!ready);
    gridRef.current.api.sizeColumnsToFit();
    setGridApi(params.api)
  };

  console.log("apiiiii", gridApi)

  const applyDefaultSelection = () => {


    if (gridApi !== null && props?.wiobj?.WI_WPQ_Data) {
      const selectedDataArray = props.wiobj.WI_WPQ_Data;

      gridApi?.forEachNode(node => {
        const nodeWelderId = node.data.WPQ_WelderId.toString();
        const nodeRecordNo = node.data.WPQ_RecordNo.toString();

        if (selectedDataArray.some(selectedData => {
          return (
            selectedData.WPQ_WelderId === nodeWelderId &&
            selectedData.WPQ_RecordNo === nodeRecordNo
          );
        })) {
          node.setSelected(true);
        }
      });
    }
  };

  const onFirstDataRendered = () => {
    applyDefaultSelection()
  }


  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    setGlobalText(searchText);
    let arr = props.masterName.split(":");
    let masterName = arr[0];
    if (searchText) {
      let result = rowData?.filter((item) => {
        switch (masterName) {

          case "Training Subject and Date":
            return (
              item.wld_trainingSub.toString().indexOf(searchText) >= 0 ||
              item.wld_trainingMonth.toLowerCase().indexOf(searchText) >= 0 ||
              item.wld_fromDate.toString().indexOf(searchText) >= 0 ||
              item.wld_toDate.toLowerCase().indexOf(searchText) >= 0 ||
              item.wld_faculty.toLowerCase().indexOf(searchText) >= 0
            );

          case "WPQ":
            return (
              item.WPQ_WelderId.toString().indexOf(searchText) >= 0 ||
              item.WPQ_WelderName.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_RecordNo.toLowerCase().indexOf(searchText) >= 0 ||
              item.wld_WPSType.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_Inspection.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_Qualificationcode.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_PlantLocation.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_specialNote.toLowerCase().indexOf(searchText) >= 0

            );
          case "WPQLinkGrid":
            return (
              item.WPQ_WelderId.toString().indexOf(searchText) >= 0 ||
              item.WPQ_WelderName.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_RecordNo.toLowerCase().indexOf(searchText) >= 0 ||
              item.wld_WPSType.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_Inspection.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_Qualificationcode.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_PlantLocation.toLowerCase().indexOf(searchText) >= 0 ||
              item.WPQ_specialNote.toLowerCase().indexOf(searchText) >= 0

            );
          // case "Location":
          //   return (
          //     item.lm_loccode.toString().indexOf(searchText) >= 0 ||
          //     item.lm_loc.toLowerCase().indexOf(searchText) >= 0 ||
          //     item.lm_loctype.toLowerCase().indexOf(searchText) >= 0 ||
          //     item.lm_country.toLowerCase().indexOf(searchText) >= 0
          //   );

          case "Welding Repair- WI Welding Numbers":
            return (
              item.WI_Project_No.toLowerCase().indexOf(searchText) >= 0 ||
              item.WI_Process.toLowerCase().indexOf(searchText) >= 0 ||
              item.WI_Joint_No.toLowerCase().indexOf(searchText) >= 0 ||
              item.WI_Welder_Name.toLowerCase().indexOf(searchText) >= 0 ||
              item.WI_Welder_Id.toLowerCase().indexOf(searchText) >= 0
            );

        }
      });
      setFilteredResult(result);
    } else {
      setFilteredResult(rowData);
    }
  };

  const resetAllFilter = () => {
    gridRef.current.api.setFilterModel(null);
  };

  function resetFilter(item) {
    let item1 = item.substring(0, item.indexOf(":") - 1);

    let col = gridRef.current.api.getFilterModel();
    for (const key of Object.keys(col)) {
      if (key === feildName(item1)) {
        delete col[key];
      }
    }
    gridRef.current.api.setFilterModel(col);
  }

  const headerName = (field) => {
    var name;
    switch (field) {
      case "dm_dealercode":
        name = "Company Code";
        break;
      case "dm_dealercompanyname":
        name = "Company Name";
        break;
      case "dm_dealercategory":
        name = "Dealer Category";
        break;
      case "SK":
        name = "Role Code";
        break;
      case "rm_name":
        name = "Role Name";
        break;
      case "lm_loccode":
        name = "Location Code";
        break;
      case "lm_loc":
        name = "Location Name";
        break;
      case "lm_loctype":
        name = "Location Type";
        break;
      case "lm_country":
        name = "Country";
        break;
      case "WPQ_WelderId":
        name = "Welder Id";
        break;
      case "WPQ_WelderName":
        name = "Welder Name";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    var name;
    switch (header) {
      case "Company Code":
        name = "dm_dealercode";
        break;
      case "Company Name":
        name = "dm_dealercompanyname";
        break;
      case "Dealer Category":
        name = "dm_dealercategory";
        break;
      case "Role Code":
        name = "SK";
        break;
      case "Role Name":
        name = "rm_name";
        break;
      case "Location Code":
        name = "lm_loccode";
        break;
      case "Location Name":
        name = "lm_loc";
        break;
      case "Location Type":
        name = "lm_loctype";
        break;
      case "Country":
        name = "lm_country";
        break;
      case "Welder Id":
        name = "WPQ_WelderId";
        break;

      case "Welder Name":
        name = "WPQ_WelderName";
        break;

      default:
        name = "No match found";
        break;
    }
    return name;
  };
  const onFilterChanged = (param) => {
    let a = document.getElementById("filterDisplayPop");
    console.log("a", a);
    setFilteredCol([]);
    let col = param.api.getFilterModel();

    if (Object.keys(col).length === 0) {
      a.classList.add("d-none");
    } else {
      a.classList.remove("d-none");
      for (const key of Object.keys(col)) {
        let filter;
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
        setFilteredCol((prev) => {
          return [...prev, filter];
        });
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <Modal {...props} centered size="lg" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{props.masterName.split(":")[0]} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="global-search-container">
              <GolbalSearch filterFunc={handleGlobalSearch}
                searchplaceholdertext={"Search for Code, Name, ID, etc"}
              />
            </div>

            <div
              className="d-none mt-3 d-flex"
              id="filterDisplayPop"
              style={{ gap: "10px" }}
            >
              {filteredCol &&
                filteredCol.map((item) => {
                  return (
                    <div className="filter-col pr-0 me-2">
                      <div>
                        <span className="ps-2 btn-text">{item}</span>
                      </div>
                      <div onClick={() => resetFilter(item)}>
                        <BsX size={32} />
                      </div>
                    </div>
                  );
                })}

              <Button
                variant="outline-primary"
                className="ms-2 lh-1 clear-all-btn"
                onClick={resetAllFilter}
              >
                Clear All
              </Button>
            </div>
            <div
              className="ag-theme-material d-none d-md-block d-lg-block"
              style={{ height: 350, width: "100%" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={filteredResult}
                columnDefs={columnDefs}
                rowSelection={"multiple"}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                headerHeight={50}
                rowHeight={50}
                pagination={true}
                paginationPageSize={5}
                onFilterChanged={onFilterChanged}
                onFirstDataRendered={onFirstDataRendered}
                // enableBrowserTooltips={true}
                domLayout={"autoHeight"}
              ></AgGridReact>
            </div>
            {/* <div className="d-md-none d-lg-none table-container1">
              <CustomPopUpTable
                masterName={masterName}
                data={filteredResult}
                setSelectedCard={setSelectedCard}
                locationData={
                  props.locationData ? props.locationData.split(",") : []
                }
                type="multiple"
              />
            </div> */}
          </div>
        </Modal.Body>

        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {/* <Alert.Heading>Please check the input!</Alert.Heading> */}
            {alertMessage}
          </Alert>
        )}

        <Modal.Footer className="justify-content-center justify-content-sm-start">
          {welderid && welderid.length > 0 ?
            "" :
            <>
              <Button
                size="sm"
                style={{ position:"relative", left:"90%"}}

                className="float-start me-2 d-none d-md-block d-lg-block btn-primary"
                onClick={props?.wiobj ? assignDataWPQ : assignData}
              >
                Assign
              </Button>
              <Button
                size="sm"
                className="float-start me-2 d-md-none d-lg-none"
                onClick={
                  assignDataCustom
                }
              >
                Assign
              </Button>
              <Button
                 style={{position:"relative", right:"6%"}}
                size="sm"
                className="float-start me-2"
                variant="outline-primary"
                onClick={() => props.onHide()}
              >
                Cancel
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MultiValuePopUp;
