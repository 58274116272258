import axios from "axios";

let DEVIATION_URL;
let MASTER_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  DEVIATION_URL = process.env.REACT_APP_DEVIATION_API_URL;
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL
} else {
  DEVIATION_URL = process.env.REACT_APP_API_URL + "deviation/raiseDeviation";
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getAllDeviaitons() {
  const response = await axios
    .get(DEVIATION_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getDeviationCategories() {
  let url = `${DEVIATION_URL}/getDeviationCategories`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getCapaDataByDevNumber(DeviationNumber) {
  let url = `${DEVIATION_URL}/getCapaDataByDevNumber/${encodeURIComponent(DeviationNumber)}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const RaiseDeviation = async (deviation) => {
  
  let url = `${DEVIATION_URL}/addDeviation`;
  const response = await axios
    .post(url, deviation)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const addCapa = async (capa) => {
  let url = `${DEVIATION_URL}/addCapa`;
  const response = await axios
    .post(url, capa)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLDev = async (
  folderName,
  subfolder,
  wld_profile
) => {
  
  let url = `${MASTER_API_URL}/file/images/${folderName}/${subfolder}/${wld_profile}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

//download file from s3
export async function downloadAttachment(downloadObj) {  
  let url = `${MASTER_API_URL}/file/downloadFile/${downloadObj.foldername}/${downloadObj.subfoldername}/${downloadObj.filename}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to fetch File", status: false };
    });  
  let preSignedUrl = response.data;
  const link = document.createElement("a");
  link.setAttribute("href", preSignedUrl);
  link.setAttribute("download", downloadObj.filename);
  link.click();
  link.remove();
}

export const sendClosureEmail = async (dev, capa) => {
  let reqBody = { "dev": dev, "capa": capa }
  let url = `${DEVIATION_URL}/sendClosureEmail`;
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const GetDeviationReport = async (deviation) => {
  let url = `${DEVIATION_URL}/getDeviationReport`;
  const response = await axios
    .post(url, deviation)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getAllCapaByDev = async (deviation) => {
  let url = `${DEVIATION_URL}/getAllCapaByDev`;
  const response = await axios
    .post(url, deviation)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};


