import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "role",
  initialState: {
    menuName: "Role and Authorization",
  },
  reducers: {
    setMenu(state, action) {
      state.menuName = action.payload;
    },
  },
});

export const menuActions = menuSlice.actions;

export default menuSlice;
