import axios from "axios";
let PQR_API_URL;
let MASTER_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  PQR_API_URL = process.env.REACT_APP_PQR_API_URL;
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  PQR_API_URL = process.env.REACT_APP_API_URL + "pqr";
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getGridHeaderData(gridParameter) {
  console.log("getGridHeaderData_pqr", gridParameter);
  let url = `${PQR_API_URL}/PqrHeaderMast/gridPQR`;
  console.log("UrlProjectNo", url);
  console.log("gridPQR", gridParameter);
  const response = await axios
    .post(url, gridParameter)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getbyprojectno = async (reqParam) => {
  let url = `${PQR_API_URL}/PqrHeaderMast/getprojectnoPQR`;
  console.log("getbyprojectno_url", url);
  const response = await axios
    .post(url, reqParam)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getbyprojectno_url_groove", response.data);
  return response.data;
};

export const getPQRonEdit = async (reqData) => {
  let url = `${PQR_API_URL}/PqrHeaderMast/getPQRonEdit`;
  console.log("UrlProjectNo", url);
  console.log("getPQRonEdit_reqData", reqData);
  const response = await axios
    .post(url, reqData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLgrid = async (image) => {
  let url = `${MASTER_API_URL}/file/pqr_groove/${image}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLgriddelete = async (image) => {
  let url = `${MASTER_API_URL}/file/pqr_groove_delete/${image}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddNewPQR = async (WPQ_Header_Master) => {
  let url = `${PQR_API_URL}/PqrHeaderMast`;
  console.log("AddNewPQR_WPQ_Header_Master", WPQ_Header_Master);
  const response = await axios
    .post(url, WPQ_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

// export const UpdateRecord = async (WPQ_Header_Master) => {
//   let url = `${PQR_API_URL}/PqrHeaderMast/update/${WPQ_Header_Master.SK}`;
//   const response = await axios
//     .post(url, WPQ_Header_Master)
//     .then(function (resp) {
//       return resp;
//     })
//     .catch(function (error) {
//       return { message: "Unable to find request", status: false };
//     });
//   return response.data;
// };

export const UpdatePQRHeader = async (PQR_Header_MasterUpdate) => {
  let url = `${PQR_API_URL}/PqrHeaderMast/UpdatePQRHeader`;
  console.log("UpdatePQRHeader_url", url);
  console.log(
    "UpdatePQRHeader_PQR_Header_MasterUpdate",
    PQR_Header_MasterUpdate
  );
  const response = await axios
    .post(url, PQR_Header_MasterUpdate)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeletePQR(DeleteRow) {
  console.log("DeletePQR_DeleteRow", DeleteRow);
  let url = `${PQR_API_URL}/PqrHeaderMast/delete`;
  console.log("DeletePQR_url", url);
  const resp = await axios
    .post(url, DeleteRow)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("DeletePQR_resp", resp);
  return resp;
}
