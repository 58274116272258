import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import stop from "../../icons/CrossSymbol.svg";
import Error from "../../icons/ErrorIcon.svg";
import Tooltip from "./Tooltip";
import Delete from "../../icons/DeleteIcon.svg";
export default (props) => {
  console.log("props.gridPage", props.gridPage);
  let editingCells = false;
  return (
    <>
      {props.selectedData.data.ErrorMessage &&
        props.selectedData.data.ErrorMessage != "" && (
<>
          <button style={{background:"none", border:"none"}} type="button" data-bs-toggle="tooltip" data-bs-placement="top" title= {props.selectedData.data.ErrorMessage}>
       
            <img src={Error} alt="Error" className="ms-1" />
        
          </button>
         
          </>
        )}

      <div className="cell-action">
        {/* <img
          onMouseOver={(e) => (e.currentTarget.src = Edit)}
          onMouseOut={(e) => (e.currentTarget.src = Edit_gray)}
          src={Edit_gray}
          alt="edit"
          onClick={() => props.handleEditEvent(props.selectedData)}
        /> */}

        {props.gridPage ? (
          <img
            src={Delete_gray}
            onMouseOver={(e) => (e.currentTarget.src = Delete)}
            onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
            alt="delete"
            className="p-1"
            onClick={() => props.handleDeleteEvent(props.selectedData)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
