import React from "react";
import { Link } from "react-router-dom";

function DeviationNumberRenderer(props) {
  const devNum = props.data;
 
  return (    
        <Link onClick={props.handleDevClick} style={{paddingLeft:"0px"}} className="add-new-link">
            {parseFloat(devNum)}
        </Link>    
  )
}

export { DeviationNumberRenderer };