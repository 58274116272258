import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "grid",
  initialState: {
    searchQuery: "",
  },
  reducers: {
    setSearch(state, action) {
      state.searchQuery = action.payload;
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice;
