export const Groove_Pdf_Fields = [
{ColumnDesc:"Process-Type", FieldName:"WPSD_Process",Process:"WPSD_Type", Unit:"",Colums:""},
{ColumnDesc:"Minimum Preheat", FieldName:"WPSD_WP_MinimumPreheatTemp", Unit:"°C",Colums:""},
{ColumnDesc:"Maximum interpass Temp", FieldName:"WPSD_WP_MaximumInterpassTemp", Unit:"°C",Colums:""},
{ColumnDesc:"Filler Metal Product Form", FieldName:"WPSD_WP_FillerMetalProductForm", Unit:"",Colums:""},
{ColumnDesc:"Filler Metal Size Ø", FieldName:"", Unit:"mm",Colums:["WPSD_WP_FillerMetalSIze","WPSD_WP_FillerMetalSIze","WPSD_WP_FillerMetalSIze"]},
{ColumnDesc:"Layer Number", FieldName:"", Unit:"",Colums:["WPSD_WP_LayerNumber","WPSD_WP_LayerNumber","WPSD_WP_LayerNumber"]},
{ColumnDesc:"Position Of Groove", FieldName:"", Unit:"",Colums:["WPSD_WP_PositionOfGroove","WPSD_WP_PositionOfGroove","WPSD_WP_PositionOfGroove"]},
{ColumnDesc:"Position Of Fillet", FieldName:"", Unit:"",Colums:["WPSD_WP_PositionOfFillet","WPSD_WP_PositionOfFillet","WPSD_WP_PositionOfFillet"]},
{ColumnDesc:"Weld Progression", FieldName:"", Unit:"",Colums:["WPSD_WP_WeldProgression","WPSD_WP_WeldProgression","WPSD_WP_WeldProgression"]},
{ColumnDesc:"Current Polarity", FieldName:"", Unit:"",Colums:["WPSD_WP_CurrentPolarity","WPSD_WP_CurrentPolarity","WPSD_WP_CurrentPolarity"]},
{ColumnDesc:"Amperes", FieldName:"", Unit:"Amps",Colums:["WPSD_WP_Amperes","WPSD_WP_Amperes","WPSD_WP_Amperes"]},
{ColumnDesc:"Pulsing Current", FieldName:"WPSD_WP_Pulsing_current", Unit:"Amps",Colums:""},
{ColumnDesc:"Voltage", FieldName:"", Unit:"Volts",Colums:["WPSD_WP_Volts","WPSD_WP_Volts","WPSD_WP_Volts"]},
{ColumnDesc:" Travel Speed", FieldName:"", Unit:"mm/min",Colums:["WPSD_WP_TravelSpeed","WPSD_WP_TravelSpeed","WPSD_WP_TravelSpeed"]},
{ColumnDesc:"Minimum bead Length (SMAW)", FieldName:"", Unit:"mm",Colums:["WPSD_WP_MinimumBeadLength","WPSD_WP_MinimumBeadLength","WPSD_WP_MinimumBeadLength"]},
{ColumnDesc:"Maximun Heat Input", FieldName:"", Unit:"KJ/mm",Colums:["WPSD_WP_MaximumHeatInput","WPSD_WP_MaximumHeatInput","WPSD_WP_MaximumHeatInput"]},
{ColumnDesc:"Tungsten Size", FieldName:"WPSD_WP_TungstenSIze", Unit:"mm"},
{ColumnDesc:"Tungsten Type", FieldName:"WPSD_WP_TungstenType", Unit:""},
{ColumnDesc:"Shielding:",MiddleDesc:"Gas Type", FieldName:"WPSD_WP_ShieldingGasType", Unit:"%Comp"},
{ColumnDesc:"",MiddleDesc:"Flow Rate", FieldName:"WPSD_WP_ShieldingFlowRate", Unit:"%I/min"},
{ColumnDesc:"Trailing:", Suffix:"Trailing", MiddleDesc:"Gas Type", FieldName:"WPSD_WP_TrailingGasType", Unit:"%Comp"},
{ColumnDesc:"",MiddleDesc:"Flow Rate", FieldName:"WPSD_WP_TrailingFlowRate", Unit:"I/min"},
{ColumnDesc:"Backing:", Suffix:"Backing",MiddleDesc:"Gas Type", FieldName:"WPSD_WP_BackingGasType", Unit:"%Comp"},
{ColumnDesc:"",MiddleDesc:"Flow Rate", FieldName:"WPSD_WP_BackingFlowRate", Unit:"I/min"},
{ColumnDesc:"Arc Transfer Mode (FCAW/GMAW)", FieldName:"WPSD_WP_ArcTransferMode", Unit:""},
{ColumnDesc:"String Or Weave", FieldName:"WPSD_WP_StringOrWeave", Unit:""},
{ColumnDesc:"Orifice / Gas Cup Size", FieldName:"WPSD_WP_Orifice_GasCupSize", Unit:"mm"},
{ColumnDesc:"CTWD", FieldName:"WPSD_WP_CTWD", Unit:"mm"},
{ColumnDesc:"Maximum Pass Thickness", FieldName:"WPSD_WP_Max_Pass_Thickness", Unit:"mm"},

]
export default Groove_Pdf_Fields;