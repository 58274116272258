import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";

export default (props) => {
  return (
    <div className="cell-action">
      <img
        onMouseOver={(e) => (e.currentTarget.src = Edit)}
        onMouseOut={(e) => (e.currentTarget.src = Edit_gray)}
        src={Edit_gray}
        alt="edit"
        style={{position:"relative",bottom:"8px"}}

        onClick={() => props.handleEditEvent(props.selectedData)}
      />
      <img
        src={Delete_gray}
        onMouseOver={(e) => (e.currentTarget.src = Delete)}
        onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
        alt="delete"
        style={{position:"relative",bottom:"8px"}}
        className="p-1"
        onClick={() => props.handleDeleteEvent(props.selectedData)}
      />
    </div>
  );
};
