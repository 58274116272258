
//TABLE WITH 2 ROWS AND 2 COLUMNS (WPQ- VARIABLES AND WELDING PROCESS)

import { Table} from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {
  isAlphanumeric
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { SearchSelect } from "../search-select";

export  const  CommonTable2 = (({headerObj,error,onInput1,onInput2,onInput3,onInput4,isReadonly,handleChange,setError,rowhead1,rowhead2,status1,status2,status3,status4,message1,message2,message3,message4,colhead1,colhead2,name1,name2,name3,name4,value1,value2,value3,value4,maxLength,style1,style2}) => {

    return(
        <Table className="text-center">
          
<thead>
  
  <tr className="border thead-color ">
  {/* {rowhead.map((head) => (
            <th  className="thickness_title" key={head}>{head}</th>
          ))} */}
    <th className="thickness_title" style={{width:"21%"}}></th>
    <th className="thickness_title">{rowhead1}</th>
    <th className="thickness_title">{rowhead2}</th>
    <th className="thickness_title"></th>
    <th className="thickness_title"></th>
    <th className="thickness_title"></th>
    <th className="thickness_title"></th>

  </tr>
</thead>

<tbody>
  <tr
    className={(
      status1 || status2)
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={(
        status1 || status2 )
          ? "text-start border thead-color error-head"
          : "text-start border thead-color"
      }
    >
      <div>
        <label>
          {colhead1}
        </label>
        {(status1 || status2 ) && (
          <Tooltip
            content={message1 || message2}
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td className="Thickness_data">
      <input
        type="text"
        className={
            status1 &&(status1)
            ? "error-input"
            : ""
        }
        name={name1}
        maxLength={maxLength}
        value={value1}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        // onInput={(e) => {
        //   let error = isAlphanumeric(e.target.value);
        //   setError((prevState) => ({
        //     ...prevState,
        //     name1: error,
        //   }));
        // }}
        onInput={onInput1}
      />
    </td>
    <td>
      <input
        type="text"
        className={
          status2 &&(status2)
          ? "error-input"
            : ""
        }
        name={name2}
        maxLength={maxLength}
        value={value2}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        // onInput={(e) => {
        //   let error = isAlphanumeric(e.target.value);
        //   setError((prevState) => ({
        //     ...prevState,
        //     name2: error,
        //   }));
        // }}
        onInput={onInput2}
      />
    </td>
    <td style={style1}></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr
    className={(
      status3 || status4)
        ? "border WpsInput-md error"
        : "border WpsInput-md"
    }
  >
    <th
      className={(
        status3 || status4)
          ? "text-start border thead-color error-head"
          : "text-start border thead-color"
      }
    >
      <div>
        <label>
          {colhead2}
        </label>
        {(status3 || status4 ) && (
          <Tooltip
            content={message3 || message4}
          >
            <img src={Error} alt="Error" className="ms-1" />
          </Tooltip>
        )}
      </div>
    </th>
    <td className="Thickness_data">
      <input
        type="text"
        className={
          status3 && (status3)         
            ? "error-input"
            : ""
        }
        name={name3}
        maxLength={maxLength}
        value={value3}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        // onInput={(e) => {
        //   let error = isAlphanumeric(e.target.value);
        //   setError((prevState) => ({
        //     ...prevState,
        //     name3: error,
        //   }));
        // }}
        onInput={onInput3}

      />
    </td>
    <td>
      <input
        type="text"
        className={
          status4 && (status4)
          ? "error-input"
            : ""
        }
        name={name4}
        maxLength={maxLength}
        value={value4}
        readOnly={
          isReadonly
        }
        onChange={handleChange}
        // onInput={(e) => {
        //   let error = isAlphanumeric(e.target.value);
        //   setError((prevState) => ({
        //     ...prevState,
        //     name4: error,
        //   }));
        // }}
        onInput={onInput4}
      />
    </td>
    <td style={style2}></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>

  
</tbody>
</Table>)})

export  const  CommonWPQVariableTable = (({headerObj,error,onInput1,onInput2,onInput3,onInput4,isReadonly,handleChange,setError,rowhead1,rowhead2,status1,status2,status3,status4,status5,status6,message1,message2,message3,message4,message5,message6,colhead1,colhead2,colhead3,name1,name2,name3,name4,name5,name6,value1,value2,value3,value4,value5,value6,maxLength,style1,style2,isDisabled,PNoActive,setheaderObj,errorstyle,blyestyle}) => {

  return(
      <Table className="text-center">
        
<thead>

<tr className="border thead-color ">
{/* {rowhead.map((head) => (
          <th  className="thickness_title" key={head}>{head}</th>
        ))} */}
  <th className="thickness_title" style={{width:"21%"}}></th>
  <th className="thickness_title"style={{width:"41%"}}>{rowhead1}</th>
  <th className="thickness_title"style={{width:"41%"}}>{rowhead2}</th>
  <th className="thickness_title"></th>

  {/* <th className="thickness_title"></th>
  <th className="thickness_title"></th>
  <th className="thickness_title"></th>
  <th className="thickness_title"></th> */}

</tr>
</thead>

<tbody>
<tr 
  className={(
    status1 || status2)
      ? "border WpsVariableInput-md error"
      : "border WpsVariableInput-md"
  }
>
  <th style={{right:"18%"}}
    className={(
      status1 || status2 )
        ? "text-start border thead-color error-head"
        : "text-start border thead-color"
    }
  >
    <div>
      <label>
        {colhead1}
      </label>
      {(status1 || status2 ) && (
        <Tooltip
          content={message1 || message2}
        >
          <img src={Error} alt="Error" className="ms-1" />
        </Tooltip>
      )}
    </div>
  </th>
  <td className="Thickness_data">
    <input
    style={{width:"400px"}}
      type="text"
      className={
          status1 &&(status1)
          ? "error-input"
          : ""
      }
      name={name1}
      maxLength={maxLength}
      value={value1}
      readOnly={
        isReadonly
      }
      onChange={handleChange}
      // onInput={(e) => {
      //   let error = isAlphanumeric(e.target.value);
      //   setError((prevState) => ({
      //     ...prevState,
      //     name1: error,
      //   }));
      // }}
      onInput={onInput1}
    />
  </td>
  <td>
    <input
        style={{width:"400px"}}

      type="text"
      className={
        status2 &&(status2)
        ? "error-input"
          : ""
      }
      name={name2}
      maxLength={maxLength}
      value={value2}
      readOnly={
        isReadonly
      }
      onChange={handleChange}
      // onInput={(e) => {
      //   let error = isAlphanumeric(e.target.value);
      //   setError((prevState) => ({
      //     ...prevState,
      //     name2: error,
      //   }));
      // }}
      onInput={onInput2}
    />
  </td>
  <td style={style2}></td>

  {/* <td style={style1}></td>
  <td></td>
  <td></td>
  <td></td> */}
</tr>
<tr
  className={(
    status3 || status4)
      ? "border WpsVariableInput-md error"
      : "border WpsVariableInput-md"
  }
>
  <th
    className={(
      status3 || status4)
        ? "text-start border thead-color error-head"
        : "text-start border thead-color"
    }
  >
    <div>
      <label>
        {colhead2}
      </label>
      {(status3 || status4 ) && (
        <Tooltip
          content={message3 || message4}
        >
          <img src={Error} alt="Error" className="ms-1" />
        </Tooltip>
      )}
    </div>
  </th>
  <td className="Thickness_data">
    <input
      type="text"
      style={{width:"400px"}}
      className={
        status3 && (status3)         
          ? "error-input"
          : ""
      }
      name={name3}
      maxLength={maxLength}
      value={value3}
      readOnly={
        isReadonly
      }
      onChange={handleChange}
      // onInput={(e) => {
      //   let error = isAlphanumeric(e.target.value);
      //   setError((prevState) => ({
      //     ...prevState,
      //     name3: error,
      //   }));
      // }}
      onInput={onInput3}

    />
  </td>
  <td>
    <input
      type="text"
      style={{width:"400px"}}
      className={
        status4 && (status4)
        ? "error-input"
          : ""
      }
      name={name4}
      maxLength={maxLength}
      value={value4}
      readOnly={
        isReadonly
      }
      onChange={handleChange}
      // onInput={(e) => {
      //   let error = isAlphanumeric(e.target.value);
      //   setError((prevState) => ({
      //     ...prevState,
      //     name4: error,
      //   }));
      // }}
      onInput={onInput4}
    />
  </td>
  <td style={style2}></td>

  {/* <td style={style2}></td>
  <td></td>
  <td></td>
  <td></td> */}
</tr>
<tr
  className={(
    status6)
      ? "border WPSSelectVariable error"
      : "border WPSSelectVariable"
  }
>
  <th
    className={(
      status6)
        ? "text-start border thead-color error-head"
        : "text-start border thead-color"
    }
  >
    <div>
      <label>
        {colhead3}
      </label>
      {(status6 ) && (
        <Tooltip
          content={message6}
        >
          <img src={Error} alt="Error" className="ms-1" />
        </Tooltip>
      )}
    </div>
  </th>
  <td className="WPSSelectVariable">
  {/* <div
    style={{paddingLeft:"38px"}}
    >
  <SearchSelect
              required
              data={PNoActive}
              stylegreen={status5 ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_p_category"}
              value={value5}
              labelField={"wld_p_category"}
              placeholder=""
              className={status5 ? "err-input WPSSelectVariable"
                : "WPSSelectVariable"}
              onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  [name5]: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  WPQ_VAR_Pcategory_actualValue: {},
                }));
              }}
            ></SearchSelect>
            </div> */}
  </td>

  <td className="WPSSelectVariable">
    <div
    style={{paddingLeft:"0px",
  }}
    >
  <SearchSelect
              required
              data={PNoActive}
              stylegreen={status6 ? errorstyle : blyestyle}
              disabled={isDisabled}
              valueField={"wld_p_category"}
              value={value6}
              labelField={"wld_p_category"}
              placeholder=""
              className={status6 ? "err-input WPSSelectVariable"
                : "WPSSelectVariable"}  
               onChange={(pnoList) => {
                setheaderObj((prev) => ({
                  ...prev,
                  [name6]: pnoList.value,
                }));
                setError((prevState) => ({
                  ...prevState,
                  WPQ_VAR_Pcategory_RangeQualified: {},
                }));
              }}
            ></SearchSelect>
            </div>
            </td>
  <td style={style2}></td>

  {/* <td style={style2}></td>
  <td></td>
  <td></td>
  <td></td> */}
</tr>


</tbody>
</Table>)})