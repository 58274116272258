//TABLE WITH 2 ROWS AND 2 COLUMNS (WPQ- VARIABLES AND WELDING PROCESS)
// PQR_Page3_Table_ColumnN (common component for all tables)
// PQR_Page3_Table_ColumnNindex (used in Macro_examination and Minimum_leak_path)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import {
  isAlphanumericAllSpecialCharacters
} from "../../../functions/validations";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const WPQ_Page3_Table1 = ({
  array,
  name1,
  name2,
  name3,
  name4,
  title1,
  title2,
  title3,
  title4,
  status,
  status1,
  status2,
  status3,
  status4,
  readOnly,
  message,
  message1,
  message2,
  message3,
  message4,
  setError,
  handleChangetestarray,
  maxLengthname1,
  maxLengthname2,
  maxLengthname3,
  maxLengthname4
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror2 = status2 && (
    <Tooltip content={message2}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror3 = status3 && (
    <Tooltip content={message3}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror4 = status4 && (
    <Tooltip content={message4}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  ////console.log("arraya", array);

  return (
    <Table className="text-center tablewidth">
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
          <th  className="thickness_title" key={head}>{head}</th>
        ))} */}
          <th className="thickness_title border" style={{ width: "25%" }}>
            {imgerror} &nbsp; {title1}
          </th>
          <th className="thickness_title border" style={{ width: "18%" }}>
            {" "}
            {imgerror1} &nbsp; {title2}
          </th>
          <th className="thickness_title border" style={{ width: "15%" }}>
            {" "}
            {imgerror2} &nbsp; {title3}
          </th>
          <th className="thickness_title border" style={{ width: "25%" }}>
            {" "}
            {imgerror3} &nbsp; {title4}
          </th>
        </tr>
      </thead>

      <tbody>
        {array &&
          array.map((item, i) => {
            ////console.log("array", array);
            return (
              <tr className={array[i]?.error ? "border error" : "border "}>
                <td className="WpsInput-md  border" key={i}>
                  {array[i]?.error && (
                    <Tooltip content={array[i]?.errormessage}>
                      <img
                        src={Img}
                        alt="Error"
                        className="ms-1"
                        style={{ position: "relative", right: "10px" }}
                      />
                    </Tooltip>
                  )}

                  <input
                    style={{ width: "200px" }}
                    type="text"
                    id={name1}
                    name={name1}
                    placeholder=" "
                    maxLength={maxLengthname1 ? maxLengthname1 : 40}
                    readOnly={readOnly}
                    // className={status ? "error-input" : ""}
                    value={item[name1]}
                    onChange={(e) =>
                      handleChangetestarray(i, name1, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name1]: error,
                      }));
                    }}
                    // onInput={onInput}

                    // onChange={(e)=>{handleChangetestarray(e,i)}}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "120px" }}
                    type="text"
                    id={name2}
                    name={name2}
                    placeholder=" "
                    maxLength={maxLengthname2 ? maxLengthname2 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name2]}
                    onChange={(e) =>
                      handleChangetestarray(i, name2, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name2]: error,
                      }));
                    }}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "100px" }}
                    type="text"
                    id={name3}
                    name={name3}
                    placeholder=" "
                    maxLength={maxLengthname3 ? maxLengthname3 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name3]}
                    onChange={(e) =>
                      handleChangetestarray(i, name3, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name3]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "180px" }}
                    type="text"
                    id={name4}
                    name={name4}
                    placeholder=" "
                    maxLength={maxLengthname4 ? maxLengthname4 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name4]}
                    onChange={(e) =>
                      handleChangetestarray(i, name4, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name4]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
export const WPQ_2Rows_Table = ({
  array,
  name1,
  name2,
  name3,
  name4,
  title1,
  title2,
  title3,
  title4,
  status,
  status1,
  status2,
  status3,
  status4,
  readOnly,
  message,
  message1,
  message2,
  message3,
  message4,
  setError,
  handleChangetestarray,
  maxLengthname1,
  maxLengthname2,
  maxLengthname3,
  maxLengthname4
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror2 = status2 && (
    <Tooltip content={message2}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror3 = status3 && (
    <Tooltip content={message3}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror4 = status4 && (
    <Tooltip content={message4}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  ////console.log("arraya", array);

  return (
    <Table className="text-center tablewidth">
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
          <th  className="thickness_title" key={head}>{head}</th>
        ))} */}
          <th className="thickness_title border" style={{ width: "25%" }}>
            {imgerror} &nbsp; {title1}
          </th>
          <th className="thickness_title border" style={{ width: "18%" }}>
            {" "}
            {imgerror1} &nbsp; {title2}
          </th>
          <th className="thickness_title border" style={{ width: "15%" }}>
            {" "}
            {imgerror2} &nbsp; {title3}
          </th>
          <th className="thickness_title border" style={{ width: "25%" }}>
            {" "}
            {imgerror3} &nbsp; {title4}
          </th>
        </tr>
      </thead>

      <tbody>
        {array &&
          array.map((item, i) => {
            ////console.log("array", array);
            return (
              <tr className={array[i]?.error ? "border error" : "border "}>
                <td className="WpsInput-md  border" key={i}>
                  {array[i]?.error && (
                    <Tooltip content={array[i]?.errormessage}>
                      <img
                        src={Img}
                        alt="Error"
                        className="ms-1"
                        style={{ position: "relative", right: "10px" }}
                      />
                    </Tooltip>
                  )}

                  <input
                    style={{ width: "200px" }}
                    type="text"
                    id={name1}
                    name={name1}
                    placeholder=" "
                    maxLength={maxLengthname1 ? maxLengthname1 : 40}
                    readOnly={readOnly}
                    // className={status ? "error-input" : ""}
                    value={item[name1]}
                    onChange={(e) =>
                      handleChangetestarray(i, name1, e.target.value)
                    }
                
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name1]: error,
                      }));
                    }}
                    // onInput={onInput}

                    // onChange={(e)=>{handleChangetestarray(e,i)}}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "120px" }}
                    type="text"
                    id={name2}
                    name={name2}
                    placeholder=" "
                    maxLength={maxLengthname2 ? maxLengthname2 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name2]}
                    onChange={(e) =>
                      handleChangetestarray(i, name2, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name2]: error,
                      }));
                    }}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "100px" }}
                    type="text"
                    id={name3}
                    name={name3}
                    placeholder=" "
                    maxLength={maxLengthname3 ? maxLengthname3 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name3]}
                    onChange={(e) =>
                      handleChangetestarray(i, name3, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name3]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "180px" }}
                    type="text"
                    id={name4}
                    name={name4}
                    placeholder=" "
                    maxLength={maxLengthname4 ? maxLengthname4 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name4]}
                    onChange={(e) =>
                      handleChangetestarray(i, name4, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters (
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name4]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export const WPQ_Page3_Table2 = ({
  error1,
  error2,
  error3,
  error4,
  error5,
  array,
  name1,
  name2,
  name3,
  name4,
  name5,
  table,
  status,
  status1,
  status2,
  status3,
  status4,
  status5,
  readOnly,
  onInput,
  WPQ_VEX_WeldPass,
  error,
  message,
  message1,
  message2,
  message3,
  message4,
  message5,
  setError,
  headerObj,
  setheaderObj,
  handleChangetestarray,
  setTestObj,
  ProcessActive,
  isReadonly,
  handleChangeLiquid_Penetrant_Examinationarray,
  sessionName,
  maxLengthname1,
  maxLengthother,
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );
  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror2 = status2 && (
    <Tooltip content={message2}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror3 = status3 && (
    <Tooltip content={message3}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror4 = status4 && (
    <Tooltip content={message4}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  ////console.log("arraya", array);

  return (
    <Table className="text-center tablewidth">
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
        <th  className="thickness_title" key={head}>{head}</th>
      ))} */}
          <th className="thickness_title border" style={{ width: "25%" }}>
            {" "}
            {imgerror} &nbsp; Tube No.
          </th>
          <th className="thickness_title "> {imgerror1} &nbsp; Loc A</th>
          <th className="thickness_title "> {imgerror2} &nbsp; Loc B</th>
          <th className="thickness_title "> {imgerror3} &nbsp; Loc C</th>
          <th className="thickness_title " style={{ width: "20%" }}>
            {" "}
            {imgerror4} &nbsp; Loc D
          </th>
        </tr>
      </thead>

      <tbody>
        {array &&
          array.map((item, i) => {
            ////console.log("array", array);
            return (
              <tr className={array[i]?.error ? "border error" : " border"}>
                <td className="WpsInput-md  border" key={i}>
                  {array[i]?.error && (
                    <Tooltip content={array[i]?.errormessage}>
                      <img
                        src={Img}
                        alt="Error"
                        className="ms-1"
                        style={{ position: "relative", right: "10px" }}
                      />
                    </Tooltip>
                  )}

                  <input
                    // style={{width:"200px"}}
                    type="text"
                    id={name1}
                    name={name1}
                    placeholder=" "
                    maxLength={maxLengthname1 ? maxLengthname1 : 3}
                    readOnly={readOnly}
                    // className={status ? "error-input" : ""}
                    value={item[name1]}
                    onChange={(e) =>
                      handleChangetestarray(i, name1, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error =
                      sessionName === "PQRTubesheet"
                        ? isAlphanumericAllSpecialCharacters(e.target.value)
                        : isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        [error1]: error,
                      }));
                    }}

                    // onChange={(e)=>{handleChangetestarray(e,i)}}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td className="WpsInput-md " style={{ paddingLeft: "2%" }}>
                  <input
                    type="text"
                    id={name2}
                    name={name2}
                    placeholder=" "
                    maxLength={maxLengthother ? maxLengthother : 4}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name2]}
                    onChange={(e) =>
                      handleChangetestarray(i, name2, table, e.target.value)
                    }
                    onInput={(e) => {
                      // let error = isAlphanumericAllSpecialCharacters(e.target.value);
                      let error =
                        sessionName === "PQRTubesheet"
                          ? isAlphanumericAllSpecialCharacters(e.target.value)
                          : isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        [error2]: error,
                      }));
                    }}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td className="WpsInput-md " style={{ paddingLeft: "2%" }}>
                  <input
                    type="text"
                    id={name3}
                    name={name3}
                    placeholder=" "
                    maxLength={maxLengthother ? maxLengthother : 4}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name3]}
                    onChange={(e) =>
                      handleChangetestarray(i, name3, table, e.target.value)
                    }
                    onInput={(e) => {
                      // let error = isNumber(e.target.value);
                      let error =
                        sessionName === "PQRTubesheet"
                          ? isAlphanumericAllSpecialCharacters(e.target.value)
                          : isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        [error3]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td className="WpsInput-md  " style={{ paddingLeft: "2%" }}>
                  <input
                    type="text"
                    id={name4}
                    name={name4}
                    placeholder=" "
                    maxLength={maxLengthother ? maxLengthother : 4}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name4]}
                    onChange={(e) =>
                      handleChangetestarray(i, name4, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error =
                        sessionName === "PQRTubesheet"
                          ? isAlphanumericAllSpecialCharacters(e.target.value)
                          : isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        [error4]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td className="WpsInput-md  " style={{ paddingLeft: "2%" }}>
                  <input
                    type="text"
                    id={name5}
                    name={name5}
                    placeholder=" "
                    maxLength={maxLengthother ? maxLengthother : 4}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name5]}
                    onChange={(e) =>
                      handleChangetestarray(i, name5, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error =
                        sessionName === "PQRTubesheet"
                          ? isAlphanumericAllSpecialCharacters(e.target.value)
                          : isAlphanumericAllSpecialCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        [error5]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export const WPQ_Page3_Table3 = ({
  array,
  name1,
  name2,
  name3,
  name4,
  table,
  status,
  status1,
  status2,
  // status3,
  // status4,
  readOnly,
  onInput,
  WPQ_VEX_WeldPass,
  error,
  message,
  message1,
  message2,
  // message3,
  // message4,
  setError,
  headerObj,
  setheaderObj,
  handleChangetestarray,
  setTestObj,
  ProcessActive,
  isReadonly,
  handleChangeLiquid_Penetrant_Examinationarray,
  maxLength1,
  maxLength2,
  maxLength3,
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );
  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  // let imgerror2 = status2 && (
  //   <Tooltip content={message2}>
  //     <img src={Img} alt="Error" className="ms-1" />
  //   </Tooltip>
  // ); 

  // let imgerror3 = status3 && (
  //   <Tooltip content={message3}>
  //     <img src={Img} alt="Error" className="ms-1" />
  //   </Tooltip>
  // );

  // let imgerror4 = status4 && (
  //   <Tooltip content={message4}>
  //     <img src={Img} alt="Error" className="ms-1" />
  //   </Tooltip>
  // );

  ////console.log("arraya", array);

  return (
    <Table className="text-left tablewidth">
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
        <th  className="thickness_title" key={head}>{head}</th>
      ))} */}
          <th className="thickness_title border">{imgerror} &nbsp; Magnification</th>
          <th className="thickness_title border">
            {" "}
            {imgerror1} &nbsp; Acceptance Criteria
          </th>
          {/* <th className="thickness_title "> {imgerror2} &nbsp; Observation</th> */}
          <th className="thickness_title "></th>
        </tr>
      </thead>

      <tbody>
        {array &&
          array.map((item, i) => {
            console.log("arrayttt", array);
            return (
              <tr className={array[i]?.error ? "border error" : "border "}>
                <td className="WpsInput-md border" key={i}>
                  {array[i]?.error && (
                    <Tooltip content={array[i]?.errormessage}>
                      <img
                        src={Img}
                        alt="Error"
                        className="ms-1"
                        style={{ position: "relative", right: "10px" }}
                      />
                    </Tooltip>
                  )}

                  <input
                    style={{ width: "150px" }}
                    type="text"
                    id={name1}
                    name={name1}
                    placeholder=" "
                    maxLength={maxLength1 ? maxLength1 : 40}
                    readOnly={readOnly}
                    // className={status ? "error-input" : ""}
                    value={item[name1]}
                    onChange={(e) =>
                      handleChangetestarray(i, name1, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name1]: error,
                      }));
                    }}

                    // onChange={(e)=>{handleChangetestarray(e,i)}}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td className="WpsInput-md border" style={{ paddingLeft: "2%" }}>
                  <input
                    style={{ width: "150px" }}
                    type="text"
                    id={name2}
                    name={name2}
                    placeholder=" "
                    maxLength={maxLength2 ? maxLength2 : 40}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name2]}
                    onChange={(e) =>
                      handleChangetestarray(i, name2, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name2]: error,
                      }));
                    }}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                {/* <td className="WpsInput-md  " style={{ paddingLeft: "2%" }}>
                  <textarea
                    style={{
                      width: "450px",
                      outlineColor: "black",
                      borderColor: "#3339CD",
                    }}
                    type="text"
                    id={name3}
                    name={name3}
                    placeholder=" "
                    maxLength={maxLength3 ? maxLength3 : 500}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item[name3]}
                    onChange={(e) =>
                      handleChangetestarray(i, name3, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericWithSpaceDotDash(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name3]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  "
                  style={{ paddingLeft: "2%" }}
                ></td> */}
              </tr>
            );
          })}
      </tbody>


    </Table>
    
  );
};

export const WPQ_Page3_Table3observation = ({
  array,
  name1,
  name2,
  name3,
  name4,
  table,
  value3,
  status,
  status1,
  status2,
  status3,
  status4,
  readOnly,
  onInput,
  WPQ_VEX_WeldPass,
  error,
  message,
  message1,
  message2,
  message3,
  message4,
  setError,
  headerObj,
  setheaderObj,
  handleChangetestarray,
  setTestObj,
  ProcessActive,
  isReadonly,
  handleChangeLiquid_Penetrant_Examinationarray,
  maxLength1,
  maxLength2,
  maxLength3,
  valueNotes,
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );
  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror2 = status2 && (
    <Tooltip content={message2}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror3 = status3 && (
    <Tooltip content={message3}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror4 = status4 && (
    <Tooltip content={message4}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  ////console.log("arraya", array);

  return (
    <Table className="text-left " style={{    width: "48.5%",
      position: "relative",
      left: "51.5%",
      bottom: "95%",
      height: "88%"}}>
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
        <th  className="thickness_title" key={head}>{head}</th>
      ))} */}
       
          <th className="thickness_title "> {imgerror2} &nbsp; Observation</th>
          <th className="thickness_title "></th>
        </tr>
      </thead>

      <tbody>
              <tr className={"border "}>
              
                <td className="WpsInput-md  " style={{ paddingLeft: "2%" }}>
                  <textarea
                    style={{
                      width: "505px",
                      height: "139px",
                      outlineColor: "black",
                      resize:"none"
                    }}
                    type="text"
                    id={name3}
                    name={name3}
                    placeholder=" "
                    maxLength={maxLength3 ? maxLength3 : 500}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={value3 ? value3 : valueNotes}
                    onChange={handleChangetestarray}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name3]: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  "
                  style={{ paddingLeft: "2%" }}
                ></td>
              </tr>
            
          
      </tbody>
    </Table>
    
  );
};

export const PQR_Page3_Table3 = ({
  array,
  name1,
  name2,
  name3,
  table,
  status,
  status1,
  status2,
  readOnly,
  message,
  message1,
  message2,
  setError,
  handleChangetestarray,
  maxLength1,
  maxLength2,
  maxLength3,
  title1,
  title2,
  title3,
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  console.log("statatatat",status,status1)

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );
  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror2 = status2 && (
    <Tooltip content={message2}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  return (
    <Table className="text-left tablewidth">
      <thead>
        <tr className="border thead-color ">
          <th className="thickness_title " style={{ width: "30%" }}>
            {imgerror} &nbsp; {title1}
          </th>
          <th className="thickness_title " style={{ width: "30%" }}>
            {" "}
            {imgerror1} &nbsp; {title2}
          </th>
          <th className="thickness_title " style={{ width: "30%" }}>
            {" "}
            {imgerror2} &nbsp; {title3}
          </th>
        </tr>
      </thead>

      <tbody>
        {array &&
          array.map((item, i) => {
            ////console.log("array", array);
            return (
              <tr className={array[i]?.error ? "border error" : "border "}>
                <td className="WpsInput-md " key={i}>
                  {array[i]?.error && (
                    <Tooltip content={array[i]?.errormessage}>
                      <img
                        src={Img}
                        alt="Error"
                        className="ms-1"
                        style={{ position: "relative", right: "10px" }}
                      />
                    </Tooltip>
                  )}

                  <input
                    style={{ width: "245px" }}
                    type="text"
                    id={name1}
                    name={name1}
                    placeholder=" "
                    maxLength={maxLength1 ? maxLength1 : 40}
                    readOnly={readOnly}
                    value={item[name1]}
                    onChange={(e) =>
                      handleChangetestarray(i, name1, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name1]: error,
                      }));
                    }}
                  />
                </td>
                <td className="WpsInput-md " style={{ paddingLeft: "2%" }}>
                  <input
                    style={{ width: "245px" }}
                    type="text"
                    id={name2}
                    name={name2}
                    placeholder=" "
                    maxLength={maxLength2 ? maxLength2 : 40}
                    readOnly={readOnly}
                    value={item[name2]}
                    onChange={(e) =>
                      handleChangetestarray(i, name2, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name2]: error,
                      }));
                    }}
                  />
                </td>
                <td className="WpsInput-md " style={{ paddingLeft: "2%" }}>
                  <input
                    style={{ width: "245px" }}
                    type="text"
                    id={name3}
                    name={name3}
                    placeholder=" "
                    maxLength={maxLength3 ? maxLength3 : 40}
                    readOnly={readOnly}
                    value={item[name3]}
                    onChange={(e) =>
                      handleChangetestarray(i, name3, table, e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name3]: error,
                      }));
                    }}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export const PQR_Page3_Table_ColumnN = ({
  columns,
  cols,
  col,
  array,
  name,
  status,
  readOnly,
  message,
  setError,
  handleChangetestarray,
  maxLength,
  title,
  disabled,
  width,
  width1,
  width2,
  width3,
  numberofrowsdisabled
}) => {
  let i=0;
  let imgerr = []

let names = name
let headerRow = []
let textwidth1 = width1 != undefined ? width1 : ""
let textwidth2=width2 != undefined ? width2 : ""
let textwidth3=width3 != undefined ? width3 : ""
  for(i=0 ; i<= columns;i++){
    console.log("Columns",columns)
    let imgerror = status[i] && (
      <Tooltip content={message[i]}>
        <img src={Img} alt="Error" className="ms-1" />
      </Tooltip>
    );
    imgerr.push(imgerror)
  
  }

   title.map((item,i) => {
   // console.log("Title",title[i])
    headerRow.push(<th className="thickness_title " style={{ width: "25%" }}>
    {imgerr[i]} {title[i]}
  </th>)
  })
  

     
let detailRow = []
  
function createRow(isdisabled,item,errormsg,row){
  let ObjCols = []
let position = 1
for(cols=0;cols<columns;cols++)
{
  // debugger
  //console.log("column name",names)
  let key = names != undefined ? names[cols] : ""

 ObjCols.push(<td className="WpsInput-md " key={i}>
             
                 {errormsg && cols==0 && (
                  <Tooltip content={errormsg}>
                    <img
                      src={Img}
                      alt="Error"
                      className="ms-1"
                      style={{ position: "relative", right: "10px" }}
                    />
                  </Tooltip>
                  )}
                 
                  <input
                    style={{width:position == 1 ? textwidth1:position == 2 ? textwidth2:position == 3 ? textwidth3 : width}}
                    id={key}
                    name={key}
                    placeholder=" "
                    maxLength={maxLength[cols] ? maxLength[cols] : 40}
                    readOnly={readOnly}
                    value={item[key]}
                    disabled={isdisabled}
                    onChange={(e) =>{
                      // alert("column")
                      
                      // alert(key)
                
                      handleChangetestarray(row, key, e.target.value)
                    }
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [key]: error,
                      }));
                    }}
                  />
          
           </td> )
position=position+1
}

return (<>{ObjCols}</>)
}

  



   return (
    <Table className="text-left tablewidth">
      <thead>
        <tr className="border thead-color ">
          {headerRow && headerRow.map((head) =>{
            return(head)
          })}
        </tr>
      </thead>

     <tbody>
          {  array &&
            array.map((item, i) => {
              let isdisabled = false
              if (i >= numberofrowsdisabled) {
                isdisabled = disabled
               }
               return(<tr className={array[i]?.error ? "border error" : "border "}>
                {createRow(isdisabled,item,array[i]?.errormessage,i)}
         
  
          </tr>)
        })}
      </tbody>
    </Table>
   )
}
export const PQR_Page3_Table_ColumnNindex = ({
  columns,
  cols,
  col,
  array,
  name,
  status,
  status1,
  readOnly,
  table,
  index,
  message,
  message1,
  setError,
  handleChangetestarray,
  maxLength,
  title,
  disabled,
  numberofrowsdisabled
}) => {
  let i=0;
  let imgerr = []

let names = name
let headerRow = []

console.log("Indexxxxx",index)


  for(i=0 ; i<= columns;i++){
    console.log("Columns",columns)

        
    let imgerror = status[i] && (
      <Tooltip content={message[i]}>
        <img src={Img} alt="Error" className="ms-1" />
      </Tooltip>
    );

    imgerr.push(imgerror)
  }


   title.map((item,i) => {
 //   console.log("Title",title[i])
    headerRow.push(<th className="thickness_title " style={{ width: "25%" }}>
    {imgerr[i]} &nbsp; {title[i]}
  </th>)
  })
  

     
let detailRow = []
  
function createRow(isdisabled,item,errormsg,row){
  let ObjCols = []
for(cols=0;cols<columns;cols++)
{
  // debugger
 // console.log("column name",names)
  let key = names != undefined ? names[cols] : ""

 ObjCols.push(<td className="WpsInput-md " key={i}>
             
                 {errormsg && cols==0 && (
                  <Tooltip content={errormsg}>
                    <img
                      src={Img}
                      alt="Error"
                      className="ms-1"
                      style={{ position: "relative", right: "10px" }}
                    />
                  </Tooltip>
                  )}
                 
                  <input
                    style={{ width: "65px"}}
                    type="text"
                    id={key}
                    name={key}
                    placeholder=" "
                    maxLength={maxLength[cols] ? maxLength[cols] : 40}
                    readOnly={readOnly}
                    index={index}
                    value={item[key]}
                    disabled={isdisabled}
                    onChange={(e) =>{
                
                      handleChangetestarray(row, key,table, e.target.value)
                      
                    }
                    }
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                     var key1 = key+index
                      setError((prevState) => ({
                        ...prevState,
                        [key1]: error,
                      }));
                      console.log("keyyyyyyy",key1);
                    }}
                  />
          
           </td> )
}

return (<>{ObjCols}</>)
}

  



   return (
    <Table className="text-center tablewidth">
      <thead>
        <tr className="border thead-color ">
          {headerRow && headerRow.map((head,id) =>{
            console.log("hhheadddd",id)
            return(head)
          })}
        </tr>
      </thead>

     <tbody>
          {  array &&
            array.map((item, i) => {

              let isdisabled = false
              if (i >= numberofrowsdisabled) {
                isdisabled = disabled
               }
               return(<tr className={array[i]?.error ? "border error" : "border "}>
                {createRow(isdisabled,item,array[i]?.errormessage,i)}
         
  
          </tr>)
        })}
      </tbody>
    </Table>
   )
}
