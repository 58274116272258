import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";
import { NavLink } from "react-bootstrap";

export default (props) => {
  console.log("kjhgf", props.selectedData.data)
  return (
    <>
    <div className="d-flex align-items-center">
    <span className="action-status-color"  onClick={() => props.handleEditEvent(props.selectedData)}
    >
     
    {/* {props.selectedData.data.wld_TrainingReqDate} */}

    {props.selectedData.data.wld_TrainingReqNumber}

  </span>
  </div>
    {/* <div className="cell-action">
      <img
        onMouseOver={e => (e.currentTarget.src = Edit)}
        onMouseOut={e => (e.currentTarget.src = Edit_gray)}
        src={Edit_gray}
        alt="edit"
        onClick={() => props.handleEditEvent(props.selectedData)}
      />
      <img
        src={Delete_gray}
        onMouseOver={e => (e.currentTarget.src = Delete)}
        onMouseOut={e => (e.currentTarget.src = Delete_gray)}
        alt="delete"
        className="p-1"
        onClick={() => props.handleDeleteEvent(props.selectedData)}
      />

    </div> */}
    </>
  )

};
