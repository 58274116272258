import { Row, Col, Container, Button, Form, Table } from "react-bootstrap";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import {
  AddNewCountWPQ,
  AddNewGrooveWPQ,
} from "../../Services/wpq_groove-services";
import { useLocation } from "react-router-dom";
import {
  isAlphanumericWithDash,
  isAlphanumericAllSpecialCharacters
  
} from "../../functions/validations";
import { getPresignedURL } from "../../Services/Welder-services";

import defaultimg from "../../icons/noimage.png";
import { AddLogs } from "../../Services/wps-logs-services";
import { Single_Input } from "./WPSObjects/WPS_Process_Grid";
import { Single_Input_Superviser_remark } from "./WPSObjects/WPS_Process_Grid";
import { IsUserMasterBand, getAllUserMaster } from "../../Services/user-service";
import {
  MessageTypes,
  WPQPage3,
  WPQ_Test,
  WPQPage1,
  WPSPage1_Overlay,
  WPSPage3_Overlay,
  WPS_Groove_Detail,
  WPS_Overlay_Detail,
  yesno,
} from "./WPSObjects/WPQ_entity";
import { WPQHeader } from "./WPSObjects/WPQHeader";
import { Action, Status } from "./WPSObjects/WPQ_entity";
import { CommonTable5 } from "../utility/CommonComponents/Table5";
import { checkTestAdded } from "./WPSObjects/WPS_Validations";

import { SearchSelectprename } from "../utility/search-select";
import { Date_InputUser } from "../utility/CommonComponents/Date_Component";
import { SearchSelectProject } from "../utility/search-select";
import { emptyError } from "./WPSObjects/WPS_common";




const Test_and_Certificate = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      testObj,
      detailObj,
      setdetailObj,
      setTestObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      isReadonly,
      isDisabled,
      setIsReadonly,
    },
    ref
  ) => {
    const location = useLocation();

    let userprofile = sessionStorage.getItem("UserProfile");
    let Band = sessionStorage.getItem("Band");
    let username = sessionStorage.getItem("UserName");

    let folderwld = "wld_signatures";
    let folderwpq = "WPQ_Groove";

    let folderName2 = "wps_groove";
    let folderName3 = "wpq_groove";

    let formattedDate
    let error_message

    const avatar = { defaultimg };
    const [action, setaction] = useState(location.state?.action);

   

    const [WPStype, setWPStype] = useState(location.state?.WPStype);

    const [users, setUsers] = useState(null);
    const [IsEdited, setIsEdited] = useState(false);

    const [userApproveSignature, setuserApproveSignature] = useState(null);

    const [userDetails, setUserDetails] = useState([]);

    //common
    const [normalstyle, setNormal] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "grey",
      }),
    });
    const [errorstyle, seterrorstyle] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    });

    console.log("ty", location.state?.WPStype, WPStype);

    const navigate = useLocation();

    //common
    const todaynew = new Date();
    const yyyy = todaynew.getFullYear();
    let mm = todaynew.getMonth() + 1;
    let dd = todaynew.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const todayDate = yyyy + "-" + mm + "-" + dd;
    const maxDate = todaynew;
    const minDate = yyyy + "-" + mm + "-" + dd;

    const [checkwpsdata, setcheckwpsdata] = useState([]);

    const [previewUrl, setPreviewUrl] = useState([]);
    const [previewuser, setPreviewuser] = useState( headerObj?.wpq_signaturePrepared
      ? headerObj?.wpq_signaturePrepared
      : avatar.defaultimg);
    const [previewapproved, setPreviewapproved] = useState(headerObj?.wpq_signatureApproved
      ? headerObj?.wpq_signatureApproved
      : avatar.defaultimg);

    const [today, setToday] = useState("");

    let array = headerObj?.testarray;

    console.log("headerr", headerObj);

    console.log("headerrr", array);

    console.log("detaillllll", testObj);

    const handleChange = async (e) => {

      if (action === Action.ActionEdit) setIsEdited(true);

      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
   

    const handleChangeRadio = async (e) => {

      if (action === Action.ActionEdit) setIsEdited(true);
      if (!(await validate(detaildata))) {

      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    else{
      alert("Cannot Approve or Reject the Record, Please Fill Required Fields of all 3 Pages.")
    }
    };


    const fetchUserMaster = async () => {
      
      //change
      const result = await getAllUserMaster();
      console.log("allllalla",result)
      const active = result.filter((item) => item.um_status === "Active")
      setUsers(active);

    };



    const fetchUserId = async () => {
      
      try {
          // Ensure users and headerObj are defined and have the necessary properties
          if (!headerObj || !headerObj.WPSH_ApprovedBy_Name) {
              console.error("users or headerObj.WPSH_ApprovedBy_Name is not defined");
              return;
          }
  
          // Filter users to find the one matching the approved name
          const Approveduserid = users?.filter((item) => item?.um_name === headerObj?.WPSH_ApprovedBy_Name);
          console.log("abcdaaaaaaa", Approveduserid);
  
          // Check if the user was found
          if (Approveduserid.length === 0) {
              console.error("No user found with the approved name");
              return;
          }
  
          // Update the user approval signature if data is returned
          if (Approveduserid && Approveduserid.length > 0) {
              setuserApproveSignature(Approveduserid[0]);
          } else {
              console.error("No data returned from getbyuserid");
          }
      } catch (error) {
          console.error("An error occurred:", error);
      }
  };


  useEffect(() => {
    fetchUserMaster();
  }, []);

  useEffect(() => {
    
    fetchUserId();
  }, [users, headerObj?.WPSH_ApprovedBy_Name]);

    const handleChangetestarray = (Index, key, newValue) => {
      setIsEdited(true)
      const updatedheadertestObj = { ...headerObj };
      console.log("headerObjjjj", headerObj);
      // updatedProcessObj[Index][0][0].Actual_Value[valueIndex] = newValue;
      updatedheadertestObj.testarray[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const [error, setError] = useState({
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_ApprovedBy_Name: {},
      WPSH_PreparedBy_Remark: {},
      WPSH_ApprovedBy_Remark: {},

      WPQ_WP_TypeOfTest: [],
      WPQ_WP_Acceptance_Criteria: [],
      WPQ_WP_Result: {},
      WPQ_WP_Comments: {},
      WPQ_WP_Report_Nummber: {},

      WPQ_Notes: {},
      WPQ_Certi_test_conductedby: {},
      WPQ_Certi_Mechanical_Tesstby: {},
      WPQ_Certi_RT_Film_Rvaluatedby: {},
    });

    console.log("eeeeewq", error);

    const validate = async (detaildata) => {
      
      var errortype =
        WPStype === "Groove" ? WPS_Groove_Detail : WPS_Groove_Detail;
      let entries1 = Object.entries(WPQPage1);
      let entries2 = Object.entries(detaildata);
      let entries3 = Object.entries(WPQPage3);
      let isError = false;
      console.log("entries", entries2);

      console.log("entries3", entries3);
      //enteries1, entries 2,enteries 3 - 
      entries1.map(([key, val]) => {
        if (headerObj[key] === "") {
          console.log("errorroro_entries1", headerObj[key], key);
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
      });

      entries3.map(([key, val]) => {
        if (headerObj[key] === "") {
          console.log("errorroro_entries3", headerObj[key], key);
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
      });
      if (Band === "T" || Band === "P") {
        if (headerObj.WPSH_ApprovedBy_Remark === "") {
          // console.log("errorroro", headerObj[key], key);
          isError = true;
          error.WPSH_ApprovedBy_Remark = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error.WPSH_ApprovedBy_Remark = {
            status: false,
            message: "",
          };
        }
      }
      errortype.map((cols) => {
        entries2.forEach(([key, val]) => {
          // console.log("entry111",cols.Data[keyprocess]);

          // var cols = val;
          console.log("entry1", detailObj[key][cols.Column][0].Actual_Value[0]);
          // let arraydata = data[0].Actual_Value[i]

          var value = "";
          var value1 = "";

          var msg = "";
          if (cols.Type === "S") {
            value = detailObj[key][cols.Column][0].Actual_Value[0];
            value1 = detailObj[key][cols.Column][1].Range_Qualified[0];

            msg = `This field is required`;
            //  isError = true
            // isError =false
          }
          if (cols.Type === "SA") {
            console.log("enterrSA")
            value = detailObj[key][cols.Column][0].Actual_Value[0];
            value1 = detailObj[key][cols.Column][1].Range_Qualified[0].Range_Qualified1[0];

            msg = `This field is required`;

            if (
              value === undefined ||
              value === "" ||
              value === null || 
              value1 === undefined ||
              value1 === "" ||
              value1 === null 
            
            ) {
              isError = true;
              // setIsError(true);
              
  
              setError((prevState) => ({
                ...prevState,
  
                [cols.Column]: [
                  {
                    Actual_Value: [
                      {
                        status: true,
                        message: msg,
                      },
                    ],
                  },
                  {
                    Range_Qualified: [
                      {
                        status: true,
                        message: msg,
                      },
                    ],
                  },
                  
                ],
  
              }));
            } else {
              setError((prevState) => ({
                ...prevState,
                [cols.Column]: [
                  {
                    Actual_Value: [
                      {
                        status: false,
                        message: "",
                      },
                    ],
                  },
                  {
                    Range_Qualified: [
                      {
                        status: false,
                        message: "",
                      },
                    ],
                  },
                  
                ],
              }));
            }
          }

          if (
            value === undefined ||
            value === "" ||
            value === null ||
            value1 === undefined ||
            value1 === "" ||
            value1 === null
          ) {
            isError = true;
            // setIsError(true);
            

            setError((prevState) => ({
              ...prevState,

              [cols.Column]: [
                {
                  Actual_Value: [
                    {
                      status: true,
                      message: msg,
                    },
                  ],
                },
                {
                  Range_Qualified: [
                    {
                      status: true,
                      message: msg,
                    },
                  ],
                },
              ],
              // dataaaa: {
              //   status: true,
              //   message: msg,
              // },
            }));
          } else {
            setError((prevState) => ({
              ...prevState,
              // ...error,
              [cols.Column]: [
                {
                  Actual_Value: [
                    {
                      status: false,
                      message: "",
                    },
                  ],
                },
                {
                  Range_Qualified: [
                    {
                      status: false,
                      message: "",
                    },
                  ],
                },
              ],
            }));
          }
        });
      });

         
      var norows = checkTestAdded(array);
      

      if (norows) {
        array[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        array[0].error = true;

        if (
          array[0].WPQ_WP_TypeOfTest !== "" &&
          array[0].WPQ_WP_Acceptance_Criteria !== ""
        ) {
          array[0].error = false;
        }
      }

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const handleCount = () => {
      
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.WPSH_Status === Status.StatusApproved) {
          console.log("no", location.state?.WPS_Change_No);

          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCountWPQ(headerObj);
        }
      }
    };




    const handleAdd = async (approvalstatus) => {

      let result = false;
      if (
        checkwpsdata !== undefined ||
        checkwpsdata !== "" ||
        checkwpsdata !== null
      ) {
        headerObj.WPS_Key = checkwpsdata?.SK;
      }

      if (headerObj?.WPSH_Status === Status.StatusApproved) {
        handleCount();
      }
      console.log("Third page", headerObj?.WPSH_Approval_Status);

      if (
        headerObj?.WPSH_Approval_Status !== Status.StatusTempSaved &&
        headerObj?.WPSH_Approval_Status !== undefined
      )
        headerObj.WPSH_Status = headerObj?.WPSH_Approval_Status;
      else headerObj.WPSH_Status = approvalstatus;


      if (Band === "P" || Band === "T") {
        headerObj.wpq_signatureApproved = headerObj.wpq_signatureApproved;
        headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name;
        // headerObj.WPSH_ApprovedBy_Date = todayDate;
        headerObj.WPSH_ApprovedBy =  headerObj.WPSH_ApprovedBy_Name
      }
      if (Band !== "T" || Band !== "P") {
        headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
        // headerObj.WPSH_PreparedBy_Date = todayDate;
        headerObj.wpq_signaturePrepared = headerObj.wpq_signaturePrepared;
      }

      console.log("third page", "1");

      let PG1Add = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        WPQ_RecordNo: headerObj.WPQ_RecordNo,
        WPQ_WelderId:headerObj.WPQ_WelderId,
        WPSH_Status: headerObj.WPSH_Status,
        testarray: headerObj.testarray

      };

      let PG1Update = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        WPQ_RecordNo: headerObj.WPQ_RecordNo,
        WPQ_WelderId:headerObj.WPQ_WelderId,
        WPSH_Status: headerObj.WPSH_Status,
        WPQ_Notes: headerObj.WPQ_Notes,
        WPQ_Certi_Mechanical_Tesstby: headerObj.WPQ_Certi_Mechanical_Tesstby,
        WPQ_Certi_RT_Film_Rvaluatedby: headerObj.WPQ_Certi_RT_Film_Rvaluatedby,
        WPQ_Certi_test_conductedby: headerObj.WPQ_Certi_test_conductedby,
        WPSH_PreparedBy_Name:headerObj.WPSH_PreparedBy_Name,
        WPSH_PreparedBy_Remark:headerObj.WPSH_PreparedBy_Remark,
        WPSH_PreparedBy_Date:headerObj.WPSH_PreparedBy_Date,
        WPSH_ApprovedBy_Name:headerObj.WPSH_ApprovedBy_Name,
        WPSH_ApprovedBy_Remark:headerObj.WPSH_ApprovedBy_Remark,
        WPSH_ApprovedBy_Date:headerObj.WPSH_ApprovedBy_Date,
        wpq_signatureApproved:headerObj.wpq_signatureApproved,
        wpq_signaturePrepared:headerObj.wpq_signaturePrepared,
        WPSH_Status:headerObj.WPSH_Status,
        Process_Status:headerObj.Process_Status
      };


      const postUpdate = [PG1Add];
      const postUpdateOthers = [PG1Update];


      
      let res = await AddNewGrooveWPQ(headerObj)
      // await AddUpdateWPQHeaderRecord(postUpdate,postUpdateOthers)
        .catch((e) => {
          console.log("error in third page", e);
          result = false;
          //return result
        })
        .then(async () => {
          result = true;
          console.log("third page", result, "done");

          console.log("third page", result, "image done");
          if (
            action === Action.ActionEdit &&
            approvalstatus !== Status.StatusTempSaved
          ) {
            await AddLogs(logObj);
            console.log("third page", result, "log done");
          }
          //return result
        });
      console.log("Third page last", result);
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      
      //return users && users.some((user) => user.um_band === "P" || user.um_band === "T" );
      let res = await IsUserMasterBand();
      console.log("IsUserMasterBand Y/N", res.data.message);
      if (res !== undefined) return res.data.message;
      else return false;
    };

    // const handleSubmitTemp = async () => {
      
    //   // headerObj.WPSH_Approval_Status = Status.StatusTempSaved
    //   var res = false;
    //   console.log("handleSubmitTemp_IsEdited", IsEdited);
    //   if (action === Action.ActionEdit) {
    //     if (IsEdited) {
    //       setIsEdited(false);
    //       // setIsEditedPage2(false);
    //       console.log("2nd page Submit no ");

    //       res = await handleAdd(Status.StatusTempSaved);

    //       if (res) {
    //         console.log("2nd Page Tab", tabno);
    //         return MessageTypes.Temp;
    //       } else {
    //         return MessageTypes.Fail;
    //       }
    //     }

    //     return MessageTypes.None;
    //   } else {
    //     // headerObj.WPSH_Approval_Status = Status.StatusTempSaved
    //     res = handleAdd(Status.StatusTempSaved);

    //     if (res) {
    //       return MessageTypes.Temp;
    //     } else {
    //       return MessageTypes.Required;
    //     }
    //   }
    // };

    const validateTemp = async () => {
      debugger
      let isError = false;
console.log("erreee",error)
       let temperror = emptyError(error, setError, isError);

       console.log("teeeee",temperror)

      console.log("issss",isError)
      return {isError ,temperror};
    };


    const handleSubmitTemp = async () => {
      debugger
      var result = false;
      var val = await validateTemp();
      console.log("valdd",val)

      // if (action === Action.ActionEdit) {
        // if (IsEdited) {

          if (val.isError === false){
          if ( val.temperror === false) {
            // setIsEdited(false);
            if (headerObj?.WPSH_Status === Status.StatusApproved) {
              result = handleAdd(Status.StatusApproved);
            } else if (headerObj?.WPSH_Status === Status.StatusPending) {
              result = handleAdd(Status.StatusPending);
            } else {
              result = handleAdd(Status.StatusTempSaved);
            }

            if (result) {
              return MessageTypes.Temp;
            }
            // } else {
            //   return MessageTypes.Required;
            // }
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;

        
        // return MessageTypes.None;
      

    //   if (val.isError === false ) {
    //     if ( val.temperror === false) {

    //     result = handleAdd(Status.StatusTempSaved);

    //     if (result) {
    //       return MessageTypes.Temp;
    //     } else {
    //       return MessageTypes.Required;
    //     }
    //   }
    //   else {
    //     return MessageTypes.SpecialCharacters;
    //   }
    // }else {
    //   return MessageTypes.Required;
    // }
  }

    var detaildata = testObj.filter((item) => item.WPSD_Process !== "");
    console.log("wps_detail", detaildata);

    const handleSubmit = async () => {
      setIsEdited(false);
      // setIsEditedPage3(false);

      var detaildata = detailObj.filter((item) => item.WPQ_Process !== "");
      console.log("wps_detail", detaildata);

      var result = false;
      const isUsernamePresent = await checkIfUsernameExists();
      
      if (isUsernamePresent) {
        headerObj.WPSH_Status = Status.StatusTempSaved;
      }

      //Va;idate All pages
      if (!(await validate(detaildata))) {
        
        result = handleAdd(Status.StatusPending);
        
        if (result) {
          // setKey(+tabno - 2)
          console.log("Key", tabno);
          // toast.success("WPS Data Added Successfully")
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
           // toast.warning("Failed, WPS Data could not be saved")
        }
      } else {
        // toast.warning("Required Fields not filled ")
        setTimeout(() => {
          if (action === Action.ActionAdd) {
            alert("Please Temp Save Data Before Leaving this Page")
          }
        }, 500);
       
        return ([MessageTypes.RequiredAll, error_message, headerObj?.wld_WPSType]);
        
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
        //  if(WPStype ==="Overlay"){
        //   res = await handleSubmit();
        //   return res
        //  }
      },

      funcOnNextTemp: async () => {
        var res = false;
        console.log("is temporary", res);
        res = await handleSubmitTemp();
        console.log("is temporary", res);
        return res;
      },
    }));

    const fetchImages = async (item) => {
      

      try {
        
        let unique = headerObj?.WPSH_Project_No + "_" + headerObj?.WPSH_WPS_No;
        if (item.value !== "" || item.value !== undefined) {
          const result = await getPresignedURL(folderwpq, item.value);
          const geturl = result.url;
          console.log("geturl", geturl);
          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          // await axios.get(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    useEffect(() => {
      
      if (headerObj?.wld_pdf_image) {
        for (let item of headerObj?.wld_pdf_image) {
          fetchImages(item);
        }
      }
    }, []);

  

    const fetchbyuserprofile = async (userprofile) => {
      try {
        console.log("updateWeldingEngg_userprofile", userprofile);
        console.log("updateWeldingEngg_folderwld", folderwld);
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          console.log("updateWeldingEngg_url", result.url);

          const geturl = result.url;
          setPreviewuser(geturl);
          console.log("Previewuser_geturl", geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

//common function
    const fetchapproveUserProfile = async (userprofile) => {
      
      try {
        console.log("updateWitness_userprofile", userprofile);
        console.log("updateWitness_folderwld", folderwld);
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          console.log("updateWitness_url", result.url);
          const geturl = result.url;
          setPreviewapproved(geturl);
          console.log("setPreviewapproved_geturl", geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };
 

    useEffect(() => {
      
      const currentDate = new Date();
      formattedDate = new Date().toLocaleDateString("en-GB")
      setToday(formattedDate);

    }, []);

    useEffect(() => {
      
            if(action === Action.ActionEdit && headerObj?.wpq_signatureApproved !== ""){
              console.log("enterrrImggg")
      
              fetchbyuserprofile(headerObj?.wpq_signaturePrepared)
            }
      
            if(action === Action.ActionEdit && headerObj?.wpq_signatureApproved !== ""){
              console.log("enterrrImggg")
            fetchapproveUserProfile(headerObj?.wpq_signatureApproved)
            }
      
          }, []);

    var isoverlay = WPStype === "Groove" ? false : true;

    var groclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype === "Overlay" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);

    const [grooveclass, setgrooveclass] = useState(groclass);

    const [overlayclass, setoverlayclass] = useState(overclass);

    console.log("class", isOverlay, overlayclass);

    return (
      <Row className="font">
        <WPQHeader headerObj={headerObj}></WPQHeader>

        <Row style={{ padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative",top:"10px"}}>Tests </p>
              {console.log("Error....", error)}

              <CommonTable5
                array={array}
                setheaderObj={setheaderObj}
                setError={setError}
                status={error.WPQ_WP_TypeOfTest.status}
                message={error.WPQ_WP_TypeOfTest.message}
                status1={error.WPQ_WP_Acceptance_Criteria.status}
                message1={error.WPQ_WP_Acceptance_Criteria.message}
                status2={error.WPQ_WP_Result.status}
                message2={error.WPQ_WP_Result.message}
                status3={error.WPQ_WP_Comments.status}
                message3={error.WPQ_WP_Comments.message}
                status4={error.WPQ_WP_Report_Nummber.status}
                message4={error.WPQ_WP_Report_Nummber.message}
                // ProcessActive={ProcessActive}
                readOnly={isReadonly}
                handleChangetestarray={handleChangetestarray}
              />
            </Col>
          </Row>

          <Row style={{ padding: "20px 0px" }}>
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Notes</span>
            <Col md={12}>
              <div class="T-input w-100">
                <textarea
                  type="text"
                  name="WPQ_Notes"
                  style={{ height: "100px" }}
                  id="WPQ_Notes"
                  readOnly={isReadonly}
                  maxLength={90}
                  placeholder=" "
                  className={
                    error.WPQ_Notes.status
                      ? "T-input placeHolderInput inputText text-field-with-error"
                      : "T-input placeHolderInput inputText"
                  }
                  value={headerObj?.WPQ_Notes}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Notes: error,
                    }));
                  }}
                />
                <label for="WPQ_Notes">Notes</label>
              </div>
              <div className="required-text1">
                {error.WPQ_Notes.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Notes.message}
                  </Form.Text>
                )}
              </div>
            </Col>
          </Row>

          <Row style={{padding: "20px 0px" }} className="PQR-Page-row-padding">
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Certification</span>
            <Col md={6}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Certi_test_conductedby"
                  type="text"
                  maxLength={30}
                  readOnly={isReadonly}
                  status={error.WPQ_Certi_test_conductedby.status}
                  value={headerObj?.WPQ_Certi_test_conductedby}
                  handleChange={handleChange}
                  label="Test Conducted By"
                  message={error.WPQ_Certi_test_conductedby.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Certi_test_conductedby: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Certi_Mechanical_Tesstby"
                  type="text"
                  maxLength={30}
                  status={error.WPQ_Certi_Mechanical_Tesstby.status}
                  value={headerObj?.WPQ_Certi_Mechanical_Tesstby}
                  handleChange={handleChange}
                  readOnly={isReadonly}
                  label="Mechanical Test By"
                  message={error.WPQ_Certi_Mechanical_Tesstby.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Certi_Mechanical_Tesstby: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Certi_RT_Film_Rvaluatedby"
                  type="text"
                  maxLength={82}
                  readOnly={isReadonly}
                  status={error.WPQ_Certi_RT_Film_Rvaluatedby.status}
                  value={headerObj?.WPQ_Certi_RT_Film_Rvaluatedby}
                  handleChange={handleChange}
                  label="RT Film Evaluated By"
                  message={error.WPQ_Certi_RT_Film_Rvaluatedby.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Certi_RT_Film_Rvaluatedby: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
          </Row>
{/* ---------------prepared by---------------------------- */}

{/* <Row style={{ padding: "20px 60px", position:"relative", bottom:"40px",}}> */}
         <Row style={{ padding: "20px 13px" }}>
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">Supervised By - Welding Engineer</span>
              </Col>
              <Col style={{paddingLeft:"101px"}}>
                <span className="WPS-blue-text"> Approved By - Welding Engineer</span>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_PreparedBy_Name"
                      name="WPSH_PreparedBy_Name"
                      className={
                        error.WPSH_PreparedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_PreparedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      stylenormal={normalstyle}
                      valueField={"um_name"}
                      // disabled={action === Action.ActionEdit ? true : false}
                      value={headerObj?.WPSH_PreparedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: prename.value,
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                        console.log("aaaarrr", a);
                        fetchbyuserprofile(a[0].wld_profile);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: a[0].um_name,
                          wpq_signaturePrepared: a[0].wld_profile,
                          WPSH_PreparedBy: a[0].SK,
                        }));

                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_PreparedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_PreparedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>



              <Col md={3}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  // todayDate={todayDate}
                  // maxDate={maxDate}
                  //  disabled={Readonly}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Col md={3} style={{position:"relative",left:"38px"}}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      id="WPSH_ApprovedBy_Name"
                      name="WPSH_ApprovedBy_Name"
                      className={ error.WPSH_ApprovedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_ApprovedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      stylenormal={normalstyle}
                      valueField={"um_name"}
                      // disabled={action === Action.ActionEdit ? true : false}
                      value={headerObj?.WPSH_ApprovedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(prename) => {
                        if (action === Action.ActionEdit) setIsEdited(true);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: prename.value,
                        }));
                        

                        console.log("ItemList on", prename);
                        let a = users.filter((b) => {
                          return b.um_name === prename.value;
                        });
                        console.log("aaaarrr", a);
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: a[0].um_name,
                          wpq_signatureApproved: a[0].wld_profile,
                          WPSH_ApprovedBy: a[0].SK,
                        }));

                        fetchapproveUserProfile(a[0].wld_profile
                        );
                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_ApprovedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_ApprovedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
          
              </Col>

              <Col md={3} style={{position:"relative", left:"30px"}}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  // todayDate={todayDate}
                  // maxDate={maxDate}
                  //  disabled={Readonly}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Row className="mb-4" >
                <Col style={{position:"relative", left:"13px"}}>
                  <span>Signature Image</span>
                </Col>
                <Col style={{position:"relative", left:"57px"}}>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row style={{position:"relative", bottom:"10px"}}>
                <Col md={6}style={{position:"relative", left:"14px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>

                <Col md={6} style={{position:"relative", left:"58px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
              </Row>
            
              <Row className="mb-4" style={{padding:"0px 15px",position:"relative", bottom:"10px",paddingTop:"3%"}}>
                  {/* <div className="Supervised_remark">
                  <div className=""> */}
                <Col md={3} style={{width:"50%"}}>
                  <Single_Input_Superviser_remark
                    disabled={copydata}
                    name="WPSH_PreparedBy_Remark"
                    type="text"
                    style={{ height: "90px" }}
                    maxLength={50}
                    readOnly={isReadonly}
                    status={error.WPSH_PreparedBy_Remark.status}
                    value={headerObj?.WPSH_PreparedBy_Remark}
                    handleChange={handleChange}
                    label="Remark"
                    message={error.WPSH_PreparedBy_Remark.message}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_PreparedBy_Remark: error,
                      }));
                    }}
                  ></Single_Input_Superviser_remark>
                </Col>
                
                {Band === "P" || Band === "T" ? (
                  <Col md={3} style={{paddingLeft:"58px",width:"50%"}}>
                    <div class="T-input w-100">
                      <input
                        style={{ height: "90px",width:"112%",position:"relative", left:"6px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        maxLength={200}
                        readOnly={isReadonly}
                        // disabled={copydata}
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        // value={ProjectObj.wld_ProjectNumber}
                        // onChange={handleChange}
                        value={headerObj?.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                    <div className="required-text1">
                      {error.WPSH_ApprovedBy_Remark.status && (
                        <Form.Text className="text-danger">
                          {error.WPSH_ApprovedBy_Remark.message}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                ) : (
                  <Col md={3} style={{paddingLeft:"58px",width:"50%"}}>
                    <div class="T-input w-100" >
                      <input
                        style={{ height: "90px",width:"112%",position:"relative", left:"6px" }}
                        type="textarea"
                        id="WPSH_ApprovedBy_Remark"
                        name="WPSH_ApprovedBy_Remark"
                        readOnly
                        maxLength={200}
                        // disabled={copydata}
                        placeholder=" "
                        className={
                          error.WPSH_ApprovedBy_Remark.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        // value={ProjectObj.wld_ProjectNumber}
                        // onChange={handleChange}
                        value={headerObj?.WPSH_ApprovedBy_Remark}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericAllSpecialCharacters(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            WPSH_ApprovedBy_Remark: error,
                          }));
                        }}
                      />
                      <label for="WPSH_ApprovedBy_Remark">Remark</label>
                    </div>
                   
                  </Col>
                )}
             
                
                {/* </div>
                </div> */}
               

                </Row>
                 {Band === "P" || Band === "T" ? (
                <Col style={{position:"relative",bottom:"40px" ,left:"54%"}}> 
               <span className="WPS-blue-text">Action</span>
             
                <div className="d-flex mb-4" style={{paddingTop:"10px"}}>
                    <div>
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Approved"
                        // checked={this.state.site === result.SITE_NAME}
                        // onChange={this.onSiteChanged}
                        checked={headerObj?.WPSH_Approval_Status === "Approved"}
                        onChange={(e) => handleChangeRadio(e)}
                      />
                      <span className="ms-2">Approve</span>
                    </div>
                    <div className="ms-5">
                      <input
                        type="radio"
                        name="WPSH_Approval_Status"
                        value="Rejected"
                        // checked={this.state.site === result.SITE_NAME}
                        // onChange={this.onSiteChanged}
                        checked={headerObj?.WPSH_Approval_Status === "Rejected"}
                        onChange={(e) => handleChangeRadio(e)}
                     in />
                      <span className="ms-2">Reject</span>
                    </div>
                  </div>
                </Col>
              
              ) : (
                ""
              )}

            </Row>
          </Row>
        </Row>
      </Row> 
    );
  }
);

export default Test_and_Certificate;
