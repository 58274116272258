import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Col, Form, Modal, Row, Alert } from "react-bootstrap";
import { todayDate,maxDate } from "../utility/CommonComponents/TodayDate";
import {
  AddNewWelder,

  UpdateWelder,

  getPresignedURL,
} from "../../Services/Welder-services";
import { getPEDUsers } from "../../Services/user-service";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import {
  islessthenMaxlength,
  isNumber,
  isAlphanumericWithDashDot,
  isAlphanumericWithSpaceDotDash,
  isAlphanumericWithDotSpaceDashCommaBrackets,
  isAlphanumericWithDotSpaceDashCommaBracketsemail,

} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Assests/Css/welder.css";
import defaultimg from "../../icons/user.png";
import WelderProfileCellRenderer from "../utility/AgGridDefination";
import { statusChange } from "../utility/FormControl";
import axios from "axios";

import { SearchSelectWelder } from "../utility/search-select";

import ButtonModal from "../Modals/buttonModal";
import { Date_Input } from "../utility/CommonComponents/Date_Component";

const AddEditWelderMaster = (props) => {
  console.log(process.env.REACT_APP_NODE_ENV);
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "Welder";
  let folderName = "wld_signatures"


  const [checked, setChecked] = useState(true);
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [Welder, setWelder] = useState([]);
  const [WelderCode, setWelderCode] = useState(false);
  const [profile, setProfile] = useState("");

  const fetchWelder = async () => {
    const result = await getPEDUsers();
    setWelder(result);
  };
  useEffect(() => {
    fetchWelder();
  }, []);

  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  const [WelderObj, setWelderObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          wld_status: true,
          wld_code: "",
          wld_name: "",
          wld_salarycode: "",
          wld_dob: todayDate,
          wld_companyname: "",
          wld_plant: "",
          wld_address: "",
          wld_city: "",
          wld_state: "",
          wld_pincode: "",
          wld_country: "",
          wld_language: "",
          wld_mobile: "",
          wld_fax: "",
          wld_telephone: "",
          wld_email: "",
          SK: "",
          PK: "Welder",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
          wld_profile: "",
        }
  );

  console.log("data", WelderObj);

  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_language: {},
    wld_address: {},
    wld_city: {},
    wld_state: {},
    wld_pincode: {},
    wld_country: {},
    wld_mobile: {},
    wld_fax: {},
    wld_email: {},
    wld_telephone: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
    wld_name: {},
    wld_salarycode: {},
    wld_dob: {},
    wld_companyname: {},
    wld_plant: {},
    wld_profile: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setWelder",
    });
  };

  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
    }),
  });
  const [blyestyle, setBlue] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-purple)",
    }),
  });
  const [errorstyle, seterrorstyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-red-alt)",
    }),
  });

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setWelderObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      wld_salarycode: {},
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setWelderObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  const [userProfiles, setUserProfiles] = useState([]);

  const fileInputRef = useRef(null);

  const [file, setFile] = useState();

  const avatar = { defaultimg };

  const [previewUrl, setPreviewUrl] = useState(avatar.defaultimg);

  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const fileInputimg = useRef(null);

  const handleFile = (file) => {
    console.log("FILEEe", file);

    const maxSize = 1 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
      file = null;
      return;
    }

    let uploadextension = file.name.split(".");
    console.log("fffnfifm", uploadextension);
    
    if (
      uploadextension[1] !== "jpeg" &&
      uploadextension[1] !== "png" &&
      uploadextension[1] !== "jpg"
    ) {
      console.log("nooooouploadeddddd");
      alert("Please Upload Jpeg,png or jpg images");
      file = null;
      return;
    }

    setProfile(file);
    setPreviewUrl(URL.createObjectURL(file));
    WelderObj.wld_profile = file.name;
  };

  const resetForm = () => {
    if (action === "Add") {
      setWelderObj((prevState) => ({
        ...prevState,
        wld_status: true,
        wld_code: "",
        wld_address: "",
        wld_city: "",
        wld_state: "",
        wld_pincode: "",
        wld_country: "",
        wld_language: "",
        wld_mobile: "",
        wld_fax: "",
        wld_telephone: "",
        wld_email: "",
        SK: "",
        wld_createdby: "",
        wld_createdon: "",
        wld_name: "",
        wld_salarycode: "",
        // wld_dob: "",
        wld_companyname: "",
        wld_plant: "",
        wld_profile: "",
      }));
      setPreviewUrl(defaultimg);
      fileInputRef.current.value = null;
    } else if (action === "Edit") {
      setWelderObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_code: props.rowdata.wld_code,
        wld_address: props.rowdata.wld_address,
        wld_city: props.rowdata.wld_city,
        wld_state: props.rowdata.wld_state,
        wld_pincode: props.rowdata.wld_pincode,
        wld_country: props.rowdata.wld_country,
        wld_language: props.rowdata.wld_language,
        wld_mobile: props.rowdata.wld_mobile,
        wld_fax: props.rowdata.wld_fax,
        wld_telephone: props.rowdata.wld_telephone,
        wld_email: props.rowdata.wld_email,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
        wld_name: props.rowdata.wld_name,
        wld_dob: props.rowdata.wld_dob,
        wld_salarycode: props.rowdata.wld_salarycode,
        wld_companyname: props.rowdata.wld_companyname,
        wld_plant: props.rowdata.wld_plant,
        wld_profile: props.rowdata.wld_profile,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_code: {},
      wld_language: {},
      wld_address: {},
      wld_city: {},
      wld_state: {},
      wld_pincode: {},
      wld_country: {},
      wld_fax: {},
      wld_mobile: {},
      wld_email: {},
      wld_telephone: {},
      SK: {},
      wld_salarycode: {},
      wld_name: {},
      wld_dob: {},
      wld_companyname: {},
      wld_plant: {},
      wld_profile: {},
    }));
  };

  const validateWelder = (action) => {
    let result = false;
    let isError = false;
    if (WelderObj.wld_email !== "" && !WelderObj.wld_email.match("[@]")) {
      WelderObj.wld_email = "";
      isError = true;
      error.wld_email = {
        status: true,
        message: "Welder Email @ and . is required. Eg: john@godrej.com",
      };
    }

    if (WelderObj.wld_email !== "" && !WelderObj.wld_email.match("[.]")) {
      WelderObj.wld_email = "";
      isError = true;
      error.wld_email = {
        status: true,
        message: "Welder Email @ and . is required. Eg: john@godrej.com",
      };
    }

    if (WelderObj.wld_status === "") {
      isError = true;
      error.wld_status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (action === "Edit") {
      console.log("imageeee", props.rowdata);
      console.log("imageeee", WelderObj);

      console.log("edittt", result);
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.SK.status ||
      error.wld_code.status ||
      error.wld_address.status ||
      error.wld_city.status ||
      error.wld_state.status ||
      error.wld_pincode.status ||
      error.wld_country.status ||
      error.wld_language.status ||
      error.wld_mobile.status ||
      error.wld_fax.status ||
      error.wld_telephone.status ||
      error.wld_email.status ||
      error.wld_salarycode.status ||
      error.wld_companyname.status ||
      error.wld_name.status ||
      error.wld_dob.status ||
      error.wld_plant.status
    ) {
      isError = true;
    }
    return isError;
  };

  const handleAdd = (e) => {
    // console.log("image",fileInput)
    console.log("profile", profile);

    let result;
    if (WelderObj.wld_status === true) {
      WelderObj.wld_status = "Active";
    } else {
      WelderObj.wld_status = "Inactive";
    }
    if (action === "Add") {
      WelderObj.wld_code = WelderObj.wld_code || "";
      WelderObj.status = "Active";
      WelderObj.SK =
        WelderObj.wld_code !== "" ? WelderObj.wld_code : WelderObj.wld_email;
      WelderObj.wld_createdby = username;

   

      WelderObj.wld_createdon = WelderObj.wld_createdon;
      result = AddNewWelder(WelderObj);
    } else {
      WelderObj.wld_updatedby = WelderObj.wld_createdby || "";
      WelderObj.wld_updatedon = WelderObj.wld_updatedon;
      result = UpdateWelder(WelderObj);
    }
    result
      .then(async (resp) => {
        if (WelderObj.wld_profile !== "") {
          
          console.log("insideUrl", WelderObj.wld_profile);
          const result = await getPresignedURL(folderName,WelderObj.wld_profile);
          console.log(result);
          const posturl = result.url;
          setPreviewUrl(posturl);
          console.log("img", posturl);
          axios.put(posturl, profile);
          // console.log("putt",posturl,fileInput.files[0])
          console.log("puttprofile", profile);
        }

        if (action === "Edit") {
          toast.success(`Selected Welder has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success(`Selected Welder has been Added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
        toast.warning(`Selected Welder has failed`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateWelder(action)) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  const fetchUserProfiles = async () => {
    try {
      if (WelderObj.wld_profile !== "") {
        console.log("getimage", WelderObj.wld_profile);
        const result = await getPresignedURL(folderName,WelderObj.wld_profile);
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl(geturl);
        console.log("img", geturl);
        // await axios.get(geturl)
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };
  useEffect(() => {
    fetchUserProfiles();
  }, []);

  const handleOndrop = (event) => {
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    console.log("drop", imageFile);
  };

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Welder successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header className="modal_heading" closeButton>
          <Modal.Title>{action} New Welder</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={handleSubmit}
          autoComplete="off"
          encType="multipart/form-data"
        >
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col md={4} sm={2}>
                  <Form.Group>
                    <form encType="multipart/form-data">
                      <div className="pic_upload">
                        <img src={previewUrl} className="circle" />
                        <WelderProfileCellRenderer src={userProfiles} />
                        <div
                          className="drop_zone"
                          onDragOver={handleOndragOver}
                          onDrop={handleOndrop}
                        >
                          <label className="pic_text_black" for="fileInput">
                            Drop your photo here or
                          </label>{" "}
                          <label className="pic_text_blue" for="fileInput">
                            Select a file
                          </label>
                          <br></br>
                          <label className="pic_text_weld">
                            Supports: Jpeg, PNG and JPG, Size:Max 1MB
                          </label>
                          <br></br>
                          <label className="pic_text_optional">
                            (Optional)
                          </label>
                          <label className="imgtext">
                            {WelderObj.wld_profile}
                          </label>
                          <input
                            id="fileInput"
                            name="fileInput"
                            type="file"
                            accept="image/*"
                            hidden
                            ref={fileInputRef}
                            onChange={(e) => handleFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </form>
                    &nbsp;&nbsp;
                  </Form.Group>
                </Col>

                {/* /////////////////////////////////////////////////status////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group className="form-switch-input1">
                    <Row>
                      <Col>
                        <div class="w-input"></div>
                        <label
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            color: "var(--col-707070)",
                            fontWeight: "bold",
                          }}
                          class="required-field"
                        >
                          Status{" "}
                        </label>
                      </Col>
                      <Col>
                        <Form.Switch
                          label={WelderObj.wld_status ? "Active" : "Inactive"}
                          className={WelderObj.wld_status ? "green" : "gray"}
                          name="wld_status"
                          style={{ padding: "10px" }}
                          checked={WelderObj.wld_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              {/* /////////////////////////////////////////////////Constructor////////////////////////////////////////////// */}
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <div className="checkboxproject">
                      <input
                        type="checkbox"
                        placeholder=" "
                        className="form-check-input"
                        id="chkcode"
                        onClick={() => {
                          setWelderCode(!WelderCode);
                          resetForm();
                        }}
                        disabled={action === "Edit" ? true : false}
                        defaultChecked={!checked}
                      />{" "}
                      &nbsp;&nbsp;
                      <label for="chkcode">Contractor </label>
                    </div>
                    &nbsp;&nbsp;
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////Welder Number////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        type="text"
                        id="wld_code"
                        name="wld_code"
                        placeholder=" "
                        maxLength="50"
                        required
                        disabled={
                          action === "Edit" || WelderCode === false
                            ? true
                            : false
                        }
                        className={
                          error.wld_code.status
                            ? " w-input placeHolderInput inputText text-field-with-error allow"
                            : "w-input placeHolderInput inputText allow"
                        }
                        value={WelderObj.wld_code || ""}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithDashDot(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_code: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_code">
                        Welder Code{" "}
                      </label>
                      {error.wld_code.status && (
                        <Form.Text className="text-danger">
                          {error.wld_code.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////SALARY CODE////////////////////////////////////////////// */}

                <Col md={4}>
                  {/* <Form.Group className="pro-input-select"> */}
                  <Form.Group className="edit-input-select">
                    <div className="search-select">
                      <SearchSelectWelder
                        // className="inspdrop"
                        id="wld_salarycode"
                        name="wld_salarycode"
                        className={
                          error.wld_salarycode.status ? "err-input" : ""
                        }
                        // className={
                        //   error.wld_salarycode.status
                        //     ? "placeHolderInput inputText text-field-with-error allow"
                        //     : "placeHolderInput inputText allow"
                        // }
                        stylegreen={
                          error.wld_salarycode.status ? errorstyle : normalstyle
                        }
                        data={Welder}
                        stylenormal={normalstyle}
                        valueField={"empSalCode"}
                        disabled={
                          action === "Edit" || WelderCode === true
                            ? true
                            : false
                        }
                        value={WelderObj?.wld_salarycode || ""}
                        labelField1={"empSalCode"}
                        labelField2={"empshortName"}
                        placeholder=""
                        onChange={(ItemList) => {
                          console.log(
                            "ItemList on",
                            ItemList,
                            Welder,
                            WelderObj
                          );
                          let a = Welder.filter((b) => {
                            return b.empSalCode === ItemList.value;
                          });
                          console.log("a", a);
                          setWelderObj((prev) => ({
                            ...prev,
                            wld_salarycode: a[0].empSalCode,
                            wld_name: a[0].empshortName,
                            // wld_dob: a[0].empDateOfBirth.split(" ")[0],
                            // wld_companyname: a[0].empCompanyCode,
                            // wld_plant: a[0].empLocationCode,
                          }));
                        }}
                      ></SearchSelectWelder>
                    </div>
                    <label>Salary Code</label>
                    <div className="required-text-select">
                      {error.wld_salarycode.status && (
                        <Form.Text className="text-danger">
                          {error.wld_salarycode.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

              
              </Row>

              {/* ///////////////////////////////////////////////// NAME///////////////////////////////////////////// */}
              <Row className="row_height">
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_name"
                        name="wld_name"
                        type="text"
                        disabled={
                          action === "Edit" || WelderCode === false
                            ? true
                            : false
                        }
                        maxLength="50"
                        required
                        class="asterisk"
                        className={
                          error.wld_name.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_name}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpaceDotDash(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_name: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_name">
                        Name
                      </label>
                      {error.wld_name.status && (
                        <Form.Text className="text-danger">
                          {error.wld_name.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>

               
<Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_companyname"
                        name="wld_companyname"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_companyname.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_companyname || ""}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_companyname: error,
                          }));
                        }}
                      />
                      <label for="wld_companyname">Company Name</label>
                      {error.wld_companyname.status && (
                        <Form.Text className="text-danger">
                          {error.wld_companyname.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
             
                
<Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_plant"
                        name="wld_plant"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_plant.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_plant || ""}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_plant: error,
                          }));
                        }}
                      />
                      <label for="wld_plant">Plant</label>
                      {error.wld_plant.status && (
                        <Form.Text className="text-danger">
                          {error.wld_plant.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>



              {/* /////////////////////////////////////////////////DOB////////////////////////////////////////////// */}
              <Row className="row_height" style={{position:"relative", top:"0"}}>
              

<Col md={4} style={{position:"relative",right:"5px"}}>
              <Date_Input
                divClass="T-input w-100"
                label="DOB"
                className="groove_datecolor"
                name="wld_dob"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                maxDate={maxDate}
                // disabled={Readonly}
                obj={WelderObj}
                setobj={setWelderObj}
                setError={setError}
                status={error.wld_dob.status}
                message={error.wld_dob.message}
                // datevalue={datevalue}
              />
            </Col>

                {/* /////////////////////////////////////////////////Address////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_address"
                        name="wld_address"
                        type="text"
                        // style={{height:"37px"}}
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_address.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_address || ""}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_address: error,
                          }));
                        }}
                      />
                      <label for="wld_address">Address</label>
                      {error.wld_address.status && (
                        <Form.Text className="text-danger">
                          {error.wld_address.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////City////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_city"
                        name="wld_city"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_city.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_city}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_city: error,
                          }));
                        }}
                      />
                      <label for="wld_city">City</label>
                      {error.wld_city.status && (
                        <Form.Text className="text-danger">
                          {error.wld_city.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* /////////////////////////////////////////////////State////////////////////////////////////////////// */}
              <Row className="row_height">
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_state"
                        name="wld_state"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_state.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_state}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                          isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_state: error,
                          }));
                        }}
                      />
                      <label for="wld_state">State</label>
                      {error.wld_state.status && (
                        <Form.Text className="text-danger">
                          {error.wld_state.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////Pincode////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_pincode"
                        name="wld_pincode"
                        type="text"
                        maxLength="6"
                        class="asterisk"
                        className={
                          error.wld_pincode.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_pincode}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumber(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_pincode: error,
                          }));

                          if (!error.status) {
                            error = islessthenMaxlength(e, "Pincode");
                            setError((prevState) => ({
                              ...prevState,
                              wld_pincode: error,
                            }));
                          }
                        }}
                      />
                      <label for="wld_pincode">Pincode</label>
                      {error.wld_pincode.status && (
                        <Form.Text className="text-danger">
                          {error.wld_pincode.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////Country////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_country"
                        name="wld_country"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_country.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_country}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_country: error,
                          }));
                        }}
                      />
                      <label for="wld_country">Country</label>
                      {error.wld_country.status && (
                        <Form.Text className="text-danger">
                          {error.wld_country.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* /////////////////////////////////////////////////Language////////////////////////////////////////////// */}
              <Row className="row_height" style={{position:"relative", top:"0px"}}>
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_language"
                        name="wld_language"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_language.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_language}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_language: error,
                          }));
                        }}
                      />
                      <label for="wld_language">Language</label>
                      {error.wld_language.status && (
                        <Form.Text className="text-danger">
                          {error.wld_language.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////Mobile////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_mobile"
                        name="wld_mobile"
                        type="text"
                        maxLength="10"
                        class="asterisk"
                        className={
                          error.wld_mobile.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_mobile}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumber(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_mobile: error,
                          }));
                        }}
                      />
                      <label for="wld_mobile">Mobile</label>
                      {error.wld_mobile.status && (
                        <Form.Text className="text-danger">
                          {error.wld_mobile.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////Fax////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_fax"
                        name="wld_fax"
                        type="text"
                        maxLength="20"
                        class="asterisk"
                        className={
                          error.wld_fax.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_fax}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumber(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_fax: error,
                          }));
                        }}
                      />
                      <label for="wld_fax">Fax</label>
                      {error.wld_fax.status && (
                        <Form.Text className="text-danger">
                          {error.wld_fax.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {/* /////////////////////////////////////////////////Telephone////////////////////////////////////////////// */}
              <Row className="row_height" style={{position:"relative", top:"7px"}}>
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_telephone"
                        name="wld_telephone"
                        type="text"
                        maxLength="12"
                        class="asterisk"
                        className={
                          error.wld_telephone.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_telephone}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumber(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_telephone: error,
                          }));
                        }}
                      />
                      <label for="wld_telephone">Telephone</label>
                      {error.wld_telephone.status && (
                        <Form.Text className="text-danger">
                          {error.wld_telephone.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* /////////////////////////////////////////////////Email ID////////////////////////////////////////////// */}
                <Col md={4}>
                  <Form.Group>
                    <div class="w-input">
                      <input
                        id="wld_email"
                        name="wld_email"
                        type="text"
                        maxLength="50"
                        //class="asterisk"
                        className={
                          error.wld_email.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WelderObj.wld_email}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBracketsemail(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_email: error,
                          }));
                        }}
                      />
                      <label for="wld_email">Email ID</label>
                      {error.wld_email.status && (
                        <Form.Text className="text-danger">
                          {error.wld_email.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

         

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        
        </Form>
      </Modal>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditWelderMaster;
