import React from "react";
import { Link } from "react-router-dom";

function DeviationImageRenderer(props) {
  const imagename = props.data;

  return (
    <Link onClick={props.handleImageClick} style={{ paddingLeft: "0px" }} className="add-new-link">
      {imagename}
    </Link>
  )
}

export { DeviationImageRenderer };