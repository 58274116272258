import { Button, Col, Modal, Row } from "react-bootstrap";

const WCSViewNote = (props) => {

  const handleOK = (e) => {
    
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title><b>NOTE</b> </Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row  style={{ color: "var(--txt-color)" }}>
                <Col lg={12} md={12} xs={12}>
                    1. File format will be .xlsx/.xls. 
                    <br/>
                    2. Columns will be in following order “Project Number”, “Joint Number”, “WPS Number 1”, “WPS Number 2”, “WPS Number 3”, “WPS Number 4”. 
                    <br/>
                    3. Columns 1 to 6 (both inclusive) will be considered for data uploading. Data in other columns will be ignored. 
                    <br/>
                    4. User should select Project Number from front end dropdown before uploading data from excel file otherwise validation message will be displayed as “Please select project number before uploading data from excel file”. 
                    <br/>
                    5. Column “Project Number” will have valid project number as per Project Master and value should be same for all rows. Otherwise validation message displayed as “Project Number not present in Project Master or multiple project numbers found” and complete file data will NOT be uploaded. 
                    <br/>
                    6. Combination of project number and joint number should be unique, then only system should upload data otherwise error message will be displayed as “Duplicate combination of project number and joint number. Cannot upload data” and complete file data will NOT be uploaded. 
                    <br/>
                    7. Each row should have data in at least in columns Project Number, Joint Number and WPS Number 1. Otherwise validation message will be displayed as “Mandatory data in Project Number, Joint Number and WPS Number 1 not present in at least one row. Cannot upload data” and complete file data will NOT be uploaded. 
                    <br/>
                    8. Data should be present in consecutive rows staring from second row (First row will have column headers). Otherwise validation message will be displayed as “Blank row found. Cannot upload data” and complete file data will NOT be uploaded. 
                    <br/>
                    9. Data in columns WPS Number 1, WPS Number 2, WPS Number 3, WPS Number 4 should be present in sessions WPS Groove, Overlay and Tube to Tubesheet otherwise validation message will be displayed as “WPS not maintained” and complete file data will NOT be uploaded. 
                    <br/>
                    10. In single row data should not be repeated in columns WPS Number 1, WPS Number 2, WPS Number 3, WPS Number 4. Otherwise validation message will be displayed as “WPS number is repeated” and complete file data will NOT be uploaded. 
                    <br/>
                    11. When data is added for first time for any project number, then fields “Change ID number” and “WCS revision no” will be 0, “Reason for change” will be blank.
                </Col>
              </Row>
              <Row >
                <Col md={12} xs={12} className="submitexcelbtn">
                  <Button variant="success" className="submitbtn" onClick={handleOK}>
                    OK
                    </Button>
                </Col>
              </Row>
            </div>
          </Modal.Body>
      </Modal>
    </>
  );
}

export default WCSViewNote;
