import { useEffect, useState } from "react";
import Select from "react-select";

import SearchIcon from "../../icons/Back Arrow-1.png";

import MultiValuePopUp from "./MultiValuePopUp";
import { RenderIf } from "../../functions/welding-functions";
import SingleValuePopUp from "./SingleValuePopUp";

export const PMItemGroup = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [WPS, setWPS] = useState(props.data[props.colDef.field]);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });
  const [correctionFactor, setCorrectionFactor] = useState([
    { value: "Select", name: "Select" },
    { value: "ESSC flux", name: "0.700" },
    { value: "GTAW/SAW wire", name: "1.000" },
    { value: "Strip", name: "1.050" },
    { value: "FCAW wire", name: "1.300" },
    { value: "SAW flux", name: "1.300" },
    { value: "Electrode", name: "2.500" },
  ]);

  // const [values, setValues] = useState([]);
  // const [defaultValues, setDefaultValues] = useState(null);

  // useEffect(() => {
  //   const data = [
  //     { value: "0.700", label: "ESSC flux" },
  //     { value: "1.300", label: "SAW flux" },
  //     { value: "2.500", label: "Electrode" },
  //   ];

  //   setValues(data);
  //   setDefaultValues([data[0], data[1]]);
  // }, []);

  const onChange = (e) => {
    // alert("dd");
    console.log("changeeeeeee", e);
    console.log("only_target", e.target);
    console.log("name", e.target.name);
    console.log("valueee", e.target.value);
    console.log("label", e.target[e.nativeEvent.target.selectedIndex].label);

    //   setWPS(e.target.value);
    props.node.setDataValue("WI_CorrectionFactor", e.target.value);
    props.node.setDataValue(
      "WI_ItemGroup",
      e.target[e.nativeEvent.target.selectedIndex].label
    );
    //props.data["isModified"] = true;
    console.log("param", props.data);
    //props.stopEditing()
  };
  return (
    <>
      <div className="itemGroup" >
        <select onChange={onChange}>
          {correctionFactor.map((item) => (
            //         console.log("itemsss",item)

            <option key={item.value} value={item.name}>
              {item.value}-{item.name}
            </option>
          ))}
        </select>

        {/* <Select
          style={{
            width: "120px",
            position: "relative",
            left: "33px",
          }}
          name="selectSubTag"
          id="selectSubTag"
          value={correctionFactor}
          options={correctionFactor}
          getOptionLabel={(option) => option.value}
          getOptionValue={(option) => option.name}
          onChange={onChange}
          placeholder="Select Sub Tag"
        /> */}
      </div>
    </>
  );
};
