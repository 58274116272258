import Select from "react-select";
import React, { useEffect, useState } from "react";

export const YearDropDown = ({selectedyear,onChange})=> {

  selectedyear = selectedyear == "" ? new Date().getFullYear() : selectedyear
 // const[yearsarr,setyearsarr] = useState([])

 const years = (()=>{
    var year =  parseInt(process.env.REACT_APP_START_YEAR);
    console.log("years",new Array(20),( val, index) => index + year)
    var yearsarr =  Array.from(new Array(20),( val, index) => index + year);
    return yearsarr.map((i)=>{
      return (
        <option value={i}>
          {i}
        </option>
      )
    })
  //   console.log("years",yearsarr)
   
   })
  //  useEffect(() =>{
  //   years()
  //  },[])
    return(
        <div  align="right" style={{align:"right",border: "none"}}>
            <select
      // styles={customstyles}
     
   
      //styles={props.stylegreen}
      required
      value={selectedyear}
      
      style={{width:"9vw",align:"right", height:"32px", borderColor:"var(--col-ddd)",borderRadius:"3px"}}
      onChange={(opt) => {
        console.log("selected",opt,opt.target.value);
        onChange(opt.target);
      }}
      // className={props.className}
      >
        
        {years()}
      </select>
        </div>
        )
}