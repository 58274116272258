import React, { useEffect, useState } from "react";
import "./Css/WPQgroove_pdf.css";
import {  Modal } from "react-bootstrap";

import { jsPDF } from "jspdf";

import {
  getPresignedURL,
} from "../../../Services/Welder-services";   



import BarLoader
  from "react-spinners/BarLoader";
import { font } from "../../../Assests/font/font"
import { fontbold } from "../../../Assests/font/font_bold";
import { fontitalic } from "../../../Assests/font/font_italic";
import { fontbolditalic } from "../../../Assests/font/font_bolditalic";

import Groove_pdf_page1WPQ from "./Pages/Groove_pdf_page1WPQ";

import { getdetailsById } from "../../../Services/wpq_details-service";
import { getbyrecordno } from "../../../Services/wpq_groove-services";
import WPQTubeToTubesheet_pdf_page1 from "./Pages/WPQTubeToTubesheet_pdf_page1";
import WPQTubeToTubesheet_pdf_page2 from "./Pages/WPQTubeToTubesheet_pdf_page2";
import WPQTubeToTubesheet_pdf_page3 from "./Pages/WPQTubeToTubesheet_pdf_page3";
import Jspdf_font from "../../../Assests/font/jsPdf_font";

const Pdf_DownloadWPQ = (props, wld) => {
  let action = props.action;

  console.log("InPropsRow", props.rowdata)
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);



  const [PreviewUrl, setPreviewUrl] = useState([]);

  const [previewUrl1, setPreviewUrl1] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  // const [previewUrl3, setPreviewUrl3] = useState();


  const [pdf, setpdf] = useState([]);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");
  let userprofile = sessionStorage.getItem("UserProfile");

  let Token = sessionStorage.getItem("Token");

  let folderwld = "wld_signatures"
  let folderwps = "WPS_Groove"
  let folderwpq = "WPQ_Groove"


  console.log("userprofile", userprofile);

  const fetchbyid = async (IddetailPK, IddetailSK) => {
    
    const result = await getdetailsById(IddetailPK, IddetailSK);
    console.log("dddddddddddd", IddetailPK, IddetailSK)
    const wpqhead = await getbyrecordno(props.WPQtype, IddetailPK, IddetailSK)
    // console.log("dddddddddddd2", result, wpqhead);
    
    setpdf([...wpqhead, ...result]);
    // setdetailObj(result);
  };



  const createPDF = () => {
    fetchbyid(props?.rowdata?.WPQ_WelderId, props?.rowdata?.WPQ_RecordNo);
    //  React.Component(groove_pdf)
  };


  useEffect(() => {
    createPDF();
  }, []);


  const fetchimages = async (item) => {
    // const result = await getPresignedURL(item.value);
    console.log("Imagessssss", item)
    if (item.value != "" && item.value != undefined) {
      getPresignedURL(folderwps, item.value)
        .then(async (result) => {
          const geturl = result.url;
          console.log("geturl", geturl);
          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          //await axios.get(geturl);
        })
        .catch((e) => console.log("error", e));
    }

  };


  const fetchimagespdf = async () => {
    try {
      // const result = await getPresignedURL(item.value);
      console.log("Imagessssss", props?.rowdata?.Pdf_images)
      if (props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0] !== "") {
        console.log("getimage", props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0]);
        const result = await getPresignedURL(folderwpq, props?.rowdata?.Pdf_images[0].tube_Hole_ligament[0]);
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl1(geturl);
        console.log("img", geturl);
        //await axios.get(geturl)
      }

      if (props?.rowdata?.Pdf_images[1].tube_Hole[0] !== "") {
        console.log("getimage", props?.rowdata?.Pdf_images[1].tube_Hole[0]);
        const result = await getPresignedURL(folderwpq, props?.rowdata?.Pdf_images[1].tube_Hole[0]);
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl2(geturl);
        console.log("img", geturl);
        //await axios.get(geturl)
      }

    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }

  };

 

  const fetchapproveUserProfileswld = async () => {
    try {
      console.log("sign", props.rowdata)
      
      if (props?.rowdata?.wpq_signaturePrepared !== "" || props?.rowdata?.wpq_signaturePrepared != undefined) {
        console.log("getimage", props?.rowdata?.wpq_signaturePrepared);
        const result = await getPresignedURL(folderwld,
          props?.rowdata?.wpq_signaturePrepared
        );
        console.log("ress", result.url, props.rowdata);
        const geturl = result.url;
        setPreviewuser(geturl);
        console.log("img user", previewuser);
        //await axios.get(geturl);
      }

    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const fetchapproveUserProfileswldapproved = async () => {
    try {
      console.log("sign", props.rowdata)

      if (props?.rowdata?.wpq_signatureApproved !== "" || props?.rowdata?.wpq_signatureApproved != undefined) {
        console.log("getimagess", props?.rowdata?.wpq_signatureApproved);

        const result = await getPresignedURL(folderwld,
          props?.rowdata?.wpq_signatureApproved
        );
        console.log("ress", result.url, props?.rowdata?.wpq_signatureApproved);
        const geturl = result.url;
        setPreviewapproved(geturl);
        console.log("img app", geturl);
        // await axios.get(geturl);


      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };

  const downloadPdf = () => {
    setTimeout(() => {
      let doc = new jsPDF('p', 'pt', [595.28, 1020]);
      doc = Jspdf_font(doc)
      let pdfjs = document.querySelector('#MainDiv');
      doc.html(pdfjs, {

        callback: function (doc) {

          // doc.addFont('ArialMS', 'Arial', 'normal');

          //doc.setFont('WorkSans-Regular')
          console.log("font", doc.getFontList())
          var Filename = `WPQ Report ${pdf[0].wld_WPSType}`
          doc.save(Filename);
          props.onHide();
          props.setReload(!props.reload);
          setLoading(false)

          doc.setFontSize(10);


        }, margin: [-25, 10, 10, 10]


      });
      console.log("width", window.innerWidth)
    }, 5000);
  }


  useEffect(() => {

    fetchimagespdf()

    // fetchimages3()

    if (props?.rowdata?.wld_pdf_image) {
      for (let item of props?.rowdata?.wld_pdf_image) {
        fetchimages(item);
      }
    }
    fetchapproveUserProfileswld()
    fetchapproveUserProfileswldapproved()
    if (pdf.length > 0) {
      downloadPdf();

      // setShowForm(false)
    }
  }, [pdf]);



  return (

    <>

      {/* {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">

    </Spinner></div>} */}

      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}

            {/* {loading &&  <Spinner animation="border" className="spinner">

</Spinner>}      */}
          </p>
        </Modal.Body>
      </Modal>

      {pdf.length > 0 && (
        <div id="MainDiv">
          <br></br>
          {pdf[0]?.wld_WPSType === "Tubesheet" ? (
            <>
              <WPQTubeToTubesheet_pdf_page1
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                previewUrl1={previewUrl1}
                previewUrl2={previewUrl2}
                // previewUrl3= {previewUrl3}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
            <div style={{backgroundColor:"none", height:"45px", position: "relative" ,top:"10px"}}></div>
             
             <WPQTubeToTubesheet_pdf_page2
                pdf={pdf}
                PreviewUrl={PreviewUrl}
                setpdf={setpdf}
                previewuser={previewuser}
                previewapproved={previewapproved}
              />
               <div style={{backgroundColor:"none", height:"230px", position: "relative" ,top:"10px"}}></div>
             
           
            </>
          ) : (
            <Groove_pdf_page1WPQ
              pdf={pdf} Band={props.Band}
              setpdf={setpdf}
              previewuser={previewuser}
              previewapproved={previewapproved}
            />
          )}

        </div>
      )}








    </>
  )
}


export default Pdf_DownloadWPQ;
