import React, { useEffect, useState } from "react";

import "../Css/groovePQR_pdf.css"
import Groove_pdf_headerPQR from "./Groove_pdf_headerPQR";
import Wld_Consolelog from "../../../utility/CommonComponents/ConsoleLog";



const Groove_pdf_page1PQR = (
  { pdf, setpdf, previewuser, previewapproved ,PQRtype,previewUrl1,previewUrl2},
  props,
  wld
) => {
  Wld_Consolelog("PQRtypeeeeeeew",PQRtype)

  Wld_Consolelog("dpdpd",pdf)
  Wld_Consolelog("Heating_Method",pdf[0]?.PQR_PWHeatTre_Heating_Method)
  Wld_Consolelog("Cooling_Method",pdf[0]?.PQR_PWHeatTre_Cooling_Method)



  
  var isoverlay = pdf[0]?.wld_WPSType === "Groove" ? false : true;
  let WPSProcessDataWidth;
  
Wld_Consolelog("typeeee",pdf[0]?.wld_WPSType, isoverlay )

  Wld_Consolelog("pdf", pdf[1]);
  Wld_Consolelog("pdfffffffffffff", pdf);
  
  function  bindewldprocess(type,index) {
    

    let ArryMap = [];
    console.log("Typeis",type)
    console.log("Index",index)
     pdf[index] && pdf[index][type]?.forEach((element, index1) => {
      
       ArryMap?.push( element);
      console.log("ele",element)
    });

    
 

    // WPSProcessDataWidth = pdf.length < 3 ? "process1" : ( pdf.length < 4 ? "process2" : "process3")

  console.log("epepepep",WPSProcessDataWidth, pdf.length < 3, pdf.length < 4 )
    Wld_Consolelog('lelelele', pdf, pdf.length)
    Wld_Consolelog("ArrayTomap11", ArryMap[0], ArryMap[1], ArryMap[2], ArryMap[3]);

    const rows = [];
    rows.push(
      <tr key={1} style={{background:"none"}} >
        <td
          className="process1"
         
        >
          {ArryMap[0]}
        </td>
        <td
          className="process2"
        >
          {ArryMap[1]}
        </td>
        <td
          className="process3"
          
        >
          {ArryMap[2]}
        </td>
        {/* <td
          className="process4"
         
        >
          {ArryMap[3]}
        </td> */}
         </tr>
    );
    Wld_Consolelog("Rowwwww", rows);
    Wld_Consolelog("Arryryryryryry", ArryMap[0]);
    return rows;
  }



  const [detailObj, setdetailObj] = useState([]);
  var cols = 3,
    colwidth = "14%",
    col1Class,
    col2Class,
    col3Class,
    col4Class;
    // col1botmclass;

 
  const styles = {
    container: {
      display: "flex",
    },
    group: {
      display: "flex",
    },
    title3: {
      width: "50%",
    },
  };


      cols = pdf[2] !== undefined ? pdf[3] !== undefined ? 3 : 2 : 1
  
      colwidth = pdf[2] !== undefined ? (pdf[3] !== undefined ? "24%" : "90%"): "70%"
    //  alert(colwidth) 
      Wld_Consolelog("PDFFDF",pdf[2])
      col1Class =  "left7-td-noleft"
      // col1botmclass = "topBotm_col1"
      col2Class =   pdf[2] !== undefined  ? (pdf[3] !== undefined ? "left7-td" : "left7-td-noright") : "tdhidden"
      col3Class =    pdf[3] !== undefined ?  "left7-td-noright1" : "tdhidden"
      col4Class =    pdf[4] !== undefined ?  "left7-td-noright2" : "tdhidden"

  return (
    <div className="MainPDF" >
      <Groove_pdf_headerPQR pdf={pdf}></Groove_pdf_headerPQR>
     
      <div style={styles.group}>
        <div className="PQRbasemetal">BASE METALS (QW- 403)</div>

      </div>
      {/* <!-- --------------------------Table 1----------------------------------------------- --> */}
      <div style={styles.group}>
        <div className="PQRbasemetal" style={{ width: "31%" }}></div>  
      </div>
      <div style={styles.container}>
        <table id="table" className="pdfwpqTable" style={{width:"570px"}} >
          <tbody>
            <tr >
              <td style={{width:"63px"}} ></td>
              <th className="PQR_border" style={{width:"65px"}}>Product Form</th>
              <th className="PQR_noleft" style={{width:"105px"}}>Spec.( Type or Grade),UNS#</th>
              <th className="PQR_noleft" style={{width:"29px"}} >P no</th>
              <th className="PQR_noleft" style={{width:"25px"}} >G no</th>
              <th className="PQR_noleft" style={{width:"27px"}} >Size</th>
              <th className="PQR_noleft" style={{width:"27px"}}>Sch</th>
              <th className="PQR_noleft" style={{width:"42px"}}>Thk mm</th>
              <th className="PQR_noleft" style={{width:"27px"}} >Dia mm</th>
            </tr>


            <tr >
              <th className="PQR_noBotm" style={{width:"20px"}} >
                  
              </th>
              <td className="PQR_right" style={{textAlign:"left"}}> {pdf[0]?.PQR_BM_BM_ProductForm} </td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Spec}</td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Pno}</td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Gno}</td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Size} </td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Sch} </td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Thk} </td>
              <td className="PQR_right" style={{textAlign:"left"}}>{pdf[0]?.PQR_BM_BM_Dia}  </td>
          
            </tr>
            <tr>
              <th style={{textAlign:"left",width:"20px"}} className="PQR_Botm PQR_rt_lft">Welded To</th>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right">{pdf[0]?.PQR_BM_WTBM_ProductForm} </td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right"> {pdf[0]?.PQR_BM_WTBM_Spec} </td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right">{pdf[0]?.PQR_BM_WTBM_Pno}</td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right">{pdf[0]?.PQR_BM_WTBM_Gno}</td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right"> {pdf[0]?.PQR_BM_WTBM_Size}</td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right"> {pdf[0]?.PQR_BM_WTBM_Sch} </td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right"> {pdf[0]?.PQR_BM_WTBM_Thk} </td>
              <td style={{textAlign:"left"}} className="PQR_Botm PQR_right">{pdf[0]?.PQR_BM_WTBM_Dia}  </td>
            </tr>
            {isoverlay ? (
            <tr>
                  <th className="PQR_noTop" >
                    Notes
                  </th>
                  <td className="PQR_Botmrt" style={{ textAlign: "left"}} colspan={8}>
                    {pdf[0]?.PQR_BM_BM_Notes}
                  </td>
                </tr> ):( <><tr>
              <th className="PQR_noTop" >
                BM Condition 1
              </th>
              <td className="PQR_Botmrt" style={{ textAlign: "left",}} colspan={2}>
                {pdf[0]?.PQR_BM_BM_Condition1}
              </td>
              <th className="PQR_Botmrt" colspan={3}
              >
                BM Condition 2
              </th>
              <td className="PQR_Botmrt" colspan={3}
                style={{ textAlign: "left", }}>
                {pdf[0]?.PQR_BM_BM_Condition2}
              </td>
            </tr><tr>
                <th className="PQR_noTop">
                  Notes
                </th>
                <td className="PQR_Botmrt" style={{ textAlign: "left" }} colspan="8">
                  {pdf[0]?.PQR_BM_BM_Notes}
                </td>
              </tr></> 
            )}
            </tbody>
            </table>

            

        </div>
  
  {/* --------------------------------table 3------------------------------------ */}
 
  <div style={styles.group}>
  <div className="PQRbasemetal">POST WELD HEAT TREATMENT(QW-407)</div>
  </div>
           <table   id="table" className="PQRpwhtTable" cellSpacing="0">
            <tr>
              <th
                style={{
                  width: "10.8%", borderBottom: "0", borderTop: "1px solid black"}}  >
                Temperature
                <div className="PQRrcorner"> °C </div>
              </th>
              <td 
                style={{ width: "13%", borderTop: "1px solid black"}}
              >
                {pdf[0]?.PQR_PWHeatTre_Temperature}
              </td>
              <th
                style={{
                  width: "9.8%",
                  borderBottom: "0",
                  borderTop: "1px solid black",
                }}
              >
                Time
                <div className="PQRrcorner"> Minutes</div>
              </th>

              <td
                style={{ width: "20%", borderBottom: "0", borderLeft: "none", borderTop: "1px solid black", }}
              >
                {pdf[0]?.PQR_PWHeatTre_Time}
              </td>

              <th
                rowSpan="3"
                style={{
                  width: "4%",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderTop: "1px solid black",
                }}
              >
                Type
              </th>

              <td
                rowSpan="3"
                style={{ width: "13%", borderBottom: "1px solid black", borderLeft: "none", borderTop: "1px solid black", borderRight: "1px solid black", }}
              >
                {pdf[0]?.PQR_PWHeatTre_Type}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%", borderBottom: "0", borderTop: "0" }}>
                Heating Rate
                <div className="PQRrcorner"> °C/hr </div>
              </th>
              <td
                style={{
                  width: "15%",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.PQR_PWHeatTre_Heating_Rate}
              </td>
              <th style={{ width: "15%", borderBottom: "0", borderTop: "0" }}>
              Loading Temp.<div className="PQRrcorner"> °C </div>
 
              </th>

              <td
                style={{
                  width: "25%",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.PQR_PWHeatTre_Heating_Method}
              </td>
            </tr>
            <tr>
              <th style={{ width: "25%", borderTop: "0"}}>
                Cooling Rate
                <div className="PQRrcorner"> °C/hr </div>
              </th>
              <td
                style={{
                  width: "15%",borderBottom:"1px solid black",
                  borderLeft: "none",
                }}
              >
                {pdf[0]?.PQR_PWHeatTre_Cooling_Rate}
              </td>
              <th style={{ width: "15%", borderTop: "0" }}>
              Unloading Temp.  <div className="PQRrcorner"> °C </div>

              </th>

              <td 
                style={{ width: "25%",
                  borderTop: "0", borderBottom: "1px solid black",
                  borderLeft: "none",}}  >
                {pdf[0]?.PQR_PWHeatTre_Cooling_Method}
              </td>
            </tr>
            <tr>
              <th className="PQR_noTop" >  Notes
              </th>
              <td colspan="5" className="PQR_rt_botm" style={{textAlign:"left"}}>
                {pdf[0]?.PQR_PWHeatTre_Notes}
              </td>
            </tr> </table>
            <table   id="table" className="PQRpwhtTable">
            <tr>
              <td  style={{ width: "7.7%",}} ></td>
              <th  style={{ width: "28%",}} className="PQR_noTop">Preheat maintenance after completion of welding prior to PWHT ( QW-406.2)</th>
              <td  style={{ width: "15%",}} className="PQR_noTopnoLft"> {pdf[0]?.PQR_PWHeatTre_Maintenance} </td>
            </tr>

          </table>
  {/* --------------------------------table 4------------------------------------ */}
      {!isoverlay ? (<>
        <div style={styles.group}>
                  <div className="PQRbasemetal">JOINTS(QW- 402) </div>
                </div>
        <div style={styles.container}>
        <table id="table" className="PQRTable2" >
                <tbody>
      <th style={{width: "9.2% ",paddingLeft:"3px",height:"65px"}} className="PQR_border">
        Joint design	<br></br>
        Backing<br></br>
        Retainers <br />
        Groove angle   <div className="PQRrcorner"> deg </div> <br />
        Root opening   <div className="PQRrcorner"> mm </div> <br />
        Root face      <div className="PQRrcorner"> mm </div><br />
        Notes<br></br>
      </th>
      <td style={{ width: "16%",height:"65px"}} className="PQR_noleft" >
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_JointDesign} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_Backing} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_Retainers} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_GrooveAngle} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_RootOpening} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_RootFace} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_Notes} </span>
        <br />  </td>
        <td style={{ width: "17%",height:"60px" }} className="PQR_noleft">  
        {previewUrl1 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "5px", marginBottom:"10px",  marginLeft: "3px", marginRight:"3px", width:"90%",height:"65px"}}
                    src={previewUrl1}
                  />
                )} </td>
        <td style={{ width: "17%",height:"60px" }} className="PQR_noleft">
        {previewUrl2 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "5px", marginBottom:"10px",  marginLeft: "3px", marginRight:"3px", width:"90%",height:"65px"}}
                    src={previewUrl2}
                  />
                )}
        </td>
      </tbody>
                  </table></div> </>)
       : 
      ( <><div style={styles.group}>
                  <div className="PQRbasemetal">JOINTS(QW - 402) </div>
                </div>
                <div style={styles.container}>
              <table id="table" className="PQRTable2">
                <tbody>
      <th style={{width: "9.3% ",paddingLeft:"3px"}} className="PQR_border">
        Joint design
       <br />
        Notes </th>
        <td style={{ width: "13%" }}  className="PQR_noleft">
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_JointDesign} </span>
        <br />
        <span className="PQRforfont" style={{ float: "left"  }}>{pdf[0]?.PQR_JD_Notes} </span>
        <br /></td>
        <td style={{ width: "17%",height:"90px" }} className="PQR_noleft">   {previewUrl1 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "5px", marginBottom:"10px",  marginLeft: "3px", marginRight:"3px", width:"90%",height:"65px"}}
                    src={previewUrl1}
                  />
                )} </td>
        <td style={{ width: "17%",height:"90px" }} className="PQR_noleft">
        {previewUrl2 && (
                  <img
                    className="imgsizebig"
                    style={{ marginTop: "5px", marginBottom:"10px",  marginLeft: "3px", marginRight:"3px", width:"90%",height:"65px"}}
                    src={previewUrl2}
                  />
                )}
        </td>
        </tbody>
                  </table></div>  </>)
     
  }
                {/* --------------------------------table 5------------------------------------ */}
                <><div style={styles.group}>
  <div className="PQRbasemetal">WELDING PROCESSES</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2">
      <tbody>


        <tr className="PQRtrhide PQR_noleft ">
          <th className=" PQR_border PQRfillerMetalth" style={{ width: "27%" }}>
            Welding Process & Type
          </th>
          {isoverlay ? 
          <><td className={col1Class} style={{ width: { colwidth } }}>
                    {pdf[1]?.PQR_Process} {pdf[1] && " & "}{pdf[1]?.PQR_Type} {pdf[1] && " & "}{pdf[1] && pdf[1]?.PQR_Layer}
                  </td><td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQR_Process} {pdf[2] && " & "}
                      {pdf[2] && pdf[2]?.PQR_Type}{pdf[2] && " & "}{pdf[2] && pdf[2]?.PQR_Layer}
                    </td><td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQR_Process} {pdf[3] && " & "}
                      {pdf[3] && pdf[3]?.PQR_Type}{pdf[3] && " & "}{pdf[3] && pdf[3]?.PQR_Layer}
                    </td></>
          :
          <><td className={col1Class}  style={{ width: { colwidth } }}>
          {pdf[1]?.PQR_Process} {pdf[1] && " & "}{pdf[1]?.PQR_Type} 
        </td>

        <td className={col2Class} style={{ width: { colwidth } }}>
          {pdf[2] && pdf[2]?.PQR_Process} {pdf[2] && " & "}
          {pdf[2] && pdf[2]?.PQR_Type}
        </td>
        <td className={col3Class} style={{ width: { colwidth } }}>
          {pdf[3] && pdf[3]?.PQR_Process} {pdf[3] && " & "}
          {pdf[3] && pdf[3]?.PQR_Type}
        </td></>}
        </tr>

      </tbody>
    </table></div></>
                {/* --------------------------------table 6------------------------------------ */}
                <><div style={styles.group}>
  <div className="PQRbasemetal">FILLER METALS (QW- 404)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2" cellSpacing="0">
      <tbody>
        
        <tr className="PQRtrhide PQR_Toprt">
          <th className="PQRfillerMetalth PQR_noBotm" style={{ width: "27%" }}>
            SFA specification
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_SFA}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_SFA}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_SFA}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Classification
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_Classification}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_Classification}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_Classification}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
            
          >
           Filler Metal F No/ Weld Metal A No
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_FNo}{"/"}{pdf[1] && pdf[1]?.PQRD_FM_Ano}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_FNo}{"/"}{pdf[2] && pdf[2]?.PQRD_FM_Ano}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_FNo}{"/"}{pdf[3] && pdf[4]?.PQRD_FM_Ano}

          </td>
        </tr>
        {/* <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
            
          >
            Weld metal A - number
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_Ano}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_Ano}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_Ano}

          </td>
        </tr> */}

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Filler metal nominal composition
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1] && pdf[1]?.PQRD_FM_Nominal}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_Nominal}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_Nominal}

          </td>
          {/* <td className={col4Class} style={{ width: { colwidth } }}>
            {pdf[4] && pdf[4]?.PQRD_FM_Nominal}

          </td> */}
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Filler metal trade name
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_TradeName}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_TradeName}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_TradeName}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Filler metal product form
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_ProductForm}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_ProductForm}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_ProductForm}

          </td>
        </tr>


        <tr className="PQRtrhide PQR_right">
          <th  className="PQRfillerMetalth PQR_rt_lft" > Filler metal size <div className="PQRrcorner"> mm </div></th>
          <td className={col1Class}  style={{ width: { colwidth } }}>

            {bindewldprocess("PQRD_FM_FillerMetalSize",1)}
          </td>
          <td className={col2Class}  style={{ width: { colwidth } }}>
            {bindewldprocess("PQRD_FM_FillerMetalSize",2)}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }} >
            {bindewldprocess("PQRD_FM_FillerMetalSize",3)}
          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Deposited thickness     <div className="PQRrcorner"> mm </div>
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_DepositedThickness}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_DepositedThickness}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_DepositedThickness}

          </td>
        </tr>

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Maximum pass thickness     <div className="PQRrcorner"> mm </div>
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_MaxPassThickness}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_MaxPassThickness}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_MaxPassThickness}

          </td>
        </tr>
         
        {isoverlay ? 
         <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Weld deposit chemistry
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_WeldDepositChemistry}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_WeldDepositChemistry}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_WeldDepositChemistry}

          </td>
        </tr> :""}

        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux AWS specification & classification
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_FluxAWS}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_FluxAWS}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_FluxAWS}

          </td>
        </tr>
        {isoverlay ?
        <tr className="PQRtrhide PQR_right" >
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux nominal composition
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_FluxNominalComposition}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_FluxNominalComposition}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_FluxNominalComposition}

          </td>
        </tr> :""}

        <tr className="PQRtrhide PQR_right" >
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux trade name
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_FluxTradeName}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_FluxTradeName}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_FluxTradeName}

          </td>
        </tr>
        <tr className="PQRtrhide PQR_right">
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux type
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_FluxType}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_FluxType}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_FluxType}

          </td>
        </tr>
        <tr className="PQRtrhide PQR_right" >
          <th
            className="PQRfillerMetalth PQR_rt_lft"
           
          >
            Flux from recrushed slag
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_FM_FluxRecrushed}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_FM_FluxRecrushed}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_FM_FluxRecrushed}

          </td>
        </tr>
        {isoverlay ? 
        <><tr className="PQRtrhide  PQR_right">
                  <th
                    className="PQRfillerMetalth PQR_rt_lft"

                  >
                    Supplemental filler metal & Insert
                  </th>
                  <td className={col1Class} style={{ width: { colwidth } }}>
                    {pdf[1]?.PQRD_FM_Supplement}
                  </td>

                  <td className={col2Class} style={{ width: { colwidth } }}>
                    {pdf[2] && pdf[2]?.PQRD_FM_Supplement}

                  </td>
                  <td className={col3Class} style={{ width: { colwidth } }}>
                    {pdf[3] && pdf[3]?.PQRD_FM_Supplement}

                  </td>
                </tr>
            <tr className="PQRtrhide PQR_Botmrt">
                    <th
                      className="PQRfillerMetalth PQR_noTop">
                      Filler metal others
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_FM_FillerMetalOthers}
                    </td>

                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_FM_FillerMetalOthers}

                    </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_FM_FillerMetalOthers}

                    </td>
                  </tr></>
        :
        <tr className="PQRtrhide  PQR_Botmrt" >
        <th
          className="PQRfillerMetalth PQR_noTop"
         
        >
          Supplemental filler metal & Insert
        </th>
        <td className={col1Class} style={{ width: { colwidth } }}>
          {pdf[1]?.PQRD_FM_Supplement}
        </td>

        <td className={col2Class} style={{ width: { colwidth } }}>
          {pdf[2] && pdf[2]?.PQRD_FM_Supplement}

        </td>
        <td className={col3Class} style={{ width: { colwidth } }}>
          {pdf[3] && pdf[3]?.PQRD_FM_Supplement}

        </td>
      </tr>}

      </tbody>
    </table></div></>

  {/* --------------------------------table 7------------------------------------ */}
   <><div style={styles.group}>
  <div className="PQRbasemetal">POSITION(QW-405)	</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2" cellSpacing="0">
      <tbody>
        <tr className="PQRtrhide PQR_Toprt">
          <th className="PQRfillerMetalth PQR_noBotm" style={{ width: "27%" }}>
            Position of groove
          </th>
          <td className={col1Class} style={{ width: { colwidth }}}>
            {bindewldprocess("PQRD_Position_PositionofGroove",1)}
          </td>
          <td className={col2Class} style={{ width: { colwidth } }}>
            {bindewldprocess("PQRD_Position_PositionofGroove",2)}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {bindewldprocess("PQRD_Position_PositionofGroove",3)}
          </td>
        </tr>


        <tr className="PQRtrhide PQR_Botmrt" >
          <th className="PQRfillerMetalth PQR_noTop"> Weld progression</th>
          <td className={col1Class} style={{ width: { colwidth } }}>
           {bindewldprocess("PQRD_Position_WeldProgression",1)}
          </td>
          <td className={col2Class} style={{ width: { colwidth } }}>
            {bindewldprocess("PQRD_Position_WeldProgression",2)}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {bindewldprocess("PQRD_Position_WeldProgression",3)}
          </td>
        </tr>
      </tbody>
    </table></div></>

 {/* --------------------------------table 8------------------------------------ */}
    <><div style={styles.group}>
  <div className="PQRbasemetal">PREHEAT(QW-406)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2" cellSpacing="0">
      <tbody>

        <tr className="PQRtrhide PQR_Toprt" >
          <th
            className="PQRfillerMetalth PQR_noBotm"
            style={{ width: "27%" }}
          >
            Minimum preheat temperature  <div className="PQRrcorner">  °C </div>
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_Preheat_MinimumPreheatTemp}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_Preheat_MinimumPreheatTemp}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_Preheat_MinimumPreheatTemp}

          </td>
        </tr>
        <tr className="PQRtrhide PQR_Botmrt">
          <th
            className="PQRfillerMetalth PQR_noTop" >
            Maximum interpass temperature <div className="PQRrcorner">  °C </div>
          </th>
          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_Preheat_MaximumInterpassTemp}
          </td>

          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_Preheat_MaximumInterpassTemp}

          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_Preheat_MaximumInterpassTemp}

          </td>
        </tr>




      </tbody>
    </table></div></>
                {/* --------------------------------table 9------------------------------------ */}
                <><div style={styles.group}>
  <div className="PQRbasemetal">GAS(QW- 408)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2" cellSpacing="0" style={{width:"570px"}}>
      <tbody>
      {isoverlay ? 
      <tr className="PQRtrhide PQR_noleft PQR_Botm">
          <th className="PQRfillerMetalth PQR_border PQR_Botm"  style={{width: "27%" }}>
            <div className="PQRgroup">
              <div className="PQRlcorner">Sheilding:Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div>
            </div>
          </th>

          <td className={col1Class} style={{ width: { colwidth } }}>{pdf[1]?.PQRD_Gas_ShieldingType} {" & "} {pdf[1]?.PQRD_Gas_ShieldingFlowRate}</td>
          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_Gas_ShieldingType} {" & "} {pdf[2]?.PQRD_Gas_ShieldingFlowRate}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_Gas_ShieldingType} {" & "} {pdf[3]?.PQRD_Gas_ShieldingFlowRate}
          </td>
        </tr> 
       : <>
      <tr className="PQRtrhide PQR_Toprt">
          <th className="PQRfillerMetalth PQR_noBotm "  style={{width: "27%" }}>
            <div className="PQRgroup">
              <div className="PQRlcorner">Sheilding:Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div>
            </div>
          </th>

          <td className={col1Class} style={{ width: { colwidth } }}>{pdf[1]?.PQRD_Gas_ShieldingType} {" & "} {pdf[1]?.PQRD_Gas_ShieldingFlowRate}</td>
          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_Gas_ShieldingType} {" & "} {pdf[2]?.PQRD_Gas_ShieldingFlowRate}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_Gas_ShieldingType} {" & "} {pdf[3]?.PQRD_Gas_ShieldingFlowRate}
          </td>
        </tr> 
        {" "}
      <tr className="WPStrhide ">
          <th className="PQRfillerMetalth PQR_rt_lft"  style={{width: "27%" }}>
            <div className="PQRgroup">
              <div className="PQRlcorner">Trailing <div className="PQRrcorner" style={{paddingLeft:"8px",float:"right"}}>:Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div></div>
             
            </div>
          </th>

          <td className={col1Class} style={{ width: { colwidth } }}>
            {pdf[1]?.PQRD_Gas_TrailingType} {" & "} {pdf[1]?.PQRD_Gas_TrailingFlowRate}</td>
          <td className={col2Class} style={{ width: { colwidth } }}>
            {pdf[2] && pdf[2]?.PQRD_Gas_TrailingType} {" & "} {pdf[2]?.PQRD_Gas_TrailingFlowRate}
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_Gas_TrailingType} {" & "} {pdf[3]?.PQRD_Gas_TrailingFlowRate}
          </td>
        </tr> 
      <tr className="WPStrhide " style={{borderBottom: "1px solid black"}}>
          <th className="PQRfillerMetalth PQR_rt_lft"  style={{width: "27%" ,borderBottom: "1px solid black"}}>
            <div className="PQRgroup">
              <div className="PQRlcorner">Backing{" "} <div className="PQRrcorner" style={{paddingLeft:"6px",float:"right"}}> :Gas{"(%Comp)"}&FlowRate{"(I/min)"}</div></div>
            
            </div>
          </th>

          <td className={col1Class} style={{ width: { colwidth } }}>{pdf[1]?.PQRD_Gas_BackingType}{" & "} {pdf[1]?.PQRD_Gas_BackingFlowRate} </td>
          <td className={col2Class} style={{ width: { colwidth } }}>  
            {pdf[2] && pdf[2]?.PQRD_Gas_BackingType}{" & "} {pdf[2]?.PQRD_Gas_BackingFlowRate} 
          </td>
          <td className={col3Class} style={{ width: { colwidth } }}>
            {pdf[3] && pdf[3]?.PQRD_Gas_BackingType}{" & "} {pdf[3]?.PQRD_Gas_BackingFlowRate} 
          </td>
        </tr></> }
        {" "}
    

       
      </tbody>
    </table></div></>
   {/* --------------------------------table 9------------------------------------ */}
                <><div style={styles.group}>
  <div className="PQRbasemetal">ELECTRICAL(QW- 409)</div>
</div><div style={styles.container}>
    <table id="table" className="PQRTable2" cellSpacing="0" >
      <tbody>
        {!isoverlay ? (<>
        <tr className="PQRtrhide PQR_Toprt" >
          <th
            className="PQRfillerMetalth PQR_noBotm"
            style={{ width: "27%" }} >
            Filler metal size <div className="PQRrcorner"> mm</div>
          </th>
          <td className={col1Class}>
            {bindewldprocess("PQRD_Electrical_FillerMetalSize",1)}
          </td>
          <td className={col2Class}>
            {bindewldprocess("PQRD_Electrical_FillerMetalSize",2)}
          </td>
          <td className={col3Class}>
            {bindewldprocess("PQRD_Electrical_FillerMetalSize",3)}
          </td>
        </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Amperes <div className="PQRrcorner"> Amps</div></th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_Amperes",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_Amperes",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_Amperes",3)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Voltage <div className="PQRrcorner"> Volts </div></th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_Volts",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_Volts",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_Volts",3)}
            </td>
          </tr>
          <tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Travel speed <div className="PQRrcorner"> mm/min </div></th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_TravelSpeed",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_TravelSpeed",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_TravelSpeed",3)}
            </td>
          </tr>
          <tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Minimum bead Length (SMAW) <div className="PQRrcorner"> mm </div></th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_minBeadLength",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_minBeadLength",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_minBeadLength",3)}
            </td>
          </tr>
          <tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Arc Transfer Mode {"(FCAW/GMAW)"}</th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_ArcTransferMode",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_ArcTransferMode",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_ArcTransferMode",3)}
            </td>
          </tr>
          
          <tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Maximum heat input <div className="PQRrcorner"> KJ/mm </div> </th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_MaxHeatInput",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_MaxHeatInput",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_MaxHeatInput",3)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Tungsten type &  size</th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_TungstenType",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_TungstenType",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_TungstenType",3)}
            </td>
                  </tr><tr className="PQRtrhide PQR_right">
            <th className="PQRfillerMetalth PQR_rt_lft">Current/polarity</th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_CurrentPolarity",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_CurrentPolarity",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_CurrentPolarity",3)}
            </td>
          </tr><tr className="PQRtrhide PQR_Botmrt" >
            <th className="PQRthhide2 PQR_noTop" >DC pulsing current</th>
            <td className={col1Class}>

              {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",3)}
            </td>
          </tr></>) : (<><tr className="PQRtrhide PQR_Toprt" >
            <th
              className="PQRfillerMetalth PQR_noBotm"
              style={{ width: "27%" }}  >
              Filler metal size  <div className="PQRrcorner"> mm</div>
            </th>
            <td className={col1Class}>
              {bindewldprocess("PQRD_Electrical_FillerMetalSize",1)}
            </td>
            <td className={col2Class}>
              {bindewldprocess("PQRD_Electrical_FillerMetalSize",2)}
            </td>
            <td className={col3Class}>
              {bindewldprocess("PQRD_Electrical_FillerMetalSize",3)}
            </td>
          </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">
                Amperes     <div className="PQRrcorner"> Amps</div></th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_Amperes",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_Amperes",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_Amperes",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">
                Volts    <div className="PQRrcorner"> Volts</div></th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_Volts",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_Volts",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_Volts",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Travel speed   <div className="PQRrcorner"> mm/min</div>       </th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_TravelSpeed",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_TravelSpeed",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_TravelSpeed",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Maximum heat input  <div className="PQRrcorner"> KJ/mm</div>   </th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_MaxHeatInput",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_MaxHeatInput",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_MaxHeatInput",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Tungsten type &  size</th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_TungstenType",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_TungstenType",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_TungstenType",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">Current/polarity</th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_CurrentPolarity",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_CurrentPolarity",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_CurrentPolarity",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft">DC pulsing current</th>
              <td className={col1Class}>

                {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_DCPulsatingCurrent",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_right">
              <th className="PQRfillerMetalth PQR_rt_lft ">Wire Feed Type</th>
              <td className={col1Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedType",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedType",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedType",3)}
              </td>
            </tr><tr className="PQRtrhide PQR_Botmrt" >
              <th className="PQRthhide2 PQR_noTop">Wire Feed Speed  <div className="PQRrcorner">  m/min</div>  </th>
              <td className={col1Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedSpeed",1)}
              </td>
              <td className={col2Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedSpeed",2)}
              </td>
              <td className={col3Class}>
                {bindewldprocess("PQRD_Electrical_WireFeedSpeed",3)}
              </td>
            </tr></>)}
      </tbody>
    </table></div></>


   {/* --------------------------------table 10------------------------------------ */}
                <><div style={styles.group}>
                  <div className="PQRbasemetal"> TECHNIQUE(QW- 410)</div>
                </div>

                  <div style={styles.container}>
                    <table id="table" className="PQRTable2" cellSpacing="0">
                      <tbody>
                      {!isoverlay ? (
                        <><tr className="PQRtrhide PQR_Toprt">
                  <th
                    className="PQRfillerMetalth PQR_noBotm"> String or weave
                  </th>
                  <td className={col1Class} style={{ width: { colwidth } }}>
                    {pdf[1]?.PQRD_Technique_StringOrWeave} </td>
                  <td className={col2Class} style={{ width: { colwidth } }}>
                    {pdf[2] && pdf[2]?.PQRD_Technique_StringOrWeave}  </td>
                  <td className={col3Class} style={{ width: { colwidth } }}>
                    {pdf[3] && pdf[3]?.PQRD_Technique_StringOrWeave}   </td>
                </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Orifice/gas cup size
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Orifice_GasCupSize} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Orifice_GasCupSize}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Orifice_GasCupSize}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> C.T.W.D <div className="PQRrcorner"> mm </div>
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_CTWD} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_CTWD}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_CTWD}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Oscillation
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Oscillation} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Oscillation}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Oscillation}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Multi/Single electrode
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Multi_SingleElectrode} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Multi_SingleElectrode}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Multi_SingleElectrode}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Electrode angle
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_ElectrodeAngle} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_ElectrodeAngle}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_ElectrodeAngle}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"
                      style={{ width: "27%" }}>
                      Multi/Single pass per side
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_SinglePassPerSide} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_SinglePassPerSide}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_SinglePassPerSide}   </td>
                  </tr><tr className="PQRtrhide PQR_right">
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Multiple or single layer
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Multiple_SingleLayer} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Multiple_SingleLayer}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Multiple_SingleLayer}   </td>
                  </tr><tr className="PQRtrhide PQR_right">
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Peening
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Peening} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Peening}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Peening}   </td>
                  </tr><tr className="PQRtrhide PQR_right" >
                    <th className="PQRfillerMetalth PQR_rt_lft" >
                      Initial/interpass cleaning
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_InitialInterpassCleaning} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_InitialInterpassCleaning}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_InitialInterpassCleaning}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Back gouging method
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_BackGaugingMethod} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_BackGaugingMethod}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_BackGaugingMethod}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                    Groove preparation
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_GroovePreparation} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_GroovePreparation}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_GroovePreparation}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_Botmrt">
                    <th
                      className="PQRfillerMetalth PQR_noTop"> Technique Others 
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Technique_Other} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Technique_Other}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Technique_Other}   </td>
                  </tr></>
                        
                        ) : (<>
                  <tr className="PQRtrhide PQR_Toprt">
                  <th
                    className="PQRfillerMetalth PQR_noBotm"
                    style={{ width: "27%" }}>
                    Oscillation
                  </th>
                  <td className={col1Class} style={{ width: { colwidth } }}>
                    {pdf[1]?.PQRD_Technique_Oscillation} </td>
                  <td className={col2Class} style={{ width: { colwidth } }}>
                    {pdf[2] && pdf[2]?.PQRD_Technique_Oscillation}  </td>
                  <td className={col3Class} style={{ width: { colwidth } }}>
                    {pdf[3] && pdf[3]?.PQRD_Technique_Oscillation}   </td>
                </tr>
                  {/* -------------------------------------- */}
                  <tr className="PQRtrhide PQR_right" >
                  <th
                    className="PQRfillerMetalth PQR_rt_lft">
                    No. of electrodes on same puddle
                  </th>
                  <td className={col1Class} style={{ width: { colwidth } }}>
                    {pdf[1]?.PQRD_Technique_ElectrodesSamePuddle} </td>
                  <td className={col2Class} style={{ width: { colwidth } }}>
                    {pdf[2] && pdf[2]?.PQRD_Technique_ElectrodesSamePuddle}  </td>
                  <td className={col3Class} style={{ width: { colwidth } }}>
                    {pdf[3] && pdf[3]?.PQRD_Technique_ElectrodesSamePuddle}   </td>
                </tr>
        
                  <tr className="PQRtrhide PQR_right">
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Multiple or single layer
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Multiple_SingleLayer} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Multiple_SingleLayer}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Multiple_SingleLayer}   </td>
                  </tr>
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">Supplemental device
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_SupplimentalDevice} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_SupplimentalDevice}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_SupplimentalDevice}   </td>
                  </tr>
                 
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft"> Initial/interpass cleaning
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_InitialInterpassCleaning} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_InitialInterpassCleaning}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_InitialInterpassCleaning}   </td>
                  </tr>
               
                  <tr className="PQRtrhide PQR_right" >
                    <th
                      className="PQRfillerMetalth PQR_rt_lft">
                      Overlapping
                    </th>
                    <td className={col1Class} style={{ width: { colwidth } }}>
                      {pdf[1]?.PQRD_Technique_Overlaping} </td>
                    <td className={col2Class} style={{ width: { colwidth } }}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_Overlaping}  </td>
                    <td className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_Overlaping}   </td>
                  </tr>
              
                  <tr className="PQRtrhide PQR_Botmrt" >
                    <th
                      className="PQRfillerMetalth PQR_noTop"> C.T.W.D <div className="PQRrcorner"> mm </div>
                    </th>
                    <td  className={col1Class} style={{ width: { colwidth }}}>
                      {pdf[1]?.PQRD_Technique_CTWD} </td>
                    <td  className={col2Class} style={{ width: { colwidth }}}>
                      {pdf[2] && pdf[2]?.PQRD_Technique_CTWD}  </td>
                    <td  className={col3Class} style={{ width: { colwidth } }}>
                      {pdf[3] && pdf[3]?.PQRD_Technique_CTWD}   </td>
                  </tr></>)}





                      </tbody>
                    </table>
                    </div>
                    {pdf[4] ?
                    <div className="WPSfooter" style={{position:"fixed",top:"1015px",fontFamily:"GE-BodyCopy"}} >
                       <table className="WPSfooter">
                          <tr> <td>Page 1 of 3</td> </tr>
                       </table>
                    </div> : 
                    <div className="WPSfooter" style={{position:"fixed",top:"1015px"}} >
                       <table className="WPSfooter">
                          <tr> <td>Page 1 of 2</td> </tr>
                       </table>
                    </div>}
                  </>

              </div>
              );
              };
              export default Groove_pdf_page1PQR;