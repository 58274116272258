//TABLE WITH 2 ROWS AND 2 COLUMNS (WPQ- VARIABLES AND WELDING PROCESS)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import { isAlphanumeric } from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { TypeData,LayerData } from "../../WPSTransaction/WPSObjects/WPSGroove_entity";
import { SearchSelect } from "../search-select";
import { Action } from "../../PQRTransaction/PQRObjects/PQR_entity";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { ToastContainer, toast } from "react-toastify";
import { DeletePQR } from "../../../Services/pqr-details-service";
import 
{createDetailObjPQR
} from "../../PQRTransaction/PQRObjects/PQR_common";
export const PQRProcess = ({
  setheaderObj,
  headerObj,
  detailObj,
  disabled,
  setdetailObj,
  ProcessActive,
  isReadonly,
  setwps_detail
}) => {
  const location = useLocation();
  const [show, setShow] = useState();
  const [action, setaction] = useState(location.state?.action);
  const[processdelete,setprocessDelete] = useState(99)
  console.log("tab5__detailObjPQR Process", detailObj);
  let formName = "PQR Process";
  // console.log("tab5__headerObj_Process_Status", headerObj?.Process_Status);
console.log("wpstypeeeee",headerObj.wld_WPSType)
  const processDisabled = (i) => {
    if (
      action === Action.ActionEdit
      //&& headerObj?.Process_Status[i] === "InActive"
    ) {
      return true;
    } else if (headerObj?.WPSH_Status === "Temporary Saved") {
      return true;
    }
  };

  const processDelete = async() => {
  
  setShow(false)
  
  console.log("ProcessDelete",detailObj[processdelete])
  await DeletePQR(detailObj[processdelete].PK,detailObj[processdelete].SK).then(
    toast.success("PQR Process Deleted ", {
      position: toast.POSITION.TOP_LEFT,
      className: "toast-message",
    })
  ).catch((ex) =>{
    toast.success("PQR Process could not be deleted ", {
      position: toast.POSITION.TOP_LEFT,
      className: "toast-message",
    });
  })
  
  let newdet = detailObj.filter((det,i) => 
    !(i == processdelete))
  
  let temparray1 = createDetailObjPQR(4, 4);
  console.log("ProcessDelete",newdet,temparray1)
  setdetailObj([...newdet,...temparray1])
 // setwps_detail(detailObj.filter((item) => item.process !== ""))
  console.log("ProcessDelete",detailObj)
  }
  return (
    <>
     <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    <Table className="text-center tablewidth" style={{position:"relative", bottom: "8px"}}>
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
          <th  className="thickness_title" key={head}>{head}</th>
        ))} */}
          <th className="thickness_title" style={{ width: "21%" }}></th>
          <th className="thickness_title" style={{ width: "22%" }} >Process</th>
          <th className="thickness_title" style={{ width: "22%" }}>Type</th>
         { headerObj?.wld_WPSType === "Overlay" ? 
         <th className="thickness_title" style={{ width: "22%" }}>Layer</th> : 
          <th className="thickness_title"></th>}
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
          <th className="thickness_title"></th>
        </tr>
      </thead>

      <tbody>
        {detailObj &&
          detailObj?.map((item, i) => {
            return (
              <tr className={detailObj[i]?.error ? "border  error" : "border "}>
                <th
                  className={
                    detailObj[i]?.error
                      ? "text-start border thead-color error-head"
                      : "text-start border thead-color"
                  }
                >
                  <div key={i}>
                    <label>Process {i + 1}</label>
                    {detailObj[i]?.error && (
                      <Tooltip content={detailObj[i]?.errormessage}>
                        <img src={Error} alt="Error" className="ms-1" />
                      </Tooltip>
                    )}
                  </div>
                </th>
                <td className="PQRSelect" style={{ paddingLeft: "2%" }}>
                  <SearchSelect
                    className="inspdrop "
                    data={ProcessActive}
                    key={i}
                    readOnly={
                      action === Action.ActionCopy ||
                      action === Action.ActionEdit
                        ? isReadonly
                        : false
                    }
                    valueField={"wld_code"}
                    // disabled={
                    //   action === Action.ActionEdit &&
                    //   headerObj?.Process_Status[i] === "InActive"
                    //     ? true
                    //     : false
                    // }
                    // disabled={processDisabled(i)}
                    placeholder=""
                    // disabled={action === Action.ActionEdit ? true : false}
                    value={item?.PQR_Process}
                    labelField={"wld_code"}
                    onChange={(e) => {
                      console.log("deee", ProcessActive);
                      item.PQR_Process = e.value;

                      if (e.value === "NA") {
                        setShow(true)
                        setprocessDelete(i)
                      
                      }
                      let a = ProcessActive.filter((b) => {
                        return b.wld_code === e.value;
                      });
                      item.PQR_Process_Category = a[0]?.wld_p_category;
                      // item.PQR_new = String(Number(item.PQR) + 1);
                      // item.PQR_Change=String(Number(item.PQR_Change) + 1);
                      // item.PQR_new =`${"Process" + (i + 1) + "#" +"Change" + item.PQR_Change}` ;

                      
      // row.WPS_Change_No = String(Number(row.WPS_Change_No) + 1);

                      setdetailObj([...detailObj]);
                      console.log("dee", [...detailObj]);
                      // setdetailObj((prev) => ({
                      //   ...prev,
                      //   WPSD_Process: ProjectList[i].value,
                      // }));
                    }}
                  ></SearchSelect>
                </td>
                <td className="PQRSelect" style={{ paddingLeft: "2%" }}>
                  <SearchSelect
                    data={TypeData}
                    readOnly={action === Action.ActionCopy ? isReadonly : false}
                    valueField={"wld_type"}
                    // disabled={
                    //   action === Action.ActionEdit &&
                    //   headerObj?.Process_Status[i] === "InActive"
                    //     ? true
                    //     : false
                    // }
                    //disabled={processDisabled(i)}
                    value={item?.PQR_Type}
                    labelField={"wld_type"}
                    placeholder=""
                    onChange={(e) => {
                      item.PQR_Type = e.value;
                      setdetailObj([...detailObj]);

                      item.PQR_Process !== "" || item.PQR_Type !== ""
                        ? (detailObj[i].error = false)
                        : (detailObj[i].error = true);
                        
                      // item.PQR_Change=String(Number(item.PQR_Change) + 1);
                      // item.PQR_new =`${"Process" + (i + 1) + "#" +"Change" + item.PQR_Change}` ;
                    }}
                  ></SearchSelect>
                </td>
                { headerObj?.wld_WPSType === "Overlay" ? 
                 <td className="PQRSelect" style={{ paddingLeft: "2%" }}>
                 <SearchSelect
                   data={LayerData}
                   readOnly={action === Action.ActionCopy ? isReadonly : false}
                   valueField={"wld_layer"}
                   value={item?.PQR_Layer}
                   labelField={"wld_layer"}
                   placeholder=""
                   onChange={(e) => {
                     item.PQR_Layer = e.value;
                     setdetailObj([...detailObj]);
                     item.PQR_Process !== "" || item.PQR_Layer !== ""
                       ? (detailObj[i].error = false)
                       : (detailObj[i].error = true);
                   }}
                 ></SearchSelect>
               </td> 
               : 
                <td style={{ width: "39%" }}></td>}
                <td></td>
                <td></td>
                <td></td>
              </tr>
            );
          })}
      </tbody>
    </Table>
      <ConfirmAlertModal
      handleYes={processDelete}
      formName={formName}
      action="Delete"
      show={show}
      handleClose={() => setShow(false)}
    />
    </>
  );
};
