import React, { useEffect, useState } from "react";
import "./Css/Download_pdf.css";
import {  Modal } from "react-bootstrap";
import { getdetailsById } from "../../../Services/wps_details-service";
import {getbyprojectno} from "../../../Services/wps_groove-services";
import { jsPDF } from "jspdf";
import Groove_pdf_page1 from "./Pages/Groove_pdf_page1";
import Groove_pdf_page2 from "./Pages/Groove_pdf_page2";
import Tubesheet_Pdf_Page2 from "./Pages/Tubesheet_pdf_page2"
import {
  getPresignedURL,
} from "../../../Services/Welder-services";

import BarLoader
from "react-spinners/BarLoader";
import {font} from "../../../Assests/font/font";
import {fontbold} from "../../../Assests/font/font_bold";
import {fontitalic} from "../../../Assests/font/font_italic";
import {fontbolditalic} from "../../../Assests/font/font_bolditalic";
import TubeToTubesheet_pdf_page1 from "./Pages/TubeToTubesheet_pdf_page1";
import Jspdf_font from "../../../Assests/font/jsPdf_font";



const   Pdf_Download = (props,wld) => {
    let action = props.action;

   console.log("propppps",props.rowdata)

   console.log("bandddddd",props.Band)

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);



  const [PreviewUrl, setPreviewUrl] = useState([]);

  const [previewUrl1, setPreviewUrl1] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  const [previewUrl3, setPreviewUrl3] = useState();





  const [pdf, setpdf] = useState([]);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");
  let userprofile = sessionStorage.getItem("UserProfile");

  let Token = sessionStorage.getItem("Token");

  let folderwld = "wld_signatures"
  let folderwps = "WPS_Groove"

  console.log("userprofile", userprofile);

  const fetchbyid = async (IddetailPK, IddetailSK) => {
    const result = await getdetailsById(IddetailPK, IddetailSK);
    console.log("dddddddddddd",IddetailPK, IddetailSK)
    const wpshead = await getbyprojectno(props.rowdata.wld_WPSType,IddetailPK, IddetailSK)
    console.log("dddddddddddd", result,wpshead);
    setpdf([...wpshead, ...result]);

    // setdetailObj(result);
  };

 

  const createPDF = () => {
    fetchbyid(props.rowdata.WPSH_Project_No, props.rowdata.WPSH_WPS_No);

    //  React.Component(groove_pdf)
  };
 

  useEffect(() => {
    createPDF();
  }, []);


  const fetchimages = async (item) => {
    // const result = await getPresignedURL(item.value);
    console.log("Imagessssss",item)
    if(item.value != "" && item.value != undefined){
      getPresignedURL(folderwps,item.value)
      .then(async (result) => {
        const geturl = result.url;
        console.log("geturl", geturl);
        let a = { name: item.name, value: geturl };
        setPreviewUrl((prev) => [...prev, a]);
        //await axios.get(geturl);
      })
      .catch((e) => console.log("error", e));
    }
  
  };

  const fetchimagespdf = async () => {
    // const result = await getPresignedURL(item.value);
    console.log("Imagessssss",props.rowdata.Pdf_images)
    if (props.rowdata.Pdf_images[0].tube_Hole_ligament[0] !== "") {
      console.log("getimage", props.rowdata.Pdf_images[0].tube_Hole_ligament[0]);
      const result = await getPresignedURL(folderwps,props.rowdata.Pdf_images[0].tube_Hole_ligament[0]);
      console.log(result.url);
      const geturl = result.url;
      setPreviewUrl1(geturl);
      console.log("img", geturl);
      //await axios.get(geturl)
    }

    if (props.rowdata.Pdf_images[1].tube_Hole[0] !== "") {
      console.log("getimage", props.rowdata.Pdf_images[1].tube_Hole[0]);
      const result = await getPresignedURL(folderwps,props.rowdata.Pdf_images[1].tube_Hole[0]);
      console.log(result.url);
      const geturl = result.url;
      setPreviewUrl2(geturl);
      console.log("img", geturl);
      //await axios.get(geturl)
    }
  
  };

  const fetchimagespdfGroove = async () => {
    // const result = await getPresignedURL(item.value);
    console.log("Imagessssss",props.rowdata.wld_pdf_image)
    if (props.rowdata.wld_pdf_image[0].Image1[0] !== "") {
      console.log("getimage", props.rowdata.wld_pdf_image[0].Image1[0]);
      const result = await getPresignedURL(folderwps,props.rowdata.wld_pdf_image[0].Image1[0]);
      console.log(result.url);
      const geturl = result.url;
      setPreviewUrl1(geturl);
      console.log("img", geturl);
      //await axios.get(geturl)
    }

    if (props.rowdata.wld_pdf_image[1].Image2[0] !== "") {
      console.log("getimage", props.rowdata.wld_pdf_image[1].Image2[0]);
      const result = await getPresignedURL(folderwps,props.rowdata.wld_pdf_image[1].Image2[0]);
      console.log(result.url);
      const geturl = result.url;
      setPreviewUrl2(geturl);
      console.log("img", geturl);
      //await axios.get(geturl)
    }
  
  };

  console.log("preevieww1&2", previewUrl1,previewUrl2,previewUrl3)
  const fetchimages3 = async () => {

    if (props.rowdata.Pdf_images[2].tube_Weld_Joint[0] !== "") {
      
      console.log("getimage", props.rowdata.Pdf_images[2].tube_Weld_Joint[0]);
      const result = await getPresignedURL(folderwps,props.rowdata.Pdf_images[2].tube_Weld_Joint[0]);
      console.log(result.url);
      const geturl = result.url;
      setPreviewUrl3(geturl);
      console.log("img", geturl);
      //await axios.get(geturl)
    }

  }

  const fetchimages3Groove = async () => {

    if (props.rowdata.wld_pdf_image[2].Image3[0] !== "") {
      
      console.log("getimage", props.rowdata.wld_pdf_image[2].Image3[0]);
      const result = await getPresignedURL(folderwps,props.rowdata.wld_pdf_image[2].Image3[0]);
      console.log(result.url);
      const geturl = result.url;
      setPreviewUrl3(geturl);
      console.log("img", geturl);
      //await axios.get(geturl)
    }

  }


  console.log("preview",previewUrl3)

    const fetchapproveUserProfileswld = async () => {
    try {
      
      console.log("sign",props.rowdata)
      if (props.rowdata.wps_signaturePrepared !== ""  || props.rowdata.wps_signaturePrepared != undefined) 

      {
        console.log("getimage", props.rowdata.wps_signaturePrepared);
        
          const result = await getPresignedURL(folderwld,
            props.rowdata.wps_signaturePrepared
          );
          console.log("ress", result.url, props.rowdata);
          const geturl = result.url;
          setPreviewuser(geturl);
          console.log("img user", previewuser);
          //await axios.get(geturl);
        
        
      }
      if (props.rowdata.wps_signatureApproved !== "" || props.rowdata.wps_signatureApproved != undefined) 

      {
        console.log("getimage",props.rowdata.wps_signatureApproved);
       
          const result = await getPresignedURL(folderwld,
            props.rowdata.wps_signatureApproved
          );
          console.log("ress", result.url, props.rowdata.wps_signatureApproved);
          const geturl = result.url;
          setPreviewapproved(geturl);
          console.log("img app", geturl);
         // await axios.get(geturl);
       
   
      }
    } catch (error) {
      if (error.request) {
        console.log(error.request);
      } else {
        console.log(`Error: ${error.message}`);
      }
    }
  };


  console.log("preeuse",previewuser,previewapproved)


  const downloadPdf = () => {
    setTimeout(() => {
          let doc = new jsPDF('p', 'pt', "a4");

          doc = Jspdf_font(doc)
          let pdfjs = document.querySelector('#MainDiv');
          
          doc.html(pdfjs, {
            
        callback: function(doc) {
       
    // doc.addFont('ArialMS', 'Arial', 'normal');
           
          //doc.setFont('WorkSans-Regular')
           console.log("font",doc.getFontList())
           var Filename = `WPS ${pdf[0].wld_WPSType} - ${pdf[0].WPSH_WPS_No}`
          doc.save(Filename);
          props.onHide();
         props.setReload(!props.reload);

         setLoading(false)
        
       // doc.setFontSize(10);

        // setTimeout(() => {
        // setLoading(false)
        // },1000000);

        

        },margin: [-25, 10, 10,10]
        
       
    });      
    console.log("width",window.innerWidth)
  },9000);
}


  useEffect(() => {

      fetchimagespdf()

      fetchimages3()

      fetchimagespdfGroove()
      fetchimages3Groove()

    if (props.rowdata.wld_pdf_image) {
      for (let item of props.rowdata.wld_pdf_image) {
        fetchimages(item);
      }
    }
    fetchapproveUserProfileswld()
    if (pdf.length > 0) {
      downloadPdf();
      // setShowForm(false)
    }
  
  }, [pdf]);

  let page1 = "1"
  let page2 = "2"




return(

<>
      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(10vh - 10px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}

          </p>
        </Modal.Body>
      </Modal>



        {pdf.length>0  && <div id="MainDiv" >
          <br></br>
        {pdf[0]?.wld_WPSType === "Tubesheet" ? (
      <TubeToTubesheet_pdf_page1 page={page1} pdf={pdf} PreviewUrl= {PreviewUrl}  previewUrl1= {previewUrl1}   previewUrl2= {previewUrl2}  previewUrl3= {previewUrl3} setpdf={setpdf} />
    ) : (
      <Groove_pdf_page1 pdf={pdf} setpdf={setpdf} />
    )}
         {pdf[0]?.wld_WPSType === "Tubesheet" ? (
     <> <br></br> <Tubesheet_Pdf_Page2 page={page2} pdf={pdf} Band={props.Band} PreviewUrl= {PreviewUrl} previewuser = {previewuser} previewapproved={previewapproved}  /></>
    ) : (
         <div style={{pageBreakAfter:"always",paddingTop:"4px"}}> <br></br>
         <Groove_pdf_page2  pdf = {pdf} Band = {props.Band} PreviewUrl= {PreviewUrl} previewuser = {previewuser} previewapproved={previewapproved}  previewUrl1= {previewUrl1}   previewUrl2= {previewUrl2} previewUrl3={previewUrl3} />
         </div>
         )}
       </div>}

</>
)
    }


export default Pdf_Download;
