import "../Css/Download_pdf.css";

// import ReportLogo from "../../../../icons/pdfGodrejlogo.png";
import PDFLogo from "../../../../icons/PDFLogo.png";


const Groove_pdf_header_common = ({ currentDate, from }, props, wld) => {
  var title = null;
  console.log("from_common", from);
  // if (from === "WPQ") title = "Welder Performance Qualification (WPQ)";
  if (from === "WPQ") title = "Welder/Welding Operator Performance Qualifications (WPQ/WOPQ)";
  if (from === "WPQTTS")
    title = "Welder/Welding Operator Performance Qualifications (WPQ/WOPQ)";
  if (from === "PQR") title = "Procedure Qualification Record (PQR)";
  if (from === "WPS") title = "Welding Procedure Specification";
  console.log("PDFHeader", from, title);


  var headertitle = from === "WPQ" ? "WPQGOpageTitle" : (from === "PQR" ?  "WPSpageHeaderTitle" : "WPSpageHeaderTitle" )
 
  return (
    <header>
      <div className="fontGE" style={{paddingTop:"15px",position:"relative"}}>
      <table className="WPStablenoborder" style={{paddingTop:"20px",position:"relative"}}>
        <tr>
          <td
            
            rowSpan={"3"}
            style={{ width: "30%", verticalAlign: "middle" }}
          >
            <span style={{ float: "left", verticalAlign: "middle" }}>
              <img
                src={PDFLogo}
                style={{ width: "110px", verticalAlign: "middle", height: "25px"}}
              />
            </span>
          </td>
          <td className={headertitle}>{title}</td>
        </tr>
      </table>
      </div>
    </header>

  );
};
export default Groove_pdf_header_common;
