import "../Css/Download_pdf.css";
import glogo1 from "../../../../icons/glogo1.png";
import Groove_pdf_header_common from "./Groove_pdf_header_common";


const Groove_pdf_header = ({ pdf, page,setpdf }, props, wld) => {
  const currentDate = new Date(pdf[0]?.WPSH_WPS_Date);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
 const formattedDate = `${day}-${month}-${year}`;
console.log("pageee",props.page, page);

  return (
    <header>
      <Groove_pdf_header_common from={"WPS"} currentDate={currentDate}></Groove_pdf_header_common>
      

      <table id="table" className="WPSTable1" style={{position:"relative",top:"5px"}}>
        <tbody>
        {page ==="2" ? (
          <>
        <tr>
            <th style={{width: "13%", borderTop:"1px solid",borderBottom:"1px solid",borderRight:"1px solid",borderLeft:"1px solid"}}>
              WPS record number
              <br></br>
              Date
            </th>
            <td style={{ width: "25%" ,borderTop:"1px solid",borderBottom:"1px solid" }}>
              <span className="WPSforfont" style={{ float: "left"  }}>
                {pdf[0]?.WPSH_WPS_No}
              </span>
              <span className="WPSforfont" style={{ float: "right"  }}>
                Revision: {pdf[0]?.WPSH_WPS_Revi_No}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <br />
              <span className="WPSforfont" style={{ float: "left" }}>
                {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
              </span>
            </td>
            <th className="WPSright-th" style={{ width: "15%",borderLeft:"1px solid",borderBottom:"1px solid" ,borderTop:"1px solid",borderRight:"1px solid"}}>
              Qualified to
              <br></br>
              Company Name
            </th>

            <td style={{ width: "35%",borderTop:"1px solid",borderBottom:"1px solid", borderRight:"1px solid" }}>
              {pdf[0]?.WPS_QualifiedTo}
              <br></br>
              {pdf[0]?.WPSH_CompanyName}
            </td>
          </tr></>
        ):(<>
          <tr>
            <th style={{width: "13%", borderTop:"1px solid",borderBottom:"1px solid",borderRight:"1px solid",borderLeft:"1px solid"}}>
              WPS record number
              <br></br>
              Date
            </th>
            <td style={{ width: "25%" ,borderTop:"1px solid",borderBottom:"1px solid" }}>
              <span className="WPSforfont" style={{ float: "left"  }}>
                {pdf[0]?.WPSH_WPS_No}
              </span>
              <span className="WPSforfont" style={{ float: "right"  }}>
                Revision: {pdf[0]?.WPSH_WPS_Revi_No}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <br />
              <span className="WPSforfont" style={{ float: "left" }}>
                {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
              </span>
            </td>
            <th className="WPSright-th" style={{ width: "15%",borderLeft:"1px solid",borderBottom:"1px solid" ,borderTop:"1px solid",borderRight:"1px solid"}}>
              Qualified to
              <br></br>
              Company Name
            </th>

            <td style={{ width: "35%",borderTop:"1px solid",borderBottom:"1px solid", borderRight:"1px solid" }}>
              {pdf[0]?.WPS_QualifiedTo}
              <br></br>
              {pdf[0]?.WPSH_CompanyName}
            </td>
          </tr>
          <tr>
            <th scope="row" style={{borderLeft:"1px solid",borderBottom:"1px solid",borderRight:"1px solid"}}>Supporting PQR(s) </th>
            <td colspan="3" style={{borderRight:"1px solid",borderBottom:"1px solid"}}>{pdf[0]?.WPSH_SupportingPQR}</td>
          </tr>

          <tr>
            <th class="WPSforfont" scope="row" style={{borderRight:"1px solid",borderLeft:"1px solid",borderBottom:"1px solid"}}>
              Reference Docs
            </th>
            <td colspan="3" style={{borderRight:"1px solid",borderBottom:"1px solid"}}>{pdf[0]?.WPSH_RefDoc}</td>
          </tr>
          {pdf[0]?.wld_WPSType !== "Tubesheet" ? (
          <><tr>
              <th class="WPSforfont" scope="row" style={{ borderRight: "1px solid", borderLeft: "1px solid", borderBottom: "1px solid" }}>
                Scope
              </th>
              <td colspan="3" style={{ borderRight: "1px solid", borderBottom: "1px solid" }}>{pdf[0]?.WPSH_Scope}</td>
            </tr><tr>
                <th class="WPSforfont" scope="row" style={{ borderRight: "1px solid", borderLeft: "1px solid", borderBottom: "1px solid" }}>
                  Joint Description
                </th>
                <td colspan="3" style={{ borderRight: "1px solid", borderBottom: "1px solid" }}>{pdf[0]?.WPSH_JointDesc}</td>
              </tr></>
           ) : (
              ""
            )}</>)}
         
        </tbody>
      </table>
    </header>
  );
};
export default Groove_pdf_header;
