import { Row, Col, Container, Table, Form } from "react-bootstrap";
import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import Error from "../../icons/ErrorIcon.svg";
import Tooltip from "../utility/Tooltip";
import { useLocation } from "react-router-dom";
import Img from "../../icons/ErrorIcon.svg";
import {
  AddNewDetail,
  getdetailsById,
  Updatedetails,
} from "../../Services/wpq_details-service";
import {
  isAlphanumericAllSpecialCharacters,
  numberMax10Digits,
} from "../../functions/validations";
import { WPQHeader, WPSHeader } from "./WPSObjects/WPQHeader";
import {
  WPS_Groove_Detail,
  Weld_Progression,
  Tungsten_size,
  Filler_Metal_Product_Form,
  Layer_No,
  Position_of_groove,
  Tungsten_type,
  WPS_Overlay_Detail,
  TypeData,
  Status,
  Action,
  MessageTypes,
  Backing,
  Arc_Transfer,
  Consumable_Insert,
  FM_Product_Form,
  Current_Polarity,
  Backing_Gas,
  Direct_Visual,
  Single_Pass,
  checkbox,
  checkboxComponent,
  checkboxComponent2,
  errorprocess,
  WPQ_FM_Welding_Position,
  Welding_Position,
} from "./WPSObjects/WPQ_entity";
// import { AddLogs } from "../../Services/wps-logs-services";
import {
  WPQ_process_CheckBox,
  WPQ_process_Input_1,
  WPQ_process_Input_1Actual,
  WPQ_process_Input_1_row_3,
  WPQ_process_Input_2,
  WPQ_process_input_and_checkbox,
  WPQ_process_select,
  WPQ_process_select_data,
  WPQ_process_textarea_1,
  WPS_Process_Grid_Input,
  WPS_Process_Grid_Input_2,
  WPS_Process_Grid_Input_3,
  WPS_Process_Grid_Select,
  WPS_Process_Grid_Select_3,
  WPS_Process_Grid_Select_master_data,
} from "./WPSObjects/WPS_Process_Grid";

import { toast } from "react-toastify";
import { getActiveSfa } from "../../Services/sfa-service";
import { SearchSelect } from "../utility/search-select";
import { getActiveItem } from "../../Services/item-services";
import { getActiveFno } from "../../Services/fno-services";
import activeimg from "../../icons/activetick.svg";
import { emptyError, emptyErrorWPQDetail } from "./WPSObjects/WPS_common";

const WPQGetDetailProcess = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      wpq_process,
      logObj,
      setlogObj,
      setKey,
      tabno,
      onInput,
      error,
      setError,
      isDisabled,
      isReadonly,
      setIsReadonly,
      daysRemainingList,
      setDaysRemainingList,
      remainingdays,
    },
    ref
  ) => {
    let temp = [];
    const [ItemActive, setItemActive] = useState([]);

    const [SfaActive, setSfaActive] = useState([]);

    const [FnoActive, setFnoActive] = useState([]);

    const [checkedbox, setcheckedbox] = useState(true);

    console.log("remainingdays0", remainingdays);

    console.log("wpq_process0", wpq_process);

    console.log("FnoActive", FnoActive);

    const [checkboxStates, setCheckboxStates] = useState(
      detailObj?.map(() => [])
    );
    console.log("dddeeee", detailObj);
    const [bluestyle, setblue] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "blue" : "blue",
      }),
    });

    const [errorstyle, seterrorstyle] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    });
    const location = useLocation();

    const customstyles = {
      control: (provided) => ({
        ...provided,
        width: "120px",
        position: "relative",
        left: "33px",
      }),
    };

    const customstyles2 = {
      control: (provided) => ({
        ...provided,
        width: "120px",
        position: "relative",
        left: "130px",
      }),
    };

    const [WPStype, setWPStype] = useState(location.state?.WPStype);
    const [action, setaction] = useState(location.state?.action);
    const [IsEdited, setIsEdited] = useState(false);

    const [futureDate, setFutureDate] = useState(null);
    const [pDate, setpDate] = useState(null);
    const [months, setMonths] = useState(0);
    const [days, setDays] = useState(0);

    var isoverlay = WPStype === "Groove" ? false : true;
    var isgroove = WPStype === "Groove" ? true : false;

    var groclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype === "Overlay" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);
    const [isGroove, setGroove] = useState(isgroove);

    const [grooveclass, setgrooveclass] = useState(groclass);

    const [overlayclass, setoverlayclass] = useState(overclass);

    const fetchSfa = async () => {
      const result = await getActiveSfa();
      setSfaActive(result);
    };

    const fetchFno = async () => {
      const result = await getActiveFno();
      setFnoActive(result);
    };

    useEffect(() => {
      fetchSfa();
      fetchFno();
    }, []);

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        return handleSubmit();
      },

      funcOnNextTemp: async () => {
        return handleSubmitTemp();
      },
    }));

    // const handleSubmitTemp = async () => {
    //   var result = false;

    //   var val = await validateTemp();

    //   if (headerObj?.WPSH_Status === Status.StatusApproved) {
    //     result = handleAdd(Status.StatusApproved);
    //   }
    //   if (headerObj?.WPSH_Status === Status.StatusPending) {
    //     result = handleAdd(Status.StatusPending);
    //   } else {
    //     result = await handleAdd(Status.StatusTempSaved);
    //   }

    //   if (action === Action.ActionEdit) {
    //     if (IsEdited) {
    //       console.log("dddqwwq", IsEdited);

    //       if (result) {
    //         console.log("fdsffdsfdddd", result);

    //         return MessageTypes.Temp;
    //       } else {
    //         return MessageTypes.Required;
    //       }
    //     }
    //     return MessageTypes.None;
    //   }
    //   if (result) {
    //     return MessageTypes.Temp;
    //   } else {
    //     return MessageTypes.Required;
    //   }
    //   return result;
    // };


    const handleSubmitTemp = async () => {
      debugger
      var result = false;
      var val = await validateTemp();
      console.log("valdd",val)

          if (val.isError === false){
          if ( val.temperror === false) {
            setIsEdited(false);
            if (headerObj?.WPSH_Status === Status.StatusApproved) {
              result = handleAdd(Status.StatusApproved);
            } else if (headerObj?.WPSH_Status === Status.StatusPending) {
              result = handleAdd(Status.StatusPending);
            } else {
              result = handleAdd(Status.StatusTempSaved);
            }

            if (result) {
              return MessageTypes.Temp;
            }
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;

        
        // return MessageTypes.None;

  }

    const handleSubmit = async () => {
      var result = false;

      if (!(await validateTemp(detaildata))) {
        result = await handleAdd("");
        if (result) {
          setKey(+tabno + 1);
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }
      return false;
    };

    const handleActualValueChange = (Index, key, id, valueIndex, newValue) => {
      const updatedProcessObj = [...detailObj];
      updatedProcessObj[Index][key][id].Actual_Value[valueIndex] = newValue;
      setdetailObj(updatedProcessObj);
    };

    const handleOtherChange = (Index, key, id, valueIndex, newValue) => {
      const updatedProcessObj = [...detailObj];
      updatedProcessObj[Index][key][id].Other[valueIndex] = newValue;
      setdetailObj(updatedProcessObj);
    };

    const handleRangeQualifiedChange = (
      Index,
      key,
      id,
      valueIndex,
      newValue
    ) => {
      const updatedProcessObj = [...detailObj];
      updatedProcessObj[Index][key][id].Range_Qualified[valueIndex] = newValue;
      setdetailObj(updatedProcessObj);
    };

    const handleRangeQualifiedChange2 = (
      Index,
      key,
      id,
      valueIndex,
      valueIndex2,
      checkedbox,
      newValue
    ) => {
      const updatedProcessObj = [...detailObj];
      updatedProcessObj[Index][key][id].Range_Qualified[
        valueIndex
      ].Range_Qualified1[valueIndex2] = newValue;
      setdetailObj(updatedProcessObj);
    };

    const handleRangeQualifiedChangeCheckBox = (
      Index,
      key,
      id,
      valueIndex,
      Value
    ) => {
      const updatedProcessObj = [...detailObj];

      let booleanIndex =
        updatedProcessObj[Index][key][id].Range_Qualified[valueIndex][1];

      let newValue = checkboxComponent(valueIndex, Value, booleanIndex);
      if (newValue) {
        newValue = newValue.Value;
        updatedProcessObj[Index][key][id].Range_Qualified[valueIndex][0] =
          newValue;

        if (newValue !== "") {
          updatedProcessObj[Index][key][id].Range_Qualified[valueIndex][1] =
            "true";
          updatedProcessObj[Index][key][id].Range_Qualified[valueIndex][2] =
            "Checked";
        } else if (newValue === "") {
          updatedProcessObj[Index][key][id].Range_Qualified[valueIndex][1] =
            "false";
          updatedProcessObj[Index][key][id].Range_Qualified[valueIndex][2] =
            "Unchecked";
        }

        setdetailObj(updatedProcessObj);
      }
    };

    const handleRangeQualifiedChangeCheckBox2 = (
      Index,
      key,
      id,
      inputvalue,
      valueIndex,
      valueIndex2,
      checkedbox,
      Value
    ) => {
      const updatedProcessObj = [...detailObj];

      let constant = "9.999.00";
      if (checkedbox === true) {
        // Value = constant;
        Value = "";
      }
      // else if (checkedbox === false) {
      //   Value = ""
      // }
      let booleanIndex =
        updatedProcessObj[Index][key][id].Range_Qualified[valueIndex]
          .Range_Qualified2[valueIndex2][1];

      updatedProcessObj[Index][key][id].Range_Qualified[
        inputvalue
      ].Range_Qualified1[valueIndex2] = Value;

      if (Value === "9999.00") {
        updatedProcessObj[Index][key][id].Range_Qualified[
          valueIndex
        ].Range_Qualified2[valueIndex2][1] = "true";
        updatedProcessObj[Index][key][id].Range_Qualified[
          valueIndex
        ].Range_Qualified2[valueIndex2][2] = "Checked";
      } else if (Value === "") {
        updatedProcessObj[Index][key][id].Range_Qualified[
          valueIndex
        ].Range_Qualified2[valueIndex2][1] = "false";
        updatedProcessObj[Index][key][id].Range_Qualified[
          valueIndex
        ].Range_Qualified2[valueIndex2][2] = "Unchecked";
      }

      setdetailObj(updatedProcessObj);
    };

    const detaObj = [...detailObj];

    var wps_detail = detailObj?.filter((item) => item.WPQ_Process !== "");

    if (wps_detail.length <= 0) {
      wps_detail = detailObj;
    }

    var detailempty = detailObj?.filter((item) => item.WPQ_Process === "");

    var detaildata = detailObj?.filter((item) => item.WPQ_Process !== "");

    var errortype =
      WPStype === "Groove" ? WPS_Groove_Detail : WPS_Overlay_Detail;



      const validateTemp = async () => {
        debugger
        let isError = false;
  console.log("erreee",error)
         let temperror = emptyErrorWPQDetail(error, setError, isError);
  
         console.log("teeeee",temperror)
        // let entries = Object.keys(error);

        // setError((prevState) => ({
        //   ...prevState,
        //   ...error,
        // }));
        console.log("issss",isError)
        return {isError ,temperror};
      };
    const handleAdd = async (savetype) => {
      let result = false;
      if (
        headerObj?.WPSH_Status === Status.StatusApproved ||
        headerObj?.WPSH_Status === Status.StatusRejected
      ) {
        headerObj.WPSH_Status = Status.StatusPending;
      }
      for (const item of detaildata) {
        console.log("oookkk");
        await AddNewDetail(item).then(async (r) => {
          result = true;
          if (
            action === Action.ActionEdit &&
            savetype !== Status.StatusTempSaved
          ) {
            // await AddLogs(logObj);
            result = true;
          }
        });
      }
      return result;
    };

    console.log("dayy", daysRemainingList);

    return (
      <Row className="font">
        <Row className="mb-4" style={{ padding: "0px 58px" }}>
          <div className="statusbg">
            <span className="statusborder float-start">
              Status &nbsp;
              <img
                style={{ width: "14px", height: "14px" }}
                src={activeimg}
              />{" "}
              {wpq_process &&
                wpq_process.map((item, Index) => {
                  console.log(
                    "remainingdays1111",
                    wpq_process,
                    item.remainingday
                  );
                  return (
                    <span style={{ color: "var(--godrej-green-alt)" }}>
                      {item?.remainingday}
                    </span>
                  );
                })}
            </span>
            <span className=" statusborder float-end">
              Last Weld Date &nbsp;&nbsp;&nbsp;
              <span className="WPQ-Green-text">
                {wpq_process &&
                  wpq_process.map((item, Index) => {
                    return item?.wld_createdon;
                  })}
              </span>{" "}
            </span>
          </div>
        </Row>
        <Row className="mb-4" style={{ padding: "20px 48px" }}>
          <Col>
            <Table
              bordered
              responsive="sm"
              className="text-center same-col-widths align-middle"
            >
              <thead>
                <tr className="border thead-color ">
                  <th
                    className="thickness_title tablethwidth"
                    style={{ width: "20%" }}
                  ></th>
                  <th
                    id="av"
                    className="thickness_title tablethwidth"
                    style={{ width: "10%" }}
                  >
                    Actual Value
                  </th>
                  <th
                    id="rq"
                    className="thickness_title tablethwidth "
                    style={{ width: "25%" }}
                  >
                    Range Qualified
                  </th>
                  <th className="thickness_title tablethwidth"></th>
                </tr>
              </thead>

              <thead>
                <tr className="align-middle">
                  <th className="text-start thead-color">Process</th>

                  <td>
                    {wpq_process &&
                      wpq_process.map((item, Index) => {
                        return item?.WPQ_Process;
                      })}
                  </td>

                  <td>
                    {wpq_process &&
                      wpq_process.map((item, Index) => {
                        return item?.WPQ_Process;
                      })}
                  </td>

                  <td>
                    {wpq_process &&
                      wpq_process.map((item, Index) => {
                        return item?.WPQ_Process_Category;
                      })}
                  </td>
                </tr>

                <tr className="align-middle">
                  <th className="text-start thead-color">Type</th>

                  <td>
                    {wpq_process &&
                      wpq_process.map((item, Index) => {
                        return item?.WPQ_Type;
                      })}
                  </td>

                  <td>
                    {wpq_process &&
                      wpq_process.map((item, Index) => {
                        return item?.WPQ_Type;
                      })}
                  </td>

                  <td></td>
                </tr>
              </thead>

              <tbody>
                <WPQ_process_select
                  wpq_process={wpq_process}
                  name="WPQ_Detail_Backing"
                  disabled={isDisabled}
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Backing"
                  array={Backing}
                  status={error.WPQ_Detail_Backing[0].Actual_Value[0].status}
                  message={error.WPQ_Detail_Backing[0].Actual_Value[0].message}
                  status1={
                    error.WPQ_Detail_Backing[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_Detail_Backing[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_select_data
                  wpq_process={wpq_process}
                  disabled={isDisabled}
                  customstyles={customstyles}
                  customstyles2={customstyles2}
                  name="WPQ_FM_Specification"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Filler Metal Specification (SFA NO.)"
                  SfaActive={SfaActive}
                  valueField="wld_code"
                  status={error.WPQ_FM_Specification[0].Actual_Value[0].status}
                  message={
                    error.WPQ_FM_Specification[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Specification[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Specification[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_Input_1
                  wpq_process={wpq_process}
                  name="WPQ_FM_Classification"
                  readOnly={isReadonly}
                  maxlength={7}
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Filler Metal Classification"
                  status={error.WPQ_FM_Classification[0].Actual_Value[0].status}
                  message={
                    error.WPQ_FM_Classification[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Classification[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Classification[1].Range_Qualified[0].message
                  }
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Classification: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [error] },
                      ],
                    }));
                  }}
                />

                <WPQ_process_select
                  wpq_process={wpq_process}
                  name="WPQ_FM_filler_Wire"
                  disabled={isDisabled}
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Filler Wire With/Without"
                  array={Backing}
                  status={error.WPQ_FM_filler_Wire[0].Actual_Value[0].status}
                  message={error.WPQ_FM_filler_Wire[0].Actual_Value[0].message}
                  status1={
                    error.WPQ_FM_filler_Wire[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_filler_Wire[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_Input_1_row_3
                  customstyles={customstyles}
                  wpq_process={wpq_process}
                  name="WPQ_FM_F_No"
                  disabled={isDisabled}
                  readOnly={isReadonly}
                  maxlength={7}
                  handleOtherChange={handleOtherChange}
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Filler Metal F-Number"
                  SfaActive={FnoActive}
                  valueField="wld_category"
                  status={error.WPQ_FM_F_No[0].Actual_Value[0].status}
                  message={error.WPQ_FM_F_No[0].Actual_Value[0].message}
                  status1={error.WPQ_FM_F_No[1].Range_Qualified[0].status}
                  message1={error.WPQ_FM_F_No[1].Range_Qualified[0].message}
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_F_No: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [error] },
                      ],
                    }));
                  }}
                />

                <WPQ_process_select
                  wpq_process={wpq_process}
                  disabled={isDisabled}
                  name="WPQ_FM_Arc_transfermode"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Arc Transfer Mode (FCAW/GMAW)"
                  array={Arc_Transfer}
                  status={
                    error.WPQ_FM_Arc_transfermode[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Arc_transfermode[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Arc_transfermode[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Arc_transfermode[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_select
                  wpq_process={wpq_process}
                  disabled={isDisabled}
                  name="WPQ_FM_Consumable_Insert"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Consumable Insert"
                  array={Consumable_Insert}
                  status={
                    error.WPQ_FM_Consumable_Insert[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Consumable_Insert[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Consumable_Insert[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Consumable_Insert[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_select
                  wpq_process={wpq_process}
                  name="WPQ_FM_Product_Form"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Filler Metal Product Form (GTAW)"
                  array={FM_Product_Form}
                  status={error.WPQ_FM_Product_Form[0].Actual_Value[0].status}
                  message={error.WPQ_FM_Product_Form[0].Actual_Value[0].message}
                  status1={
                    error.WPQ_FM_Product_Form[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Product_Form[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_Input_1
                  wpq_process={wpq_process}
                  readOnly={isReadonly}
                  maxlength={7}
                  name="WPQ_FM_No_of_LayerDeposit"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="No Of Layer Deposited"
                  status={
                    error.WPQ_FM_No_of_LayerDeposit[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_No_of_LayerDeposit[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_No_of_LayerDeposit[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_No_of_LayerDeposit[1].Range_Qualified[0]
                      .message
                  }
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_No_of_LayerDeposit: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [error] },
                      ],
                    }));
                  }}
                />

                <WPQ_process_input_and_checkbox
                  readOnly={isReadonly}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Weld_Deposit_Thickness"
                  error={error}
                  maxlength={10}
                  setError={setError}
                  checkedbox={checkedbox}
                  setcheckedbox={setcheckedbox}
                  disabled={isDisabled}
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange2={handleRangeQualifiedChange2}
                  handleRangeQualifiedChangeCheckBox2={
                    handleRangeQualifiedChangeCheckBox2
                  }
                  ColumnHeader="Weld Deposit Thickness (mm)"
                  status={
                    error.WPQ_FM_Weld_Deposit_Thickness[0].Actual_Value[0]
                      .status
                  }
                  message={
                    error.WPQ_FM_Weld_Deposit_Thickness[0].Actual_Value[0]
                      .message
                  }
                  // status1={
                  //   error.WPQ_FM_Weld_Deposit_Thickness[1].Range_Qualified[0]
                  //     .Range_Qualified1[0].status
                  // }
                  // message1={
                  //   error.WPQ_FM_Weld_Deposit_Thickness[1].Range_Qualified[0]
                  //     .Range_Qualified1[0].message
                  // }
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Weld_Deposit_Thickness: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [{ Range_Qualified1: [""] }] },
                      ],
                    }));
                  }}
                  onInput1={(e) => {
                    let error = numberMax10Digits(e.target.value);
                    // setError((prevState) => ({
                    //   ...prevState,
                    //   WPQ_FM_Weld_Deposit_Thickness: [
                    //     { Actual_Value: [""] },
                    //     { Range_Qualified: [{ Range_Qualified1: [error] }] },
                    //   ],
                    // }));
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Weld_Deposit_Thickness: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [error] },
                      ],
                    }));
                  }}
                />

                <WPQ_process_Input_1
                  readOnly={isReadonly}
                  wpq_process={wpq_process}
                  maxlength={7}
                  name="WPQ_FM_Thickness_Limit"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Thickness Limit (S.Cir.Arc)"
                  status={
                    error.WPQ_FM_Thickness_Limit[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Thickness_Limit[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Thickness_Limit[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Thickness_Limit[1].Range_Qualified[0].message
                  }
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Thickness_Limit: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [error] },
                      ],
                    }));
                  }}
                />

                <WPQ_process_Input_1Actual
                  readOnly={isReadonly}
                  wpq_process={wpq_process}
                  maxlength={10}
                  name="WPQ_FM_Welding_Position"
                  handleActualValueChange={handleActualValueChange}
                  // handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Welding Position (Actual Position Tested)"
                  status={
                    error.WPQ_FM_Welding_Position[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Welding_Position[0].Actual_Value[0].message
                  }
                  // status1={
                  //   error.WPQ_FM_Welding_Position[1].Range_Qualified[0].status
                  // }
                  // message1={
                  //   error.WPQ_FM_Welding_Position[1].Range_Qualified[0].message
                  // }
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Welding_Position: [{ Actual_Value: [error] }],
                    }));
                  }}
                />

                {/* <WPQ_process_select
                  wpq_process={wpq_process}
                  name="WPQ_FM_Welding_Position"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Welding Position (Actual Position Tested)"
                  array={Welding_Position}
                  status={error.WPQ_FM_Welding_Position[0].Actual_Value[0].status}
                  message={error.WPQ_FM_Welding_Position[0].Actual_Value[0].message}
                  status1={
                    error.WPQ_FM_Welding_Position[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Welding_Position[1].Range_Qualified[0].message
                  }
                /> */}
                {isgroove ? (
                  <>
                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Groove_610"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Groove-Plate & Pipe {">"} 610mm
                        </span>
                      }
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Groove_73to610"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Groove-Pipe 73 to 610mm
                        </span>
                      }
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Groove_73"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Groove-Pipe {"<"} 73mm
                        </span>
                      }
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Fillet_610"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Fillet-Plate & pipe{">"} 610mm
                        </span>
                      }
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Fillet_73to610"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader="Fillet-Pipe 73mm to 610mm"
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Fillet_73"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader="Fillet-Pipe < 73mm"
                    />
                  </>
                ) : (
                  <>
                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Overlay_610"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Overlay-Plate & Pipe {">"} 610mm
                        </span>
                      }
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Overlay_73to610"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Overlay-Pipe 73 to 610mm
                        </span>
                      }
                    />

                    <WPQ_process_CheckBox
                      wpq_process={wpq_process}
                      name="WPQ_Overlay_73"
                      disabled={isDisabled}
                      handleActualValueChange={handleActualValueChange}
                      handleRangeQualifiedChangeCheckBox={
                        handleRangeQualifiedChangeCheckBox
                      }
                      ColumnHeader={
                        <span
                          className="greenfont"
                          style={{ fontWeight: "700" }}
                        >
                          Overlay-Pipe {"<"} 73mm
                        </span>
                      }
                    />
                  </>
                )}

                <WPQ_process_select
                  disabled={isDisabled}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Progression"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Progression"
                  array={Weld_Progression}
                  status={error.WPQ_FM_Progression[0].Actual_Value[0].status}
                  message={error.WPQ_FM_Progression[0].Actual_Value[0].message}
                  status1={
                    error.WPQ_FM_Progression[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Progression[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_select
                  disabled={isDisabled}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Current_Polarity"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Current / Polarity"
                  array={Current_Polarity}
                  status={
                    error.WPQ_FM_Current_Polarity[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Current_Polarity[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Current_Polarity[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Current_Polarity[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_select
                  disabled={isDisabled}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Backing_Gas"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Backing Gas"
                  array={Backing_Gas}
                  status={error.WPQ_FM_Backing_Gas[0].Actual_Value[0].status}
                  message={error.WPQ_FM_Backing_Gas[0].Actual_Value[0].message}
                  status1={
                    error.WPQ_FM_Backing_Gas[1].Range_Qualified[0].status
                  }
                  message1={
                    error.WPQ_FM_Backing_Gas[1].Range_Qualified[0].message
                  }
                />

                <WPQ_process_select
                  disabled={isDisabled}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Direct_VisualControl"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Direct/Remote visual control (Machine)"
                  array={Direct_Visual}
                  status={
                    error.WPQ_FM_Direct_VisualControl[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Direct_VisualControl[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Direct_VisualControl[1].Range_Qualified[0]
                      .status
                  }
                  message1={
                    error.WPQ_FM_Direct_VisualControl[1].Range_Qualified[0]
                      .message
                  }
                />

                <WPQ_process_select
                  disabled={isDisabled}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Automatic_jointTracking"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Automatic joint tracking (Machine)"
                  array={Backing_Gas}
                  status={
                    error.WPQ_FM_Automatic_jointTracking[0].Actual_Value[0]
                      .status
                  }
                  message={
                    error.WPQ_FM_Automatic_jointTracking[0].Actual_Value[0]
                      .message
                  }
                  status1={
                    error.WPQ_FM_Automatic_jointTracking[1].Range_Qualified[0]
                      .status
                  }
                  message1={
                    error.WPQ_FM_Automatic_jointTracking[1].Range_Qualified[0]
                      .message
                  }
                />

                <WPQ_process_select
                  disabled={isDisabled}
                  wpq_process={wpq_process}
                  name="WPQ_FM_Single_Pass_PerSide"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Single Pass/Multi pass per side"
                  array={Single_Pass}
                  status={
                    error.WPQ_FM_Single_Pass_PerSide[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Single_Pass_PerSide[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Single_Pass_PerSide[1].Range_Qualified[0]
                      .status
                  }
                  message1={
                    error.WPQ_FM_Single_Pass_PerSide[1].Range_Qualified[0]
                      .message
                  }
                />

                <WPQ_process_Input_1
                  readOnly={isReadonly}
                  wpq_process={wpq_process}
                  maxlength={7}
                  name="WPQ_FM_Automatic_AVC_System"
                  handleActualValueChange={handleActualValueChange}
                  handleRangeQualifiedChange={handleRangeQualifiedChange}
                  ColumnHeader="Automatic AVC System (GTAW)"
                  status={
                    error.WPQ_FM_Automatic_AVC_System[0].Actual_Value[0].status
                  }
                  message={
                    error.WPQ_FM_Automatic_AVC_System[0].Actual_Value[0].message
                  }
                  status1={
                    error.WPQ_FM_Automatic_AVC_System[1].Range_Qualified[0]
                      .status
                  }
                  message1={
                    error.WPQ_FM_Automatic_AVC_System[1].Range_Qualified[0]
                      .message
                  }
                  onInput={(e) => {
                    let error =
                      isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Automatic_AVC_System: [
                        { Actual_Value: [error] },
                        { Range_Qualified: [error] },
                      ],
                    }));
                  }}
                />
              </tbody>
            </Table>
          </Col>
        </Row>
      </Row>
    );
  }
);

export default WPQGetDetailProcess;
