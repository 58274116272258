// import GridMaster from "./GridMaster";
// import { useDispatch } from "react-redux";
// import { roleActions } from "../store/role-slice";
// import { useState } from "react";

export function getInitials(userName) {
  if (userName) {
    let names = userName.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}

export function getFirstAlphabet(userName) {
  if (userName) {
    let names = userName.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    return initials;
  }
}

export function formatDateInDDMMYYYY(dateStr) {
  if (dateStr !== "") {
    let newDate = "";
    let separator = "/";
    let str = "";
    let time = "";
    if (dateStr.indexOf(" ") !== -1) {
      [str] = dateStr.split(" ");
      time = dateStr.split(" ")[1];
    } else {
      str = dateStr;
    }
    if (str !== "") {
      let [year, month, day] = str.split("-");
      newDate = `${day}${separator}${month}${separator}${year} ${time}`;
    }
    return newDate;
  } else {
    return "";
  }
}

const contains = (target, lookingFor) => {
  return target && target.indexOf(lookingFor) >= 0;
};

export function myTextMatchFunc(value, filterText, aliases) {
  let filterTextLowerCase = filterText ? filterText.toLowerCase() : "";
  let valueLowerCase = value.toString().toLowerCase();
  let literalMatch = contains(valueLowerCase, filterTextLowerCase);
  return (
    !!literalMatch || !!contains(valueLowerCase, aliases[filterTextLowerCase])
  );
}

export const resetAllFilter = (gridRef, props) => {
  
  gridRef.current.api.setFilterModel(null);
  props.getDataEvent([]);

  // alert(gridRef.current.api.getFilterModel())
};

export function resetFilter(gridRef, item, feildName) {
  
  let item1 = item.substring(0, item.indexOf(":") - 1);
  let col = gridRef.current.api.getFilterModel();
  for (const key of Object.keys(col)) {
    if (key === feildName(item1)) {
      delete col[key];
    }
  }
  gridRef.current.api.setFilterModel(col);
}

export function defaultColParas() {
  return {
    flex: 1,
    lockPinned: true,
    sortable: true,
    resizable: true,
    textAlign: "center",
    suppressMovable: true,
    //width: 100,
    minWidth: 150,
    // maxWidth: 180,
    enableCellChangeFlash: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
    filter: "agTextColumnFilter",
    stopEditingWhenCellsLoseFocus: true
  };
}

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

export function formatDateDD_MM_YYYY(input) {
  let input1 = new Date(input);
  const newDate = `${pad2(input1.getDate())}-${pad2(
    input1.getMonth() + 1
  )}-${pad2(input1.getFullYear())}`;
  console.log("dates", input, newDate);

  return newDate;
}

export const FilterChange = (
  param,
  setFilteredCol,
  headerName,
  setRowData,
  setExcelData
) => {
  
  //let a = document.getElementById("filterDisplay");
  ///alert("Ddd")
  //console.log("new filter on reset")
  setFilteredCol([]);
  setRowData([]);
  let col = param.api.getFilterModel();
  console.log("col", col);

  if (param.api.getDisplayedRowCount() === 0) {
    param.api.showNoRowsOverlay();
  } else {
    param.api.hideOverlay();
  }
  if (Object.keys(col).length === 0) {
    //   a.classList.add("d-none");
    console.log("hi");
    let temp = [];
    param.api.forEachNodeAfterFilter((node) => {
      temp.push(node.data);
    });

    setRowData(temp);
    setExcelData(temp);
    console.log("new filter on reset", temp);
  } else {
    // a.classList.remove("d-none");
    setFilteredCol([]);
    setRowData([]);
    for (const key of Object.keys(col)) {
      let filter;
      if (col[key].filterType === "date") {
        if (col[key].condition1) {
          let fromDate1 = col[key].condition1.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
            : col[key].condition1.dateFrom;
          let toDate1 = col[key].condition1.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
            : col[key].condition1.dateTo;
          let fromDate2 = col[key].condition2.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
            : col[key].condition2.dateFrom;
          let toDate2 = col[key].condition2.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
            : col[key].condition2.dateTo;
          filter =
            headerName(key) +
            " : " +
            fromDate1 +
            (toDate1 ? " To " + toDate1 : "") +
            ", " +
            fromDate2 +
            (toDate2 ? " To " + toDate2 : "");
          console.log("true date");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].dateFrom?.substring(0, 10) +
            (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
          console.log("false date", col[key].dateFrom?.substring(0, 10));
        }
      } else if (col[key].filterType === "number") {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            (col[key].condition1.filterTo
              ? " To " + col[key].condition1.filterTo
              : "") +
            ", " +
            col[key].condition2.filter +
            (col[key].condition2.filterTo
              ? " To " + col[key].condition2.filterTo
              : "");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].filter +
            (col[key].filterTo ? " To " + col[key].filterTo : "");
        }
      } else {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
      }
      console.log("SF ", filter);
      

      let temp = [];
      param.api.forEachNodeAfterFilter((node) => {
        temp.push(node.data);
      });

      setRowData(temp);
      setExcelData(temp);
      console.log("new filter", temp);
      setFilteredCol((prev) => {
        return [...prev, filter];
      });
    }
  }
};

export function getData(param) {
  // let col = param.api.getRenderedNodes();
  let col = param.getRenderedNodes();
  //console.log("col", param.api.getModel().rootNode.childrenAfterFilter);
  let filteredData = [];
  for (let dt of col) {
    filteredData.push(dt.data);
  }
  console.log("filter", filteredData);
  return filteredData;
}
