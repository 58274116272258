import React, { useState } from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";
import Copy_gray from "../../icons/Copy_gray.svg"
import Copy_blue from "../../icons/Copy_blue.svg"
import { getDefaultDate } from "../../functions/welding-functions";

export default (props) => {
  let status=false;
  let toDate = new Date(props.selectedData.data.wld_toDate.replace(/-/g, '/'));
  let todayDate = new Date(getDefaultDate().replace(/-/g, '/'));
  if(todayDate > toDate) {status=true}
  return (

    <div className="cell-action">
      <img
        src={Copy_gray}
        onMouseOver={e => (e.currentTarget.src = Copy_blue)}
        onMouseOut={e => (e.currentTarget.src = Copy_gray)}
        alt="copy"
        className="p-1"
        style={{position:"relative",bottom:"8px"}}

        onClick={() => props.handleCopyEvent(props.selectedData)}
      />
      {!status?(<><img
        onMouseOver={e => (e.currentTarget.src = Edit)}
        onMouseOut={e => (e.currentTarget.src = Edit_gray)}
        src={Edit_gray}
        style={{position:"relative",bottom:"8px"}}

        alt="edit"
        onClick={() => props.handleEditEvent(props.selectedData)}
      />
      <img
        src={Delete_gray}
        onMouseOver={e => (e.currentTarget.src = Delete)}
        onMouseOut={e => (e.currentTarget.src = Delete_gray)}
        alt="delete"
        className="p-1"
        style={{position:"relative",bottom:"8px"}}

        onClick={() => props.handleDeleteEvent(props.selectedData)}
      /></>):(<></>)}

    </div>
  )

};
