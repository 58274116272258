/*
This Module Add Qualifcation into the system
1. Qualification is added in "in progress state"
2. All users of QCE role can aprove or reject
3. Once Rejected it goes back to initiator
4. Initiator camn again submit

Important status

1. Inprogress
2. Approved
3. Deleted - Only initiator can Delete Inprogress Requistion
4. Rejected


2 status wld_pendingstatus and wld_status are used
1 Inprogress - wld_pendingstatus - Inprogress, wld_status - Final (No approval required), wld_pendingwith - QCE
2. Rejecetd - wld_pendingstatus - rejected wld_status - Rejected, wld_pendingwith - <initiator>
3. Deleted - wld_pendingstatus - Deleted wld_status - final, wld_pendingwith - QCE
4. Approved - wld_pendingstatus - Approved wld_status - final, , wld_pendingwith - initiator

All QCE Role user email ids are saved to DB for emailing.
*/

import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import {

  isAlphanumericWithSpace,
  isAlphanumericampspacedotdashbrackets,
  isAlphanumericWithDashDotSlashSpaceBrackets,
  isAlphanumericWithDotSpaceDashCommaBracketsAmpersand,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {

  AddQualificationRequest,
  getQualReqByType,

  AddFinalApprover,
} from "../../Services/trainingRequisition-service";
import { getAllUsersByRole } from "../../Services/user-service";
import {
  RenderIf,
  formatDateDDMMYYYY,
  getDefaultDate,
  weldReqFormat,
} from "../../functions/welding-functions";

import { BiSelectMultiple } from "react-icons/bi";
import ClosureRemarksQual from "../utility/CommonComponents/ClosureRemarksQual";
import SingleValuePopUp from "../utility/SingleValuePopUp";

import { Date_Input } from "../utility/CommonComponents/Date_Component";
import { todayDate, maxDate } from "../utility/CommonComponents/TodayDate";

//Add and Edit Qualification Requisition
const AddEditQualificationRequisition = (props) => {
 
  const TrainingReqEmployeeType = {
    NewWelder: "New Welder",
    ExistingWelder: "Existing Welder",
  };
  const todayDate = getDefaultDate();
  const todayDate1 = todayDate;


  let action = props.action;
  let formName = "Welder Qualification Requisition";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [subjectArr, setSubjectArr] = useState([]);
  const [Flg, setFlg] = useState(false);
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });

  let userId = sessionStorage.getItem("User");
  let username = sessionStorage.getItem("UserName");
  let department = "PED";


  const getTraningReq = async (SK) => {
    let tmpObj = { fieldValue: SK, flag: "trainreq" };
    const Records = await getQualReqByType(tmpObj);
    if (Records != undefined) {
      setTrainReqObj(Records[0]);
      return Records[0];
    }

  };

  const [trainReqObj, setTrainReqObj] = useState([]);

  useEffect(() => {
    if (action == "Edit") {
      Promise.resolve(getTraningReq(props.rowData.SK));
    } else {
      setTrainReqObj({
        wld_TrainingReqEmployeeType: "",
        wld_TrainingReqSalaryEmployeeCode: "",
        wld_WpqNo:"",
        wld_TrainingReqEmployeeName: "",
        wld_TrainingReqCompanyName: "Godrej & Boyce Mfg. Ltd.",
        wld_TrainingReqExperience: "",
        wld_TrainingReqSubject: "",
        wld_TrainingReqSubjectCode: "",
        wld_TrainingReqDate: formatDateDDMMYYYY(todayDate),
        wld_TrainingReqRequesterName: username,
        wld_TrainingReqRequesterEmailId: userId,
        wld_TrainingReqRequesterDepartment: department,
        wld_TrainingReqNumber: "",
        wld_createdon: "",
        wld_createdby: username,
        wld_TrainingReqApprover1: "",
        wld_TrainingReqApprover1EmailId: "",
        wld_TrainingReqApprover2: "",
        wld_TrainingReqApprover2EmailId: "",
        wld_TrainingReqApproverFinal: "",
        wld_TrainingReqApproverFinalEmailId: "",
        wld_TrainingReqPendingWith: "",
        wld_TrainingReqApprover1Comment: "",
        wld_TrainingReqApprover1Status: "",
        wld_TrainingReqApprover2Comment: "",
        wld_TrainingReqApprover2Status: "",
        wld_TrainingReqApproverFinalComment: "",
        wld_TrainingReqApproverFinalStatus: "",
        wld_ProcessQualified: "",
        
        wld_PositionQualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
        wld_BaseMaterial: "",
        wld_SupervisorName: username,
        wld_SupervisorDept: department,
        wld_WeldingDate: todayDate1,
        wld_VisualExam: "",
        wld_NDEExam: "",
        wld_VisualExamDate: todayDate1,
        wld_NDEExamDate: todayDate1,
        wld_pendingSince: "",
        wld_CycleTime: "",
        wld_NewWelder:"",
        wld_ExistingWelder:"",
        wld_JointCategory:"",
        wld_AssgnDept:"",
        wld_Tpi:"",
        wld_RTOfferWrench:"",
        wld_RTOfferWrenchDate:todayDate1,
        wld_PositionQualified1:"",
          });
    }

  }, []);

  useEffect(() => {
    console.log("TrainingOb", trainReqObj);
    if (action == "Edit" && trainReqObj.length != 0) {
      setFlg(true);
    }
  }, [trainReqObj]);

  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_TrainingReqEmployeeType: {},
    wld_TrainingReqSalaryEmployeeCode: {},
    wld_WpqNo:{},
    wld_TrainingReqEmployeeName: {},
    wld_TrainingReqRequesterDepartment: {},
    wld_TrainingReqSubject: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_TrainingReqCompanyName: {},
    wld_TrainingReqExperience: {},
    wld_TrainingReqDate: {},
    wld_TrainingReqRequesterName: {},
    wld_ProcessQualified: {},
    wld_PositionQualified: {},
    wld_BaseMaterial: {},
    wld_SupervisorName: {},
    wld_SupervisorDept: {},
    wld_WeldingDate: {},
    wld_VisualExam: {},
    wld_NDEExam: {},
    wld_VisualExamDate: {},
    wld_NDEExamDate: {},
    wld_NewWelder:{},
    wld_ExistingWelder:{},
    wld_JointCategory:{},
    wld_AssgnDept:{},
    wld_Tpi:{},
    wld_RTOfferWrench:{},
    wld_RTOfferWrenchDate:{},
  });
 

  const callReload = () => {
    props.onHide();
    props.setMainReload(true);
  };
  const contractsupervisor = (e) => {
    setTrainReqObj((prevState) => ({
      ...prevState,

      ["wld_TrainingReqApprover1"]: e.label,
      ["wld_TrainingReqApprover1EmailId"]: e.value,
      ["wld_TrainingReqPendingWith"]: e.value,
    }));
  };
  const handleCloseSuccess = () => {
    
    setShowSuccessModel(false);
    props.onHide();
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };

  const handleRadioChange = (e) => {
    console.log(e.target.value)

    if (e.target.value === TrainingReqEmployeeType.ExistingWelder || e.target.value === TrainingReqEmployeeType.NewWelder) {
      setError((prevState) => ({
        ...prevState,
        wld_TrainingReqEmployeeType: {
          status: false,
          message: "",
        }
      }));
      setTrainReqObj(prevState => ({
        ...prevState,
        wld_PositionQualified: prevState.wld_PositionQualified.map(item => ["", "false", "Unchecked", item[3]])
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrainReqObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "wld_TrainingReqEmployeeType") {
      let compName = "";
      if (value === TrainingReqEmployeeType.ExistingWelder) {
        compName = "Godrej & Boyce Mfg. Ltd.";

       

        setTrainReqObj((prevState) => ({
          ...prevState,
          ["wld_TrainingReqSalaryEmployeeCode"]: "",
          ["wld_TrainingReqEmployeeName"]: "",
          ["wld_TrainingReqApprover1"]: "",
          ["wld_TrainingReqApprover1EmailId"]: "",
          ["wld_TrainingReqPendingWith"]: "",
          ["wld_BaseMaterial"]: "",
          ["wld_TrainingReqExperience"]: "",
          ["wld_PositionQualified"[0][0]]: "",
          ["wld_PositionQualified"[1][0]]: "",

        }));
      } else {
       

        setTrainReqObj((prevState) => ({
          ...prevState,
          ["wld_TrainingReqSalaryEmployeeCode"]: "NA",
          ["wld_TrainingReqEmployeeName"]: "",
          ["wld_TrainingReqApprover1"]: "",
          ["wld_TrainingReqApprover1EmailId"]: "",
          ["wld_TrainingReqPendingWith"]: "",
          ["wld_BaseMaterial"]: "",
          ["wld_TrainingReqExperience"]: "",
          ["wld_PositionQualified"[0][0]]: "",
          
        }));
      }
      setTrainReqObj((prevState) => ({
        ...prevState,
        wld_TrainingReqCompanyName: compName,
      }));
    }
  };


  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = (data) => {
    console.log(data);

    setTrainReqObj((prevState) => ({
      ...prevState,
      ["wld_TrainingReqSalaryEmployeeCode"]: data.result.wld_code == undefined || data.result.wld_code == "" ?  data.result.SK : data.result.wld_code ,
      ["wld_TrainingReqEmployeeName"]: data.result.wld_name,
      ["wld_TrainingReqEmployeeEmailID"]: data.result.wld_email,
    }));
  };

  //Reset the data
  const resetForm = () => {
    
    if (action === "Add") {
      setTrainReqObj((prevState) => ({
        ...prevState,
        wld_TrainingReqEmployeeType: "",
        wld_TrainingReqSalaryEmployeeCode: "",
        wld_TrainingReqEmployeeName: "",
        SK: "",
        PK: "",
        wld_TrainingReqCompanyName: "Godrej & Boyce Mfg. Ltd.",
        wld_TrainingReqExperience: "",
        wld_TrainingReqSubject: "",
        wld_TrainingReqSubjectCode: "",
        wld_TrainingReqDate: formatDateDDMMYYYY(todayDate),
        wld_TrainingReqRequesterName: username,
        wld_TrainingReqRequesterEmailId: userId,
        wld_TrainingReqRequesterDepartment: department,
        wld_TrainingReqNumber: "",
        wld_TrainingReqApprover1: "",
        wld_TrainingReqApprover1Status: "",
        wld_createdon: "",
        wld_createdby: "",
        wld_SupervisorName: "",
        wld_SupervisorDept: "",
        wld_ProcessQualified: "",
        wld_PositionQualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
        wld_BaseMaterial: "",
        wld_PositionQualified1:""
      }));
      setSubjectArr([]);
    }

    setError((prevState) => ({
      ...prevState,
      SK: {},
      PK: {},
      wld_TrainingReqEmployeeType: {},
      wld_TrainingReqSalaryEmployeeCode: {},
      wld_TrainingReqEmployeeName: {},
      wld_TrainingReqRequesterDepartment: {},
      wld_TrainingReqSubject: {},
      wld_createdby: {},
      wld_createdon: {},
      wld_TrainingReqCompanyName: {},
      wld_TrainingReqExperience: {},
      wld_TrainingReqDate: {},
      wld_TrainingReqRequesterName: {},
    }));
  };

  const validateTrainingRequest = (action) => {
    let isError = false;
    var regExp = /^[-A-Za-z0-9\s., &()]*$/;
    var regExpBM = /^[-A-Za-z0-9\s.,()&]*$/;
    var regExpEmpname = /^[A-Za-z0-9\s]*$/;
    var regVisual = /^[-A-Za-z0-9\s.()/]*$/;


    
    var quaPosi = trainReqObj.wld_PositionQualified;

for (var i = 0; i < quaPosi.length; i++) {
  if (quaPosi[i][0] !== "") {
    isError = false;
    break;
  }
  else{
    isError = true;
  }
}

error.wld_PositionQualified={
        status: true,
        message: true ? " Atleast one checkbox to be selected" : "",
      };

    if (trainReqObj.wld_TrainingReqEmployeeType === "") {
      isError = true;
      error.wld_TrainingReqEmployeeType = {
        status: true,
        message: "Employee Type is required.",
      };
      
    }
    if (!trainReqObj.wld_TrainingReqExperience.match(regExp))
     {
      isError=true;
      error.wld_TrainingReqExperience={
        status: true,
        message: "Special Characters not allowed",
      };
    }
    if (!trainReqObj.wld_TrainingReqCompanyName.match(regExp))
     {
      isError=true;
      error.wld_TrainingReqCompanyName={
        status: true,
        message: "Special Characters not allowed",
      };
    }
    if (!trainReqObj.wld_VisualExam.match(regVisual))
     {
      isError=true;
      error.wld_VisualExam={
        status: true,
        message: "Special Characters not allowed",
      };
    }
    if (!trainReqObj.wld_NDEExam.match(regVisual))
     {
      isError=true;
      error.wld_NDEExam={
        status: true,
        message: "Special Characters not allowed",
      };
    }
    if (!trainReqObj.wld_ProcessQualified.match(regExp))
     {
      isError=true;
      error.wld_ProcessQualified={
        status: true,
        message: "Special Characters not allowed",
      };
    }
    if (!trainReqObj.wld_TrainingReqEmployeeName.match(regExpEmpname))
     {
      isError=true;
      error.wld_TrainingReqEmployeeName={
        status: true,
        message: "Special Characters not allowed",
      };
    }
   
    if (!trainReqObj.wld_BaseMaterial.match(regExpBM))
     {
      isError=true;
      error.wld_BaseMaterial={
        status: true,
        message: "Special Characters not allowed",
      };
    }


    if (
      trainReqObj.wld_TrainingReqSalaryEmployeeCode.trim() === "" &&
      trainReqObj.wld_TrainingReqEmployeeType === "Company Employee"
    ) {
      trainReqObj.wld_TrainingReqSalaryEmployeeCode = "";
      isError = true;
      error.wld_TrainingReqSalaryEmployeeCode = {
        status: true,
        message: "Employee Code is required.",
      };
    }

    if (
      trainReqObj.wld_TrainingReqEmployeeName.trim() === "" ||
      trainReqObj.wld_TrainingReqEmployeeName === ""
    ) {
      trainReqObj.wld_TrainingReqEmployeeName = "";
      isError = true;
      error.wld_TrainingReqEmployeeName = {
        status: true,
        message: "Employee Name is required.",
      };
    }

    if (
      trainReqObj.wld_TrainingReqCompanyName.trim() === "" ||
      trainReqObj.wld_TrainingReqCompanyName === ""
    ) {
      trainReqObj.wld_TrainingReqCompanyName = "";
      isError = true;
      error.wld_TrainingReqCompanyName = {
        status: true,
        message: "Company Name is required.",
      };
    }
    if (trainReqObj.wld_TrainingReqExperience === "") {
      isError = true;
      error.wld_TrainingReqExperience = {
        status: true,
        message: "Experience is required.",
      };
    }

    if (trainReqObj.wld_ProcessQualified === "") {
      trainReqObj.wld_ProcessQualified = "";
      isError = true;
      error.wld_ProcessQualified = {
        status: true,
        message: "Process Qualified is required.",
      };
    }

    if (trainReqObj.wld_BaseMaterial === "") {
      trainReqObj.wld_BaseMaterial = "";
      isError = true;
      error.wld_BaseMaterial = {
        status: true,
        message: "Base Material is required.",
      };
    }

    if (trainReqObj.wld_SupervisorDept.trim() === "") {
      trainReqObj.wld_SupervisorDept = "";
      isError = true;
      error.wld_SupervisorDept = {
        status: true,
        message: "Godrej Supervisor Department is required.",
      };
    }

    if (trainReqObj.wld_SupervisorName.trim() === "") {
      trainReqObj.wld_SupervisorName = "";
      isError = true;
      error.wld_SupervisorName = {
        status: true,
        message: "Godrej Supervisor Name is required.",
      };
    }


    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    return isError;
  };

  console.log("errormsg",error);
  const validateApproverRequest = (action) => {
    let isError = false;

    if (trainReqObj.wld_VisualExam === "") {
      isError = true;
      error.wld_VisualExam = {
        status: true,
        message: "Visual Examination is required.",
      };
    }

    if (trainReqObj.wld_NDEExam === "") {
      isError = true;
      error.wld_NDEExam = {
        status: true,
        message: "NDE Examination is required.",
      };
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    return isError;
  };




  const handleAdd = async(e) => {
    
    let result;
    let weldposi =  trainReqObj?.wld_PositionQualified;
    if (action === "Add") {

      /*
      Add all the QCE email id from ROLE master to 
      Training table
      This is for sending mails to all QCE for approval
      */
     let QCEusers =  await  getAllUsersByRole([{role: process.env.REACT_APP_ROLE_QCENGINEER}])
     let qceEmailid =""
     QCEusers.map((i) => qceEmailid += i.SK + ",")
    let reqbody =  {PK:"QualificationRequisitionFinalApprover",SK:"Final",FinalEmailIds:qceEmailid};
     let finalRole = await AddFinalApprover(reqbody)
     console.log("atddsda",weldposi)
     // Extract the first elements and filter out empty values
     const firstElements = weldposi && weldposi?.map(item => item[0]).filter(value => value !== "");
     console.log("fds",firstElements)
     // Join the elements with a comma
     let position = firstElements?.join(',');
     console.log("sdfdsfeefsdsa",position)

      trainReqObj.wld_status = "In Progress";
      trainReqObj.wld_pendingStatus = "Final";
      trainReqObj.wld_TrainingReqNumber = weldReqFormat(todayDate);
      trainReqObj.PK = "QualificationRequisition";
      trainReqObj.SK = userId + "#" + Math.floor(Date.now() / 1000) + "#qual";
      trainReqObj.wld_createdby = username;
      trainReqObj.wld_createdon = new Date().toLocaleDateString("en-GB");
      trainReqObj.wld_ApprovedRejectedQualDate = ""
      trainReqObj.wld_TrainingReqPendingWith = process.env.REACT_APP_ROLE_QCENGINEER
      trainReqObj.wld_PositionQualified1 = position
      // trainReqObj.wld_CycleTime = String(wld_CycleTime)
      // trainReqObj.wld_pendingSince = String(wld_pendingSince)
      result = AddQualificationRequest(trainReqObj);
     
    }
    result
      .then((resp) => {
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (resp.message === "Success") {
          toast.success(
            `Welder Qualification Request has been Initiated successfully`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
        }

        props.setMainReload(!props.mainReload);

        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
      });
  };

  function funcclose(closebool, param2) {
    if (closebool == true && param2 === "Success") {
      setShowSuccessModel(true);
      props.close(true);
    }

    if (closebool == true && param2 === "Cancel") {
      props.onHide();
    }
  }

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    var val = await validateTrainingRequest();
    if (!val) {
      setshowConfirmModel(true);
    }
  };


  const Mandtoryheading = () =>{
    return(
      <>
      <span style={{color:"red",fontSize:"12px"}}>(All input fields are mandatory)</span>
      </>
    )
  }


  const handleCheckboxChange = (position) => {
    setTrainReqObj(prevState => ({
      ...prevState,
      wld_PositionQualified: prevState.wld_PositionQualified.map(item => {
        if (item[3] === position) {
          const newValue = item[2] === "Unchecked" ? [position, "false", "Checked", position] : ["", "false", "Unchecked", position];
          return newValue;
        }
        return item;
      })
    }));
  };
  console.log("trainReqObjjjjjj",trainReqObj)
  return (
    <>
      <SuccessAlertModal
        msg={`Closure Remarks Updated successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />
      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {action === "Add"
              ? (<>Add Qualification Request <Mandtoryheading/> </> )
              : "Welder Qualification Request Details"}
          </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row>
                <Col md={4}>
                <Form.Group>
                  <div className="m-input" style={{ width: 300 }}>
                    <span >Employee Type</span>
                    <div className="d-flex mb-2">
                      <div >
                        <input
                          type="radio"
                          
                          name="wld_TrainingReqEmployeeType"
                          value={TrainingReqEmployeeType.ExistingWelder}
                          disabled={action !== "Add"}

                         checked={
                           trainReqObj.wld_TrainingReqEmployeeType ===
                           TrainingReqEmployeeType.ExistingWelder
                         }
                          onChange={(e) => {
                            handleRadioChange(e);
                            handleChange(e);
                          }}            
                           style={{
                            fontSize: 10,
                            border: 20,
                            height: 10,
                          }}
                        />
                        <span
                          className={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.ExistingWelder
                              ? "fontWeight "
                              : ""
                          }
                          style={{ fontSize: 12 }}
                        >
                          {TrainingReqEmployeeType.ExistingWelder}
                        </span>
                      </div>
                      <div className="ms-3">
                        <input
                          type="radio"
                          name="wld_TrainingReqEmployeeType"
                          value={TrainingReqEmployeeType.NewWelder}
                          disabled={action !== "Add"}
                          checked={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.NewWelder
                          }
                            onChange={(e) => {
                              handleRadioChange(e);
                              handleChange(e);
                            }}
                           style={{
                            fontSize: 10,
                            border: 20,
                            height: 10,
                          }}
                        />
                        <span
                          className={
                            trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.NewWelder
                              ? "fontWeight"
                              : ""
                          }
                          style={{ fontSize: 12 }}
                        >
                          {TrainingReqEmployeeType.NewWelder}
                        </span>
                      
                      </div>
                    </div>
                    {error.wld_TrainingReqEmployeeType.status && (
                          <Form.Text >
                          
                            <span
                              style={{
                                position: "relative",
                                bottom: "17px",
                                fontSize: "12px",
                                fontWeight: "normal",
                           
                              }}
                              className="text-danger"
                            >
                              {error.wld_TrainingReqEmployeeType.message}
                              </span>
                             
                              </Form.Text>)}
                  </div>
                  </Form.Group>
                </Col>

               {
                  trainReqObj.wld_TrainingReqEmployeeType ===
                    TrainingReqEmployeeType.ExistingWelder && (
                    <Col md={4}>
                      <Form.Group>
                        <div className="g-input" style={{ width: "100%" }}>
                          <>
                            <input
                              id="wld_TrainingReqSalaryEmployeeCode"
                              name="wld_TrainingReqSalaryEmployeeCode"
                              type="text"
                              maxLength="100"
                              readOnly
                              className={
                                error.wld_TrainingReqSalaryEmployeeCode.status
                                  ? "err-input"
                                  : ""
                              }
                              placeholder=" "
                              color="var(--col-707070)"
                              style={{ width: "100%" }}
                              value={
                                trainReqObj.wld_TrainingReqSalaryEmployeeCode
                              }
                              onChange={handleChange}
                            />
                            <label
                              for="wld_TrainingReqSalaryEmployeeCode"
                            >
                              Salary / Employee Code
                            </label>

                          {action=="Add"  && (<BiSelectMultiple
                              size={20}
                              onClick={() => {
                                showPopUp(
                                  "Welder",
                                  "Single",
                                  "emailid",
                                  trainReqObj.wld_TrainingReqSalaryEmployeeCode
                                );
                              }}
                              color={"gray"}
                              style={{
                                marginLeft: "15.5rem",
                                marginTop: "-4.2rem",
                              }}
                            /> )}
                          </>
                        </div>
                        {error.wld_TrainingReqSalaryEmployeeCode.status && (
                          <Form.Text className="text-danger">
                            <span
                              style={{
                                position: "relative",
                                bottom: "32px",
                                fontSize: "12px",
                              }}
                            >
                              {error.wld_TrainingReqSalaryEmployeeCode.message}
                            </span>
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                <Col md={4} style={{height:"80px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        type="text"
                        id="wld_TrainingReqEmployeeName"
                        name="wld_TrainingReqEmployeeName"
                        placeholder=" "
                        readOnly={
                          action === "Edit" ||
                          trainReqObj.wld_TrainingReqEmployeeType ===
                            TrainingReqEmployeeType.Employee
                        }
                        maxLength="50"
                        className={
                          error.wld_TrainingReqEmployeeName.status
                            ? " g-input placeHolderInput inputText text-field-with-error"
                            : "g-input placeHolderInput inputText"
                        }
                        value={trainReqObj.wld_TrainingReqEmployeeName}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericWithSpace(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqEmployeeName: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_TrainingReqEmployeeName"
                      >
                        Employee Name
                      </label>
                    </div>
                    {error.wld_TrainingReqEmployeeName.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "20px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_TrainingReqEmployeeName.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                { !(trainReqObj.wld_TrainingReqEmployeeType ===
                   TrainingReqEmployeeType.ExistingWelder) &&(
                      <Col md={4}>
                      <>
                      </>
                      </Col>
                    )
                }
                
                <Col md={4} style={{position:'relative',bottom:"13px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        id="wld_TrainingReqCompanyName"
                        name="wld_TrainingReqCompanyName"
                        type="text"
                        maxLength="100"
                        className={
                          error.wld_TrainingReqCompanyName.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_TrainingReqCompanyName}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqCompanyName: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_TrainingReqCompanyName"
                      >
                        Company Name
                      </label>
                    </div>
                    {error.wld_TrainingReqCompanyName.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_TrainingReqCompanyName.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
     
            
           
                <Col md={4} style={{position:'relative',bottom:"15px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        id="wld_TrainingReqExperience"
                        name="wld_TrainingReqExperience"
                        type="text"
                        maxLength="50"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_TrainingReqExperience.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_TrainingReqExperience}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                          isAlphanumericampspacedotdashbrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_TrainingReqExperience: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_TrainingReqExperience"
                      >
                        Experience
                      </label>
                    </div>
                    {error.wld_TrainingReqExperience.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_TrainingReqExperience.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} style={{position:'relative',bottom:"15px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        id="wld_ProcessQualified"
                        name="wld_ProcessQualified"
                        type="text"
                        maxLength="50"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_ProcessQualified.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_ProcessQualified}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_ProcessQualified: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_ProcessQualified"
                      >
                        Welding Process to be Qualified
                      </label>
                    </div>
                    {error.wld_ProcessQualified.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_ProcessQualified.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              
                <Col md={4} style={{ position: 'relative', bottom: "25px" }}>
  <Form.Group>
    <div className="g-input" style={{ width: "100%" }}>

      {trainReqObj.wld_PositionQualified?.map((position, index) => (
        <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
          
          <input
            className="checkboxcolor"
            style={{
              position: "relative",
              top: "30px",
              marginRight: "7px",
              marginLeft: "7px",
              width: "15px",
              height: "15px",
            
            }}
            
            type="checkbox"
            id={`position_${position[3]}`}
            name={`position_${position[3]}`}
            value={position[3]}
            checked={position[2] === "Checked"}
                onChange={() => handleCheckboxChange(position[3])}
           
          />
          <label  style={{ position:"relative", top:"18px", left:"22px" }}>
            {position[3]} {/* Assuming the label is the fourth value in the array */}
          </label>
        
        </div>
      ))}
      
     
    </div>
    <label style={{position:"relative",bottom:"60px", fontSize:"14px",color:"var(--col-707070)" , fontWeight:"500" }} >
      Welding Position to be Qualified
    </label>
  
    {error.wld_PositionQualified.status && (
      <Form.Text className="text-danger">
        <p
          style={{
            position: "relative",
            bottom: "33px",
            left: "6px",
            fontSize: "12px",
          }}
        >
          {error.wld_PositionQualified.message}
        </p>
      </Form.Text>
    )}
  </Form.Group>
</Col>        
           
 <>
                <Col md={4} style={{position:'relative',bottom:"8px"}}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        id="wld_BaseMaterial"
                        name="wld_BaseMaterial"
                        type="text"
                        maxLength="50"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_BaseMaterial.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_BaseMaterial}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_BaseMaterial: error,
                          }));
                        }}
                      />
                      <label  for="wld_BaseMaterial">
                        Base Material
                      </label>
                    </div>
                    {error.wld_BaseMaterial.status && (
                      <Form.Text className="text-danger">
                        <span
                          style={{
                            position: "relative",
                            bottom: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {error.wld_BaseMaterial.message}
                        </span>
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4} style={{position:'relative',bottom:"8px"}}>
               
                    <div className="g-input" style={{ width: "100%" }}>
                      <input
                        id="wld_TrainingReqDate"
                        readOnly
                        name="wld_TrainingReqDate"
                        type="text"
                        maxLength="20"
                        style={{ width: "100%" }}
                        className={
                          error.wld_TrainingReqDate.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        value={trainReqObj.wld_TrainingReqDate}
                      />
                      <label
                        for="wld_TrainingReqDate"
                      >
                        Training Requisition Date
                      </label>
                    </div>
                    {error.wld_TrainingReqDate.status && (
                      <Form.Text className="text-danger">
                        {error.wld_TrainingReqDate.message}
                      </Form.Text>
                    )}
                 
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" ,bottom:"45px"}}>
                      <input
                        id="wld_JointCategory"
                        name="wld_JointCategory"
                        type="text"
                        maxLength="35"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_JointCategory.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_JointCategory}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_JointCategory: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_JointCategory"
                      >
                        Joint Category
                      </label>
                    </div>
                    {error.wld_JointCategory.status && (
                      <Form.Text className="text-danger">
                        {error.wld_JointCategory.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%",bottom:"45px" }}>
                      <input
                        id="wld_AssgnDept"
                        name="wld_AssgnDept"
                        type="text"
                        maxLength="35"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_AssgnDept.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_AssgnDept}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_AssgnDept: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_AssgnDept"
                      >
                       Assigning Dept
                      </label>
                    </div>
                    {error.wld_AssgnDept.status && (
                      <Form.Text className="text-danger">
                        {error.wld_AssgnDept.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%",bottom:"45px" }}>
                      <input
                        id="wld_Tpi"
                        name="wld_Tpi"
                        type="text"
                        maxLength="35"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_Tpi.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_Tpi}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_Tpi: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_Tpi"
                      >
                        TPI
                      </label>
                    </div>
                    {error.wld_Tpi.status && (
                      <Form.Text className="text-danger">
                        {error.wld_Tpi.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>




                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%" ,bottom:"45px"}}>
                      <input
                        id="wld_SupervisorName"
                        name="wld_SupervisorName"
                        type="text"
                        maxLength="50"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_SupervisorName.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_SupervisorName}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_SupervisorName: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_SupervisorName"
                      >
                        Godrej Supervisor Name
                      </label>
                    </div>
                    {error.wld_SupervisorName.status && (
                      <Form.Text className="text-danger">
                        {error.wld_SupervisorName.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div className="g-input" style={{ width: "100%",bottom:"45px" }}>
                      <input
                        id="wld_SupervisorDept"
                        name="wld_SupervisorDept"
                        type="text"
                        maxLength="50"
                        readOnly={
                          action === "Edit" &&
                          (props.calledFrom === "Initiated" ||
                            trainReqObj.wld_status !== "Rejected")
                        }
                        className={
                          error.wld_SupervisorDept.status
                            ? "placeHolderInput inputText text-field-with-error"
                            : "placeHolderInput inputText"
                        }
                        placeholder=" "
                        style={{ width: "100%" }}
                        value={trainReqObj.wld_SupervisorDept}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumericampspacedotdashbrackets(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_SupervisorDept: error,
                          }));
                        }}
                      />
                      <label
                        for="wld_SupervisorDept"
                      >
                        Godrej Supervisor Dept
                      </label>
                    </div>
                    {error.wld_SupervisorDept.status && (
                      <Form.Text className="text-danger">
                        {error.wld_SupervisorDept.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                </>
              

              </Row>
            </div>
           
            {props.calledFrom === "Approval" && (
              <Row >
                 <Form.Group>
                    <div className="g-input" style={{ width: "100%",bottom:"45px" }}>
                <Col md={4} >
                
                <Date_Input
                divClass="T-input w-100"
                label="Welding Date"
                className="groove_datecolor"
                name="wld_WeldingDate"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate1}
                // maxDate={maxDate}
                obj={trainReqObj}
                setobj={setTrainReqObj}
                setError={setError}
                status={error.wld_WeldingDate.status}
                message={error.wld_WeldingDate.message}
              />
                </Col>
                </div>
                </Form.Group>
              </Row>
            )}
        
            {props.calledFrom === "Approval"  && action != "Add" && (
                <Form.Group>
                <div className="g-input" style={{ width: "100%",bottom:"55px" ,height:"50px"}}>
              <Row style={{ marginBottom: ".5rem" }}>
               
                <Col md={8}>
                  <div className="g-input w-200">
                    <input
                      type="text"
                      id="wld_VisualExam"
                      name="wld_VisualExam"
                      placeholder=" "
                      style={{ width: "510px" }}
                      maxLength="100"
                      className={error.wld_VisualExam.status ? "err-input" : ""}
                      value={trainReqObj.wld_VisualExam}
                      onChange={handleChange}
                      required
                      onInput={(e) => {
                        let error = isAlphanumericWithDashDotSlashSpaceBrackets(
                          e.target.value
                        );
                        setError((prevState) => ({
                          ...prevState,
                          wld_VisualExam: error,
                        }));
                      }}
                    />
                    <label for="wld_VisualExam">Visual Examination</label>
                  </div>
                  {error.wld_VisualExam.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "14px",fontSize:"12px" }}>
                        {error.wld_VisualExam.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>
                
                <Col md={4}>
       
<Date_Input
                divClass="T-input w-100"
                label=" Visual Examination Date"
                className="groove_datecolor"
                name="wld_VisualExamDate"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate1}
                maxDate={maxDate}
                obj={trainReqObj}
                setobj={setTrainReqObj}
                setError={setError}
                status={error.wld_VisualExamDate.status}
                message={error.wld_VisualExamDate.message}
              />
                </Col>

         
              </Row>
              </div>
           </Form.Group>
            )}

{props.calledFrom === "Approval"  && action != "Add" && (
                <Form.Group>
                <div className="g-input" style={{ width: "100%",bottom:"55px" ,height:"50px"}}>
              <Row style={{ marginBottom: ".5rem" }}>
               
              <Col md={8}>
                  <div className="g-input w-200">
                    <input
                      type="text"
                      id="wld_RTOfferWrench"
                      name="wld_RTOfferWrench"
                      placeholder=" "
                      style={{ width: "510px" }}
                      maxLength="100"
                      className={error.wld_RTOfferWrench.status ? "err-input" : ""}
                      value={trainReqObj.wld_RTOfferWrench}
                      onChange={handleChange}
                      required
                      onInput={(e) => {
                        let error = isAlphanumericWithDashDotSlashSpaceBrackets(
                          e.target.value
                        );
                        setError((prevState) => ({
                          ...prevState,
                          wld_RTOfferWrench: error,
                        }));
                      }}
                    />
                    <label for="wld_RTOfferWrench">RT Offer Wrench</label>
                  </div>
                  {error.wld_RTOfferWrench.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "14px",fontSize:"12px" }}>
                        {error.wld_RTOfferWrench.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>
                
                <Col md={4}>
       
<Date_Input
                divClass="T-input w-100"
                label="RT Offer Wrench Date"
                className="groove_datecolor"
                name="wld_RTOfferWrenchDate"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate1}
                maxDate={maxDate}
                obj={trainReqObj}
                setobj={setTrainReqObj}
                setError={setError}
                status={error.wld_RTOfferWrenchDate.status}
                message={error.wld_RTOfferWrenchDate.message}
              />
                </Col>

         
              </Row>
              </div>
           </Form.Group>
            )}



            {props.calledFrom === "Approval" && action != "Add" && (
                 <Form.Group style={{height:"0px"}}>
                 <div className="g-input" style={{ width: "100%",bottom:"65px",height:"10px" }}>
              <Row style={{ marginBottom: ".5rem" }}>
                <Col md={8}>
                  <div className="g-input_Nde w-200" style={{height:"30px",top:"8px"}}>
                    <input
                      type="text"
                      id="wld_NDEExam"
                      name="wld_NDEExam"
                      required
                      placeholder=" "
                      style={{ width: "510px" }}
                      maxLength="100"
                      className={error.wld_NDEExam.status ? "err-input" : ""}
                      value={trainReqObj.wld_NDEExam}
                      onChange={handleChange}
                      onInput={(e) => {
                        let error = isAlphanumericWithDashDotSlashSpaceBrackets(
                          e.target.value
                        );
                        setError((prevState) => ({
                          ...prevState,
                          wld_NDEExam: error,
                        }));
                      }}
                    />
                    <label for="wld_NDEExam">NDE Examination</label>
                  </div>
                  {error.wld_NDEExam.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "8px",fontSize:"12px" }}>
                        {error.wld_NDEExam.message}
                      </span>
                    </Form.Text>
                  )}
                </Col>

                <Col md={4}>
             

<Date_Input
                divClass="T-input w-100"
                label="NDE Examination Date"
                className="groove_datecolor"
                name="wld_NDEExamDate"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate1}
                maxDate={maxDate}
                obj={trainReqObj}
                setobj={setTrainReqObj}
                setError={setError}
                status={error.wld_NDEExamDate.status}
                message={error.wld_NDEExamDate.message}
              />
                </Col>
              </Row>
              </div></Form.Group>
            )}

            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
            {action === "Add" && (
              <Row>
                <div>
                  <div
                    className={
                      action === "Edit"
                        ? "float-start m-1 invisible"
                        : "float-start m-1 visible"
                    }
                  >
                    <Button variant="outline-primary" onClick={resetForm} style={{width:"80px", position:"relative", top:"8px"}}>
                      Reset
                    </Button>
                  </div>
                  <div className="float-end m-1">
                    <Button type="submit" className="btn-add-custom" style={{width:"80px"}}>
                      {action === "Edit" ? "Update" : "Add"}
                    </Button>
                  </div>
                </div>
              </Row>
            )}
            {action === "Edit" && Flg && (
              <>
                
                <Row >
                <Form.Group style={{height:"10px"}}>
                 <div className="g-input" style={{ width: "100%",bottom:"5px" }}>
                <u>Closure Remarks </u>
                </div>
                </Form.Group>
                  <ClosureRemarksQual
                    close={funcclose}
                    rowData={trainReqObj}
                    ExperienceNewRev={trainReqObj.wld_TrainingReqExperience}
                    callReload={callReload}
                    calledFrom={props.calledFrom}
                    setMainReload={props.setMainReload}
                    error={error}
                    setError={setError}
                  />
                </Row>
              
              </>
            )}
          </Modal.Body>
        </Form>
      </Modal>

      <RenderIf isTrue={popUp.type === "Single" && showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          masterName={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>

      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
    
  );
};

export default AddEditQualificationRequisition;
