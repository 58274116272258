import React, {
    useState,
    forwardRef,
    useEffect,
  } from "react";
  import { Row, Col, Container, Form, Button } from "react-bootstrap";
  import {ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
import { getwiwelders, WIexportreport,getWIdataexcelbyProjectno, getListofProjectno} from "../../Services/WI-services";
import { SearchSelectWIProject } from "../utility/search-select";
import { getAllOnlyProject } from "../../Services/project-services";
import { Date_Input } from "./Excel_Headers";
import {  ExportToExcel_Welder_Record } from "./wel_Record_Excel";
import jsPDF from "jspdf";
import { todayDate } from "../utility/CommonComponents/TodayDate";
import images from "../../icons/welder report.png"
  
  const Welder_Record_sheet = forwardRef((props, ref) => {
    let formName = "WI Slip";
    const [rowData, setRowData] = useState([]);
    const [widata, setwidata] = useState(null);
    const [wpsde, setwpsde] = useState({});
    const [reload, setReload] = useState(false);
    const [getreport, setgetreport] = useState([]);
    const [ProjectDetails, setProjectDetails] = useState([]);

    // const [loader, setloader] = useState(false);

    const [normalstyle, setNormal] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "grey",
      }),
    });


    let Data = []



    const [datevalue, setdatevalue] = useState(false);

    const [wiobj, setwiobj] = useState({
      WI_Project_No:"",
      WI_Joint_No:"",
      WI_Welder_Id:"",
      WI_From_Date:"",
      WI_To_Date:"",
      WI_Slip_Status:"",
      WPS_Rev_no:"",
      WI_FileFormat:"",
      
      
    });

    const [error, setError] = useState({
      WI_Project_No:{},
      WI_Joint_No:{},
      WI_Welder_Id:{},
      WI_From_Date:{},
      WI_To_Date:{},
      WI_Slip_Status:{},

    });

    console.log("wiobj",wiobj)

    console.log("errroorrr",error)


    var Slipstatus = [
      { value: "Created", name: "Created" },
      { value: "Cancelled", name: "Cancelled" },
      { value: "Deleted", name: "Deleted" },
    ];

    var fileformat = [
      { value: "Excel", name: "Excel" },
      { value: "PDF", name: "PDF" },
    ];



    // const fetchProject = async () => {  
    //   var result = await getAllOnlyProject();
    //   setProjectDetails(result);
    // };

    console.log("allpro",ProjectDetails)

    const fetchwidata = async (projectno,jointno) => {
      if(wiobj.WI_Project_No && wiobj.WI_Joint_No !== undefined){
        const result = await getWIdataexcelbyProjectno(projectno,jointno);
        setwidata(result);
      }
      };

    // const fetchwidata = async () => {
    //   const result = await getwiwelders();
    //   console.log("reeeeeew",result)
    // setwidata(result);
    // };


    console.log("ProjectDetails",ProjectDetails)
    console.log("WIData",widata)


    useEffect(() => {
      // fetchProject(),    
      fetchListofProject()    
    }, []);
  
    const fetchListofProject = async () => {
      const result = await getListofProjectno();
      setProjectDetails(result);
    };

    // useEffect(() => {
    //   fetchwidata();
    // }, [reload]);

    useEffect(() => {
      if (reload) {
        setReload(false);
      }
    }, [reload]);

    const uniqueProjectNumbers = [
      ...new Set(ProjectDetails && ProjectDetails.map((item) => item.SK)),
    ];
    // const uniqueProjectNumbers = [...new Set(widata && widata.map((item) => item.WI_Project_No))];
   
    // const getJointNosForSelectedProject = () => {
    //   if (wiobj.WI_Project_No && widata && widata.length > 0) {
    //     const selectedProjects = widata.filter(item => item.WI_Project_No === wiobj.WI_Project_No);
    //     const uniqueJointNos = new Set(selectedProjects.map(project => project.WI_Joint_No).filter(jointNo => jointNo));
    
    //     if (uniqueJointNos.size > 0) {
    //       return Array.from(uniqueJointNos).map(jointNo => ({
    //         label: jointNo,
    //         value: jointNo,
    //       }));
    //     }
    //   }
    //   return [];
    // };

    const getJointNosForSelectedProject = () => {

      if (wiobj.WI_Project_No && ProjectDetails && ProjectDetails.length > 0) {
        const selectedProjects = ProjectDetails.filter(
          (item) => item.SK === wiobj.WI_Project_No
        );
        
  
        const uniqueJointNos = 
          selectedProjects
            .map((project,id) => project.JointNumbers.values)
          
  
  let abc = uniqueJointNos[0]
        
  
        if (uniqueJointNos.length > 0) {
          return Array.from(abc).map((jointNo) => ({
            label: jointNo,
            value: jointNo,
          }));
        }
      }
      return [];
  
  
    };
    
    const getWelderForSelectedProject = () => {
      if (wiobj.WI_Project_No && wiobj.WI_Joint_No && widata && widata.length > 0) {
        const removeDuplicates = (keyFn, array) => {
          var mySet = new Set();
          return array.filter(function(x) {
              var key = keyFn(x), isNew = !mySet.has(key);
              if (isNew) mySet.add(key);
              return isNew;
          });
        }
  
       
          const uniqueWelders = removeDuplicates(x => x.WI_Welder_Id, widata);
          return uniqueWelders.map((welderId) => ({
            label: welderId.WI_Welder_Id,
            value: welderId.WI_Welder_Id,
          }));
       
      }
    
      return [];
    };

    

    const validate = async (e) => {
      
    var isError = false;

if (wiobj.WI_From_Date !== "" && wiobj.WI_To_Date !== "")
{
      if(wiobj.WI_From_Date > wiobj.WI_To_Date){
        console.log("datee",wiobj.WI_From_Date > wiobj.WI_To_Date)

        isError = true;
        error.WI_From_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        error.WI_To_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        toast.warning("ToDate Should be Greater than or Equal to FromDate", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    }
  
    return isError;

    }

    const [imageBase64, setImageBase64] = useState('');

    useEffect(() => {
      const fetchImage = async () => {
        try {
          // Replace './path/to/image.png' with the actual path to your PNG image file
          const response = await fetch(images);
          const blob = await response.blob();
          const reader = new FileReader();
  
          reader.onload = () => {
            const base64String = reader.result;
            setImageBase64(base64String);
          };
  
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      };
  
      fetchImage();
    }, [images]);


    console.log("fadffddf",imageBase64)

      const handleSubmit = async (e) => {
        
        if (!(await validate())) {


          let errorMessageShown = false;

        if(wiobj.WI_Project_No === ""){
          // alert("Please Add Project No")
          toast.warning("Please Add Project No", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          errorMessageShown = true;

        }

        if(!errorMessageShown && wiobj.WI_Slip_Status === ""){
          // alert("Please Add Project No")
          toast.warning("Please Add Slip Status", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          errorMessageShown = true;

        }

        if(!errorMessageShown && wiobj.WI_FileFormat === ""){
          // alert("Please Add Project No")
          toast.warning("Please Select File Format", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          errorMessageShown = true;

        }


        if(wiobj.WI_Project_No !== "" && wiobj.WI_Slip_Status !== "" && wiobj.WI_FileFormat !== "" ){
        const result =  await WIexportreport(wiobj)
        console.log("rress",result)
        setgetreport(result)

        // if(wiobj.WI_Project_No !== "" && wiobj.WI_FileFormat !== "" ){
        //   const result =  await WIexportreport(wiobj)
        //   console.log("rress",result)
        //   setgetreport(result)

        

        const isDuplicate = (result, item) =>
  result.some(
    (existingItem) =>
      existingItem.WI_Joint_No === item.WI_Joint_No &&
      existingItem.WI_WPS_No === item.WI_WPS_No &&
      existingItem.WI_ProcessCategory === item.WI_ProcessCategory
  );

const uniqueCombinations = result.reduce((result, item) => {

  const { WI_Project_No, WI_WPS_No, WI_Joint_No,WI_ProcessCategory, WI_Welder_Id } = item;
  const newItem = { WI_Project_No, WI_WPS_No, WI_Joint_No,WI_ProcessCategory, WI_Welder_Id };
  if (!isDuplicate(result, newItem)) {
    result.push(newItem);
  }
  return result;
}, []);

console.log("heyyyyyyy",uniqueCombinations);



const groupedData = result.reduce((result, item) => {
  const key = item.WI_Joint_No;
  // const category = item.WI_ProcessCategory ? item.WI_ProcessCategory.split("#") : [];
  // const pcate = category[0]


  if (!result[key]) {
    result[key] = {
      WI_Joint_No: item.WI_Joint_No,
      Data: [{
        WI_Project_No: item.WI_Project_No,
        WI_ProcessCategory: item.WI_ProcessCategory,
        WI_WPS_No: item.WI_WPS_No,
        WI_Welder_Id: new Set([item.WI_Welder_Id]),
        WPS_Rev_no:item.WPS_Rev_no
      }]
    };
  } else {
    const existingEntry = result[key].Data.find(entry =>
      entry.WI_WPS_No === item.WI_WPS_No &&
      entry.WI_ProcessCategory === item.WI_ProcessCategory
    );

    if (!existingEntry) {
      result[key].Data.push({
        WI_Project_No: item.WI_Project_No,
        WI_ProcessCategory: item.WI_ProcessCategory,
        WI_WPS_No: item.WI_WPS_No,
        WI_Welder_Id: new Set([item.WI_Welder_Id]),
        WPS_Rev_no:item.WPS_Rev_no

      });
    } else {
      // If the combination already exists, add the WI_Welder_Id to the existing entry
      existingEntry.WI_Welder_Id.add(item.WI_Welder_Id);
    }
  }

  return result;
}, {});

// Convert Set to Array for WI_Welder_Id
const finalResult = Object.values(groupedData).map(entry => ({
  ...entry,
  Data: entry.Data.map(data => ({
    ...data,
    WI_Welder_Id: Array.from(data.WI_Welder_Id)
  }))
}));

console.log("finalResult", finalResult);
  if(wiobj?.WI_FileFormat === "Excel"){


        let filename = "Welder Record Sheet";

        if (finalResult?.length > 0) {
          let prevProjectNo = null;
          let prevJointNo = null;
          let serialNumber = 1;


        
          finalResult.forEach((row, index) => {
            console.log("rroo", row.Data[index], index);
        
            row.Data.forEach((subrow, subIndex) => {
              const shouldPrintProjectNo = subIndex === 0 && subrow.WI_Project_No !== prevProjectNo;
              const shouldPrintJointNo = row.WI_Joint_No !== prevJointNo;
        
              Data.push({
                A: shouldPrintJointNo ? serialNumber++ : "",
                B: shouldPrintJointNo ? row.WI_Joint_No : "",
                C: subrow.WI_WPS_No,
                D: subrow.WPS_Rev_no,
                E: subrow.WI_ProcessCategory,
                F: Array.isArray(subrow.WI_Welder_Id) ? subrow.WI_Welder_Id.join(", ") : "",
              });
        
              // Update the previous values
              prevProjectNo = subrow.WI_Project_No;
              prevJointNo = row.WI_Joint_No;
            });
          });
        
          let titleCell = "E3";
          let titleWelderRange = "A6:F6";
          let tbodyHeadRange = "C1:F2";
          let tbodyRange = "A3";
          let blankRow = "";
          let width = [
            { col: "A", width: 5.91 },
            { col: "B", width: 19.91 },
            { col: "C", width: 20.91 },
            { col: "D", width: 15.91 },
            { col: "E", width: 15.91 },
            { col: "F", width: 13.55 },

          ];
          let Headings = [

            { col: "A1", value: "Godrej Process Equipment" },
            { col: "D1", value: "Manufacturing" },
            {
              col: "D2",
              value:
                "Welder Record Sheet",
            },
            {
              col: "B3",
              value:
                `Document number : PED/WRS/${finalResult[0].Data[0].WI_Project_No}`,
            },
            {
              col: "B4",
              value:
                `Project:${finalResult[0].Data[0].WI_Project_No}`,
            },
          ];
        
          ExportToExcel_Welder_Record(
            filename,
            width,
            finalResult,
            Data,
            titleCell,
            titleWelderRange,
            tbodyHeadRange,
            tbodyRange,
            blankRow,
            Headings,
            imageBase64
          );
        }
        

        else{
          toast.info("There are no records matching to filters.", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if(uniqueCombinations.length > 0 ){
          // alert("Data Exported")
          toast.success("Exported successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

      }

      if(wiobj?.WI_FileFormat === "PDF"){

console.log("finalResult11", finalResult);        

  //   if (finalResult?.length > 0) {
  //     const pdf = new jsPDF();
  
  //     // Title and Header
  //     const imgURL = images; // Replace with your image URL
  //     const imgWidth = 50; // Adjust image width as needed
  //     const imgHeight = 9; // Adjust image height as needed
  //     const marginLeft1 = 10; // Adjust margin as needed
  
  //     pdf.addImage(imgURL, 'PNG', marginLeft1, 15, imgWidth, imgHeight);
  //     pdf.setFont('helvetica', 'bolditalic');
  //     pdf.text('Manufacturing', 100, 20);
  //     pdf.text('Welder Record Sheet', 90, 30);
  
  //     pdf.setFont('helvetica', 'normal');
  
  //     // Document Information
  //     pdf.setFontSize(12);
  //     const projectNo = finalResult[0]?.Data[0]?.WI_Project_No || 'N/A'; // Safeguard for undefined
  //     pdf.text(`Document number: PED/WRS/${projectNo}`, 20, 45);
  //     pdf.text(`Project: ${projectNo}`, 20, 55);
  
  //     // Headers
  //     const marginLeft = 5; // Adjust the left margin as needed
  //     const headerX = marginLeft; // Define x for headers
  //     const headerY = 70;
  
  //     // Draw border lines for headers
  //     pdf.rect(headerX, headerY, 200, 10); // Adjust the width (200 in this case) based on your layout
  
  //     // Add text to headers with a smaller font size
  //     pdf.setFontSize(10); // Set the font size to 10
  //     pdf.text('Sr No', headerX + 2, headerY + 7);
  //     pdf.line(headerX + 15, headerY, headerX + 15, headerY + 10);
  //     pdf.text('Joint No', headerX + 17, headerY + 7);
  //     pdf.line(headerX + 56, headerY, headerX + 56, headerY + 10);
  //     pdf.text('WPS No', headerX + 57, headerY + 7);
  //     pdf.line(headerX + 108, headerY, headerX + 108, headerY + 10);
  //     pdf.text('Rev No', headerX + 110, headerY + 7);
  //     pdf.line(headerX + 128, headerY, headerX + 128, headerY + 10);
  //     pdf.text('Process', headerX + 130, headerY + 7);
  //     pdf.line(headerX + 148, headerY, headerX + 148, headerY + 10);
  //     pdf.text('Welder IDs', headerX + 150, headerY + 7);
  
  //     // Data Rows
  //     let y = 80; // Initialize y position for the first row
  //     finalResult.forEach((row, rowIndex) => {
  //         row.Data.forEach((subrow, subIndex) => {
  //             const x = marginLeft;
  //             const jointNo = subIndex === 0 ? row.WI_Joint_No : ''; // Only display Joint No once per group
  //             const process = subrow.WI_ProcessCategory || 'N/A';
  //             const wpsNo = subrow.WI_WPS_No || 'N/A';
  //             const revNo = subrow.WPS_Rev_no || 'N/A';
  //             const welderIds = Array.isArray(subrow.WI_Welder_Id) ? subrow.WI_Welder_Id : [];
  
  //             // Split welder IDs into groups of 2
  //             const welderIdGroups = [];
  //             for (let i = 0; i < welderIds.length; i += 3) {
  //                 welderIdGroups.push(welderIds.slice(i, i + 3).join(', '));
  //             }
  
  //             // Debugging logs
  //             // console.log(Row ${rowIndex + 1}, Subrow ${subIndex + 1});
  //             // console.log(jointNo: ${jointNo}, process: ${process}, wpsNo: ${wpsNo}, revNo: ${revNo}, welderIdGroups: ${welderIdGroups});
  
  //             // Ensure all arguments are strings
  //             const jointNoText = String(jointNo);
  //             const processText = String(process);
  //             const wpsNoText = String(wpsNo);
  //             const revNoText = String(revNo);
  
  //             // Determine the height of the row based on the number of welder ID groups
  //             const rowHeight = welderIdGroups.length * 10;
  
  //             // Draw a single border line for the entire row
  //             pdf.rect(x, y, 200, rowHeight); // Adjust the width (200 in this case) based on your layout
  
  //             // Add text to cells with a smaller font size
  //             pdf.setFontSize(12); // Set the font size to 12 (adjust as needed)
  //             pdf.text(`${rowIndex + 1}`, x + 2, y + 7); // Index
  //             pdf.text(jointNoText, x + 17, y + 7); // Joint No
  //             pdf.text(wpsNoText, x + 57, y + 7); // WPS No
  //             pdf.text(revNoText, x + 110, y + 7); // Rev No
  //             pdf.text(processText, x + 130, y + 7); // Process
  
  //             // Add welder IDs text for each group
  //             welderIdGroups.forEach((welderIdText, groupIndex) => {
  //                 pdf.line(x + 15, y, x + 15, y + 10 + groupIndex * 10);
  //                 pdf.line(x + 56, y, x + 56, y + 10 + groupIndex * 10);
  //                 pdf.line(x + 108, y, x + 108, y + 10 + groupIndex * 10);
  //                 pdf.line(x + 128, y, x + 128, y + 10 + groupIndex * 10);
  //                 pdf.line(x + 148, y, x + 148, y + 10 + groupIndex * 10);
  //                 pdf.text(welderIdText, x + 150, y + 7 + groupIndex * 10); // Adjust x and y positions as needed
  //             });
  
  //             // Increment y position for the next row
  //             y += rowHeight;
  //         });
  //     });
  
  //     // Save or download the PDF
  //     pdf.save('Welder Record Sheet.pdf');
  // }

  if (finalResult?.length > 0) {
    const pdf = new jsPDF();

    // Title and Header
    const imgURL = images;
    const imgWidth = 50;
    const imgHeight = 9;
    const marginLeft1 = 10;
    pdf.addImage(imgURL, 'PNG', marginLeft1, 15, imgWidth, imgHeight);
    pdf.setFont('helvetica', 'bolditalic');
    pdf.text('Manufacturing', 100, 20);
    pdf.text('Welder Record Sheet', 90, 30);
    pdf.setFont('helvetica', 'normal');

    pdf.setFontSize(12);
    const projectNo = finalResult[0]?.Data[0]?.WI_Project_No || 'N/A';
    pdf.text(`Document number: PED/WRS/${projectNo}`, 20, 45);
    pdf.text(`Project: ${projectNo}`, 20, 55);

    const marginLeft = 5;
    const headerX = marginLeft;
    const headerY = 70;
    pdf.rect(headerX, headerY, 200, 10);
    pdf.setFontSize(10);
    pdf.text('Sr No', headerX + 2, headerY + 7);
    pdf.line(headerX + 15, headerY, headerX + 15, headerY + 10);
    pdf.text('Joint No', headerX + 17, headerY + 7);
    pdf.line(headerX + 56, headerY, headerX + 56, headerY + 10);
    pdf.text('WPS No', headerX + 57, headerY + 7);
    pdf.line(headerX + 108, headerY, headerX + 108, headerY + 10);
    pdf.text('Rev No', headerX + 110, headerY + 7);
    pdf.line(headerX + 128, headerY, headerX + 128, headerY + 10);
    pdf.text('Process', headerX + 130, headerY + 7);
    pdf.line(headerX + 148, headerY, headerX + 148, headerY + 10);
    pdf.text('Welder IDs', headerX + 150, headerY + 7);

    // Data Rows
    let y = 80; // Initialize y position for the first row
    const pageHeight = pdf.internal.pageSize.height; // Page height in jsPDF

    finalResult.forEach((row, rowIndex) => {
        row.Data.forEach((subrow, subIndex) => {
            if (y + 20 > pageHeight) { // Add a new page if y position exceeds page height
                pdf.addPage();
                
                // Reset y position after adding a new page
                y = 20;

                // Redraw headers on the new page
                pdf.setFontSize(10);
                pdf.rect(headerX, y, 200, 10);
                pdf.text('Sr No', headerX + 2, y + 7);
                pdf.line(headerX + 15, y, headerX + 15, y + 10);
                pdf.text('Joint No', headerX + 17, y + 7);
                pdf.line(headerX + 56, y, headerX + 56, y + 10);
                pdf.text('WPS No', headerX + 57, y + 7);
                pdf.line(headerX + 108, y, headerX + 108, y + 10);
                pdf.text('Rev No', headerX + 110, y + 7);
                pdf.line(headerX + 128, y, headerX + 128, y + 10);
                pdf.text('Process', headerX + 130, y + 7);
                pdf.line(headerX + 148, y, headerX + 148, y + 10);
                pdf.text('Welder IDs', headerX + 150, y + 7);

                y += 20; // Move y down after headers
            }

            const x = marginLeft;
            const jointNo = subIndex === 0 ? row.WI_Joint_No : '';
            const process = subrow.WI_ProcessCategory || 'N/A';
            const wpsNo = subrow.WI_WPS_No || 'N/A';
            const revNo = subrow.WPS_Rev_no || 'N/A';
            const welderIds = Array.isArray(subrow.WI_Welder_Id) ? subrow.WI_Welder_Id : [];
            const welderIdGroups = [];

            for (let i = 0; i < welderIds.length; i += 3) {
                welderIdGroups.push(welderIds.slice(i, i + 3).join(', '));
            }

            const jointNoText = String(jointNo);
            const processText = String(process).split("#")[0];
            const wpsNoText = String(wpsNo);
            const revNoText = String(revNo);
            const rowHeight = welderIdGroups.length * 10;

            pdf.rect(x, y, 200, rowHeight);
            pdf.setFontSize(12);
            pdf.text(`${rowIndex + 1}`, x + 2, y + 7);
            pdf.text(jointNoText, x + 17, y + 7);
            pdf.text(wpsNoText, x + 57, y + 7);
            pdf.text(revNoText, x + 110, y + 7);
            pdf.text(processText, x + 130, y + 7);

            welderIdGroups.forEach((welderIdText, groupIndex) => {
                pdf.line(x + 15, y, x + 15, y + 10 + groupIndex * 10);
                pdf.line(x + 56, y, x + 56, y + 10 + groupIndex * 10);
                pdf.line(x + 108, y, x + 108, y + 10 + groupIndex * 10);
                pdf.line(x + 128, y, x + 128, y + 10 + groupIndex * 10);
                pdf.line(x + 148, y, x + 148, y + 10 + groupIndex * 10);
                pdf.text(welderIdText, x + 150, y + 7 + groupIndex * 10);
            });

            y += rowHeight; // Increment y position for the next row
        });
    });

    pdf.save('Welder Record Sheet.pdf');
}
        
        
        else{
          toast.info("There are no records matching to filters.", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (finalResult?.length > 0) {
          // alert("Data Exported")
          toast.success("Exported successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }


      }
    }





      }
    
    
  }
  


  // const GeneratePDF = () => {
  //   const finalResult = Object.values(groupedData).map(entry => ({
  //     ...entry,
  //     Data: entry.Data.map(data => ({
  //       ...data,
  //       WI_Welder_Id: Array.from(data.WI_Welder_Id)
  //     }))
  //   }));  


  //   console.log("finalResult11", finalResult);

  //   if (finalResult?.length > 0) {
  //     const pdf = new jsPDF();
  
  //     // Title and Header
  //     pdf.text('Godrej', 20, 20);
  //     pdf.text('Manufacturing', 70, 20);
  //     pdf.text('Welder Record Sheet', 70, 30);
  
  //     // Document Information
  //     pdf.text(`Document number: PED/WRS/${finalResult[0].Data[0].WI_Project_No}`, 20, 50);
  //     pdf.text(`Project: ${finalResult[0].Data[0].WI_Project_No}`, 20, 60);
  
  //     // Table Headers
  //     pdf.text('A', 20, 80);
  //     pdf.text('B', 40, 80);
  //     pdf.text('C', 70, 80);
  //     pdf.text('D', 110, 80);
  //     pdf.text('E', 150, 80);
  //     pdf.text('F', 190, 80);
  
  //     // Data Rows
  //     finalResult.forEach((row, rowIndex) => {
  //       row.Data.forEach((subrow, subIndex) => {
  //         const x = 20;
  //         const y = 90 + rowIndex * 10 + subIndex * 10;
          
  //         pdf.text(subIndex === 0 ? rowIndex + 1 : '', x, y);
  //         pdf.text(subIndex === 0 ? row.WI_Joint_No : '', x + 20, y);
  //         pdf.text(subrow.WI_WPS_No, x + 50, y);
  //         pdf.text(subrow.WPS_Rev_no, x + 90, y);
  //         pdf.text(subrow.WI_Process, x + 130, y);
  //         pdf.text(Array.isArray(subrow.WI_Welder_Id) ? subrow.WI_Welder_Id.join(', ') : '', x + 170, y);
  //       });
  //     });
  
  //     // Save or download the PDF
  //     pdf.save('generated.pdf');
  //   } else {
  //     console.log('No data to generate PDF.');
  //   }
  
  //   return <div>PDF generated!</div>;
  // }



  
  console.log("reeport",getreport)
      
    return (
      <>
      <Container fluid>
        <Row className="RowHeight mb-4 ml-2">
          <Col md={12} sm={12} xs={12} className="ListTitle">
          Welder Record Sheet Used in Project Filters
          </Col>
        </Row>
        <div className="px-2 pb-2">
        <Row className="gx-3" style={{ marginBottom: "2rem" }}>
          <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  // className="inspdrop"
                  id="WI_Project_No"
                  name="WI_Project_No"
                  className={""}
                  stylegreen={normalstyle}
                  data={uniqueProjectNumbers && uniqueProjectNumbers.map(projectNo => ({ WI_Project_No: projectNo }))} 
                  stylenormal={normalstyle}
                  valueField={"WI_Project_No"}
                  value={wiobj?.WI_Project_No}
                  labelField={"WI_Project_No"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Project_No: ProjectList.value,
                      WI_Joint_No:"",
                      WI_Welder_Id:"",
                      WI_From_Date:"",
                      WI_To_Date:"",
                      WI_Slip_Status:"",
                      WI_FileFormat:"",
                    }));
                  }}
                 
                ></SearchSelectWIProject>
              </div>
              <label>Project Number</label>
             
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  // className="inspdrop"
                  id="WI_Joint_No"
                  name="WI_Joint_No"
                  className={""}
                  stylegreen={normalstyle}
                  data={getJointNosForSelectedProject()}
                  stylenormal={normalstyle}
                  valueField={"value"}
                  value={wiobj?.WI_Joint_No}
                  labelField={"label"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Joint_No: ProjectList.value,
                      WI_Welder_Id:"",
                    }));
                    fetchwidata(wiobj?.WI_Project_No, ProjectList.value)   
                  }}
                 
                ></SearchSelectWIProject>
              </div>
              <label>Joint</label>
             
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  id="WI_Welder_Id"
                  name="WI_Welder_Id"
                  className={""}
                  stylegreen={normalstyle}
                  data={getWelderForSelectedProject()}
                  stylenormal={normalstyle}
                  valueField={"value"}
                  value={wiobj?.WI_Welder_Id}
                  labelField={"label"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Welder_Id: ProjectList.value,
                    }));
                  }}
                 
                ></SearchSelectWIProject>
              </div>
              <label>Welder Code</label>
             
            </Form.Group>
          </Col>

            <Col md={3} >
            <Date_Input 
            divClass="Date-input w-100"
            label="Slip Creation Date From"
            className="groove_datecolor"
            name="WI_From_Date"
            sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex:"0" }}
            todayDate={todayDate}
            obj={wiobj}
            setobj={setwiobj}
            datevalue={datevalue}
            setdatevalue={setdatevalue}
            todate={wiobj.WI_To_Date}
            setError={setError}
            status={error.WI_From_Date.status}
            message={error.WI_From_Date.message}
            />
            </Col>

            <Col md={3} style={{position:"relative",right:"3px", bottom:"5px"}}>
            <Date_Input 
            divClass="Date-input w-100"
            label="Slip Creation Date To"
            className="groove_datecolor"
            name="WI_To_Date"
            sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex:"0", width: "300px",}}
            todayDate={todayDate}
            obj={wiobj}
            fromdate={wiobj.WI_From_Date}
            setobj={setwiobj}
            setdatevalue={setdatevalue}
            datevalue={datevalue}
            setError={setError}
            status={error.WI_To_Date.status}
            message={error.WI_To_Date.message}
            />
            </Col>

          <Col md={3} style={{position:"relative", bottom:"5px"}}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  id="WI_Slip_Status"
                  name="WI_Slip_Status"
                  className={""}
                  stylegreen={normalstyle}
                  data={Slipstatus}
                  stylenormal={normalstyle}
                  valueField={"value"}
                  value={wiobj?.WI_Slip_Status}
                  labelField={"name"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setwiobj((prev) => ({
                      ...prev,
                      WI_Slip_Status: ProjectList.value,
                    }));
                  }}
                 
                ></SearchSelectWIProject>
              </div>
              <label>Slip Status</label>
             
            </Form.Group>
          </Col>

          <Col md={3} style={{position:"relative", bottom:"5px"}}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  id="WI_FileFormat"
                  name="WI_FileFormat"
                  className={""}
                  stylegreen={normalstyle}
                  data={fileformat}
                  stylenormal={normalstyle}
                  valueField={"value"}
                  value={wiobj?.WI_FileFormat}
                  labelField={"name"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setwiobj((prev) => ({
                      ...prev,
                      WI_FileFormat: ProjectList.value,
                    }));
                    // if(ProjectList.value === "PDF"){
                    //   alert("PDF is work in progress")
                    // }
                  }}


                 
                ></SearchSelectWIProject>
              </div>
              <label>File Format</label>
             
            </Form.Group>
          </Col>
          </Row>
              </div>
              <Row>
            <Col>
              <Button onClick={handleSubmit} className="btn-add-color semiBoldToggleButton rightAlignedButton" >
                Download
              </Button>
            </Col>
          </Row>
       </Container>

<ToastContainer
className="toast-position"
autoClose={3000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
</>
    );
  });
  
  export default Welder_Record_sheet;
  