import { createSlice } from "@reduxjs/toolkit";

const roleSlice = createSlice({
  name: "role",
  initialState: {
    roleDetails: {
      role: "",
      userName: "",
      email: "",
    },
  },
  reducers: {
    setRole(state, action) {
      state.roleDetails = action.payload;
    },
  },
});

export const roleActions = roleSlice.actions;

export default roleSlice;
