export const emptyError = (err, seterr, iserror) => {
  var entries = Object.keys(err);
  console.log("d", entries);

  entries.map((i) => {
    console.log("eeed",err[i])
    if(err[i].message ===  `Special Characters # & % + - ${""} ' < > = ; not allowed`){
      iserror = true
      err[i] = {
        status: true,
        message: err[i].message,
      };    
    }
    else{
    // console.log("new error", i);
    err[i] = {
      status: false,
      message: ``,
    };
  }
    //console.log("new error", err[i], i);
  });
  console.log("new error", err);
  seterr((prevState) => ({
    ...prevState,
    ...err,
  }));

  return iserror
};

export const emptyErrorWPQDetail = (err, seterr, iserror) => {
  debugger
  var entries = Object.keys(err);
  console.log("deeeeeeec", entries);

  entries.map((i) => {
    console.log("eeed",err[i] )
    let errmessage = "Special Characters # & % + -  ' < > = ; not allowed"
    // if(err[i][0].Actual_Value[0].message ===  "Special Characters # & % + -  ' < > = ; not allowed"){
      if(err[i][0].Actual_Value[0].message === errmessage  ||  err[i][1].Range_Qualified[0].message === errmessage){
      iserror = true
      seterr((prevState) => ({
        ...prevState,
        [entries]: [
          { Actual_Value: [{status: true,
            message: "Special Characters # & % + -  ' < > = ; not allowed"}] },
          { Range_Qualified: [{status: true,
            message: "Special Characters # & % + -  ' < > = ; not allowed"}] },
        ],
      }));  
    }
    else{
    seterr((prevState) => ({
      ...prevState,
      [entries]: [
        { Actual_Value: [{status: false,
          message: ""}] },
        { Range_Qualified: [{status: false,
          message: ""}] },
      ],
    }));
  }
  });
  console.log("new error", err);
  return iserror
};

export const emptyErrorWPQDetailTubesheet = (err, seterr, iserror) => {
  debugger
  var entries = Object.keys(err);
  console.log("deeeeeeec", entries);

  entries.map((i) => {
    console.log("eeed",err[i]  )
    let value = err[i]
    if(Array.isArray(value)){
    let errmessage = "Special Characters # & % + -  ' < > = ; not allowed"
    // if(err[i][0].Actual_Value[0].message ===  "Special Characters # & % + -  ' < > = ; not allowed"){
      if(err[i][0].Value_A[0].message === errmessage ||  err[i][1].Value_B[0].message === errmessage){
      iserror = true
      seterr((prevState) => ({
        ...prevState,
        [entries]: [
          { Value_A: [{status: true,
            message: "Special Characters # & % + -  ' < > = ; not allowed"}] },
          { Value_B: [{status: true,
            message: "Special Characters # & % + -  ' < > = ; not allowed"}] },
        ],
      }));  
    }
    else{
    seterr((prevState) => ({
      ...prevState,
      [entries]: [
        { Value_A: [{status: false,
          message: ""}] },
        { Value_B: [{status: false,
          message: ""}] },
      ],
    }));
  }
}
else{

  if(err[i].message ===  `Special Characters # & % + - ${""} ' < > = ; not allowed`){
    iserror = true
    err[i] = {
      status: true,
      message: err[i].message,
    };    
  }
  else{
  // console.log("new error", i);
  err[i] = {
    status: false,
    message: ``,
  };
}
  //console.log("new error", err[i], i);
console.log("new error", err);
seterr((prevState) => ({
  ...prevState,
  ...err,
}));


}
  });
  console.log("new error", err);
  return iserror
};
