export const isWPQProcessActive = (wldCreatedOnString) => {
    let ProcessActive = "InActive"
    if (wldCreatedOnString && wldCreatedOnString !== undefined) {
                    const [day, month, year] = wldCreatedOnString
                        .split("-")
                        .map(Number);
                    const wldCreatedOnDate = new Date(year, month - 1, day);

                    const lastWeldDate = new Date(
                        wldCreatedOnDate.getTime() + 178 * 24 * 60 * 60 * 1000
                    );
                    const currentDate = new Date();

                    const timeDiffMilliseconds = lastWeldDate - currentDate;
                    const timeDiffDays = Math.ceil(
                        timeDiffMilliseconds / (1000 * 3600 * 24)
                    );
                    ProcessActive = timeDiffDays < 0 ? "InActive" : "Active";

                  

    }
    return ProcessActive;


}




 export const DiameterCheck = (element, item, WiObj) => {
    
    var DiameterCheck = false
    let selectedDiameter
    console.log("pipe check");
    if (
      
      WiObj.WI_PipePlate === "Pipe"
    ) {
      selectedDiameter = WiObj.WI_Pipe_Diameter;
    } else if (
     
      WiObj.WI_PipePlate === "Plate"
    ) {
      selectedDiameter = WiObj.WI_Plate_No;
    }

    let WPQ_Groove_610 = [
      element.WPQ_Groove_610?.[0]?.Range_Qualified?.some((subArray) =>
        subArray.includes(WiObj.WI_Welding_Position)
      ),
    ].some((hasH) => hasH);

    let WPQ_Groove_73to610 = [
      element.WPQ_Groove_73to610?.[0]?.Range_Qualified?.some((subArray) =>
        subArray.includes(WiObj.WI_Welding_Position)
      ),
    ].some((hasH) => hasH);


    let WPQ_Groove_73 = [
      element.WPQ_Groove_73?.[0]?.Range_Qualified?.some((subArray) =>
        subArray.includes(WiObj.WI_Welding_Position)
      ),
    ].some((hasH) => hasH);

    let WPQ_Overlay_610 = [
      element.WPQ_Overlay_610?.[0]?.Range_Qualified?.some((subArray) =>
        subArray.includes(WiObj.WI_Welding_Position)
      ),
    ].some((hasH) => hasH);

    let WPQ_Overlay_73to610 = [
      element.WPQ_Overlay_73to610?.[0]?.Range_Qualified?.some((subArray) =>
        subArray.includes(WiObj.WI_Welding_Position)
      ),
    ].some((hasH) => hasH);

    let WPQ_Overlay_73 = [
      element.WPQ_Overlay_73?.[0]?.Range_Qualified?.some((subArray) =>
        subArray.includes(WiObj.WI_Welding_Position)
      ),
    ].some((hasH) => hasH);

    if (selectedDiameter !== undefined && WiObj.wld_WPSType === "Groove") {
      console.log("1");
      if (selectedDiameter > 610 && WPQ_Groove_610) {
        DiameterCheck = true
        return DiameterCheck;

      } else if (
        selectedDiameter >= 73 &&
        selectedDiameter <= 610 &&
        (WPQ_Groove_73to610 || WPQ_Groove_610)
      ) {
        DiameterCheck = true
        return DiameterCheck;

      } else if (
        selectedDiameter < 73 &&
        (WPQ_Groove_73to610 || WPQ_Groove_610 || WPQ_Groove_73)
      ) {
        DiameterCheck = true
        return DiameterCheck;

      }
    }

    if (selectedDiameter !== undefined && WiObj.wld_WPSType === "Overlay") {
      console.log("1");
      if (selectedDiameter > 610 && WPQ_Overlay_610) {
        DiameterCheck = true
        return DiameterCheck;

      } else if (
        selectedDiameter >= 73 &&
        selectedDiameter <= 610 &&
        (WPQ_Overlay_73to610 || WPQ_Overlay_610)
      ) {
        DiameterCheck = true
        return DiameterCheck;

      } else if (
        selectedDiameter < 73 &&
        (WPQ_Overlay_73to610 || WPQ_Overlay_610 || WPQ_Overlay_73)
      ) {
        DiameterCheck = true
        return DiameterCheck;

      }
    }
  };
