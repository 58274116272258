import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import {
  AddNewWeldingProcess,
  UpdateWeldingProcess,
} from "../../Services/weldingprocess-services";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumeric,
  isAlphanumericWithDotSpaceDashCommaBrackets,
  isAlphanumericWithSpecialChars,
  isNumber,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../utility/FormControl";
import ButtonModal from "../Modals/buttonModal";
import { SearchSelectWIProject } from "../utility/search-select";

const AddEditWeldingProcessMaster = (props) => {
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "WeldingProcess";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  console.log("username is -- ", username);

  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "grey" : "grey",
    }),
  });

  var Process_Cat = [
    { value: "SMAW", name: "SMAW" },
    { value: "SAW", name: "SAW" },
    { value: "GTAW", name: "GTAW" },
    { value: "FCAW", name: "FCAW" },
    { value: "GMAW", name: "GMAW" },
    { value: "ESW", name: "ESW" },
    { value: "GTAW (Hot wire)", name: "GTAW (Hot wire)" },
  ];


  const [WeldingProcessObj, setWeldingProcessObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          wld_status: true,
          wld_code: "",
          wld_p_category: "",
          wld_description: "",
          SK: "",
          PK: "WeldingProcess",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
        }
  );
  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_p_category: {},
    wld_description: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setWeldingProcess",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setWeldingProcessObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      // wld_code: {},
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setWeldingProcessObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  const resetForm = () => {
    if (action === "Add") {
      setWeldingProcessObj((prevState) => ({
        ...prevState,
        wld_status: true,
        wld_code: "",
        wld_p_category: "",
        wld_description: "",
        SK: "",
        wld_createdby: "",
        wld_createdon: "",
      }));
    } else if (action === "Edit") {
      setWeldingProcessObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_code: props.rowdata.wld_code,
        wld_p_category: props.rowdata.wld_p_category,
        wld_description: props.rowdata.wld_description,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_code: {},
      wld_p_category: {},
      wld_description: {},
      SK: {},
    }));
  };

  const validateWeldingProcess = (action) => {
    let result = false;
    let isError = false;
    if (WeldingProcessObj.wld_code.trim() === "") {
      WeldingProcessObj.wld_code = "";
      isError = true;
      error.wld_code = {
        status: true,
        message: "Welding Process Number is required",
      };
    }
    if (WeldingProcessObj.wld_p_category.trim() === "") {
      WeldingProcessObj.wld_p_category = "";
      isError = true;
      error.wld_p_category = {
        status: true,
        message: "Process Category is required.",
      };
    }
    if (WeldingProcessObj.wld_description.trim() === "") {
      WeldingProcessObj.wld_description = "";
      isError = true;
      error.wld_description = {
        status: true,
        message: "Process Description is required.",
      };
    }
    if (WeldingProcessObj.wld_status === "") {
      isError = true;
      error.wld_status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (action === "Add") {
      
      console.log("WeldingProcessObj -- ", WeldingProcessObj);
      result =
        props.allRecs &&
        props.allRecs.find(
          (item) =>
            item.PK.toLowerCase() === WeldingProcessObj.PK.toLowerCase() &&
            item.SK.toLowerCase() === WeldingProcessObj.wld_code.toLowerCase()
        );
      console.log(result);
    }

    if (result) {
      setAlertMessage("Process  Combination Already Exists!");
      isError = true;
    }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.SK.status ||
      error.wld_code.status ||
      error.wld_p_category.status ||
      error.wld_description.status
    ) {
      isError = true;
    }
    return isError;
  };
  const handleAdd = (e) => {
    let result;
    if (WeldingProcessObj.wld_status === true) {
      WeldingProcessObj.wld_status = "Active";
    } else {
      WeldingProcessObj.wld_status = "Inactive";
    }
    if (action === "Add") {
      WeldingProcessObj.SK = WeldingProcessObj.wld_code;
      WeldingProcessObj.wld_createdby = username;
      WeldingProcessObj.wld_createdon = WeldingProcessObj.wld_createdon;
      result = AddNewWeldingProcess(WeldingProcessObj);
    } else {
      WeldingProcessObj.wld_updatedby = WeldingProcessObj.wld_createdby;
      WeldingProcessObj.wld_updatedon = WeldingProcessObj.wld_updatedon;
      result = UpdateWeldingProcess(WeldingProcessObj);
    }
    result
      .then((resp) => {
        if (action === "Edit") {
          toast.success(
            `Selected Welding Process has been Updated successfully`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
        }
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success(
            `Selected Welding Process has been Added successfully`,
            {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            }
          );
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Unable to process request");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateWeldingProcess(action)) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Welding Process successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Welding Process </Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        type="text"
                        id="wld_code"
                        name="wld_code"
                        placeholder=" "
                        disabled={action === "Edit" ? true : false}
                        maxLength="50"
                        className={
                          error.wld_code.status
                            ? " g-input placeHolderInput inputText text-field-with-error allow"
                            : "g-input placeHolderInput inputText allow"
                        }
                        value={WeldingProcessObj.wld_code}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_code: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_code">
                        Process {" "}
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                  </Form.Group>
                  {error.wld_code.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "42px" }}>
                        {error.wld_code.message}
                      </span>{" "}
                    </Form.Text>
                  )}
                </Col>
                {/* <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_p_category"
                        name="wld_p_category"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_p_category.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WeldingProcessObj.wld_p_category}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_p_category: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_p_category">
                        Process Category
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                  </Form.Group>
                  {error.wld_p_category.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "32px" }}>
                        {error.wld_p_category.message}
                      </span>{" "}
                    </Form.Text>
                  )}
                </Col> */}
                <Col md={4} style={{position:"relative", right:"5px"}}>
            <Form.Group className="ListW-input-select">
              <div className="search-select">
                <SearchSelectWIProject
                  id="wld_p_category"
                  name="wld_p_category"
                  className={""}
                  stylegreen={normalstyle}
                  data={Process_Cat}
                  stylenormal={normalstyle}
                  valueField={"value"}
                  value={WeldingProcessObj?.wld_p_category}
                  labelField={"name"}
                  placeholder=""
                  onChange={(ProjectList) => {
                    setWeldingProcessObj((prev) => ({
                      ...prev,
                      wld_p_category: ProjectList.value,
                    }));
                    // if(ProjectList.value === "PDF"){
                    //   alert("PDF is work in progress")
                    // }
                  }}


                 
                ></SearchSelectWIProject>
              </div>
              <label> Process Category</label>
             
            </Form.Group>
          </Col>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_description"
                        name="wld_description"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_description.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={WeldingProcessObj.wld_description}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_description: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_description">
                        Process Description
                      </label>
                    </div>
                  </Form.Group>
                  {error.wld_description.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "10px" }}>
                        {error.wld_description.message}
                      </span>{" "}
                    </Form.Text>
                  )}
                </Col>
                <Col md={4}>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        {" "}
                        <label
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            color: "var(--col-707070)",
                            fontWeight: "bold",
                          }}
                          class="required-field"
                        >
                          Status{" "}
                        </label>
                      </Col>
                      <Col>
                        {" "}
                        <Form.Switch
                          label={
                            WeldingProcessObj.wld_status ? "Active" : "Inactive"
                          }
                          className={
                            WeldingProcessObj.wld_status ? "green" : "gray"
                          }
                          name="wld_status"
                          style={{ padding: "10px" }}
                          checked={WeldingProcessObj.wld_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

         
          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditWeldingProcessMaster;
