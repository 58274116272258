import {

  BrowserRouter,

} from "react-router-dom";

import React, { useState,  useRef } from "react";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";

import SideBar from "./SideBar/SideBar";
import Header from "./Header";
import hamburger from "../icons/hamburger.svg";
// import logo from "../icons/GCEM_Logo.svg";
import "../Assests/Css/header.css";

const Home = (props) => {
  var mobile = window.matchMedia("(max-width: 600px)").matches;

  const [isOpen, setIsOpen] = useState(false);
  const [mobileOpen, setmobileOpen] = useState(mobile);

  const childRef = useRef();

  function openmenu() {
    childRef.current.mobiletoggle();
    setIsOpen(!isOpen);
  }

  function closemenu() {
    setIsOpen(false);
  }

  // useEffect( () => {
  //   
  //   var mobile = window.matchMedia("(max-width: 600px)").matches;
  //   console.log("change first path",window.location.pathname)
  //   if(mobile && !window.location.pathname == "/"){
  //     console.log("change 2 ",isOpen)
  //     setIsOpen(!isOpen)
  //   }

  //   //}
  //  }
  //  ,[window.location.pathname])

  return (
    <>
      <BrowserRouter >
        <div className="Main">
          <div className="main">
            <SideBar ref={childRef} closemenu={closemenu} />
          </div>

          <div
            className="head"
            style={{ display: !isOpen ? "inline-block" : "none" }}
          >
            <div className="mobilelogo">
              <div
                class="hamburger"
                style={{
                  display: mobileOpen ? (!isOpen ? "block" : "none") : "none",
                }}
              >
                <img src={hamburger} onClick={openmenu} />
              </div>
              <div className="logo">
                {/* <img src={logo} /> */}
              </div>
            </div>

            <Header />
          </div>
          <div
            className="blurrOverlay"
            style={{ display: isOpen ? "block" : "none" }}
            onClick={openmenu}
          ></div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default Home;
