import { useEffect, useState } from "react";
import { getClosureRepairWorkflow } from "../../Services/weldingRepairAnalysis-service";
import {
  Accordion,
  Alert,
  Badge,
  Col,
  Form,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import Track from "../../icons/Track.svg";
import DownArrow from "../../icons/DownArrow.svg";
import { isAlphanumericWithSpecialChars } from "../../functions/validations";
import { getInitials, formatDateTimeAMPM } from "../../functions/welding-functions";

const ClosureRemarksWeldingRepair = (props) => {
  console.log("propsClosureRemarks", props.rowData);
  const [closureremarksobj, setClosureRemarksObj] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState(props.errorData);

  let levelCountForWeldingRepair = [
    { levelName: "1", approverDesignation: "Welding Engineer", approverPendingWith: "WE" },
    { levelName: "2", approverDesignation: "QC Engineer", approverPendingWith: "QCE" },
    { levelName: "3", approverDesignation: "NDE", approverPendingWith: "NDE" },
  ];


  let Role = sessionStorage.getItem("Role");

  const [loopCount, setLoopCount] = useState(1);

  const getAllRecords = async () => {
    let tmpObj = { fieldValue: props.rowData.reportNumber, flag: "All" };
    const Records = await getClosureRepairWorkflow(tmpObj);
    let currRevision = [];
    if (Records?.length > 0) {
      setClosureRemarksObj(Records);
      currRevision = Records.filter(
        (item) => item.revisionNumber === props.rowData.workflowRevisionNumber
      );
      setCollapse({ activeItem: props.rowData.workflowRevisionNumber });
      if (props.rowData.approvalPendingWith === "Supervisor") {
        if (currRevision[0].repairReqApprover1Status === "Rejected") {
          setLoopCount(2);
        }
        else if (currRevision[0].repairReqApprover2Status === "Rejected") {
          setLoopCount(3);
        }
        else if (currRevision[0].repairReqApprover3Status === "Rejected") {
          setLoopCount(4);
        }
      }
    }
  };

  const handleCollapsible = (collpasedItem) => {
    if (collapse.activeItem === collpasedItem) {
      setCollapse({ activeItem: -1 });
    } else {
      setCollapse({ activeItem: collpasedItem });
    }
  };

  function handleChange(e, currentClosureObj, currentWeldObj, levelnm) {
    setError((prevState) => ({
      ...prevState,
      ["repairReqApprover" + levelnm + "Status"]: { status: false, message: "" }
    }));
    props.remarksData(e, currentClosureObj, currentWeldObj, levelnm);
    
  }


  console.log("ffjfjfjfjfjfjf",)

  useEffect(() => {
    if (props.rowData.approvalPendingWith === "WE") {
      setLoopCount(2);
    }
    else if (props.rowData.approvalPendingWith === "QCE") {
      setLoopCount(3);
    }
    else if (props.rowData.approvalPendingWith === "NDE") {
      setLoopCount(4);
    }
    getAllRecords();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 1000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <Accordion
        defaultActiveKey="1"
        style={{
          marginTop: "20px"
        }}
      >
        {closureremarksobj?.map((value, index) => {
          return (
            <Accordion.Item eventKey={index}
              style={{
                backgroundColor: "white",
                marginBottom: "2rem"
              }}
              key={index}
            >
              <Accordion.Header className={
                collapse?.activeItem === index
                  ? `dispaly-none accordion-header`
                  : `accordion-header`
              }
                onClick={() => handleCollapsible(index)}
              >
                <Image
                  src={Track}
                  alt="Track"
                  className="profile-initials top-3 "
                  style={{position:"relative", left:"4.2%"}}
                ></Image>
                <label
                  className="remarkLabel size-20"
                  style={{position:"relative",left:"60px"}}
                >
                  Track Report - Rev.{index}
                </label>
                <Image
                  src={DownArrow}
                  alt="DownArrow"
                  className="blue-arrow downarrow"
                  style={{position:"relative",left:"40px"}}

                ></Image>
              </Accordion.Header>
              <Accordion.Body
                className={
                  collapse?.activeItem !== index ? `dispaly-none` : `show-block`
                }
              >
                <Form>
                  <ListGroup as="ol">
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start" 
                      style={{borderLeft:"none",borderTop:"none",borderRight:"none"}}
                      >
                      <div
                        className={"profile-initialsClosure "}
                        style={{ marginLeft: "-0.4%" }}>
                        {getInitials(
                          value.repairSupervisorName
                        )}
                      </div>
                      <div className="ms-5 me-auto">
                        <div className="fw-bold">
                          {value.repairSupervisorName}
                        </div>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            paddingTop: "122px",
                          }}>
                          Supervisor
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          paddingRight: "32px",
                        }}
                      >
                        {formatDateTimeAMPM(value.repairSupervisorCreatedOn)}
                      </span>
                      <Badge bg="light" style={{ color: "black" }} >
                        Initiated
                      </Badge>
                    </ListGroup.Item>

                    {levelCountForWeldingRepair.map((ind, rowNo) => {
                      console.log("levelCountForWeldingRepair",levelCountForWeldingRepair)
                      return (
                        ((ind.levelName < loopCount && index === closureremarksobj.length - 1)
                          || (index < closureremarksobj.length - 1))
                        &&
                        value[
                        "repairReqApprover" +
                        (ind.levelName - 1) +
                        "Status"
                        ] !== "Rejected" && value[
                        "repairReqApprover" +
                        (ind.levelName - 2) +
                        "Status"
                        ] !== "Rejected"
                        &&
                        (
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                            // style={{border:"none"}}
                            style={{borderLeft:"none",borderTop:"none",borderRight:"none"}}

                          >
                            {" "}
                            <div
                              className={"profile-initialsClosure "}
                              style={{ marginLeft: "-0.4%" }}
                            >
                              {getInitials(
                                value["repairReqApprover" + ind.levelName]
                              )}
                            </div>
                            <div className="ms-5 me-auto" style={{ width: "20%" }}>
                            {Role === props.rowData.approvalPendingWith || value["repairReqApprover" + ind.levelName + "Status" ] === "Approved" ?
                              <div className="fw-bold">
                                {value["repairReqApprover" + ind.levelName]}
                              </div>
                               : <> <div className="fw-bold">
                             Pending With
                             </div></>
                                }
                              <div style={{ marginBottom: "10px" }}>
                                <span style={{ fontSize: "12px", color: "grey" }}>
                                  {ind.approverDesignation}
                                </span>
                              </div>
                              {
                                (index === closureremarksobj.length - 1)
                                  &&
                                  props.rowData.approvalPendingWith === ind.approverPendingWith
                                  && props.action !== "View"
                                  ? (
                                    <>
  {Role === props.rowData.approvalPendingWith ?

                                      <Form.Group className="g-input">
                                        <div style={{ width: "400%" }}>
                                          <input
                                            id={
                                              "repairReqApprover" +
                                              ind.levelName +
                                              "Comment"
                                            }
                                            name={
                                              "repairReqApprover" +
                                              ind.levelName +
                                              "Comment"
                                            }
                                            type="text"
                                            className={
                                              error["repairReqApprover" +
                                                ind.levelName +
                                                "Comment"]
                                                .status
                                                ? "placeHolderInput inputText text-field-with-error"
                                                : "placeHolderInput inputText"
                                            }
                                            placeholder=" "
                                            onChange={(e) => handleChange(e, value, props.rowData, ind.levelName)}
                                            style={{ width: "80%" }}
                                            onBlur={(e) => {
                                              let error =
                                                isAlphanumericWithSpecialChars(
                                                  e.target.value
                                                );
                                              setError((prevState) => ({
                                                ...prevState,
                                                ["repairReqApprover" + ind.levelName + "Comment"]: error,
                                              }));
                                            }}
                                          />
                                          <label htmlFor={
                                            "repairReqApprover" +
                                            ind.levelName +
                                            "Comment"
                                          }
                                          >
                                            Comments
                                          </label>
                                        </div>
                                        &nbsp;&nbsp;
                                        {error["repairReqApprover" +
                                          ind.levelName +
                                          "Comment"].status && (
                                            <Form.Text className="text-danger" style={{fontSize:"12px", position:"relative",right:"10px"}}>
                                              {
                                                error["repairReqApprover" +
                                                  ind.levelName +
                                                  "Comment"]
                                                  .message
                                              }
                                            </Form.Text>
                                          )}
                                      </Form.Group>
                                      : ""
                                            }
                                      {Role === props.rowData.approvalPendingWith ?
                                      <>
                                      <div className="m-input" style={{position:"relative", bottom:"10px"}}>
                                        <div className={
                                          error["repairReqApprover" +
                                            ind.levelName +
                                            "Status"]
                                            .status
                                            ? "placeHolderInput inputText text-field-with-error d-flex"
                                            : "placeHolderInput inputText d-flex"
                                        }>
                                          <input
                                            id={
                                              "repairReqApprover" +
                                              ind.levelName +
                                              "Status"
                                            }
                                            type="radio"
                                            name={
                                              "repairReqApprover" +
                                              ind.levelName +
                                              "Status"
                                            }
                                            value="Approved"
                                            onChange={(e) => handleChange(e, value, props.rowData, ind.levelName)}
                                          />
                                          <span>&nbsp;Approve</span>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <input
                                            id={
                                              "repairReqApprover" +
                                              ind.levelName +
                                              "Status"
                                            }
                                            type="radio"
                                            name={
                                              "repairReqApprover" +
                                              ind.levelName +
                                              "Status"
                                            }
                                            value="Rejected"
                                            onChange={(e) =>
                                              handleChange(e, value, props.rowData, ind.levelName)
                                            }
                                          />
                                          <span>&nbsp;Reject</span>
                                        </div>
                                        <div className="mt-1">
                                          {error["repairReqApprover" +
                                            ind.levelName +
                                            "Status"].status && (
                                              <Form.Text className="text-danger" style={{fontSize:"12px"}}>
                                                {
                                                  error["repairReqApprover" +
                                                    ind.levelName +
                                                    "Status"]
                                                    .message
                                                }
                                              </Form.Text>
                                            )}
                                        </div>
                                      </div>
                                      </> 
                                      :""}
                                    </>
                                  ) : (
                                    <i style={{ fontSize: "12px", color: "grey" }}>
                                      {value[
                                        "repairReqApprover" +
                                        ind.levelName +
                                        "Comment"
                                      ]
                                      }
                                    </i>
                                  )}
                            </div>
                            <div className="ms-2 me-auto"></div>
                            <span
                              style={{
                                fontSize: "12px",
                                color: "grey",
                                paddingRight: "32px",
                              }}
                            >
                              {value[
                                "repairReqApprover" +
                                ind.levelName +
                                "CreatedOn"
                              ] === "" ? "" :
                                formatDateTimeAMPM(value[
                                  "repairReqApprover" +
                                  ind.levelName +
                                  "CreatedOn"
                                ])
                              }
                            </span>
                            <Badge
                              style={{
                                position: "relative",
                                color:
                                  value[
                                    "repairReqApprover" +
                                    ind.levelName +
                                    "Status"
                                  ] === "Approved"
                                    ? "var(--godrej-green-alt)"
                                    : value[
                                      "repairReqApprover" +
                                      ind.levelName +
                                      "Status"
                                    ] === "In-Progress"
                                      ? "var(--godrej-orange)"
                                      : "var(--godrej-red)",

                                      background: 
                                      value[
                                        "repairReqApprover" +
                                          ind.levelName +
                                          "Status"
                                      ] === "Approved"
                                        ? "var(--godrej-green-bg)"
                                        : value[
                                            "repairReqApprover" +
                                              ind.levelName +
                                              "Status"
                                          ] === "In Progress"
                                        ? "var(--godrej-orange-bg)"
                                        : "var(--godrej-red-bg)"
                              }}

                              bg={
                                value[
                                  "repairReqApprover" +
                                  ind.levelName +
                                  "Status"
                                ] === "Approved"
                                  ? "sucessapproved"
                                  : value[
                                    "repairReqApprover" +
                                    ind.levelName +
                                    "Status"
                                  ] === "In-Progress"
                                    ? "inprogressapproved"
                                    : "rejctedapproved"
                              }
                            >
                              {
                                value[
                                "repairReqApprover" +
                                ind.levelName +
                                "Status"
                                ]
                              }
                            </Badge>
                          </ListGroup.Item>
                        )
                      );
                    })}
                  </ListGroup>
                </Form>
                <Row style={{ alignContent: "right" }}>
                  <Col style={{ padding: "0" }}>
                    <div className="container history-box"></div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}

      </Accordion >

      <Row>
        {alertMessage && (
          <Alert className="alertBox" variant="warning">
            {alertMessage}
          </Alert>
        )}
      </Row>
    </>
  );
};
export default ClosureRemarksWeldingRepair;
