import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {  isAlphanumericAllCharacters } from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
export const WPS_Thickness_overlay = ({
  headerObj,
  error,
  isReadonly,
  handleChange,
  setError,
}) => {
  console.log("WPS_Thickness_overlay", isReadonly);
  return (
    <Table className="text-center">
      <thead>
        <tr className="border thead-color ">
          <th className="thickness_title"></th>
          <th colSpan={2} className="thickness_title">
            {headerObj.wld_TRQ_Type === "PWHT" ? "With PWHT" : "As Welded"}
          </th>
        </tr>
        <tr className="border thead-color ">
          <th className="thickness_title"></th>
          <th className="thickness_title">Min</th>
          <th className="thickness_title">Max</th>
        </tr>
      </thead>

      <tbody>
        <tr
          className={
            error.WPSH_TRQ_BaseMetalCRO_Min &&
            (error.WPSH_TRQ_BaseMetalCRO_Min.status ||
              error.WPSH_TRQ_BaseMetalCRO_Min.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPSH_TRQ_BaseMetalCRO_Min &&
              (error.WPSH_TRQ_BaseMetalCRO_Min.status ||
                error.WPSH_TRQ_BaseMetalCRO_Max.status)
                ? "text-start error-head"
                : "text-start"
            }
          >
            <div>
              <label className="Groove_green">Base Metal CRO</label>
              {error.WPSH_TRQ_BaseMetalCRO_Min &&
                (error.WPSH_TRQ_BaseMetalCRO_Min.status ||
                  error.WPSH_TRQ_BaseMetalCRO_Max.status) && (
                  <Tooltip
                    content={
                      error.WPSH_TRQ_BaseMetalCRO_Min.message ||
                      error.WPSH_TRQ_BaseMetalCRO_Max.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="Thickness_data">
            <input
              type="text"
              className={
                error.WPSH_TRQ_BaseMetalCRO_Min &&
                error.WPSH_TRQ_BaseMetalCRO_Min.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_TRQ_BaseMetalCRO_Min"}
              maxLength={6}
              value={headerObj.WPSH_TRQ_BaseMetalCRO_Min}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_TRQ_BaseMetalCRO_Min: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_TRQ_BaseMetalCRO_Max &&
                error.WPSH_TRQ_BaseMetalCRO_Max.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_TRQ_BaseMetalCRO_Max"}
              maxLength={6}
              value={headerObj.WPSH_TRQ_BaseMetalCRO_Max}
              readOnly={isReadonly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_TRQ_BaseMetalCRO_Max: error,
                }));
              }}
            />
          </td>
        </tr>
        <tr
          className={
            error.WPSH_TRQ_BaseMetalCRO_Min &&
            (error.WPSH_TRQ_BaseMetalCRO_Min.status ||
              error.WPSH_TRQ_BaseMetalCRO_Max.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPSH_TRQ_BaseMetalCRO_Min &&
              (error.WPSH_TRQ_BaseMetalCRO_Min.status ||
                error.WPSH_TRQ_BaseMetalCRO_Max.status)
                ? "text-start error-head"
                : "text-start"
            }
          >
            <div>
              Base Metal HRO
              {error.WPSH_TRQ_BaseMetalHRO_Min &&
                (error.WPSH_TRQ_BaseMetalHRO_Min.status ||
                  error.WPSH_TRQ_BaseMetalHRO_Max.status) && (
                  <Tooltip
                    content={
                      error.WPSH_TRQ_BaseMetalHRO_Min.message ||
                      error.WPSH_TRQ_BaseMetalHRO_Max.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td>
            <input
              type="text"
              className={
                error.WPSH_TRQ_BaseMetalHRO_Min &&
                error.WPSH_TRQ_BaseMetalHRO_Min.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPSH_TRQ_BaseMetalHRO_Min"}
              maxLength={6}
              value={headerObj.WPSH_TRQ_BaseMetalHRO_Min}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_TRQ_BaseMetalHRO_Min: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_TRQ_BaseMetalHRO_Max &&
                error.WPSH_TRQ_BaseMetalHRO_Max.status
                  ? "error-input"
                  : ""
              }
              readOnly={isReadonly}
              name={"WPSH_TRQ_BaseMetalHRO_Max"}
              maxLength={6}
              value={headerObj.WPSH_TRQ_BaseMetalHRO_Max}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_TRQ_BaseMetalHRO_Max: error,
                }));
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
