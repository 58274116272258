import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { checkWPSinArray } from "../../WPSTransaction/WPSObjects/WPS_Validations";
import { uploadWCSexceldata } from "../../../Services/wcs-services";
export const MessageTypes = {
  Success: "Success",
  Fail: "Failure",
  Required: "Required",
  RequiredAll: "RequiredAll",
  Temp: "Temp",
  Band: "Band",
  None: "None",
};

export const Action = {
  ActionEdit: "Edit",
  ActionCopy: "Copy",
  ActionAdd: "Add",
  ActionUpload: "Upload",
  ActionDelete: "Delete",
  ActionApprove: "Approve",
  ActionReject: "Reject",
  ActionSend: "Send",
};

export const Status = {
  StatusPending: "Pending For Approval",
  StatusApproved: "Approved",
  StatusRejected: "Rejected",
  StatusTempSaved: "Temporary Saved",
};

export const validateFile = (file) => {
  //const validateFile = (file) => {
  
  let error = "";
  let fileName = file.name;
  let fileExtn =
    fileName.indexOf(".") > 0
      ? fileName.split(".").pop().toLowerCase()
      : "undefined";
  let fileSize = file.size;
  let allowedExtensions = process.env.REACT_APP_FILE_EXTENSIONS;
  if (fileName.match(/\./g).length !== 1) {
    error = error + "File name contains more than one dot. ";
  }
  if (!allowedExtensions.includes(fileExtn)) {
    error = error + "Unsupported file format. ";
  }
  if (fileSize > 5242880) {
    error = error + "File size is greater than 5MB.";
  }
  return error;
};

export const handleExcelUpload = async (
  wcsHeader,
  props,
  excelData,
  showForm,
  setShowForm
) => {
  let result;
  console.log("Upload excel data");
  wcsHeader.SK = props.selectProject;
  let uploadExcelRequest = { excelData, wcsHeader };
  result = await uploadWCSexceldata(uploadExcelRequest);
  console.log("result_await", result);
  if (result?.status) {
    toast.success(`Selected WCS file data has been Uploaded successfully`, {
      position: toast.POSITION.TOP_LEFT,
      //className: "toast-message",
    });
    props.setReload(!props.reload);
    setShowForm(false);
    props.onHide();
  } else {
    toast.error(`Selected WCS file data is not Uploaded`, {
      position: toast.POSITION.TOP_LEFT,
      //className: "toast-message",
    });
  }
};

export const handleDeleteDocument = async (
  file,
  setFile,
  fileName,
  setFileName,
  isExcelUploaded,
  setIsExcelUploaded,
  disableMnlEntry,
  setdisableMnlEntry
) => {
  setFileName("");
  setFile([]);
  setIsExcelUploaded(false);
  setdisableMnlEntry(false);
};

function createAndDownloadReport(uploadReport, fileName) {
  
  const finalUploadReport = uploadReport.map((item) => ({
    "Project Number": item.WCS_Project_No,
    "Joint Number": item.WCS_Joint_No,
    "WPS Number 1": item.WCS_WPS_No1,
    "WPS Number 2": item.WCS_WPS_No2,
    "WPS Number 3": item.WCS_WPS_No3,
    "WPS Number 4": item.WCS_WPS_No4,
    Message: item.Message,
    //'Status': item.Status,
  }));
  const ws = XLSX.utils.json_to_sheet(finalUploadReport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, fileName);
}

export const handleFileSelect = async (
  files,
  setFileName,
  setFile,
  //createAndDownloadReport,
  setIsExcelUploaded,
  setdisableMnlEntry,
  setexcelData,
  selectProject,
  setError
) => {
  let isError = false;

  console.log("sagar_files", files);
  const newFile = files[0];
  var name = newFile.name;

  let fileError = validateFile(newFile);
  if (fileError !== "") {
    toast.error(fileError, {
      position: toast.POSITION.TOP_LEFT,
      //className: "toast-message",
    });
    return;
  }
  let fileErr = false;
  const reader = new FileReader();
  reader.onload = async (e) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    console.log("wsname", wsname);

    const ws = wb.Sheets[wsname];

    console.log("ws", ws);
    console.log(
      "Beforeeee",
      ws["A1"]?.v,
      ws["B1"]?.v,
      ws["C1"]?.v,
      ws["D1"]?.v,
      ws["E1"]?.v,
      ws["F1"]?.v
    );
    if (
      ws["A1"]?.v &&
      ws["B1"]?.v &&
      ws["C1"]?.v &&
      ws["D1"]?.v &&
      ws["E1"]?.v &&
      ws["F1"]?.v &&
      ws["A1"]?.v === "Project Number" &&
      ws["B1"]?.v === "Joint Number" &&
      ws["C1"]?.v === "WPS Number 1" &&
      ws["D1"]?.v === "WPS Number 2" &&
      ws["E1"]?.v === "WPS Number 3" &&
      ws["F1"]?.v === "WPS Number 4"
    ) {
      console.log(
        "proper ,all column",
        ws["A1"]?.v,
        ws["B1"]?.v,
        ws["C1"]?.v,
        ws["D1"]?.v,
        ws["E1"]?.v,
        ws["F1"]?.v
      );
    } else {
      toast.error("Columns were not in order or blank in excel .", {
        position: toast.POSITION.TOP_LEFT,
      });
      return;
    }

    /* Convert to json */
    const tempData = XLSX.utils.sheet_to_json(ws);

    const mappedData = tempData.map((item) => ({
      WCS_Project_No: item["Project Number"],
      WCS_Joint_No: item["Joint Number"],
      WCS_WPS_No1: item["WPS Number 1"],
      WCS_WPS_No2: item["WPS Number 2"],
      WCS_WPS_No3: item["WPS Number 3"],
      WCS_WPS_No4: item["WPS Number 4"],
      PK: item["Project Number"],
      SK: item["Joint Number"],
      WCS_PreparedBy: sessionStorage.getItem("User"),
      WCS_ChangeDetail_No: 0,
    })); //row number to be add
    ///=============================================
    console.log("mappedData_sagar", mappedData);
    if (mappedData <= 0) {
      toast.error("Please select file with valid data.", {
        position: toast.POSITION.TOP_LEFT,
        //className: "toast-message",
      });
      return;
    }
    if (mappedData.length > 1000) {
      toast.error(
        "Limit of excel upload option is 1000. Please upload records within limit.",
        {
          position: toast.POSITION.TOP_LEFT,
          //className: "toast-message",
        }
      );
      return;
    }
    const uploadExcelReport = [];
    let arr_list = [];
    var wps1Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No1 };
    });
    var wps2Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No2 };
    });
    var wps3Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No3 };
    });
    var wps4Arr = mappedData.map(function (item) {
      return { wps: item.WCS_WPS_No4 };
    });
    const allWpsArray = [...wps1Arr, ...wps2Arr, ...wps3Arr, ...wps4Arr];
    let wpsValueArr1 = [
      ...new Map(allWpsArray.map((item) => [item.wps, item])).values(),
    ];
    let wpsArr1 = wpsValueArr1.filter(({ wps }) => wps);

    //   console.log("GrooveType", GrooveType);
    let wpsData1 = {
      // project: props.selectProject,
      project: selectProject,
      WPSNo_Array: wpsArr1,
      //groovetype: GrooveType,
    };
    console.log("validWPS1",wpsData1);
    let validWPSArr = await checkWPSinArray(wpsData1);
    console.log("validWPS1", validWPSArr);
    /* console.log("wpsData:", wpsData1);
    // let invalidWPSArr = await checkValidWPS(wpsData1);
    let invalidWPSArr = await checkWPS(wpsData1); */
    

    for (const dataObject of mappedData) {
      const notUploadReason = [];
      // if (dataObject.WCS_Project_No !== props.selectProject) {
      if (dataObject.WCS_Project_No !== selectProject) {
        notUploadReason.push(
          "Project Number not present in Project Master or multiple project numbers found."
        );
        fileErr = true;
      }
      if (
        dataObject.WCS_Project_No === "" ||
        dataObject.WCS_Project_No === undefined ||
        dataObject.WCS_Joint_No === "" ||
        dataObject.WCS_Joint_No === undefined ||
        dataObject.WCS_WPS_No1 === "" ||
        dataObject.WCS_WPS_No1 === undefined
      ) {
        fileErr = true;
        notUploadReason.push(
          "Mandatory data in Project Number, Joint Number and WPS Number 1 not present in at least one row. Cannot upload data."
        );
      }
      var duplicateJointArr = mappedData.filter(
        (item) => item.WCS_Joint_No === dataObject.WCS_Joint_No.toString()
      );
      
      if (duplicateJointArr.length > 1) {
        fileErr = true;
        notUploadReason.push(
          "Duplicate combination of project number and joint number. Cannot upload data."
        );
      }
      let wpsErr1 = true,
        wpsErr2 = true,
        wpsErr3 = true,
        wpsErr4 = true;
      
      //console.log("invalidWPSArr_bfore", validWPSArr);
      if (validWPSArr?.length > 0) {
        
        if (!dataObject.WCS_WPS_No1 != undefined) {
          validWPSArr.some((element) => {
            console.log(
              "wpserr1_2",
              element,
              dataObject.WCS_WPS_No1,
              element.trim() === dataObject.WCS_WPS_No1.toString()
            );
            if (element.trim() === dataObject.WCS_WPS_No1.toString()) {
              console.log("wpserr1_3", element, dataObject.WCS_WPS_No1);
              wpsErr1 = false;
              return;
            }
          });
        } else {
          wpsErr1 = false;
        }
        console.log("wpserr1", wpsErr1, dataObject.WCS_WPS_No1);

        if (dataObject.WCS_WPS_No2 != undefined) {
          validWPSArr.some((element) => {
            console.log(
              "wpserr2_1",
              element === String(dataObject.WCS_WPS_No2)
            );
            if (element === dataObject.WCS_WPS_No2.toString()) wpsErr2 = false;
            return;
          });
        } else {
          wpsErr2 = false;
        }
        console.log("wpserr2", dataObject.WCS_WPS_No2, wpsErr2);
        if (dataObject.WCS_WPS_No3 != undefined) {
          validWPSArr.some((element) => {
            if (element === dataObject.WCS_WPS_No3.toString()) wpsErr3 = false;
            return;
          });
        } else {
          wpsErr3 = false;
        }
        console.log("wpserr3", dataObject.WCS_WPS_No3, wpsErr3);
        if (dataObject.WCS_WPS_No4 != undefined) {
          validWPSArr.some((element) => {
            if (element === dataObject.WCS_WPS_No4.toString()) wpsErr4 = false;
            return;
          });
        } else {
          wpsErr4 = false;
        }
        //  console.log("ErrorInFile",wpsErr,err)
        console.log("wpserr4", dataObject.WCS_WPS_No4, wpsErr4);
      }

      console.log(
        "Errorfile",
        dataObject.WCS_WPS_No1,
        wpsErr1,
        wpsErr1 || wpsErr2 || wpsErr3 || wpsErr4
      );
      if (wpsErr1 || wpsErr2 || wpsErr3 || wpsErr4) {
        fileErr = true;
        let msg = "";
        if (wpsErr1) msg += "WPS1, ";
        if (wpsErr2) msg += "WPS2, ";
        if (wpsErr3) msg += "WPS3, ";
        if (wpsErr4) msg += "WPS4, ";
        notUploadReason.push(msg + " not valid.");
      }
      
      const wpsArr = [
        dataObject.WCS_WPS_No1,
        dataObject.WCS_WPS_No2,
        dataObject.WCS_WPS_No3,
        dataObject.WCS_WPS_No4,
      ];
      const filteredWPSArr = wpsArr.filter(
        (item) => item !== undefined && item !== null
      );
      // console.log("filteredWPSArr", filteredWPSArr);
      const duplicateWPSNo = filteredWPSArr.filter(
        (item, index) => wpsArr.indexOf(item) !== index
      );
      //console.log("duplicateWPSNo", duplicateWPSNo);
      //
      if (duplicateWPSNo.length > 0) {
        // console.log("duplicateWPSNo.length", duplicateWPSNo.length);
        //console.log("duplicateWPSNo_inside", duplicateWPSNo);
        fileErr = true;
        notUploadReason.push("WPS number is repeated.");
      }
      
      dataObject.Message = notUploadReason.join(", ");
      uploadExcelReport.push(dataObject);
      console.log("Errorfile", dataObject.Message);
    }
    
    //console.log("ErrorInFile",fileErr)
    if (fileErr) {
      setFileName("");
      setFile([]);
      console.log("File Error In Validate");
      createAndDownloadReport(uploadExcelReport, "WCSExcelUploadReport.xlsx");
      toast.warning(
        "Error while uploading file, Please check downloaded file.",
        {
          position: toast.POSITION.TOP_LEFT,
          //className: "toast-message",
        }
      );
    } else {
      setFile(newFile);
      setFileName(name);
      setIsExcelUploaded(true);
      setdisableMnlEntry(true);
      console.log("mappedData  - ", mappedData);
      setexcelData(mappedData);
    }
  };

  reader.readAsBinaryString(newFile);
  setError(isError);
  return isError;
};
