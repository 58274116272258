import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "./grid-slice";
import menuSlice from "./menu-slice";
import roleSlice from "./role-slice";

const store = configureStore({
  reducer: {
    role: roleSlice.reducer,
    menu: menuSlice.reducer,
    grid: searchSlice.reducer,
  },
});

export default store;
