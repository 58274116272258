import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import SuccessAlertModal from "../../Modals/successAlertModal";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { MDBInput } from "mdb-react-ui-kit";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumericWithDot,
  isAlphanumericWithSpaceandDash,
  isNumberwithdashdot
} from "../../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../../utility/FormControl";
import ButtonModal from "../../Modals/buttonModal";
import { addTraining, updateTrainingRecord } from "../../../Services/trainingRecord-service";

const AddEditAttendanceDetails = (props) => {
  console.log("jdjdjdjddj",props.rowdata)
  // if (props.rowdata.wld_status === "Active") {
  //   props.rowdata.wld_status = true;
  // } else if (props.rowdata.wld_status === "Inactive") {
  //   props.rowdata.wld_status = false;
  // }

  let action = props.action;
  let formName = "Attendance Details";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  console.log("username is -- ", username);
  console.log("action ", action);

  
  // const [attendance, setAttendance] = useState('');
  const [TrainingObj, setTrainingObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
        wld_marks:"",
        wld_percentage:"",
        wld_score:"",
        wld_attendance:TrainingObj.wld_attendance,
        
          
        }
  );
  console.log("wld_attendance",TrainingObj);
  
  const [error, setError] = useState({
    // SK: {},
    // PK: {},
    wld_marks: {},
    wld_percentage: {},
    wld_score: {},
    wld_attendance: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setTrainingObj",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    console.log("eeeeeee",e.target.name)
    const { name, value } = e.target;
    setTrainingObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
      // wld_marks: {},
    }));
  };



  const handleAttendanceChange = (e) => {
    const { name, value } = e.target;
   
    if (e.target.value === 'Absent') {
      setTrainingObj((prevState) => ({
        ...prevState,
        wld_attendance:e.target.value,
        wld_score: '',
        wld_marks: '',
        wld_percentage: ''  
      }));
    }
    else{
      setTrainingObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }; 

  const resetForm = () => {
      setTrainingObj((prevState) => ({
        ...prevState,
        wld_marks:"",
        wld_percentage:"",
        wld_score:"",
        wld_attendance:"",
      }));
    // } else if (action === "Edit") {
    //   setTrainingObj((prevState) => ({
    //     ...prevState,
    //     // PK: props.rowdata.PK,
    //     // SK: props.rowdata.SK,
    //     wld_percentage: props.rowdata.wld_percentage,
    //     wld_marks: props.rowdata.wld_marks,
    //     wld_score: props.rowdata.wld_score,
    //     wld_attendance: props.rowdata.wld_attendance,
    //     // wld_createdon: props.rowdata.wld_createdon,
    //   }));
    // }
    setError((prevState) => ({
      ...prevState,
      wld_marks: {},
      wld_percentage: {},
      wld_score: {},
      wld_attendance: {},
      // SK: {},
    }));
  };

  const validateAttendance = (action) => {
    let result = false;
    let isError = false;
    if(TrainingObj.wld_attendance === "Present"){
    if (TrainingObj.wld_marks.trim() === "") {
      TrainingObj.wld_marks = "";
      isError = true;
      error.wld_marks = {
        status: true,
        message: "Marks is required.",
      };
    }

    if (TrainingObj.wld_score.trim() === "") {
      TrainingObj.wld_score = "";
      isError = true;
      error.wld_score = {
        status: true,
        message: "Score is required.",
      };
    }

    if (TrainingObj.wld_attendance === "") {
      isError = true;
      error.wld_attendance = {
        status: true,
        message: "Attendance is required.",
      };
    }

    if (action === "Edit") {
      
      console.log("TrainingObj -- ", TrainingObj);
      result =
        props.allRecs &&
        props.allRecs.find(
          (item) =>
            item.PK.toLowerCase() === TrainingObj.PK.toLowerCase() &&
            item.SK.toLowerCase() === TrainingObj.wld_marks.toLowerCase()
        );
      console.log(result);
    }

    // if (result) {
    //   setAlertMessage("Sfa Combination Already Exists!");
    //   isError = true;
    // }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.wld_marks.status ||
      error.wld_percentage.status ||
      error.wld_attendance.status ||
      error.wld_score.status
    ) {
      isError = true;
    }
    return isError;
  }
  };
  
  const handleAdd = (e) => {
    debugger
    let result;
    if (action === "Update") {
      TrainingObj.wld_marks = TrainingObj.wld_marks;
      TrainingObj.wld_score = TrainingObj.wld_score;
      TrainingObj.wld_percentage = ((TrainingObj.wld_score / TrainingObj.wld_marks) * 100).toFixed(2);
      // TrainingObj.wld_percentage = TrainingObj.wld_percentage;
      TrainingObj.wld_attendance = TrainingObj.wld_attendance;
      result = addTraining(TrainingObj);
    } else {
     
      TrainingObj.wld_marks = TrainingObj.wld_marks;
      TrainingObj.wld_score = TrainingObj.wld_score;
      TrainingObj.wld_percentage =  ((TrainingObj.wld_score / TrainingObj.wld_marks) * 100).toFixed(2);
      // TrainingObj.wld_percentage = TrainingObj.wld_percentage;
      TrainingObj.wld_attendance = TrainingObj.wld_attendance;
      result = updateTrainingRecord(TrainingObj);
    }
    result
      .then((resp) => {
        if (action === "Edit") {
          toast.success(`Selected attendance detail has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success(`Selected attendance detail has been Added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Failed");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var val = await validateAttendance();
    if (!val) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  console.log("formName_Y", formName);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed Attendance detail successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Training Detail</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
              <Col md={4}>
                  <div className="m-input" style={{ width: 400 }}>
                    <span>Attendance</span>
                    <div className="d-flex mb-2">
                     <div>
                        <input
                          type="radio"
                          name="wld_attendance"
                          value='Present'
                          checked={TrainingObj.wld_attendance === 'Present' && "Present"}
                          onChange={handleAttendanceChange}
                          style={{
                            fontSize: 12,
                            border: 20,
                            height: 10,
                          }}
                        />
                        <span
                          style={{ fontSize: 14 }}
                        >
                        Present
                        </span>
                      </div>
                     
                     <div style={{paddingLeft:"30px"}}>
                        <input
                          type="radio"
                          name="wld_attendance"
                          value='Absent'
                          checked={TrainingObj.wld_attendance === 'Absent' && "Absent"}
                          onChange={handleAttendanceChange}
                          // className={`${TrainingObj.wld_attendance} === 'Absent' ? "disabled" : "" `}
                          style={{
                            fontSize: 12,
                            border: 20,
                            height: 10,
                          }}
                        />
                        <span
                          style={{ fontSize: 14 }}
                        >
                          Absent
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
                
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_score"
                        name="wld_score"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        disabled={TrainingObj.wld_attendance === 'Absent'}
                        className={
                          error.wld_score.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={TrainingObj.wld_score}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumberwithdashdot(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_score: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_score">
                        Score
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_score.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "10px", fontSize:"11px" }}>
                          {error.wld_score.message}
                        </span>{" "}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        type="text"
                        id="wld_marks"
                        name="wld_marks"
                        placeholder=" "
                        // disabled={action === "Edit" ? true : false}
                        maxLength="5"
                        disabled={TrainingObj.wld_attendance === 'Absent'}
                        className={
                          error.wld_marks.status
                            ? " g-input placeHolderInput inputText text-field-with-error allow"
                            : "g-input placeHolderInput inputText allow"
                        }
                        value={TrainingObj.wld_marks}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumberwithdashdot(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_marks: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_marks">
                      Total Marks{" "}
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_marks.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "20px", fontSize:"11px"  }}>
                          {error.wld_marks.message}
                        </span>{" "}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_percentage"
                        name="wld_percentage"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        disabled={TrainingObj.wld_attendance === 'Absent'}
                        className={
                          error.wld_percentage.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={TrainingObj.wld_attendance === 'Present' ?((TrainingObj.wld_score / TrainingObj.wld_marks) * 100).toFixed(2) : " "}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumberwithdashdot(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            wld_percentage: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_percentage">
                      Percentage
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                    {error.wld_percentage.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "10px", fontSize:"11px" }}>
                          {error.wld_percentage.message}
                        </span>{" "}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col> 

              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

          <Row>
            <ButtonModal action={action} training={"training"} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditAttendanceDetails;
