import { borderBottom, padding } from "@mui/system";
import React, { useEffect, useState } from "react";
import "../Css/groovePQR_pdf.css";
import PQR_Footer from "./PQR_Footer";
import PageFooter from "../../../WPSTransaction/PDF/Pages/PageFooter";


import TTS_pdf_headerWPQpage3 from "./TTS_pdf_headerPQRpage3";

const WPQTubeToTubesheet_pdf_page3 = (
  { pdf, setpdf, PreviewUrl, previewuser, previewapproved },
  props,
  wld
) => {
  let action = props.action;
  console.log("pdf", pdf);
  const width = "72.6%";
  var cols = 3,
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class;

  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };


  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";

  col1Class = "left7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "left7-td"
        : "left7-td-noright"
      : "tdhidden";
  col3Class = pdf[3] !== undefined ? "left7-td-noright1" : "tdhidden";

  function Tear_Test() {
    const rows = [];

    for (let i = 0; i < pdf.length; i++) {
      const visualExamination = pdf[i]?.Tear_Test || [];
      for (let j = 0; j < visualExamination.length; j++) {
          const item = visualExamination[j];

        rows.push(
          <tr key={`${i}-${j}`}>
            <td className="PQR_rt_lft" style={{whiteSpace:"nowrap"}}>
              {item.PQR_Tear_TubeNo !== "" ? item.PQR_Tear_TubeNo : "--"}
            </td>
            <td className="PQR_right"style={{whiteSpace:"nowrap"}} >
              {item.PQR_Tear_Observation !== "" ? item.PQR_Tear_Observation : "--"}
            </td>
            <td className="PQR_right"style={{whiteSpace:"nowrap"}}>
              {item.PQR_Tear_Results !== "" ? item.PQR_Tear_Results : "--"}
            </td>
          </tr>
        );
      }
    }

    return rows;
  }
 

  function  Pull_out() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.PullOut_Test || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft" style={{whiteSpace:"nowrap"}}>{item.PQR_PullOut_TubeNo !== "" ? item.PQR_PullOut_TubeNo : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}} >{item.PQR_PullOut_Location !== "" ? item.PQR_PullOut_Location : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_PullOut_Load !== "" ? item.PQR_PullOut_Load : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_PullOut_Fracture !== "" ? item.PQR_PullOut_Fracture : '--'}</td>
                   
                </tr>
            );
        }
         }
    return rows;

}
  function  Other_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.Other_Test || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft"style={{whiteSpace:"nowrap"}} >{item.PQR_OT_TypeofTest !== "" ? item.PQR_OT_TypeofTest : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}} >{item.PQR_OT_Acceptance_Criteria !== "" ? item.PQR_OT_Acceptance_Criteria : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_OT_Results !== "" ? item.PQR_OT_Results : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_OT_Remarks !== "" ? item.PQR_OT_Remarks : '--'}</td>
                   
                </tr>
            );
        }
         }
    return rows;

}
  function  Radiography_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.Radiography_Test || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft"style={{whiteSpace:"nowrap"}} >{item.PQR_Radio_TubeNo !== "" ? item.PQR_Radio_TubeNo : '--'}</td>
                    <td className="PQR_right" style={{whiteSpace:"nowrap"}}>{item.PQR_Radio_Acceptance_Criteria !== "" ? item.PQR_Radio_Acceptance_Criteria : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_Radio_Observation !== "" ? item.PQR_Radio_Observation : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_Radio_Results !== "" ? item.PQR_Radio_Results : '--'}</td>
                   
                </tr>
            );
        }
         }
    return rows;

}
  function  Pullout_Test_tab2() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.PullOut_Test_Table || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft"style={{whiteSpace:"nowrap"}} >{item.PQR_PullOut_Acceptance_Criteria !== "" ? item.PQR_PullOut_Acceptance_Criteria : '--'}</td>
                    <td className="PQR_right" style={{whiteSpace:"nowrap"}}>{item.PQR_PullOut_Observation !== "" ? item.PQR_PullOut_Observation : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_PullOut_Results !== "" ? item.PQR_PullOut_Results : '--'}</td>
                  
                </tr>
            );
        }
         }
    return rows;

}
  function  Hardness_Test() {
    const rows = [];
    for (let i = 0; i < pdf.length; i++) {
        const visualExamination = pdf[i]?.Hardness_Test || [];
        for (let j = 0; j < visualExamination.length; j++) {
            const item = visualExamination[j];
            rows.push(
               <tr key={`${i}-${j}`}>

                    <td className="PQR_rt_lft"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_TypeofTest !== "" ? item.PQR_HT_TypeofTest : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}} >{item.PQR_HT_Tube_No !== "" ? item.PQR_HT_Tube_No : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_BM_Tubesheet !== "" ? item.PQR_HT_BM_Tubesheet : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_HAZ_Tubesheet !== "" ? item.PQR_HT_HAZ_Tubesheet : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_Weld !== "" ? item.PQR_HT_Weld : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_HAZ_Tube !== "" ? item.PQR_HT_HAZ_Tube : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_BM_Tube !== "" ? item.PQR_HT_BM_Tube : '--'}</td>
                    <td className="PQR_right"style={{whiteSpace:"nowrap"}}>{item.PQR_HT_Results !== "" ? item.PQR_HT_Results : '--'}</td>
                   
                </tr>
            );
        }
         }
    return rows;

}

  return (
    // ------------------------------------------------------------------------------------//

    <div  className="MainPDF">
      
 
      <TTS_pdf_headerWPQpage3 pdf={pdf}></TTS_pdf_headerWPQpage3>
     
      {/* -------------------------Tear TEST---------------------------------*/}
      <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            TEAR TEST
            </div>
          </div>
           <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "30%"}}>TUBE NO</th>
              <th className="PQR_noleft" style={{ width: "48%"}}>Observation</th>
              <th className="PQR_noleft" style={{ width: "28%"}}>Results</th>
              
            </tr> 

        { Tear_Test()}
        </table>
        <table className="PQRTablePg2" style={{ width: { width } }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_Tear_Notes}</td>
           </tr>
         </table>
      {/* -------------------------Pull out TEST---------------------------------*/}
      <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            PULL OUT TEST
            </div>
          </div>
           <table className="PQRTablePg2" style={{ width:"570px" }}>
            <tr>
              <th className="PQR_border" style={{ width: "15%"}}>Tube No</th>
              <th className="PQR_noleft" style={{ width: "10%"}}>Location</th>
              <th className="PQR_noleft" style={{ width: "10%"}}>Load</th>
              <th className="PQR_noleft" style={{ width: "28%"}}>Fracture</th>
              
            </tr> 

        { Pull_out()}
        </table>
        <table className="PQRTablePg2" style={{ width: { width } }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_PullOut_Notes}</td>
           </tr></table> 
{/* -------------------------------------------------------------------------------------------- */}
<div className="group">
<div className="PQRbasemetal" style={{whiteSpace:"nowrap",wordBreak:"nowrap",width:"130px" }}>
Calculation for Fr value
            </div></div>
          {/* <th className=" PQR_rt_lft PQRthhide" style={{width:"10%"}} >
          Calculation for Fr value
          </th> */}
          {/* <td className={col1Class}  style={{ width: { colwidth } }}>
            {pdf[0]?.PQR_PullOut_Calculation}</td> */}
       <table className="PQRTablePg2" style={{ width: "570px" }}>   
        <tr className="PQRtrhide PQR_noBotm">
          <th className=" PQR_noBotm PQRthhide" style={{ width:"10%" }} >
          Minimum Breaking Load (KN) 
          </th>
          <td className={col1Class}  style={{ width:"10%",whiteSpace:"nowrap",textAlign:"left" }}>
            {pdf[0]?.PQR_PullOut_MinBreakLoad}
          </td></tr>
          <tr className="PQRtrhide PQR_noBotm">
          <th className=" PQR_noBotm PQRthhide" style={{ width:"10%" }} >
          Area of Tube Material (mm2)
          </th>
          <td className={col1Class}  style={{ width:"10%",whiteSpace:"nowrap",textAlign:"left"}}>
            {pdf[0]?.PQR_PullOut_AreaTubeMaterial} 
          </td></tr>
        <tr className="PQRtrhide PQR_noBotm ">
          <th className=" PQR_noBotm PQRthhide" style={{width:"10%"}}>
          Minimum Specified Tensile Strength of Tube(Mpa)
          </th>
          <td className={col1Class}  style={{whiteSpace:"nowrap",textAlign:"left"}}>
            {pdf[0]?.PQR_PullOut_MinTensilStrength}
          </td></tr>
        <tr className="PQRtrhide PQR_noBotm ">
          <th className=" PQR_noBotm PQRthhide" style={{width:"10%"}}>
          Minimum Breaking Stress <br></br>'('=   Minimum Breaking Load in KN <br></br>/ Area of Tube Material in mm2)
          </th>
          <td className={col1Class}  style={{whiteSpace:"nowrap",textAlign:"left" }}>
            {pdf[0]?.PQR_PullOut_MinBreakStress}
          </td></tr>
        <tr className="PQRtrhide PQR_noBotm ">
          <th className=" PQR_noBotm PQRthhide" style={{width:"10%"}}>
          Fr Value (  = Minimum Breaking  Stress /<br></br>  Minimum Tensile Strength of Tube )
          </th>
          <td className={col1Class}  style={{whiteSpace:"nowrap",textAlign:"left"}}>
            {pdf[0]?.PQR_PullOut_FrValue}
          </td></tr>
          
           </table>

           {/* </div> */}
           <div className="group">
          </div>
           <table className="PQRTablePg2" style={{ width: { width } }}>
            <tr>
              <th className="PQR_border" style={{ width: "32%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "48%"}}>Observation </th>
              <th className="PQR_noleft" style={{ width: "32%"}}>Results</th>
              
            </tr> 

        { Pullout_Test_tab2()}
        </table>
        <table className="PQRTablePg2" style={{ width: { width } }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_PullOut_AccCriteria_Notes}</td>
           </tr>
         </table>
  {/* ---------------------------------HARDNESS TEST--------------------------- */}
  <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            HARDNESS TEST
            </div>
          </div>

         <table  className="PQRTablePg2" style={{ width:  "570px"  }}>
            <tr>
              <th className="PQR_border">Type( Scale)</th>
              <th className="PQR_noleft">Tube No</th>
              <th className="PQR_noleft">BM - Tube Sheet</th>
              <th className="PQR_noleft">HAZ - Tube Sheet</th>
              <th className="PQR_noleft">Weld</th>
              <th className="PQR_noleft">HAZ - Tube</th>
              <th className="PQR_noleft">BM - Tube</th>
              <th className="PQR_noleft">Results</th>
            
            </tr> 
    
           
       { Hardness_Test()} 
       </table>
        <table className="PQRTablePg2" style={{ width: "570px"  }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_HT_Notes}</td>
           </tr>
         </table>
  {/* ------------------------------Radiography test---------------------------------------------         */}
  <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            RADIOGRAPHY TEST
            </div>
          </div>
           <table className="PQRTablePg2" style={{ width: "570px"  }}>
            <tr>
              <th className="PQR_border" style={{ width: "25%"}}>Tube No</th>
              <th className="PQR_border" style={{ width: "25%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "28%"}}>Observation</th>
              <th className="PQR_noleft" style={{ width: "22%"}}>Results</th>
              
            </tr> 

        { Radiography_Test()}
        </table>
        <table className="PQRTablePg2" style={{ width: "570px"  }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_Radio_Notes}</td>
           </tr>
         </table>
          
     {/* ------------------------------------other test---------------------------------      */}
          <div className="group">
            <div className="PQRbasemetal" style={{ width: { width } }}>
            OTHER TEST
            </div>
          </div>
           <table className="PQRTablePg2" style={{ width: "570px" }}>
            <tr>
              <th className="PQR_border" style={{ width: "30%"}}>Type of Test</th>
              <th className="PQR_noleft" style={{ width: "23%"}}>Acceptance Criteria</th>
              <th className="PQR_noleft" style={{ width: "23%"}}>Results</th>
              <th className="PQR_noleft" style={{ width: "23%"}}>Remarks</th>
              
            </tr> 

        { Other_Test()}
        </table>
        <table className="PQRTablePg2" style={{ width: "570px"  }}>
           
           <tr>
             <th className="PQR_border" style={{width:"35px"}}>Notes</th>
             <td className="PQR_noleft" style={{textAlign:"left"}}>{pdf[0]?.PQR_OT_Notes}</td>
           </tr>
         </table>
          <p className="PQRcertifont" >We certify that the statement in this record are correct and that the test welds were prepared, welded and tested in the accordance with the requirements of Section IX of the ASME code </p>
          
          <div id="footer" style={{position:"fixed",top:"2985px"}} >
          <PQR_Footer pdf={pdf} previewuser={previewuser} previewapproved={previewapproved}></PQR_Footer>

</div>
<br></br>
<br></br>
<div id="footer" style={{position:"fixed",top:"3075px"}} >
        <table className="WPSfooter">
          <tr>
            <td>Page 3 of 3</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default WPQTubeToTubesheet_pdf_page3;
