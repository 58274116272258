import "../Css/groovePQR_pdf.css";

import Groove_pdf_header_common from "../../../WPSTransaction/PDF/Pages/Groove_pdf_header_common";

const Groove_pdf_headerDemo = ({ pdf, setpdf }, props, wld) => {
  const currentDate = new Date(pdf[0]?.PQR_DOB);
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  var isoverlay = pdf[0]?.wld_WPSType === "Groove" ? false : true;
  return (
    <header>
      <Groove_pdf_header_common
        currentDate={currentDate}
        from="PQR"
      ></Groove_pdf_header_common>

      <table id="table" className="PQRpwhtTable">
        <tbody>
          <tr>
            <th
              style={{
                width: "28%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
                   paddingTop:"0",
                paddingBottom:"10px",
                borderLeft: "1px solid",
              }}
            >
              PQR record number
              <br></br>
              Date
              <br></br>
            </th>
            <td
              style={{
                width: "40%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                paddingTop:"0",
                paddingBottom:"10px"
              }}
            >
              <span className="PQRforfont" style={{ float: "left" }}>
              {pdf[0]?.PQR_RecordNo === "" || pdf[0]?.PQR_RecordNo == undefined  ? "--"
                    : pdf[0]?.PQR_RecordNo}
              </span>
              <span className="PQRforfont" style={{ float: "right" }}>
                Revision: {pdf[0]?.PQR_ReviNo === "" || pdf[0]?.PQR_ReviNo == undefined  ? "--"
                    : pdf[0]?.PQR_ReviNo}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <br />
              <span className="PQRforfont" style={{ float: "left" }}>
                {formattedDate === "NaN-NaN-NaN" ? "" : formattedDate}
             
              </span> <br></br>
            </td>
            <th
              className="WPSright-th"
              style={{
                width: "28%",
                borderLeft: "1px solid",
                borderBottom: "1px solid",
                borderTop: "1px solid",
                borderRight: "1px solid",
              }}
            >
              WPS record Number
              <br></br>
              Company Name
              <br></br>
             {isoverlay ? " Welding standard" : "Reference Code"}
            </th>

            <td
              style={{
                width: "55%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <span className="PQRforfont" style={{ float: "left" }}>
               {pdf[0]?.PQR_WPS_RecordNo === "" || pdf[0]?.PQR_WPS_RecordNo == undefined  ? "--"
                    : pdf[0]?.PQR_WPS_RecordNo}
              </span> 
              <span className="PQRforfont" style={{ float: "right" }}>
                 Revision:{pdf[0]?.PQR_WPS_ReviNo === "" || pdf[0]?.PQR_WPS_ReviNo == undefined  ? "--"
                    : pdf[0]?.PQR_WPS_ReviNo}
              </span>
              <br />
              <span className="PQRforfont" style={{ float: "left" }}>
                {pdf[0]?.PQR_CompanyName === "" || pdf[0]?.PQR_CompanyName == undefined  ? "--"
                    : pdf[0]?.PQR_CompanyName} <br></br>
              </span>
              <br></br>
              {isoverlay ? 
              <span className="PQRforfont" style={{ float: "left" }}>
                {pdf[0]?.PQR_WeldingStandard === "" || pdf[0]?.PQR_WeldingStandard == undefined  ? "--"
                    : pdf[0]?.PQR_WeldingStandard} <br></br>
              </span>
              :
              <span className="PQRforfont" style={{ float: "left",wordBreak:"nowrap",whiteSpace:"nowrap" }}>
                {pdf[0]?.PQR_ReferenceCode === "" || pdf[0]?.PQR_ReferenceCode == undefined  ? "--"
                    : pdf[0]?.PQR_ReferenceCode} <br></br>
              </span>}
              <span className="PQRforfont" style={{ float: "left" }}>
              </span>
            </td>

            <td
              style={{
                width: "35%",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderRight: "1px solid",
              }}
            >
              {pdf[0]?.WPS_QualifiedTo}
              <br></br>
              {pdf[0]?.WPSH_CompanyName}
            </td>
          </tr>
        </tbody>
      </table>
    </header>
  );
};
export default Groove_pdf_headerDemo;
