import React from "react";


import { NavLink, UNSAFE_DataRouterStateContet } from "react-router-dom";

import "../Assests/Css/sidebar.css";
import "../Assests/Css/header.css";
// import SideBar from "./SideBar";
import Home from "./Home";

const Dashboard = () => {
  // if (window.performance) {
  //   if (performance.navigation.type == 1) {
  //     alert("This page is reloaded");
  //   } else {
  //     alert("This page is not reloaded");
  //   }
  // }
  return (
    <>
      <main className="box">
        <div className="app-content">ddd</div>
      </main>
    </>
  );
};

export default Dashboard;
