import axios from "axios";

let GROOVE_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  GROOVE_API_URL = process.env.REACT_APP_WPS_GROOVE_API_URL;
} else {
  GROOVE_API_URL = process.env.REACT_APP_API_URL + "wps";
}

export async function getallgroove(type) {
  const response = await axios
    .get(GROOVE_API_URL + `/WpsHeaderMast/${type}`)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function getGridHeaderData(type, band, user, year) {
  console.log("getGridHeaderData_amaze", type, band, user, year);
  let reqbody={type:type,band:band,user:user,year:year}

  const response = await axios
    .post(GROOVE_API_URL + `/WpsHeaderMast/grid`,reqbody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

//  ---------- used in wpq general to validate wpsno-------------------
export const getbywpsno = async (Type, WPSNo) => {
  
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getwps`;
  let reqbody = {Type:Type,WPSNo:WPSNo}
  const response = await axios
    .post(url,reqbody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("resppo", response);

  return response.data;
};

export const getbywpsnowi = async (WPSNo) => {
  
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getwpswi`;
  let reqbody = {WPSNo:WPSNo}
  const response = await axios
    .post(url,reqbody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("resppo", response);

  return response.data;
};

export const getbyprojectno = async (type, IdprojectPK, IdprojectSK) => {
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getprojectno`;
  let reqbody = {Type:type,PK:IdprojectPK,SK:IdprojectSK}
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getbyprojectnoWPS = async (type, IdprojectPK) => {
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getProjWPSNo`;
  let reqbody = {Type:type,PK:IdprojectPK}
  console.log("getbyprojectnoWPS", url);
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getbyprojectnoWI = async (type, IdprojectPK, IdprojectSK) => {
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getprojectnoWI`;
  let reqbody = {Type:type,PK:IdprojectPK,SK:IdprojectSK}
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const AddNewGroove = async (WPS_Header_Master) => {
  let url = `${GROOVE_API_URL}/WpsHeaderMast`;
  const response = await axios
    .post(url, WPS_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("AddNewGroove_alldata", response.data);
  return response.data;
};

export const AddNewCount = async (WPS_Header_Master) => {
  console.log("WPS_Change_No11", WPS_Header_Master.WPS_Change_No);
  console.log("AddNewCount11111", WPS_Header_Master);
  let url = `${GROOVE_API_URL}/WpsHeaderMast`;
  const response = await axios
    .post(url, WPS_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateGroove = async (WPS_Header_Master) => {
  let url = `${GROOVE_API_URL}/WpsHeaderMast/update`;
  const response = await axios
    .post(url, WPS_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteGroove(DeleteRow) {
  console.log("DeleteGroove", DeleteRow);
  // let url = `${GROOVE_API_URL}/WpsHeaderMast/delete/${pk}/${sk}`;
  let url = `${GROOVE_API_URL}/WpsHeaderMast/delete`;
  console.log("DeleteGroove_url", url);
  const resp = await axios
    .post(url, DeleteRow)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("DeleteGroove_resp", resp);
  return resp;
}

//Used in WCS Add scree
export const getWPSNo = async (Type, ProjNo) => {
  console.log("getWPSNo_ProjNo", ProjNo);
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getProjWPSNo`;
  let reqbody = {Type:Type,ProjNo:ProjNo}
  console.log("url_url", url);
  const response = await axios.post(url,reqbody).catch(function (error) {
    return { message: "Unable to find request", status: false };
  });
  console.log("getWPSNo_ProjNo_res", response.data);
  return response.data;
};

export const checkValidWPS = async (reqData) => {
  
  console.log("checkValidWPS_reqData", reqData);
  let url = `${GROOVE_API_URL}/check/getwpsno`;
  const response = await axios
    .post(url, reqData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  // console.log("checkValidWPS", response.data["notFoundWPS"]);
  let resArray = response.data["foundWPS"];
  console.log("resArray", resArray);

  return resArray;
};

export const getWPSbyJoint = async (type) => {
  let url = `${GROOVE_API_URL}/WpsHeaderMast/getWPSNoinPQR`;
  let reqbody = {Type:type}
  console.log("getWPSNoinPQR", url);
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
