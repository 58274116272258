import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Export from "../../icons/Download_Blue_Big.svg"
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";
import Export_gray from "../../icons/Download_Grey_Big.svg"


export default (props) => {
  return (
    <div className="cell-action">
      <img
        src={Delete_gray}
        onMouseOver={(e) => (e.currentTarget.src = Delete)}
        onMouseOut={(e) => (e.currentTarget.src = Delete_gray)}
        alt="delete"
        className="p-1"
        style={{position:"relative",bottom:"8px"}}
        onClick={() => props.handleDeleteEvent(props.selectedData)}
      />
      <img
        onMouseOver={(e) => (e.currentTarget.src = Edit)}
        onMouseOut={(e) => (e.currentTarget.src = Edit_gray)}
        src={Edit_gray}
        alt="edit"
        style={{position:"relative",bottom:"8px"}}
        onClick={() => props.handleEditEvent(props.selectedData)}
      />
      <img
        onMouseOver={(e) => (e.currentTarget.src = Export)}
        onMouseOut={(e) => (e.currentTarget.src = Export_gray)}
        src={Export_gray}
        alt="Export"
        style={{position:"relative",bottom:"8px"}}
        onClick={() => props.handlePdfEvent(props.selectedData)}
      />
    </div>
  );
};
