import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
} from "react";

import { Row, Col, Container, Form, Button } from "react-bootstrap";

import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getwiwelders,
  addwpqlink,
} from "../../Services/WI-services";

import {
  SearchSelectWIProject,
} from "../utility/search-select";
import { getAllOnlyProject } from "../../Services/project-services";
import {
  isAlphanumericWithDot,
} from "../../functions/validations";
import { BiSelectMultiple } from "react-icons/bi";
import { RenderIf } from "../../functions/welding-functions";
import SingleValuePopUp from "../utility/SingleValuePopUp";
import GridMaster from "../utility/GridMaster";
import { handleExport1 } from "../utility/GlobalExport";
import MultiValuePopUp from "../utility/MultiValuePopUp";
import { Action } from "../WPQTransaction/WPSObjects/WPQ_entity";
import { useLocation } from "react-router-dom";
import { getWCSDataHeader } from "../../Services/wcs-services";

const Wpq_linkingAddEdit = forwardRef((props, ref) => {
  let formName = "WI Slip";
  const [rowData, setRowData] = useState([]);
  const [widata, setwidata] = useState(null);
  const [wpsde, setwpsde] = useState({});
  const [reload, setReload] = useState(false);
  const [getreport, setgetreport] = useState([]);
  const [ProjectDetails, setProjectDetails] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const [columnDefs, setColumnDefs] = useState([]);

  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);

  const [excelData1, setExcelData] = useState([]);

  const [wcsprojectdata, setwcsprojectdata] = useState([]);

  const childRef = useRef();
  const location = useLocation();

  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
    }),
  });

  let Data = [];


  const [datevalue, setdatevalue] = useState(false);

  const [action, setaction] = useState(location.state?.action);

  let Edit = action === Action.ActionEdit;
  let ADD = action === Action.ActionAdd;

  const [wiobj, setwiobj] = useState(
    action === Action.ActionEdit
      ? location.state?.data
      : {
          PK: "",
          SK: "",
          SR_No: "",
          Action: "",
          WI_Project_No: "",
          WI_Joint_No: "",
          WI_Welder_Id: "",
          WI_FileFormat: "",
          WI_Wpq_Radio: "",
          WI_Joint_Type: "",
          WCS_Project_No: "",
          WI_WPQ_Data: [],
        }
  );

  const [filteredResult, setFilteredResult] = useState([]);

  let a = [filteredResult];
  console.log("dnskbdsdh", a);

  let tmpcolumnDefs;

  tmpcolumnDefs = [
    {
      headerName: "Welder Id",
      field: "WPQ_WelderId",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Welder Name",
      field: "WPQ_WelderName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "WPQ Number",
      field: "WPQ_RecordNo",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const [JointType, setJointType] = useState("");

  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year);
  console.log("selected", new Date().getFullYear(), selectedYear);

  // const [isChecked, setIsChecked] = useState(false);
  const [check, setCheck] = useState(false);

  const [error, setError] = useState({
    WI_Project_No: {},
    WI_Joint_No: {},
    WI_Welder_Id: {},
    WI_From_Date: {},
    WI_To_Date: {},
    WI_Slip_Status: {},
  });

  console.log("wiobj", wiobj);

  console.log("errroorrr", error);

  var Slipstatus = [
    { value: "Created", name: "Created" },
    { value: "Cancelled", name: "Cancelled" },
    { value: "Deleted", name: "Deleted" },
  ];

  var fileformat = [
    { value: "Excel", name: "Excel" },
    { value: "PDF", name: "PDF" },
  ];

  const WPQ_Linking_Radio = {
    Linked: "Link WPQ with Project",
    UnLinked: "Linking WPQ with project not Required",
  };

  const fetchProject = async () => {
    var result = await getAllOnlyProject();
    setProjectDetails(result);
  };

  console.log("allpro", ProjectDetails);

  const fetchwidata = async () => {
    const result = await getwiwelders();
    console.log("reeeeeew", result);
    setwidata(result);
  };

  const Joint_Type = [
    { value: "", name: "Select" },
    { value: "Groove", name: "Groove" },
    { value: "Overlay", name: "Overlay" },
    { value: "Tubesheet", name: "Tubesheet" },
  ];

  console.log("ProjectDetails", ProjectDetails);
  console.log("WIData", widata);

  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    fetchwidata();
  }, [reload]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);

  // const uniqueProjectNumbers = [
  //   ...new Set(widata && widata.map((item) => item.WI_Project_No)),
  // ];

  console.log("wcsprojectdata22", wcsprojectdata);

  const editjoit = async () => {
    if (Edit) {
      console.log("wcsprojectdata224443", wcsprojectdata);
      let result = await getWCSDataHeader({
        WCS_Project_No: wiobj?.WI_Project_No,
      });
      console.log("wcsprojectdata22444dsda3", result);

      setwcsprojectdata(result);
    }
  };

  useEffect(() => {
    editjoit();
  }, []);

  const getJointNosForSelectedProject = () => {
    if (wiobj.WI_Project_No && wcsprojectdata && wcsprojectdata.length > 0) {
      let joint = wcsprojectdata[1];

      const selectedProjects = joint?.filter(
        (item) => item.WCS_Project_No === wiobj.WI_Project_No
      );
      const selectedJointNos = selectedProjects
        .map((project) => project.WCS_Joint_No)
        .filter((jointNo) => jointNo);

      if (selectedJointNos.length > 0) {
        return selectedJointNos.map((jointNo) => ({
          label: jointNo,
          value: jointNo,
        }));
      }
    }
    return [];
  };

  // const getWelderForSelectedProject = () => {
  //   if (
  //     wiobj.WI_Project_No &&
  //     wiobj.WI_Joint_No &&
  //     widata &&
  //     widata.length > 0
  //   ) {
  //     const selectedWelders = widata
  //       .filter(
  //         (item) =>
  //           item.WI_Project_No === wiobj.WI_Project_No &&
  //           item.WI_Joint_No === wiobj.WI_Joint_No
  //       )
  //       .map((item) => item.WI_Welder_Id)
  //       .filter((welderId) => welderId);

  //     if (selectedWelders.length > 0) {
  //       const uniqueWelders = [...new Set(selectedWelders)];

  //       return uniqueWelders.map((welderId) => ({
  //         label: welderId,
  //         value: welderId,
  //       }));
  //     }
  //   }

  //   return [];
  // };

  const activewelder = widata?.filter(
    (item) => item.WI_Slip_Status === "Created"
  );

  console.log("dsaa", activewelder);

  const selectedWelderId = wiobj.WI_Welder_Id; // Replace with the actual selected welder ID

  const filteredData = activewelder?.filter((entry, index, array) => {
    return (
      array.findIndex(
        (e) =>
          e.WI_Welder_Id === entry.WI_Welder_Id &&
          e.WI_Welder_Name === entry.WI_Welder_Name
      ) === index
    );
  });

  console.log("fffffffffd", filteredData);

  const filteredDataProcess = activewelder
    ?.filter((entry) => entry.WI_Welder_Id === selectedWelderId)
    .filter((entry, index, array) => {
      return (
        array.findIndex(
          (e) =>
            e.WI_Process === entry.WI_Process &&
            e.WI_ProcessCategory === entry.WI_ProcessCategory
        ) === index
      );
    });

  console.log("filteredDataProcess", filteredDataProcess);

  const validate = async () => {
    let result = false;
    let isError = false;
    let entries;

    entries = Object.entries(wiobj);

    let emptyFields = [];

    if (wiobj.WI_Project_No === "") {
      isError = true;
      emptyFields.push("Project No");
    }

    if (wiobj.WI_Joint_Type === "") {
      isError = true;
      emptyFields.push("Joint Type");
    }

    if (wiobj.WI_Joint_Type === "Tubesheet") {
      if (wiobj.WI_Joint_No === "") {
        isError = true;
        emptyFields.push("Joint No");
      }
    }

    if (wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.Linked) {
      if (wiobj.WI_Welder_Id === "") {
        isError = true;
        emptyFields.push("Welder Id");
      }
    }

    if (isError) {
      toast.warning(`Field Required: ${emptyFields.join(", ")}`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }

    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (error.WI_Project_No.status) {
      isError = true;
    }
    return isError;
  };

  const handleSubmit = async (e) => {
    if (Edit) {
      wiobj.Action = "Edit";
    } else {
      wiobj.Action = "Add";
    }

    try {
      if (!(await validate())) {
        const resp = await addwpqlink(wiobj);
        console.log("Response:", resp);

        if (action === "Edit") {
          toast.success("Selected WPQ Linking has been Updated successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (resp.message === "Duplicate") {
          toast.warning("Combination of Project number and Joint Type already exist", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success("Selected WPQ Linking has been Added successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      }
    } catch (error) {
      console.error("Error:", error);
      // toast.error(`An error occurred while processing the request: ${error}`, {
      //   position: toast.POSITION.TOP_LEFT,
      //   className: "toast-message",
      // });
    }
  };

  const handleChangeselect = (e) => {
    const { name, value } = e.target;

    setwiobj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log("reeport", getreport);

  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });

  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = (data) => {
    console.log("Popupee", data);
    if (data.name === "WPQ") {
      console.log("PEDdata", data);
      setFilteredResult(data.result);

      const fields = ["WPQ_WelderId", "WPQ_WelderName", "WPQ_RecordNo", "WPQ_Qualificationcode","WPQ_PlantLocation","WPQ_Inspection","WPQ_specialNote"];
      const newarray = data.result.map((e) => {
        const newobj = {};
        fields.forEach((field) => {
          newobj[field] = e[field];
        });
        return newobj;
      });

      console.log("newarray", newarray);
      wiobj.WI_WPQ_Data.length = 0;

      wiobj.WI_WPQ_Data.push(...newarray);

      var vs1 = Array.prototype.map
        .call(data.result, function (item) {
          return item.WPQ_WelderId;
        })
        .join(",");
      setwiobj((prevState) => ({
        ...prevState,
        ["WI_Welder_Id"]: vs1,
        WI_WPQ_Data: newarray,
      }));
    }
  };

  console.log("fffffs", filteredResult);

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    // result.push({ headerName: "", cellRenderer: "", width: 250 });
    console.log("rreessss", result);
    setColumnDefs(result);
  };

  useEffect(() => {
    // fetchData()
    fetchColumnDefns();
  }, []);

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);
  };

  const handleGlobalExport = () => {
    let filename = "AnoMaster";
    setExcelData(filteredResult);
    let result = handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const handleCheckboxClick = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "Link WPQ with Project") {
      setwiobj((prev) => ({
        ...prev,
        WI_Wpq_Radio: selectedValue,
        // Enable the select wpq field when Link WPQ with Project is selected
        // Add other state updates as needed
      }));
    } else if (selectedValue === "Linking WPQ with project not Required") {
      setwiobj((prev) => ({
        ...prev,
        WI_Wpq_Radio: selectedValue,
        // Disable the select wpq field when Linking WPQ with project not Required is selected
        // Add other state updates as needed
      }));
    } else {
      // Handle the case when "Groove" or "Overlay" is selected
      setwiobj((prev) => ({
        ...prev,
        WI_Joint_Type: selectedValue,
        WI_Wpq_Radio: "Link WPQ with Project",
        // Enable both radio buttons and handle other state updates as needed
      }));
    }

    setwiobj((prev) => ({
      ...prev,
      WI_WPQ_Data: [],
      WI_Welder_Id: "",
    }));

    // Add any additional logic or state updates here
  };

  return (
    <>
      <div className="position-absolute ms-3" style={{ top: "15px" }}>
        <b style={{ fontSize: "22px" }}>WPQ-Linking</b>
      </div>
      <div className="location">
        <div className="ltext" style={{ position: "relative", left: "7px" }}>
          {" "}
          Home
          {"> "}
          <a>{"WPQ-Linking"}</a> {"> "}
          <a>{"WPQ-Linking"}</a>
        </div>
      </div>
      <Container fluid>
        <Row className="RowHeight">
          <Col
            md={4}
            sm={12}
            xs={12}
            className="ListTitle mt-2 mb-4"
            style={{ position: "relative", left: "7px" }}
          >
            Add New Project
          </Col>
          {/* <Col>
            <div className="global-search-container">
              <GolbalSearch
                searchplaceholdertext={"Search for Welder ID , Welder Name"}
                filterFunc={handleGlobalSearch}
              />
              <img
                className="allexport"
                src={ExportIcon}
                onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
                onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
                alt="ExportIcon"
                onClick={handleGlobalExport}
              />
            </div>
          </Col> */}
        </Row>

        <div className="px-2 pb-2 mt-2 ">
          <Row className="gx-3" style={{height:"40px"}}>
            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    id="WI_Project_No"
                    name="WI_Project_No"
                    className={""}
                    disabled={Edit ? true : false}
                    stylegreen={normalstyle}
                    data={ProjectDetails}
                    stylenormal={normalstyle}
                    valueField={"wld_ProjectNumber"}
                    value={wiobj?.WI_Project_No}
                    labelField={"wld_ProjectNumber"}
                    placeholder=""
                    onChange={async (ProjectList) => {
                      console.log("wcsprojectdata:", wcsprojectdata);

                      setwiobj((prev) => ({
                        ...prev,
                        WI_Project_No: ProjectList.value,
                        WCS_Project_No: ProjectList.value,
                        WI_Joint_No: "",
                        WI_Welder_Id: "",
                        WI_WPQ_Data: [],
                      }));
                      if (ADD) {
                        try {
                          const result = await getWCSDataHeader({
                            WCS_Project_No: ProjectList.value,
                          });

                          setwcsprojectdata(result);
                        } catch (error) {
                          console.error("Error fetching data:", error);
                        }
                      }
                    }}
                    onInput={(e) => {
                      setError((prevState) => ({
                        ...prevState,
                        WI_Project_No: error,
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Project No.</label>
              </Form.Group>
            </Col>
          </Row>
          <div style={{height:"28px"}}></div>
          <Row className="gx-3" style={{height:"40px"}}>
            <Col md={3} style={{ position: "relative", bottom: "10px" }}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    // className="inspdrop"
                    id="WI_Joint_Type"
                    name="WI_Joint_Type"
                    className={""}
                    disabled={Edit ? true : false}
                    stylegreen={normalstyle}
                    data={Joint_Type}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wiobj?.WI_Joint_Type}
                    labelField={"name"}
                    placeholder=""
                    onChange={(e) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Joint_Type: e.value,
                        WI_Wpq_Radio:
                          e.value === "Tubesheet"
                            ? "Link WPQ with Project"
                            : "Linking WPQ with project not Required",
                        WI_Joint_No: "",
                        // WI_WPQ_Data:,
                        WI_Welder_Id: "",
                      }));
                      setFilteredResult([]);
                      wiobj.WI_WPQ_Data.length = 0;

                      setJointType(e.value);
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Joint Type</label>
              </Form.Group>
            </Col>
          </Row>
          <div style={{height:"28px"}}></div>

          <Row className="gx-3" style={{height:"40px"}}>
            <Col md={3} style={{ position: "relative", bottom: "20px" }}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    id="WI_Joint_No"
                    name="WI_Joint_No"
                    className={""}
                    disabled={
                      wiobj.WI_Joint_Type !== "Tubesheet" &&
                      wiobj.WI_Joint_Type !== ""
                        ? true
                        : false
                    }
                    stylegreen={normalstyle}
                    data={getJointNosForSelectedProject()}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wiobj?.WI_Joint_No}
                    labelField={"label"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Joint_No: ProjectList.value,
                        // WI_Welder_Id: "",
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Joint No.</label>
              </Form.Group>
            </Col>
          </Row>
          {/* <div style={{height:"28px"}}></div> */}

          <Row className="gx-3" style={{ marginBottom: "2rem",height:"40px"  }}>
            <Col md={2}>
              <div>
                <input
                  type="radio"
                  name="WI_Wpq_Radio"
                  value="Link WPQ with Project"
                  onClick={(e) => handleCheckboxClick(e)}
                  onChange={(e) => handleCheckboxClick(e)}
                  style={{ position: "relative", top: "2px" }}
                  checked={wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.Linked}
                  // disabled={wiobj.WI_Joint_Type !== "Tubesheet"}
                />
                <span style={{ fontSize: "14px" }}>
                  &nbsp; Link WPQ with Project
                </span>
              </div>
            </Col>

            <Col md={3}>
              {/* <div style={{ width: "232px" }}>
                <input
                  // disabled={wiobj.WI_Joint_Type === "Tubesheet"  ? true : false}
                  disabled={wiobj.WI_Joint_Type === "Tubesheet"  ? true : false}
                  type="radio"
                  name="WI_Wpq_Radio"
                  value="Linking WPQ with project not Required"
                  onClick={(e) => handleCheckboxClick(e)}
                  onChange={(e) => handleCheckboxClick(e)}
                  style={{ position: "relative", top: "2px" }}
                  checked={wiobj.WI_Joint_Type === "Groove" || wiobj.WI_Joint_Type === "Overlay" || wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.UnLinked}
                />
                <span style={{ fontSize: "14px" }}>
                  &nbsp; Linking WPQ with project
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    position: "relative",
                    left: "24px",
                  }}
                >
                  &nbsp;&nbsp; not Required
                </span>
              </div> */}

              <div style={{ width: "200px", display: "inline-block", lineHeight: "1.2" }}>
                <input
                  // disabled={wiobj.WI_Joint_Type === "Groove" || wiobj.WI_Joint_Type === "Overlay"}
                  disabled={wiobj.WI_Joint_Type === "Tubesheet"}
                  type="radio"
                  name="WI_Wpq_Radio"
                  value="Linking WPQ with project not Required"
                  onClick={(e) => handleCheckboxClick(e)}
                  onChange={(e) => handleCheckboxClick(e)}
                  style={{ position: "relative", top: "2px" }}
                  // checked={wiobj.WI_Joint_Type === "Groove" || wiobj.WI_Joint_Type === "Overlay" || wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.UnLinked}
                  checked={wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.UnLinked}
                />
                <span style={{ fontSize: "14px" }}>
                  &nbsp; Linking WPQ with project
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    position: "relative",
                    left: "20px",
                  }}
                >
                  &nbsp; not Required
                </span>
              </div>
            </Col>
          </Row>
          {/* <div style={{height:"28px"}}></div> */}

          <Row className="gx-3" style={{ marginBottom: "2rem" }}>
            <Col>
              <Form.Group>
                <div className="d-flex align-items-center">
                  <div class="g-input">
                    <input
                      // readOnly={true}
                      style={{ width: "285px", paddingRight: "45px" }}
                      id="SK"
                      name="SK"
                      type="text"
                      disabled={
                        wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.UnLinked
                          ? true
                          : false
                      }
                      placeholder=" "
                      color="var(--col-707070)"
                      value={wiobj.WI_Welder_Id}
                      // required={wiobj.WI_Wpq_Radio === WPQ_Linking_Radio.Linked}
                      onChange={handleChangeselect}
                      onInput={(e) => {
                        let error = isAlphanumericWithDot(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          SK: error,
                        }));
                      }}
                    />
                    <label class="required-field" for="SK">
                      Select WPQ
                    </label>
                    <BiSelectMultiple
                      size={20}
                      onClick={() => {
                        if (wiobj.WI_Wpq_Radio !== WPQ_Linking_Radio.UnLinked) {
                          showPopUp(
                            "WPQ",
                            "Multiple",
                            "WI_Welder_Id",
                            wiobj.WI_Welder_Id
                          );
                        }
                      }}
                      color={"gray"}
                      style={{
                        marginLeft: "15.5rem",
                        marginTop: "-4.2rem",
                      }}
                    />
                  </div>
                </div>
                {/* {error.SK.status && (
                      <Form.Text className="text-danger">
                        <span style={{ position: "relative", bottom: "30px" }}>
                          {" "}
                          {error.SK.message}
                        </span>
                      </Form.Text>
                    )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                onClick={handleSubmit}
                style={{ position: "relative", bottom: "30px" }}
                className="btn-add-color semiBoldToggleButton leftAlignedButton"
              >
                {Edit ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
          {filteredResult.length > 0 || action === Action.ActionEdit ? (

          <div
            style={{
              position: "relative",
              left: "500px",
              bottom: "445px",
              width: "600px",
              height: "483px",
              border: "1px solid var(--col-ddd)"
            }}
          >
              <GridMaster
                rowDetails={Edit ? wiobj?.WI_WPQ_Data : filteredResult}
                colDetails={columnDefs}
                fieldNames={fieldName}
                headerNames={headerName}
                getDataEvent={(getFilteredData) =>
                  setFilteredResulttemp(getFilteredData)
                }
                getFilterColLength={(getLength) =>
                  setFilterColLength(getLength)
                }
                setExcelData={setExcelData}
                ref={childRef}
              />
          
          </div>
            ) : (
              ""
            )}
        </div>

        <RenderIf isTrue={popUp.type === "Multiple" && showForm}>
          <MultiValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            backdrop="static"
            JointType={JointType}
            wiobj={wiobj}
            action={Edit}
          />
        </RenderIf>

        <RenderIf isTrue={popUp.type === "Single" && showForm}>
          <SingleValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            backdrop="static"
            JointType={JointType}
          />
        </RenderIf>
        {/* <div style={{position:"relative", top:"10000px"}}>
{showForm && (
        <WCR_PDF
          show={showForm}
          widata={widata}
          // action={action}
          rowdata={wiobj}
          onHide={() => setShowForm(false)}
          // fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          // wiobj={wiobj}
          // allRecs={Groove}
          backdrop="static"
        />
      )}
      </div> */}
      </Container>

      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
});

export default Wpq_linkingAddEdit;
