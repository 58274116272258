import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

import GolbalSearch from "../../utility/GolbalSearch";

import { handleExport1 } from "../../utility/GlobalExport";
import { Button, Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import Export from "../../../icons/Export.svg";



import "react-toastify/dist/ReactToastify.css";

import Toaster from "../../utility/Toaster";
import { TrainingReqApproval } from "./TrainingReqApproval";
import { TrainingReqApproval1, TrainingReqInitiated } from "./TrainingReqInitiated";
import AddEditTrainingRequisition from "./AddEditTrainingRequisition";


const TrainingRequisition = forwardRef((props, ref) => {
  const [key, setKey] = useState(1);
  const [refe, setRefe] = useState();
  const [excelData1, setExcelData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [mainReload, setMainReload] = useState(false);
  const [username, setusername] = useState(sessionStorage.getItem("UserName"))

  const [rowData, setRowData] = useState({});
  const Traininginit = useRef();
  const Trainingpend = useRef();
  const [calledFrom, setCalledFrom] = useState("");
  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "wld_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "trainingReqCellRenderer",
    },
    {
      headerName: "Training Request Number",
      field: "wld_TrainingReqNumber",
      valueGetter: params => {
        return parseFloat(params.data.wld_TrainingReqNumber);
      },
      width: 250
    },
    {
      headerName: "Employee Name",
      field: "wld_TrainingReqEmployeeName",
      type: "Test",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    }

  ];

  const handleGlobalSearch = (text) => {
    refe.current.setSearch(text)


  };

  const handleGlobalExport = () => {
    let filename = "TrainingRequisitionMaster";
    let result = handleExport1(excelData1, filename, tmpcolumnDefs);
  };


  const handleSelect = (e) => {

    setKey(e)
  }
  useEffect(() => {

    Traininginit.current.reload()
    Trainingpend.current.reload()
 
  }, [mainReload]);

  
  

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setMainReload(!mainReload);
    },
  }));

  function funcclose(boolVar) {
    setMainReload(!mainReload)
  }


  return (
    <>
      

      <Row className="RowHeightTREQ">
        <Col md={2} sm={12} xs={12} className="ListTitle" style={{position:"relative",left:"11px"}}>
          Training Request list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for Training subject, Faculty"
              }
              filterFunc={handleGlobalSearch}
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <Tabs
        activeKey={key}
        onSelect={(e) => handleSelect(e)}

        className="mb-3 w-100 border-end"


      >
        <Tab
          eventKey={1}
          title="Requests pending for your Approval"
          style={{
            alignItems: "left",
            height: window.innerHeight - 750,
          }}
        >
          <TrainingReqApproval
            tmpcolumnDefs={tmpcolumnDefs}
            setRefe={setRefe}
            key={key}
            close={funcclose}
            setExcelData={setExcelData}
            mainReload={mainReload}
            ref={Trainingpend}
            showAddEditForm={()=>setShowForm(true)}
            setShowForm={setShowForm}
            setAction={setAction}
            setRowData={setRowData}
            setCalledFrom={setCalledFrom}
          />
        </Tab>
        <Tab
          eventKey={2}
          title="Requests sent for Approval"
          style={{
            height: window.innerHeight - 750,
          
          }}


        >
          <TrainingReqInitiated
            username={username}
            tmpcolumnDefs={tmpcolumnDefs}
            setRefe={setRefe}
            setExcelData={setExcelData}
            key={key}
            close={funcclose}
          
            mainReload={mainReload}
            ref={Traininginit}
            setShowForm={setShowForm}
            setAction={setAction}
            setRowData={setRowData}
            setCalledFrom={setCalledFrom}
          />
        </Tab>

      </Tabs>




      {showForm && (
        <AddEditTrainingRequisition
          show={showForm}
          action={action}
          onHide={() =>{setShowForm(false)}}
          setMainReload={setMainReload}
          mainReload={mainReload}
          rowData={rowData}
          calledFrom={calledFrom}
        />
      )}
      <Toaster />

    </>
  );
});

export default TrainingRequisition;
