import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";

import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";

import GolbalSearch from "../../utility/GolbalSearch";

import { handleExport1 } from "../../utility/GlobalExport";
import {  Row, Col, Container } from "react-bootstrap";
import Export from "../../../icons/Export.svg";
 import GlobalColumnDefinations from "../../utility/AgGridDefination";
import { getHeader_FieldName } from "../../utility/AgGridDefination";
  import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../../utility/GridMaster";
import Toaster from "../../utility/Toaster";


import { getCompletedTraining } from "../../../Services/training-service";

import TrainingDetailExcelRecords from "./TraineeDetailExcelRecord";
import { getTrainingRecordbyID } from "../../../Services/trainingRequisition-service";
import ActionRenderer from "../../utility/ActionRenderer";
import { toast } from "react-toastify";
import { DeleteTrainingRecord } from "../../../Services/trainingRecord-service";
import AddEditAttendanceDetails from "./AddEditAttendanceDetails";
import { GridLoader } from "react-spinners";

const TrainingRecord = forwardRef((props, ref) => {
  let formName = "Training";
  const gridRef = useRef();
  const [rowData, setRowData] = useState({});
  const [filteredCol, setFilteredCol] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [combinedArray, setCombinedArray] = useState([]);
  const [training, setTraining] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showFormexcel, setShowFormexcel] = useState(false);

  const [action, setAction] = useState("");

  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);

  const [loading, setLoading] = useState(true);



 
  const childRef = useRef();

 
 const navigate = useNavigate();


  const tmpcolumnDefs = [
   
    {
      headerName: "Training subject",
      field: "wld_trainingSub",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Trainee Name",
      field: "wld_TrainingReqEmployeeName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Salcode",
      field: "wld_TrainingReqSalaryEmployeeCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
   
    {
      headerName: "Month of Training",
      field: "wld_trainingMonth",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "From Date",
      field: "wld_fromDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "To Date",
      field: "wld_toDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "Faculty",
      field: "wld_faculty",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
    {
      headerName: "Requester Department",
      field: "wld_TrainingReqRequesterDepartment",
      type: "text",
      isCheckbox: false,
      cellRenderer: "",

    },
    {
      headerName: "Attendance",
      field: "wld_attendance",
      type: "text",
      isCheckbox: false,
    },
    {
      headerName: "Score",
      field: "wld_score",
      type: "Text",
      isCheckbox: false,
    },
    {
      headerName: "Total Marks",
      field: "wld_marks",
      type: "Text",
      isCheckbox: false,

    },
    {
      headerName: "Percentage",
      field: "wld_percentage",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  
    // {
    //   headerName: "All Score Updated",
    //   field: "wld_scoreupdated",
    //   type: "Text",
    //   isCheckbox: false,
    //   cellRenderer:"statustrainRenderer",
    // },
    
  ];
  
  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);


  const handleHyperlink = (row) => {
   
    navigate("/weldingmaster/TrainingRecord/TrainingDetails", { state: {
      data: row,
      action: "Trainee",
    },
    });
  };


  const handleView = (row) => {

    setAction("View");
    setRowData(row);
    setShowForm(true);
  }




  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };


  
  // const fetchTraining = async () => {
   
  //   const result = await getCompletedTraining();//================
    
  //   console.log("resurr",result)
  // //  setTraining(result);
  //   setFilteredResult(result);
    
  //   let resultcombined = []
  //   for(let item of result ){
  //     let detailresult = await getTrainingRecordbyID(item.SK);
  //     console.log("detailresult",detailresult);
  //     console.log("itemm",item);
  //       let combinedTraining
  //       detailresult.map((training) =>{
  //         combinedTraining = {
  //           wld_trainingSub : item.wld_trainingSub,
  //           wld_fromDate : item.wld_fromDate,
  //           wld_toDate: item.wld_toDate,
  //           wld_trainingMonth :item.wld_trainingMonth,
  //           wld_TrainingReqRequesterDepartment:training.wld_TrainingReqRequesterDepartment,
  //           wld_faculty :item.wld_faculty,
  //           wld_TrainingReqEmployeeName:training.wld_TrainingReqEmployeeName,
  //           wld_TrainingReqSalaryEmployeeCode:training.wld_TrainingReqSalaryEmployeeCode,
  //           wld_attendance:training.wld_attendance,
  //           wld_score:training.wld_score,
  //           wld_marks:training.wld_marks,
  //           wld_percentage:training.wld_percentage,
  //           PK: training.PK,
  //           SK: training.SK

  //         }
  //         resultcombined.push(combinedTraining)
  //       })
        
         
 

  // }; 
            
               
    
  //  setCombinedArray(resultcombined)
  //   console.log("Combined Result",resultcombined)
  // }



  const fetchTraining = async () => {
  try {
    const result = await getCompletedTraining();
    setFilteredResult(result);

    // Fetch all training details concurrently
    const detailResults = await Promise.all(result.map(item => getTrainingRecordbyID(item.SK)));

    // Combine data based on fetched results
    const resultcombined = [];
    result.forEach((item, index) => {
      const detailresult = detailResults[index];
      detailresult.forEach(training => {
        resultcombined.push({
          wld_trainingSub: item.wld_trainingSub,
          wld_fromDate: item.wld_fromDate,
          wld_toDate: item.wld_toDate,
          wld_trainingMonth: item.wld_trainingMonth,
          wld_TrainingReqRequesterDepartment: training.wld_TrainingReqRequesterDepartment,
          wld_faculty: item.wld_faculty,
          wld_TrainingReqEmployeeName: training.wld_TrainingReqEmployeeName,
          wld_TrainingReqSalaryEmployeeCode: training.wld_TrainingReqSalaryEmployeeCode,
          wld_attendance: training.wld_attendance,
          wld_score: training.wld_score,
          wld_marks: training.wld_marks,
          wld_percentage: training.wld_percentage,
          PK: training.PK,
          SK: training.SK,
        });
      });
    });

    setCombinedArray(resultcombined);

    console.log("Combined Result", resultcombined);
  } catch (error) {
    console.error("Error fetching training data:", error);
  }
  setLoading(false);

};

 console.log("All detailed results:", combinedArray);

  //setCombinedArray(combinedArray)

 // console.log("setFilteredResult222//22222",filteredResult);

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    // result.push({ headerName: "Trainee Details", cellRenderer: actionTraineeRenderer, width: 250 });
    result.push({ headerName: "", cellRenderer: actionRenderer, width: 250,   suppressMenu: true,})
   
    setColumnDefs(result);
  };

  useEffect(() => {
    fetchTraining();
    fetchColumnDefns();
  }, [reload]);

  // useEffect(() => {
  //   let SK = filteredResult[0]?.SK
  //   console.log("Skkkkk",SK)
  //   fetchTrainingDetail(SK);
  // }, [filteredResult]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));
  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  const handleEdit = (row) => {
    debugger
    console.log("rowData",row);
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };
  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(training);
  };

  // const handleDelete = (row) => {
  //   console.log("deleteRow",row);
  //   let result;
  //   console.log("data",data);
    
  //   result = updateTrainingRecord(data);
  //   console.log("updateTrainingRecordresult",result);
    
  //   result
  //     .then((resp) => {
  //       if (resp.message === "Success") {
  //         setShowForm(false);
  //         toast.success(`Selected Detail has been Deleted successfully`, {
  //           position: toast.POSITION.TOP_LEFT,

  //           className: "toast-message",
  //         });
  //       } else {
  //         toast.warning("Error!!!, Not able to deactivate", {
  //           position: toast.POSITION.TOP_LEFT,

  //           className: "toast-message",
  //         });
  //       }
  //       setReload(!reload);
  //     })
  //     .catch((error) => {
  //       console.log("Unable to process request");
  //       toast.warning("Error!!!, Not able to Add or deactivate Data", {
  //         position: toast.POSITION.TOP_LEFT,

  //         className: "toast-message",
  //       }); 
  //     })
  //     .finally(() => {
  //       setdata("");
  //       setShow1(false);
  //     });
  // };

 
  const handleDelete = (row) => {
    DeleteTrainingRecord(data)
      .then((result) => {
        if (result.data.message === "Success") {
          setShowForm(false);
          toast.success(`Selected Training has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          setReload(!reload);
        }
      })
      .catch((error) => {
        console.log("Unable to process request");
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  const [show1, setShow1] = useState(false);

  

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text)

  };
 /*  const actionTraineeRenderer = (params) => {
    return (
      <ActionRendererTrainingR
      selectedData={params}
      
      handleViewEvent={(params) => {
        handleView(params.data);
      
      }}
      handleHyperlink={(params) => handleHyperlink(params.data)}
      handleGlobalExport={(params) => handleExport(params.data)}
    />
    
      
    );

      } */

/*   const handleExport = (row) => {
    setAction("Trainee");
    setRowData(row);
    setShowFormexcel(true);
  } */
 
  const handleGlobalExport = (selectedRow) => {
    let filename = "TrainingMaster";
    setExcelData(filteredResult)
    let result = handleExport1(combinedArray, filename, tmpcolumnDefs);
  };
console.log("rowwwwwwwwwww",rowData.SK);

debugger
  return (
    <>
      {loading && (
      <div className="loader-overlay">
        <GridLoader size={10} className="pageloader" />
      </div>
    )}
    
    <Container fluid>
    
      <ConfirmAlertModal
        action={action}
        handleYes={handleView}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
       <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      
     
    
        <>
         <Row className="RowHeight">
         <Col md={2} sm={12} xs={12} className="ListTitle">
           Training Record list
           </Col>
         <Col>
           <div className="global-search-container">
             <GolbalSearch
               searchplaceholdertext={
                 "Search for Training subject, Faculty"
               }
               filterFunc={handleGlobalSearch}
             />
             <img
               className="allexport"
               src={Export}
               alt="Export"
               onClick={handleGlobalExport}
             />
           </div>
         </Col>
       </Row>
    

      <GridMaster
        rowDetails={combinedArray}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
      />
      </>
        
      <Row className="RowHeight">
         <Col md={2} sm={12} xs={12} className="ListTitle">
           Training Record list
           </Col>
         <Col>
         
         </Col>
       </Row>
       {showForm && (
        <AddEditAttendanceDetails
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          // fetchUserMaster={fetchSfa}
          // allRecs={Sfa}
          backdrop="static"
        />
      )}

{showFormexcel && (
        
        <TrainingDetailExcelRecords
        show={showFormexcel}
        action={action}
        TrainingSubject={rowData.SK}
        PK={rowData.PK}
        SK={rowData.SK}
        wld_ScoreUpdate={rowData.wld_ScoreUpdate}
       
        setRowData={setRowData}
        handleHyperlink={handleHyperlink}
        onHide={() => setShowFormexcel(false)}
        resetForm={resetForm}
        setReload={setReload}
        reload={reload}
        fetchUserMaster={fetchTraining}
        allRecs={combinedArray}
        backdrop="static"
      />

      
       )}
        

      <Toaster />

    </Container>
    </>
  );
});

export default TrainingRecord;
