import axios from "axios";



let MASTER_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
   MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
}
else{
   MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getAllAno() {
  const response = await axios
    .get(MASTER_API_URL + "/ano/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function getActiveAno() {
  const response = await axios
    .get(MASTER_API_URL + "/ano/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export const AddNewAno = async (Ano) => {
  let url = `${MASTER_API_URL}/Ano`;
  const response = await axios
    .post(url, Ano)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateAno = async (Ano) => {
  let url = `${MASTER_API_URL}/Ano/update`;
  const response = await axios
    .post(url, Ano)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteAno(skid) {
  let url = `${MASTER_API_URL}/Ano/delete`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
