import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("Token");

    console.log("in axios.........");
    // console.log(token)
    // console.log(config)

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    // config.headers["Content-Type"] = "application/json";

    return config;
  },

  (error) => {
    Promise.reject(error);
  }
);
