import React, { useState, forwardRef, useEffect } from "react";
import SuccessAlertModal from "../Modals/successAlertModal";
import { useNavigate, Link } from "react-router-dom";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "../../Assests/Css/header.css";
import "./deviation.css";
import { getAllDeviaitons } from "../../Services/deviationcapa-service";
import { ToastContainer, toast } from "react-toastify";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import dayjs from "dayjs";
import { getCurrentDateTime } from "../../functions/welding-functions";
import { GetDeviationReport, getDeviationCategories, getAllCapaByDev } from "../../Services/deviationcapa-service";
import { exportToExcel } from "./ExcelCreationDeviation";
import { Date_Input } from "../ListOfWelders/Excel_Headers";

const DeviationReport = forwardRef((props, ref) => {
  let formName = "Welding Deviation Report";
  const [deviations, setDeviations] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [projNumResult, setProjNumResult] = useState([]);
  const [devNumResult, setDevNumResult] = useState([]);
  const [welderResult, setWelderResult] = useState([]);
  const [observerCodeResult, setObserverCodeResult] = useState([]);
  const [deviationCategoryResult, setDeviationCategoryResult] = useState([]);
  const [supervisorCodeResult, setSupervisorCodeResult] = useState([]);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const handleCloseSuccess = () => setShowSuccess(false);
  const [deviationObj, setDeviationObj] = useState({});
  var todayDate = getCurrentDateTime("-");
  const [datevalue, setdatevalue] = useState(false);
  const [error, setError] = useState({});

  const fetchRaise = async () => {
    const result = await getAllDeviaitons();
    result?.sort(sortByPropertyInAscending("deviationNumber"));
    setDeviations(result);
    let tempDev = [];
    let tempProj = [];
    let tempWelder = [];
    let tempSupervisor = [];
    let tempObserver = [];
    for (let i = 0; i < result?.length; i++) {
      tempDev.push({ "Name": result[i].deviationNumber, "Code": result[i].deviationNumber })
      let chk = tempProj.filter(x => x.Name === result[i].projectNumber);
      if (chk?.length === 0 || tempProj?.length === 0) {
        tempProj.push({ "Name": result[i].projectNumber, "Code": result[i].projectNumber })
      }
      chk = tempWelder.filter(x => x.Code === result[i].welderCode);
      if (chk?.length === 0 || tempWelder?.length === 0) {
        tempWelder.push({ "Name": result[i].welderCode + " " + result[i].welderName, "Code": result[i].welderCode })
      }
      chk = tempSupervisor.filter(x => x.Code === result[i].supervisorCode);
      if (chk?.length === 0 || tempSupervisor?.length === 0) {
        tempSupervisor.push({ "Name": result[i].supervisorName + " (" + result[i].supervisorCode + ")", "Code": result[i].supervisorCode, "Dept": result[i].supervisorDept });
      }
      chk = tempObserver?.filter(x => x.Code === result[i].observerCode);
      if (chk?.length === 0 || tempObserver?.length === 0) {
        tempObserver.push({ "Name": result[i].observerName + " (" + result[i].observerCode + ")", "Code": result[i].observerCode, "Dept": result[i].observerDept })
      }
    }
    setProjNumResult(tempProj)
    setDevNumResult(tempDev);
    setWelderResult(tempWelder);
    setSupervisorCodeResult(tempSupervisor);
    setObserverCodeResult(tempObserver);
  };

  const resetForm = () => {
    setDeviationObj({});
  }

  console.log("Ffesf",deviationObj)

  console.log("welllll",welderResult)

  const fetchDeviationCategory = async () => {
    let result = await getDeviationCategories();
    let temp = [];
    if (result != undefined) {
      for (let k = 0; k < result.length; k++) {
        temp.push({ "Name": result[k].devCategory, "Code": result[k].devCategory, "CategoryDesc": result[k].devCategoryDesc })
      }
    }
    setDeviationCategoryResult(temp);
  }

  function sortByPropertyInAscending(property) {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  }

  const handleChange = (e) => {
    if (e.target.name === "projectNumber") {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
    const { name, value } = e.target;
    setDeviationObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchRaise();
    fetchDeviationCategory();
  }, []);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setDeviations",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (error.observationDateFrom?.status === true || error.observationDateTo?.status === true) {
      toast.warning("Selected date cannot be greater than observation To date", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      return;
    }
    let temp = {};
    for (let key of Object.keys(deviationObj)) {
      console.log("key", key);
      console.log("value", deviationObj[key]);
      if (deviationObj[key] !== null && deviationObj[key] !== undefined && deviationObj[key] !== "") {
        temp[key] = deviationObj[key];
      }
    }
    if (temp.supervisorCode?.length > 0) {
      let supernm = temp.supervisorCode?.split("(");
      let supercode = supernm[1]?.split(")");
      temp.supervisorCode = supercode[0].trim();
      temp.supervisorName = supernm[0].trim();
    }
    if (temp.observerCode?.length > 0) {
      let obsnm = temp.observerCode?.split("(");
      let obscode = obsnm[1]?.split(")");
      temp.observerCode = obscode[0].trim();
      temp.observerName = obsnm[0].trim();
    }
    let excelData = await GetDeviationReport(temp);
    let devNumbers = [];
    for (let item of excelData) {
      item.observationDate = dayjs(item.observationDate).format("DD/MM/YYYY");
      devNumbers.push(item.deviationNumber);
    }

    let capaData = await getAllCapaByDev(devNumbers);
    if (capaData?.length > 0) {
      for (let item of excelData) {
        let chk = capaData.filter(x => x.deviationNumber === item.deviationNumber);
        if (chk?.length > 0) {
          item.correctiveAction = chk[0].correctiveAction;
          item.actionTakenBy = chk[0].actionTakenBy;
          item.actionTakenDate = chk[0].actionTakenDate;
          item.actionTimeTaken = chk[0].actionTimeTaken;
          item.rootCauseAnalysis = chk[0].rootCauseAnalysis;
          item.preventiveAction = chk[0].preventiveAction;
          item.capaStatus = chk[0].capaStatus;
        }
      }
    }
    let filename = "Deviation Report";
    if (excelData?.length > 0) {
      exportToExcel(filename, excelData);
    }
    else {
      toast.info("There are no records matching to filters.", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
  };

  const handleDropDownChange = async (e) => {
    const { name, value } = e.target;
    if (name === "observerCode") {
      let roledesc1 = e.target.children[e.target.selectedIndex].getAttribute('result');
      setDeviationObj((prevState) => ({
        ...prevState,
        [name]: value,
        observerDept: roledesc1
      }));
      if (value === "") {
        setDeviationObj((prevState) => ({
          ...prevState,
          observerDept: ""
        }));
      }
    }
    if (name === "supervisorCode") {
      let roledesc1 = e.target.children[e.target.selectedIndex].getAttribute('result');
      setDeviationObj((prevState) => ({
        ...prevState,
        [name]: value,
        supervisorDept: roledesc1
      }));
      if (value === "") {
        setDeviationObj((prevState) => ({
          ...prevState,
          supervisorDept: ""
        }));
      }
    }
    else {
      setDeviationObj((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  }

  return (
    <Container fluid className="main-container">
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <Row className="RowHeight">
        <Col md={3} xs={12} className="deviationheaderResp">
          Welding Deviation Report Filters
        </Col>
      </Row>
      <div className="divFilter scrollable-report">
        <Form onSubmit={handleSubmit} onReset={resetForm} autoComplete="off">
          <div className="px-2 pb-2">
            <Row className="gx-3">
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="projectNumber"
                      name="projectNumber"
                      type="text"
                      placeholder=" "
                      value={deviationObj.projectNumber}
                      onChange={(e) => { handleDropDownChange(e) }}
                    >
                      <option value="">Select</option>
                      {projNumResult ? projNumResult.map((projNum) => (
                        <option key={projNum.Code} value={projNum.Name}>{projNum.Name}</option>
                      )) : null
                      }
                    </Form.Select>
                    <label for="projectNumber">Project Number</label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="deviationNumber"
                      name="deviationNumber"
                      type="text"
                      placeholder=" "
                      value={deviationObj.deviationNumber}
                      onChange={(e) => { handleDropDownChange(e) }}
                    >
                      <option value="">Select</option>
                      {devNumResult ? devNumResult.map((devNum) => (
                        <option key={devNum.Code} value={devNum.Name}>{devNum.Name}</option>
                      )) : null
                      }
                    </Form.Select>
                    <label for="deviationNumber">Deviation Number</label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="deviationCategory"
                      name="deviationCategory"
                      type="text"
                      placeholder=" "
                      value={deviationObj.deviationCategory}
                      onChange={(e) => { handleDropDownChange(e) }}
                    >
                      <option value="">Select</option>
                      {deviationCategoryResult ? deviationCategoryResult.map((deviationCategoryResult) => (
                        <option key={deviationCategoryResult.Code} result={deviationCategoryResult.CategoryDesc} value={deviationCategoryResult.Name}>{deviationCategoryResult.Name}</option>
                      )) : null
                      }
                    </Form.Select>
                    <label for="deviationCategory">Deviation Category</label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}} >
                <Form.Group class="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="welderCode"
                      name="welderCode"
                      type="text"
                      placeholder=" "
                      value={deviationObj.welderCode}
                      onChange={(e) => { handleDropDownChange(e) }}
                    >
                      <option value="">Select</option>
                      {welderResult ? welderResult.map((weldercd) => (
                        <option key={weldercd.Code} value={weldercd.Code}>{weldercd.Name}</option>
                      )) : null
                      }
                    </Form.Select>
                    <label for="welderCode">Welder Code</label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="supervisorCode"
                      name="supervisorCode"
                      type="text"
                      placeholder=" "
                      value={deviationObj.supervisorCode}
                      onChange={(e) => { handleDropDownChange(e) }}
                    >
                      <option value="">Select</option>
                      {supervisorCodeResult ? supervisorCodeResult.map((supervisor) => (
                        <option key={supervisor.Code} result={supervisor.Dept} value={supervisor.Name}>{supervisor.Name}</option>
                      )) : null
                      }
                    </Form.Select>
                    <label for="supervisorCode">Supervisor Code</label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-input">
                  <div className="d-grid align-items-center">
                    <Form.Control
                      id="supervisorDept"
                      name="supervisorDept"
                      type="text"
                      readOnly
                      placeholder=" "
                      value={deviationObj.supervisorDept}
                    />
                    <label for="supervisorDept">Supervisor Department</label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} xs={12} className="mt-1">
                <Date_Input
                  divClass="Date-input w-100"
                  label="Observation Date From"
                  className="groove_datecolor"
                  name="observationDateFrom"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  todayDate={todayDate}
                  obj={deviationObj}
                  setobj={setDeviationObj}
                  datevalue={datevalue}
                  setdatevalue={setdatevalue}
                  todate={deviationObj.observationDateFrom}
                  setError={setError}
                />
              </Col>
              <Col lg={3} xs={12} className="mt-1">
                <Date_Input
                  divClass="Date-input w-100"
                  label="Observation Date To"
                  className="groove_datecolor"
                  name="observationDateTo"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  todayDate={todayDate}
                  obj={deviationObj}
                  setobj={setDeviationObj}
                  datevalue={datevalue}
                  setdatevalue={setdatevalue}
                  todate={deviationObj.observationDateTo}
                  setError={setError}
                />
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-select">

                  <Form.Select
                    id="observerCode"
                    name="observerCode"
                    type="text"
                    placeholder=" "
                    value={deviationObj.observerCode}
                    onChange={(e) => { handleDropDownChange(e) }}
                  >
                    <option value="">Select</option>
                    {observerCodeResult ? observerCodeResult.map((observerCodeResult) => (
                      <option key={observerCodeResult.Code} result={observerCodeResult.Dept} value={observerCodeResult.Name}>{observerCodeResult.Name}</option>
                    )) : null
                    }
                  </Form.Select>
                  <label for="observerCode">Observer Code</label>

                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-input">
                  <div className="d-grid align-items-center">
                    <Form.Control
                      id="observerDept"
                      name="observerDept"
                      type="text"
                      readOnly
                      placeholder=" "
                      value={deviationObj.observerDept}
                    />
                    <label for="observerDept">
                      Observer Department
                    </label>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3} md={3} xs={12} style={{paddingBottom:"8px"}}>
                <Form.Group class="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="devstatus"
                      name="devstatus"
                      type="text"
                      placeholder=" "
                      value={deviationObj.devstatus}
                      onChange={(e) => { handleChange(e) }}
                    >
                      <option key="select" value="">Select</option>
                      <option key="open" value="Open">Open</option>
                      <option key="closed" value="Closed">Closed</option>
                    </Form.Select>
                    <label for="status">Status</label>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="px-2 pb-2">
            <Row>
              <Col>
                <Button variant="outline-success" type="reset" className="semiBoldToggleButton tempSaveBtn">
                  Reset
                </Button>
              </Col>
              <Col>
                <Button type="submit" className="btn-add-color semiBoldToggleButton rightAlignedButton" >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container >
  );
});

export default DeviationReport;
