import { font } from "./font";
import { fontbold } from "./font_bold";
import { fontitalic } from "./font_italic";
import { fontbolditalic } from "./font_bolditalic";

const Jspdf_font = (doc) =>{
    doc.addFileToVFS("./fonts-rebranding/GE-BodyCopy.ttf", font);
    doc.addFileToVFS("./fonts-rebranding/GE-BodyCopyHighlight.ttf", fontbold);
     doc.addFileToVFS("./fonts-rebranding/GE-BodyCopyHighlightItalic.ttf", fontbolditalic);
    doc.addFileToVFS("./fonts-rebranding/GE-BodyCopyItalic.ttf", fontitalic);
    doc.addFont("./fonts-rebranding/GE-BodyCopy.ttf", "GE-BodyCopy", "normal");
    doc.addFont("./fonts-rebranding/GE-BodyCopyHighlight.ttf", "GE-BodyCopyHighlight", "bold");
    doc.addFont("./fonts-rebranding/GE-BodyCopyItalic.ttf", "GE-BodyCopyItalic", "italic");
   doc.addFont( "./fonts-rebranding/GE-BodyCopyHighlightItalic.ttf", "GE-BodyCopyHighlightItalic", "bolditalic");

     doc.setFont("GE-BodyCopy", "normal");
     doc.setFont("GE-BodyCopyHighlight", "bold");
     doc.setFont("GE-BodyCopyItalic", "italic");
     doc.setFont("GE-BodyCopyHighlightItalic", "bolditalic");
     return doc
}
export default Jspdf_font;