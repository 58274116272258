import Select from "react-select";
import React from "react";

export function SearchSelect(props) {
  // console.log("props_SearchSelect", props);
  // console.log("props.className", props.className);

  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "green" : "green",
    }),
  };

  // const customstyles={
  //   // control represent the select component
  //     control: (provided)=>({
  //       ...provided,
  //       width:'100px',
  //        position: "relative",
  //       left: "25px"

  //    })
  //  }

  return (
    <Select
      // styles={customstyles}
      classNamePrefix="select"
      options={options}
      styles={props.stylegreen}
      required
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      isReadonly={props.readOnly}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
      onKeyDown={props.onBlur}

      // className={props.className}
    />
  );
}

export function SearchSelectprename(props) {
  // console.log("props_SearchSelect", props);
  // console.log("props.className", props.className);

  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "green" : "green",
    }),
  };

  // const customstyles={
  //   // control represent the select component
  //     control: (provided)=>({
  //       ...provided,
  //       width:'100px',
  //        position: "relative",
  //       left: "25px"

  //    })
  //  }

  return (
    <Select
      // styles={customstyles}
      classNamePrefix="select"
      options={options}
      styles={props.stylegreen}
      required
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      isReadonly={props.readOnly}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
      // className={props.className}
    />
  );
}

export function SearchProcessSelect(props) {
  // console.log("props_SearchSelect", props);
  // console.log("props.className", props.className);

  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "green" : "green",
    }),
  };

  // const customstyles={
  //   // control represent the select component
  //     control: (provided)=>({
  //       ...provided,
  //       width:'100px',
  //        position: "relative",
  //       left: "25px"

  //    })
  //  }

  return (
    <Select
      styles={props.customstyles}
      classNamePrefix="select"
      options={options}
      // styles={props.stylegreen}
      required
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      isReadonly={props.readOnly}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
      // className={props.className}
    />
  );
}

export function SearchProcessSelect1(props) {
  // console.log("props_SearchSelect", props);
  // console.log("props.className", props.className);

  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "green" : "green",
    }),
  };

  // const customstyles={
  //   // control represent the select component
  //     control: (provided)=>({
  //       ...provided,
  //       width:'100px',
  //        position: "relative",
  //       left: "25px"

  //    })
  //  }

  return (
    <Select
      styles={props.customstyles2}
      classNamePrefix="select"
      options={options}
      // styles={props.stylegreen}
      required
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      isReadonly={props.readOnly}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        // 
        props.onChange(opt);
      }}
      // className={props.className}
    />
  );
}

export function SearchSelectProject(props) {
  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "blue" : "blue",
    }),
  };
  return (
    <Select
      id={props.id}
      name={props.name}
      classNamePrefix="select"
      // className={props.className}
      options={options}
      styles={props.stylegreen}
      required
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
    />
  );
}

export function SearchSelectWIProject(props) {
  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "blue" : "blue",
    }),
  };
  return (
    <Select
      id={props.id}
      name={props.name}
      classNamePrefix="select"
      // className={props.className}
      options={options}
      styles={props.stylegreen}
      required={props.mandatory}
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
    />
  );
}

export function SearchSelectWelder(props) {
  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField] != null 
      ? item[props.labelField] 
      : item[props.labelField1] + "-" + item[props.labelField2];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "blue" : "blue",
    }),
  };
  return (
    <Select
      id={props.id}
      name={props.name}
      classNamePrefix="select"
      // className={props.className}
      options={options}
      styles={props.stylegreen}
      required
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
    />
  );
}

export function ModalSelect(props) {
  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  // const green = {
  //   control: (baseStyles, state) => ({
  //     ...baseStyles,
  //     borderColor: state.isFocused ? "green" : "green",
  //   }),
  // };

  return (
    <Select
      // styles={customstyles}
      classNamePrefix="select"
      options={options}
      styles={props.stylegreen}
      //required={props?.required ? props.required : false}
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      isReadonly={props.readOnly}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
      id={props.id}
      name={props.name}
      onKeyDown={props.onBlur}
      // className={props.className}
    />
  );
}

export function SearchSelectReportField(props) {
  console.log("yyyprops", props);
  let options = [];

  props?.data?.map((item, index) => {
    let option = {};
    option.label = item[props.labelField];
    option.value = item[props.valueField];
    options.push(option);
  });

  const green = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--col-ddd)",
    }),
  };
  return (
    <Select
      id={props.id}
      name={props.name}
      classNamePrefix="select"
      // className={props.className}
      options={options}
      styles={props.stylegreen}
      required={props.mandatory}
      value={props.value && { label: props.value, value: props.value }}
      isDisabled={props.disabled}
      placeholder={`${props.placeholder}`}
      onChange={(opt) => {
        
        props.onChange(opt);
      }}
    />
  );
}
