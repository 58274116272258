import React from "react";
import { ToastContainer, toast } from "react-toastify";

const Toaster = (props) => {
    return (


        <ToastContainer
            autoClose={1200}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    )

}

export default Toaster;

