import React from "react";
import Edit from "../../icons/EditIcon.svg";
import Edit_gray from "../../icons/Edit_gray.svg";
import Temp_Save from "../../icons/Temp-Save.svg";
import copy from "../../icons/copy.svg";
import Export from "../../icons/Export.svg";
import Delete_gray from "../../icons/Delete_gray.svg";
import Delete from "../../icons/DeleteIcon.svg";
// import Export_gray from "../../icons/Download_grey.svg"


export default (props) => {
  return (
    <div className="cell-action">
     
      <img
        onMouseOver={(e) => (e.currentTarget.src = Edit)}
        onMouseOut={(e) => (e.currentTarget.src = Edit_gray)}
        src={Edit_gray}
        alt="edit"
        style={{position:"relative",bottom:"8px"}}
        onClick={() => props.handleEditEvent(props.selectedData)}
      />

    </div>
  );
};

