import axios from "axios";

let MASTER_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}
export async function getAllInspectionAgency() {
  const response = await axios
    .get(MASTER_API_URL + "/inspectionagency")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });

  return response.data;
}

export async function getActiveInsp() {
  const response = await axios
    .get(MASTER_API_URL + "/inspectionagency/activeinsp")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getActiveInsp_response.data", response.data);
  return response.data;
}

export const AddNewInspectionAgency = async (InspectionAgency) => {
  let url = `${MASTER_API_URL}/inspectionagency`;
  const response = await axios
    .post(url, InspectionAgency)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log(error);
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateInspectionAgency = async (User) => {
  let url = `${MASTER_API_URL}/inspectionagency/update`;
  const response = await axios
    .post(url, User)
    .then(function (resp) {
      console.log(resp);
      return resp;
    })
    .catch(function (error) {
      console.log(error);
      return { message: "Unable to find request", status: false };
    });
  console.log(response);
  return response.data;
};
