import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Delete_green from "../icons/Delete_green.svg";
import Delete from "../icons/Delete.svg";

function DragDropRender(props) {
  console.log("DragDropRender props ====> ", props);
  return (
    <Form.Group className="dropzone-fileupload">
      <div className="dropZoneContainer">
        <Dropzone
          onDrop={(acceptedFiles) => props.handleChangeEvent(acceptedFiles)}
          noClick={props.disableExlUpload}
          multiple={false}
          maxFiles={1}
          accept="image/jpeg, image/png, image/jpg"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropZoneMainDiv">
              <input {...getInputProps()} />
              <div className="dropTextContainer">
                <Row>
                  <span />
                  <span className="semiBoldToggleButton">
                    <b>Upload file here or &nbsp;</b>
                    <span className="dropSelectText">
                      <b>Select a file</b>
                    </span>
                  </span>
                  <span>Supports : .Xlsx, .Xls: Max 5MB</span>
                </Row>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </Form.Group>
  );
}

export default DragDropRender;
