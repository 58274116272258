import React from "react";
import { usePagination } from "../../hooks/usePagination";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "./pagination.css"
import { Pagination as PaginationReact, Row, Col, Button } from "react-bootstrap";

export default function Pagination(props) {  
  const { onPageChange, totalCount, currentPage, pageSize } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  let lastPage = paginationRange?.totalPageCount;
  let pageText = `${paginationRange?.startInd} to ${paginationRange?.endInd} of ${totalCount} deviations`;
  
  const onNext = () => {    
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    } else {
      onPageChange(1);
    }
  };

  return (
    <>
      <Row>
        <span className="fontCss">{pageText}</span>
      </Row>
      <Row>
        <Col>
          <span className="fontCss">The page you're on  </span>
        </Col>
        <Col>
          <div className="pagintion-bar">
            <span className="fontCssPage">{currentPage}</span>
            <PaginationReact>
              &nbsp;<div className="verticalLine" style={{ marginTop: "6px" }}></div>&nbsp;<ChevronLeft
                size={14}
                onClick={onPrevious}
                className={currentPage === 1 ? "disabled" : ""}
              />
              <Col className="centerAlign hstack">
                <Button variant="link" className="resetBtndev semiBoldToggleButton prevNext" onClick={onPrevious}>
                  Prev
                </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<div className="verticalLine"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="link" className="resetBtndev semiBoldToggleButton prevNext" onClick={onNext}>
                  Next
                </Button>
              </Col>
              <ChevronRight
                size={14}
                onClick={onNext}
                className={currentPage === lastPage ? "disabled" : ""}
              />
            </PaginationReact>
          </div>
        </Col>
      </Row>
    </>
  );
};


