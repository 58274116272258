import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { Form } from "react-bootstrap";

//-----below in use

export const WPS_Process_Grid_InputProcess = ({
  detailObj,
  ColumnHeader,
  Columndimension,
  type,
  name,
  onInput,
  disabled,
  maxLength,
}) => {
  console.log("wps_detail_WPS_Process_Grid_Input", detailObj);
  return (
    <tr className={"border WpsInput-md"}>
      <th
        className={"text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
        </div>
      </th>
      <td
            className={"border WPSSelect"}
            // key={id}
          >
            <input
              style={{width:"275px"}}
              type={type}
              name={name}
              value={"GTAW"}
              readOnly
              disabled={disabled}
              maxLength={maxLength}
            />
          </td>

       </tr>
  );
};
export const General_Single_Input_select = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          disabled={disabled}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const Single_Input = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  labeloptional,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? " T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
        {/* <label for={name}>{labeloptional}</label> */}
        <label style={{ fontStyle: "italic", color: "var(--col-707070)" }}>
          {labeloptional}
        </label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const WPS_Process_Grid_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  detailObj,
  maxLength,
  width1
}) => {
  
  let textwidth = width1 != undefined ? width1 : "";
  return (   
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      <td className={status ? "border WPSSelect error" : "border WPSSelect"}>
        <input
        style={{position:"relative",top:"4px",width:textwidth}}
          className={status ? "error-input" : ""}
          type={type}
          name={name}
          value={detailObj?.[name]}
          maxLength={maxLength}
          onChange={(e) => {
            handleChangedetailsarray(e);
          }}
          onInput={onInput}
        />
      </td>
    </tr>
  );
};

export const WPS_Process_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  detailObj,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  obj,
  setfunction,
  onInput,
  ColumnHeader1,
  name1,
  width1
}) => {
 let textwidth = width1 !=undefined ? width1 : "" ; 
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div >
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3">
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-center">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      <td className={status ? "border WPSSelect error" : "border WPSSelect"}>
        <div className="d-flex flex-column align-items-center">
          <input
            type={type}
            className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
            name={name}
            value={detailObj?.[name]}
            style={{width:textwidth}}
            readOnly={readOnly}
            maxLength={maxLength}
            onChange={(e) => {
              handleChangedetailsarray(e);
            }}
            onInput={onInput}
          />

          <input
            type={type}
            className={status ? "error-input mt-2" : "mt-2"}
            name={name1}
            value={detailObj?.[name1]}
            style={{width:textwidth}}
            readOnly={readOnly}
            maxLength={maxLength}
            onChange={(e) => {
              handleChangedetailsarray(e);
            }}
            onInput={onInput1}
          />
        </div>
      </td>
    </tr>
  );
};

export const WPS_Process_Grid_Select = ({
  status,
  message,
  detailObj,
  ColumnHeader,
  Columndimension,
  array,
  name,
  handleChangedetailsarray,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      <td className={status ? "border WPSSelect error" : "border WPSSelect"}>
        <select
          className={status ? "error-input" : ""}
          name={name}
          value={detailObj?.[name]}
          onChange={(e) => {
            handleChangedetailsarray(e);
          }}
        >
          {array &&
            array.map((e) => {
              return <option value={e.value}>{e.name}</option>;
            })}
        </select>
      </td>
    </tr>
  );
};
