import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";

import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useLocation, useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleGlobalSearch1 } from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Button, Row, Col, Container } from "react-bootstrap";
import Export from "../../icons/Export.svg";
// import { Action, Status } from "./WPSObjects/WPQ_entity";
import {
  AddNewGroove,
  getallgroove,
  getGridHeaderData,
  getPresignedURLgrid,
  getPresignedURLgriddelete,
  UpdateRecord,
} from "../../Services/wpq_groove-services";

import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import ActionRendererWps from "../utility/ActionRendererWps";
import BarLoader from "react-spinners/BarLoader";
import { getAllDetails, getGridData } from "../../Services/wps_details-service";
import {
  ImageUploadRenderer,
  QualificationDueInRenderer,
} from "../utility/ImageUploadRenderer";
import axios from "axios";
// import Pdf_DownloadWPQ from "./PDF/Pdf_DownloadWPQ";
import { getPresignedURL } from "../../Services/Welder-services";
import { getwpqlink } from "../../Services/WI-services";
import { RenderIf } from "../../functions/welding-functions";
import MultiValuePopUp from "../utility/MultiValuePopUp";
import { GridLoader } from "react-spinners";
import ActionRendererWPQLinking from "../utility/ActionRendererWPQLinking";

const Wpq_linking = forwardRef((props, ref) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState({});
  const [welData, setWelData] = useState({});

  const [filteredCol, setFilteredCol] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [filtereddetailResult, setFilteredDetailResult] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [Groove, setGroove] = useState(null);
  const [details, setdetails] = useState(null);

  const [Overlay, setOverlay] = useState(null);

  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");

  const [pdf, setpdf] = useState([]);

  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);

  const [loadingReportData, setloadingReportData] = useState(false);

  const [detailObj, setdetailObj] = useState([]);

  const [profileOriginal, setProfileOriginal] = useState("");

  const [profileRename, setProfileRename] = useState("");

  const [Dateresult, setDateResult] = useState(''); // State to hold the result


  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();

  let username = localStorage.getItem("UserName");

  let Band = sessionStorage.getItem("Band");

  let folderwpq = "WPQ_Groove"

  let initiator = Band !== "P" && Band !== "T";

  let finalApprover = Band === "P" || Band === "T";

  let userprofile = sessionStorage.getItem("UserProfile");

  const [WPStype, setWPStype] = useState(props.type);

  const [userObj, setuserObj] = useState("");

  const [count, setCount] = useState("0");

  const [countrow, setCountrow] = useState("");

  const [loading, setLoading] = useState(true);

  const [Imgloading, setImgLoading] = useState(true);

  const [JointType, setJointType] = useState("");

  const [WIWelderid, setWIWelderid] = useState("");



  const childRef = useRef();

  const year = new Date().getFullYear()
  const[selectedYear,setSelectedYear] = useState(year) 
  console.log("selected",new Date().getFullYear(),selectedYear)

  const[isselected,setisselected] = useState(false) 




  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });


  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };


  const WPQ_Linking_Radio = {
    Linked: "Link WPQ with Project",
    UnLinked: "Linking WPQ with project not Required",
  };

  const HyperLink = (params, row) => {
    console.log("HyperLink", params);
    console.log("row111", row);
    console.log("HyperLink22", params.data);
    console.log("jjjjjjj", params.data.WI_Joint_Type);
    console.log("fffssdad",params.data.WI_WPQ_Data)

    return (
      <Button
      variant="link"
      onClick={() => {
        setJointType(params.data.WI_Joint_Type);
        setWIWelderid(params.data.WI_WPQ_Data);
        setWelData(params.data);
    
        if (params.data.WI_Wpq_Radio !== WPQ_Linking_Radio.UnLinked) {
          const welderIds = params.data.WI_Welder_Id.split(",");
          const filteredData = params.data.WI_WPQ_Data.filter(item => welderIds.includes(item.WPQ_WelderId));
          showPopUp(
            "WPQLinkGrid",
            "Multiple",
            "WI_Welder_Id",
            filteredData
          );
        }
      }}
      size="sm"
    >
      {params.data.WI_Welder_Id}
    </Button>
    );
  };

  console.log("WElfd",WIWelderid)

  const tmpcolumnDefs = [

    {
      headerName: "Project No.",
      field: "WI_Project_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Joint Type",
      field: "WI_Joint_Type",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Joint No.",
      field: "WI_Joint_No",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Linked WPQs",
      field: "WI_Welder_Id",
      type: "Text",
      isCheckbox: false,
      cellRenderer: HyperLink,
    },
   
  ];

  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

//   const fetchLinking = async () => {
//     
    
//     console.log("selectedYear", selectedYear);
//     var result = await getwpqlink();
//     console.log("myresult", result);
    
//     const filter = result.filter(item => {
//         if (item.WI_Createdon) {
//             let dateParts = item.WI_Createdon.split("-");
//             if (dateParts.length === 3) {
//                 return dateParts[0] === selectedYear;
//             }
//         }
//         return false;
//     });
//     console.log("filterrrr", filter);
    
//     setGroove(filter);
//     setLoading(false);
//     setFilteredResult(filter);

//     fetchColumnDefns();
// };


const fetchLinking = async () => {
  try {
      
      
      console.log("selectedYear", selectedYear);
      const result = await getwpqlink();
      console.log("myresult", result);
      
      if (result && result.length > 0) {
          const filter = result.filter(item => {
              if (item.WI_Createdon) {
                  const yearPart = item.WI_Createdon.split("-")[0];
                  return yearPart === selectedYear.toString();
              }
              return false;
          });
          
          console.log("filterrrr", filter);
          
          setGroove(filter);
          setLoading(false);
          setFilteredResult(filter);
      
          fetchColumnDefns();
      } else {
          console.log("No data found.");
      }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
};


  const fetchColumnDefns =  () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);

    console.log("reeeeessa",result)
    result[3].cellRenderer = HyperLink;

    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      minWidth: 250,
      suppressMenu: true,
      sortable:false,
    });
    setColumnDefs(result);
  };

  useEffect(() => {
    console.log("selected1",selectedYear)
    fetchLinking();
    // jointtype()
  }, [selectedYear]);




  function handleClick() {
      navigate("/Wpqlinking/AddEdit", {
        state: { data: "", action: "Add" },
      });
  }

  function handleClickedit(row) {
    
    console.log("editdata", row);

      navigate("/Wpqlinking/AddEdit", {
        state: {
          data: row,
          action: "Edit",
        },
      });
   
    
  }


  useImperativeHandle(ref, () => ({
    handleAdd: () => {
      handleClick();
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(Groove);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setGroove",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    childRef.current.setSearch(text);
  };


  console.log("filteredResult",filteredResult)

  
  const handleGlobalExport = () => {
    console.log("rowdata",filteredResult)
  
    // Define the fields you want to export
    const allFields = [
      'WI_Project_No', 
      'WI_Joint_Type', 
      'WI_Joint_No', 
      "WPQ_Inspection",
      "WPQ_PlantLocation",
      "WPQ_Qualificationcode",
      "WPQ_specialNote",
      // 'WI_Welder_Id', 
      // 'WI_WPQ_Data', // Remove this line
    ];
  
    // Create column definitions for all fields
    const allColumnDefs = allFields.map(field => ({
      headerName: field,
      field: field,
    }));
  
    // Add a column for WPQ_WelderName
    allColumnDefs.push({
      headerName: 'WPQ_WelderName',
      field: 'WPQ_WelderName',
    });

    allColumnDefs.push({
      headerName: 'WPQ_WelderId',
      field: 'WPQ_WelderId',
    });

    allColumnDefs.push({
      headerName: 'WPQ_RecordNo',
      field: 'WPQ_RecordNo',
    });

    allColumnDefs.push({
      headerName: 'WPQ_Inspection',
      field: 'WPQ_Inspection',
    });

    allColumnDefs.push({
      headerName: 'WPQ_PlantLocation',
      field: 'WPQ_PlantLocation',
    });

    allColumnDefs.push({
      headerName: 'WPQ_Qualificationcode',
      field: 'WPQ_Qualificationcode',
    });

    allColumnDefs.push({
      headerName: 'WPQ_specialNote',
      field: 'WPQ_specialNote',
    });
  
    // Process filteredResult to include WPQ_WelderName
    const processedResult = filteredResult.map(item => {
      // Join all WPQ_WelderName values into a single string
      const welderId = item.WI_WPQ_Data.map(wpq => wpq.WPQ_WelderId).join(', ');

      const welderNames = item.WI_WPQ_Data.map(wpq => wpq.WPQ_WelderName).join(', ');

      const welderRecordno = item.WI_WPQ_Data.map(wpq => wpq.WPQ_RecordNo).join(', ');

      const WelderInspection = item.WI_WPQ_Data.map(wpq => wpq.WPQ_Inspection).join(', ');

      const welderPlantlocation = item.WI_WPQ_Data.map(wpq => wpq.WPQ_PlantLocation).join(', ');

      const welderQualificationcode = item.WI_WPQ_Data.map(wpq => wpq.WPQ_Qualificationcode).join(', ');

      const welderSpecialnote = item.WI_WPQ_Data.map(wpq => wpq.WPQ_specialNote).join(', ');


  
      // Return a new object with the original data and the new WPQ_WelderName field
      return {
        ...item,
        WPQ_WelderId: welderId,
        WPQ_WelderName: welderNames,
        WPQ_RecordNo: welderRecordno,
        WPQ_Inspection:WelderInspection,
        WPQ_PlantLocation:welderPlantlocation,
        WPQ_Qualificationcode:welderQualificationcode,
        WPQ_specialNote:welderSpecialnote,
        

      };
    });
  
    let filename = "Project to WPQ linking - Groove, Overlay, Tube to Tubesheet";
    let result = handleExport1(processedResult, filename, allColumnDefs);
  };

  const handleEdit = (row) => {
    setAction("Edit");
    setRowData(row);
  };


  const actionRenderer = (params) => {
    return (
      <ActionRendererWPQLinking
        selectedData={params}
        handleEditEvent={(params) => handleClickedit(params.data)}
      />
    );
  };

  console.log("errrrorow", rowData);


  const handleimage = (row) => {
    setRowData(row);
    setShowForm(true);
    console.log("rowdata_justpass", rowData);
  };


  // const jointtype = (row) => {
  //   console.log("rowdata_justpass", row);
  //   setRowData(row);
  //   setJointType(row);
  // };

  console.log("joiiii", JointType);



  const QualificationDueInRenderer1 =  (params) => {
    const [selectedDate, setSelectedDate] = useState(params.data.WPQ_Date);
    const [futureDate, setFutureDate] = useState(null);
    const [pDate, setpDate] = useState(null);
    const [months, setMonths] = useState(0);
    const [days, setDays] = useState(0);


    const [result, setResult] = useState(''); 

    const handleResult = (resultValue) => {
      setResult(resultValue); 
    };

    console.log("rreeeess",result)

    setDateResult(result)

    useEffect(() => {
      handleResult(result);
    }, [result, params.handleResult]);
    return (
      <>
        <QualificationDueInRenderer
          handleResult={handleResult} 
          selectedData={params}
          setFutureDate={setFutureDate}
          futureDate={futureDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          pDate={pDate}
          setpDate={setpDate}
          months={months}
          setMonths={setMonths}
          days={days}
          setDays={setDays}
        />
      </>
    );
  };

  const ImageUploadRenderer1 = (params) => {
    

    const [profileOg, setProfileOg] = useState("");
    const [ImageObjectURL, setImageObjectURL] = useState("");
    const [PreviewUrl, setPreviewUrl] = useState([]);
    const [PreUrl, setPreUrl] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [profile, setProfile] = useState("");
    const [progress, setProgress] = useState(0);
    const [deleteCounter, setDeleteCounter] = useState(0);
    const [downloadloading, setdownloadLoading] = useState(false);

    //////////////////// ADD FUNCTION ///////////////////////////////////////////

    const handleFile = async (file, row, data) => {
      

      console.log("ffffffii",file, file.name)
      let res;
      const maxSize = 40 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit.");
        file = null;
        return;
      }
      

      let uploadextension = file.name.split(".")
      console.log("fffnfifm",uploadextension)

      if (uploadextension[1] !== "pdf" ) {
        alert("Please Upload Pdf");
        file = null;
        return;
      }
      setProfileOg(file);
      let abc = new File([file], `${row.WPQ_WelderId}_${row.WPQ_RecordNo}.pdf`);
      console.log("filenew", abc);
      setProfile(abc);

      const objectURL = URL.createObjectURL(abc);
      setImageObjectURL(objectURL);

      setRowData(row);

      row.WPQ_DocImage = abc.name;
      row.WPQ_DocImageOriginal = file.name;

      setProfileRename(row.WPQ_DocImage);

      if (row.WPQ_DocImageUrl === "") {
        let startByte = 0;
        while (startByte < file.size) {
          const endByte = Math.min(startByte + chunkSize, file.size);
          await add(row, abc, res, startByte, endByte);
          startByte = endByte;
        }
        setProgress(0);
        alert("File upload complete!");
      }
    };
    //////////////////// END //////////////////////////////////////////////////////////

    //////////////////// DOWNLOAD FUNCTION ///////////////////////////////////////////

    const handleDownload = async (row, data) => {
      
      const result = await getPresignedURL(folderwpq,row.WPQ_DocImage);
      const geturl = result.url;
      setPreviewUrl(geturl);
      console.log("urrl", geturl);
      setdownloadLoading(true);
      fetch(geturl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank";
          anchor.download = row.WPQ_DocImageOriginal;

          anchor.click();
          window.URL.revokeObjectURL(url);

          setdownloadLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
        });
      // setDeleteCounter(deleteCounter + 1);
    };
    //////////////////// END /////////////////////////////////////////////

    ///////////////////// DELETE FUNCTION ///////////////////////////////////////////
    const handleDelete = async (row, abc, res) => {
      
      row.WPQ_DocImageOriginal = "";
      row.WPQ_DocImageUrl = "";
      row.WPQ_DocImage = "";
      row.WPQ_DocImageData.pdfsize = "";
      row.WPQ_DocImageData.pdfname = "";
      row.WPQ_DocImageData.pdftype = "";
      res = await UpdateRecord(row);
      setDeleteCounter(deleteCounter + 1);
    };
    ///////////////////// END ////////////////////////////////////////////////////////

    const chunkSize = 1024 * 1024;
    let uploadedBytes = 0;

    const add = async (row, abc, res, start, end) => {
      const chunk = profile.slice(start, end);
      const formData = new FormData();
      formData.append("chunk", chunk);
      formData.append("filename", profile.name);

      const result = await getPresignedURL(folderwpq,row.WPQ_DocImage);
      setPreUrl(result);
      row.WPQ_DocImageUrl = result.url;

      res = await UpdateRecord(row);

      const posturl = result.url;
      setPreviewUrl(posturl);

      try {
        await axios.put(posturl, abc, {
          onUploadProgress: (progressEvent) => {
            uploadedBytes += progressEvent.loaded;
            const completed = Math.min(
              100,
              Math.round((uploadedBytes / end) * 100)
            );
            setProgress(completed);
          },
        });
      } catch (error) {
        console.error("Error uploading chunk:", error);
        alert("File upload failed.");
        return;
      }
    };

    return (
      <>
        <ImageUploadRenderer
          Imgloading={Imgloading}
          downloadloading={downloadloading}
          setdownloadLoading={setdownloadLoading}
          key={deleteCounter}
          imageData={imageData}
          progress={progress}
          PreviewUrl={params.data.WPQ_DocImageUrl}
          profileOg={params.data.WPQ_DocImageOriginal}
          id={`fileUpload_${params.data.SK}`}
          name="fileInput"
          type="file"
          accept="application/pdf"
          ref={fileInputRef}
          selectedData={params}
          handleFile={(e, params) =>
            handleFile(e.target.files[0], params.data, imageData)
          }
          handleDownload={(params) => handleDownload(params.data, imageData)}
          handleDelete={(params) => handleDelete(params.data, imageData)}
          handleimage={(params) => handleimage(params.data)}
        />
      </>
    );
  };

  
  const handleYearChange = (e) =>{
    // console.log("selected",e)
     setSelectedYear(e.value)
   }

  return (
    <>
    {loading && (
      <div className="loader-overlay">
        <GridLoader size={10} className="pageloader" />
      </div>
    )}
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          WPQ Transaction list
        </Col>

        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for " +
                " Project No, Joint Type, Joint No, Linked WPQ"
              }
              // filterFunc={handleGlobalSearch}
              withYear={true}
              filterFunc={handleGlobalSearch}
              YearHandleChange={handleYearChange}
              YearSelected={selectedYear} 
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>
      {/* {loading && (
        <BarLoader color="blue" height={5} width={195} className="gridloader" />
      )} */}

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
      />


<RenderIf isTrue={popUp.type === "Multiple" && showForm}>
          <MultiValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            // setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            backdrop="static"
            JointType={JointType}
            WIWelderid={WIWelderid}
            wiobj={welData}
            // action={Edit}
          />
        </RenderIf>

      {/* {showForm && (
        <Pdf_DownloadWPQ
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          userObj={userObj}
          allRecs={Groove}
          backdrop="static"
        />
      )} */}
      <Toaster />
    </Container>
    </>
  );
});

export default Wpq_linking;
