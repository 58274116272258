import axios from "axios";

let GROOVE_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  GROOVE_API_URL = process.env.REACT_APP_WPQ_GROOVE_API_URL;
} else {
  GROOVE_API_URL = process.env.REACT_APP_API_URL + "wpq";
}

export async function getAllDetails() {
  const response = await axios
    .get(GROOVE_API_URL + "/WpqDetailsMast")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllWPSNo() {
  const response = await axios
    .get(GROOVE_API_URL + "/WpqDetailsMast/allwpsno")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export const getdetailsById = async (IddetailPK, IddetailSK) => {
  let url = `${GROOVE_API_URL}/WpqDetailsMast/getdetailsById`;
  let reqbody={PK:IddetailPK,SK:IddetailSK}
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getdetailsByIdTube = async (IdprojectPK, IdprojectSK, IdPK, IdSK) => {
  console.log("tubesheetdata....", IdprojectPK, IdprojectSK , IdPK , IdSK)
  let url = `${GROOVE_API_URL}/WpqDetailsMast/tubesheet/get`;
  let reqbody={DetailPK:IdprojectPK,DetailSK:IdprojectSK,PK:IdPK,SK:IdSK}
  console.log("url", url)
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
    console.log("tuberesponeseeee",response)
  return response.data;
};



export const AddNewDetail =  (WPQ_Details_Master) => {
  
  console.log("AddNewdetail -- ", WPQ_Details_Master);
 let url = `${GROOVE_API_URL}/WpqDetailsMast`;
try {
      const response =  axios.post(url, WPQ_Details_Master);
      console.log("AddNewDetail response:", response);
      return response;
  } catch (error) {
      console.error("Error in AddNewDetail:", error);
      return { message: "Unable to add detail", status: false };
  }
};


export const updatedatewpq = async (PK, SK, DetailPK, DetailSK) => {
  
  console.log("updatedatewpq",PK, SK, DetailPK, DetailSK)
  let url = `${GROOVE_API_URL}/WpqDetailsMast/updatedate`;
  let reqbody ={PK:PK,SK:SK,DetailPK:DetailPK,DetailSK:DetailSK}
  console.log("url", url)
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};



export const getdetailsInWI = async (PK, PK1, SK, SK1) => {
  
  let url = `${GROOVE_API_URL}/WpqDetailsMast/getdetailsInWI`;
  // let reqbody ={PK:PK,DetailPK:PK1,SK:SK,DetailSK:SK1}
  let reqbody ={PK:PK,PK1:PK1,SK:SK,SK1:SK1}

  console.log("url", url)
  const response = await axios
    .post(url,reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};


export const AddNewDetailTubesheet = async (WPQ_Details_Master) => {
  
  console.log("dddddeewwww",WPQ_Details_Master)
  let url = `${GROOVE_API_URL}/WpqDetailsMast/tubesheet/add`;
  console.log("urururur",url)
  const response = await axios
    .post(url, WPQ_Details_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateWPQDetail = async (WPQ_Detail) => {
  let url = `${GROOVE_API_URL}/WpqDetailsMast/UpdateWPQDetail`;
  console.log("UpdateWPQDetail", url);
  console.log(
    "UpdateWPQDetail11111",
    WPQ_Detail
  );
  const response = await axios
    .post(url, WPQ_Detail)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const Updatedetails = async (WPQ_Details_Master) => {
  let url = `${GROOVE_API_URL}/WpqDetailsMast/update`;
  const response = await axios
    .post(url, WPQ_Details_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};


