import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import SuccessAlertModal from "../../Components/Modals/successAlertModal";
import ConfirmAlertModal from "../../Components/Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import {
  getAllRecordsWeldingProcess,
  DeleteWeldingProcess,
  UpdateWeldingProcess,
} from "../../Services/weldingprocess-services";
import AddEditWeldingProcessMaster from "./AddEditWeldingProcessMaster";
import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../utility/GridMaster";
import ActionRenderer from "../utility/ActionRenderer";
import Toaster from "../utility/Toaster";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import Export from "../../icons/Export.svg";

const WeldingProcess = forwardRef((props, ref) => {
  let formName = "WeldingProcess";
  const gridRef = useRef();
  const [rowData, setRowData] = useState({});
  const [filteredCol, setFilteredCol] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [pcategories, setPcategories] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);

  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);

  let username = localStorage.getItem("UserName");
  const childRef = useRef();

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "wld_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "statusRenderer",
      sortable: true,
      sort: 'asc',
    },
    {
      headerName: "Process",
      field: "SK",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Process Category",
      field: "wld_p_category",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Process Description",
      field: "wld_description",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  const handleEdit = (row) => {
    console.log(row);
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchWeldingProcess = async () => {
    const result = await getAllRecordsWeldingProcess();
    setPcategories(result);
    setFilteredResult(result);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ field: "", cellRenderer: actionRenderer, width: 250 ,  suppressMenu: true,
    sortable:false,});
    console.log(result);
    setcolumnDefs(result);
  };

  useEffect(() => {
    fetchWeldingProcess();
    fetchColumnDefns();
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(pcategories);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setWeldingProcess",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    // let searchText = text.toLowerCase();
    childRef.current.setSearch(text);

    // if (searchText) {
    //   let result = handleGlobalSearch1(
    //     text,
    //     pcategories,
    //     "wld_code",
    //     "wld_p_category",
    //     "wld_description"
    //   );
    //   setFilteredResult(result);
    // } else {
    //   setFilteredResult(pcategories);
    // }
  };

  const handleGlobalExport = () => {
    let filename = "WeldingProcessMaster";
    setExcelData(filteredResult);
    let result = handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const actionRenderer = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  const handleDelete = (row) => {
    let result;
    data.wld_status = "Inactive";
    result = UpdateWeldingProcess(data);
    result
      .then((resp) => {
        if (resp.message === "Success") {
          setShowForm(false);
          toast.success(
            `Selected Welding Process has been Deleted successfully`,
            {
              position: toast.POSITION.TOP_LEFT,

              className: "toast-message",
            }
          );
        } else {
          toast.warning("Error!!!, Not able to deactivate  Welding Process", {
            position: toast.POSITION.TOP_LEFT,

            className: "toast-message",
          });
        }
        setReload(!reload);
      })
      .catch((error) => {
        console.log("Unable to process request");
        toast.warning("Error!!!, Not able to Add or deactivate Data", {
          position: toast.POSITION.TOP_LEFT,

          className: "toast-message",
        }); 
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  return (
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Welding Process list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              filterFunc={handleGlobalSearch}
              searchplaceholdertext={
                "Search for Process Code,Process Category,Process Description"
              }
            />
            {/* <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            /> */}
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
      />

      {showForm && (
        <AddEditWeldingProcessMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          fetchUserMaster={fetchWeldingProcess}
          allRecs={pcategories}
          backdrop="static"
        />
      )}
      <Toaster />
    </Container>
  );
});

export default WeldingProcess;
