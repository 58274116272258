import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert, Navbar, Container } from "react-bootstrap";
import godrejLogo from "../styles/images/Godrej_LogoGodrejLogo_Login.svg";
// import WeldingLogin from "../styles/images/WeldingLogin.jpeg";

import WeldingLogo from "../styles/images/weldingLogo.svg";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export default function LoginPage({ showLoginError }) {
  console.log("showLoginError_LoginPage", showLoginError);

  const [alertMessage, setAlertMessage] = useState("");
  let err = "";
  const [errorMessage, setErrorMessage] = useState(
    "Oh! User does not exist / inactive in application"
  );
  const { instance } = useMsal();
  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }
  return (
    <div className="login-container">
      {alertMessage && (
        <Alert className="alertBoxLogin" variant="success">
          {alertMessage}
        </Alert>
      )}
      <Navbar>
        <Container>
          <Navbar.Brand>
            <img
              alt=""
              src="/godrej-logo.svg"
            />
          </Navbar.Brand>
          <p className="login-text">
            Godrej Enterprises Group
          </p>
        </Container>
      </Navbar>
      <div className="place-right">
          <div className="loginLeftImage" >
            <img src="/WeldingLogin.png" style={{width:"100%", height: "100%", objectFit: "cover"}} alt="WeldingLogin" />
          </div>
        <div  className="rightSideLoginPage" > 
          {/* <div className="LoginRightImage text-center px-4" style={{position:"relative", top:"15%"}}>
            <div>
              <img
                src={godrejLogo}
                alt="godrejLogo"
                className="LoginRightImageGodrej mt-5"
                style={{ position: "relative", left: "0px", top:"20px", width:"95px"}}
              />
            </div>
            <></> */}
            <div>
              {/* <img
                src={WeldingLogo}
                alt="WeldingLogo"
                className="LoginRightImageAem mt-5 "
                style={{ height: "42px", position: "relative", left: "0px" }}
              /> */}
              <p className="login-text">Welding System</p>
            </div>
            {/* <></>
            <br></br> <br></br> <br></br> */}
            <div className="mt-3">
              {showLoginError && (
                <div className="loginErrorDiv">
                  <div className="loginErrorDivHeader">Login Failed</div>
                  <div className="loginErrorDivBody">{errorMessage}</div>
                </div>
              )}
            </div>
            <div className="LoginRightInput">
              <Button
                onClick={() => handleLogin(instance)}
                className={"forgotButton"}
              >
                Login
              </Button>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
