import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { Form } from "react-bootstrap";
import { SearchSelect } from "../../utility/search-select";
import { isAlphanumericAllSpecialCharacters } from "../../../functions/validations";

export const WPS_Process_Grid_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  detailObj,
  maxLength,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>


          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
          >
            <input
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={detailObj?.[name]}
              style={{width:"512px"}}
              // readOnly={readOnly}
              // disabled={disabled}
              maxLength={maxLength}
              // value={item.WPSD_Process !== "" &&  item.WPSD_Type !== "" ? item.WPSD_WP_MinimumPreheatTemp : "NA"}
              // disabled={ location.state?.action === "Edit" && item.WPSD_WP_MinimumPreheatTemp === ""}
              // readOnly={item.WPSD_Process === "" &&  item.WPSD_Type === ""}
              onChange={(e) => {
                handleChangedetailsarray(e);
              }}
              onInput={onInput}
            />
          </td>
          

    </tr>
  );
};

export const WPS_Process_Grid_Input2_tube = ({
    status,
    status1,
    item,
    message,
    message1,
    Error,
    wps_detail,
    ColumnHeader,
    Columndimension,
    type,
    name,
    setError,
    error,
    value,
    readOnly,
    handleChangedetailsarray,
    onInput,
    disabled,
    detailObj,
    handle_2Input_ChangeA,
    handle_2Input_ChangeB,
    maxLength,
  }) => {
    
    console.log("detailo",detailObj)
    console.log("statt",status)
    console.log("eeeeerrw",error)


    return (
      <tr className={status || status1 ? "border WpsInput-md error" : "border WpsInput-md"}>
        <th
          className={status || status1 ? "text-start thead-color" : "text-start thead-color"}
        >
          <div>
            <div className="headwidth">{ColumnHeader} </div>
            <span>{Columndimension}</span>
            {(status || status1) && (
              <Tooltip content={message}>
                <img src={Img} alt="Error" className="ms-1" />
              </Tooltip>
            )}
          </div>
        </th>

        <td 
                    className={status ? "border WPSSelect error" : "border WPSSelect"}
                    >
            <div>
              {detailObj?.[name] &&
                detailObj?.[name].map((element, id) => {
                  console.log("layerelement", element);
                  return (
                    <div key={id}>
                    
                      {element.Value_A &&
                        element.Value_A.map((value, valueIndex) => {
                          console.log("abcccc", element.Value_A);

                          return (
                            <input
                            className={status ? "error-input" : ""}

                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"250px"
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handle_2Input_ChangeA(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              // onInput={onInput}
                              onInput={(e) => {
                                let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                setError((prevState) => ({
                                  ...prevState,
                                  [name]: [
                                    { Value_A: [error1] },
                                    { Value_B: [error[name][1].Value_B[0]] },
                                  ],
                                }));
                              }}
                           
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

          </td>

          <td                     className={status1 ? "border WPSSelect error" : "border WPSSelect"}
>
            <div>
              {detailObj?.[name] &&
                detailObj?.[name].map((element, id) => {
                  console.log("layerelement", element);
                  return (
                    <div key={id}>
                     

                      {element.Value_B &&
                        element.Value_B.map((value, valueIndex) => {
                          console.log("abcccc", element.Value_B);

                          return (
                            <input
                            className={status1 ? "error-input" : ""}

                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                width:"250px",
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handle_2Input_ChangeB(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              // onInput={onInput}
                              onInput={(e) => {
                                let error1 = isAlphanumericAllSpecialCharacters(e.target.value);
                                setError((prevState) => ({
                                  ...prevState,
                                  [name]: [
                                    { Value_A: [error[name][0].Value_A[0]] },
                                   { Value_B: [error1] },
                                  ],
                                }));
                              }}
                           
                              
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>


          </td>
  
  
 
            
            
            
  
      </tr>
    );
  };
export const PQR_Process_Grid_Input2_tube = ({
    status,
    status1,
    item,
    message,
    message1,
    Error,
    wps_detail,
    ColumnHeader,
    Columndimension,
    type,
    name,
    setError,
    error,
    value,
    readOnly,
    handleChangedetailsarray,
    onInput,
    disabled,
    detailObj,
    handle_2Input_ChangeA,
    handle_2Input_ChangeB,
    maxLength,
  }) => {
    
    console.log("detailo",detailObj)
    console.log("statt",status)

    return (
      <tr className={status || status1 ? "border WpsInput-md error" : "border WpsInput-md"}>
        <th
          className={status || status1 ? "text-start thead-color" : "text-start thead-color"}
        >
          <div>
            <div className="headwidth">{ColumnHeader} </div>
            <span>{Columndimension}</span>
            {(status || status1) && (
              <Tooltip content={message}>
                <img src={Img} alt="Error" className="ms-1" />
              </Tooltip>
            )}
          </div>
        </th>

        <td 
                    className={status ? "border WPSSelect error" : "border WPSSelect"}
                    >
            <div>
              {detailObj?.[name] &&
                detailObj?.[name].map((element, id) => {
                  console.log("abcdefg");

                  console.log("layerelement", element);
                  return (
                    <div key={id}>
                    
                      {element.Value_A &&
                        element.Value_A.map((value, valueIndex) => {
                          console.log("abcccc", element.Value_A);

                          return (
                            <input
                            className={status ? "error-input" : ""}

                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handle_2Input_ChangeA(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput}
                           
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>

          </td>

          <td className={status1 ? "border WPSSelect error" : "border WPSSelect"}
>
            <div>
              {detailObj?.[name] &&
                detailObj?.[name].map((element, id) => {
                  console.log("layerelement", element);
                  return (
                    <div key={id}>
                     

                      {element.Value_B &&
                        element.Value_B.map((value, valueIndex) => {
                          console.log("abcccc", element.Value_B);

                          return (
                            <input
                            className={status1 ? "error-input" : ""}

                              style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                              }}
                              key={valueIndex}
                              type="text"
                              name={name}
                              readOnly={readOnly}
                              value={value}
                              maxLength={maxLength}
                              onChange={(e) =>
                                handle_2Input_ChangeB(
                                  name,
                                  id,
                                  valueIndex,
                                  e.target.value
                                )
                              }
                              onInput={onInput}
                              
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>


          </td>
  
  
 
            
            
            
  
      </tr>
    );
  };

  export const textarea_Input = ({
    status,
    type,
    name,
    error,
    value,
    handleChange,
    typeclass,
    onChange,
    style,
    readOnly,
    label,
    message,
    onInput,
    maxLength,
  }) => {
    return (
      <>
       <div class="T-input w-100">
        <textarea
          type={type}
          name={name}
          style={style}
          id={name}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? "T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        { status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
      </>
    );
  };
  



export const WPS_Process_Grid_Input_3 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "1"}
                value={item[name][0]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[0] === ""}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "2"}
                value={item[name][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "3"}
                value={item[name][2]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select = ({
  status,
  message,
  detailObj,
  ColumnHeader,
  Columndimension,
  array,
  name,
  handleChangedetailsarray,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
          >
            <input
              style={{
                width: "512px",
                height: "30px",
                borderRadius: "3px",
              }}
              readOnly
              className={status ? "error-input" : ""}
              name={name}
              value="GTAW"
              // onChange={(e) => {
              //   handleChangedetailsarray(e);
              // }}
            />
              {/* {array &&
                array.map((e) => {
                  return (
                    <option value={e.value}>
                      {e.name}
                    </option>
                  );
                })} */}

              {/* <option value="1">1</option>
              <option value="1.6">1.6</option>
              <option value="2.4">2.4</option>
              <option value="3.2">3.2</option>
              <option value="2.4/3.2">2.4/3.2</option> */}
          </td>

    </tr>
  );
};

export const WPS_Process_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  detailObj,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  obj,
  setfunction,
  onInput,
  ColumnHeader1,
  name1,
}) => {
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3">
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-center">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

   
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                type={type}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                style={{width:"512px"}}
                value={detailObj?.[name]}
                readOnly={readOnly}
                maxLength={maxLength}
                onChange={(e) => {
                  handleChangedetailsarray(e);
                }}
                onInput={onInput}
              />

              <input
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                style={{width:"512px"}}
                value={detailObj?.[name1]}
                readOnly={readOnly}
                maxLength={maxLength}
                onChange={(e) => {
                  handleChangedetailsarray(e);
                }}
                onInput={onInput1}
              />
            </div>
          </td>

    </tr>
  );
};

export const WPS_Process_Grid_Select_3 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  array,
  handleChange,
  option,
  onChange,
  setfunction,
  obj,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div>
              <select
                style={{
                  width: "85px",
                  height: "32px",
                  borderRadius: "4px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => {
                  handleChange(id, 0, name, obj, setfunction, e);

                  //   setError((prevState) => ({
                  //     ...prevState,
                  //     WPSD_WP_LayerNumber: {status: false, message: ""},
                  //   }));
                }}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "3"}
                // value = {item[name[2]]}
                value={item[name][2]}
                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const Single_Input = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? " T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select_green = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
}) => {
  return (
    <>
      <div class="green-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {/* <option value=" " selected>
                    select
                  </option>
                  <option value="With">With</option>
                  <option value="With/Without">With/Without</option>
                  <option value="NA">NA</option>
                </select> */}
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          disabled={disabled}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {/* <option value=" " selected>
                    select
                  </option>
                  <option value="With">With</option>
                  <option value="With/Without">With/Without</option>
                  <option value="NA">NA</option>
                </select> */}
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const WPS_Process_Grid_Select_master_data = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  data,
  type,
  name,
  setError,
  error,
  valueField,
  labelField,
  errorstyle,
  bluestyle,
  onInput,
  errorr,
  value,
  readOnly,
  array,
  handleChangedetail,
  option,
  onChange,
  setdetailObj,
  detailObj,
}) => {
  return (
    <tr className={status ? "border error" : ""}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border  WPSSelect "}
            key={id}
          >
            <div className="h-100 d-flex align-items-center justify-content-center">
              <SearchSelect
                className={status ? "error-input inspdrop" : "inspdrop"}
                data={data}
                name={name}
                status={status}
                valueField={valueField}
                value={item[name]}
                labelField={labelField}
                stylegreen={status ? errorstyle : bluestyle}
                placeholder=""
                onChange={(e) => {
                  item[name] = e.value;
                  setdetailObj([...detailObj]);
                  setError((prevState) => ({
                    ...prevState,
                    name: { status: false, message: "" },
                  }));
                }}
              ></SearchSelect>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_Horizon_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              {/* <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "3"}
                value={item[name][2]}
                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              /> */}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

// export const WPS_Grid_Input_2_bkp = ({
//   status,
//   item,
//   message,
//   Error,
//   wps_detail,
//   ColumnHeader,
//   Columndimension,
//   type,
//   name,
//   setError,
//   error,
//   value,
//   readOnly,
//   handleChange,
//   maxLength,
//   obj,
//   setfunction,
//   onInput,
// }) => {

//   return (
//     <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
//       <th
//         className={status ? "text-start thead-color" : "text-start thead-color"}
//       >
//         <div>
//           {ColumnHeader}
//           <span>{Columndimension}</span>
//           {status && (
//             <Tooltip content={message}>
//               <img src={Img} alt="Error" className="ms-1" />
//             </Tooltip>
//           )}
//         </div>
//       </th>
//       {wps_detail.map((item, id, k) => {
//         return (
//           <td
//             className={status ? "border WPSSelect error" : "border WPSSelect"}
//             key={id}
//           >
//             <div className="dInput ">
//               <div
//                 className="dInput"
//                 style={{
//                   height: "200%",
//                   width: 2,
//                   backgroundColor: "#909090",
//                   // position: "relative",
//                   // left: "5px",
//                 }}
//               >
//                 <input
//                   type={type}
//                   maxLength={maxLength}
//                   readOnly={readOnly}
//                   style={{ marginRight: "10px", marginLeft: "10px" }}
//                   className={status ? "error-input" : ""}
//                   name={name + "1"}
//                   value={item[name][0]}
//                   onChange={(e) =>
//                     handleChange(id, 0, name, obj, setfunction, e)
//                   }
//                   onInput={onInput}
//                 />

//                 <input
//                   type={type}
//                   maxLength={maxLength}
//                   readOnly={readOnly}
//                   style={{ marginRight: "10px" }}
//                   className={status ? "error-input" : ""}
//                   name={name + "2"}
//                   value={item[name][1]}
//                   onChange={(e) =>
//                     handleChange(id, 1, name, obj, setfunction, e)
//                   }
//                   onInput={onInput}
//                 />
//               </div>
//             </div>
//           </td>
//         );
//       })}
//     </tr>
//   );
// };

export const WPS_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
