import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, NavLink, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Assests/Css/header.css";
import {
  getApprovedPendingRepairWorkflow,
  getProjectReqCount,
  getallApprovedCount,
} from "../../Services/weldingRepairAnalysis-service";
import { getCurrentDateTime } from "../../functions/welding-functions";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import GridMaster from "../utility/GridMaster";
import GlobalColumnDefinations, {
  getHeader_FieldName,
} from "../utility/AgGridDefination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
// import { LineChart } from "@mui/x-charts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Customized,
  Text,
} from "recharts";
import { getWIReqCount } from "../../Services/WI-services";

import Chart from "react-apexcharts";

import Export from "../../icons/Download_Blue_Big.svg"
import WhiteExport from "../../icons/Download_White.svg"

import { handleExport1 } from "../utility/GlobalExport";
// import Export from "../../icons/Download_Grey_Big.svg"



const WeldingRepairAnalysis = forwardRef((props, ref) => {
  let formName = "Weld Repair Analysis";
  const [allPendingRepairAnalysis, setAllPendingRepairAnalysis] = useState([]);
  const [allRaisedRepairAnalysis, setAllRaisedRepairAnalysis] = useState([]);
  const navigate = useNavigate();
  const [flagforApprovalDisplay, setFlagforApprovalDisplay] = useState(true);
  let userId = sessionStorage.getItem("UserId")?.split("@")[0];
  let userRole = sessionStorage.getItem("Role");
  let todayDate = getCurrentDateTime("-");
  const [columnDefs, setColumnDefs] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);
 let height = window.innerHeight - 37
  const [viewportHeight, setViewportHeight] = useState(height);
  const [columnReceivedforApproval, setColumnReceivedforApproval] = useState(
    []
  );
  const [columnRaisedforApproval, setColumnRaisedforApproval] = useState([]);

  const [ReqCount, setReqCount] = useState([]);
  const [WIReqCount, setWIReqCount] = useState([]);
  const [skResults, setSKResults] = useState({});
  const [totalApprovedCount, setTotalApprovedCount] = useState();
  const [totalApprovedCountGraph, setTotalApprovedCountGraph] = useState([]);
  const years = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(years);
  console.log("selecteeeed", new Date().getFullYear(), selectedYear);

  const handleYearChange = (e) => {
    // console.log("selected",e)
    setSelectedYear(e.value);
  };

  const Graphyears = () => {
    var year = parseInt(process.env.REACT_APP_START_YEAR);
    console.log("years", new Array(20), (val, index) => index + year);
    var yearsarr = Array.from(new Array(20), (val, index) => index + year);
    return yearsarr.map((i) => {
      return <option value={i}>{i}</option>;
    });
    //   console.log("years",yearsarr)
  };

  const childRef = useRef();

  const HyperLink = (params, row) => {
    console.log("HyperLink", params);
    console.log("row111", row);
    console.log("HyperLink22", params.data.SK);

    return (
      <Button
        variant="link"
        onClick={() => {
          navigate("/weldingRepair/WeldingRepairAnalysisEntry", {
            state: {
              data: params.data,
              action: "Edit",
            },
          });
        }}
        size="sm"
      >
        {params.data.reportNumber}
      </Button>
    );
  };


//   const fetchprojectreqcount = async () => {
    
//     const Projectcount = await getProjectReqCount();
//     console.log("asffdad", Projectcount)
    
//     let WICounts = [];

//     // Aggregating project counts by projectNo
//     const aggregatedProjects = {};
//     for (let project of Projectcount) {
//         const abc = project.SK.split("#");
//         //rename
//         const projectNo = abc[0]

//         console.log("aaaaa", projectNo)

//         if (!aggregatedProjects[projectNo]) {
//             aggregatedProjects[projectNo] = { ...project };
//         } else {
//             // Check if ApprovedCount property exists before adding
//             if (project.hasOwnProperty("ApprovedCount")) {
//                 if (aggregatedProjects[projectNo].hasOwnProperty("ApprovedCount")) {
//                     aggregatedProjects[projectNo].ApprovedCount += project.ApprovedCount;
//                 } else {
//                     aggregatedProjects[projectNo].ApprovedCount = project.ApprovedCount;
//                 }
//             }
//         }

//         // Adding RaisedCount, count, month, projectNo, and year
//         aggregatedProjects[projectNo].RaisedCount = (aggregatedProjects[projectNo].RaisedCount || 0) + project.RaisedCount;
//         aggregatedProjects[projectNo].count = (aggregatedProjects[projectNo].count || 0) + project.count;
//         aggregatedProjects[projectNo].month = abc[2];
//         aggregatedProjects[projectNo].year = abc[1];
//         aggregatedProjects[projectNo].projectNo = abc[0];
//     }

//     console.log("adfafa", aggregatedProjects)

//     // Create an array to hold aggregated projects
//     const finalcount = [];

//     // Loop through aggregated projects
//     for (let proj in aggregatedProjects) {
//         const project = aggregatedProjects[proj];
//         const count = await getWIReqCount(project.SK);
//         console.log("cccounnn",count)

//         WICounts.push(count);

//         // Calculate the percentage
//         if (count !== 0) {
//             const percentage = ((count - project.ApprovedCount) / count) * 100;
//             const roundedPercentage = Math.round(percentage);
//             project.count = count.toString();
//             project.percentage = `${roundedPercentage}%`;
//         } else {
//             project.percentage = "100%";
//         }

//         // Add the project to the final array
//         finalcount.push(project);
//     }

//     console.log("finalcount", finalcount);

//     setReqCount(finalcount);
//     setWIReqCount(WICounts);

//     fetchColumnDefns();
// }



const fetchprojectreqcount = async () => {
  const Projectcount = await getProjectReqCount();
  
  console.log("pppp",Projectcount)
  const aggregatedProjects = Projectcount.reduce((data, { SK, ApprovedCount = 0, RaisedCount = 0, count = 0 }) => {
      const [projectNo, year, month] = SK.split("#");

      data[projectNo] = data[projectNo] || { SK, projectNo, year, month, ApprovedCount: 0, RaisedCount: 0, count: 0 };
      data[projectNo].ApprovedCount += ApprovedCount;
      data[projectNo].RaisedCount += RaisedCount;
      data[projectNo].count += count;

      return data;
  }, {});


  console.log("aggregatedProjectsdd",aggregatedProjects)


  const finalcount = await Promise.all(
      Object.values(aggregatedProjects).map(async (project) => {
          const total = await getWIReqCount(project.SK);
          const percentage = total ? Math.round(((total - project.ApprovedCount) / total) * 100) : 100;
          
          return { ...project, count: total.toString(), percentage:`${percentage}%` };
      })
  );

  setReqCount(finalcount);
  setWIReqCount(finalcount.map(({ count }) => count));

  fetchColumnDefns();
};

  console.log("totalllll", totalApprovedCount);

  console.log("ReqCountDWDWD", ReqCount);


  const tmpcolumnDefs = [
    {
      headerName: "Project Number",
      field: "projectNo",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      sortable: true,
      sort: "asc",
    },
    {
      headerName: "Repair Count",
      field: "ApprovedCount",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      minWidth: 50,
    },
    {
      headerName: "% First Attempt Clear",
      field: "percentage",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];

  const tableReceivedforApproval = [
    {
      headerName: "Status",
      field: "repairAnalysisStatus",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WeldRepairRenderer",
      sortable: true,
    },
    {
      headerName: "Report Number",
      field: "reportNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: HyperLink,
      minWidth: 50,
    },
    {
      headerName: "Ageing (Days)",
      field: "aging(days)",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      sort: "desc",
    },
  ];

  const tableRaisedforApproval = [
    {
      headerName: "Status",
      field: "repairAnalysisStatus",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "WeldRepairRenderer",
      sortable: true,
    },
    {
      headerName: "Report Number",
      field: "reportNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: HyperLink,
      minWidth: 50,
    },
    {
      headerName: "Pending with",
      field: "approvalPendingWith",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      minWidth: 50,
    },
    {
      headerName: "Ageing (Days)",
      field: "aging(days)",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
      sort: "desc",
    },
  ];

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const headerReceivedforApproval = (field) => {
    let name = getHeader_FieldName(
      field,
      tableReceivedforApproval,
      "getHeader"
    );
    return name;
  };

  const fieldReceivedforApproval = (header) => {
    let name = getHeader_FieldName(
      header,
      tableReceivedforApproval,
      "getField"
    );
    return name;
  };

  const headerRaisedforApproval = (field) => {
    let name = getHeader_FieldName(field, tableRaisedforApproval, "getHeader");
    return name;
  };

  const fieldRaisedforApproval = (header) => {
    let name = getHeader_FieldName(header, tableRaisedforApproval, "getField");
    return name;
  };

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    console.log("reeeee", result);
    if (result) {
      setColumnDefs(result);
    }
  };

  const fetchColumnReceivedforApproval = async () => {
    const result = GlobalColumnDefinations(tableReceivedforApproval);
    console.log("reeeee", result);
    result[1].cellRenderer = HyperLink;
    setColumnReceivedforApproval(result);
  };

  const fetchColumnRaisedforApproval = async () => {
    const result = GlobalColumnDefinations(tableRaisedforApproval);
    console.log("reeeee", result);
    result[1].cellRenderer = HyperLink;

    setColumnRaisedforApproval(result);
  };

  function handleClick() {
    if (userRole !== "WE") {
      toast.error(`Only welding engineer is allowed to raise weld repair.`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    } else {
      navigate("/weldingRepair/WeldingRepairAnalysisEntry", {
        state: { data: "", action: "Add" },
      });
    }
  }

  function handleView() {
    console.log("enteeerrrrrr");
    navigate("/weldingRepair/WeldingRepairAnalysisViewAll", {
      state: { data: "", action: "View" },
    });
  }

  useImperativeHandle(ref, () => ({
    handleAdd: () => {
      handleClick();
    },
  }));

  const getRoleConfirmation = async () => {
    if (userRole === "Supervisor") {
      setFlagforApprovalDisplay(true);
    } else {
      setFlagforApprovalDisplay(false);
    }
  };

  const fetchApprovedPendingRepair = async () => {
    let temp = [];
    let newDate = new Date();
    let yeardata = newDate.getFullYear();

    let userObj = {
      userId: userId,
      userRole: userRole,
      flag: "AllUserRoleWise",
      year: yeardata,
    };
    let repairresult = await getApprovedPendingRepairWorkflow(userObj);
    if (repairresult?.length > 0) {
      for (let item of repairresult) {
        var date1 = new Date(item.initiatedDate);
        var date2 = new Date(todayDate);
        var time_difference = date2.getTime() - date1.getTime();
        item["aging(days)"] = Math.round(
          time_difference / (1000 * 60 * 60 * 24)
        );
        if (item.approvalPendingWith === userRole) {
          temp.push(item);
        }
      }
    }
    setAllPendingRepairAnalysis(temp);
  };

  const fetchAllRepairWorkflowByUserIdWise = async () => {
    let raised = [];
    let newDate = new Date();
    let yeardata = newDate.getFullYear();
    let userObj = {
      userId: userId,
      userRole: userRole,
      flag: "AllUserIdWise",
      year: yeardata,
    };
    let repairresult = await getApprovedPendingRepairWorkflow(userObj);
    if (repairresult?.length > 0) {
      for (let item of repairresult) {
        var date1 = new Date(item.initiatedDate);
        var date2 = new Date(todayDate);
        var time_difference = date2.getTime() - date1.getTime();
        item["aging(days)"] = Math.round(
          time_difference / (1000 * 60 * 60 * 24)
        );
        if (item.createdBy === userId) raised.push(item);
      }
    }
    setAllRaisedRepairAnalysis(raised);
  };

  console.log("allPendingRepairAnalysis", allPendingRepairAnalysis);
  console.log("allRaisedRepairAnalysis", allRaisedRepairAnalysis);

  const GetTotalApprovedCount = async () => {
    const TotalApprovedCount = await getallApprovedCount(selectedYear);
    setTotalApprovedCountGraph(TotalApprovedCount);
    console.log("totototot", TotalApprovedCount);
  };

  console.log("tototototffff", totalApprovedCountGraph);

  ReqCount.forEach((project) => {
    console.log("FFFFf", project.count);
  });

  useEffect(() => {
    getRoleConfirmation();
    fetchApprovedPendingRepair();
    fetchAllRepairWorkflowByUserIdWise();
    fetchprojectreqcount();
    // fetchColumnDefns();
    fetchColumnReceivedforApproval();
    fetchColumnRaisedforApproval();
    // fetchWIreqcount()
  }, []);

  useEffect(() => {
    GetTotalApprovedCount();
  }, [selectedYear]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(height);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const chartData = [
  //   { month: "Apr", value: "0" },
  //   { month: "May", value: "10" },
  //   { month: "Jun", value: "15" },
  //   { month: "Jul", value: "5" },
  //   { month: "Aug", value: "8" },
  //   { month: "Sep", value: "3" },
  //   { month: "Oct", value: "30" },
  //   { month: "Nov", value: "5" },
  //   { month: "Dec", value: "5" },
  //   { month: "Jan", value: "5" },
  //   { month: "Feb", value: "5" },
  //   { month: "Mar", value: "5" },

  //   // Add other months and corresponding values
  // ];

  let graph = totalApprovedCountGraph?.totalApprovedCount;

  //   let abc = graph.totalApprovedCount

  console.log("safsaf", graph);
  const chartData = graph;

  console.log("chchchchc", chartData);

  // const CustomizedLabel = ({ x, y, value }) => {
  //   <Text x={x} y={y} dy={-10} textAnchor="middle" fill="#666">{value}</Text>
  // }

  const graphdata = chartData && chartData.map((entry) => entry.value);

  console.log("graphhhh", graphdata);

  // Sample data (replace with your actual data)
  const data = [10, 20, 9, 40, 50, 60, 70, 80, 90, 100, 110, 120];

  // Months for x-axis
  const months = [
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
  ];

  // Configuration for the chart
  const chartOptions = {
    chart: {
      id: "straight-line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: months,
      title: {
        text: "Months",
        style: {
          fontWeight: "normal",
        },
      },
    },
    yaxis: {
      title: {
        text: "Enquiry (Value and Volume)",
        style: {
          fontWeight: "normal",
        },
      },
    },
    colors: ["var(--godrej-green-alt)"], // Green color for the line
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
    },
    //   row:{colors:["trasparent"],
    // opacity:0.5},
    // column:{colors:["trasparent"],
    // opacity:0.5},
    // plotOptions:{
    //   line:{
    //     startingShape:"flat"
    //   }
    // },
    grid: {
      borderColor: "var(--col-ddd)",
      borderWidth: 1,
      zindex: 10,
    },
    // strokeDashArray:4
  };

  // const seriesData = [{ curve: 'linear', data: chartData }];

  const handleGlobalExport = async () => {
    debugger
    
    let AllResult
            console.log("allRaisedRepairAnalysis1", allRaisedRepairAnalysis);
        console.log("allPendingRepairAnalysis1", allPendingRepairAnalysis);

        const allColumnDefs=[]
      
        // Add a column for WPQ_WelderName
        allColumnDefs.push({
          headerName: 'Status',
          field: 'repairAnalysisStatus',
        });

        allColumnDefs.push({
          headerName: 'Report Number',
          field: 'reportNumber',
        });

        allColumnDefs.push({
          headerName: 'Created On',
          field: 'createdOn',
        });

        allColumnDefs.push({
          headerName: 'Project Number',
          field: 'projectNumber',
        });

        allColumnDefs.push({
          headerName: 'Client',
          field: 'client',
        });

        allColumnDefs.push({
          headerName: 'Equipment Number',
          field: 'equipmentNumber',
        });

        allColumnDefs.push({
          headerName: 'Inspection By',
          field: 'inspectionBy',
        });
    
    
        allColumnDefs.push({
          headerName: 'Drawing Number',
          field: 'drawingNumber',
        });

        allColumnDefs.push({
          headerName: 'Sheet Number',
          field: 'sheetNumber',
        });

        allColumnDefs.push({
          headerName: 'Revision Number',
          field: 'revisionNumber',
        });

        allColumnDefs.push({
          headerName: 'Originating Dept',
          field: 'orginatingDept',
        });

        allColumnDefs.push({
          headerName: 'Joint Number',
          field: 'jointNumber',
        });

        allColumnDefs.push({
          headerName: 'Spot Number',
          field: 'spotNumber',
        });

        allColumnDefs.push({
          headerName: 'NDT Method',
          field: 'ndtMethod',
        });

        allColumnDefs.push({
          headerName: 'Material',
          field: 'material',
        });

        allColumnDefs.push({
          headerName: 'Welding Process',
          field: 'weldingProcess',
        });

        allColumnDefs.push({
          headerName: 'Welding Code',
          field: 'welderNumber',
          // cellRenderer:(params) => { return params.value ? `'${params.value}'`: ""},
        });

        allColumnDefs.push({
          headerName: 'Welding Name',
          field: 'welderName',
        });

        allColumnDefs.push({
          headerName: 'Repair OR Defect Type',
          field: 'repairOrDefectType',
        });

        allColumnDefs.push({
          headerName: 'Repair Location Marked By',
          field: 'repairLocationMarkedBy',
        });

        allColumnDefs.push({
          headerName: 'Repair Length Spotwise',
          field: 'repairLengthSpotwise',
        });

        allColumnDefs.push({
          headerName: 'Repair Legth Actual Wise',
          field: 'repairLengthActualwise',
        });

        allColumnDefs.push({
          headerName: 'Repair Removed By',
          field: 'repairRemovedByAfterRepairRemoval',
        });

        allColumnDefs.push({
          headerName: 'Repair Found',
          field: 'repairFoundAfterRepairRemoval',
        });

        allColumnDefs.push({
          headerName: 'Repair Depth',
          field: 'repairDepthAfterRepairRemoval',
        });

        allColumnDefs.push({
          headerName: 'Defective Process',
          field: 'defectiveProcessAfterRepairRemoval',
        });

        allColumnDefs.push({
          headerName: 'Welding Joint Detail',
          field: 'weldingJointDetailAfterRepairRemoval',
        });

        allColumnDefs.push({
          headerName: 'Why-1',
          field: 'rootCauseAnalysisWhy1',
        });

        allColumnDefs.push({
          headerName: 'Why-1',
          field: 'rootCauseAnalysisWhy1',
        });

        allColumnDefs.push({
          headerName: 'Why-2',
          field: 'rootCauseAnalysisWhy2',
        });

        allColumnDefs.push({
          headerName: 'Why-3',
          field: 'rootCauseAnalysisWhy3',
        });

        allColumnDefs.push({
          headerName: 'Why-4',
          field: 'rootCauseAnalysisWhy4',
        });

        allColumnDefs.push({
          headerName: 'Why-5',
          field: 'rootCauseAnalysisWhy5',
        });

        allColumnDefs.push({
          headerName: 'Others',
          field: 'rootCauseAnalysisOthers',
        });

        allColumnDefs.push({
          headerName: 'Root Cause',
          field: 'rootCauseAnalysisRootCause',
        });

        allColumnDefs.push({
          headerName: 'Corrective Action',
          field: 'rootCauseAnalysisCorrectiveAction',
        });

        allColumnDefs.push({
          headerName: 'Corrective Action',
          field: 'rootCauseAnalysisCorrectiveAction',
        });

        allColumnDefs.push({
          headerName: 'Preventive Action',
          field: 'rootCauseAnalysisPreventionAction',
        });

        allColumnDefs.push({
          headerName: 'Kaizen Idea',
          field: 'rootCauseAnalysisKaizenIdea',
        });

        allColumnDefs.push({
          headerName: 'Counter Measure',
          field: 'rootCauseAnalysisCounterMeasure',
        });

        allColumnDefs.push({
          headerName: 'Cost of Repair',
          field: 'costOfRepair',
        });

        const modifieddata = allRaisedRepairAnalysis.map((row)=>({
          ...row,
          welderNumber: row.welderNumber ? `'${row.welderNumber.toString()}'` : "",
        }))
      
        let filename = "Weld Repair Analysis";

          handleExport1(modifieddata, filename, allColumnDefs);
      
        console.log("AllResult",AllResult)
    
      };

  return (

    <>
    {
    (userRole === "WE"|| userRole === "Supervisor" || userRole === "QCE" || userRole === "NDE") ?
    <>
    (
    
    <div
     className="hide_Container"
        style={{
          height: viewportHeight,
          overflow: "auto",
          paddingBottom: "100px",
          overflowY:"scroll",
          overflowX:"hidden",
          position: "relative",
          bottom: "10px",
        }}
      >
        <div style={{position:"relative", top:"20px", left:"10px"}}>
            <button onClick={handleGlobalExport} type="button" class="btn btn-outline-primary hover_btttn" style={{background:"#F4F4FF", border:"none", fontSize:"14px", height:"35px", width:"150px"}}>
               <img  className="default_img" src={Export} />
               <img  className="hover_img" src={WhiteExport} />
               <span style={{position:"relative", bottom:"24px", left:"10px"}}>Download</span></button>
          </div>
        <div className="weldrepairtitle">
          <div className="repairtitleleft">
            <span style={{fontSize:"18px"}}>Project Wise Repair</span>
          </div>
          <div className="repairtitleright">
            <span style={{fontSize:"18px"}}>Monthly Repair</span>
          </div>
        </div>

        <div className="weldrepairview" style={{zIndex:"10"}}>
          <div className="repairviewleft">
            <Button
              variant="link"
              className="label-color"
              onClick={() => {
                navigate("/weldingRepair/WeldingRepairAnalysisViewAll", {
                  state: { data: "", action: "viewReqCount" },
                });
              }}
              size="sm"
              // className="float-end mt-2 me-2 me-md-0"
            >
              View All
            </Button>
            {/* <span>view all</span> */}
          </div>
          <div className="repairviewright">
            <Button
              variant="link"
              onClick={() => {
                navigate("/weldingRepair/WeldingRepairAnalysisViewAll", {
                  state: { data: "", action: "ViewReceivedforapproval" },
                });
              }}
              size="sm"
            >
              {/* View All */}
            </Button>
          </div>
        </div>

        <div className="weldrepairtitlebottom" style={{zIndex:"-1"}}>
          <div className="repairtitleleft">
            <span style={{fontSize:"18px"}}>List of workflow received for approval</span>
          </div>
          <div className="repairtitleright">
            <span style={{fontSize:"18px"}}>List of workflow raised by logged in user for approval</span>
          </div>
        </div>

        <div className="weldrepairviewbottom">
          <div className="repairviewleftbottom">
            <Button
              className="label-color"
              variant="link"
              onClick={() => {
                navigate("/weldingRepair/WeldingRepairAnalysisViewAll", {
                  state: { data: "", action: "ViewReceivedforapproval" },
                });
              }}
              size="sm"
              // className="float-end mt-2 me-2 me-md-0"
            >
              View All
            </Button>
            {/* <span>view all</span> */}
          </div>
          <div className="repairviewrightbottom">
            <Button
              className="label-color"
              variant="link"
              onClick={() => {
                navigate("/weldingRepair/WeldingRepairAnalysisViewAll", {
                  state: { data: "", action: "ViewRaisedforapproval" },
                });
              }}
              size="sm"
              // className="float-end mt-2 me-2 me-md-0"
            >
              View All
            </Button>
            {/* <span>view all</span> */}
          </div>
        </div>

        <div className="grid-container" >
          <div className="repairgridtableleft">
            <GridMaster
              rowDetails={ReqCount}
              colDetails={columnDefs}
              fieldNames={fieldName}
              headerNames={headerName}
              getDataEvent={(getFilteredData) =>
                setFilteredResulttemp(getFilteredData)
              }
              getFilterColLength={(getLength) => setFilterColLength(getLength)}
              setExcelData={setExcelData}
              ref={childRef}
              paginationPageSize={4}
              pagination={false}
              height={210}
              width={100}
              rowHeight={40}
              headerHeight={40}
            />
          </div>

          <div className="repairgridtableright">
            <div className="repairgraphtoplabel">
              <div className="weldrepairtitledash"></div>
              Enquiry Volume (In Nos.) year{" "}
              <select
                className="weldgraphyeardropdown"
                value={selectedYear}
                onChange={(opt) => {
                  console.log("selected", opt, opt.target.value);
                  handleYearChange(opt.target);
                }}
              >
                {Graphyears()}
              </select>
            </div>
            <div style={{ width: "90%", paddingLeft: "20px" }}>
              <Chart
                options={chartOptions}
                series={[{ data: graphdata }]}
                type="line"
                height={175}
              />
            </div>
         
          </div>
          <div className="repairgridtableleftbottom" style={{zIndex:"10"}}>
            <GridMaster
              rowDetails={allPendingRepairAnalysis}
              colDetails={columnReceivedforApproval}
              fieldNames={fieldReceivedforApproval}
              headerNames={headerReceivedforApproval}
              getDataEvent={(getFilteredData) =>
                setFilteredResulttemp(getFilteredData)
              }
              getFilterColLength={(getLength) => setFilterColLength(getLength)}
              setExcelData={setExcelData}
              ref={childRef}
              paginationPageSize={4}
              pagination={false}
              height={230}
              width={100}
              rowHeight={43}
              headerHeight={43}
            />
          </div>
          <div className="repairgridtablerightbottom" style={{zIndex:"10"}}>
            <GridMaster
              rowDetails={allRaisedRepairAnalysis}
              colDetails={columnRaisedforApproval}
              fieldNames={fieldRaisedforApproval}
              headerNames={headerRaisedforApproval}
              getDataEvent={(getFilteredData) =>
                setFilteredResulttemp(getFilteredData)
              }
              getFilterColLength={(getLength) => setFilterColLength(getLength)}
              setExcelData={setExcelData}
              ref={childRef}
              paginationPageSize={4}
              pagination={false}
              height={230}
              width={100}
              rowHeight={43}
              headerHeight={43}
            />
          </div>
        </div>

      
        <ToastContainer
          className="toast-position"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    )
    </>

    : <> <Row className="RowHeight">
    <Col md={2} sm={12} xs={12} className="ListTitle" style={{position:"relative", top:"200px", left:"400px"}}>
      You are not authorized to enter / view weld repair data
    </Col></Row></>
}
</>
  );
});

export default WeldingRepairAnalysis;
