import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "users";

//dev api
let INTERFACE_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "PEDUsers";
} else {
  INTERFACE_URL = process.env.REACT_APP_API_URL + "interface/PEDUsers";
}

console.log("define_url_", INTERFACE_URL);



export async function authenticate(EmailId, accessToken) {
  console.log("API url: ", API_URL);
  console.log("API EmailId: ", EmailId);
  console.log(`${API_URL}/user/authenticate`);
  const response = await axios
    .post(`${API_URL}/user/authenticate`, { EmailId: EmailId })
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("authenticate_response_data", response.data);
  return response.data;
}

export async function getAllUserMaster() {
  console.log("userapi", API_URL + "/user");
  const response = await axios
    .get(API_URL + "/user")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getAllUserMaster_response", response.data);
  return response.data;
}

export async function getAllUsersByRole(roles) {
  console.log("userapi", API_URL + "/user");
  const response = await axios
    .post(API_URL + "/user/getrolesusers", roles)
    .then(function (resp) {
      console.log("L1", resp);
      console.log("L1w", resp.da);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function IsUserMasterBand() {
  //console.log("userapi", API_URL + "/getPTband/user");
  const response = await axios
    .get(API_URL + "/band/ptband")
    .then(function (resp) {
      console.log("IsUserMasterBand", resp);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("IsUserMasterBand_api", response.data);
  return response;
}

// export async function getPTBandUsers() {
export async function getPTUsers() {
  console.log("ptusers", API_URL + "/band/getbyBandPT");
  const response = await axios
    .get(API_URL + "/band/getbyBandPT")
    .then(function (resp) {
      // console.log("getptband", resp);
      console.log("GetPTusers", resp);
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  //   console.log("getptband1", response.data);
  //   return response;
  // }
  console.log("GetPTusers_api", response.data);
  return response;
}

export async function getPEDUsers() {
  console.log();
  const response = await axios
    .get(INTERFACE_URL)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
  // return fetch(API_URL + "/ene").then((response) => response.json());
}

export const AddNewUser = async (User) => {
  console.log(JSON.stringify(User));
  let url = `${API_URL}/user`;
  const response = await axios
    .post(url, User)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateUser = async (User) => {
  console.log(User);
  let url = `${API_URL}/user/updateuser`;
  console.log(url);
  const response = await axios
    .post(url, User)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};



export async function DeleteUser(um_id) {
  console.log(um_id);
  let url = `${API_URL}/user/${um_id}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export async function DeleteUser1(um_id) {
  let url = `${API_URL}/user/deleteuser/${um_id}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export async function deletefromuserlocation(UserId) {
  let url = `${API_URL}/user/${UserId}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export async function getUsersL1(salaryCode) {
  console.log("salaryCode", salaryCode);
  let url = `${INTERFACE_URL}/salaryCode`;
  console.log("getUsersL1_url", url);
  const resp = await axios
    .post(url, salaryCode)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("getUsersL1_resp.data", resp.data);

  return resp.data;
}


export async function getbyuserid(UserId) {
  
  let url = `${API_URL}/user/${UserId}`;
  console.log("UserIdddd",UserId)
  const resp = await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}
