
const Overlay_BaseMetal_Table = ({pdf}) =>{

    var WPSH_TRQ_GroovewithoutImpact_Min= "-", 

    WPSH_TRQ_GroovewithoutImpact_Max = "-",
    WPSH_TRQ_GroovewithoutImpact_Max = "-",
    WPSH_TRQ_GroovewithImpact_Min = "-",

    WPSH_TRQ_GroovewithImpact_Max = "-",

    WPSH_TRQ_FilletwithoutImpact_Min = "-",

    WPSH_TRQ_FilletwithoutImpact_Max = "-",

    WPSH_TRQ_FilletwithImpact_Min = "-",

    WPSH_TRQ_FilletwithImpact_Max= "-"
    console.log("Base Metal PDF",pdf)
    const styles = {
        container: {
          display: "flex",
        },
    
        group: {
          display: "flex",
        },
    
        title3: {
          width: "50%",
        },
      };

var TRQ_Type = pdf[0]?.wld_TRQ_Type === "Welded" ? " As-Welded" : "With PWHT"
    WPSH_TRQ_GroovewithoutImpact_Min =
      pdf[0]?.WPSH_TRQ_GroovewithoutImpact_Min === ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_GroovewithoutImpact_Min;
    WPSH_TRQ_GroovewithoutImpact_Max =
      pdf[0]?.WPSH_TRQ_GroovewithoutImpact_Max == ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_GroovewithoutImpact_Max;
    WPSH_TRQ_GroovewithImpact_Min=
      pdf[0]?.WPSH_TRQ_GroovewithImpact_Min === ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_GroovewithImpact_Min;

    WPSH_TRQ_GroovewithImpact_Max =
      pdf[0]?.WPSH_TRQ_GroovewithImpact_Max == ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_GroovewithImpact_Max;
    WPSH_TRQ_FilletwithoutImpact_Min =
      pdf[0]?.WPSH_TRQ_FilletwithoutImpact_Min === ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_FilletwithoutImpact_Min;
    WPSH_TRQ_FilletwithoutImpact_Max =
      pdf[0]?.WPSH_TRQ_FilletwithoutImpact_Max == ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_FilletwithoutImpact_Max;
    WPSH_TRQ_FilletwithImpact_Min =
      pdf[0]?.WPSH_TRQ_FilletwithImpact_Min === ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_FilletwithImpact_Min;
    WPSH_TRQ_FilletwithImpact_Max =
      pdf[0]?.WPSH_TRQ_FilletwithImpact_Max === ""
        ? "-"
        : pdf[0]?.WPSH_TRQ_FilletwithImpact_Max;
 

return(<>
<div style={styles.container}>
          <table id="table" className="WPSTable2">
  <tbody>
  <tr style={{ borderBottom: "0", borderRight: "1px solid black" }}>
  <th
                  className="WPSnoborder1st"
                  style={{
                    borderTop: "0",
                    borderLeft: "0",
                    background: "none",
                  }}
                ></th>
                <th
                  className="widthth"
                  style={{
                    borderLeft: ".11px solid black",
                    borderTop: ".11px solid black",
                    borderBottom: "0",
                  }}
                >
                  Material
                </th>

                <th
                  className="widththpno"
                  style={{
                    borderLeft: ".11px solid black",
                    borderTop: ".11px solid black",
                    borderBottom: "0",
                  }}
                >
                  P No.
                </th>
                <th
                      className="widththGrpno"
                      style={{
                        borderLeft: ".11px solid black",
                        borderTop: ".11px solid black",
                        borderRight: ".11px solid black",
                        borderBottom: "0",
                      }}
                    >
                      G No.
                    </th>  
                       
</tr>
<tr style={{ borderBottom: "0", borderRight: "1px solid black" }}>
                <th
                  className="widthblank"
                  style={{
                    borderLeft: ".11px solid black",
                    borderTop: ".11px solid black",
                    borderBottom: "0",
                  }}
                >
                  Type
                </th>
                <td
                  class="WPStable2firstcolumn"
                  style={{
                    // borderRight: ".11px solid black",
                    borderLeft: ".11px solid black",
                    borderTop: ".11px solid black",
                    borderBottom: "0",
                  }}
                >
                  {pdf[0]?.WPSH_BM_Type_Type}
                </td>
                <td
                  style={{
                    borderTop: ".11px solid black",
                    borderLeft: ".11px solid black",
                  }}
                >
                  {pdf[0]?.WPSH_BM_Type_PNo}
                </td>

                <td
                  style={{
                    borderTop: ".11px solid black",
                    borderLeft: ".11px solid black",
                  }}
                >
                  {pdf[0]?.WPSH_BM_Type_GNo}
                </td>
            </tr>
<tr style={{ borderRight: "1px solid black" }}>
                <th style={{ borderLeft: ".11px solid black", borderBottom:".11px solid black" ,width: "22%" }}>
                  Welded To
                </th>
                <td
                  class="WPStable2firstcolumn"
                  style={{
                    // borderRight: ".11px solid black",
                    borderLeft: ".11px solid black",borderBottom:".11px solid black" 
                  }}
                >
                  {pdf[0]?.WPSH_BM_WeldingTo_Type}
                </td>
                <td style={{ borderLeft: ".11px solid black",borderBottom:".11px solid black"  }}>
                  {pdf[0]?.WPSH_BM_WeldingTo_PNo}
                </td>
                <td style={{ borderLeft: ".11px solid black",borderBottom:".11px solid black"  }}>
                  {pdf[0]?.WPSH_BM_WeldingTo_GNo}
                </td>
               
              </tr>

                  <tr
                    className="WPStable2bottomboder"
                    style={{
                      borderTop: "0",
                      borderBottom: ".11px solid black",
                    }}
                  >
                    <th
                      className="WPStable2rightborder table2bottomboder"
                      style={{
                        borderLeft: ".11px solid black",
                        borderTop: "0",
                        borderBottom: ".11px solid black",
                      }}
                      scope="row"
                    >
                      Notes
                    </th>
                    <td
                      colspan="4"
                      className="WPStable2bottomboder"
                      style={{
                        borderRight: ".11px solid black",
                        borderLeft: ".11px solid black",
                      }}
                    >
                      {pdf[0]?.WPSH_BM_Notes}
                    </td>
                  </tr> </tbody></table>

                  <div className="WPSempty" style={{ width: "1%" }}></div>

<div className="WPSTable3">
<table className="WPStable-3" style={{height:"60px"}}>

    <>
      <tr>
        <td
          className="WPSnoborder"
          rowSpan="2"
          style={{ borderTop: "0", borderLeft: "0", width: "4%" }}
        ></td>

        <th
          colspan="2"
          className="WPStable3-topth"
          style={{ width: "3.7%" }}
        >
          {TRQ_Type}
        </th>
      </tr>
      <tr>
        <th className="WPStable3-sideth1">Min</th>
        <th className="WPStable3-bottomth">Max</th>
      </tr>
    </>

     <>
      <tr
        className="WPSnoborder"
        style={{ borderBottom: "0", borderTop: "0" }}
      >
        <th
          className="WPStable3-thwithboder"
          style={{ borderBottom: "0", width: "70%" }}
        >
         
            <>Base Metal CRO</>
         
        </th>

        <td
          className="WPSright-td1 dyamicwidth"
          style={{ borderRight: ".11px solid black" }}
        >
         
            <>{pdf[0]?.WPSH_TRQ_BaseMetalCRO_Min}</>
         
        </td>
        <td
          className="WPSright-td1"
          style={{ borderRight: ".11px solid black " }}
        >
         
            <>{pdf[0]?.WPSH_TRQ_BaseMetalCRO_Max}</>
         
        </td>
      </tr>
      <tr
        className="WPSnoborder"
        style={{ borderBottom: "0", borderTop: "0" }}
      >

        <th
          className="WPStable3-thwithboder"
          style={{ borderBottom:".11px solid black" , width: "65%" , borderTop:"0",  }}
        >
          
            <>Base Metal HRO</>
                            
        </th>


        <td
          className="WPSright-td1 dyamicwidth"
          style={{ borderRight: ".11px solid black",borderBottom:".11px solid black" }}
        >
         
            <>{pdf[0]?.WPSH_TRQ_BaseMetalHRO_Min}</>
         
        </td>


        <td
          className="WPSright-td1"
          style={{ borderRight: ".11px solid black " ,borderBottom:".11px solid black" }}
        >
        
            <>{pdf[0]?.WPSH_TRQ_BaseMetalHRO_Max}</>
         
        </td>
      </tr>

    </>
 
</table>
</div>

</div>
</>)

}

export default Overlay_BaseMetal_Table;
