import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import GolbalSearch from "../../utility/GolbalSearch";
import { handleExport1 } from "../../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import AddEditTrainingMaster from "./AddEditTrainingMaster";
import GlobalColumnDefinations from "../../utility/AgGridDefination";
import { getHeader_FieldName } from "../../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../../utility/GridMaster";
import Toaster from "../../utility/Toaster";
import { todayDate,maxDate,minDate } from "../../utility/CommonComponents/TodayDate";
import {
  
  DeleteTraining,
} from "../../../Services/training-service";

import ActionRendererTraining from "../../utility/ActionRendererTraining";
import { getTrainingSts, getDefaultDate } from "../../../functions/welding-functions";

import Export_blue from "../../../icons/Export_blue.svg";
import ExportIcon from "../../../icons/ExportIcon.svg";
import { getTraining } from "../Common/training";

const TrainingMaster = forwardRef((props, ref) => {
  let formName = "Training";
  const gridRef = useRef();
  const [rowData, setRowData] = useState({});
  const [filteredCol, setFilteredCol] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [training, setTraining] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");

  const [data, setdata] = useState("");
  const [reload, setReload] = useState(false);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);

  let username = localStorage.getItem("UserName");
  const childRef = useRef();
  const todayDate = getDefaultDate();
  const [newtraining,setnewTraining]= useState([]);
  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "wld_status",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "trainingCellRenderer",
    },
    {
      headerName: "Training subject",
      field: "wld_trainingSub",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "From Date",
      field: "wld_fromDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "To Date",
      field: "wld_toDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    },
    {
      headerName: "Start Time",
      field: "wld_startTime",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "End Time",
      field: "wld_endTime",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Duration (hrs)",
      field: "wld_duration",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Month of Training",
      field: "wld_trainingMonth",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Faculty",
      field: "wld_faculty",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      suppressMovable: true,
      sortable: true,
      textAlign: "center",
      filterParams: {
        buttons: ["reset", "apply"],
        closeOnApply: true,
        defaultJoinOperator: "OR",
      },
    };
  }, []);

  const handleEdit = (row) => {
    console.log("row", row);
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  };

  const handleClickcopy = (row) => {

    setAction("Copy");
    setRowData(row);
    setShowForm(true);
    if(row.wld_status === "Completed"){
      row.wld_fromDate = todayDate
      row.wld_toDate = todayDate
    }
  };

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchTraining = async () => {
    let result1 =[],result2 =[],result3 = []
   let result = await getTraining()
    let upcomingtraining = []
    result?.map(function (a, index) {
      let status = getTrainingSts(a.wld_fromDate, a.wld_toDate, todayDate);
      if (status === "In Progress") {
        a.wld_status = "In Progress";
        upcomingtraining.push(a)
        result1.push(a);
      } else if (status === "Upcoming") {
        a.wld_status = "Upcoming";
      result2.push(a);
         upcomingtraining.push(a)
      } else if (status === "Completed") {
        a.wld_status = "Completed";
         result3.push(a);
      } 
    })

     result1.sort((a, b) => {
      return new Date(a.wld_fromDate) - new Date(b.wld_fromDate);
    });

    result2.sort((a, b) => {
      return new Date(a.wld_fromDate) - new Date(b.wld_fromDate);
    });

    result3.sort((a, b) => {
      return new Date(a.wld_fromDate) - new Date(b.wld_fromDate);
    }); 

    let NewResult = [...result1, ...result2, ...result3];


    setTraining(NewResult);
    setnewTraining(upcomingtraining)
    setFilteredResult(result);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({ headerName: "", cellRenderer: actionRenderer, width: 250 ,   suppressMenu: true,
    sortable:false,});
    setColumnDefs(result);
  };

  useEffect(() => {
    fetchTraining();
    fetchColumnDefns();
  }, [reload]);

  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      
      setAction("Add");
      setShowForm(true);
      setReload(true);
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(training);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const handleClose = () => {
    setShow1(false);
  };

  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text);

  
  };

  const handleGlobalExport = () => {
    let filename = "TrainingMaster";
    setExcelData(filteredResult);
    let result = handleExport1(filteredResult, filename, tmpcolumnDefs);
  };

  const actionRenderer = (params) => {
    return (
      <ActionRendererTraining
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleCopyEvent={(params) => handleClickcopy(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  const handleDelete = (row) => {
    DeleteTraining(data)
      .then((result) => {
        if (result.data.message === "Success") {
          setShowForm(false);
          toast.success(`Selected Training has been Deleted successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          setReload(!reload);
        }
      })
      .catch((error) => {
        console.log("Unable to process request");
      })
      .finally(() => {
        setdata("");
        setShow1(false);
      });
  };

  return (
    <Container fluid>
      <ConfirmAlertModal
        action={action}
        handleYes={handleDelete}
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />

      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          Training list
        </Col>
        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={"Search for Training subject, Faculty"}
              filterFunc={handleGlobalSearch}
            />
            <img
              className="allexport"
              src={ExportIcon}
              onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
              onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
              alt="ExportIcon"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
      />
      {showForm && (
        <AddEditTrainingMaster
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          resetForm={resetForm}
          setReload={setReload}
          reload={reload}
          fetchUserMaster={fetchTraining}
          allRecs={newtraining}
          backdrop="static"
        />
      )}
      <Toaster />
    </Container>
  );
});

export default TrainingMaster;
