import React, { useEffect, useState } from "react";
import "../Css/Download_pdf.css";
import Groove_pdf_header from "./Groove_pdf_header WPS";
import Groove_BaseMetal_Table from "./GrooveQw404Table"
import Overlay_BaseMetal_Table from "./OverlayQw404Table"
import Groove_Pdf_Fields from "./WPS_Groove_pdf_fields_in_json"
import FillerMetal from "./WPSFillerMetal";
import Overlay_Pdf_Fields from "./WPS_Overlay_Welding_procedure_Json";
const Groove_pdf_page1 = ({ pdf, setpdf }, props, wld) => {
  let action = props.action;
  console.log("pdfjjfjfjfjd", pdf);
  // wld_WPSType
      
  const [detailObj, setdetailObj] = useState([]);
  var cols = 3,
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class;
  let WPSProcessDataWidth;
  
  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };

  console.log("PDFFFFFF", pdf);
  console.log("PDF", pdf);

  //((pdf[0]?.WPSH_Flux_Classification === "" || pdf[0]?.WPSH_Flux_Classification  === undefined))



  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";
  //alert(colwidth)
  console.log("PDFFDF", pdf[2]);
  col1Class = "WPSleft7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "WPSleft7-td"
        : "WPSleft7-td-noright"
      : "WPStdhidden";
  col3Class = pdf[3] !== undefined ? "WPSleft7-td-noright1" : "WPStdhidden";

  //   WPSProcessDataWidth = pdf.length < 2 ? pdf.length < 3 ? pdf.length === 3 ? "process3" : "" : "process2": "prcess1";
  WPSProcessDataWidth =
    pdf.length < 3 ? "process1" : pdf.length < 4 ? "process2" : "process3";

    console.log("pdfff",pdf);
    

  console.log("epepepep", WPSProcessDataWidth, pdf.length < 3, pdf.length < 4);
  var WPType= pdf[0].wld_WPSType !== "Overlay" ? (Groove_Pdf_Fields):(Overlay_Pdf_Fields)
 
  
  function bindewldprocess(type,processindex,Amp) {
    console.log("rrrrrrrttyyyyye", type);
    let attributetoFetch = type;

    console.log("InBind", attributetoFetch, pdf[1]);
    let ArryMap = [];

   
    let WPS_Process_Values = undefined
    let WPS_Amp = undefined
    let Array_Amp = []
    if(pdf[processindex] != undefined) 
      WPS_Process_Values=  pdf[processindex][attributetoFetch];
  
    if(pdf[processindex] != undefined)
    {
     
      WPS_Amp =  pdf[processindex][Amp];
    }
    console.log("ProcessType",attributetoFetch,WPS_Process_Values,WPS_Amp)
    WPS_Process_Values?.forEach((element, index) => {
        // console.log("sdwds",attributetoFetch, element, index); 
       
        if(WPS_Amp!= undefined){
          ArryMap.push(element)
          Array_Amp.push(WPS_Amp[index])
        }
        else
            ArryMap.push(element);
      });

  


   
    console.log("ArrayTomap11", ArryMap, ArryMap[0], ArryMap[1], ArryMap[2]);

    let Insidearray = ArryMap.filter(item=> item !== "");


    let processdata = Insidearray.length < 2  ? "insideprocess" : Insidearray.length < 3 ? "insideprocess1" : "insideprocess2"

    let processdata1 = (ArryMap[0] !== "" && ArryMap[1] === "" && ArryMap[2] === "" ) ? "insideprocess" : (ArryMap[0] !== ""  &&  ArryMap[1] !== "" && ArryMap[2] === "" ) || (ArryMap[0] !== ""  &&  ArryMap[2] !== "" && ArryMap[1] === "") || (ArryMap[1] !== ""  &&  ArryMap[2] !== "" && ArryMap[0] === "")? "insideprocess1" : "insideprocess2"
    let processdata2 = (ArryMap[1] !== "" && ArryMap[0] === "" && ArryMap[2] === "" ) ? "insideprocess" : (ArryMap[0] !== ""  &&  ArryMap[1] !== "" && ArryMap[2] === "" ) || (ArryMap[0] !== ""  &&  ArryMap[2] !== "" && ArryMap[1] === "") || (ArryMap[1] !== ""  &&  ArryMap[2] !== "" && ArryMap[0] === "") ? "insideprocess1" : "insideprocess2"
    let processdata3 = (ArryMap[2] !== "" && ArryMap[0] === "" && ArryMap[1] === "" ) ? "insideprocess" : (ArryMap[0] !== ""  &&  ArryMap[1] !== "" && ArryMap[2] === "" ) || (ArryMap[0] !== ""  &&  ArryMap[2] !== "" && ArryMap[1] === "") || (ArryMap[1] !== ""  &&  ArryMap[2] !== "" && ArryMap[0] === "") ? "insideprocess1" : "insideprocess2"


    // let processd = (ArryMap[1] !== "" ) ? "insideprocess" : (ArryMap[0] !== ""  &&  ArryMap[1] !== "")? "insideprocess1" : "insideprocess2"
    // let process = (ArryMap[2] !== "" ) ? "insideprocess" : (ArryMap[0] !== ""  &&  ArryMap[1] !== "")? "insideprocess1" : "insideprocess2"

    console.log("processdataaaa", processdata1,processdata2,processdata3);

    
    console.log("processsssssssssss", Insidearray,processdata, Insidearray.length < 2 , Insidearray.length < 3);

   
    const rows = [];
    rows.push(
      <tr key={1} style={{ background: "none" }}>
        {ArryMap[0] && (
        <td className={`${WPSProcessDataWidth} ${processdata1}`}>{
        
          ArryMap[0]}
          {WPS_Amp && <>{" "}-<br></br>{Array_Amp[0]}</>}
        </td>)}
         {ArryMap[1] && (
        <td className={`${WPSProcessDataWidth} ${processdata2}`}>{ArryMap[1]}
        {WPS_Amp && <>{" "}-<br></br>{Array_Amp[1]}</>}
        </td>)}
         {ArryMap[2] && (
        <td className={`${WPSProcessDataWidth} ${processdata3}`} >{ArryMap[2]}
         {WPS_Amp && <>{" "}-<br></br>{Array_Amp[2]}</>}
        </td>)
       
        }
        {/* <td className="processleft" style={{ width: "6%", borderRight: "0", borderLeft: "0", borderTop: "0", borderBottom: "0", textAlign: "center" }}>{ArryMap[3]}</td> */}
      </tr>
    );
    console.log("Rowwwww", rows);
    console.log("Arryryryryryry", ArryMap[0]);
    return rows;
    // return(<div className="WPSgroupcols">{rows}</div>)
  }
  return (
    <div className="WPSMainPDF">
      <Groove_pdf_header pdf={pdf}></Groove_pdf_header>

      {/*Table 1 */}
      {/*BASE METAL */}
      <div style={{ position: "relative", top: "7px" }}>
        <div style={styles.group}>
          <div className="WPSbasemetal">BASE METALS (QW-403)</div>
          <div className="WPSemptyTitle" style={{ width: "31%" }}></div>
          <div className="WPSPthickness">THICKNESS RANGE QUALIFIED</div>
          <div className="WPSmesursment">mm</div>
        </div>
        {/* <!-- ----------------------------------Table 2---------------------------------------------------- --> */}
        {pdf[0].wld_WPSType === "Groove" ? (
        <Groove_BaseMetal_Table pdf={pdf}> </Groove_BaseMetal_Table>):

       ( <Overlay_BaseMetal_Table pdf={pdf}> </Overlay_BaseMetal_Table>)}

        {/*End table 2 */}
        

        {/*Table 4 */}
        <div className="WPSgroup">
          <div className="WPStitle5">FILLER METALS (QW-404)</div>
          <div className="WPSemptyTitle" style={{ width: "3%" }}></div>
          <div className="WPSemptytitle" style={{ width: "35%" }}></div>

          <div className="WPStitle5">THICKNESS RANGE QUALIFIED</div>
          <div className="WPSemptyTitle" style={{ width: "2%" }}></div>
          <div className="WPSmesursment" style={{ paddingLeft: "1.1px" }}>
            mm
          </div>
        </div>

        {/* <!-- --------------------------Table 5----------------------------------------------- --> */}
        <div>
          <div style={{ paddingTop: ".1px" }}></div>
          <FillerMetal pdf={pdf}></FillerMetal>
        </div>

        {/* <!-- --------------------------Welding Procedure----------------------------------------------- --> */}
        <div
          className="WPSgroup"
          style={{ align: "right", textAlign: "right", width: "50%"}}
        >
          <div className="WPStitle5">WELDING PROCEDURE</div>
        </div>
        {/* <!-- --------------------------Welding Procudre----------------------------------------------- --> */}
        <table id="table" className="WPSTable7" >
        {WPType.map((i,count) => {
          var value1 = pdf[1]?.[i.FieldName]
          var value2 = pdf[2]?.[i.FieldName]
          var value3 = pdf[3]?.[i.FieldName]
        
         
          if (i.Process !== undefined) {
            value1 += "-"  + (pdf[1] ? pdf[1].WPSD_Type : "");
            value2 += "-"  + (pdf[2] ? pdf[2].WPSD_Type : "");
            value3 += "-"  + (pdf[3] ? pdf[3].WPSD_Type : "");
        }
        
          else if(i.Colums && i.Colums.length >0 && i.Amp == undefined){
            value1= bindewldprocess(i.Colums[0],1)  
            value2 = bindewldprocess(i.Colums[1],2)
            value3 =  bindewldprocess(i.Colums[2],3)
          }
          else if(i.Amp != undefined){
            value1 = bindewldprocess(i.Colums[0],1,i.Amp[0]) //+  " " +  bindewldprocess(i.Amp[0],1)
            value2 =  bindewldprocess(i.Colums[1],2,i.Amp[0]) //+  " " +  bindewldprocess(i.Amp[1],2)
            value3 =  bindewldprocess(i.Colums[2],3,i.Amp[0]) //+  " " +  bindewldprocess(i.Amp[2],3)
            console.log("value1",value1);
            
          }
          var paddingleft = i.ColumnDesc != "" ? "WPSwithLeftrcorner" :"WPSwithoutLeftrcorner"
          var middleLeft  = i.ColumnDesc != "" ? "WPSMidwithLefttext" :"WPSMidwithoutLefttext" 
          var midtext = i.Suffix  === "Trailing" ?  <> &nbsp;&nbsp;&nbsp;{i.MiddleDesc} </> : i.Suffix === "Backing" ?  <> &nbsp;&nbsp;{i.MiddleDesc}</> :  <>{i.MiddleDesc}</>
          var rowClass = "WPStrhide";
          var ThClass = "WPSthhide"
           if (count === 0) {
               rowClass = "WPStrFirstRow";
               ThClass ="WPSthhideFirstRow"
          }
          var lastrowstyle ="none"
          
          if(count == WPType.length-1){
            ThClass="WPSthhideLastRow"
            rowClass = "WPStrLastRow"
      
        
          }
          return(
          
          <tr className={rowClass}>
          
            <th
              className={ThClass}
              style={{  width: "27%" }}
            >
              {i.MiddleDesc != undefined ?  
              <div className="WPSgroup">
              <div className="WPSlcorner" >
             {i.ColumnDesc}
             </div>
            <div className={middleLeft}>
                {midtext}
                </div>
             <div className={paddingleft} > {i.Unit} </div>
             </div>
            :
            <>
            <div className="WPSlcorner" >
             {i.ColumnDesc}
             </div>
           
             <div className="WPSrcorner1"> {i.Unit} </div>
             </>
            }
            </th>
             
            <td className={col1Class} style={{ width: { colwidth } }}>
              {value1}
            </td>

            <td className={col2Class} style={{ width: { colwidth } }}>
              {value2}
            </td>
            <td className={col3Class} style={{ width: { colwidth } }}>
              {value3}
            </td>
          </tr>)
        })}

          </table>
        {/* <!-- --------------------------Welding Procedure End ----------------------------------------------- --> */}
   
      </div>
      <br></br>
      <div id="footer" style={{ position: "fixed", top: "825px" }}>
        <table className="WPSfooter">
          <tr>
            <td>Page 1 of 2</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
export default Groove_pdf_page1;
