export default function Validate(file) {
  let error = "";
  let fileName = file.name;
  let fileExtn =
    fileName.indexOf(".") > 0
      ? fileName.split(".").pop().toLowerCase()
      : "undefined";
  let fileSize = file.size;
  //let format = process.env.REACT_APP_SPECIAL_CHARACTERS;
  let allowedExtensions = process.env.REACT_APP_DEVIATION_FILE_EXTENSIONS;


  if (fileName.match(/\./g).length !== 1) {
    error = error + "File name contains more than one dot. ";
  }

  if (!allowedExtensions.includes(fileExtn)) {
    error = error + "Unsupported file format. ";
  }

  if (fileSize > 5242880) {
    error = error + "File size is greater than 5MB.";
  }
  return error;
}

export const specialCharError = (e) => {
  let specialchar = process.env.REACT_APP_SPECIAL_CHARACTERS;
  let charsToCheck = "/\r\t/";
  let word = e.target.value.trim();
  let errorFlag = specialchar.split("").some((char) => word.includes(char));
  let errorFlag0 = charsToCheck
    .split("")
    .some((char) => word.charAt(0).includes(char));

  if (!!(errorFlag || errorFlag0)) {
    //let result1 = specialchar.replace(/.{1}/g, '$&, ');
    return { flag: true, msg: "Special Characters " + specialchar + " are not allowed." }
  }
  else {
    return { flag: false, msg: "" }
  }
};
