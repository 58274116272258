import axios from "axios";

let GROOVE_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  GROOVE_API_URL = process.env.REACT_APP_WPS_GROOVE_API_URL;
} else {
  GROOVE_API_URL = process.env.REACT_APP_API_URL + "/wps";
}

export async function getAllLogs() {
  const response = await axios
    .get(GROOVE_API_URL + "/WpsLogs")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddLogs = async (wld_WPS_Logs) => {
  
  console.log("AddNewAno -- ", wld_WPS_Logs);
  console.log(JSON.stringify(wld_WPS_Logs));
  let url = `${GROOVE_API_URL}/WpsLogs`;
  const response = await axios
    .post(url, wld_WPS_Logs)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
