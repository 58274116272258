import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import ConfirmAlertModal from "../../Modals/confirmAlertModal";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Container, Tabs, Tab } from "react-bootstrap";


import GlobalColumnDefinations from "../../utility/AgGridDefination";
import { getHeader_FieldName } from "../../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";
import GridMaster from "../../utility/GridMaster";
import Toaster from "../../utility/Toaster";

import { getDefaultDate } from "../../../functions/welding-functions";
import { getReqByType } from "../../../Services/trainingRequisition-service";
import ActioRenderTrainingReq from "../../utility/ActioRenderTrainingReq";



export const TrainingReqInitiated = forwardRef((props,ref) => {
  const childRef = useRef();
  console.log("InsideTrainingReqApproval", props.trainType, props, props.key)
  let formName = "TrainingRequisition";
  const [reload, setReload] = useState(false);
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [training, setTraining] = useState(null);
  const [action, setAction] = useState("");

  const [data, setdata] = useState("");
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  let userId = sessionStorage.getItem("User");




  const handleEdit = (row) => {
    console.log("row", row);
    props.setAction("Edit");
    props.setRowData(row);
    props.setShowForm(true);

    props.setCalledFrom("Initiated")
  };

  useImperativeHandle(ref, () => ({
    reload: async () => {
      fetchTrainingRequisition();
      setRefere();
      fetchColumnDefns();
    }, 
   }));

   useEffect( ()=>{
    fetchTrainingRequisition();
    setRefere();
    fetchColumnDefns();
  },reload)

  const headerName = (field) => {
    let name = getHeader_FieldName(field, props.tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, props.tmpcolumnDefs, "getField");
    return name;
  };
  function setRefere() {
    props.setRefe(childRef)
  }
  const fetchTrainingRequisition = async () => {
    
    let tmpObj = {fieldValue : userId, flag : "initiated"}
    let result = await getReqByType(tmpObj);

    setFilteredResult(result);
  };
  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(props.tmpcolumnDefs);
    result[result.length - 2].cellRenderer = actionRenderer;
    setColumnDefs(result);
  };

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(training);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const handleClose = () => {
    setShow1(false);
  };

  const actionRenderer = (params) => {

    return (
      <ActioRenderTrainingReq
        selectedData={params}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => {
          setdata(params.data);
          setShow1(true);
          setAction("Delete");
        }}
      />
    );
  };

  return (
    <Container fluid>
      <ConfirmAlertModal
        formName={formName}
        show={show1}
        handleClose={handleClose}
      />

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={props.setExcelData}
        ref={childRef}
        paginationPageSize={7}
        pagination={true}
        height={450}
      />
      <Toaster />
    </Container>
  )
})