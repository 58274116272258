const TechniqueTable_overlay = ({pdf}) =>{
    var cols = 3,    
    colwidth = "24%",
    col1Class,
    col2Class,
    col3Class;
  let WPSProcessDataWidth;
  
  const styles = {
    container: {
      display: "flex",
    },

    group: {
      display: "flex",
    },

    title3: {
      width: "50%",
    },
  };
  cols = pdf[2] !== undefined ? (pdf[3] !== undefined ? 3 : 2) : 1;
  colwidth =
    pdf[2] !== undefined ? (pdf[3] !== undefined ? "23%" : "35%") : "70%";
  console.log("PDFFDF", pdf[2]);
  col1Class = "WPSleft7-td-noleft";
  col2Class =
    pdf[2] !== undefined
      ? pdf[3] !== undefined
        ? "WPSleft7-td"
        : "WPSleft7-td-noright"
      : "WPStdhidden";
  col3Class = pdf[3] !== undefined ? "WPSleft7-td-noright1" : "WPStdhidden";

 WPSProcessDataWidth = pdf.length < 3 ? "process1" : pdf.length < 4 ? "process2" : "process3";

    

return(<>
     <table id="table" className="WPSTable7">
                <tr className="WPStrhide">
                  <th
                    style={{ borderTop: "1px solid black", width: "22%" }}
                    className="WPSthhide "
                  >
                    Weld Deposit Chemistry{" "}
                  </th>
                  <td className="borderTop">
                    {pdf[0]?.WPSH_Tech_Weld_deposit_chemistry}
                  </td>

                  <th
                    style={{ borderTop: "1px solid black", width: "22%" }}
                    className="WPSthhide "
                  >
                    Initial/ Interpass Cleaning
                  </th>
                  <td className="borderTop">
                    {pdf[0]?.WPSH_Tech_Inital_InterpassCleaning}
                  </td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Supplementary MF Control</th>
                  <td>{pdf[0]?.WPSH_Tech_SupplementaryMFControl}</td>
                  <th className="WPSthhide">Surface Preparation</th>
                  <td>{pdf[0]?.WPSH_Tech_SurfacePreparation}</td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Oscillation</th>
                  <td>{pdf[0]?.WPSH_Tech_Oscillation}</td>
                  <th className="WPSthhide">Peening</th>
                  <td>{pdf[0]?.WPSH_Tech_Peening}</td>
                </tr>
                <tr className="WPStrhide">
                  <th className="WPSthhide">Oscillation Width(mm)</th>
                  <td>{pdf[0]?.WPSH_Tech_Oscillation_Width}</td>
                  <th className="WPSthhide">Filler Metal Delivery(GTAW)</th>
                  <td>{pdf[0]?.WPSH_Tech_Filler_Metal_Del}</td>
                </tr>
                <tr className="WPStrhide ">
                  <th
                    style={{ borderBottom: "1px solid black" }}
                    className="WPSthhide "
                  >
                    Oscillation Frequency in CPM
                  </th>
                  <td className="borderBottom">
                    {pdf[0]?.WPSH_Tech_Oscillation_Freq}
                  </td>
                  <th
                    style={{ borderBottom: "1px solid black" }}
                    className="WPSthhide "
                  ></th>
                  <td className="borderBottom"></td>
                </tr>{" "}
              </table>
</>
)
}
export default TechniqueTable_overlay;