import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { Form } from "react-bootstrap";
import {
  SearchProcessSelect,
  SearchProcessSelect1,
  SearchSelect,
} from "../../utility/search-select";
import { isAlphanumericWithDashDot } from "../../../functions/validations";

export const WPS_Process_Grid_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        console.log("wps_detail_id", id);
        console.log("message", message);

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={item[name]}
              maxLength={maxLength}
              // readOnly={readOnly}
              // value={item.WPSD_Process !== "" &&  item.WPSD_Type !== "" ? item.WPSD_WP_MinimumPreheatTemp : "NA"}
              // disabled={ location.state?.action === "Edit" && item.WPSD_WP_MinimumPreheatTemp === ""}
              // readOnly={item.WPSD_Process === "" &&  item.WPSD_Type === ""}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_3 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "1"}
                value={item[name][0]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[0] === ""}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "2"}
                value={item[name][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "3"}
                value={item[name][2]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  array,
  handleChangedetailsarray,
  option,
  onChange,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <select
              style={{
                width: "87px",
                height: "30px",
                
                borderRadius: "3px",
              }}
              className={status ? "error-input" : ""}
              name={name}
              value={item[name]}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
                //   setError((prevState) => ({
                //     ...prevState,
                //     WPSD_WP_TungstenSIze: {status: false, message: ""},
                //   }));
              }}
            >
              {array &&
                array.map((e, id) => {
                  return (
                    <option key={id} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}

              {/* <option value="1">1</option>
              <option value="1.6">1.6</option>
              <option value="2.4">2.4</option>
              <option value="3.2">3.2</option>
              <option value="2.4/3.2">2.4/3.2</option> */}
            </select>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  obj,
  setfunction,
  onInput,
  ColumnHeader1,
  name1,
}) => {
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
      //   className={error ? "WpsInput-md error" : "WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
        // className={
        //   error ? "text-start error-head" : "text-start thead-color"
        // }
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3">
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-center">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                type={type}
                // className={
                //     error.WPSD_WP_ShieldingGasType.status
                //       ? "error-input"
                //       : ""
                //   }
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                // className="mb-3 mt-2"
                name={name}
                value={item[name]}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput}
              />

              <input
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                value={item[name1]}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput1}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select_3 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  array,
  handleChange,
  option,
  onChange,
  setfunction,
  obj,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div>
              <select
                style={{
                  width: "85px",
                  height: "32px",
                  
                  borderRadius: "4px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => {
                  handleChange(id, 0, name, obj, setfunction, e);

                  //   setError((prevState) => ({
                  //     ...prevState,
                  //     WPSD_WP_LayerNumber: {status: false, message: ""},
                  //   }));
                }}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",
                  
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",
                  
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "3"}
                // value = {item[name[2]]}
                value={item[name][2]}
                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const Single_Input = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? "T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};
export const Single_Input_Superviser_remark = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? "T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const textarea_Input = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <textarea
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? "T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select_green = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
}) => {
  return (
    <>
      <div class="green-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {/* <option value=" " selected>
                    select
                  </option>
                  <option value="With">With</option>
                  <option value="With/Without">With/Without</option>
                  <option value="NA">NA</option>
                </select> */}
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {/* <option value=" " selected>
                    select
                  </option>
                  <option value="With">With</option>
                  <option value="With/Without">With/Without</option>
                  <option value="NA">NA</option>
                </select> */}
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

// export const WPS_Process_Grid_Select_master_data = ({
//   status,
//   item,
//   message,
//   Error,
//   wps_detail,
//   ColumnHeader,
//   Columndimension,
//   data,
//   type,
//   name,
//   setError,
//   error,
//   valueField,
//   labelField,
//   errorstyle,
//   bluestyle,
//   onInput,
//   errorr,
//   value,
//   readOnly,
//   array,
//   handleChangedetail,
//   option,
//   onChange,
//   setdetailObj,
//   detailObj,
// }) => {
//   return (
//     <tr className={status ? "border error" : ""}>
//       <th
//         className={status ? "text-start thead-color" : "text-start thead-color"}
//       >
//         <div>
//           {ColumnHeader}
//           <span>{Columndimension}</span>

//                             ></SearchSelect>
//                             </div>

//       </td>
//     );
//   })}
// </tr>)
// }

export const WPQ_process_Input_2 = ({
  wpq_process,
  status,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  ColumnHeader,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("hiiiiii", wpq_process);
            console.log("abci", item);
            console.log("abcid", Index);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("xxyyzz", element);
                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                value={value}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abcc", element);
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log("abcccc", element.Range_Qualified);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                name={name}
                                type="text"
                                value={value}
                                onChange={(e) =>
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>
    </tr>
  );
};

export const WPQ_process_Input_1 = ({
  wpq_process,
  maxlength,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  onInput,
  setError,
  error,
}) => {
  
  return (
    <tr
      className={
        status || status1 ? "border WpqInput-md error" : "border WpqInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("hiiiiii", wpq_process);
            console.log("abci", item);
            console.log("abcid", Index);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("xxyyzz", element);
                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "14px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                                // onInput={(e) => {
                                //   let error = isAlphanumericWithDashDot(e.target.value);

                                //   setError((prevState) => ({
                                //     ...prevState,
                                //     WPQ_FM_Classification: [
                                //       {
                                //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
                                //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
                                //       },
                                //       {
                                //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
                                //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
                                //       },
                                //     ],
                                //   }));
                                // }
                                // }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abcc", element);
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log("abcccc", element.Range_Qualified);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                name={name}
                                readOnly={readOnly}
                                type="text"
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>
    </tr>
  );
};

export const WPQ_process_Input_1Actual = ({
  wpq_process,
  maxlength,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  onInput,
  setError,
  error,
}) => {
  
  return (
    <tr
      className={
        status || status1 ? "border WpqInput-md error" : "border WpqInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("hiiiiii", wpq_process);
            console.log("abci", item);
            console.log("abcid", Index);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("xxyyzz", element);
                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "14px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                                // onInput={(e) => {
                                //   let error = isAlphanumericWithDashDot(e.target.value);

                                //   setError((prevState) => ({
                                //     ...prevState,
                                //     WPQ_FM_Classification: [
                                //       {
                                //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
                                //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
                                //       },
                                //       {
                                //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
                                //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
                                //       },
                                //     ],
                                //   }));
                                // }
                                // }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>

      <td>
     
      </td>
    </tr>
  );
};


export const WPQ_process_textarea_1 = ({
  wpq_process,
  maxlength,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  onInput,
  setError,
  error,
}) => {
  
  return (
    <tr
      className={
        status || status1 ? "border WpsInput-md error" : "border WpsInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("hiiiiii", wpq_process);
            console.log("abci", item);
            console.log("abcid", Index);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("xxyyzz", element);
                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
console.log("abccc",a)
return (
<div key={i}> */}

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <textarea
                                style={{
                                  width: "120px",
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                                // onInput={(e) => {
                                //   let error = isAlphanumericWithDashDot(e.target.value);

                                //   setError((prevState) => ({
                                //     ...prevState,
                                //     WPQ_FM_Classification: [
                                //       {
                                //         Actual_Value: [error, prevState.WPQ_FM_Classification[0].Actual_Value[0]],
                                //         Range_Qualified: prevState.WPQ_FM_Classification[0].Range_Qualified,
                                //       },
                                //       {
                                //         Actual_Value: prevState.WPQ_FM_Classification[1].Actual_Value,
                                //         Range_Qualified: [error, prevState.WPQ_FM_Classification[1].Range_Qualified[1]],
                                //       },
                                //     ],
                                //   }));
                                // }
                                // }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abcc", element);
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log("abcccc", element.Range_Qualified);

                            return (
                              <textarea
                                style={{
                                  width: "120px",
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                name={name}
                                readOnly={readOnly}
                                type="text"
                                value={value}
                                maxLength={maxlength}
                                onChange={(e) =>
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
)})} */}
      </td>
    </tr>
  );
};

export const WPQ_process_Input_1_row_3 = ({
  wpq_process,
  maxlength,
  disabled,
  readOnly,
  status,
  status1,
  handleActualValueChange,
  handleOtherChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  SfaActive,
  valueField,
  customstyles,
  onInput,
}) => {
  console.log("SfaActive_WPQ_process_Input_1_row_3", SfaActive);
  return (
    <tr
      className={
        status || status1 ? "border WPSSelectsm error" : "border WPSSelectsm"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {/* Below code is working */}
      <td className="WpqInput-md">
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("hiiiiii", wpq_process);
            console.log("abci", item);
            console.log("abcid", Index);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("xxyyzz", element);
                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
  console.log("abccc",a)
  return (
  <div key={i}> */}

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                type="text"
                                maxLength={maxlength}
                                readOnly={readOnly}
                                name={name}
                                value={value}
                                onInput={onInput}
                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
  )})} */}
      </td>

      <td className="WpqInput-md">
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abcc", element);
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log("abcccc", element.Range_Qualified);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                                key={valueIndex}
                                readOnly={readOnly}
                                maxLength={maxlength}
                                name={name}
                                type="text"
                                value={value}
                                onInput={onInput}
                                onChange={(e) =>
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
  )})} */}
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abcc", element);
                    return (
                      <div key={id}>
                        {element.Other &&
                          element.Other.map((value, valueIndex) => {
                            console.log("abcdddd", element.Other);

                            return (
                              // <input
                              //   style={{
                              //     marginRight: "10px",
                              //     marginLeft: "10px",
                              //   }}
                              //   key={valueIndex}
                              //   name={name}
                              //   type="text"
                              //   value={value}
                              //   onChange={(e) =>
                              //     handleRangeQualifiedChange(
                              //       Index,
                              //       name,
                              //       id,
                              //       valueIndex,
                              //       e.target.value
                              //     )
                              //   }
                              // />
                              <SearchProcessSelect
                                key={valueIndex}
                                customstyles={customstyles}
                                disabled={disabled}
                                // className={error.WPQ_FM_Specification.status ? "error-input inspdrop" : "inspdrop"}
                                data={SfaActive}
                                name={name}
                                // status={error.WPQ_FM_Specification.status}
                                valueField={valueField}
                                labelField={valueField}
                                value={value}
                                // stylegreen={error.WPQ_FM_Specification.status ? errorstyle : bluestyle}
                                placeholder=""
                                // onChange={(e) => {
                                
                                //   console.log("iitem",ItemActive)

                                //   value = e.value;
                                //   console.log("deee",value)

                                //   setdetailObj([...detailObj]);
                                //   // setError((prevState) => ({
                                //   //   ...prevState,
                                //   //   name: { status: false, message: "" },
                                //   // }));
                                // }}
                                onChange={(e) => {
                                  
                                  value = e.value;
                                  handleOtherChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    value
                                  );
                                }}
                              ></SearchProcessSelect>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}

        {/* </div>
  )})} */}
      </td>
    </tr>
  );
};

export const WPQ_process_select = ({
  wpq_process,
  disabled,
  status,
  status1,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  message1,
  ColumnHeader,
  array,
}) => {
  return (
    <tr
      className={
        status || status1 ? "border WpsInput-md error" : "border WpsInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abec", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("ggggg", element);
                    return (
                      <div key={id}>
                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <div class="T-input w-20">
                                <select
                                  style={{
                                    width: "120px",
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                  }}
                                  key={valueIndex}
                                  // type="text"
                                  name={name}
                                  disabled={disabled}
                                  value={value}
                                  onChange={(e) =>
                                    handleActualValueChange(
                                      Index,
                                      name,
                                      id,
                                      valueIndex,
                                      e.target.value
                                    )
                                  }
                                >
                                  {array &&
                                    array.map((e, id) => {
                                      return (
                                        <option key={id} value={e.value}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                  {/* <option value=" " selected>
    select
  </option>
  <option value="With">With</option>
  <option value="With/Without">With/Without</option>
  <option value="NA">NA</option> */}
                                </select>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abec", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abccd", element);
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log("abcccc", element.Range_Qualified);

                            return (
                              <div class="T-input w-20">
                                <select
                                  style={{
                                    width: "120px",
                                    width: "120px",
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                  }}
                                  key={valueIndex}
                                  name={name}
                                  disabled={disabled}
                                  value={value}
                                  onChange={(e) =>
                                    handleRangeQualifiedChange(
                                      Index,
                                      name,
                                      id,
                                      valueIndex,
                                      e.target.value
                                    )
                                  }
                                >
                                  {array &&
                                    array.map((e, id) => {
                                      return (
                                        <option key={id} value={e.value}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export const WPQ_process_select_data = ({
  wpq_process,
  status,
  disabled,
  handleActualValueChange,
  name,
  handleRangeQualifiedChange,
  message,
  ColumnHeader,
  array,
  SfaActive,
  valueField,
  customstyles,
  customstyles2,
}) => {
  return (
    <tr className={status ? "border WPSSelectsm error" : "border WPSSelectsm"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}
      <td className="WPSSelectsm">
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abec", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abccd", element);
                    return (
                      <div key={id} className="selectstyle">
                        {/* className="selectstyle" */}
                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);
                            console.log("value", value);

                            return (
                              <SearchProcessSelect
                                key={valueIndex}
                                // className={error.WPQ_FM_Specification.status ? "error-input inspdrop" : "inspdrop"}
                                data={SfaActive}
                                disabled={disabled}
                                name={name}
                                // status={error.WPQ_FM_Specification.status}
                                valueField={valueField}
                                labelField={valueField}
                                customstyles={customstyles}
                                value={value}
                                // stylegreen={error.WPQ_FM_Specification.status ? errorstyle : bluestyle}
                                placeholder=""
                                // onChange={(e) => {
                                
                                //   console.log("iitem",ItemActive)

                                //   value = e.value;
                                //   console.log("deee",value)

                                //   setdetailObj([...detailObj]);
                                //   // setError((prevState) => ({
                                //   //   ...prevState,
                                //   //   name: { status: false, message: "" },
                                //   // }));
                                // }}
                                onChange={(e) => {
                                  
                                  value = e.value;
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    value
                                  );
                                }}
                              ></SearchProcessSelect>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>

      <td className="WPSSelectsm">
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abec", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index} className="selectstyle">
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abccd", element);
                    return (
                      <div key={id}>
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log("abcccc", element.Range_Qualified);
                            console.log("value", value);

                            return (
                              <SearchProcessSelect1
                                key={valueIndex}
                                // className={error.WPQ_FM_Specification.status ? "error-input inspdrop" : "inspdrop"}
                                data={SfaActive}
                                disabled={disabled}
                                name={name}
                                // status={error.WPQ_FM_Specification.status}
                                valueField={valueField}
                                labelField={valueField}
                                value={value}
                                customstyles2={customstyles2}
                                // stylegreen={error.WPQ_FM_Specification.status ? errorstyle : bluestyle}
                                placeholder=""
                                // onChange={(e) => {
                                
                                //   console.log("iitem",ItemActive)

                                //   value = e.value;
                                //   console.log("deee",value)

                                //   setdetailObj([...detailObj]);
                                //   // setError((prevState) => ({
                                //   //   ...prevState,
                                //   //   name: { status: false, message: "" },
                                //   // }));
                                // }}
                                onChange={(e) => {
                                  
                                  value = e.value;
                                  handleRangeQualifiedChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    value
                                  );
                                }}
                              ></SearchProcessSelect1>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export const WPQ_process_CheckBox = ({
  wpq_process,
  status,
  disabled,
  readOnly,
  handleActualValueChange,
  name,
  handleRangeQualifiedChangeCheckBox,
  message,
  ColumnHeader,
  array,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}
      <td></td>

      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abcc", element);
                    return (
                      <div key={id}>
                        {/* {element.WPSD_WP_FillerMetalSIze && element.WPSD_WP_FillerMetalSIze.map((a, i) =>{ 
  console.log("abccc",a)
  return (
<div key={i}> */}

                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log(
                              "checkkabc",
                              element.Range_Qualified,
                              value
                            );

                            return (
                              <>
                                <input
                                  className="checkboxcolor"
                                  style={{
                                    marginRight: "8px",
                                    marginLeft: "8px",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                  disabled={disabled}
                                  readOnly={readOnly}
                                  key={valueIndex}
                                  type="checkbox"
                                  name={name}
                                  value={value}
                                  checked={value[2] === "Checked"}
                                  onChange={(e) => {
                                    handleRangeQualifiedChangeCheckBox(
                                      Index,
                                      name,
                                      id,
                                      valueIndex,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label className="checkbox_label">
                                  {value[3]}
                                </label>
                              </>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};

export const WPQ_process_input_and_checkbox = ({
  wpq_process,
  disabled,
  status,
  readOnly,
  status1,
  handleRangeQualifiedChange2,
  name,
  checkedbox,
  setcheckedbox,
  handleRangeQualifiedChangeCheckBox2,
  message,
  message1,
  ColumnHeader,
  error,
  setError,
  onInput,
  onInput1,
  onInputcheck,
  twodecimal,
  array,
  maxlength,
  handleActualValueChange,
}) => {
  return (
    <tr
      className={
        status || status1 ? "border WpsInput-md error" : "border WpsInput-md"
      }
    >
      <th
        className={
          status || status1
            ? "text-start thead-color"
            : "text-start thead-color"
        }
      >
        <div>
          {ColumnHeader}
          {/* <span>mm</span> */}
          {(status || status1) && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {/* Below code is working */}
      <td className="WpqInput-md">
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("hiiiiii", wpq_process);
            console.log("abci", item);
            console.log("abcid", Index);

            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("xxyyzz", element);
                    return (
                      <div key={id}>
                       

                        {element.Actual_Value &&
                          element.Actual_Value.map((value, valueIndex) => {
                            console.log("abcccc", element.Actual_Value);

                            return (
                              <input
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "14px",
                                }}
                                key={valueIndex}
                                type="text"
                                name={name}
                                readOnly={readOnly}
                                value={value}
                                maxLength={maxlength}

                                onChange={(e) =>
                                  handleActualValueChange(
                                    Index,
                                    name,
                                    id,
                                    valueIndex,
                                    e.target.value
                                  )
                                }
                                onInput={onInput}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
      {/* <td></td> */}
      <td>
        {wpq_process &&
          wpq_process.map((item, Index) => {
            console.log("abc", wpq_process);
            console.log("abci", item);

          
            return (
              <div key={Index}>
                {item[name] &&
                  item[name].map((element, id) => {
                    console.log("abccddd", element);
                    return (
                      <div key={id} className="input_checkbox">
                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log(
                              "checkkabcfgf4",
                              element.Range_Qualified[2].Range_Qualified2[0][2],
                              value
                            );

                            return (
                              <div key={valueIndex}>
                                {value.Range_Qualified1 &&
                                  value.Range_Qualified1.map(
                                    (value2, valueIndex2) => {
                                      console.log(
                                        "Range_Qualified222",
                                        value.Range_Qualified2,
                                        value2
                                      );
                                      return (
                                        <>
                                          {element.Range_Qualified[2]
                                            .Range_Qualified2[0][2] ===
                                          "Checked" ? (
                                            <input
                                              style={{
                                                marginRight: "8px",
                                                marginLeft: "60px",
                                                border: "none",
                                              }}
                                              key={valueIndex2}
                                              type="input"
                                              name={name}
                                              value="9.999.00"
                                              readOnly={
                                                element.Range_Qualified[2]
                                                  .Range_Qualified2[0][2] ===
                                                "Checked"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          ) : (
                                            <input
                                              style={{
                                                marginRight: "8px",
                                                marginLeft: "60px",
                                              }}
                                              key={valueIndex2}
                                              type="text"
                                              name={name}
                                              value={value2}
                                              readOnly={
                                                element.Range_Qualified[2]
                                                  .Range_Qualified2[0][2] ===
                                                "Checked"
                                                  ? true
                                                  : false
                                              }
                                              placeholder="Enter Value"
                                              onChange={(e) => {
                                                handleRangeQualifiedChange2(
                                                  Index,
                                                  name,
                                                  id,
                                                  valueIndex,
                                                  valueIndex2,
                                                  checkedbox,
                                                  e.target.value
                                                );
                                              }}
                                              onInput={onInput1}
                                            />
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}

                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log(
                              "checkkabcfgf",
                              element.Range_Qualified,
                              value
                            );

                            return (
                              <div key={valueIndex}>
                                {value.Range_Qualified3 &&
                                  value.Range_Qualified3.map(
                                    (value2, valueIndex2) => {
                                      console.log(
                                        "Range_Qualified2",
                                        value.Range_Qualified3,
                                        value2
                                      );
                                      return (
                                        <>
                                          <label className="OR_label">
                                            {value2[0]}
                                          </label>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}

                        {element.Range_Qualified &&
                          element.Range_Qualified.map((value, valueIndex) => {
                            console.log(
                              "checkkabcfgf",
                              element.Range_Qualified,
                              value
                            );

                            return (
                              <div key={valueIndex}>
                                {value.Range_Qualified2 &&
                                  value.Range_Qualified2.map(
                                    (value2, valueIndex2) => {
                                      console.log(
                                        "Range_Qualified2",
                                        value.Range_Qualified1,
                                        value2
                                      );
                                      return (
                                        <>
                                          <input
                                            className="checkboxcolor"
                                            style={{
                                              marginRight: "8px",
                                              marginLeft: "50px",
                                              marginTop: "5px",
                                              width: "15px",
                                              height: "15px",
                                            }}
                                            key={valueIndex2}
                                            type="checkbox"
                                            disabled={disabled}
                                            name={name}
                                            value={
                                              value2[2] === "Checked"
                                                ? (value2[0] = "9999.00")
                                                : value2[0]
                                            }
                                            checked={value2[2] === "Checked"}
                                            onClick={() => {
                                              setcheckedbox(!checkedbox);
                                            }}
                                            onChange={(e) => {
                                              handleRangeQualifiedChangeCheckBox2(
                                                Index,
                                                name,
                                                id,
                                                0,
                                                valueIndex,
                                                valueIndex2,
                                                checkedbox,
                                                e.target.value
                                              );

                                              setError((prevState) => ({
                                                ...prevState,
                                                [name]: [
                                                  { Actual_Value: [""] },
                                                  {
                                                    Range_Qualified: [
                                                      {
                                                        Range_Qualified1: [""],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              }));
                                            }}
                                          />
                                          <label className="checkbox_label">
                                            {value2[3]}
                                          </label>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </td>
    </tr>
  );
};
