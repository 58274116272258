
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { getCurrentDateTime } from "../../functions/welding-functions";
import { tableHeader } from "./Excel_Headers";

import { Welder_Record_tableHeader } from "./Excel_Headers";


export async function ExportToExcel(fileName,colwidth, excelData,Data,titleCell,titleWelderRange,tbodyHeadRange,tbodyRange,blankRow,Headings) {
  console.log("excelData1112",excelData)

  let wb = XLSX.utils.book_new();  

  // var todayDate = getCurrentDateTime("-").substring(0, 10);

  let data = [...tableHeader, ...Data]

  let alldata = [''].concat([''])
  .concat([''])    
    .concat([''])
    .concat([''])
    .concat(data);

  console.log("data is ----------", alldata);
  let ws = XLSX.utils.json_to_sheet(alldata, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "List of Welders report");


  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  const dataInfo1 = {
    titleCell: titleCell,
    titleWelderRange: titleWelderRange,
    tbodyHeadRange: tbodyHeadRange, 
    tbodyRange: `${tbodyRange}:L${alldata.length}`,
    blankRow: blankRow, 
  };

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];    

    sheet.row(5).style('wrapText', true);    
    
    sheet.gridLinesVisible(false);

    sheet.freezePanes(0, 2);
  //  console.log("Excel",sheet)
     for(var i=0;i<colwidth.length;i++){
       console.log("Excel",colwidth[i])
      sheet.column(colwidth[i].col).width(colwidth[i].width)
    } 

   /*  sheet.column("A").width(2.5);
    sheet.column("B").width(13.7);
    sheet.column("C").width(13.7);
    sheet.column("D").width(13.7);
    sheet.column("E").width(8.5);
    sheet.column("F").width(9.5);
    sheet.column("G").width(16.5);
    sheet.column("H").width(20);
    sheet.column("I").width(13.7);
    sheet.column("J").width(13.7);
    sheet.column("K").width(15);
    sheet.column("L").width(18); */


    // sheet.cell("I3").value("As on: "+todayDate);        
    // sheet.cell("G2").value("LIST OF WELDERS USED IN PROJECT").style("underline");
    // sheet.cell("C4").value("1.One row displays data for one slip number for entered filter. Slip number is unique. ");        
  
    for(var i=0;i<Headings.length;i++){
      console.log("Excelllllll",Headings[i])
     sheet.cell(Headings[i].col).value(Headings[i].value)
   } 

    sheet.range(dataInfo1.titleWelderRange).style({            
      bold:true ,
      underline: true
    })

    sheet.range(dataInfo1.tbodyHeadRange).style({
      bold:true,
      horizontalAlignment:'center',
      border: "thin",      
    })
    sheet.range(dataInfo1.tbodyRange).style({           
      border: "thin",      
    })
 
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
    
  });
  

}


export async function ExportToExcel_Welder_Record(fileName,colwidth, excelData,Data,titleCell,titleWelderRange,tbodyHeadRange,tbodyRange,blankRow,Headings,imageBase64) {


  console.log("excelData1112",excelData)

  let wb = XLSX.utils.book_new();  

  // var todayDate = getCurrentDateTime("-").substring(0, 10);

  let data = [...Welder_Record_tableHeader, ...Data]

  let alldata = [''].concat([''])
  .concat([''])    
    .concat([''])
    .concat([''])
    .concat(data);

  console.log("data is ----------", alldata);
  let ws = XLSX.utils.json_to_sheet(alldata, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, "List of Welders report");


  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });



  const dataInfo1 = {
    titleCell: titleCell,
    titleWelderRange: titleWelderRange,
    tbodyHeadRange: tbodyHeadRange, 
    tbodyRange: `${tbodyRange}:F${alldata.length}`,
    blankRow: blankRow, 
  };

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];    

    sheet.row(5).style('wrapText', true);    
    
    sheet.gridLinesVisible(false);

    sheet.freezePanes(0, 2);
  //  console.log("Excel",sheet)
     for(var i=0;i<colwidth.length;i++){
       console.log("Excel",colwidth[i])
      sheet.column(colwidth[i].col).width(colwidth[i].width)
    } 

   /*  sheet.column("A").width(2.5);
    sheet.column("B").width(13.7);
    sheet.column("C").width(13.7);
    sheet.column("D").width(13.7);
    sheet.column("E").width(8.5);
    sheet.column("F").width(9.5);
    sheet.column("G").width(16.5);
    sheet.column("H").width(20);
    sheet.column("I").width(13.7);
    sheet.column("J").width(13.7);
    sheet.column("K").width(15);
    sheet.column("L").width(18); */


    // sheet.cell("I3").value("As on: "+todayDate);        
    // sheet.cell("G2").value("LIST OF WELDERS USED IN PROJECT").style("underline");
    // sheet.cell("C4").value("1.One row displays data for one slip number for entered filter. Slip number is unique. ");        
  
    for(var i=0;i<Headings.length;i++){
      console.log("Excelllllll",Headings[i])
     sheet.cell(Headings[i].col).value(Headings[i].value)
     if(Headings[i].col === "A1")
     {
      sheet.cell(Headings[i].col).style({bold : true})
     }
     if(Headings[i].col === "D1")
     {
      sheet.cell(Headings[i].col).style({italic : true})
     }
     if(Headings[i].col === "D2")
     {
      sheet.cell(Headings[i].col).style({italic : true})
     }
   } 

    sheet.range(dataInfo1.titleWelderRange).style({            
      bold:true ,
    })

    sheet.range(dataInfo1.tbodyHeadRange).style({
      bold:true,
      horizontalAlignment:'center',
      border: "thin", 
      fontSize: 16,
    })
    sheet.range(dataInfo1.tbodyRange).style({           
      border: "thin",      
    })

    
    // // const img = workbook.addImage({
    // //   base64: imageBase64,
    // //   extension: 'png',
    // // });

    // sheet.cell('B1').value(img);
    // try {
    //   // Convert the base64 image to a Blob
    //   const byteCharacters = atob(imageBase64.split(",")[1]);
    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const imageBlob = new Blob([byteArray], { type: 'image/png' });
    
    //   // Read the Blob as an ArrayBuffer
    //   const reader = new FileReader();
    //   reader.readAsArrayBuffer(imageBlob);
    
    //   reader.onloadend = function() {
    //     // Add the image to the workbook
    //     workbook.addImage({
    //       base64: reader.result,
    //       extension: 'png'
    //     }).then(function(image) {
    //       // Insert the image into a cell
    //       sheet.cell('B1').value(image);
    //     });
    //   };
    // } catch (error) {
    //   console.error('Failed to decode base64 image:', error);
    // }
  
 
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
    
  });
  

}


// export async function ExportToExcel_Welder_Record(fileName, colwidth, data, titleCell, titleWelderRange, tbodyHeadRange, tbodyRange, blankRow, Headings, imageBase64) {
//   const wb = XLSX.utils.book_new();
//   const wsName = "List of Welders report";

//   // Convert data to worksheet
//   const wsData = [
//       [], [], [], [], // Empty rows
//       ...data
//   ];

//   const ws = XLSX.utils.aoa_to_sheet(wsData);

//   // Set column widths
//   colwidth.forEach(({ col, width }) => {
//       const wscols = [{ wpx: width }];
//       ws['!cols'] = wscols;
//   });

//   Headings.forEach(({ col, value }) => {
//     const cellRef = `${col}1`;
//     XLSX.utils.sheet_add_aoa(ws, [[value]], { origin: cellRef });

//     // Check if the cell exists and set styles accordingly
//     const cell = ws[cellRef];
//     if (cell && (col === "D1" || col === "D2")) {
//         const cellStyle = { italic: true };
//         XLSX.utils.format_cell(cell, cellStyle);
//     }
// });
//   // Add title cell
//   XLSX.utils.sheet_add_aoa(ws, [['Title']], { origin: titleCell });
//   // ws[XLSX.utils.decode_cell(titleCell)].s = { bold: true };
//   const titleRange = XLSX.utils.decode_range(titleWelderRange);
//   for (let R = titleRange.s.r; R <= titleRange.e.r; ++R) {
//       for (let C = titleRange.s.c; C <= titleRange.e.c; ++C) {
//           const cellAddress = { r: R, c: C };
//           const cellRef = XLSX.utils.encode_cell(cellAddress);
//           ws[cellRef].s = { font: { bold: true } };
//       }
//   }

//   // Add image
//   const imgData = imageBase64.split(',')[1]; // Remove 'data:image/png;base64,'
//   const imgBlob = b64toBlob(imgData, 'image/png');
//   const img = XLSX.utils.addImage(ws, imgBlob, { tl: { col: 1, row: 1 }, ext: 'png' }); // Adjust coordinates as needed

//   // Add worksheet to workbook
//   XLSX.utils.book_append_sheet(wb, ws, wsName);

//   // Save workbook
//   const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
//   saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), fileName);
// }

// // Helper function to convert string to array buffer
// function s2ab(s) {
//   const buf = new ArrayBuffer(s.length);
//   const view = new Uint8Array(buf);
//   for (let i = 0; i < s.length; i++) {
//       view[i] = s.charCodeAt(i) & 0xff;
//   }
//   return buf;
// }

// // Helper function to convert base64 to Blob
// function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];
//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       const slice = byteCharacters.slice(offset, offset + sliceSize);
//       const byteNumbers = new Array(slice.length);
//       for (let i = 0; i < slice.length; i++) {
//           byteNumbers[i] = slice.charCodeAt(i);
//       }
//       const byteArray = new Uint8Array(byteNumbers);
//       byteArrays.push(byteArray);
//   }
//   return new Blob(byteArrays, { type: contentType });
// }




const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);
  //create a 8 bit integer array
  const view = new Uint8Array(buf);
  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};