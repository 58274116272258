const WCSFormStatus = (props) => {
  console.log("props_props", props);
  let status_color = "";
  let bk_color = "";
  let status = props.formStatus;
  console.log("props.status",props.formStatus)
  switch (status) {
    case "Temporary Saved":
      status_color = "var(--godrej-orange)";
      bk_color = "var(--godrej-orange-bg)";
      break;
    case "Pending For Approval":
      status_color = "var(--godrej-blue)";
      bk_color = "var(--godrej-blue-bg)";
      break;
    case "Approved":
      status_color = "var(--godrej-green-alt)";
      bk_color = "var(--godrej-green-bg)";
      break;
    case "Rejected":
      status_color = "var(--godrej-red)";
      bk_color = "var(--godrej-red-bg)";
      break;
  }

  return (
    <>
      <div style={{ display: "flex" ,marginTop: "6px"}}>
        <div style={{ display: "flex",fontSize:"12px" }}>
          <b>Status {"    "}</b>
        </div>
        <div
          style={{
            backgroundColor: bk_color,
            borderRadius: "4px",
            height: "20px",
            left: "65px",
            marginLeft: "15px"
          }}
          className="d-flex align-items-center"
        >
          &nbsp; &nbsp;
          <span
            style={{ color: status_color, marginLeft: "5px" ,fontSize:"12px"}}
            className="text-overflow"
          >
            &nbsp;
            {status}
            &nbsp;
          </span>
        </div>
      </div>
    </>
  );
};

export default WCSFormStatus;
