import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { Col, Form } from "react-bootstrap";
import { SearchSelect } from "../../utility/search-select";
import { isAlphanumericAllCharacters,isAlphanumericAllSpecialCharacters } from "../../../functions/validations";

export const WPS_Process_Grid_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
}) => {
  console.log("wps_detail_WPS_Process_Grid_Input", wps_detail);
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              // style={{position:"relative", top:"0px"}}
              style={{
                width: "275px",
           
              }}
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={item[name]}
              readOnly={readOnly}
              disabled={disabled}
              maxLength={maxLength}
              // value={item.WPSD_Process !== "" &&  item.WPSD_Type !== "" ? item.WPSD_WP_MinimumPreheatTemp : "NA"}
              // disabled={ location.state?.action === "Edit" && item.WPSD_WP_MinimumPreheatTemp === ""}
              // readOnly={item.WPSD_Process === "" &&  item.WPSD_Type === ""}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input_Tubesheet = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
}) => {
  console.log("wps_detail_WPS_Process_Grid_Input", wps_detail);
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              // style={{position:"relative", top:"0px"}}
              style={{
                width: "512px",
           
              }}
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={item[name]}
              readOnly={readOnly}
              disabled={disabled}
              maxLength={maxLength}
              // value={item.WPSD_Process !== "" &&  item.WPSD_Type !== "" ? item.WPSD_WP_MinimumPreheatTemp : "NA"}
              // disabled={ location.state?.action === "Edit" && item.WPSD_WP_MinimumPreheatTemp === ""}
              // readOnly={item.WPSD_Process === "" &&  item.WPSD_Type === ""}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};



export const WPS_Process_Grid_InputProcess = ({
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  onInput,
  disabled,
  maxLength,
}) => {
  console.log("wps_detail_WPS_Process_Grid_Input", wps_detail);
  return (
    <tr className={"border WpsInput-md"}>
      <th
        className={"text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {/* {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )} */}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={"border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              style={{width:"275px"}}
              type={type}
              name={name}
              value={"GTAW"}
              readOnly
              disabled={disabled}
              maxLength={maxLength}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_InputProcess_tubesheet = ({
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  onInput,
  disabled,
  maxLength,
}) => {
  console.log("wps_detail_WPS_Process_Grid_Input", wps_detail);
  return (
    <tr className={"border WpsInput-md"}>
      <th
        className={"text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {/* {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )} */}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={"border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              style={{width:"512px"}}
              type={type}
              name={name}
              value={"GTAW"}
              readOnly
              disabled={disabled}
              maxLength={maxLength}
              onInput={onInput}
            />
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_3Filermetal = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  handleChangeFillermetal,
  maxLength,
  obj,
  setfunction,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  objbatch,
  setfunctionb,
  fields,
}) => {


  
      
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        console.log("iiiieiie",item[fieldfiller])
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={item[fieldfiller]?.[0] === ""}

                // disabled={field  && conditions?.isFillerMetalEmpty1}
                name={name + "1"}
                value={item[name][0]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[0] === ""}
                onChange={(e) => {handleChangeFillermetal(id, 0, name, obj, setfunction, e, metal) 
                  // handleChange(id, 0, fields, objbatch, setfunctionb, e, metal)
                }}
                onInput={onInput}
                // onInput={(e) => {
                //   let error = isAlphanumericAllCharacters(e.target.value);
                //   setError((prevState) => ({
                //     ...prevState,
                //     [name]: error,
                //   }));
                // }}
              />

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={field  && conditions?.isFillerMetalEmpty2}

                name={name + "2"}
                value={item[name][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => {handleChangeFillermetal(id, 1, name, obj, setfunction, e, metal) 
                }}               
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "3"}
                value={item[name][2]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => {handleChangeFillermetal(id, 2, name, obj, setfunction, e, metal) 
                }}    
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_2_Amperes = ({
  status,
  status1,
  item,
  message,
  message1,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  name1,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  obj1,
  setfunction1,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  handleChangeempty
}) => {

  console.log("ooaoaoaoaoa", obj);

  // console.log("ooaoaoaoaoadddd", conditions);

  console.log("wps_detail_WPS_Process_Grid_Input_3", wps_detail);

  // const disablefield = () =>{
  //   {wps_detail.map((item, id, k) => {

  //     if(item.fillerMetal === ""){

  //     }

  //       })    }
  //     }
  const pro1 = (item, fieldfiller) => {
    console.log("fffffs", item,fieldfiller);

    return item[fieldfiller]?.[0] === ""
    
  };


  
      
  return (
    <tr className={status || status1  ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status || status1  ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status || status1 && (
            <Tooltip content={message || message1}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        console.log("iiiieiie",item[fieldfiller])


        if (!Array.isArray(item[fieldfiller])) {
          item[fieldfiller] = ["", "", ""];
        }
        if (!Array.isArray(item[name])) {
          item[name] = ["", "", ""];
        }

        if (!Array.isArray(item[name1])) {
          item[name1] = ["", "", ""];
        }


        const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
        const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
        const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;
      
      
        if (isDisabled1) item[name][0] = ""
        if (isDisabled2) item[name][1] = ""
        if (isDisabled3) item[name][2] = ""

        if (isDisabled1) item[name1][0] = ""
        if (isDisabled2) item[name1][1] = ""
        if (isDisabled3) item[name1][2] = ""

        return (
          <td
            className={message || message1 ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                disabled={isDisabled1}
                name={name + "1"}
                value={isDisabled1 ? "" : item[name][0]}
                onChange={(e) =>
                  handleChange(id, 0, name, obj, setfunction, e)              
              }
                onInput={onInput}

              />
              

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={field  && conditions?.isFillerMetalEmpty2}
                disabled={isDisabled2}
                name={name + "2"}
                value={isDisabled2 ? "" : item[name][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "3"}
                disabled={isDisabled3}

                value={isDisabled3 ? "" : item[name][2]}

                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
            <br></br>
            <span style={{position:"relative", left:"100px", bottom:"7px"}}>|</span>
            <span style={{position:"relative", right:"1px", bottom:"7px"}}>|</span>
            <span style={{position:"relative", right:"100px", bottom:"7px"}}>|</span>


            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status1 ? "error-input" : ""}
                disabled={isDisabled1}
                name={name1 + "1"}
                value={isDisabled1 ? "" : item[name1][0]}
                onChange={(e) =>
                  handleChange(id, 0, name1, obj1, setfunction1, e)              
              }
                onInput={onInput}

              />

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status1 ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={field  && conditions?.isFillerMetalEmpty2}
                disabled={isDisabled2}
                name={name1 + "2"}
                value={isDisabled2 ? "" : item[name1][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => handleChange(id, 1, name1, obj1, setfunction1, e)}
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status1 ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name1 + "3"}
                disabled={isDisabled3}

                value={isDisabled3 ? "" : item[name1][2]}

                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => handleChange(id, 2, name1, obj1, setfunction1, e)}
                onInput={onInput}
              />
            </div>
            
          </td>
          
        );
      })}
    </tr>
  );
};


export const WPS_Process_Grid_Input_3 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  handleChangeempty
}) => {

  console.log("ooaoaoaoaoa", obj);

  // console.log("ooaoaoaoaoadddd", conditions);

  console.log("wps_detail_WPS_Process_Grid_Input_3", wps_detail);

  // const disablefield = () =>{
  //   {wps_detail.map((item, id, k) => {

  //     if(item.fillerMetal === ""){

  //     }

  //       })    }
  //     }
  const pro1 = (item, fieldfiller) => {
    console.log("fffffs", item,fieldfiller);

    return item[fieldfiller]?.[0] === ""
    
  };


  
      
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        console.log("iiiieiie",item[fieldfiller])


        if (!Array.isArray(item[fieldfiller])) {
          item[fieldfiller] = ["", "", ""];
        }
        if (!Array.isArray(item[name])) {
          item[name] = ["", "", ""];
        }


        const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
        const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
        const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;
      
      
        if (isDisabled1) item[name][0] = ""
        if (isDisabled2) item[name][1] = ""
        if (isDisabled3) item[name][2] = ""

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                disabled={isDisabled1}
                name={name + "1"}
                value={isDisabled1 ? "" : item[name][0]}
                onChange={(e) =>
                  handleChange(id, 0, name, obj, setfunction, e)              
              }
                onInput={onInput}

              />

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={field  && conditions?.isFillerMetalEmpty2}
                disabled={isDisabled2}
                name={name + "2"}
                value={isDisabled2 ? "" : item[name][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "3"}
                disabled={isDisabled3}

                value={isDisabled3 ? "" : item[name][2]}

                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  array,
  handleChangedetailsarray,
  option,
  onChange,
  disabled,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <select
              style={{
                width: "275px",
                height: "30px",
                // borderColor: "#D8D8D8",
                borderRadius: "3px",
              }}
              className={status ? "error-input" : ""}
              readOnly={readOnly}
              disabled={disabled}
              name={name}
              value={item[name]}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
                //   setError((prevState) => ({
                //     ...prevState,
                //     WPSD_WP_TungstenSIze: {status: false, message: ""},
                //   }));
              }}
            >
              {array &&
                array.map((e, id) => {
                  return (
                    <option key={id} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}

              {/* <option value="1">1</option>
              <option value="1.6">1.6</option>
              <option value="2.4">2.4</option>
              <option value="3.2">3.2</option>
              <option value="2.4/3.2">2.4/3.2</option> */}
            </select>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  obj,
  setfunction,
  onInput,
  ColumnHeader1,
  name1,
}) => {
  console.log("maxLengthssss", maxLength);
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3" style={{width:"135px"}}>
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-end">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                maxLength={maxLength ? maxLength : 18}
                type={type}
                style={{width:"275px"}}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                value={item[name]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput}
              />

              <input
                maxLength={maxLength ? maxLength : 8}
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                style={{width:"275px"}}
                value={item[name1]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput1}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input_2_Tubesheet = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength,
  obj,
  setfunction,
  onInput,
  ColumnHeader1,
  name1,
}) => {
  console.log("maxLengthssss", maxLength);
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3" style={{width:"135px"}}>
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-end">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                maxLength={maxLength ? maxLength : 18}
                type={type}
                style={{width:"512px"}}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                value={item[name]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput}
              />

              <input
                maxLength={maxLength ? maxLength : 8}
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                style={{width:"512px"}}
                value={item[name1]}
                readOnly={readOnly}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput1}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Select_3 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  array,
  handleChange,
  option,
  onChange,
  setfunction,
  obj,
  fieldfiller,

}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {

if (!Array.isArray(item[fieldfiller])) {
  item[fieldfiller] = ["", "", ""];
}
if (!Array.isArray(item[name])) {
  item[name] = ["", "", ""];
}


const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;


if (isDisabled1) item[name][0] = ""
if (isDisabled2) item[name][1] = ""
if (isDisabled3) item[name][2] = ""

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div>
              <select
                style={{
                  width: "85px",
                  height: "32px",
                  // borderColor: "#D8D8D8",
                  borderRadius: "4px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={isDisabled1 ? "" : item[name][0]}
                disabled={isDisabled1}
                onChange={(e) => {
                  handleChange(id, 0, name, obj, setfunction, e);

                  //   setError((prevState) => ({
                  //     ...prevState,
                  //     WPSD_WP_LayerNumber: {status: false, message: ""},
                  //   }));
                }}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",
                  // borderColor: "#D8D8D8",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={isDisabled2 ? "" : item[name][1]}
                disabled={isDisabled2}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>

              <select
                style={{
                  width: "85px",
                  height: "32px",
                  // borderColor: "#D8D8D8",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
                className={status ? "error-input" : ""}
                name={name + "3"}
                // value = {item[name[2]]}
                value={isDisabled3 ? "" : item[name][2]}
                disabled={isDisabled3}
                onChange={(e) => handleChange(id, 2, name, obj, setfunction, e)}
              >
                {array &&
                  array.map((e, id) => {
                    return (
                      <option key={id} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const Single_Input = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? " T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const Single_Input_textara = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <textarea
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? " T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};


export const Single_Input_WPS = ({
  status,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
  width,
  setError,
  md,
  divstyle,
}) => {
  return (
    <>
     <Col md={md} style={style}>
<Form.Group style={{width}}>
  <div class={divstyle} >
    <input
      type="text"
      id={name}
      name={name}
      placeholder=" "
      disabled={disabled}
      maxLength={maxLength}
      className={status ? "err-input" : ""}
      value={value}
      // onChange={(e) => {
      //   handleChange(e);

      //   for (var i in detailObj) {
      //     detailObj[i][name] = e.target.value;
      //   }
      // }}
      onChange={onChange}
      // onInput={onInput}
      onInput={(e) => {
        let error = isAlphanumericAllSpecialCharacters(e.target.value);
        setError((prevState) => ({
          ...prevState,
          [name]: error,
        }));
      }}
    />
    <label for={name}>{label}</label>
  </div>
  <div className="required-text">
    {status && (
      <Form.Text className="text-danger">
        {message}
      </Form.Text>
    )}
  </div>
</Form.Group>
</Col>
    </>
  );
};


export const Single_Input_textaraNotes = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
  onKeyDown,
  className
}) => {
  return (
    <>
      <div class="T-input w-100">
        <textarea
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? " T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
         
        />
        <label for={name}>{label}</label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select_green = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
}) => {
  return (
    <>
      <div class="green-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {/* <option value=" " selected>
                    select
                  </option>
                  <option value="With">With</option>
                  <option value="With/Without">With/Without</option>
                  <option value="NA">NA</option>
                </select> */}
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
  disabled,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          disabled={disabled}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {/* <option value=" " selected>
                    select
                  </option>
                  <option value="With">With</option>
                  <option value="With/Without">With/Without</option>
                  <option value="NA">NA</option>
                </select> */}
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};



export const WPS_Process_Grid_Select_master_data_TTS = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  data,
  type,
  name,
  setError,
  error,
  valueField,
  labelField,
  errorstyle,
  bluestyle,
  onInput,
  errorr,
  value,
  readOnly,
  array,
  handleChangedetail,
  option,
  onChange,
  setdetailObj,
  detailObj,
  name1,
}) => {
  const handleChange = (id, index, name, obj, setfunction, e) => {
    let selectedItem = data.find(b => b[valueField] === e.target.value);

    let updatedItem = {
      ...obj[id],
      [name]: [
        ...(obj[id][name] || []),
      ],
      welddesc: [
        ...(obj[id].welddesc || []),
      ],
      weldweight: [
        ...(obj[id].weldweight || []),
      ],
    };

    updatedItem[name][index] = e.target.value;

    // Handle the special cases for "Select" and "NA"
    if (e.target.value === "") {
      updatedItem.welddesc[index] = "";
      updatedItem.weldweight[index] = "";
    } else if (e.target.value === "NA") {
      updatedItem.welddesc[index] = "NA";
      updatedItem.weldweight[index] = "NA";
    } else {
      updatedItem.welddesc[index] = selectedItem?.wld_description || "";
      updatedItem.weldweight[index] = selectedItem?.wld_weight || "";
    }

    // Store the updated item in state
    setfunction(prevDetailObj => {
      const updatedDetailObj = [...prevDetailObj];
      updatedDetailObj[id] = updatedItem;
      return updatedDetailObj;
    });

    setError((prevState) => ({
      ...prevState,
      [name]: { status: false, message: "" },
    }));

   
  };

  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th className={status ? "text-start thead-color" : "text-start thead-color"}>
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        console.log("iiiiieeeee",item, id)
        return (
          <td
            className={message ? "border WPSSelect error " : "border WPSSelect "}
            key={id}
          >
            <div>
              {[0, 1].map((index) => (
                <select
                  key={index}
                  style={{
                    width: "250px",
                    height: "32px",
                    borderRadius: "4px",
                    marginRight: "10px",
                    marginLeft: index === 0 ? "10px" : "0",
                  }}
                  className={status ? "error-input" : ""}
                  name={`${name}${index + 1}`}
                  value={item[name] && item[name][index]}
                  onChange={(e) => handleChange(id, index, name, detailObj, setdetailObj, e)}
                >
                  {data &&
                    data.map((e, idx) => (
                      <option key={idx} value={e[valueField]}>
                        {e[valueField] === "" ? "Select" : e[labelField]}
                      </option>
                    ))}
                </select>
              ))}
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Select_master_data = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  data,
  type,
  name,
  setError,
  error,
  valueField,
  labelField,
  errorstyle,
  bluestyle,
  onInput,
  errorr,
  value,
  readOnly,
  array,
  handleChangedetail,
  option,
  onChange,
  setdetailObj,
  detailObj,
  name1,
  fieldfiller

}) => {
  const handleChange = (id, index, name, obj, setfunction, e) => {
    let selectedItem = data.find(b => b[valueField] === e.target.value);

    let updatedItem = {
      ...obj[id],
      [name]: [
        ...(obj[id][name] || []),
      ],
      welddesc: [
        ...(obj[id].welddesc || []),
      ],
      weldweight: [
        ...(obj[id].weldweight || []),
      ],
    };

    updatedItem[name][index] = e.target.value;

    // Handle the special cases for "Select" and "NA"
    if (e.target.value === "") {
      updatedItem.welddesc[index] = "";
      updatedItem.weldweight[index] = "";
    } else if (e.target.value === "NA") {
      updatedItem.welddesc[index] = "NA";
      updatedItem.weldweight[index] = "NA";
    } else {
      updatedItem.welddesc[index] = selectedItem?.wld_description || "";
      updatedItem.weldweight[index] = selectedItem?.wld_weight || "";
    }

    // Store the updated item in state
    setfunction(prevDetailObj => {
      const updatedDetailObj = [...prevDetailObj];
      updatedDetailObj[id] = updatedItem;
      return updatedDetailObj;
    });

    setError(prevState => ({
      ...prevState,
      [name]: { status: false, message: "" },
    }));
  };

  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th className={status ? "text-start thead-color" : "text-start thead-color"}>
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {


if (!Array.isArray(item[fieldfiller])) {
  item[fieldfiller] = ["", "", ""];
}
if (!Array.isArray(item[name])) {
  item[name] = ["", "", ""];
}


const isDisabled1 = item[fieldfiller]?.[0] === "" || item[fieldfiller]?.[0] === undefined;
const isDisabled2 = item[fieldfiller]?.[1] === "" || item[fieldfiller]?.[1] === undefined;
const isDisabled3 = item[fieldfiller]?.[2] === "" || item[fieldfiller]?.[2] === undefined;


if (isDisabled1) item[name][0] = ""
if (isDisabled2) item[name][1] = ""
if (isDisabled3) item[name][2] = ""

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div>
              {[0, 1, 2].map((index) => (
                <select
                  key={index}
                  style={{
                    width: "85px",
                    height: "32px",
                    borderRadius: "4px",
                    marginRight: "10px",
                    marginLeft: index === 0 ? "10px" : "0",
                  }}
                  className={status ? "error-input" : ""}
                  name={`${name}${index + 1}`}
                  value={item[name] && item[name][index]}
                  onChange={(e) => handleChange(id, index, name, detailObj, setdetailObj, e)}
                  disabled={item[fieldfiller]?.[index] === "" || item[fieldfiller]?.[index] === undefined}

                >
                  {data &&
                    data.map((e, idx) => (
                      <option key={idx} value={e[valueField]}>
                        {e[valueField] === "" ? "Select" : e[labelField]}
                      </option>
                    ))}
                </select>
              ))}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const WPS_Process_Grid_Input_Horizon_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};


//   status,
//   item,
//   message,
//   Error,
//   wps_detail,
//   ColumnHeader,
//   Columndimension,
//   type,
//   name,
//   setError,
//   error,
//   value,
//   readOnly,
//   handleChange,
//   maxLength,
//   obj,
//   setfunction,
//   onInput,
// }) => {

//   return (
//     <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
//       <th
//         className={status ? "text-start thead-color" : "text-start thead-color"}
//       >
//         <div>
//           {ColumnHeader}
//           <span>{Columndimension}</span>
//           {status && (
//             <Tooltip content={message}>
//               <img src={Img} alt="Error" className="ms-1" />
//             </Tooltip>
//           )}
//         </div>
//       </th>
//       {wps_detail.map((item, id, k) => {
//         return (
//           <td
//             className={status ? "border WPSSelect error" : "border WPSSelect"}
//             key={id}
//           >
//             <div className="dInput ">
//               <div
//                 className="dInput"
//                 style={{
//                   height: "200%",
//                   width: 2,
//                   backgroundColor: "#909090",
//                   // position: "relative",
//                   // left: "5px",
//                 }}
//               >
//                 <input
//                   type={type}
//                   maxLength={maxLength}
//                   readOnly={readOnly}
//                   style={{ marginRight: "10px", marginLeft: "10px" }}
//                   className={status ? "error-input" : ""}
//                   name={name + "1"}
//                   value={item[name][0]}
//                   onChange={(e) =>
//                     handleChange(id, 0, name, obj, setfunction, e)
//                   }
//                   onInput={onInput}
//                 />

//                 <input
//                   type={type}
//                   maxLength={maxLength}
//                   readOnly={readOnly}
//                   style={{ marginRight: "10px" }}
//                   className={status ? "error-input" : ""}
//                   name={name + "2"}
//                   value={item[name][1]}
//                   onChange={(e) =>
//                     handleChange(id, 1, name, obj, setfunction, e)
//                   }
//                   onInput={onInput}
//                 />
//               </div>
//             </div>
//           </td>
//         );
//       })}
//     </tr>
//   );
// };

export const WPS_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px", width:"131px"}}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", width:"131px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Grid_Input_2_Tubesheet = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  maxLength,
  obj,
  setfunction,
  onInput,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        return (
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px", width:"250px"}}
                className={status ? "error-input" : ""}
                name={name + "1"}
                value={item[name][0]}
                onChange={(e) => handleChange(id, 0, name, obj, setfunction, e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", width:"250px" }}
                className={status ? "error-input" : ""}
                name={name + "2"}
                value={item[name][1]}
                onChange={(e) => handleChange(id, 1, name, obj, setfunction, e)}
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
