import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
// import calenderIcon from "../../icons/calendericon.svg";
import calenderIcon from "../../../icons/calendericon.svg"

export const Date_Input = ({
    label,
    className,
    name,
    sx,
    value,
    todayDate,
    setobj,
    obj,
    datevalue,
    setdatevalue,
    divClass,
    status,
    message,
    maxDate,
    disabled,
    readOnly,
    setError,
    handleDateChange,
    onChange,
    mindate,
    fromdate,
    todate,
  }) => {
    console.log("foaooao",obj)
    console.log('setwiobj11:', setobj);   
    console.log('datevalue:', datevalue);    
    
    
    function MuiIcon() {
      return <Image src={calenderIcon}/>;
    }
  
  //   const creationDate = () => {
      
  //     if(name === "WI_To_Date"){
  //     // datevalue === false || obj[name] === "" ? 
  //     // null :
  //     obj && obj[name]
  //       ? dayjs(obj[name])
  //       : dayjs(todayDate)
  //   }else{
  //      datevalue === false || obj[name] === "" ? 
  //     null :
  //     obj && obj[name]
  //       ? dayjs(obj[name])
  //       : dayjs(todayDate)

  //   }
  // }

    return (
      <>
  <div className="groove_date">
  <div class={divClass}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
         slots={{ openPickerIcon: MuiIcon }}
          label={label}
          className={className}
          format="DD-MM-YYYY"
          name={name}
          sx={sx}
          disabled={disabled}
          readOnly={readOnly}
          value={
            // name === "WI_To_Date" ?
            // // datevalue === false || obj[name] === "" ? 
            // // null :
            // obj && obj[name]
            //   ? dayjs(obj[name])
            //   : dayjs(todayDate)
            // :
                datevalue === false || obj[name] === "" ? 
            null :
            obj && obj[name]
            ? dayjs(obj[name])
            : dayjs(todayDate)
     

          }
          // value={creationDate}
          maxDate={dayjs(maxDate) === dayjs(maxDate) ? dayjs(maxDate) : ""}
          onChange={(e) => {
            
            console.log("naaaaame",name)
            let date = dayjs(e).format("YYYY-MM-DD");

            setobj((prev) => ({
              ...prev,
              [name]: date,
            }));


            if(name === "WI_From_Date"){
              if (todate){
                if( date > todate){
               console.log("dateeeemin",date > todate,obj[name],date,todate)
               setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: true,
                  // message: "Selected date cannot be greater than To date",
                },
              }));
              toast.warning("Selected date cannot be greater than To date", {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              //  setdatevalue(false);
               console.log("vall",datevalue)

               setobj((prev) => ({
                 ...prev,
                 [name]: date,
               }));
            }
          }
            else{


              setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: false,
                  message: "",
                },
              }));
            }
            }

            if(name === "WI_To_Date"){
              if (date < fromdate){
               console.log("dateeeemin",date < fromdate,obj[name],date)
               setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: true,
                  // message: "Selected date cannot be less than From date",
                },
              }));
              toast.warning("Selected date cannot be less than From date", {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
               setdatevalue(false);
               console.log("vall",datevalue)
            }
            else{
              setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: false,
                  message: "",
                },
              }));
              


            }
            }

    
            if(name !== "WI_To_Date" && name !== "WI_From_Date" ){
            setobj((prev) => ({
              ...prev,
              [name]: date,
            }));

            setError((prevState) => ({
              ...prevState,
              [name]: {
                status: false,
                message: "",
              },
            }));
          }
          
          }}
        //     setobj((prev) => ({
        //       ...prev,
        //       [name]: date,
        //     }));

        //     setError((prevState) => ({
        //       ...prevState,
        //       [name]: {
        //         status: false,
        //         message: "",
        //       },
        //     }));
        //   }
        
        // }
          
          
            
        />
      </DemoContainer>
    </LocalizationProvider>
  </div>
</div>
<div className="required-text-select mt-2">
                {status && (
                  <Form.Text className="text-danger">
                    {message}
                  </Form.Text>
                )}
              </div>


</>
    )}

    export const Date_InputUser = ({
      label,
      className,
      name,
      sx,
      value,
      todayDate,
      setobj,
      obj,
      datevalue,
      setdatevalue,
      divClass,
      status,
      message,
      maxDate,
      disabled,
      readOnly,
      setError,
      handleDateChange,
      onChange,
      mindate,
      fromdate,
      todate,
    }) => {
      console.log("foaooao",obj)
      console.log('setwiobj11:', setobj);   
      console.log('datevalue:', datevalue);    
      
      
      function MuiIcon() {
        return <Image src={calenderIcon}/>;
      }
    
    //   const creationDate = () => {
        
    //     if(name === "WI_To_Date"){
    //     // datevalue === false || obj[name] === "" ? 
    //     // null :
    //     obj && obj[name]
    //       ? dayjs(obj[name])
    //       : dayjs(todayDate)
    //   }else{
    //      datevalue === false || obj[name] === "" ? 
    //     null :
    //     obj && obj[name]
    //       ? dayjs(obj[name])
    //       : dayjs(todayDate)
  
    //   }
    // }
  
      return (
        <>
    <div className="groove_date">
    <div class={divClass}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
           slots={{ openPickerIcon: MuiIcon }}
            label={label}
            className={className}
            format="DD-MM-YYYY"
            name={name}
            sx={sx}
            disabled={disabled}
            readOnly={readOnly}
            value={
              name === "WI_To_Date" ?
              // datevalue === false || obj[name] === "" ? 
              // null :
              obj && obj[name]
                ? dayjs(obj[name])
                : dayjs(todayDate)
              :
                  datevalue === false || obj[name] === "" ? 
              null :
              obj && obj[name]
              ? dayjs(obj[name])
              : dayjs(todayDate)
  
            }
            // value={creationDate}
            // maxDate={dayjs(maxDate)}
            onChange={(e) => {
              
              console.log("naaaaame",name)
              let date = dayjs(e).format("YYYY-MM-DD");
  
              setobj((prev) => ({
                ...prev,
                [name]: date,
              }));
  
  
              if(name === "WI_From_Date"){
                if (todate){
                  if( date > todate){
                 console.log("dateeeemin",date > todate,obj[name],date,todate)
                 setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: true,
                    // message: "Selected date cannot be greater than To date",
                  },
                }));
                toast.warning("Selected date cannot be greater than To date", {
                  position: toast.POSITION.TOP_LEFT,
                  className: "toast-message",
                });
                //  setdatevalue(false);
                 console.log("vall",datevalue)
  
                 setobj((prev) => ({
                   ...prev,
                   [name]: date,
                 }));
              }
            }
              else{
  
  
                setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: false,
                    message: "",
                  },
                }));
              }
              }
  
              if(name === "WI_To_Date"){
                if (date < fromdate){
                 console.log("dateeeemin",date < fromdate,obj[name],date)
                 setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: true,
                    // message: "Selected date cannot be less than From date",
                  },
                }));
                toast.warning("Selected date cannot be less than From date", {
                  position: toast.POSITION.TOP_LEFT,
                  className: "toast-message",
                });
                 setdatevalue(false);
                 console.log("vall",datevalue)
              }
              else{
                setError((prevState) => ({
                  ...prevState,
                  [name]: {
                    status: false,
                    message: "",
                  },
                }));
                
  
  
              }
              }
  
      
              if(name !== "WI_To_Date" && name !== "WI_From_Date" ){
              setobj((prev) => ({
                ...prev,
                [name]: date,
              }));
  
              setError((prevState) => ({
                ...prevState,
                [name]: {
                  status: false,
                  message: "",
                },
              }));
            }
            
            }}
          //     setobj((prev) => ({
          //       ...prev,
          //       [name]: date,
          //     }));
  
          //     setError((prevState) => ({
          //       ...prevState,
          //       [name]: {
          //         status: false,
          //         message: "",
          //       },
          //     }));
          //   }
          
          // }
            
            
              
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  </div>
  <div className="required-text-select mt-2">
                  {status && (
                    <Form.Text className="text-danger">
                      {message}
                    </Form.Text>
                  )}
                </div>
  
  
  </>
      )}