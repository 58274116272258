import axios from "axios";

let INTERFACE_URL;
let MASTER_API_URL;
if (process.env.REACT_APP_NODE_ENV === "local") {
  INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "CRData/";
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  INTERFACE_URL = process.env.REACT_APP_API_URL + "interface/CRData/";
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}
export async function getAllProject() {
  console.log("getAllProject", MASTER_API_URL + "/Project/get");
  const response = await axios
    .get(MASTER_API_URL + "/Project/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const AddNewProject = async (Project) => {
  
  let url = `${MASTER_API_URL}/Project`;
  const response = await axios
    .post(url, Project)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateProject = async (Project) => {
  let url = `${MASTER_API_URL}/Project/update`;
  const response = await axios
    .post(url, Project)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteProject(skid) {
  let url = `${MASTER_API_URL}/Project/delete`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  return resp;
}

export async function getAllLNProjects() {
  const response = await axios
    .get(INTERFACE_URL + "project")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getLNProjectdata() {
  const response = await axios
    .get(MASTER_API_URL + "/Project/ln")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getAllOnlyProject() {
  console.log("getAllProject", MASTER_API_URL + "/Project/getProject");
  const response = await axios
    .get(MASTER_API_URL + "/Project/getProject")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getAllOnlyProject_response.data", response.data);
  return response.data;
}
