import { Row, Col, Table, Form } from "react-bootstrap";
import axios from "axios";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  DisableFields,
  DisableFieldsWPQ,
} from "../WPSTransaction/WPSObjects/WPS_common";
import defaultimg from "../../icons/noimage.png";
import {
  MessageTypes,
  WPSH_PWHeatTre_Type,
  Action,
  Status,
  Tubesheet_WPSPage1,
  Tubesheet_WPSPage2,
  WPS_Tubesheet_Detail,
  WeldingProcess,
} from "../WPSTransaction/WPSObjects/WPSGroove_entity";
import { WPSHeader } from "../WPSTransaction/WPSObjects/WPSHeader";
import {
  WPS_Process_Grid_Input,
  WPS_Process_Grid_Input_2,
  General_Single_Input_select,
  Single_Input,
  WPS_Process_Grid_Select,
  WPS_Grid_Input_2,
} from "../WPQTransaction/WPSObjects/WPQ_Grid_Tubesheet";
import {
  isAlphanumericWithSpaceDotDash,
  isAlphanumericWithSpaceDotDashComma,
  isAlphanumericWithDotSpaceDashCommaBracketsSlash,
  isAlphanumericWithDashDot,
  isAlphanumericWithSpaceDotDashAmpersand,
  isNumber,
  isAlphanumericAllSpecialCharacters
} from "../../functions/validations";
// import { AddLogs } from "../../Services/wps-logs-services";
import { IsUserMasterBand } from "../../Services/user-service";
import { WPQHeader } from "./WPSObjects/WPQHeader";
import { AddNewDetailTubesheet } from "../../Services/wpq_details-service";
import { CommonTable5 } from "../utility/CommonComponents/Table5";
import {
  WPQ_Page3_Table1,
  WPQ_2Rows_Table,
  WPQ_Page3_Table2,
  WPQ_Page3_Table3,
  WPQ_Page3_Table3observation,
} from "../utility/CommonComponents/wpq_Page3_table_components";
import {
  check_Wpq_Pag3_LPEX,
  check_Wpq_Pag3_MEX,
  check_Wpq_Pag3_VEX,
  check_Wpq_Page4_MLPT5,
} from "./WPSObjects/WPS_Validations";
import { WPQ_tubePage3, errorTubepage3 } from "./WPSObjects/WPQ_entity";
import { AddNewGrooveWPQ } from "../../Services/wpq_groove-services";
import { UpdateWPQDetailComplete } from "../PQRTransaction/PQRObjects/PQR_Validations";
import { emptyError, emptyErrorWPQDetail } from "./WPSObjects/WPS_common";

const WPQ_Tubesheet_Page3 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      setIsEditedPage2,
    },
    ref
  ) => {
    const location = useLocation();
    const avatar = { defaultimg };

    let userprofile = sessionStorage.getItem("UserProfile");
    let Band = sessionStorage.getItem("Band");
    let username = sessionStorage.getItem("UserName");

    let initiator = Band !== "P" && Band !== "T";
    console.log("initiator", initiator);

    let finalApprover = Band === "P" || Band === "T";
    console.log("finalApprover", finalApprover);

    var disableButton =
      initiator && headerObj?.WPSH_Status === Status.StatusPending
        ? true
        : false;
    const [isDisabled, setIsDisabled] = useState(disableButton);

    var readonly =
      location.state?.action === Action.ActionCopy ||
      (initiator && headerObj?.WPSH_Status === Status.StatusPending)
        ? true
        : false;
    const [isReadonly, setIsReadonly] = useState(readonly);

    const [IsEdited, setIsEdited] = useState(false);
    const [dis, setdis] = useState(false);

    const [action, setaction] = useState(location.state?.action);
    const [Readonly, setReadonly] = useState("");

    let Visual_Examination = detailObj?.Visual_Examination;
    let Macro_Examination5 = detailObj?.Macro_Examination5;

    console.log("ldldld",Macro_Examination5)
    let Liquid_Penetrant_Examination = detailObj?.Liquid_Penetrant_Examination;

    let object = detailObj?.Macro_Examination;

    let array = { ...object };
    let element = array[0];
    let data = { ...element };
    let Table1 = data.Table1;
    let Table2 = data.Table2;
  

    // let aaa = abc[0]
    console.log("abcffff", Table1);

    let Macro_Examination1 = Table1;
    let Macro_Examination2 = Table2;
  

    const [profile, setProfile] = useState("");
    const [today, setToday] = useState("");

    const [degLayerObj, setdegLayerObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [fillerMetalObj, setFillerMetalObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [itemsObj, setItemsObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [batchObj, setBatchObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [amperesObj, setAmperesObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentPulseObj, setCurrentPulseObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentOthersObj, setcurrentOthersObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [voltsObj, setVoltsObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [travelSObj, setTravelSObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [heatObj, setHeatObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentPObj, setcurrentPObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );



    const [error, setError] = useState({
      WPQ_VEX_WeldPass: {},
      WPQ_VEX_Acceptance_Criteria: {},
      WPQ_VEX_Observation: {},
      WPQ_VEX_Results: {},

      WPQ_LPEX_WeldPass: {},
      WPQ_LPEX_Acceptance_Criteria: {},
      WPQ_LPEX_Observation: {},
      WPQ_LPEX_Results: {},

      WPQ_MEX_Tube_No: {},
      WPQ_MEX_LocA: {},
      WPQ_MEX_LocB: {},
      WPQ_MEX_LocC: {},
      WPQ_MEX_LocD: {},

      WPQ_MEX_Tube_No1: {},
      WPQ_MEX_LocA1: {},
      WPQ_MEX_LocB1: {},
      WPQ_MEX_LocC1: {},
      WPQ_MEX_LocD1: {},

      WPQ_MEX_Tube_No2: {},
      WPQ_MEX_LocA2: {},
      WPQ_MEX_LocB2: {},
      WPQ_MEX_LocC2: {},
      WPQ_MEX_LocD2: {},

      WPQ_MEX_Tube_No3: {},
      WPQ_MEX_LocA3: {},
      WPQ_MEX_LocB3: {},
      WPQ_MEX_LocC3: {},
      WPQ_MEX_LocD3: {},

      WPQ_VEX_Report: {},
      WPQ_LPEX_Report: {},
      WPQ_LPEX_Notes: {},
      WPQ_VEX_Notes: {},
      WPQ_MEX_Result: {},

      WPQ_MEX_Magnification: {},
      WPQ_MEX_Acceptance_Criteria: {},
      WPQ_MEX_Observation: {},
      
    });

    console.log("reeeeee",error)

   
    const validate = async () => {
      let tubePage1 = Object.entries(WPQ_tubePage3);
      let errorpage3 = Object.entries(errorTubepage3);


      let isError = false;

      tubePage1.map(([key, val]) => {
        if (detailObj?.[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      errorpage3.map(([key, val]) => {
        if (error?.[key] === "" || error?.[key].status === true) {
          console.log("errorroro_tubePage4", error[key], key);
          isError = true;
          error[key] = {
            status: true,
            message: `Special Characters # & % + -  ' < > = ; not allowed`,
          };
        }
      });

      var norows = check_Wpq_Pag3_VEX(Visual_Examination);
      

      if (norows) {
        Visual_Examination[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Visual_Examination[0].error = true;

        if (
          Visual_Examination[0].WPQ_VEX_WeldPass !== "" &&
          Visual_Examination[0].WPQ_VEX_Acceptance_Criteria !== "" &&
          Visual_Examination[0].WPQ_VEX_Observation !== "" &&
          Visual_Examination[0].WPQ_VEX_Results !== ""
        ) {
          Visual_Examination[0].error = false;
        }
      }

      var norows = check_Wpq_Pag3_LPEX(Liquid_Penetrant_Examination);
      

      if (norows) {
        Liquid_Penetrant_Examination[0].errormessage =
          "Atleast one Full Row is required";
        isError = true;
        Liquid_Penetrant_Examination[0].error = true;

        if (
          Liquid_Penetrant_Examination[0].WPQ_LPEX_WeldPass !== "" &&
          Liquid_Penetrant_Examination[0].WPQ_LPEX_Acceptance_Criteria !== "" &&
          Liquid_Penetrant_Examination[0].WPQ_LPEX_Observation !== "" &&
          Liquid_Penetrant_Examination[0].WPQ_LPEX_Results !== ""
        ) {
          Liquid_Penetrant_Examination[0].error = false;
        }
      }

      var norows = check_Wpq_Pag3_MEX(Macro_Examination1);
      

      if (norows) {
        Macro_Examination1[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Macro_Examination1[0].error = true;

        if (
          Macro_Examination1[0]?.WPQ_MEX_Tube_No !== "" &&
          Macro_Examination1[0]?.WPQ_MEX_LocA !== "" &&
          Macro_Examination1[0]?.WPQ_MEX_LocB !== "" &&
          Macro_Examination1[0]?.WPQ_MEX_LocC !== "" &&
          Macro_Examination1[0]?.WPQ_MEX_LocD !== ""
        ) {
          Macro_Examination1[0].error = false;
        }
      }

      // //////

      var norows = check_Wpq_Page4_MLPT5(Macro_Examination5);

      if (norows) {
        Macro_Examination5[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Macro_Examination5[0].error = true;

        if (
          Macro_Examination5[0].WPQ_MEX_Magnification !== "" &&
          Macro_Examination5[0].WPQ_MEX_Acceptance_Criteria !== "" 
          // Macro_Examination5[0].WPQ_MEX_Observation !== ""
        ) {
          Macro_Examination5[0].error = false;
        }
      }

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      console.log("Validate1", isError);
      return isError;
    };

    let VEX = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_VEX_Report: detailObj.WPQ_VEX_Report,
      WPQ_VEX_Notes: detailObj.WPQ_VEX_Notes,
      Visual_Examination: detailObj.Visual_Examination,
 
    };

    let LiqEx = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_LPEX_Report: detailObj.WPQ_LPEX_Report,
      WPQ_LPEX_Notes: detailObj.WPQ_LPEX_Notes,
      Liquid_Penetrant_Examination: detailObj.Liquid_Penetrant_Examination,

    };

let Macro_ExaminationArray= [ 
  {
  Table1: Macro_Examination1,
  Table2: Macro_Examination2,
  // Table3: Macro_Examination3,
  // Table4: Macro_Examination4,
  // Table5: Macro_Examination5,
  }

];


    
    let MacroEx = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      WPQ_MEX_Result: detailObj.WPQ_MEX_Result,
      Macro_Examination: Macro_ExaminationArray,
    };

    let MacroEx5 = {
      wld_createdon: detailObj.wld_createdon || "",
      WPQ_WelderId: detailObj.WPQ_WelderId,
      WPQ_RecordNo: detailObj.WPQ_RecordNo,
      WPQ_Process: detailObj.WPQ_Process,
      WPSD_FM_SFA: detailObj.WPSD_FM_SFA,
      Macro_Examination5: detailObj.Macro_Examination5,
      WPQ_MEX_Observation:detailObj.WPQ_MEX_Observation,

    };

    const postList = [VEX,LiqEx,MacroEx,MacroEx5];


    const handleAdd = async (approvalstatus) => {
      
      let result = false;
      console.log("handleAdd_headerObj?.WPSH_Status", headerObj?.WPSH_Status);
      console.log(
        "handleAdd_headerObj?.WPSH_Approval_Status",
        headerObj?.WPSH_Approval_Status
      );
      console.log("handleAdd_approvalstatus", approvalstatus);

      if (
        headerObj?.WPSH_Approval_Status !== "" &&
        headerObj?.WPSH_Approval_Status !== undefined
      ) {
        headerObj.WPSH_Status = headerObj?.WPSH_Approval_Status;
      } else {
        headerObj.WPSH_Status = approvalstatus;
      }

      console.log("now_in", headerObj);

      console.log("AddNewGroove_headerObj", headerObj);
      console.log("detialllllllobj", detailObj);


      let res = await AddNewGrooveWPQ(headerObj)
      // let res = await UpdateWPQDetailComplete(postList)
        .catch((e) => {
          result = false;
        })
        .then(async () => {
          result = true;

          
          // await AddNewDetailTubesheet(detailObj)
          await UpdateWPQDetailComplete(postList)
            .catch((err) => {
              console.log("error" + err);
            })
            .then((r) => {
              console.log("first  done detail", r);
              setaction(Action.ActionEdit);
              result = true;
            });
          if (
            action === Action.ActionEdit &&
            approvalstatus !== Status.StatusTempSaved
          ) {
            // await AddLogs(logObj);
            result = true;

          }
        });
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };

    useEffect(() => {
      console.log("IsEdited", IsEdited);
    }, [IsEdited]);

    // const handleSubmitTemp = async () => {
      
    //   var res = false;
    //   console.log("handleSubmitTemp_IsEdited", IsEdited);
    //   if (action === Action.ActionEdit) {
    //     if (IsEdited) {
    //       setIsEdited(false);
    //       // setIsEditedPage2(false);
    //       console.log("2nd page Submit no ");

    //       res = await handleAdd(Status.StatusTempSaved);

    //       if (res) {
    //         console.log("2nd Page Tab", tabno);
    //         return MessageTypes.Temp;
    //       } else {
    //         return MessageTypes.Fail;
    //       }
    //     }

    //     return MessageTypes.None;
    //   } else {
    //     res = handleAdd(Status.StatusTempSaved);

    //     if (res) {
    //       return MessageTypes.Temp;
    //     } else {
    //       return MessageTypes.Required;
    //     }
    //   }
    // };

    const validateTemp = async () => {
      debugger
      let isError = false;
console.log("erreee",error)
       let temperror = emptyError(error, setError, isError);

       console.log("teeeee",temperror)

      console.log("issss",isError)
      return {isError ,temperror};
    };

    const handleSubmitTemp = async () => {
      debugger
      var result = false;
      var val = await validateTemp();
      console.log("valdd",val)

        if (IsEdited) {
          if (val.isError === false){
          if ( val.temperror === false) {
            setIsEdited(false);
            if (headerObj?.WPSH_Status === Status.StatusApproved) {
              result = handleAdd(Status.StatusApproved);
            } else if (headerObj?.WPSH_Status === Status.StatusPending) {
              result = handleAdd(Status.StatusPending);
            } else {
              result = handleAdd(Status.StatusTempSaved);
            }

            if (result) {
              return MessageTypes.Temp;
            }
     
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;

        }
        return MessageTypes.None;
      
  }

    const handleSubmit = async () => {
      
      setIsEdited(false);
      if ((await validate())) {
        setIsEdited(true);
      }
      // setIsEditedPage2(false);
      console.log("headerObj_handleSubmit", headerObj);
      console.log("headerObj_handleSubmit_WPSH_Status", headerObj?.WPSH_Status);
      console.log(
        "headerObj_handleSubmit_WPSH_Approval_Status",
        headerObj?.WPSH_Approval_Status
      );
      var result = false;
      const isUsernamePresent = await checkIfUsernameExists();
      

      if (!isUsernamePresent) {
        console.log("!isUsernamePresent", headerObj?.WPSH_Status);
        headerObj.WPSH_Status = Status.StatusTempSaved;
      } else {
        console.log("else before", headerObj?.WPSH_Status);
        headerObj.WPSH_Status = Status.StatusPending;
      }
      console.log("bfr decide", headerObj?.WPSH_Status);


      console.log("after decide", headerObj?.WPSH_Status);

      if (!(await validate())) {
        result = handleAdd(Status.StatusTempSaved);
        console.log("result_pending", result);
        
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext3: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp3: async () => {
        
        var res = false;
        console.log("is temporary", res);
        res = await handleSubmitTemp();
        console.log("is temporary return", res);
        return res;
      },
    }));

    useEffect(() => {
      
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      setToday(formattedDate);
      console.log("here", headerObj?.WPSH_PreparedBy, headerObj?.WPSH_Status);
      setReadonly(
        DisableFieldsWPQ(headerObj, Status, Action, action) ? "disabled" : ""
      );
      console.log("here", Readonly, "ddd");
    }, [headerObj]);


    const handleChangetestarray = (Index, key, newValue) => {
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };
      console.log("headerObjjjj", detailObj);
      // updatedProcessObj[Index][0][0].Actual_Value[valueIndex] = newValue;
      updatedheadertestObj.Visual_Examination[Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };

    const handleChangeLiquid_Penetrant_Examinationarray = (
      Index,
      key,
      newValue
    ) => {
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };
      console.log("headerObjjjj", detailObj);
      // updatedProcessObj[Index][0][0].Actual_Value[valueIndex] = newValue;
      updatedheadertestObj.Liquid_Penetrant_Examination[Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };

    const handleChangeMacro_Examinationarray = (
      Index,
      key,
      table,
      newValue
    ) => {
      
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };
      console.log("headerObjjjj", detailObj);
      // updatedProcessObj[Index][0][0].Actual_Value[valueIndex] = newValue;
      updatedheadertestObj.Macro_Examination[0][table][Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };

    const handleChangeMacro_Examinationarray5 = (
      Index,
      key,
      table,
      newValue
    ) => {
      
      
      setIsEdited(true);
      const updatedheadertestObj = { ...detailObj };
      console.log("headerObjjjj", detailObj);
      // updatedProcessObj[Index][0][0].Actual_Value[valueIndex] = newValue;
      updatedheadertestObj.Macro_Examination5[Index][key] = newValue;
      setdetailObj(updatedheadertestObj);
    };

   
    const handleChangedetail = (e, i) => {
      
  setIsEdited(true);
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines = 2; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
     setIsEdited(true);
      // setIsEditedPage3(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 45) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,45}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n'); // Use 'let' here instead of 'const'
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setdetailObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };


    //console.log("tube_Readonly", Readonly);
  

    return (
      //style="width:70%"
      <fieldset disabled={isReadonly}>
        <Row className="font">
          <WPQHeader headerObj={headerObj}></WPQHeader>
          <Row style={{ padding: "20px 60px" }}>
            <Row className="mb-1">
              <Col md={12}>
                <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Visual Examination </p>
                {console.log("Error....", error)}

                <WPQ_Page3_Table1
                  title1="Weld Pass"
                  title2="Acceptance Criteria"
                  title3="Observation"
                  title4="Results"
                  array={Visual_Examination}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  name1="WPQ_VEX_WeldPass"
                  name2="WPQ_VEX_Acceptance_Criteria"
                  name3="WPQ_VEX_Observation"
                  name4="WPQ_VEX_Results"
                  status={error.WPQ_VEX_WeldPass.status}
                  message={error.WPQ_VEX_WeldPass.message}
                  status1={error.WPQ_VEX_Acceptance_Criteria.status}
                  message1={error.WPQ_VEX_Acceptance_Criteria.message}
                  status2={error.WPQ_VEX_Observation.status}
                  message2={error.WPQ_VEX_Observation.message}
                  status3={error.WPQ_VEX_Results.status}
                  message3={error.WPQ_VEX_Results.message}
                  maxLengthname1={24}
                  maxLengthname2={16}
                  maxLengthname3={16}
                  maxLengthname4={23}
                  readOnly={isReadonly}
                  handleChangetestarray={handleChangetestarray}
                />
              </Col>
              <Col className="mt-3" md={6}>
                <Single_Input
                  disabled={copydata}
                  name="WPQ_VEX_Report"
                  type="text"
                  maxLength={95}
                  status={error.WPQ_VEX_Report.status}
                  value={detailObj?.WPQ_VEX_Report}
                  handleChange={handleChangedetail}
                  label="Report No."
                  message={error.WPQ_VEX_Report.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_VEX_Report: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
              <Col md={12}>
                <Single_Input
                  disabled={copydata}
                  name="WPQ_VEX_Notes"
                  type="text"
                  // style={{ height: "100px" }}
                  maxLength={95}
                  //readOnly={isReadonly}
                  status={error.WPQ_VEX_Notes.status}
                  value={detailObj?.WPQ_VEX_Notes}
                  handleChange={handleChangedetail}
                  label="Notes"
                  message={error.WPQ_VEX_Notes.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_VEX_Notes: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
          </Row>

          <Row style={{  padding: "20px 60px" }}  className="WPQ-Page-row-padding">
            <Row className="mb-1">
              <Col md={12}>
                <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Liquid Penetrant Examination </p>
                {console.log("Error....", error)}

                <WPQ_2Rows_Table
                  title1="Weld Pass"
                  title2="Acceptance Criteria"
                  title3="Observation"
                  title4="Results"
                  array={Liquid_Penetrant_Examination}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  name1="WPQ_LPEX_WeldPass"
                  name2="WPQ_LPEX_Acceptance_Criteria"
                  name3="WPQ_LPEX_Observation"
                  name4="WPQ_LPEX_Results"
                  status={error.WPQ_LPEX_WeldPass.status}
                  message={error.WPQ_LPEX_WeldPass.message}
                  status1={error.WPQ_LPEX_Acceptance_Criteria.status}
                  message1={error.WPQ_LPEX_Acceptance_Criteria.message}
                  status2={error.WPQ_LPEX_Observation.status}
                  message2={error.WPQ_LPEX_Observation.message}
                  status3={error.WPQ_LPEX_Results.status}
                  message3={error.WPQ_LPEX_Results.message}
                  maxLengthname1={24}
                  maxLengthname2={16}
                  maxLengthname3={16}
                  maxLengthname4={23}
                 
                  handleChangetestarray={
                    handleChangeLiquid_Penetrant_Examinationarray
                  }
                />
              </Col>
              <Col className="mt-3" md={6}>
                <Single_Input
                  disabled={copydata}
                  name="WPQ_LPEX_Report"
                  type="text"
                  maxLength={95}
                  status={error.WPQ_LPEX_Report.status}
                  value={detailObj?.WPQ_LPEX_Report}
                  handleChange={handleChangedetail}
                  label="Report No."
                  message={error.WPQ_LPEX_Report.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_LPEX_Report: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Notes</span>
              <Col md={12}>
                <Single_Input
                  disabled={copydata}
                  name="WPQ_LPEX_Notes"
                  type="text"
                  // style={{ height: "100px" }}
                  maxLength={95}
                  //readOnly={isReadonly}
                  status={error.WPQ_LPEX_Notes.status}
                  value={detailObj?.WPQ_LPEX_Notes}
                  handleChange={handleChangedetail}
                  label="Notes"
                  message={error.WPQ_LPEX_Notes.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_LPEX_Notes: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
          </Row>

          <Row style={{ padding: "20px 60px" }}>
            <Row className="mb-1">
              <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Macro Examination </p>

              {/* ////////////////////////2nd table ////////////////////////////////////////////*/}
              <Col md={6}>
                {console.log("Error....", error)}

                <WPQ_Page3_Table2
                  array={Macro_Examination1}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  name1="WPQ_MEX_Tube_No"
                  name2="WPQ_MEX_LocA"
                  name3="WPQ_MEX_LocB"
                  name4="WPQ_MEX_LocC"
                  name5="WPQ_MEX_LocD"
                  table="Table1"

                  error1={"WPQ_MEX_Tube_No"}
                  error2={"WPQ_MEX_LocA"}
                  error3={"WPQ_MEX_LocB"}
                  error4={"WPQ_MEX_LocC"}
                  error5={"WPQ_MEX_LocD"}

                  status={error.WPQ_MEX_Tube_No.status}
                  message={error.WPQ_MEX_Tube_No.message}
                  status1={error.WPQ_MEX_LocA.status}
                  message1={error.WPQ_MEX_LocA.message}
                  status2={error.WPQ_MEX_LocB.status}
                  message2={error.WPQ_MEX_LocB.message}
                  status3={error.WPQ_MEX_LocC.status}
                  message3={error.WPQ_MEX_LocC.message}
                  status4={error.WPQ_MEX_LocD.status}
                  message4={error.WPQ_MEX_LocD.message}
                  // ProcessActive={ProcessActive}
                  // readOnly={isReadonly}
                  handleChangetestarray={handleChangeMacro_Examinationarray}
                />
              </Col>

              <Col md={6}>
                <WPQ_Page3_Table2
                  array={Macro_Examination2}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  name1="WPQ_MEX_Tube_No"
                  name2="WPQ_MEX_LocA"
                  name3="WPQ_MEX_LocB"
                  name4="WPQ_MEX_LocC"
                  name5="WPQ_MEX_LocD"
                  table="Table2"

                  error1={"WPQ_MEX_Tube_No1"}
                  error2={"WPQ_MEX_LocA1"}
                  error3={"WPQ_MEX_LocB1"}
                  error4={"WPQ_MEX_LocC1"}
                  error5={"WPQ_MEX_LocD1"}

  
                  status={error.WPQ_MEX_Tube_No1.status}
                  message={error.WPQ_MEX_Tube_No1.message}
                  status1={error.WPQ_MEX_LocA1.status}
                  message1={error.WPQ_MEX_LocA1.message}
                  status2={error.WPQ_MEX_LocB1.status}
                  message2={error.WPQ_MEX_LocB1.message}
                  status3={error.WPQ_MEX_LocC1.status}
                  message3={error.WPQ_MEX_LocC1.message}
                  status4={error.WPQ_MEX_LocD1.status}
                  message4={error.WPQ_MEX_LocD1.message}
                  // ProcessActive={ProcessActive}
                  // readOnly={isReadonly}
                  handleChangetestarray={handleChangeMacro_Examinationarray}
                />
              </Col>

            

              {/* ////////////////////////3nd table ////////////////////////////////////////////*/}

              <Col md={12} style={{height:"215px"}}>
                <WPQ_Page3_Table3
                  array={Macro_Examination5}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  name1="WPQ_MEX_Magnification"
                  name2="WPQ_MEX_Acceptance_Criteria"
                  // name3="WPQ_MEX_Observation"
                  table="Table5"
                  status={error.WPQ_MEX_Magnification.status}
                  message={error.WPQ_MEX_Magnification.message}
                  status1={error.WPQ_MEX_Acceptance_Criteria.status}
                  message1={error.WPQ_MEX_Acceptance_Criteria.message}
                 
                  handleChangetestarray={handleChangeMacro_Examinationarray5}
                  maxLength1={16}
                  maxLength2={11}
                />

<WPQ_Page3_Table3observation
                  
                  setheaderObj={setheaderObj}
                  setError={setError}
          
                  name3="WPQ_MEX_Observation"
                  table="Table5"
                  value3={detailObj?.WPQ_MEX_Observation}
                  maxLength3={180}
                  status2={error.WPQ_MEX_Observation.status}
                  message2={error.WPQ_MEX_Observation.message}
      
                  // handleChangetestarray={handleChangedetail}
                  handleChangetestarray={handleChangeNotes}
                  valueNotes={valueNotes}

                />
              </Col>
            </Row>
          </Row>

          <Row style={{ padding: "0px 60px", position:"relative", bottom:"20px" }}>
            <span className="WPS-blue-text" style={{position:"relative",right:"11px",top:"10px"}}>Result</span>
            <Row className="mb-1">
              <Col md={12}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPQ_MEX_Result"
                    name="WPQ_MEX_Result"
                    //readOnly={isReadonly}
                    maxLength={60}
                    placeholder=" "
                    value={detailObj?.WPQ_MEX_Result}
                    onChange={handleChangedetail}
                    onInput={(e) => {
                      let error = isAlphanumericAllSpecialCharacters(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_MEX_Result: error,
                      }));
                    }}
                  />
                  <label for="WPQ_MEX_Result" >Result</label>
                </div>
                {error.WPQ_MEX_Result.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_MEX_Result.message}
                  </Form.Text>
                )}
              </Col>
            </Row>
          </Row>
        </Row>
      </fieldset>
    );
  }
);

export default WPQ_Tubesheet_Page3;
