import correctsign from "../../icons/correctsign.svg";
import wrongsign from "../../icons/wrongsign.svg";
import Edit from "../../icons/EditIcon.svg";
import Delete from "../../icons/DeleteIcon.svg";
import uploadImage from "../../icons/uploadImage.svg";
import "../../App.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import Avatar from "react-avatar";
import { WPSearch } from "./WPSearchRenderer";
import { ItemGroup } from "./ItemGroupRenderer";
import { PMItemGroup } from "./PMItemGroup";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  getTrainingSts,
  getDefaultDate,
  formatDateDDMMYYYY,
  formatDateDD_MM_YYYY,
} from "../../functions/welding-functions";
import axios from "axios";
import { getPresignedURLgrid } from "../../Services/wpq_groove-services";
import { NavLink } from "react-bootstrap";

const GlobalColumnDefinations = (tmpcolumnDefs) => {
  let coldefs = [];
  //let classes = ["avatarFirst", "orangeAvatar", "purpleAvatar"];
  const todayDate = getDefaultDate();
  let classes = ["a", "b", "c"];

  const getRandomColorClass = () => {
    return classes[Math.floor(Math.random() * 3)];
  };
  const randomColorClass = getRandomColorClass();

  const filterDate = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = formatDateDD_MM_YYYY(cellValue);
      if (dateAsString === null) {
        return 0;
      }
      const dateParts = dateAsString.split("-");
      const year = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[0]);
      const dateCell = new Date(year, month, day);
      console.log("dateCell", dateCell);
      if (dateCell < filterLocalDateAtMidnight) {
        console.log("dateCell < filterLocalDateAtMidnight");
        return -1;
      } else if (dateCell > filterLocalDateAtMidnight) {
        console.log("dateCell > filterLocalDateAtMidnight");
        return 1;
      }
      return 0;
    },
  };

  // let Avatarbackground = ["#d5e6f5", "#FAE9E8", "#EBF9F5"];
  // let AvatarfgColor = ["#004C93", "#E36334", "#5CA58C"];
  const AvatarCellRenderer = (props) => {
    const colors = ["avatarcolor1", "avatarcolor2", "avatarcolor3"]; // Define the available colors
    // const name = params.data.WI_Welder_Name;
    const index = props.rowIndex; // Get the index of the current row

    // Calculate the index of the color based on the row index
    const colorIndex = index % colors.length;
    const selectedColor = colors[colorIndex];
    
    console.log("propsssssss", props);
    return (
      <>
        <Avatar
          // className="profile_text"
          // className={classes[Math.floor(Math.random() * 3)]}
          className={selectedColor}
          maxInitials={2}
          name={props.value}
          // color="#F4F4FF"
          // fgColor="#004C93"
          round={true}
          marginTop="120"
          size="35"
        ></Avatar>
        &nbsp;&nbsp;
        <span>{props.value ? props.value : ""}</span>
      </>
    );
  };

  // const WelderAvatarCellRenderer = (props) => {
  //   return (
  //     <>
  //       <Avatar
  //         className="profile_text"
  //         maxInitials={2}
  //         name={props.data.wld_name}
  //         color="#F4F4FF"
  //         fgColor={classes[Math.floor(Math.random() * 3)]}
  //         //fgColor="#5CA58C"
  //         round={true}
  //         marginTop="120px"
  //         size="35px"
  //       ></Avatar>
  //       &nbsp;&nbsp;
  //       <span>{props.data.wld_name}</span>
  //     </>
  //   );
  // };

  const WelderAvatarCellRenderer = (props) => {
    return (
      <>
        <Avatar
          unstyled={true}
          className={classes[Math.floor(Math.random() * 3)]}
          maxInitials={2}
          name={props.value}
          round={true}
          size="10px"
        ></Avatar>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/* <span>{props.data.wld_name}</span> */}
        <span>{props.value ? props.value : ""}</span>
      </>
    );
  };
  const PWHTcheckbox = (props) => {
    // console.log("propppp",props?.data);
    
    return (
      <>
        <span>{props?.data?.CheckboxPostWHT === true ? "yes" : "no"}</span>

      </>
    );
  };

  const statusRenderer = (params) => {
    var st =
      params.value !== "Active" && params.value !== "Inactive"
        ? params.value === true || params.value === "true"
          ? "Active"
          : "Inactive"
        : params.value;
    // let status_color = st === "Active" ? "#3CB23D" : "#A7AEC6";
    // let status_color = st === "Active" ? "#3CB23D" : "#575B64";
    // let bk_color = st === "Active" ? "#E9FAE9" : "#EBEBEC";
    let statusimage = st === "Active" ? correctsign : wrongsign;

    // let status_background_Color=params.value=="1" ? "#E9FAE9" : "#EBEBEC"
    //   let status_color = params.value ? "#3CB23D" : "#A7AEC6";
    return (
      <>
        <span className={st === "Active" ? 'status-color-active' : 'status-color-inactive'}>
          <img
            style={{
              paddingLeft: "4px",
              width: "16px",
              height: "18px",
            }}
            src={statusimage}
            alt="hi"
          ></img>
          &nbsp;
          {st === "Active" ? "Active" : "Inactive"}
          &nbsp;
        </span>
      </>
    );
  };

  const statustrainRenderer = (params) => {
    var st =
      params.value !== "Yes" && params.value !== "No"
        ? params.value === true || params.value === "true"
          ? "Yes"
          : "No"
        : params.value;
    // let status_color = st === "Yes" ? "#3CB23D" : "#A7AEC6";
    // let bk_color = st === "Yes" ? "#E9FAE9" : "#EBEBEC";
    let statusimage = st === "Yes" ? correctsign : wrongsign;

    // let status_background_Color=params.value=="1" ? "#E9FAE9" : "#EBEBEC"
    //   let status_color = params.value ? "#3CB23D" : "#A7AEC6";
    return (
      <>
        <span className={st === "Yes" ? 'status-color-active' : 'status-color-inactive'}>
          <img
            style={{
              paddingLeft: "4px",
              width: "16px",
              height: "16px",
            }}
            src={statusimage}
            alt="hi"
          ></img>
          &nbsp;
          {st === "Yes" ? "Yes" : "No"}
          &nbsp;
        </span>
      </>
    );
  };


  const WeldReq = (params) => {
    
    const process = params.data?.wld_PositionQualified;
    console.log("atddsda",process)
    // Extract the first elements and filter out empty values
    const firstElements = process && process?.map(item => item[0]).filter(value => value !== "");
    
    // Join the elements with a comma
    const result = firstElements.join(',');

    return (
      <>
      {result}
       
      </>
    );
  };

  const Editor = (type) => {
    console.log("deed1dddd", type, type === "WPSearch");
    if (type === "Attendance")
      return {
        component: "agSelectCellEditor",
        params: {
          values: ["Present", "Absent"],
        },
      };

    if (type === "WPSearch")
      return {
        component: WPSearch,
      };

    if (type === "PRODSelect")
      return {
        component: "agSelectCellEditor",
        params: {
          values: ["Kgs", "Nos"],
        },
      };

    if (type === "ItemGroup")
      return {
        //component: "agSelectCellEditor",
        component: PMItemGroup,
        // params: {
        //   values: [
        //     "ESSC flux",
        //     "GTAW/SAW wire",
        //     "Strip",
        //     "FCAW wire",
        //     "SAW flux",
        //     "Electrode",
        //   ],
        // },
      };
  };

  const ErrorCss = (error) => {
    console.log("error", error);
    if (error === true) {
      return { "rag-amber": (params) => params.data.hasError === true };
    }
  };

  const ImageUploadRenderer = (props) => {
    console.log("paramsrow", props.data);

    // var st = (params.value !== "Active" && params.value !== "Inactive") ? (params.value === true || params.value === "true" ? "Active" : "Inactive") : params.value
    // let status_color = st === "Active" ? "#3CB23D" : "#A7AEC6";
    // let bk_color = st === "Active" ? "#E9FAE9" : "#EBEBEC";
    // let statusimage = st === "Active" ? correctsign : wrongsign;

    // // let status_background_Color=params.value=="1" ? "#E9FAE9" : "#EBEBEC"
    // //   let status_color = params.value ? "#3CB23D" : "#A7AEC6";
    const [profile, setProfile] = useState("");

    const [ImgObj, setImgObj] = useState({
      image: "",
    });

    console.log("ImgObj1", ImgObj);

    const [previewUrl, setPreviewUrl] = useState("");

    const fileInputRef = useRef(null);

    const handleFile = async (file, row) => {
      console.log("FILEEe", row);

      console.log("FILEEe", file);
      console.log("profile", profile);

      setProfile(file);

      //  const result = await getPresignedURL(ImgObj.wld_profile);
      //  console.log(result.url)
      //  const geturl = (result.url)
      //  setPreviewUrl(geturl)
      //  console.log("img", geturl)
      //  await axios.get(geturl)

      // setPreviewUrl(URL.createObjectURL(file));
      ImgObj.image = file.name;

      console.log("ImgObj", ImgObj.image);

      const result = await getPresignedURLgrid(ImgObj.image);
      console.log("1234", result);
      const posturl = result.url;
      setPreviewUrl(posturl);
      console.log("img", posturl);
      axios.put(posturl, profile);
      // console.log("putt",posturl,fileInput.files[0])
      console.log("puttprofile", profile);
    };

    return (
      <>
        {ImgObj.image !== "" ? (
          <label>{ImgObj.image}</label>
        ) : (
          <>
            <img for="fileInput" src={uploadImage} />

            <label for="fileInput" className="label-color">
              Upload File
            </label>
          </>
        )}

        <input
          id="fileInput"
          name="fileInput"
          type="file"
          accept="image/*"
          hidden
          ref={fileInputRef}
          onChange={(e) => {
            handleFile(e.target.files[0]);
          }}
        />
      </>
    );
  };

  const trainingCellRenderer = (params) => {
    let status_color = "";
    let bk_color = "";
    let status = "";

    status = getTrainingSts(
      params.data.wld_fromDate,
      params.data.wld_toDate,
      todayDate
    );

    switch (status) {
      case "In Progress":
        status_color = "var(--godrej-orange)";
        bk_color = "var(--godrej-orange-bg)";
        break;
      case "Upcoming":
        status_color = "var(--godrej-blue)";
        bk_color = "var(--godrej-blue-bg)";
        break;
      case "Completed":
        status_color = "var(--godrej-green-alt)";
        bk_color = "var(--godrej-green-bg)";
        break;
    }

    return (
      <>
        <div
          style={{
            backgroundColor: bk_color,
            borderRadius: "4px",
            height: "24px",
            marginTop: "12px",
          }}
          className="d-flex align-items-center"
        >
          <span style={{ color: status_color, marginLeft: "20px" }}>
            &nbsp;
            {status}
            &nbsp;
          </span>
        </div>
      </>
    );
  };

  const RaiseCellRenderer = (params) => {
    let status_color = "";
    let bk_color = "";
    let font__Size = "";
    let Margin_Size = "";
    console.log("paramsdddd", params);
    let Rstatus = params.data.devstatus;
    console.log("RStatus", Rstatus);


    switch (Rstatus) {
      case "Pending for CAPA Analysis":
        status_color = "var(--godrej-orange)";
        bk_color = "var(--godrej-orange-bg)";
        font__Size = "12px";
        Margin_Size = "4.4cm";

        break;
      case "Pending for Closure":
        status_color = "var(--godrej-blue)";
        bk_color = "var(--godrej-blue-bg)";
        font__Size = "12px";
        Margin_Size = "3.4cm";
        break;
      case "Closed":
        status_color = "var(--txt-color)";
        bk_color = "var(--bg-alt-2)";
        font__Size = "12px";
        Margin_Size = "1.4cm";
        break;
        case "Rejected":
        status_color = "var(--godrej-red)";
        bk_color = "var(--godrej-red-bg)";
        font__Size = "12px";
        Margin_Size = "1.7cm";
        break;
    }

    return (
      <>
        <div
          style={{
            backgroundColor: bk_color,
            borderRadius: "4px",
            width: Margin_Size,
            height: "24px",
            marginTop: "12px",
          }}
          className="d-flex align-items-center"
        >
          <span
            style={{
              color: status_color,
              borderRadius: "14px",
              fontSize: font__Size,
              marginLeft: "2px",
              paddingRight: Margin_Size,
            }}
            // className="text-overflow"
          >
            &nbsp;
            {Rstatus}
            &nbsp;
          </span>
        </div>
      </>
    );
  };

  const trainingReqCellRenderer = (params) => {
    let status_color = "";
    let bk_color = "";
    let status = params.data.wld_status;

    if (
      params.data.wld_status === "In Progress" ||
      params.data.wld_status === "In_Progress"
    ) {
      status = "In Progress";
    } else if (params.data.wld_status === "Initiated") {
      status = "Initiated";
    }
    // else if (params.data.wld_status === "Approved") {
    //   status = "Approved";
    // } else {
    //   status = "Rejected";
    // }

    switch (status) {
      case "Initiated":
        status_color = "var(--godrej-orange)";
        bk_color = "var(--godrej-orange-bg)";
        break;
      case "In Progress":
        status_color = "var(--godrej-orange)";
        bk_color = "var(--godrej-orange-bg)";
        break;
      case "Approved":
        status_color = "var(--godrej-blue)";
        bk_color = "var(--godrej-blue-bg)";
        break;
      case "Rejected":
      case "Deleted":
        status_color = "var(--godrej-red)";
        bk_color = "var(--godrej-red-bg)";
        break;
    }

    return (
      <>
        <div
          style={{
            backgroundColor: bk_color,
            borderRadius: "4px",
            height: "24px",
            marginTop: "12px",
            width:"140px"
          }}
          className="d-flex align-items-center"
        >
          <span style={{ color: status_color, marginLeft: "20px" }}>
            &nbsp;
            {status}
            &nbsp;
          </span>
        </div>
      </>
    );
  };

  const trainingReqNumberCellRenderer = (params) => {
    let bk_color = "";
    let status = "";

    return (
      <>
        <div className="d-flex align-items-center">
          <NavLink className="action-status-color">
            {params.data.wld_TrainingReqNumber}
          </NavLink>
        </div>
      </>
    );
  };

  const WPSCellRenderer = (params) => {
    let status_color = "";
    let bk_color = "";
    let status = params.value;
    let multiplier
    // let multiplier = status.length > 10 ? 11.5 :  status.length > 14 ? 5 : 14

    switch (status) {
      case "Temporary Saved":
        status_color = "var(--godrej-orange)";
        bk_color = "var(--godrej-orange-bg)";
        multiplier = 11.5;
        break;
      case "Pending For Approval":
        status_color = "var(--godrej-blue)";
        bk_color = "var(--godrej-blue-bg)";
        multiplier = 10;

        break;
      case "Approved":
        status_color = "var(--godrej-green-alt)";
        bk_color = "var(--godrej-green-bg)";
        multiplier = 15;

        break;
      case "Rejected":
        status_color = "var(--godrej-red)";
        bk_color = "var(--godrej-red-bg)";
        multiplier = 15;
        break;
      case "Freezed":
        status_color = "var(--godrej-red)";
        bk_color = "var(--godrej-red-bg)";
        multiplier = 15;
        break;
    }
const textwidth = status.length * multiplier;
    return (
      <>
        <div
          style={{
            width: textwidth + "px",
            backgroundColor: bk_color,
            borderRadius: "4px",
            height: "24px",
            marginTop: "12px",
          }}
          className="d-flex align-items-center"
        >
          <span
            style={{ color: status_color, marginLeft: "20px" }}
            className="text-overflow"
          >
            &nbsp;
            {status}
            &nbsp;
          </span>
        </div>
      </>
    );
  };

  const PQRSrno = (params) => {
    console.log("aff",params)
   
    return params.node.rowIndex + 1
  };

  const WeldRepairRenderer = (params) => {
    let status_color = "";
    let bk_color = "";
    let status = params.value;
    let multiplier


    switch (status) {
      case "In Progress":
        status_color = "var(--godrej-orange)";
        bk_color = "var(--godrej-orange-bg)";
        multiplier = 12;

        break;
      case "Pending for Proposal Status":
        status_color = "var(--godrej-blue)";
        bk_color = "var(--godrej-blue-bg)";
        break;
      case "Created":
        status_color = "var(--godrej-green-alt)";
        bk_color = "var(--godrej-green-bg)";
        multiplier = 15;

        break;
      case "Approved":
        status_color = "var(--godrej-green-alt)";
        bk_color = "var(--godrej-green-bg)";
        multiplier = 15;

        break;
      case "Rejected":
        status_color = "var(--godrej-red)";
        bk_color = "var(--godrej-red-bg)";
        multiplier = 15;

        break;
      case "Closed":
        status_color = "var(--godrej-red)";
        bk_color = "var(--godrej-red-bg)";
        multiplier = 15;

        break;
    }
    const textwidth = status.length * multiplier;


    return (
      <>
        <div
          style={{
            width: textwidth + "px",
            backgroundColor: bk_color,
            borderRadius: "4px",
            height: "24px",
            marginTop: "12px",
          }}
          className="d-flex align-items-center"
        >
          <span
            style={{ color: status_color, marginLeft: "20px" }}
            className="text-overflow"
          >
            &nbsp;
            {status}
            &nbsp;
          </span>
        </div>
      </>
    );
  };

  const dateCellRenderer = (params) => {
    return formatDateDDMMYYYY(params.value);
  };

  const getFilterParam = (filter) => {
    if (filter === "filterDate") {
      return filterDate;
    }
  };

  const getCellRenderer = (type) => {
    if (type === "AvatarCellRenderer") {
      return AvatarCellRenderer;
    }
    if (type === "WelderAvatarCellRenderer") {
      return WelderAvatarCellRenderer;
    }
    if (type === "statusRenderer") {
      return statusRenderer;
    }
    if (type === "trainingCellRenderer") {
      return trainingCellRenderer;
    }
    if (type === "trainingReqCellRenderer") {
      return trainingReqCellRenderer;
    }
    if (type === "RaiseCellRenderer") {
      return RaiseCellRenderer;
    }
    if (type === "trainingReqNumberCellRenderer") {
      return trainingReqNumberCellRenderer;
    }
    if (type === "WPSCellRenderer") {
      return WPSCellRenderer;
    }
    if (type === "PQRSrno") {
      return PQRSrno;
    }
    if (type === "WeldRepairRenderer") {
      return WeldRepairRenderer;
    }
    if (type === "dateCellRenderer") {
      return dateCellRenderer;
    }
    if (type === "ImageUploadRenderer") {
      return ImageUploadRenderer;
    }
    if (type === "statustrainRenderer") {
      return statustrainRenderer;
    }
    if (type === "WPSearch") {
      return WPSearch;
    }

    if (type === "ItemGroup") {
      return ItemGroup;
    }

    if (type === "WeldReq") {
      return WeldReq;
    }
    if (type === "PWHTcheckbox") {
      return PWHTcheckbox;
    }
  };

  const getfiltertype = (type) => {
    var filtertype;
    switch (type) {
      case "Text":
        filtertype = "agTextColumnFilter";
        break;
      case "Number":
        filtertype = "agNumberColumnFilter";
        break;
      case "Date":
        filtertype = "agDateColumnFilter";
        break;
      default:
        filtertype = "agTextColumnFilter";
        break;
    }
    return filtertype;
  };
  const contains = (target, lookingFor) => {
    return target && target.startsWith(lookingFor);
  };
  for (let i = 0; i < tmpcolumnDefs.length; i++) {
    let tmpheadername = tmpcolumnDefs[i]["headerName"];
    let tmpHeaderField = tmpcolumnDefs[i]["field"];

    if (tmpheadername != "Status") {
      console.log(
        "deed",
        "editable",
        tmpcolumnDefs[i]["editable"],
        tmpcolumnDefs[i]["editable"] === true ? true : false,
        tmpcolumnDefs[i]["type"],
        tmpcolumnDefs[i]["value"]
      );
      coldefs.push({
        field: tmpcolumnDefs[i]["field"],
        cellRenderer: getCellRenderer(tmpcolumnDefs[i]["cellRenderer"]),
        resizable: true,
        headerName: tmpcolumnDefs[i]["headerName"],
        filter: getfiltertype(tmpcolumnDefs[i]["type"]),
        tooltipField: tmpcolumnDefs[i]["field"],
        // width: 400,
        headerwidth: tmpcolumnDefs[i]["width"],
        headerCheckboxSelection: tmpcolumnDefs[i]["isCheckbox"],
        checkboxSelection: tmpcolumnDefs[i]["isCheckbox"],
        suppressSizeToFit: tmpcolumnDefs[i]["suppressSizeToFit"],
        flex: tmpcolumnDefs[i]["flex"],
        minWidth: tmpcolumnDefs[i]["minWidth"],
        sort: tmpcolumnDefs[i]["sort"],
        // sortable: tmpcolumnDefs[i]["sortable"] !== true ? false : true,
        pinned: tmpcolumnDefs[i]["pinned"],
        suppressFilter: tmpcolumnDefs[i]["suppressFilter"],
        floatingFilter: tmpcolumnDefs[i]["floatingFilter"],
        floatingFilterComponentParams:
          tmpcolumnDefs[i]["floatingFilterComponentParams"],
        suppressFilterButton: tmpcolumnDefs[i]["suppressFilterButton"],

        suppressMenu: tmpcolumnDefs[i]["suppressMenu"],
        suppressSort: tmpcolumnDefs[i]["suppressSort"],

        filterParams: getFilterParam(tmpcolumnDefs[i]["filterParams"]),
        cellStyle:tmpcolumnDefs[i]["cellStyle"],
        cellClass: tmpcolumnDefs[i]["cellClass"],


        editable:
          tmpcolumnDefs[i]["editable"] === "dependentField"
            ? (params) => {
                return editableProd(params);
              }
            : tmpcolumnDefs[i]["editable"] === true
            ? true
            : false,

        cellEditorSelector: (params) => Editor(tmpcolumnDefs[i]["type"]),
        cellClassRules: ErrorCss(tmpcolumnDefs[i]["Error"]),
        valueGetter: tmpcolumnDefs[i]["valueGetter"]
          ? (params) => {
              return calculateValue(params);
            }
          : "",

        valueSetter: tmpcolumnDefs[i]["valueSetter"]
          ? (params) => {
              return calculateValueSet(params);
            }
          : "",
      });
    } else {
      coldefs.push({
        field: tmpcolumnDefs[i]["field"],
        cellRenderer: getCellRenderer(tmpcolumnDefs[i]["cellRenderer"]),
        cellStyle: { paddingLeft: 0, alignItems: "center" },
        headerName: tmpcolumnDefs[i]["headerName"],
        cellStyle: tmpcolumnDefs[i]["cellStyle"],
       cellClass: tmpcolumnDefs[i]["cellClass"],
        filter: getfiltertype(tmpcolumnDefs[i]["type"]),
        sort: tmpcolumnDefs[i]["sort"],
        minWidth: tmpcolumnDefs[i]["minWidth"],
        sortable: tmpcolumnDefs[i]["sortable"] !== true ? false : true,
        filterParams: {
          filterOptions: ["equals"],
          suppressAndOrCondition: true,
          textMatcher: ({ value, filterText }) => {
            var filterTextLowerCase = filterText
              ? filterText.toLowerCase()
              : "";
            var valueLowerCase = value.toString().toLowerCase();
            return contains(valueLowerCase, filterTextLowerCase);
          },
        },
        width: 130,
      });
    }
  }
  console.log("ColDefs  ", coldefs);
  return coldefs;
};

const getHeader_FieldName = (field, tmpcolumnDefs, type) => {
  console.log("getHeader type ", type);
  console.log(field);
  let name = "No match found";

  if (type === "getHeader") {
    let index = tmpcolumnDefs.map((o) => o.field).indexOf(field);
    console.log(index);
    if (index >= 0) {
      console.log(
        tmpcolumnDefs[index].headerName,
        " tmpcolumnDefs[index].field"
      );
      name = tmpcolumnDefs[index].headerName;
    }
  }
  if (type === "getField") {
    let index = tmpcolumnDefs.map((o) => o.headerName).indexOf(field);
    console.log(index);
    if (index >= 0) {
      console.log(tmpcolumnDefs[index].field, " tmpcolumnDefs[index].field");
      name = tmpcolumnDefs[index].field;
    }
  }

  return name;
};

const editableProd = (params) => {
  console.log("Prodme_params", params);
  var fieldname = params.colDef.field;
  console.log("Prodme_fieldname", fieldname);

  if (fieldname === "WI_WCinKgs" && params.data.WI_WCinKgsNos === "Kgs")
  {
    params.api.getRowNode(params.node.rowIndex).setDataValue('WI_WCinNos', "");
    console.log("Prodme_fieldname1d", params.data.WI_WCinNos);
    return true;
  }
  else if (fieldname === "WI_WCinNos" && params.data.WI_WCinKgsNos === "Nos"){
  //  params.api.getRowNode(params.node.rowIndex).setDataValue('WI_WCinKgs', params.value * 10);

    console.log("Prodme_fieldname2", params);
    return true;
  }

  else return false;
};

const calculateValue = (params) => {
  console.log(
    "calculateValue_params",
    params,
    params.data.WI_WCinKgs,
    params.newValue,
    params.oldValue
  );

  let calRes;
  if (params.colDef.field === "WI_WMinKgs") {
    calRes = parseFloat(
      params.data.WI_WCinKgs / params.data.WI_CorrectionFactor
    ).toFixed(2);
    console.log("calRes", calRes);
  }

  /* if (
    params.colDef.field === "WI_WCinKgs" &&
    params.data.WI_WCinKgsNos === "Nos"
  ) {
    calRes = parseFloat(
      params.data.WI_WCinNos * params.data.WI_CorrectionFactor
    ).toFixed(2);

    console.log("calRes", calRes);
  } else {
    calRes = params.data.WI_WCinKgs;
  } */
  console.log("calResGet", params.data.WI_WCinKgs, params);
  return calRes === NaN || calRes === Infinity || calRes === undefined
    ? "0"
    : calRes;
};

const calculateValueSet = (params) => {
  console.log(
    "calculateValueset_params",
    params.colDef.field,
    params.newValue,
    params.oldValue
  );
  let WCinKg = params.newValue;
  let calRes;
  if (params.colDef.field === "WI_WMinKgs") {
    calRes = parseFloat(
      params.data.WI_WCinKgs / params.data.WI_CorrectionFactor
    ).toFixed(2);
    console.log("calRes", calRes);
  }

  if (
    params.colDef.field === "WI_WCinKgs" &&
    params.data.WI_WCinKgsNos === "Nos"
  ) {
    calRes = parseFloat(
      params.data.WI_WCinNos * params.data.WI_CorrectionFactor
    ).toFixed(2);
  } else {
    console.log("calResSet", WCinKg, params.newValue);
    calRes = WCinKg;
  }
  console.log("calResSet", calRes);
  // console.log("calResSet", params.data.WI_WCinKgs, params);
  return calRes;
};

export default GlobalColumnDefinations;
export { getHeader_FieldName };
