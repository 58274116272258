import React, { useState, useRef, forwardRef, useImperativeHandle, useMemo, useEffect } from "react";
import SuccessAlertModal from "../Modals/successAlertModal";
import { useNavigate, Link } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import AddRaiseDeviationCapa from "./AddRaiseDeviationCapa";
import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import "react-toastify/dist/ReactToastify.css";
import "../../Assests/Css/header.css";
import "./deviation.css";
import { ToastContainer, toast } from "react-toastify";
import Export_blue from "../../icons/Export_blue.svg";
import ExportIcon from "../../icons/ExportIcon.svg";
import { downloadAttachment } from "../../Services/deviationcapa-service";
import { getAllDeviaitons, getCapaDataByDevNumber } from "../../Services/deviationcapa-service";
import { resetFilter, FilterChange, defaultColParas } from "../utility/gbcs-functions";
import Pagination from "../utility/Pagination";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { DeviationNumberRenderer } from "./DeviationNumberRenderer";
import { DeviationImageRenderer } from "./DeviationImageRenderer";
import { DeviationStatusRenderer } from "./DeviationStatusRenderer";
import { getCurrentDateTime } from "../../functions/welding-functions";
import { BsX } from "react-icons/bs";
import { getUsersL1 } from "../../Services/user-service";
import GridMaster from "../utility/GridMaster";


const RaiseDeviationCapa = forwardRef((props, ref) => {
  let formName = "Raise Deviation Capa Analysis";
  const [rowData, setRowData] = useState({});
  const [capaData, setCapaData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [columnDefs, setcolumnDefs] = useState([]);
  const [deviations, setDeviations] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [excelData1, setExcelData] = useState([]);
  const [filteredCol, setFilteredCol] = useState([]);
  const [showMore, setShowMore] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [searchReText, setSearchReText] = useState("");
  const [filterColLength, setFilterColLength] = useState(0);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const pageSize = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const handleCloseSuccess = () => setShowSuccess(false);
  const [usersL1Result, setUsersL1Result] = useState([]);

  let salCode = sessionStorage.getItem("SalCode");
  console.log("salCode_salCode", salCode);

  const gridRef = useRef();
  var height = window.innerHeight - 130;
  var paginationPageSize = 6;

  if (filteredCol?.length > 0) height = window.innerHeight - 200;

  const gridStyle = useMemo(() => ({ height: height, width: "100%" }), []);
  const gridStylewithFilter = useMemo(
    () => ({ height: "100%", width: "100%" }),
    []
  );

  const defaultColDef = useMemo(() => {
    return defaultColParas();
  }, []);

  function onGridReady(params) {
    let temp = [];
    params.api.forEachNodeAfterFilter((node) => {
      temp.push(node.data);
    });
    setRowData(temp);
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setExcelData(temp);
  }

  const handleDeviation = async (row) => {
    let result = await getCapaDataByDevNumber(row.deviationNumber);
    setAction("Editcapa");
    setRowData(row);
    if (result?.length > 0) {
      setCapaData(result[0]);
    }
    else {
      setCapaData({});
    }
    setShowForm(true);
  };

  const handleImageDeviation = (row) => {
    downloadAttachment({ foldername: "Deviations", subfoldername: row.deviationNumber, filename: row.deviationImageFile })
      .catch((err) => {
        toast.error(`Error in fetching file.`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      });
  };

  const HyperLink = (params) => {
    return (
      <Link onClick={() => handleDeviation(params.data)} className="add-new-link">
        {parseFloat(params.data.deviationNumber)}
      </Link>
    )
  }

  const ImageHyperLink = (params) => {
    return (
      <Link onClick={() => handleImageDeviation(params.data)} className="add-new-link">
        {params.data.deviationImageFile}
      </Link>
    )
  }

  const tmpcolumnDefs = [
    {
      headerName: "Status",
      field: "devstatus",
      type: "Text",
      width: 300,
      isCheckbox: false,
      cellRenderer: "RaiseCellRenderer",
      cellClass: 'status-column',
    },
    {
      headerName: "Deviation Number",
      field: "deviationNumber",
      width: 250,
      type: "Number",
      cellRenderer: HyperLink
    },
    {
      headerName: "Slip Number",
      field: "slipNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Project Number",
      field: "projectNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Plant Number",
      field: "plantNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Joint Number",
      field: "jointNumber",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Welder Code",
      field: "welderCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    }, {
      headerName: "Supervisor Code",
      tooltipField: "Supervisor Code",
      field: "supervisorCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    }, {
      headerName: "Observer Code",
      tooltipField: "Observer Code",
      field: "observerCode",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    }, {
      headerName: "Observation Date",
      tooltipField: "Observation Date",
      field: "observationDate",
      type: "Date",
      isCheckbox: false,
      cellRenderer: "dateCellRenderer",
      filterParams: "filterDate",
    }, {
      headerName: "Observation Time",
      tooltipField: "Observation Time",
      field: "observationTime",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    }, {
      headerName: "Deviation Category Desc",
      field: "deviationCategoryDesc",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Deviation Severity Level",
      field: "deviationSeverityLevel",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    }, {
      headerName: "Updated By",
      field: "updatedByName",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "AvatarCellRenderer",
    },
    {
      headerName: "Updated On",
      field: "updatedOn",
      type: "Text",
      filter: 'agDateColumnFilter',
      isCheckbox: false,
      cellRenderer: "",
    }, {
      headerName: "Deviation Text",
      field: "deviationText",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
    {
      headerName: "Image",
      field: "deviationImageFile",
      isCheckbox: false,
      cellRenderer: ImageHyperLink
    },
    {
      headerName: "Ageing (Days)",
      field: "ageing(days)",
      type: "Text",
      isCheckbox: false,
      cellRenderer: "",
    },
  ];

  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  function sortByPropertyInAscending(property) {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  }

  const fetchRaise = async () => {
    const result = await getAllDeviaitons();
    console.log("reeessssssss",result)
    let todayDate = getCurrentDateTime("-");
    for (let i = 0; i < result?.length; i++) {
      let date1 = new Date(result[i].observationDate);
      let date2 = new Date(todayDate);
      let time_difference = date2.getTime() - date1.getTime();
      result[i]["ageing(days)"] = Math.round(time_difference / (1000 * 60 * 60 * 24));
      result[i].observationDate = result[i].observationDate.substring(0, 10);
      result[i].updatedOn = result[i].updatedOn.substring(0, 10);
    };
    result?.sort(sortByPropertyInAscending("deviationNumber"))
    setDeviations(result);
    setFilteredResult(result);
  };

  const fetchColumnDefns = async () => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result[1].cellRenderer = HyperLink;
    result[16].cellRenderer = ImageHyperLink;
    console.log(result);
    setcolumnDefs(result);
  };

  useEffect(() => {
    fetchRaise();
    fetchColumnDefns();
  }, [reload]);

  // useEffect(() => {
  //   setFilteredResult(deviations);
  //   let temp = [];
  //   if (gridRef.current.api) {
  //     gridRef.current.api.setQuickFilter(searchReText);
  //     gridRef.current.api.forEachNodeAfterFilter((node) => {
  //       temp.push(node.data);
  //     });
  //   }
  //   setExcelData(temp);
  //   setFilteredResult(temp);
  // }, [gridRef.current, searchReText]);


  const fetchUsersL1Code = async () => {
    let result = await getUsersL1({ emailId: salCode });
    console.log("fetchUsersL1Code_result", result);
    setUsersL1Result(result);
  };

  useEffect(() => {
    fetchUsersL1Code();
  }, [action]);


  useImperativeHandle(ref, () => ({
    handleAdd: async () => {
      setAction("Add");
      if(usersL1Result !== undefined){
      setShowForm(true);
      setReload(true);
      }
      else{
        toast.error(`User's L+1 EmailId not Maintained.`, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    },
  }));

  const resetForm = () => {
    document.filterForm.reset();
    setFilteredResult(deviations);
  };

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setDeviations",
    });
  };

  const handleGlobalSearch = (text) => {
    // setSearchReText(text);
    gridRef.current.setSearch(text);

  };

  const handleGlobalExport = () => {
    let filename = "RaiseDeviationDetails";
    setExcelData(filteredResult);
    let result = handleExport1(excelData1, filename, tmpcolumnDefs);
  };

  const handleCardClick = (index) => {
    if (showMore[index]) {
      setShowMore((prev) => ({ ...prev, [index]: false }));
    }
    else {
      setShowMore((prev) => ({ ...prev, [index]: true }));
    }
  }

  const filteredResulttemp = useMemo(() => {
    console.log(filteredResult, currentPage, pageSize);
    let firstPageIndex = 0;
    if (currentPage > 1) {
      firstPageIndex = (currentPage - 1) * pageSize;
    }
    if (firstPageIndex >= filteredResult?.length) {
      firstPageIndex = 0;
      const totalPageCount = Math.ceil(filteredResult?.length / pageSize);
      if (totalPageCount > 0) {
        setCurrentPage(totalPageCount);
      } else {
        setCurrentPage(1);
      }
    }
    let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
    return filteredResult
      ? filteredResult.slice(firstPageIndex, lastPageIndex)
      : [];
  }, [currentPage, filteredResult, pageSize]);

  const resetAllFilter = (gridRef, props) => {
    gridRef.current.api.setFilterModel(null);
  };

  return (
    <Container fluid className="main-container">
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <Row className="RowHeight">
        <Col lg={5} xs={12} className="deviationheaderResp">
          Raise Deviation / CAPA Analysis list
        </Col>
        <Col lg={7} xs={12} className="d-flex align-items-center justify-content-end">
          <div className="global-search-container">
            <GolbalSearch
              className="search"
              filterFunc={handleGlobalSearch}
              searchplaceholdertext={"Search for Status, Deviation Number, Slip Number..."}
            />
          </div>
          <img
            className="allexport "
            src={ExportIcon}
            onMouseOver={(e) => (e.currentTarget.src = Export_blue)}
            onMouseOut={(e) => (e.currentTarget.src = ExportIcon)}
            alt="ExportIcon"
            onClick={handleGlobalExport}
          />
        </Col>
      </Row>
      <div className="d-none">
        {() => setFilterColLength(filteredCol.length)}
      </div>
      {filteredCol.length > 0 && (
        <div className="d-flex" style={{ paddingBottom: "0.5%" }}>
          {filteredCol.map((item, index) => {
            return (
              <div key={`FilterCol${index}`} className="filter-col pr-0 me-2">
                <span className="ps-2 reset-filter-text">
                  {item}{" "}
                  <BsX
                    size={18}
                    onClick={() => resetFilter(gridRef, item, fieldName)}
                  />
                </span>
              </div>
            );
          })}
          <div>
            <Button className="clrbtn"
              onClick={() => {
                resetAllFilter(gridRef, props);
              }}
            >
              Clear All
            </Button>
          </div>
        </div>
      )}
      <div className="ag-theme-material d-none d-md-block d-lg-block"
        style={gridStylewithFilter}>
        {/* <AgGridReact
          ref={gridRef}
          rowData={deviations}
          columnDefs={columnDefs}
          headerNames={headerName}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={paginationPageSize}
          searchReText={searchReText}
          enableBrowserTooltips={true}
          onFilterChanged={(params) => {
            FilterChange(
              params,
              setFilteredCol,
              headerName,
              setFilteredResult,
              setExcelData
            );
          }}
        /> */}

<GridMaster
        rowDetails={deviations}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResult(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilteredCol(getLength)}
        setExcelData={setExcelData}
        ref={gridRef}
      />
      </div>
      <div className="scrollable-container">
        <div className="market-wrapper d-md-none d-lg-none table-containerDeviation">
          {
            filteredResulttemp?.length > 0 && <> {
              filteredResulttemp.map((selection, index) =>
                <Card key={index} className="cardContainer">
                  <Card.Body className="cardbodycontainer" key={index}>
                    {columnDefs.map((header, col_index) => {
                      return (header.field && header.field !== "" && (col_index < 5 || (col_index >= 5 && showMore[index])) &&
                        <Row key={col_index} className="responsive-row">
                          <Col className="colHeader">{header.headerName}</Col>
                          {
                            header.headerName === "Status" ?
                              <>
                                <Col className="colData"><DeviationStatusRenderer data={selection[header.field]} /></Col>
                              </>
                              : header.headerName === "Deviation Number" ?
                                <>
                                  <Col className="colData"><DeviationNumberRenderer data={selection[header.field]} handleDevClick={() => handleDeviation(selection)} /></Col>
                                </>
                                :
                                header.headerName === "Image" ?
                                  <>
                                    <Col className="colData"><DeviationImageRenderer data={selection[header.field]} handleImageClick={() => handleImageDeviation(selection)} /></Col>
                                  </>
                                  :
                                  <>
                                    <Col className="colData">{selection[header.field]}</Col>
                                  </>
                          }
                        </Row>
                      )
                    })
                    }
                  </Card.Body>
                  <Card.Footer className="responsive-card-footer">
                    <Row className="responsive-row">
                      <Col className="customerArrowResponsive deviation-cards-header">
                        <span id={index}
                          onClick={() => { handleCardClick(index) }}
                          className="show-more-link">{showMore[index] ? "Show less" : "Show More"}</span>
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              )}
              {filteredResulttemp?.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalCount={filteredResult ? filteredResult?.length : 0}
                  data={filteredResult}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </>
          }
        </div>
      </div>
      {
        showForm && (
          <AddRaiseDeviationCapa
            show={showForm}
            usersL1Result={usersL1Result}
            action={action}
            rowdata={rowData}
            onHide={() => setShowForm(false)}
            resetForm={resetForm}
            setReload={setReload}
            reload={reload}
            caparowdata={capaData}
            alldeviationsdata={deviations}
            backdrop="static"
          />
        )
      }
      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
    </Container >
  );
});

export default RaiseDeviationCapa;
