
import { checkValidJointNo } from "../../../Services/wcs-services";
export const emptyError = (err, seterr) => {
  var entries = Object.keys(err);

  entries.map((i) => {
    err[i] = {
      status: false,
      message: ``,
    };
  });
  console.log("new error", err);
  seterr((prevState) => ({
    ...prevState,
    ...err,
  }));
};

export const DisableFields = (headerObj, Status, Action, action) => {
  if (action === Action.ActionCopy) {
    return true;
  } else {
    if (
      headerObj.WPSH_PreparedBy !== sessionStorage.getItem("User") &&
      (headerObj.WPSH_Status === Status.StatusApproved ||
        headerObj.WPSH_Status === Status.StatusRejected ||
        headerObj.WPSH_Status === Status.StatusTempSaved)
    ) {
      return true;
    } else if (
      headerObj.WPSH_Status === Status.StatusPending &&
      sessionStorage.getItem("Band") !== "P" &&
      sessionStorage.getItem("Band") !== "T"
    ) {
      return true;
    }
  }

  return false;
};
function getArrayList(start) {
  let arr_list = [];
  start.forEach((element, index) => {
    console.log("element____", element);

    if (element.WCS_Joint_No !== "" && element.WCS_Joint_No !== undefined)
      arr_list.push({ jnt: element.WCS_Joint_No });
  });
  console.log("arr_list", arr_list);
  return arr_list;
}

export const validateWps = async (wcsDetail) =>{
 // let hasValidationErrors = false;
  let checkVal;
  console.log("handleAdd_wcsDetail", wcsDetail);

  if (wcsDetail.length > 0) {
    let finaled = {
      project: wcsDetail[0].WCS_Project_No,
      JointNo_Array: getArrayList(wcsDetail),
    };

    console.log("finaled:", finaled);
    checkVal =  await checkValidJointNo(finaled);
    console.log("myResult:",checkVal);
    //setJointErr(checkVal);
    let WCSError 
    if (checkVal.length > 0) {
      WCSError =await Promise.resolve(checkVal)
      console.log("validatewps",WCSError)
      return WCSError
    }

  }
  return undefined
}
