import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Ano from "./Masters/AnoMaster";
import TrainingMaster from "./Training/Training Subject/TrainingMaster";
import TrainingRecord from "./Training/Training Records/TrainingRecord";
import TraineeDetails from "./Training/Training Records/addTraineeDetails";
import Fno from "./Masters/FnoMaster";
import SFA from "./Masters/SfaMaster";
import UserMaster from "./Masters/UserMaster";
import Dashboard from "./Dashboard";
import UnAuthorized from "./UnAuthorized";
import RoleMaster from "./Masters/RoleMaster";
import InspectionAgencyMaster from "./Masters/InspectionAgencyMaster";
import ProjectMaster from "./Masters/ProjectMaster";
import PCategory from "./Masters/PCategoryMaster";
import Welder from "./Masters/WelderMaster";
import Item from "./Masters/ItemMaster";
import React, { useEffect, useRef, useState } from "react";
import WeldingProcess from "./Masters/WeldingProcessMaster";
import WPSGroove_Overlay from "./WPSTransaction/WPSGroove_Overlay";
import GrooveTransaction from "./WPSTransaction/GrooveTransaction";
import WPSTubesheetTransaction from "./WPSTransaction/WPSTubesheetTransaction";
import WPQGroove_Overlay from "./WPQTransaction/WPQGroove_Overlay";
import WPQGrooveTransaction from "./WPQTransaction/WPQGrooveTransaction";
import TrainingRequisition from "./Training/Training Requisition/TrainingRequisitionMaster";
import WPQTubeSheetTransaction from "./WPQTransaction/WPQTubeSheetTransaction";
import WCSDashboard from "./WCSTransaction/WCSDashboard";
import DeviationReport from "./Deviation/DeviationReport";
import WIGridPage from "./WITransaction/WIGridPage";
import WCSDetail from "./WCSTransaction/WCSDetail";
import { getMenuDetails } from "../Services/menu-service";
import RaiseDeviationCapa from "./Deviation/RaiseDeviationCapa";
import List_Of_Welders from "./ListOfWelders/List_Of_Welders";
import ProdLandPage from "./PRODTransaction/ProdLandPage";
import ProdReport from "./PRODTransaction/ProdReport";
import Welder_Record_sheet from "./Welder Record Sheet/Welder_Record_sheet";
import Welder_Continuity_Record from "./Welder Continuity Record/Welder_Continuity_Record";
import Wpq_linking from "./WPQ_Linking/Wpq_linking";
import Wpq_linkingAddEdit from "./WPQ_Linking/Wpq_linkingAddEdit";
import QualificationRequisition from "./WQRTransaction/QualificationRequisitionMaster";
import WQChartReport from "./WQChart/WelderQChart";
import PQRGroove_Overlay from "./PQRTransaction/PQRGroove_Overlay";
import PQRGrooveTransaction from "./PQRTransaction/PQRGrooveTransaction";
import PQRTubeSheetTransaction from "./PQRTransaction/PQRTubeSheetTransaction";
import WeldingRepairAnalysis from "./weldingRepair/WeldingRepairAnalysis";
import WeldingRepairAnalysisViewAll from "./weldingRepair/WeldingRepairAnalysisViewAll";
import WeldingRepairAnalysisEntry from "./weldingRepair/WeldingRepairAnalysisEntry";

const MainComponent = (props) => {
  console.log("props_MainComponent", props);
  const childRef = props.childRef;
  const location = useLocation();
  const [key, setKey] = useState("/wps/Groove");
  const [access, setAccess] = useState("Dasboard");

  useEffect(() => {
    setKey(location.pathname);
  }, [location.pathname]);

  const hasAcces = (menupath, menuElement) => {
    var menuaccses = sessionStorage.getItem("UserAccess");
    var Labels = {
      title: "",
      button: false,
      buttontext: "",
      currentMenu: "",
      heading: "",
    };

    if (menuaccses !== undefined) Labels = getMenuDetails(menuaccses, menupath);

    // console.log("Has access12", Labels, Labels != undefined);
    // console.log("Has access", menuElement);
    // access=true
    if (Labels != undefined) {
      if (Labels != undefined && Labels.title != undefined) return menuElement;
      else return <Navigate to="/unauthorized" />;
    }
  };
  return (
    <Routes>
      <Route path="/" element={<Dashboard></Dashboard>} />
      <Route path="/dashboard" element={<Dashboard></Dashboard>} />
      <Route path="/unauthorized" element={<UnAuthorized></UnAuthorized>} />
      <Route
        path="/wps/Groove/Groovetrans"
        element={hasAcces(
          "/wps/Groove",
          <GrooveTransaction type={process.env.REACT_APP_GROOVE_TYPE} />
        )}
      />
      <Route
        path="/wps/Overlay/Overlaytrans"
        element={hasAcces(
          "/wps/Overlay",
          <GrooveTransaction type={process.env.REACT_APP_OVERLAY_TYPE} />
        )}
      />
      <Route
        path="/wps/Groove"
        element={hasAcces(
          "/wps/Groove",
          <WPSGroove_Overlay
            type={process.env.REACT_APP_GROOVE_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />
      <Route
        path="/wps/Overlay"
        element={hasAcces(
          "/wps/Overlay",
          <WPSGroove_Overlay
            type={process.env.REACT_APP_OVERLAY_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />
      <Route
        path="/wps/Tubesheet"
        element={hasAcces(
          "/wps/Tubesheet",
          <WPSGroove_Overlay
            type={process.env.REACT_APP_TUBESHEET_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />
      {/* <Route path="/wps/Tubesheet" element={<WPSTubesheet ref={childRef}/>} /> */}
      <Route
        path="/wps/Tubesheet/Tubesheettrans"
        element={hasAcces("/wps/Tubesheet", <WPSTubesheetTransaction />)}
      />
      <Route
        path="/WPQTransaction/Groove"
        element={hasAcces(
          "/WPQTransaction/Groove",
          <WPQGroove_Overlay
            type={process.env.REACT_APP_GROOVE_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />
      <Route
        path="/wpq/Groove/Groovetrans"
        element={hasAcces(
          "/WPQTransaction/Groove",
          <WPQGrooveTransaction type={process.env.REACT_APP_GROOVE_TYPE} />
        )} //has access to parent element then internal has accses
      />
      <Route
        path="/WPQTransaction/Overlay"
        element={hasAcces(
          "/WPQTransaction/Overlay",
          <WPQGroove_Overlay
            type={process.env.REACT_APP_OVERLAY_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />

      <Route
        path="/wpq/Overlay/Overlaytrans"
        element={hasAcces(
          "/WPQTransaction/Overlay",
          <WPQGrooveTransaction type={process.env.REACT_APP_OVERLAY_TYPE} />
        )}
      />

      <Route
        path="/WPQTransaction/TubetoTubesheet"
        element={hasAcces(
          "/WPQTransaction/TubetoTubesheet",
          <WPQGroove_Overlay
            type={process.env.REACT_APP_TUBESHEET_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />

      <Route
        path="/wpq/TubetoTubesheet/TubetoTubesheettrans"
        element={hasAcces(
          "/WPQTransaction/TubetoTubesheet",
          <WPQTubeSheetTransaction
            type={process.env.REACT_APP_TUBESHEET_TYPE}
          />
        )}
      />

      <Route
        path="/weldingmaster/Ano"
        element={hasAcces("/weldingmaster/Ano", <Ano ref={childRef} />)}
      />
      <Route
        path="/weldingmaster/Fno"
        element={hasAcces("/weldingmaster/Fno", <Fno ref={childRef} />)}
      />
      <Route
        path="/weldingmaster/WelderMaster"
        element={hasAcces(
          "/weldingmaster/WelderMaster",
          <Welder ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/pcategory"
        element={hasAcces(
          "/weldingmaster/pcategory",
          <PCategory ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/Project"
        element={hasAcces(
          "/weldingmaster/Project",
          <ProjectMaster ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/SFA"
        element={hasAcces("/weldingmaster/SFA", <SFA ref={childRef} />)}
      />
      <Route
        path="/weldingmaster/inspectionagency"
        element={hasAcces(
          "/weldingmaster/inspectionagency",
          <InspectionAgencyMaster ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/Training"
        element={hasAcces(
          "/weldingmaster/Training",
          <TrainingMaster ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/TrainingRequisition"
        element={hasAcces(
          "/weldingmaster/TrainingRequisition",
          <TrainingRequisition ref={childRef} />
        )}
      />
      <Route
        path="/maintenance/user"
        element={hasAcces("/maintenance/user", <UserMaster ref={childRef} />)}
      />
      <Route
        path="/maintenance/role"
        element={hasAcces("/maintenance/role", <RoleMaster ref={childRef} />)}
      />

      <Route
        path="/weldingmaster/WPS"
        element={hasAcces(
          "/weldingmaster/WPS",
          <WeldingProcess ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/Item"
        element={hasAcces("/weldingmaster/Item", <Item ref={childRef} />)}
      />
      <Route
        path="/weldingmaster/TrainingRecord"
        element={hasAcces(
          "/weldingmaster/TrainingRecord",
          <TrainingRecord ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/TrainingRecord/TrainingDetails"
        element={hasAcces(
          "/weldingmaster/TrainingRecord",
          <TraineeDetails ref={childRef} />
        )}
      />
      <Route
        path="/weldingmaster/ViewTraineeDetails"
        element={hasAcces(
          "/weldingmaster/TrainingRecord",
          <TraineeDetails ref={childRef} />
        )}
      />

      <Route
        path="/WCSDashboard"
        element={hasAcces("/WCSDashboard", <WCSDashboard ref={childRef} />)}
      />
      <Route
        path="/wcsdetail"
        element={hasAcces("/WCSDashboard", <WCSDetail ref={childRef} />)}
      />

      <Route
        path="/raiseDeviation"
        element={<RaiseDeviationCapa ref={childRef} />}
      />
      <Route
        path="/raiseDeviation/deviationreport"
        element={<DeviationReport ref={childRef} />}
      />

      <Route
        path="/WITransaction"
        element={hasAcces("/WITransaction", <WIGridPage ref={childRef} />)}
      />
      <Route
        path="/ListofWelders"
        element={hasAcces("/ListofWelders", <List_Of_Welders ref={childRef} />)}
      />

      <Route
        path="/PRODTransaction"
        element={hasAcces("/PRODTransaction", <ProdLandPage ref={childRef} />)}
      />

      <Route
        path="/PRODReport"
        element={hasAcces("/PRODReport", <ProdReport ref={childRef} />)}
      />
      <Route
        path="/WelderRecordSheet"
        element={hasAcces(
          "/WelderRecordSheet",
          <Welder_Record_sheet ref={childRef} />
        )}
      />
      <Route
        path="/WelderContinuityRecord"
        element={hasAcces(
          "/WelderContinuityRecord",
          <Welder_Continuity_Record ref={childRef} />
        )}
      />
      <Route
        path="/WQRTransaction"
        element={hasAcces(
          "/WQRTransaction",
          <QualificationRequisition ref={childRef} />
        )}
      />

      <Route
        path="/Wpqlinking"
        element={hasAcces("/Wpqlinking", <Wpq_linking ref={childRef} />)}
      />

      <Route
        path="/Wpqlinking/AddEdit"
        element={hasAcces("/Wpqlinking", <Wpq_linkingAddEdit ref={childRef} />)} //has access to parent element then internal has accses
      />

      <Route
        path="/WQChart"
        element={hasAcces("/WQChart", <WQChartReport ref={childRef} />)}
      />

      <Route
        path="/PQRTransaction/Groove"
        element={hasAcces(
          "/PQRTransaction/Groove",
          <PQRGroove_Overlay
            type={process.env.REACT_APP_GROOVE_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />

      <Route
        path="/PQRTransaction/Overlay"
        element={hasAcces(
          "/PQRTransaction/Overlay",
          <PQRGroove_Overlay
            type={process.env.REACT_APP_OVERLAY_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />

      <Route
        path="/PQRTransaction/TubetoTubesheet"
        element={hasAcces(
          "/PQRTransaction/TubetoTubesheet",
          <PQRGroove_Overlay
            type={process.env.REACT_APP_TUBESHEET_TYPE}
            key={key}
            ref={childRef}
          />
        )}
      />

      <Route
        path="/pqr/Groove/Groovetrans"
        element={hasAcces(
          "/PQRTransaction/Groove",
          <PQRGrooveTransaction type={process.env.REACT_APP_GROOVE_TYPE} />
        )} //has access to parent element then internal has accses
      />

      <Route
        path="/pqr/Overlay/Overlaytrans"
        element={hasAcces(
          "/PQRTransaction/Overlay",
          <PQRGrooveTransaction type={process.env.REACT_APP_OVERLAY_TYPE} />
        )}
      />

      <Route
        path="/pqr/TubetoTubesheet/TubetoTubesheettrans"
        element={hasAcces(
          "/PQRTransaction/TubetoTubesheet",
          <PQRTubeSheetTransaction
            type={process.env.REACT_APP_TUBESHEET_TYPE}
          />
        )}
      />

      <Route
        path="/weldingRepair/WeldingRepairAnalysis"
        element={hasAcces(
          "/weldingRepair/WeldingRepairAnalysis",
          <WeldingRepairAnalysis
            // key={key}
            ref={childRef}
          />
        )}
      />
      <Route
        path="/weldingRepair/WeldingRepairAnalysisEntry"
        element={hasAcces(
          "/weldingRepair/WeldingRepairAnalysis",
          <WeldingRepairAnalysisEntry />
        )}
      />
      <Route
        path="/weldingRepair/WeldingRepairAnalysisViewAll"
        element={hasAcces(
          "/weldingRepair/WeldingRepairAnalysis",
          <WeldingRepairAnalysisViewAll />
        )}
      />

    </Routes>
  );
};

export default MainComponent;
