import { Row, Col, Form, Table, Tabs } from "react-bootstrap";
import {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useRef,
} from "react";
import {

  isAlphanumericAllSpecialCharacters,
} from "../../functions/validations";
import {
  company_name,

  MessageTypes,
  WeldedCoupon,

  WPQ_tubePage1,
  WPQ_Tube_Page1_Process,
  Plant_Location,
  Plant_Location_GO,
} from "./WPSObjects/WPQ_entity";
import { SearchSelect, SearchSelectWelder } from "../utility/search-select";
import {
  AddNewCountWPQ,
  AddNewGrooveWPQ,
  getbyrecordno,
} from "../../Services/wpq_groove-services";

import { useLocation, useParams } from "react-router-dom";
import { getPNOActive } from "../../Services/pcategory-service";
import { getActiveAno } from "../../Services/ano-services";
import { getActiveProcess } from "../../Services/weldingprocess-services";
import { getActiveSfa } from "../../Services/sfa-service";
import { getActiveFno } from "../../Services/fno-services";
import { todayDate, maxDate } from "../utility/CommonComponents/TodayDate";
import {

  page1characters,
} from "./WPSObjects/WPS_Validations";
import { getAllWPSNo } from "../../Services/wps_details-service";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { emptyError } from "./WPSObjects/WPS_common";
import { Action, Status } from "./WPSObjects/WPQ_entity";

import {
  General_Single_Input_select,
  Single_Input,
} from "../WPSTransaction/WPSObjects/WPS_Process_Grid";

import { getActiveInsp } from "../../Services/inspectionagency-service";

import {
  getActiveWelder,
  getPresignedURL,
} from "../../Services/Welder-services";
import {

  AddNewDetailTubesheet,
} from "../../Services/wpq_details-service";

import {  BaseMetalTableTTS } from "../utility/CommonComponents/BaseMetalTable";

import {
 
  Wpq_JointDetail_table1A,
  Wpq_JointDetail_table1B,
} from "../utility/CommonComponents/wpq_JointDetail_table";
import { WPS_Image } from "../WPSTransaction/WPSObjects/WPS_Image";
import { DisableFields } from "../WPSTransaction/WPSObjects/WPS_common";

import { WPQ_Filler_metals } from "./WPSObjects/WPQ_Filler_metals";
import { checkTubeProcessAdded } from "./WPSObjects/WPS_Validations";
import axios from "axios";
import { Date_Input } from "../utility/CommonComponents/Date_Component";


const WPQTubesheetPage1 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      normalstyle,
      errorstyle,
      blyestyle,
      WPStype,
      isDisabled,
      isReadonly,
      setIsReadonly,
      setIsEditedPage1,
    },
    ref
  ) => {
    const location = useLocation();

    const [Welder, setWelder] = useState([]);
    let username = sessionStorage.getItem("UserName");
    let folderwld = "wld_signatures";
    let folderwps = "WPS_Groove";
    let folderwpq = "WPQ_Groove";

    console.log("headerrrrr", headerObj);

    const [ProcessActive, setProcessActive] = useState([]);
    const [PNoActive, setPNoActive] = useState([]);
    const [WPSNo, setWPSNo] = useState([]);
    const [WelderActive, setWelderActive] = useState([]);

    const [ImageView1, setImageView1] = useState(false);
    const [ImageView2, setImageView2] = useState(false);
    const [ImageView3, setImageView3] = useState(false);

    const [InspecAgencyActive, setInspecAgencyActive] = useState([]);
    const [IsEdited, setIsEdited] = useState(false);

    const [ProjectDetails, setProjectDetails] = useState([]);
    const [SfaActive, setSfaActive] = useState([]);
    const [FnoActive, setFnoActive] = useState([]);
    const [AnoActive, setAnoActive] = useState([]);
    const [chemicalAnaError, setChemicalAnaError] = useState([]);

    const [checkwpsdata, setcheckwpsdata] = useState([]);
    const FilemaxSize = 1 * 1024 * 1024;

    const [classError, setClassError] = useState([]);

    const [action, setaction] = useState(location.state?.action);

    const [Readonly, setReadonly] = useState("");

    var isoverlay = WPStype === "Groove" ? false : true;

    var isgroove = WPStype !== "Groove" ? true : false;

    var grooveclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype !== "Groove" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);
    const [Grooveclass, setoverlayclass] = useState(grooveclass);
    const [overlaytext, setoverlaytext] = useState(overclass);

    const [profile, setProfile] = useState("");
    const [profile1, setProfile1] = useState("");
    const [profile2, setProfile2] = useState("");

    const [previewUrl, setPreviewUrl] = useState([]);

    const [previewUrl1, setPreviewUrl1] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();

    console.log("previewimg3",previewUrl3);
    console.log("previewimg2",previewUrl2);
    console.log("previewimg1",previewUrl1);

    const [imgObj, setimgObj] = useState([]);
    const [imgDataObj, setimgDataObj] = useState(
      headerObj?.wld_pdf_image ? headerObj?.wld_pdf_image : []
    );

    const [error, setError] = useState({
      WPQ_WelderId: {},
      WPQ_WelderName: {},
      WPQ_Date: {},
      WPQ_RecordNo: {},
      WPQ_Wps_RecordNo: {},
      WPQ_ReviNo: {},
      WPQ_Inspection: {},
      WPQ_DOB: {},
      WPQ_Qualificationcode: {},
      WPQ_Last_UpdatedDate: {},
      WPQ_Last_UpdatedBy: {},
      WPQ_Welded_on_coupon: {},
      WPQ_CompanyName: {},
      WPQ_Division: {},
      WPQ_PlantLocation: {},
      WPQ_specialNote: {},
      WPQ_Status: {},

      WPQ_BM_BM_ProductForm: {},
      WPQ_BM_BM_Spec: {},
      WPQ_BM_BM_Pno: {},
      WPQ_BM_BM_Gno: {},
      WPQ_BM_BM_Thk: {},
      WPQ_BM_BM_Dia: {},

      WPQ_BM_WTBM_ProductForm: {},
      WPQ_BM_WTBM_Spec: {},
      WPQ_BM_WTBM_Pno: {},
      WPQ_BM_WTBM_Gno: {},
      WPQ_BM_WTBM_Thk: {},
      WPQ_BM_WTBM_Dia: {},
      WPQ_BM_RangeQualified_Thk: {},
      WPQ_BM_RangeQualified_Dia: {},

      WPQ_VAR_TypeofJoint_actualValue: {},
      WPQ_VAR_TypeofJoint_RangeQualified: {},
      WPQ_VAR_BaseM_actualValue: {},
      WPQ_VAR_BaseM_RangeQualified: {},

      WPQ_BMT_BaseM_ActualV_Groove: {},
      WPQ_BMT_BaseM_ActualV_Fillet: {},
      WPQ_BMT_BaseM_ActualV_Overlay: {},

      WPQ_BMT_BaseM_RangeQ_Groove: {},
      WPQ_BMT_BaseM_RangeQ_Fillet: {},
      WPQ_BMT_BaseM_RangeQ_Overlay: {},

      WPQ_BMT_WeldedTo_ActualV_Groove: {},
      WPQ_BMT_WeldedTo_ActualV_Fillet: {},
      WPQ_BMT_WeldedTo_ActualV_Overlay: {},
      WPQ_BMT_WeldedTo_RangeQ_Groove: {},
      WPQ_BMT_WeldedTo_RangeQ_Fillet: {},
      WPQ_BMT_WeldedTo_RangeQ_Overlay: {},
      WPQ_WP_Process_Process: {},
      WPQ_WP_Process_Type: {},
      WPQ_WP_Type_Process: {},
      WPQ_WP_Type_Type: {},
      WPSH_Project_No: {},
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_SupportingPQR: {},
      WPSH_WPS_Date: {},
      WPSH_RefDoc: {},
      WPSH_Scope: {},
      WPSH_JointDesc: {},
      WPSH_BM_Type_Type: {},
      WPSH_BM_WeldingTo_Type: {},
      WPSH_BM_Type_GNo: {},
      WPSH_BM_Type_PNo: {},
      WPSH_BM_WeldingTo_PNo: {},
      WPSH_BM_WeldingTo_GNo: {},
      WPSH_BM_Backing_Backing: {},
      WPSH_BM_Backing_GNo: {},
      WPSH_BM_Backing_PNo: {},
      WPSH_BM_Retainer: {},
      WPSH_WPS_WeldLayer: {},
      WPSH_BM_Backing_Material: {},
      WPSH_ConsuInsert_SFA: {},
      WPSH_ConsuInsert_Classification: {},
      WPSH_ConsuInsert_FNo: {},
      WPSH_ConsuInsert_ANo: {},
      WPSH_ConsuInsert_ChemicalAnalysis_TradeName: {},
      WPSH_Flux_SFA: {},
      WPSH_Flux_Classification: {},
      WPSH_Flux_FNo: {},
      WPSH_Flux_ANo: {},
      WPSH_Flux_ChemicalAnalysis_TradeName: {},
      WPSH_SupFiller_SFA: {},
      WPSH_SupFiller_Classification: {},
      WPSH_SupFiller_FNo: {},
      WPSH_SupFiller_ANo: {},
      WPSH_SupFiller_ChemicalAnalysis_TradeName: {},
      WPSH_BM_Notes: {},
      WPSH_TRQ_GroovewithoutImpact_Min: {},
      WPSH_TRQ_GroovewithoutImpact_Max: {},
      WPSH_TRQ_GroovewithImpact_Min: {},
      WPSH_TRQ_GroovewithImpact_Max: {},
      WPSH_TRQ_FilletwithoutImpact_Min: {},
      WPSH_TRQ_FilletwithoutImpact_Max: {},
      WPSH_TRQ_FilletwithImpact_Min: {},
      WPSH_TRQ_FilletwithImpact_Max: {},
      WPSH_FM_FluxType: {},
      WPSH_FM_AlloyFlux: {},
      WPSH_FM_AlloyElement: {},
      WPSH_FM_Add_Del_Filler: {},
      WPSH_FM_Add_Del_Flux: {},
      WPSH_FM_Add_Del_orchange: {},
      WPSH_FM_FluxItemBatch: {},
      WPSH_FM_FluxFromRecrush: {},
      WPSH_FM_FluxItemCode: {},
      WPSH_FM_FilerMetal: {},
      WPSD_FM_Classification: {},
      WPSH_BM_Type_HT_Con: {},
      WPSH_BM_WeldingTo_HT_Con: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},
      WPSD_FM_TRQ_Min: {},
      WPSD_FM_TRQ_Max: {},
      WPSH_ConsuInsert: {},
      WPSD_FM_Make: {},
      WPSH_Flux: [],
      WPSH_SupFiller: [],
      WPSH_MinOvlFinThick: {},

      WPQ_Process: {},
      WPSH_TRQ_BaseMetalCRO_Min: {},
      WPSH_TRQ_BaseMetalCRO_Max: {},
      WPSH_TRQ_BaseMetalHRO_Min: {},
      WPSH_TRQ_BaseMetalHRO_Max: {},

      WPSH_Project_No: {},
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_SupportingPQR: {},
      WPSH_WPS_Date: {},
      WPSH_RefDoc: {},
      WPSH_Scope: {},
      WPSH_BM_BaseMetal1_ProductForm: "",
      WPSH_BM_BaseMetal1_Spec: "",
      WPSH_BM_BaseMetal1_PNo: "",
      WPSH_BM_BaseMetal1_GNo: "",
      WPSH_BM_BaseMetal1_Thick: "",
      WPSH_BM_BaseMetal1_Diam: "",
      WPSH_BM_WeldBaseMetal2_ProductForm: "",
      WPSH_BM_WeldBaseMetal2_Spec: "",
      WPSH_BM_WeldBaseMetal2_PNo: "",
      WPSH_BM_WeldBaseMetal2_GNo: "",
      WPSH_BM_WeldBaseMetal2_Thick: "",
      WPSH_BM_WeldBaseMetal2_Diam: "",
      WPSH_JD_JointType: {},
      WPSH_JD_HoleId: {},
      WPSH_JD_TubePitch: {},
      WPSH_JD_GrooveDepth: {},
      WPSH_JD_TubeProj: {},
      WPSH_JD_WidthLigament: {},
      WPSH_JD_Pattern: {},
      WPSH_JD_GrooveAngle: {},
      WPSH_JD_GrooveType: {},
      WPQ_FM_PreFillerMetal: {},
      WPQ_FM_WithFillerMetal: {},
      WPQ_FM_FillerProduct: {},
      WPQ_FM_Inserts: {},
      // WPQ_FM_FillerFlux: {},
      WPQ_FM_FillerOthers: {},
      WPSD_FM_SFA: {},
      WPSD_FM_Classification: {},
      WPSD_FM_FNo: {},
      WPSD_FM_Ano: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},

      WPQ_Joint_Type: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
      WPQ_Hole_Id: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
      WPQ_Tube_Pitch: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
      WPQ_Groove_Depth: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

      WPQ_Tube_Projection: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
      WPQ_Width_Ligament: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
      WPQ_Pattern: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
      WPQ_Groove_Angle: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

      wld_pdf_image: {},
    });

    const handleChange = (e) => {
       setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleActualValueChange = (key, id, valueIndex, newValue) => {
      
      const updatedProcessObj = { ...headerObj };
      updatedProcessObj[key][id].Actual_Value[valueIndex] = newValue;
      setheaderObj(updatedProcessObj);
    };

    const uploadImage = async (item) => {
      let unique = headerObj?.WPSH_Project_No + "_" + headerObj?.WPSH_WPS_No;
      const result = await getPresignedURL(folderwpq, item.value.name);
      const posturl = result.url;
      await axios.put(posturl, item.value);
    };

    const handleRangeQualifiedChange = (key, id, valueIndex, newValue) => {
      
      const updatedProcessObj = { ...headerObj };
      updatedProcessObj[key][id].Range_Qualified[valueIndex] = newValue;
      setheaderObj(updatedProcessObj);
    };

    const validate = async () => {
      let entries;
      let entries1;
      let entries2;
      entries = Object.entries(WPQ_tubePage1);
      entries1 = Object.entries(WPQ_Tube_Page1_Process);

      var isError = false;

      entries.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }

        if (WPStype === "Groove") {
          var data = page1characters(error);
          if (data) {
            isError = true;
          } else {
            error[key] = {
              status: false,
              message: "",
            };
          }
        }

        return isError;
      });

      entries1.map(([key, val]) => {
        if (detailObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
        return isError;
      });

      var norows = checkTubeProcessAdded(detailObj);
      if (norows) {
        

        detailObj.errormessage =
          "Atleast one Tubesheet Process and SFA Type is required";
        isError = true;
        detailObj.error = true;
      }

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(headerObj?.WPQ_WelderId, headerObj?.WPQ_RecordNo)
        ) {
          isError = true;
          error.WPQ_WelderId.status = true;
          error.WPQ_WelderId.message =
            "Project Number & WPQ Number already exists";
        } else {
          error.WPQ_WelderId.status = false;
          error.WPQ_WelderId.message = "";
        }
      }
      // {
        
      //   if (await checkWPSNO(WPStype, headerObj?.WPQ_Wps_RecordNo)) {
          

      //     isError = true;
      //     error.WPQ_Wps_RecordNo.status = true;
      //     error.WPQ_Wps_RecordNo.message =
      //     `Enter Approved Existing WpsRecordNo from WPS ${WPStype} Transaction`;
      //     console.log("condition", "error");
      //   } else {
      //     console.log("condition", "no error");
      //     error.WPQ_Wps_RecordNo.status = false;
      //     error.WPQ_Wps_RecordNo.message = "";
      //   }
      // }
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const checkDuplicate = async (type, proj, revno) => {
      
      let result = await getbyrecordno(type, proj, revno);
      console.log("rres", result);
      return !(result[0] === undefined);
    };
    

    // const checkWPSNO = async (type, wpsno) => {
      
    //   if (headerObj?.WPQ_Wps_RecordNo !== "") {
    //     let result = await getbywpsno(type, wpsno);

    //     console.log("reeeeess", result);
    //     setcheckwpsdata(result[0]);
    //     return (
    //       result[0] === undefined || result[0] === "" || result[0] === null
    //     );
    //   } else {
    //     return null;
    //   }
    // };
    
    const validateTemp = async () => {
      let isError = false;

      let temperror = emptyError(error, setError, isError);

      if (headerObj?.WPQ_WelderId.trim() === "") {
        headerObj.WPQ_WelderId = "";
        isError = true;
        error.WPQ_WelderId = {
          status: true,
          message: "This field is required",
        };
      }
      if (headerObj?.WPQ_RecordNo.trim() === "") {
        headerObj.WPQ_RecordNo = "";
        isError = true;
        error.WPQ_RecordNo = {
          status: true,
          message: "This field is required",
        };
      }

      var norows = checkTubeProcessAdded(detailObj);
      if (norows) {
        

        detailObj.errormessage =
          "Atleast one Tubesheet SFA Type and F.No. is required";
        isError = true;
        detailObj.error = true;

        if (
          detailObj.WPQ_Process !== "" &&
          detailObj.WPSD_FM_FNo !== "" &&
          detailObj.WPSD_FM_SFA !== ""
        ) {
          detailObj.error = false;
        }
      }


      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        if (
          await checkDuplicate(headerObj?.WPQ_WelderId, headerObj?.WPQ_RecordNo)
        ) {
          console.log("condition", "if");
          isError = true;
          error.WPQ_WelderId.status = true;
          error.WPQ_WelderId.message =
            "Project Number & WPQ Number already exists";
        } else {
          console.log("condition", "inelse");
          error.WPQ_WelderId.status = false;
          error.WPQ_WelderId.message = "";
        }
      }
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      console.log("error", isError);
      return {isError ,temperror};
    };

    const handleCount = () => {
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.wld_Approval_Type === Status.StatusApproved) {
          console.log("no", location.state?.WPS_Change_No);

          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCountWPQ(headerObj);
        }
      }
    };

    const handleChangedetailsarray = (e, i) => {
      
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));

    };

    const handleChangeFillerMetal = (e) => {
      setIsEdited(true);
      // setIsEditedPage1(true);
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleAdd = async (approvalstatus) => {
      let result = false;
      headerObj.WPQ_CompanyName = company_name[0]?.value;
      // headerObj.WPQ_Division = division[0]?.value;
      if (headerObj?.WPQ_Status === "Active") {
        headerObj.WPQ_Status = "Active";
      } else {
        headerObj.WPQ_Status = "Inactive";
      }

      if (
        checkwpsdata !== undefined ||
        checkwpsdata !== "" ||
        checkwpsdata !== null
      ) {
        headerObj.WPS_Key = checkwpsdata?.SK;
      }

      headerObj.WPQ_Last_UpdatedBy = username;

      if (
        headerObj?.WPSH_Status === Status.StatusApproved ||
        headerObj?.WPSH_Status === Status.StatusRejected
      ) {
        headerObj.WPSH_Status = Status.StatusPending;
        if (headerObj?.WPSH_Status === Status.StatusApproved) handleCount();
      }
      console.log("header......", headerObj);
  
      var WPSH_WPQ_Process = "";
      var WPSH_WPQ_Type = "";
      var WPSH_WPS_WeldLayer = "";

      detailObj.WPQ_WelderId = headerObj?.WPQ_WelderId;
      detailObj.WPQ_RecordNo = headerObj?.WPQ_RecordNo;

      detailObj.PK = headerObj?.WPQ_WelderId + "#" + headerObj?.WPQ_RecordNo;
      console.log("item.PK", detailObj.PK);

      WPSH_WPQ_Process += detailObj.WPQ_Process;
      WPSH_WPQ_Type += detailObj.WPSD_FM_SFA;
      // WPSH_WPS_WeldLayer += item.WPSH_WeldLayer;
      console.log("item.PK", detailObj.WPQ_Process);

      headerObj.WPSH_WPQ_Process = WPSH_WPQ_Process;
      headerObj.WPSH_WPQ_Type = WPSH_WPQ_Type;

      // headerObj.WPSH_WPS_WeldLayer = WPSH_WPS_WeldLayer;

      
      detailObj.WPSH_WPQ_Process = headerObj?.WPSH_WPQ_Process;
      detailObj.WPSD_FM_SFA = headerObj?.WPSH_WPQ_Type;
      detailObj.wld_WPSType = headerObj?.wld_WPSType;

      detailObj.SK =
        headerObj?.WPSH_WPQ_Process + "#" + headerObj?.WPSH_WPQ_Type;
      console.log("item.SK", detailObj.SK);

      let abc = await AddNewGrooveWPQ(headerObj)
      console.log("aaaabbbcc", abc)
      if(abc !== undefined){

          try {
            if(profile !== ""){

            if (headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "") {
              
              console.log(
                "insideUrl",
                headerObj?.Pdf_images[0]?.tube_Hole_ligament[0]
              );
              const result = await getPresignedURL(
                folderwpq,
                headerObj.Pdf_images[0].tube_Hole_ligament[0]
              );
              console.log(result);
              const posturl = result.url;
              setPreviewUrl1(posturl);
              console.log("img", posturl);
              await axios.put(posturl, profile);
              // console.log("putt",posturl,fileInput.files[0])
              console.log("puttprofile", profile);
              setImageView1(true)

            }
          }
          if(profile1 !== ""){

            if (headerObj?.Pdf_images[1].tube_Hole[0] !== "") {
              
              console.log("insideUrl", headerObj?.Pdf_images[1].tube_Hole[0]);
              const result = await getPresignedURL(
                folderwpq,
                headerObj?.Pdf_images[1].tube_Hole[0]
              );
              console.log(result);
              const posturl = result.url;
              setPreviewUrl2(posturl);
              console.log("img", posturl);
              await axios.put(posturl, profile1);
              // console.log("putt",posturl,fileInput.files[0])
              console.log("puttprofile", profile1);
              setImageView2(true)

            }
          }
          if(profile2 !== ""){

            if (headerObj?.Pdf_images[2].tube_Weld_Joint[0] !== "") {
              
              console.log(
                "insideUrl",
                headerObj?.Pdf_images[2].tube_Weld_Joint[0]
              );
              const result = await getPresignedURL(
                folderwpq,
                headerObj?.Pdf_images[2].tube_Weld_Joint[0]
              );
              console.log(result);
              const posturl = result.url;
              setPreviewUrl3(posturl);
              console.log("img", posturl);
              await axios.put(posturl, profile2);
              // console.log("putt",posturl,fileInput.files[0])
              console.log("puttprofile", profile2);
              setImageView3(true)

            }
          }
          } catch (error) {
            if (error.request) {
              console.log("hii", error.request);
            } else {
              console.log("hiiiii", `Error: ${error.message}`);
            }
          }
          console.log("Details......", detailObj);
          setaction(Action.ActionEdit);
          result = true;
          if (imgObj.length > 0) {
            for (let item of imgObj) {
              if (item.value.name) await uploadImage(item);
            }
          }
          
          console.log("unique", action);
          
          await AddNewDetailTubesheet(detailObj)
          .catch((err)=>{
            console.log("errorr + ", err)
          })
            .then((r) => {
              console.log("first  done detail", r);
              setaction(Action.ActionEdit);
              result = true;
            });
           
        
        }
        else{alert("Please enter valid data")}

      console.log("first complete", result);
      return result;
    };

    const fetchProcess = async () => {
      const result = await getActiveProcess();
      const process = [{ wld_code: "NA" }, ...result];
      console.log("proccess", process);
      setProcessActive(process);
    };

    const fetchPNo = async () => {
      const result = await getPNOActive();

      setPNoActive(result);
    };

    const fetchSfa = async () => {
      const result = await getActiveSfa();
      setSfaActive(result);
    };
    const fetchAno = async () => {
      const result = await getActiveAno();
      setAnoActive(result);
    };
    const fetchFno = async () => {
      const result = await getActiveFno();
      setFnoActive(result);
    };

    const fetchWPSNo = async () => {
      const result = await getAllWPSNo();
      setWPSNo(result);
    };
    console.log("rraaaaar", WPSNo);

    const fetchWelder = async () => {
      const result = await getActiveWelder();
      // Check if wld_salarycode is empty, if so, use wld_code as wld_salarycode
      const modifiedResult = result.map(welder => ({
          ...welder,
          wld_salarycode: welder.wld_salarycode || welder.wld_code,
      }));
      setWelderActive(modifiedResult);
      console.log("Modified Result:", modifiedResult);
  };
    console.log("rrr", WPSNo);

    const fetchInspecAgency = async () => {
      const result = await getActiveInsp();
      setInspecAgencyActive(result);
    };

    useEffect(() => {
      fetchProcess();
      fetchPNo();
      fetchInspecAgency();
      fetchWPSNo();
      fetchWelder();
      fetchSfa();
      fetchAno();
      fetchFno();
    }, []);

    const handleFile = (e) => {
      console.log("FIL", e.target.files[0]);
      setimgObj(imgObj.filter((item) => item.name !== e.target.name));
      let img = { name: e.target.name, value: e.target.files[0] };
      setimgObj((result) => [...result, img]);

      setimgDataObj(imgDataObj.filter((item) => item.name !== e.target.name));
      let img1 = { name: e.target.name, value: e.target.files[0].name };
      setimgDataObj((result) => [...result, img1]);

      setPreviewUrl(previewUrl.filter((item) => item.name !== e.target.name));
      let a = {
        name: e.target.name,
        value: URL.createObjectURL(e.target.files[0]),
      };
      setPreviewUrl((prev) => [...prev, a]);
    };

    useEffect(() => {
      if (imgDataObj.length > 0) {
        setheaderObj((prevState) => ({
          ...prevState,
          ["wld_pdf_image"]: imgDataObj,
        }));
      }
    }, [imgDataObj]);

    const fetchImages = async (item) => {
      

      try {
        



        let unique = headerObj?.WPSH_Project_No + "_" + headerObj?.WPSH_WPS_No;
        if (item.value !== "" || item.value !== undefined) {
          const result = await getPresignedURL(folderwpq, item.value);
          const geturl = result.url;
          console.log("geturl", geturl);
          let a = { name: item.name, value: geturl };
          setPreviewUrl((prev) => [...prev, a]);
          // await axios.get(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };


    const fetchImagestube1 = async (item) => {

    if (item) {
              
      console.log(
        "insideUrl",
        item,
        headerObj?.Pdf_images[0]?.tube_Hole_ligament[0]
      );
      const result = await getPresignedURL(
        folderwpq,
        item
      );
      console.log(result);
      const posturl = result.url;
      setPreviewUrl1(posturl);
      console.log("img", posturl);

    }

  }

  const fetchImagestube2 = async (item) => {
    
        if (item) {
                  
          console.log(
            "insideUrl",
            item,
            headerObj?.Pdf_images[1]?.tube_Hole[0]
          );
          const result = await getPresignedURL(
            folderwpq,
            item
          );
          console.log(result);
          const posturl = result.url;
          setPreviewUrl2(posturl);
          console.log("img2", posturl);
        }
    
      }

      const fetchImagestube3 = async (item) => {
        
            if (item) {
                      
              console.log(
                "insideUrl",
                item,
                headerObj?.Pdf_images[2]?.tube_Weld_Joint[0]
              );
              const result = await getPresignedURL(
                folderwpq,
                item
              );
              console.log(result);
              const posturl = result.url;
              setPreviewUrl3(posturl);
              console.log("img3", posturl);
            }
        
          }

    useEffect(() => {
      
      if (headerObj?.wld_pdf_image) {
        for (let item of headerObj?.wld_pdf_image) {
          fetchImages(item);
        }
      }
    }, []);

    useEffect(() => {
      
      if (action === Action.ActionEdit && headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "")
        {
        fetchImagestube1(headerObj?.Pdf_images[0]?.tube_Hole_ligament[0])
        }

        if (action === Action.ActionEdit && headerObj?.Pdf_images[1]?.tube_Hole[0] !== "")
          {
          fetchImagestube2(headerObj?.Pdf_images[1]?.tube_Hole[0])
          }
        if (action === Action.ActionEdit && headerObj?.Pdf_images[2]?.tube_Weld_Joint[0] !== "")
          {
          fetchImagestube3(headerObj?.Pdf_images[2]?.tube_Weld_Joint[0])
          }
      
    }, []);


    
    useEffect(() => {
      
      if (ImageView1 === true && headerObj?.Pdf_images[0]?.tube_Hole_ligament[0] !== "")
      {
        console.log("hiiipic1")
        fetchImagestube1(headerObj?.Pdf_images[0]?.tube_Hole_ligament[0])
      }
    }, [ImageView1]);

    useEffect(() => {
      if (ImageView2 === true && headerObj?.Pdf_images[1]?.tube_Hole[0] !== "")
      {
        console.log("hiiipic2")
        fetchImagestube2(headerObj?.Pdf_images[1]?.tube_Hole[0])
      }
    }, [ImageView2]);

    useEffect(() => {
    if (ImageView3 === true && headerObj?.Pdf_images[2]?.tube_Weld_Joint[0] !== "")
      {
        console.log("hiiipic3")
        fetchImagestube3(headerObj?.Pdf_images[2]?.tube_Weld_Joint[0])
      }
    }, [ImageView3]);

    const fileInputRef = useRef(null);

    const getImgURL = (name) => {
      let temp = previewUrl.filter((item) => {
        return item.name === name;
      });
      return temp[0]?.value;
    };

    useEffect(() => {
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, [action]);

    useEffect(() => {
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, [headerObj]);

    const handleSubmit = async () => {
      
      if ((await validate())) {
        setIsEdited(true);
      }
      var result = false;
      if (!(await validate())) {
        result = await handleAdd(Status.StatusTempSaved);
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }

      return false;
    };

    // const handleSubmitTemp = async () => {
    //   var result = false;
    //   var val = await validateTemp();

    //   console.log("temp", val);
    //   if (action === Action.ActionEdit) {
    //     if (IsEdited) {
    //       setIsEdited(false);
    //       // setIsEditedPage1(false);

    //       if (!val) {
    //         if (headerObj?.WPSH_Status === Status.StatusApproved) {
    //           result = handleAdd(Status.StatusApproved);
    //         }
    //         if (headerObj?.WPSH_Status === Status.StatusPending) {
    //           result = handleAdd(Status.StatusPending);
    //         } else {
    //           result = handleAdd(Status.StatusTempSaved);
    //         }

    //         if (result) {
    //           return MessageTypes.Temp;
    //         }
    //       }
    //       return MessageTypes.Required;
    //     }
    //     return MessageTypes.None;
    //   }
    //   if (!val) {
    //     result = handleAdd(Status.StatusTempSaved);

    //     if (result) {
    //       return MessageTypes.Temp;
    //     } else {
    //       return MessageTypes.Required;
    //     }
    //   }
    // };


    const handleSubmitTemp = async () => {
      debugger
      var result = false;
      var val = await validateTemp();
      console.log("valdd",val)

        if (IsEdited) {
          if (val.isError === false){
          if ( val.temperror === false) {
            setIsEdited(false);
            if (headerObj?.WPSH_Status === Status.StatusApproved) {
              result = handleAdd(Status.StatusApproved);
            } else if (headerObj?.WPSH_Status === Status.StatusPending) {
              result = handleAdd(Status.StatusPending);
            } else {
              result = handleAdd(Status.StatusTempSaved);
            }

            if (result) {
              return MessageTypes.Temp;
            }
    
          }
          return MessageTypes.SpecialCharacters;
        }
        return MessageTypes.Required;

        }
        return MessageTypes.None;
      

    
  }

    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        
        var res = false;
        res = await handleSubmit();
        return res;
        // return handleSubmit();
      },

      funcOnNextTemp: async () => {
        // return handleSubmitTemp()
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));

    const green = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "green" : "green",
      }),
    };


    const handleFile1 = (file,index, e) => {
      
      const maxSize = FilemaxSize
      if (file.size > maxSize) {
        alert("File size exceeds the allowed limit. Add File Less Than 1 Mb");
        file = null;
        return;
      }

      let uploadextension = file.name.split(".");
      console.log("fffnfifm", uploadextension);
      
      if (
        uploadextension[1] !== "jpeg" &&
        uploadextension[1] !== "png" &&
        uploadextension[1] !== "jpg"
      ) {
        console.log("nooooouploadeddddd");
        alert("Please Upload Jpeg,png or jpg images");
        file = null;
        return;
      }

      if(index == 0 ){
      setProfile(file);
      setPreviewUrl1(URL.createObjectURL(file));
      headerObj.Pdf_images[0].tube_Hole_ligament[0] = file.name;
      }
      else if(index == 1){
      setProfile1(file);
      setPreviewUrl2(URL.createObjectURL(file));
      headerObj.Pdf_images[1].tube_Hole[0] = file.name;
      }
      else if(index == 2){
      setProfile2(file);
      setPreviewUrl3(URL.createObjectURL(file));
      headerObj.Pdf_images[2].tube_Weld_Joint[0] = file.name;
      }
    };

    const fetchUserProfiles = async () => {
      
      try {
        if (headerObj?.Pdf_images[0].tube_Hole_ligament[0] !== "") {
          console.log(
            "getimage",
            headerObj?.Pdf_images[0].tube_Hole_ligament[0]
          );
          const result = await getPresignedURL(
            folderwpq,
            headerObj?.Pdf_images[0].tube_Hole_ligament[0]
          );
          console.log(result.url);
          const geturl = result.url;
          setPreviewUrl1(geturl);
          console.log("img", geturl);
          //await axios.get(geturl)
        }

        if (headerObj?.Pdf_images[1].tube_Hole[0] !== "") {
          console.log("getimage", headerObj?.Pdf_images[1].tube_Hole[0]);
          const result = await getPresignedURL(
            folderwpq,
            headerObj?.Pdf_images[1].tube_Hole[0]
          );
          console.log(result.url);
          const geturl = result.url;
          setPreviewUrl2(geturl);
          console.log("img", geturl);
          //await axios.get(geturl)
        }
        if (headerObj?.Pdf_images[2].tube_Weld_Joint[0] !== "") {
          console.log("getimage", headerObj?.Pdf_images[2].tube_Weld_Joint[0]);
          const result = await getPresignedURL(
            folderwpq,
            headerObj?.Pdf_images[2].tube_Weld_Joint[0]
          );
          console.log(result.url);
          const geturl = result.url;
          setPreviewUrl3(geturl);
          console.log("img", geturl);
          //await axios.get(geturl)
        }
      } catch (error) {
        if (error.request) {
          console.log("hii", error.request);
        } else {
          console.log("hiiiii", `Error: ${error.message}`);
        }
      }
    };
    useEffect(() => {
      fetchUserProfiles();
    }, []);

    return (
      <Row className="font">
        <Row style={{ padding: "20px 60px" }} className="WPQ-Page-row-padding">
          <Row className="mb-2">
            <Col md={3}>
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelectWelder
                    // className="inspdrop"
                    
                    id="WPQ_WelderId"
                    name="WPQ_WelderId"
                    className={error.WPQ_WelderId.status ? "err-input" : ""}
                    stylegreen={
                      error.WPQ_WelderId.status ? errorstyle : normalstyle
                    }
                    data={WelderActive}
                    stylenormal={normalstyle}
                    valueField={"wld_salarycode"}
                    disabled={action === Action.ActionEdit ? true : false}
                    value={headerObj?.WPQ_WelderId || ""}
                    labelField1={"wld_salarycode"}
                    labelField2={"wld_name"}
                    placeholder=""
                    onChange={(List) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_WelderId: List.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WelderId: {},
                      }));

                      console.log("ItemList on", List, Welder, headerObj);
                      let a = WelderActive.filter((b) => {
                        return b.wld_salarycode == List.value;
                      });
                      console.log("a", a);
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_WelderId: a[0].wld_salarycode,
                        WPQ_WelderName: a[0].wld_name,
                      }));
                    }}
                  ></SearchSelectWelder>
                </div>
                <label>Welder Id</label>
                <div className="required-text-select">
                  {error.WPQ_WelderId.status && (
                    <Form.Text className="text-danger">
                      {error.WPQ_WelderId.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPQ_WelderName"
                    name="WPQ_WelderName"
                    readOnly
                    placeholder=" "
                    disabled={action === Action.ActionEdit ? true : false}
                    maxLength={20}
                    value={headerObj?.WPQ_WelderName}
                    onChange={(e) => {
                      handleChange(e);
                      if (action === Action.ActionCopy) {
                        setIsReadonly(false);
                      }
                    }}
                  />
                  <label for="WPQ_WelderName">Welder Name</label>
                </div>
              </Form.Group>
            </Col>

            <Col md={3}>
              <div className="groove_date">
                <div class="T-input w-100">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Welding Date"
                        className="groove_datecolor"
                        format="DD-MM-YYYY"
                        name="WPQ_Date"
                        sx={{ fontSize: "6px", fontFamily: "Work Sans" }}
                        // disabled={isDisabled}
                        readOnly={isReadonly}
                        value={
                          headerObj?.WPQ_Date
                            ? dayjs(headerObj?.WPQ_Date)
                            : dayjs(todayDate)
                        }
                        maxDate={dayjs(maxDate)}
                        onChange={(e) => {
                          let date = dayjs(e).format("YYYY-MM-DD");
                          setheaderObj((prev) => ({
                            ...prev,
                            WPQ_Date: date,
                          }));

                          setError((prevState) => ({
                            ...prevState,
                            WPQ_Date: {
                              status: false,
                              message: "",
                            },
                          }));
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="required-text-select mt-2">
                {error.WPQ_Date.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Date.message}
                  </Form.Text>
                )}
              </div>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_RecordNo"
                  type="text"
                  maxLength={20}
                  status={error.WPQ_RecordNo.status}
                  disabled={action === Action.ActionEdit ? true : false}
                  value={headerObj?.WPQ_RecordNo}
                  handleChange={handleChange}
                  readOnly={isReadonly}
                  label="WPQ Record Number"
                  message={error.WPQ_RecordNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_RecordNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Wps_RecordNo"
                  type="text"
                  maxLength={15}
                  readOnly={isReadonly}
                  status={error.WPQ_Wps_RecordNo.status}
                  value={headerObj?.WPQ_Wps_RecordNo}
                  handleChange={handleChange}
                  label="WPS Record Number"
                  message={error.WPQ_Wps_RecordNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Wps_RecordNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  name="WPQ_ReviNo"
                  type="text"
                  maxLength={10}
                  status={error.WPQ_ReviNo.status}
                  readOnly={isReadonly}
                  value={headerObj?.WPQ_ReviNo}
                  handleChange={handleChange}
                  label="Rev.No"
                  message={error.WPQ_ReviNo.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_ReviNo: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3} className="mb-1">
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelect
                    required
                    data={InspecAgencyActive}
                    stylegreen={
                      error.WPQ_Inspection.status ? errorstyle : normalstyle
                    }
                    disabled={isDisabled}
                    valueField={"wld_code"}
                    value={headerObj?.WPQ_Inspection}
                    labelField={"wld_code"}
                    placeholder=""
                    className={error.WPQ_Inspection.status ? "err-input" : ""}
                    onChange={(pnoList) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_Inspection: pnoList.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Inspection: {},
                      }));
                    }}
                  ></SearchSelect>
                </div>
                <label>Inspection Agency</label>
              </Form.Group>
              <div className="required-text-select">
                {error.WPQ_Inspection.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Inspection.message}
                  </Form.Text>
                )}
              </div>
            </Col>

            <Col md={3}>
              <div className="groove_date">
                <div class="T-input w-100">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Date Of Birth"
                        className="groove_datecolor"
                        format="DD-MM-YYYY"
                        name="WPQ_DOB"
                        readOnly={isReadonly}
                        sx={{ fontSize: "6px", fontFamily: "Work Sans" }}
                        value={
                          headerObj?.WPQ_DOB
                            ? dayjs(headerObj?.WPQ_DOB)
                            : dayjs(todayDate)
                        }
                        maxDate={dayjs(maxDate)}
                        onChange={(e) => {
                          let date = dayjs(e).format("YYYY-MM-DD");
                          setheaderObj((prev) => ({
                            ...prev,
                            WPQ_DOB: date,
                          }));

                          setError((prevState) => ({
                            ...prevState,
                            WPQ_DOB: {
                              status: false,
                              message: "",
                            },
                          }));
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="required-text-select mt-2">
                {error.WPQ_DOB.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_DOB.message}
                  </Form.Text>
                )}
              </div>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Qualificationcode"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.WPQ_Qualificationcode.status}
                  value={headerObj?.WPQ_Qualificationcode}
                  handleChange={handleChange}
                  label="Qualification Code"
                  message={error.WPQ_Qualificationcode.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Qualificationcode: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Date_Input
                divClass="T-input w-100"
                label="Last Updated Date"
                className="groove_datecolor"
                name="WPQ_Last_UpdatedDate"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                maxDate={maxDate}
                // disabled={Readonly}
                readOnly={isReadonly}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
                //   setError ={setError((prevState) => ({
                //     ...prevState,
                //     WPSH_WPS_Date: {
                //       status: false,
                //       message: "",
                //     },
                //   }))
                // }
                status={error.WPQ_Last_UpdatedDate.status}
                message={error.WPQ_Last_UpdatedDate.message}
                // datevalue={datevalue}
              />
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_Last_UpdatedBy"
                  type="text"
                  readOnly={isReadonly}
                  value={
                    action === Action.ActionEdit
                      ? headerObj?.WPQ_Last_UpdatedBy
                      : username
                  }
                  handleChange={handleChange}
                  label="Last Updated By"
                ></Single_Input>
              </Form.Group>
            </Col>

            <Col md={3} className="mb-1">
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelect
                    required
                    data={WeldedCoupon}
                    stylegreen={
                      error.WPQ_Welded_on_coupon.status
                        ? errorstyle
                        : normalstyle
                    }
                    readOnly={isReadonly}
                    disabled={isDisabled}
                    valueField={"WPQ_Welded_on_coupon"}
                    value={headerObj?.WPQ_Welded_on_coupon}
                    labelField={"WPQ_Welded_on_coupon"}
                    placeholder=""
                    className={
                      error.WPQ_Welded_on_coupon.status ? "err-input" : ""
                    }
                    onChange={(pnoList) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPQ_Welded_on_coupon: pnoList.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Welded_on_coupon: {},
                      }));
                    }}
                  ></SearchSelect>
                </div>
                <label>Welded On Coupon</label>
              </Form.Group>
              <div className="required-text-select">
                {error.WPQ_Welded_on_coupon.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Welded_on_coupon.message}
                  </Form.Text>
                )}
              </div>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  readOnly
                  name="WPQ_CompanyName"
                  type="text"
                  value={company_name[0]?.value}
                  label="Company name"
                ></Single_Input>
              </Form.Group>
            </Col>
            {/* <Col md={3}>
              <Form.Group>
                <Single_Input
                  readOnly
                  name="WPQ_Division"
                  type="text"
                  value={division[0]?.value}
                  label="Division"
                ></Single_Input>
              </Form.Group>
            </Col> */}

<Col md={3}>
              <Form.Group>
                <General_Single_Input_select
                  name="WPQ_Division"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  disabled={isDisabled}
                  status={error.WPQ_Division.status}
                  value={headerObj?.WPQ_Division}
                  handleSelectChange={handleChange}
                  label="Division"
                  message={error.WPQ_Division.message}
                  array={Plant_Location_GO}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="form-switch-input">
                <Row>
                  <Col>
                    {" "}
                    <div class="T-input"></div>{" "}
                    <label
                      style={{
                        fontSize: "14px",
                        padding: "10px",
                        color: "var(--col-707070)",
                        fontWeight: "bold",
                      }}
                    >
                      Status{" "}
                    </label>
                  </Col>
                  <Col className="ml-8" style={{position:"relative",right:"50px"}}>
                    <Form.Switch
                      label={headerObj?.WPQ_Status ? "Active" : "Inactive"}
                      className={headerObj?.WPQ_Status ? "green" : "gray"}
                      name="WPQ_Status"
                      style={{ padding: "10px" }}
                      checked={headerObj?.WPQ_Status}
                      // onChange={(e) => {
                      //   handleSwitchChange(e);
                      // }}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

           
          </Row>
          <Row className="row-margin mt-1">
            <Col md={3}>
              <Form.Group>
                <General_Single_Input_select
                  name="WPQ_PlantLocation"
                  type="text"
                  disabled={isDisabled}
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.WPQ_PlantLocation.status}
                  value={headerObj?.WPQ_PlantLocation}
                  handleSelectChange={handleChange}
                  label="Plant Location"
                  message={error.WPQ_PlantLocation.message}
                  array={Plant_Location}
                ></General_Single_Input_select>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_specialNote"
                  type="text"
                  maxLength={20}
                  readOnly={isReadonly}
                  status={error.WPQ_specialNote.status}
                  value={headerObj?.WPQ_specialNote}
                  handleChange={handleChange}
                  label="Special Notes"
                  message={error.WPQ_specialNote.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_specialNote: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>

           
          </Row>
        </Row>

        <Row style={{ background: "white", padding: "20px 60px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text ">Base Metal (QW-403) </p>
              {console.log("Error....", error)}

              <BaseMetalTableTTS
                headerObj={headerObj}
                setheaderObj={setheaderObj}
                error={error}
                isReadonly={isReadonly}
                isDisabled={isDisabled}
                handleChange={handleChange}
                setError={setError}
                PNoActive={PNoActive}
                blyestyle={blyestyle}
                errorstyle={errorstyle}
              ></BaseMetalTableTTS>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_BM_RangeQualified_Thk"
                  type="text"
                  maxLength={17}
                  readOnly={isReadonly}
                  status={error.WPQ_BM_RangeQualified_Thk.status}
                  value={headerObj?.WPQ_BM_RangeQualified_Thk}
                  handleChange={handleChange}
                  label="Range Qualified Tube Thickness"
                  message={error.WPQ_BM_RangeQualified_Thk.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_BM_RangeQualified_Thk: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_BM_RangeQualified_Dia"
                  type="text"
                  maxLength={17}
                  readOnly={isReadonly}
                  status={error.WPQ_BM_RangeQualified_Dia.status}
                  value={headerObj?.WPQ_BM_RangeQualified_Dia}
                  handleChange={handleChange}
                  label="Range Qualified Tube Diameter"
                  message={error.WPQ_BM_RangeQualified_Dia.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_BM_RangeQualified_Dia: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
          </Row>
        </Row>

        <Row style={{padding: "20px 60px" }} className="WPQ-Page-row-padding">
          <Row className="mb-1">
            <p className="WPS-blue-text">Joint Detail</p>

            <Col md={6}>
              {console.log("Er", error)}
              {/* {console.log("Er1", error.WPQ_Joint_Type[0].status)} */}

              <Wpq_JointDetail_table1A
                headerObj={headerObj}
                name="WPQ_Joint_Type"
                name1="WPQ_Hole_Id"
                name2="WPQ_Tube_Pitch"
                name3="WPQ_Groove_Depth"
                readOnly={isReadonly}
                maxLength={12}
                handleActualValueChange={handleActualValueChange}
                handleRangeQualifiedChange={handleRangeQualifiedChange}
                ColumnHeader="Joint Type"d
                ColumnHeader1="Hole ID mm"
                ColumnHeader2="Tube Pitch mm"
                ColumnHeader3="Groove Depth mm"
                status={error.WPQ_Joint_Type[0]?.Actual_Value[0]?.status}
                message={error.WPQ_Joint_Type[0]?.Actual_Value[0]?.message}
                status1={error.WPQ_Joint_Type[1]?.Range_Qualified[0]?.status}
                message1={error.WPQ_Joint_Type[1]?.Range_Qualified[0]?.message}
                status2={error.WPQ_Hole_Id[0]?.Actual_Value[0]?.status}
                message2={error.WPQ_Hole_Id[0]?.Actual_Value[0]?.message}
                status3={error.WPQ_Hole_Id[1]?.Range_Qualified[0]?.status}
                message3={error.WPQ_Hole_Id[1]?.Range_Qualified[0]?.message}
                status4={error.WPQ_Tube_Pitch[0]?.Actual_Value[0]?.status}
                message4={error.WPQ_Tube_Pitch[0]?.Actual_Value[0]?.message}
                status5={error.WPQ_Tube_Pitch[1]?.Range_Qualified[0]?.status}
                message5={error.WPQ_Tube_Pitch[1]?.Range_Qualified[0]?.message}
                status6={error.WPQ_Groove_Angle[0]?.Actual_Value[0]?.status}
                message6={error.WPQ_Groove_Angle[0]?.Actual_Value[0]?.message}
                status7={error.WPQ_Groove_Angle[1]?.Range_Qualified[0]?.status}
                message7={
                  error.WPQ_Groove_Angle[1]?.Range_Qualified[0]?.message
                }
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Joint_Type: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
                onInput1={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Hole_Id: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
                onInput2={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Tube_Pitch: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
                onInput3={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Groove_Angle: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
               
              />
            </Col>
            <Col md={6}>
              {console.log("Error....", error)}
              <Wpq_JointDetail_table1B
                headerObj={headerObj}
                name="WPQ_Tube_Projection"
                name1="WPQ_Width_Ligament"
                name2="WPQ_Pattern"
                name3="WPQ_Groove_Angle"
                maxLength={12}
                readOnly={isReadonly}
                handleActualValueChange={handleActualValueChange}
                handleRangeQualifiedChange={handleRangeQualifiedChange}
                ColumnHeader="Tube Projection"
                ColumnHeader1="Width of Ligament mm"
                ColumnHeader2="Pattern"
                ColumnHeader3="Groove Angle(°)/Radius mm"
                status={error.WPQ_Tube_Projection[0]?.Actual_Value[0]?.status}
                message={error.WPQ_Tube_Projection[0]?.Actual_Value[0]?.message}
                status1={
                  error.WPQ_Tube_Projection[1]?.Range_Qualified[0]?.status
                }
                message1={
                  error.WPQ_Tube_Projection[1]?.Range_Qualified[0]?.message
                }
                status2={error.WPQ_Width_Ligament[0]?.Actual_Value[0]?.status}
                message2={error.WPQ_Width_Ligament[0]?.Actual_Value[0]?.message}
                status3={
                  error.WPQ_Width_Ligament[1]?.Range_Qualified[0]?.status
                }
                message3={
                  error.WPQ_Width_Ligament[1]?.Range_Qualified[0]?.message
                }
                status4={error.WPQ_Pattern[0]?.Actual_Value[0]?.status}
                message4={error.WPQ_Pattern[0]?.Actual_Value[0]?.message}
                status5={error.WPQ_Pattern[1]?.Range_Qualified[0]?.status}
                message5={error.WPQ_Pattern[1]?.Range_Qualified[0]?.message}
                status6={error.WPQ_Groove_Depth[0]?.Actual_Value[0]?.status}
                message6={error.WPQ_Groove_Depth[0]?.Actual_Value[0]?.message}
                status7={error.WPQ_Groove_Depth[1]?.Range_Qualified[0]?.status}
                message7={
                  error.WPQ_Groove_Depth[1]?.Range_Qualified[0]?.message
                }
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Tube_Projection: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
                onInput1={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(
                    e.target.value
                  );
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Width_Ligament: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
                onInput2={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Pattern: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
                onInput3={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Groove_Depth: [
                      { Actual_Value: [error] },
                      { Range_Qualified: [error] },
                    ],
                  }));
                }}
              />
            </Col>
          </Row>
        </Row>
        <Row style={{padding: "20px 60px" }} className="WPQ-Page-row-padding">
          <div style={{display:"flex"}}>
          <span className="WPS-blue-text" style={{position:"relative" , right:"13px"}}>Tube Hole Ligament Detail</span>

            <span className="WPS-blue-text"  style={{position:"relative" , left:"173px"}}>Tube Hole Detail</span>
         
            <span className="WPS-blue-text"  style={{position:"relative" , left:"426px"}}> Tube to Tubesheet Weld Joint Detail </span>
          </div>
          <WPS_Image
            handleFile={handleFile1}
            previewUrl1={previewUrl1}
            previewUrl2={previewUrl2}
            previewUrl3={previewUrl3}
            headerObj={headerObj}
            setheaderObj={setheaderObj}
            getImgURL={getImgURL}
            imgObj={imgObj}
            name="fileInput"
            name1="fileInput1"
            name2="fileInput2"
            name3="fileInput3"
            type="file"
            accept="image/*"
            fileInputRef={fileInputRef}
            readOnly={Readonly}
            disabled={isDisabled}
          ></WPS_Image>
        </Row>

        <Row style={{ padding: "20px 47px" }}>
          <Col md={12}>
            <p className="WPS-blue-text">Filler Metals (QW-404)</p>
            <WPQ_Filler_metals
              headerObj={headerObj}
              detailObj={detailObj}
              isReadonly={isReadonly}
              disabled={isDisabled}
              error={error}
              readOnly={Readonly}
              handleChangedetailsarray={handleChangedetailsarray}
              handleChangeFillerMetal={handleChangeFillerMetal}
              setError={setError}
              setdetailObj={setdetailObj}
              SfaActive={SfaActive}
              AnoActive={AnoActive}
              FnoActive={FnoActive}
              classError={classError}
              setClassError={setClassError}
              chemicalAnaError={chemicalAnaError}
              setChemicalAnaError={setChemicalAnaError}
              isOverlay={isOverlay}
              setOverlay={setOverlay}
              Grooveclass={Grooveclass}
              setoverlayclass={setoverlayclass}
              overlaytext={overlaytext}
              action={action}
              setaction={setaction}
            ></WPQ_Filler_metals>
          </Col>
        </Row>

        <Row style={{ background: "white", padding: "0px 60px" }}>
          <Row className="mb-1">
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_FM_PreFillerMetal"
                  type="text"
                  maxLength={4}
                  readOnly={isReadonly}
                  status={error.WPQ_FM_PreFillerMetal.status}
                  value={headerObj?.WPQ_FM_PreFillerMetal}
                  handleChange={handleChange}
                  label="Preplaced Filler Metal"
                  message={error.WPQ_FM_PreFillerMetal.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_PreFillerMetal: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_FM_WithFillerMetal"
                  type="text"
                  maxLength={7}
                  readOnly={isReadonly}
                  status={error.WPQ_FM_WithFillerMetal.status}
                  value={headerObj?.WPQ_FM_WithFillerMetal}
                  handleChange={handleChange}
                  label="With/ Without Filler Metal"
                  message={error.WPQ_FM_WithFillerMetal.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_WithFillerMetal: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_FM_FillerProduct"
                  type="text"
                  maxLength={6}
                  readOnly={isReadonly}
                  status={error.WPQ_FM_FillerProduct.status}
                  value={headerObj?.WPQ_FM_FillerProduct}
                  handleChange={handleChange}
                  label="Filler Metal Product Form"
                  message={error.WPQ_FM_FillerProduct.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_FillerProduct: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_FM_Inserts"
                  type="text"
                  maxLength={4}
                  readOnly={isReadonly}
                  status={error.WPQ_FM_Inserts.status}
                  value={headerObj?.WPQ_FM_Inserts}
                  handleChange={handleChange}
                  label="Inserts"
                  message={error.WPQ_FM_Inserts.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_Inserts: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
            <Col md={9}>
              <Form.Group>
                <Single_Input
                  // disabled={copydata}
                  name="WPQ_FM_FillerOthers"
                  type="text"
                  maxLength={60}
                  readOnly={isReadonly}
                  status={error.WPQ_FM_FillerOthers.status}
                  value={headerObj?.WPQ_FM_FillerOthers}
                  handleChange={handleChange}
                  label="Filler Metals - Others"
                  message={error.WPQ_FM_FillerOthers.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_FM_FillerOthers: error,
                    }));
                  }}
                ></Single_Input>
              </Form.Group>
            </Col>
          </Row>
        </Row>
      </Row>
    );
  }
);

export default WPQTubesheetPage1;
