import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "users";

export async function getAllRoles() {
  
  const response = await axios
    .get(API_URL + "/role")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function AddNewRole(role) {
  
  const response = await axios
    .post(`${API_URL}/role`, role)
    .then(function (resp) {
      
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function UpdateRole(role) {
  const response = await axios
    .post(`${API_URL}/role/updaterole`, role)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getRoleDescByRoleId(roleid) {
  let url = `${API_URL}/role/getRoleDescByRoleId`;
  const response = await axios
    .post(url,{Role:roleid})
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function deleteRole(userid) {
  axios
    .post(`${API_URL}/role/deleterole/${userid}`)
    .then((res) => {
      if (res.status === 200) {
        alert("Role is deleted");
      }
    })
    .catch((e) => {
      e = alert("Something went wrong.");
    });
}
