export function getInitials(userName) {
  if (userName) {
    var names = userName.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export function getCurrentDateTime(separator = "/") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let seconds = newDate.getSeconds();
  date = date < 10 ? "0" + date : date;
  month = month < 10 ? "0" + month : month;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${year}${separator}${month}${separator}${date} ${hours}:${minutes}:${seconds}`;
}

export function formatDateDD_MM_YYYY(input) {
  let input1 = new Date(input);
  const newDate = `${pad2(input1.getDate())}-${pad2(
    input1.getMonth() + 1
  )}-${pad2(input1.getFullYear())}`;
  return newDate;
}

export function dateCompare(date1, date2) {
  var date1Updated = new Date(date1.replace(/-/g, "/"));
  var date2Updated = new Date(date2.replace(/-/g, "/"));
  return date1Updated < date2Updated;
}

export function removeduplicate(input) {
  return input
    .split(",")
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    })
    .join(",");
}

export function formatDate(input) {
  if (input !== "" && input !== null && input.includes("T")) {
    let input1 = new Date(input);
    const newDate = `${pad2(input1.getFullYear())}-${pad2(
      input1.getMonth() + 1
    )}-${pad2(input1.getDate())}`;

    return newDate;
  } else return input;
}

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

export function getDefaultDate() {
  const current = new Date();
  const defaultDate = `${pad2(current.getFullYear())}-${pad2(
    current.getMonth() + 1
  )}-${pad2(current.getDate())}`;

  return defaultDate;
}

export const FormatYYYYMMDD = (date) => {
 let dates=  date.split("/")
 return dates[2] + "-" + dates[1] + "-" + dates[0]
}

export const DateDiff = (Date1,Date2)=>{
  let startDate = new Date(Date1)

  
  let endDate = new Date(Date2)
  let Difference_In_Time =
  endDate.getTime() - startDate.getTime();
  
  // Calculating the no. of days between
  // two dates
  let Difference_In_Days =
      Math.round
          (Difference_In_Time / (1000 * 3600 * 24));

          console.log("Difference_In_Time",Difference_In_Time,"Days",Difference_In_Days)
          return Difference_In_Days

}

export const getTodayDateDDMMYYYY = ()=>{
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const todayDate1 = dd + "/" + mm + "/" + yyyy;
  return todayDate1
}


// export const FilterChange = (param, setFilteredCol, headerName, setFilteredData, filteredData) => {
export const FilterChange = (param, setFilteredCol, headerName) => {
  let a = document.getElementById("filterDisplay");
  setFilteredCol([]);
  let col = param.api.getFilterModel();
  console.log("col", col);

  if (param.api.getDisplayedRowCount() === 0) {
    param.api.showNoRowsOverlay();
  } else {
    param.api.hideOverlay();
  }
  if (Object.keys(col).length === 0) {
    a.classList.add("d-none");
  } else {
    a.classList.remove("d-none");
    for (const key of Object.keys(col)) {
      let filter;
      if (col[key].filterType === "date") {
        if (col[key].condition1) {
          let fromDate1 = col[key].condition1.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
            : col[key].condition1.dateFrom;
          let toDate1 = col[key].condition1.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
            : col[key].condition1.dateTo;
          let fromDate2 = col[key].condition2.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
            : col[key].condition2.dateFrom;
          let toDate2 = col[key].condition2.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
            : col[key].condition2.dateTo;
          filter =
            headerName(key) +
            " : " +
            fromDate1 +
            (toDate1 ? " To " + toDate1 : "") +
            ", " +
            fromDate2 +
            (toDate2 ? " To " + toDate2 : "");
          console.log("true date");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].dateFrom?.substring(0, 10) +
            (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
          console.log("false date", col[key].dateFrom?.substring(0, 10));
        }
      } else if (col[key].filterType === "number") {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            (col[key].condition1.filterTo
              ? " To " + col[key].condition1.filterTo
              : "") +
            ", " +
            col[key].condition2.filter +
            (col[key].condition2.filterTo
              ? " To " + col[key].condition2.filterTo
              : "");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].filter +
            (col[key].filterTo ? " To " + col[key].filterTo : "");
        }
      } else {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
      }
      console.log("SF ", filter);
      setFilteredCol((prev) => {
        return [...prev, filter];
      });
    }
  }
};

export const ExportFilterData = (
  param1,
  setFilteredCol1,
  headerName1,
  setFilteredData,
  filteredData
) => {
  let a = document.getElementById("filterDisplay");
  setFilteredCol1([]);
  let col = param1.api.getFilterModel();
  console.log("col", col);

  filteredData.splice(0, filteredData.length);
  console.log("SFD ", filteredData);
  param1.api.forEachNodeAfterFilter((node) => {
    filteredData.push(node.data);
  });
  setFilteredData(filteredData);
  console.log("Row data ", filteredData);

  if (param1.api.getDisplayedRowCount() === 0) {
    param1.api.showNoRowsOverlay();
  } else {
    param1.api.hideOverlay();
  }
  if (Object.keys(col).length === 0) {
    a.classList.add("d-none");
  } else {
    a.classList.remove("d-none");
    for (const key of Object.keys(col)) {
      let filter;
      if (col[key].filterType === "date") {
        if (col[key].condition1) {
          let fromDate1 = col[key].condition1.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
            : col[key].condition1.dateFrom;
          let toDate1 = col[key].condition1.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
            : col[key].condition1.dateTo;
          let fromDate2 = col[key].condition2.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
            : col[key].condition2.dateFrom;
          let toDate2 = col[key].condition2.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
            : col[key].condition2.dateTo;
          filter =
            headerName1(key) +
            " : " +
            fromDate1 +
            (toDate1 ? " To " + toDate1 : "") +
            ", " +
            fromDate2 +
            (toDate2 ? " To " + toDate2 : "");
        } else {
          filter =
            headerName1(key) +
            " : " +
            col[key].dateFrom?.substring(0, 10) +
            (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
        }
      } else if (col[key].filterType === "number") {
        if (col[key].condition1) {
          filter =
            headerName1(key) +
            " : " +
            col[key].condition1.filter +
            (col[key].condition1.filterTo
              ? " To " + col[key].condition1.filterTo
              : "") +
            ", " +
            col[key].condition2.filter +
            (col[key].condition2.filterTo
              ? " To " + col[key].condition2.filterTo
              : "");
        } else {
          filter =
            headerName1(key) +
            " : " +
            col[key].filter +
            (col[key].filterTo ? " To " + col[key].filterTo : "");
        }
      } else {
        if (col[key].condition1) {
          filter =
            headerName1(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName1(key) + " : " + col[key].filter;
        }
      }
      console.log("SF ", filter);
      setFilteredCol1((prev) => {
        return [...prev, filter];
      });
    }
  }
};

export const resetAllFilter = (gridRef) => {
  gridRef.current.api.setFilterModel(null);
};

export function resetFilter(gridRef, item, feildName) {
  let item1 = item.substring(0, item.indexOf(":") - 1);
  let col = gridRef.current.api.getFilterModel();
  for (const key of Object.keys(col)) {
    if (key === feildName(item1)) {
      delete col[key];
    }
  }
  gridRef.current.api.setFilterModel(col);
}

export const getTrainingSts = (fromDate, toDate, todayDate) => {
  let fromDateUpdated = new Date(fromDate.replace(/-/g, "/"));
  let toDateUpdated = new Date(toDate.replace(/-/g, "/"));
  let todayDateUpdated = new Date(todayDate.replace(/-/g, "/"));
  let status = "";
  if (fromDateUpdated <= todayDateUpdated && toDateUpdated >= todayDateUpdated)
    status = "In Progress";
  else if (todayDateUpdated < fromDateUpdated) status = "Upcoming";
  else if (todayDateUpdated > toDateUpdated) status = "Completed";
  return status;
};

export const formatDateDDMMYYYY = (date) => {
  if (date !== "" && date !== "undefined" && date !== undefined) {
    console.log("date_sg", date);
    date = date.split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  } else {
    return "";
  }
};

export const weldReqFormat = (date) => {
  if (date !== "") {
    date = date.split("-");
    return date[0] + "/" + date[1];
  } else {
    return "";
  }
};

export function formatDateTimeAMPM(newDate1) {
  console.log("Newwwww", newDate1)
  
  const month1 = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let newDate = new Date(newDate1);
  let date = newDate.getDate();
  let monthname = month1[newDate.getMonth()];
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  date = date < 10 ? "0" + date : date;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'

  let strTime = hours + ":" + minutes + " " + ampm;

  if (date === "01") {
    date = date + "st";
  } else if (date === "02") {
    date = date + "nd";
  } else if (date === "03") {
    date = date + "rd";
  } else {
    date = date + "th";
  }
  return `${date} ${monthname} ${"|"} ${strTime}`;
}
