import { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Tabs, Tab, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  getbyprojectno,
  getPQRonEdit,
} from "../../Services/pqr-header-service";
import { MessageTypes, PQRHeader_Tube } from "./PQRObjects/PQR_entity";
import { ToastContainer, toast } from "react-toastify";
import { Action, Status } from "./PQRObjects/PQR_entity";
import { getPQRDetailsEdit } from "../../Services/pqr-details-service";
import PQRTubesheetPage1 from "./PQRTubesheetPage1";
import PQRTubesheetPage2 from "./PQRTubesheetPage2";
import PQRTubesheetPage3 from "./PQRTubesheetPage3";
const PQRTubeSheetTransaction = () => {
  const [key, setKey] = useState(1);

  const firstChildRef = useRef();
  const secondChildRef = useRef();
  const thirdChildRef = useRef();

  const location = useLocation();

  let companyname = "Godrej & Boyce Mfg. Co. Ltd";
  let UserName = sessionStorage.getItem("UserName");
  let UserId = sessionStorage.getItem("UserId");

  const [copydata, setcopydata] = useState("");
  const [action, setaction] = useState(location.state?.action);
  const [originalaction, setoriginalaction] = useState(location.state?.action);
  const [WPStype, setWPStype] = useState(location.state?.WPStype);

  const [headerObj, setheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : PQRHeader_Tube(WPStype)
  );

  const [editheaderObj, seteditheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : PQRHeader_Tube(WPStype)
  );


  let Band = sessionStorage.getItem("Band");


  let initiator = Band !== "P" && Band !== "T";


  var readonly =
    location.state?.action === Action.ActionCopy ||
    (initiator && headerObj?.WPSH_Status === Status.StatusPending)
      ? true
      : false;

  var disableButton =
    initiator && headerObj?.WPSH_Status === Status.StatusPending ? true : false;
  const [isDisabled, setIsDisabled] = useState(disableButton);

  const [isReadonly, setIsReadonly] = useState(readonly);

  let Message = MessageTypes.Message;

  const [detailObj, setdetailObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : {
          SK: "",
          PK: "",
          PQR_Process: "GTAW",
          WPSD_FM_SFA: "",
          WPSD_FM_Classification: "",
          WPSD_FM_FNo: "",
          WPSD_FM_Ano: "",
          WPSD_FM_ChemicalAnalysis_TradeName: "",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
          PQRD_WP_WeldingProcess: "GTAW",
          PQRD_WP_Type: "",
          PQRD_WP_MinimumPreheatTemp: "",
          PQRD_WP_MaximumInterpassTemp: "",
          PQRD_WP_PostHeating: "",
          PQRD_WP_TungstenType: "",
          PQRD_WP_WeldPosition: "",
          PQRD_WP_WeldProgression: "",
          PQRD_WP_NoofPasses: "",

          PQRD_WP_ArcVoltageControl: "",
          PQRD_WP_ShieldingGasType: "",
          PQRD_WP_ShieldingFlowRate: "",

          PQRD_WP_Auxiliarygas: "",
          PQRD_WP_Expansion: "",
          PQRD_WP_MethodofCleaning: "",
          PQRD_WP_LayerNo: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_FillerMetalSize: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_Amperes: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_DC_Pulsing_current: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_DC_Pulsing_current_Others: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_Volatage_Volts: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_Travel_Speed: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_Heat_Input: [{ Value_A: [""] }, { Value_B: [""] }],
          PQRD_WP_PolarityOrCurrent: [{ Value_A: [""] }, { Value_B: [""] }],

        }
  );

  const [error, setError] = useState({
    PQR_RecordNo: {},
    PQR_Date: {},
    PQR_Qualificationcode: {},
    PQR_pWPSNo: {},
    PQR_WPS_ReviNo: {},
    PQR_LastUpdate_Date: {},
    PQR_Last_UpdatedBy: {},
    PQR_MatchStatus: {},
    PQR_Last_WeldDate: {},
    PQR_Address: {},

    PQR_BM_BM_ProductForm: {},
    PQR_BM_BM_Spec: {},
    PQR_BM_BM_Pno: {},
    PQR_BM_BM_Gno: {},
    PQR_BM_BM_Thk: {},
    PQR_BM_BM_Dia: {},

    PQR_BM_WTBM_ProductForm: {},
    PQR_BM_WTBM_Spec: {},
    PQR_BM_WTBM_Pno: {},
    PQR_BM_WTBM_Gno: {},
    PQR_BM_WTBM_Thk: {},
    PQR_BM_WTBM_Dia: {},

    PQR_JD_JointType: {},
    PQR_JD_TubePitch: {},
    PQR_JD_TubeProj: {},
    PQR_JD_Pattern: {},
    PQR_JD_GrooveAngle: {},
    PQR_JD_Notes: {},

    wld_pdf_image: {},
    PQR_Image_Notes: {},

    PQR_Process: {},
    WPSD_FM_SFA: {},
    WPSD_FM_Classification: {},
    WPSD_FM_FNo: {},
    WPSD_FM_Ano: {},
    WPSD_FM_ChemicalAnalysis_TradeName: {},

    // PQRD_WP_WeldingProcess: {},
    PQRD_WP_MinimumPreheatTemp: {},
    PQRD_WP_MaximumInterpassTemp: {},
    PQRD_WP_PostHeating: {},
    PQRD_WP_TungstenType: {},
    PQRD_WP_WeldPosition: {},
    PQRD_WP_WeldProgression: {},
    PQRD_WP_NoofPasses: {},

    PQRD_WP_ArcVoltageControl: {},
    PQRD_WP_ShieldingGasType: {},
    PQRD_WP_ShieldingFlowRate: {},
    PQRD_WP_Auxiliarygas: {},
    PQRD_WP_Expansion: {},
    PQRD_WP_MethodofCleaning: {},

    PQR_PWHeatTre_Temperature: {},
    PQR_PWHeatTre_Time: {},
    PQR_PWHeatTre_Type: {},
    PQR_PWHeatTre_Heating_Rate: {},
    PQR_PWHeatTre_Cooling_Rate: {},
    PQR_PWHeatTre_Notes: {},

    PQR_Certification_Welder_Name: {},
    PQR_Certification_ID: {},
    PQR_Certification_Process: {},
    PQR_Certification_ConductedBy: {},
    PQR_Certification_Mechanical_TestsBy: {},
    PQR_Certification_Lab_RefNo: {},

    PQRD_WP_LayerNo: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_FillerMetalSize: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_Amperes: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_DC_Pulsing_current: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_DC_Pulsing_current_Others: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_Volatage_Volts: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_Travel_Speed: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_Heat_Input: [{ Value_A: [""] }, { Value_B: [""] }],
    PQRD_WP_PolarityOrCurrent: [{ Value_A: [""] }, { Value_B: [""] }],

  });

  const [testObj, setTestObj] = useState([]);

  const Visual_Examination = [];
  const Liquid_Penetrant_Examination = [];
  const Macro_Examination = [
    { Table6: [], Table7: [], Table8: [], Table9: [], Table10: [] },
  ];
  const Other_Test = [];
  const Radiography_Test = [];
  const Minimum_Leak_Path = [
    { Table1: [], Table2: [], Table3: [], Table4: [], Table5: [] },
  ];

  const PullOut_Test = [];
  const PullOut_Test_Table = [];
  const Tear_Test = [];
  const Hardness_Test = [];
  const Certification=[];

  useEffect(() => {
    if (action === Action.ActionAdd) {
      let Visual_Examination
      let Liquid_Penetrant_Examination
      let Macro_Examination
      let Minimum_Leak_Path
      let Tear_Test
      let PullOut_Test
      let PullOut_Test_Table
      let Hardness_Test
      let Radiography_Test
      let Other_Test
      let Certification



       Visual_Examination = Visual_Examination_Obj(1, 4);
       Liquid_Penetrant_Examination = Liquid_Penetrant_Examination_Obj(1, 4);
       Macro_Examination = Macro_Examination_Obj(1, 4);

      Minimum_Leak_Path = Minimum_Leak_Path_Obj(1, 4);
      Tear_Test = Tear_Test_Obj(1, 4);
      PullOut_Test = PullOut_Test_Obj(1, 4);
      PullOut_Test_Table = PullOut_Test_Table_Obj(1, 4);
      Hardness_Test = Hardness_Test_Obj(1, 8);
      Radiography_Test = Radiography_Test_Obj(1, 4);
      Other_Test = Other_Test_Obj(1, 4);

      Certification = Certification_Obj(1,4)

      setdetailObj({
        ...detailObj,
      });

      setheaderObj({
        ...headerObj,
        Visual_Examination,
        Liquid_Penetrant_Examination,
        Minimum_Leak_Path,
        Tear_Test,
        PullOut_Test,
        PullOut_Test_Table,
        Hardness_Test,
        Radiography_Test,
        Other_Test,
        Macro_Examination,
        Certification,
      });
    }
    else{
      let IdprojectPK = location.state?.data.PK;
      let IdprojectSK = location.state?.data.PQR_RecordNo;


      let IdPK = location.state?.data.WPSH_PQR_Process;
      let IdSK = location.state?.data.WPSH_PQR_Type;

      fetchprojectbyid(IdprojectPK, IdprojectSK);

      fetchbyid(IdprojectPK, IdprojectSK, IdPK, IdSK);

      
      let Visual_Examination = editheaderObj.Visual_Examination == undefined ? Visual_Examination_Obj(1, 4) :headerObj.Visual_Examination ;
      let Liquid_Penetrant_Examination = editheaderObj.Liquid_Penetrant_Examination == undefined ?  Liquid_Penetrant_Examination_Obj(1, 4) : headerObj.Liquid_Penetrant_Examination;
      let Minimum_Leak_Path = editheaderObj.Minimum_Leak_Path == undefined ? Minimum_Leak_Path_Obj(1, 4) : headerObj.Minimum_Leak_Path;
      let Tear_Test = editheaderObj.Tear_Test == undefined ?  Tear_Test_Obj(1, 4) : headerObj.Tear_Test ;
      let PullOut_Test = editheaderObj.PullOut_Test == undefined ? PullOut_Test_Obj(1, 4) :headerObj.PullOut_Test ;
      let PullOut_Test_Table = editheaderObj.PullOut_Test_Table == undefined ?  PullOut_Test_Table_Obj(1, 4) : headerObj.PullOut_Test_Table;
      let Hardness_Test = editheaderObj.Hardness_Test == undefined ?  Hardness_Test_Obj(1, 8) : headerObj.Hardness_Test;
      let Radiography_Test = editheaderObj.Radiography_Test == undefined ?  Radiography_Test_Obj(1, 4) : headerObj.Radiography_Test;
      let Other_Test = editheaderObj.Other_Test == undefined ?  Other_Test_Obj(1, 4) : headerObj.Other_Test;
      let Macro_Examination = editheaderObj.Macro_Examination == undefined ?  Macro_Examination_Obj(1, 4) : headerObj.Macro_Examination;
      let Certification = editheaderObj.Certification == undefined ?  Certification_Obj(1, 4) : headerObj.Certification;



    
      editheaderObj.Visual_Examination = Visual_Examination 
      editheaderObj.Liquid_Penetrant_Examination = Liquid_Penetrant_Examination
      editheaderObj.Minimum_Leak_Path = Minimum_Leak_Path
      editheaderObj.Tear_Test = Tear_Test
      editheaderObj.PullOut_Test  = PullOut_Test
      editheaderObj.PullOut_Test_Table  = PullOut_Test_Table
      editheaderObj.Hardness_Test  = Hardness_Test
      editheaderObj.Radiography_Test  = Radiography_Test
      editheaderObj.Other_Test  = Other_Test
      editheaderObj.Macro_Examination  = Macro_Examination
      editheaderObj.Certification  = Certification




      setheaderObj(editheaderObj);

    }
  }, []);


  const [processObj, setprocessObj] = useState([]);

  function Visual_Examination_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Visual_Examination.push({
        PQR_VEX_WeldPass: "",
        PQR_VEX_Acceptance_Criteria: "",
        PQR_VEX_Observation: "",
        PQR_VEX_Results: "",
      });
    }

    return Visual_Examination;
  }

  function Liquid_Penetrant_Examination_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Liquid_Penetrant_Examination.push({
        PQR_LPEX_WeldPass: "",
        PQR_LPEX_Acceptance_Criteria: "",
        PQR_LPEX_Observation: "",
        PQR_LPEX_Results: "",
      });
    }

    return Liquid_Penetrant_Examination;
  }

  function Certification_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Certification.push({
        PQR_Certification_Welder_Name: "",
        PQR_Certification_ID: "",
        PQR_Certification_Tubes_Weld: "",
        PQR_Certification_ConductedBy: "",
        PQR_Certification_Mechanical_TestsBy:"",
        PQR_Certification_Lab_RefNo:"",
      });
    }

    return Certification;
  }

  function Macro_Examination_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Macro_Examination[0].Table6.push({
        PQR_MEX_Tube_No: "",
        PQR_MEX_LocA: "",
        PQR_MEX_LocB: "",
        PQR_MEX_LocC: "",
        PQR_MEX_LocD: "",
      });
      Macro_Examination[0].Table7.push({
        PQR_MEX_Tube_No: "",
        PQR_MEX_LocA: "",
        PQR_MEX_LocB: "",
        PQR_MEX_LocC: "",
        PQR_MEX_LocD: "",
      });
      Macro_Examination[0].Table8.push({
        PQR_MEX_Tube_No: "",
        PQR_MEX_LocA: "",
        PQR_MEX_LocB: "",
        PQR_MEX_LocC: "",
        PQR_MEX_LocD: "",
      });
      Macro_Examination[0].Table9.push({
        PQR_MEX_Tube_No: "",
        PQR_MEX_LocA: "",
        PQR_MEX_LocB: "",
        PQR_MEX_LocC: "",
        PQR_MEX_LocD: "",
      });

      Macro_Examination[0].Table10.push({
        PQR_MEX_Magnification: "",
        PQR_MEX_Acceptance_Criteria: "",
        PQR_MEX_Observation: "",
      });
    }
    return Macro_Examination;
  }

  function Minimum_Leak_Path_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Minimum_Leak_Path[0].Table1.push({
        PQR_MLP_Tube_No: "",
        PQR_MLP_LocA: "",
        PQR_MLP_LocB: "",
        PQR_MLP_LocC: "",
        PQR_MLP_LocD: "",
      });
      Minimum_Leak_Path[0].Table2.push({
        PQR_MLP_Tube_No: "",
        PQR_MLP_LocA: "",
        PQR_MLP_LocB: "",
        PQR_MLP_LocC: "",
        PQR_MLP_LocD: "",
      });
      Minimum_Leak_Path[0].Table3.push({
        PQR_MLP_Tube_No: "",
        PQR_MLP_LocA: "",
        PQR_MLP_LocB: "",
        PQR_MLP_LocC: "",
        PQR_MLP_LocD: "",
      });
      Minimum_Leak_Path[0].Table4.push({
        PQR_MLP_Tube_No: "",
        PQR_MLP_LocA: "",
        PQR_MLP_LocB: "",
        PQR_MLP_LocC: "",
        PQR_MLP_LocD: "",
      });

      Minimum_Leak_Path[0].Table5.push({
        PQR_MLP_Magnification: "",
        PQR_MLP_Acceptance_Criteria: "",
        PQR_MLP_Observation: "",
      });
    }
    return Minimum_Leak_Path;
  }

  function Tear_Test_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Tear_Test.push({
        PQR_Tear_TubeNo: "",
        PQR_Tear_Observation: "",
        PQR_Tear_Results: "",
      });
    }
    return Tear_Test;
  }

  function PullOut_Test_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      PullOut_Test.push({
        PQR_PullOut_TubeNo: "",
        PQR_PullOut_Location: "",
        PQR_PullOut_Load: "",
        PQR_PullOut_Fracture: "",
      });
    }

    return PullOut_Test;
  }

  function PullOut_Test_Table_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      PullOut_Test_Table.push({
        PQR_PullOut_Acceptance_Criteria: "",
        PQR_PullOut_Observation: "",
        PQR_PullOut_Results: "",
      });
    }

    return PullOut_Test_Table;
  }

  function Hardness_Test_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Hardness_Test.push({
        PQR_HT_TypeofTest: "",
        PQR_HT_Tube_No: "",
        PQR_HT_BM_Tubesheet: "",
        PQR_HT_HAZ_Tubesheet: "",
        PQR_HT_Weld: "",
        PQR_HT_HAZ_Tube: "",
        PQR_HT_BM_Tube: "",
        PQR_HT_Results: "",
      });
    }

    return Hardness_Test;
  }

  function Radiography_Test_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Radiography_Test.push({
        PQR_Radio_TubeNo: "",
        PQR_Radio_Acceptance_Criteria: "",
        PQR_Radio_Observation: "",
        PQR_Radio_Results: "",
      });
    }

    return Radiography_Test;
  }

  function Other_Test_Obj(start, end) {
    for (let i = start; i <= end; i++) {
      Other_Test.push({
        PQR_OT_TypeofTest: "",
        PQR_OT_Acceptance_Criteria: "",
        PQR_OT_Results: "",
        PQR_OT_Remarks: "",
      });
    }

    return Other_Test;
  }

  const [logObj, setlogObj] = useState({
    WPSH_Project_No_log:
      headerObj !== undefined ? headerObj.WPSH_Project_No : "",
    WPSH_WPS_No_log: headerObj !== undefined ? headerObj.WPSH_WPS_No : "",
    WPSH_User_Name_log: UserName,
    WPSH_User_Id_log: UserId,
    WPSH_Status_log: "",
  });
  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
    }),
  });
  const [blyestyle, setBlue] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-purple)",
    }),
  });
  const [errorstyle, seterrorstyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-red-alt)",
    }),
  });

  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    let reqData = {
      WPStype: WPStype,
      IdprojectPK: IdprojectPK,
      IdprojectSK: IdprojectSK,
    };

    const result = await getPQRonEdit(reqData);

    if (result !== undefined && result[0] !== undefined) seteditheaderObj(headerObj);
  };


  const fetchbyid = async (IdprojectPK, IdprojectSK, IdPK, IdSK) => {
    let reqData = {
      WPStype: WPStype,
      IdprojectPK: IdprojectPK,
      IdprojectSK: IdprojectSK,
      IdPK: IdPK,
      IdSK: IdSK,
    };

    const result = await getPQRDetailsEdit(reqData);
    setdetailObj(result[0]);
  };
  console.log("detailobjjjj",detailObj)
  useEffect(() => {
   

    if (action === Action.ActionCopy) {
      setcopydata(true);
    }
  }, []);

 
  const next = async (e) => {
    var res = false;
    var val = false;

    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNext();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNext2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNext3();
        break;
      default:
        break;
    }

    if (res === MessageTypes.Success) {
      toast.success("PQR Data Added ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (key !== 3) setKey(+key + 1);
    } else if (res === MessageTypes.Fail) {
      toast.warning("Failed, PQR Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.RequiredAll) {
      toast.warning(
        "Required Fields not filled.Please check and Fill Required Fields for all 3 Pages.",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning("Special Characters # & % + - ' < > = ; not allowed", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Band) {
      toast.warning(
        "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  const handleSelect = (e) => {
    Temp(e, "tab");
  };
  const Temp = async (e, tabaction) => {
    var res = false;
    var val = false;
    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNextTemp();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNextTemp2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNextTemp3();
        break;
      default:
        break;
    }

    if (res === MessageTypes.Temp) {
      toast.success("Temporary Saved PQR Data Successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (tabaction === "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction === "next") {
        if (key !== 3) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res === MessageTypes.None) {
      if (tabaction === "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction === "next") {
        if (key !== 3) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res === MessageTypes.Fail) {
      toast.warning("Failed, PQR Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.SpecialCharacters) {
      toast.warning("Special Characters # & % + - ' < > = ; not allowed", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res === MessageTypes.Band) {
      toast.warning(
        " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  return (
    <>
      <div className="position-absolute ms-3" style={{ top: "15px" }}>
        <strong>Add New</strong>
        <span className="ms-1 warning-text" style={{ fontSize: "small"}} >
          (All fields are mandatory)
        </span>
      </div>

      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <div className="mt-4">
         <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div>

        <Tabs
          activeKey={key}
          onSelect={(e) => handleSelect(e)}
          className="mb-2 w-100 border-end"
          fill
        >
          <Tab
            eventKey={1}
            title="PQR Page 1"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <PQRTubesheetPage1
              ref={firstChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              normalstyle={normalstyle}
              errorstyle={errorstyle}
              blyestyle={blyestyle}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          <Tab
            eventKey={2}
            title="PQR Page 2"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}
          >
            <PQRTubesheetPage2
              ref={secondChildRef}
              processObj={processObj}
              setprocessObj={setprocessObj}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          <Tab
            eventKey={3}
            title="PQR Page 3"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}
          >
            <PQRTubesheetPage3
              ref={thirdChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              testObj={testObj}
              setTestObj={setTestObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
        </Tabs>

        <Row style={{ padding: "5px 90px" }}>
          <div>
            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary"
                className="me-2"
                disabled={isDisabled}
                style={{
                  display: key === 1 ? "none" : "  ",
                  height: "38px",
                  width: "100px",
                }}
                onClick={(e) => Temp(e, "prev")}
              >
                Prev
              </Button>
            )}

          

            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                className="float-end  me-2 btn-primary"
                onClick={(e) => next(e)}
                style={{
                  height: "38px",
                  width: "100px", 
                }}
              >
                {key === 3 ? "Done" : "Next"}
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : originalaction !== Action.ActionAdd ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary "
                className="float-end  me-2"
                onClick={(e) => Temp(e, "next")}
                style={{
                  height: "38px",
                  width: "100px",
                }}
              >
                {key === 3 ? "Temp Save" : "Temp Save"}
              </Button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default PQRTubeSheetTransaction;
