import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import {
  AddNewPCategory,
  UpdatePCategory,
} from "../../Services/pcategory-service";
import SuccessAlertModal from "../Modals/successAlertModal";
import ConfirmAlertModal from "../Modals/confirmAlertModal";
import { useHistory, withRouter, useNavigate } from "react-router-dom";
import {
  isAlphanumeric,
  isAlphanumericWithDotSpaceDashCommaBrackets,
  isAlphanumericWithSpecialChars,
  isNumber,
} from "../../functions/validations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { statusChange } from "../utility/FormControl";

import ButtonModal from "../Modals/buttonModal";

const AddEditPCategoryMaster = (props) => {
  if (props.rowdata.wld_status === "Active") {
    props.rowdata.wld_status = true;
  } else if (props.rowdata.wld_status === "Inactive") {
    props.rowdata.wld_status = false;
  }

  let action = props.action;
  let formName = "P Category";

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const history = useNavigate();
  let username = sessionStorage.getItem("UserName");

  console.log("username is -- ", username);

  const [PCategoryObj, setPCategoryObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
          wld_status: true,
          wld_code: "",
          wld_p_category: "",
          wld_material: "",
          SK: "",
          PK: "PCategory",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
        }
  );
  const [error, setError] = useState({
    SK: {},
    PK: {},
    wld_status: {},
    wld_code: {},
    wld_p_category: {},
    wld_material: {},
    wld_createdby: {},
    wld_createdon: {},
    wld_updatedby: {},
    wld_updatedon: {},
  });
  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setPCategory",
    });
  };

  const handleCloseSuccess = () => {
    setShowSuccessModel(false);
    props.onHide();
    props.setReload(!props.reload);
  };
  const handleCloseConf = () => {
    setshowConfirmModel(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPCategoryObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError((prevState) => ({
      ...prevState,
    }));
  };

  const handleSwitchChange = (e) => {
    statusChange(e);

    setPCategoryObj((prevState) => ({
      ...prevState,
      wld_status: e.target.checked,
    }));
  };

  const resetForm = () => {
    if (action === "Add") {
      setPCategoryObj((prevState) => ({
        ...prevState,
        wld_status: true,
        wld_code: "",
        wld_p_category: "",
        wld_material: "",
        SK: "",
        wld_createdby: "",
        wld_createdon: "",
      }));
    } else if (action === "Edit") {
      setPCategoryObj((prevState) => ({
        ...prevState,
        PK: props.rowdata.PK,
        SK: props.rowdata.SK,
        wld_status: props.rowdata.wld_status,
        wld_code: props.rowdata.wld_code,
        wld_p_category: props.rowdata.wld_p_category,
        wld_material: props.rowdata.wld_material,
        wld_createdby: props.rowdata.wld_createdby,
        wld_createdon: props.rowdata.wld_createdon,
      }));
    }
    setError((prevState) => ({
      ...prevState,
      wld_code: {},
      wld_p_category: {},
      wld_material: {},
      SK: {},
    }));
  };

  const validatePCategory = (action) => {
    let result = false;
    let isError = false;
    if (PCategoryObj.wld_code.trim() === "") {
      PCategoryObj.wld_code = "";
      isError = true;
      error.wld_code = {
        status: true,
        message: "P Category Number is required.",
      };
    }
    if (PCategoryObj.wld_p_category.trim() === "") {
      PCategoryObj.wld_p_category = "";
      isError = true;
      error.wld_p_category = {
        status: true,
        message: "P Category is required.",
      };
    }
    if (PCategoryObj.wld_material.trim() === "") {
      PCategoryObj.wld_material = "";
      isError = true;
      error.wld_material = {
        status: true,
        message: "Material is required.",
      };
    }
    if (PCategoryObj.wld_status === "") {
      isError = true;
      error.wld_status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (action === "Add") {
      

      result =
        props.allRecs &&
        props.allRecs.find(
          (item) =>
            item.PK.toLowerCase() === PCategoryObj.PK.toLowerCase() &&
            item.SK.toLowerCase() === PCategoryObj.wld_code.toLowerCase()
        );
      console.log(result);
    }

    if (result) {
      setAlertMessage("P Category Number Already Exists!");
      isError = true;
    }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));
    if (
      error.SK.status ||
      error.wld_code.status ||
      error.wld_p_category.status ||
      error.wld_material.status
    ) {
      isError = true;
    }
    return isError;
  };
  const handleAdd = (e) => {
    let result;
    if (PCategoryObj.wld_status === true) {
      PCategoryObj.wld_status = "Active";
    } else {
      PCategoryObj.wld_status = "Inactive";
    }
    if (action === "Add") {
      PCategoryObj.SK = PCategoryObj.wld_code;
      PCategoryObj.wld_createdby = username;
      PCategoryObj.wld_createdon = PCategoryObj.wld_createdon;
      result = AddNewPCategory(PCategoryObj);
    } else {
      PCategoryObj.wld_updatedby = PCategoryObj.wld_createdby;
      PCategoryObj.wld_updatedon = PCategoryObj.wld_updatedon;
      result = UpdatePCategory(PCategoryObj);
    }
    result
      .then((resp) => {
        if (action === "Edit") {
          toast.success(`Selected P Category has been Updated successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        if (resp.message === "Duplicate") {
          toast.warning(resp.message, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        } else if (action === "Add") {
          toast.success(`Selected P Category has been Added successfully`, {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
        props.setReload(!props.reload);
        setShowForm(false);
        props.onHide();
      })
      .catch((error) => {
        console.log("Unable to process request");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validatePCategory(action)) {
      setshowConfirmModel(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  return (
    <>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={`Great! You've ${action}ed P Category successfully.`}
        show={showSuccessModel}
        handleClose={handleCloseSuccess}
      />
      <ConfirmAlertModal
        action={action}
        handleYes={handleAdd}
        formName={formName}
        show={showConfirmModel}
        handleClose={handleCloseConf}
      />

      <Modal
        {...props}
        size="lg"
        centered
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} P Category</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="px-2 pb-2">
              <Row className="gx-3" style={{ marginBottom: "2rem" }}>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        type="text"
                        id="wld_code"
                        name="wld_code"
                        placeholder=" "
                        disabled={action === "Edit" ? true : false}
                        maxLength="4"
                        className={
                          error.wld_code.status
                            ? "  placeHolderInput inputText text-field-with-error allow"
                            : " placeHolderInput inputText allow"
                        }
                        value={PCategoryObj.wld_code}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isAlphanumeric(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_code: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_code">
                        P Number{" "}
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                  </Form.Group>
                  {error.wld_code.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "30px" }}>
                        {error.wld_code.message}
                      </span>{" "}
                    </Form.Text>
                  )}
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_p_category"
                        name="wld_p_category"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_p_category.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={PCategoryObj.wld_p_category}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error = isNumber(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            wld_p_category: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_p_category">
                        P Category
                      </label>
                    </div>{" "}
                    &nbsp;&nbsp;
                  </Form.Group>
                  {error.wld_p_category.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "30px" }}>
                        {error.wld_p_category.message}
                      </span>{" "}
                    </Form.Text>
                  )}
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <div class="g-input">
                      <input
                        id="wld_material"
                        name="wld_material"
                        type="text"
                        maxLength="50"
                        class="asterisk"
                        className={
                          error.wld_material.status
                            ? "placeHolderInput inputText text-field-with-error allow"
                            : "placeHolderInput inputText allow"
                        }
                        placeholder=" "
                        value={PCategoryObj.wld_material}
                        onChange={handleChange}
                        onInput={(e) => {
                          let error =
                            isAlphanumericWithDotSpaceDashCommaBrackets(
                              e.target.value
                            );
                          setError((prevState) => ({
                            ...prevState,
                            wld_material: error,
                          }));
                        }}
                      />
                      <label class="required-field" for="wld_material">
                        Material
                      </label>
                    </div>
                  </Form.Group>
                  {error.wld_material.status && (
                    <Form.Text className="text-danger">
                      <span style={{ position: "relative", bottom: "8px" }}>
                        {error.wld_material.message}
                      </span>{" "}
                    </Form.Text>
                  )}
                </Col>
                <Col md={4}>
                  <Form.Group className="form-switch-input">
                    <Row>
                      <Col>
                        {" "}
                        <label
                          style={{
                            fontSize: "14px",
                            padding: "10px",
                            color: "var(--col-707070)",
                            fontWeight: "bold",
                          }}
                          class="required-field"
                        >
                          Status{" "}
                        </label>
                      </Col>
                      <Col>
                        {" "}
                        <Form.Switch
                          label={
                            PCategoryObj.wld_status ? "Active" : "Inactive"
                          }
                          className={PCategoryObj.wld_status ? "green" : "gray"}
                          name="wld_status"
                          style={{ padding: "10px" }}
                          checked={PCategoryObj.wld_status}
                          onChange={(e) => {
                            handleSwitchChange(e);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            {alertMessage && (
              <Alert className="alertBox" variant="warning">
                {alertMessage}
              </Alert>
            )}
          </Modal.Body>

         

          <Row>
            <ButtonModal action={action} resetForm={resetForm}></ButtonModal>
          </Row>

          <Row>
            <br></br>
          </Row>
        </Form>
      </Modal>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AddEditPCategoryMaster;
