import {
    getAllTraining,
    
  } from "../../../Services/training-service";
const TraingType = ["Upcoming","InProgress","Completed"]
    

export const getTraining = async() =>{
    let result1
    let results = [], trainingarray = [];

    TraingType.forEach((i) =>{
        result1 =  getAllTraining(i);
    
    

    if (result1 != undefined ) {
        
        results.push(result1)
    }
    })
    for (let result of results) {
        let training = await Promise.resolve(result);
        trainingarray = [...trainingarray, ...training];
    }
     return trainingarray;
}