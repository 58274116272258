import React, { useEffect, useState } from "react";
import "../Css/groove_pdf.css";
import { Button, Col, Form, Modal, Row, Alert, Spinner } from "react-bootstrap";

import { jsPDF } from "jspdf";

import {
  getPresignedURL,
} from "../../../Services/Welder-services";
import axios from "axios";
import BarLoader
from "react-spinners/BarLoader";
import {font} from "../font/font";
import {fontbold} from "../font/font_bold";
import {fontitalic} from "../font/font_italic";
import {fontbolditalic} from "../font/font_bolditalic";
// import Groove_pdf_page2 from "./Pages/Groove_pdf_page2WPQ";
// import Groove_pdf_page1WPQ from "./Pages/Groove_pdf_page1WPQ";
// import Groove_pdf_page2WPQ from "./Pages/Groove_pdf_page2WPQ";
// import WPQTubeToTubesheet_pdf_page1 from "./Pages/WPQTubeToTubesheet_pdf_page1";
// import WPQTubeToTubesheet_pdf_page2 from "./Pages/WPQTubeToTubesheet_pdf_page2";
// import WPQTubeToTubesheet_pdf_page3 from "./Pages/WPQTubeToTubesheet_pdf_page3";
import WI_PDF_1 from "./WI_PDF_1";
import { getallWelderid, getallwelders } from "../../../Services/WI-services";
import { getdetailsById } from "../../../Services/wps_details-service";
import WI_PDF_2 from "./WI_PDF_2";
import Jspdf_font from "../../../Assests/font/jsPdf_font";

// const   WI_PDF_Download = ({widata,props.rowdata,onHide,setReload,reload},...props) => {
  const   WI_PDF_Download = (props) => {

    let action = props.action;

    console.log("InPropsRow", props.rowdata, props.wpsde)
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmModel, setshowConfirmModel] = useState(false);
  const [loading, setLoading] = useState(true);

  const [previewuser, setPreviewuser] = useState("");

  const [previewapproved, setPreviewapproved] = useState("");
  let userprofile = sessionStorage.getItem("UserProfile");

  let Token = sessionStorage.getItem("Token");

  let folderwld = "wld_signatures"
  let folderwps = "WPS_Groove"
  let folderwpq = "WPQ_Groove"


  console.log("userprofile", userprofile);

  console.log("widataddddddddd", props.widata);

  console.log("rowdataddd", props?.rowdata ,props?.rowdata[1]);



  const downloadPdf = () => {

    
          let doc = new jsPDF('p', 'pt', "a4");
          // doc.addFileToVFS('./font/WorkSans-Regular.ttf', font);
          // doc.addFileToVFS('./font/WorkSans-Bold.ttf', fontbold);
          // doc.addFileToVFS('./font/WorkSans-Italic.ttf', fontitalic);
          // doc.addFileToVFS('./font/WorkSans-BoldItalic.ttf', fontbolditalic);
          // doc.addFont('./font/WorkSans-Regular.ttf',  'WorkSans-Regular', 'normal');
          // doc.addFont('./font/WorkSans-Bold.ttf',  'WorkSans-Regular', 'bold');
          // doc.addFont('./font/WorkSans-Italic.ttf',  'WorkSans-Regular', 'italic');
          // doc.addFont('./font/WorkSans-BoldItalic.ttf',  'WorkSans-Regular', 'bolditalic');
          //  doc.setFont('WorkSans-Regular',"normal")
          //  doc.setFont('WorkSans-Regular',"bold")
          //  doc.setFont('WorkSans-Regular',"italic")
          //  doc.setFont('WorkSans-Regular',"bolditalic") 
          doc = Jspdf_font(doc) 
          let pdfjs = document.querySelector('#MainDiv');
          doc.html(pdfjs, {
            
        callback: function(doc) {
       
    // doc.addFont('ArialMS', 'Arial', 'normal');
           
          //doc.setFont('WorkSans-Regular')
           console.log("font",doc.getFontList())
          //  var Filename = "WI Report "
           var Filename = `WI Report ${props?.rowdata[1]?.wld_WPSType}`
          doc.save(Filename);
          props.onHide();
         props.setReload(!props.reload);
         setLoading(false)
        
        doc.setFontSize(10);
        

        },margin: [-25, 10, 10,10]
        
       
    });      
    console.log("width",window.innerWidth)
  ;
}



  useEffect(() => {

    downloadPdf();

  }, [props?.rowdata]);



return(

<>

{/* {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">

    </Spinner></div>} */}

      <Modal
        {...props}
        size="sm"
        centered
        transparent={true}
        className={
          showForm || showSuccessModel || showConfirmModel
            ? "opacity-0 userMasterModal"
            : "opacity-100 userMasterModal"
        }
        backdrop="static"
      >
        <Modal.Body
          style={{
            maxHeight: "calc(1000vh - 1000px)",
          }}
        >
          <p>
            {loading && (
              <BarLoader
                color="blue"
                height={8}
                width={196}
                className="shift"
              />
            )}

            {/* {loading &&  <Spinner animation="border" className="spinner">

</Spinner>}      */}
          </p>
        </Modal.Body>
        {/* <WI_PDF_1 /> */}

      </Modal>

      <div id="MainDiv">
    <br></br>
    <>
    {props?.rowdata[1].wld_WPSType !== "Tubesheet" ?

    <WI_PDF_1 
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
    : 
     <WI_PDF_2
    rowdata={props?.rowdata}
    wpsde={props.wpsde}
    // wpsdetail={wpsdetail}
    />
}
    </>

 
  </div>


       
         
         
      
      

</>
)
    }


export default WI_PDF_Download;
