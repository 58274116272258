import React, { useState, useEffect, useRef, Suspense } from "react";
import "../Assests/Css/header.css";
import "../Assests/Css/account.css";
import { getMenuDetails } from "../Services/menu-service";
import Account from "./Account";
import { useLocation } from "react-router-dom";
import MainComponent from "./MainComponent";

const Header = (props) => {
  const childRef = useRef();
  const [pathname, setpath] = useState(useLocation().pathname);
  const [heading, setHeading] = useState("Dashboard");
  const [Labels, setLabel] = useState({
    title: "Dashboard",
    button: false,
    buttontext: "",
    currentMenu: "DASHBOARD",
    heading: "",
  });

  console.log("useLocation", useLocation());

  //var location = camelize(useLocation().pathname);
  var location = useLocation().pathname;
  console.log("pathloc", location);
  let lastElement;
  var Title;
  console.log(
    "location",
    Labels?.title,
    Labels?.title === undefined && location !== "/",
    Labels?.title === undefined,
    location
  );
  const GetNav = () => {
    lastElement = location.replace(/[\W_]/g, " > ");
    console.log(
      "Location",
      location,
      lastElement.lastIndexOf(">"),
      Labels?.title
    );
    lastElement = lastElement.slice(1, lastElement.lastIndexOf(">"));
    console.log("Location1222", lastElement);
    // lastElement =  Labels?.title != undefined ? lastElement.concat(">",Labels.title) : lastElement.concat("> ", "Dashboard")
    lastElement = lastElement.replace("weldingmaster", "Welding Master");
    lastElement = lastElement.replace("wps", "WPS Transaction");
    lastElement = lastElement.replace("maintenance", "Maintenance");
    lastElement = lastElement.replace("dashboard", "Dashboard");
    lastElement = lastElement.replace("raiseDeviation", "Raise Deviation");
    lastElement = lastElement.replace("groove", "Raise Deviation");
    lastElement = lastElement.replace("PQRTransaction", "PQR Transaction");
    lastElement = lastElement.replace("TubetoTubesheet", "Tube to Tubesheet");

    return lastElement;
  };
  const getTitle = (location) => {
    var innerpage = location.slice(
      location.lastIndexOf("/") + 1,
      location.length
    );
    console.log("locationinner", innerpage);
    Title = Labels?.title;
    switch (innerpage) {
      case "WCSdetail":
        Title = "WCS Detail";
        break;
      case "/":
        Title = "Dashboard";
        break;
    }
    return Title;
  };

  // let lastElement = location.split("/").pop();
  //let lastElement = location.split(">").pop();
  console.log("lastElement", lastElement);

  const next = async (e) => {
    console.log("Labels.button", Labels.button);
    console.log("childRef", childRef);

    if (Labels.button.toLowerCase() === "true") await childRef.current.handleAdd();
  };

  useEffect(() => {
    let result1;
    result1 = sessionStorage.getItem("UserAccess");
    if (result1 !== undefined)
      setLabel(getMenuDetails(result1, window.location.pathname));

    if (location.indexOf("unauthorized") !== -1)
      setLabel({
        title: "UnAuthrorized",
        button: false,
        buttontext: "",
        currentMenu: "UnAuthorized",
        heading: "Un Authorized",
      });

    //console.log("header----1",window.location.pathname);
  }, [useLocation().pathname]);

  return (
    <>
      <div className="top">
        <div class="outertitle">
          <div
            className="title"
            style={{
              fontSize: "24px",
              width: "max-content",
              position: "relative",
              top: "8px",
            }}
          >
            <b>
              {Labels?.heading !== undefined
                ? Labels?.heading
                : Labels?.title === undefined && location === "/"
                ? "Dashboard"
                : Labels?.title}
            </b>
          </div>
          <div class="btnn">
            <div className="add_btn">
              {/* {console.log("nn",Labels.button)} */}
              {Labels?.button === "TRUE" && (
                <button class="button button1" onClick={(e) => next(e)}>
                  {" "}
                  {/* <div className="text"> */}
                  <div className="text">
                    <img src="../sidebar/add.png"></img>
                    {Labels?.buttontext}
                    &nbsp;
                  </div>
                </button>
              )}
            </div>
          </div>
          <div className="account1">
            <Account />
          </div>
        </div>
      </div>
      {getTitle(location) !== undefined && (
        <div className={"location"}>
          <div className="ltext">
            {" "}
            Home
            {/* {location} */}
            {GetNav()}
            {"> "}
            <a>{getTitle(location)}</a>
          </div>
        </div>
      )}
      <MainComponent childRef={childRef}></MainComponent>
    </>
  );
};

export default Header;
